/* eslint-disable no-unused-vars */
import * as React from "react";
import Flag from "react-world-flags";
import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Open Sans, sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      backgroundColor: "transparent",
    },
  },
}))(InputBase);

const LocaleSwitcher = (props) => {
  const { i18n } = useTranslation();
  const handleChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <FormControl>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={i18n.language}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value={"fr-FR"}>
            <Flag code="FR" height="16" />
          </MenuItem>
          <MenuItem value={"en-US"}>
            <Flag code="US" height="16" />
          </MenuItem>
          <MenuItem value={"it-IT"}>
            <Flag code="IT" height="16" />
          </MenuItem>
          <MenuItem value={"es-ES"}>
            <Flag code="ES" height="16" />
          </MenuItem>
          <MenuItem value={"pt-PT"}>
            <Flag code="PT" height="16" />
          </MenuItem>
          <MenuItem value={"de-DE"}>
            <Flag code="DE" height="16" />
          </MenuItem>
          <MenuItem value={"nl-NL"}>
            <Flag code="NL" height="16" />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default LocaleSwitcher;
