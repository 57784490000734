import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const ColorInput = styled(TextField)`
  && {
    .MuiInputLabel-formControl {
      left: 10px;
    }
    .MuiInputBase-input {
      padding: 6px 8px;
    }
    width: 100%;
  }
`;
export const LabelColorInput = styled.label`
  color: grey;
  font-size: 12px;
`;

export const ResetButton = styled.svg`
  cursor: pointer;
  margin-right: 3px;
`;
