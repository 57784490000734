import React from "react";
import { CombineContext } from "../../../../../store/UserProvider";
import { getAgency } from "../../Utils/axiosRequest";

const formWrapper = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { agency: null, userSelect: null };
    }

    onChangeColor = (setter, color) => {
      this.setState({ [setter]: color, enableSaveAddress: false });
    };

    static contextType = CombineContext;

    onPlaceSelect = (value) => {
      if (value !== null) {
        this.setState({
          addressfull: value.properties.formatted,
          address: value.properties.address_line1,
          postalcode: value.properties.postcode,
          city: value.properties.city,
          country: value.properties.country,
          lat: value.properties.lat,
          lon: value.properties.lon,
          enableSaveAddress: false,
        });
      }
    };

    async componentDidMount() {
      const user = await this.context;
      if (user.valueChange.userChange) {
        this.setState({ userSelect: user.valueChange.userChange });
      } else {
        this.setState({ userSelect: user.value.user });
      }
      if (this.state.userSelect && this.state.userSelect.agenceId) {
        const getAgencyDetails = await getAgency(
          this.state.userSelect.agenceId
        );
        if (getAgencyDetails.status === 200) {
          this.setState({
            agency: getAgencyDetails.data,
          });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    }

    render() {
      return (
        <WrappedComponent
          initialValues={this.state}
          onPlaceSelect={this.onPlaceSelect}
          onChangeColor={this.onChangeColor}
          userSelect={this.state.userSelect}
          agency={this.state.agency}
          enableSaveAddress={
            this.state.enableSaveAddress && this.state.enableSaveAddress
          }
          {...this.props}
        >
          {this.props.children}
        </WrappedComponent>
      );
    }
  };
};

export default formWrapper;
