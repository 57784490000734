/* eslint-disable no-unused-vars */
import React from "react";
import "../../Utils/Styles/index.css";
import { useRedirect, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import DashboardHeader from "./DashboardHeader";

import {
  DashboardContainer,
  DashboardSLidesContainer,
  DashboardTopProjectContainer,
  TopProjectText,
  TopProjectTextBold,
} from "../../Utils/Styles/DashboardComponents.styles";
import DashboardSlide from "./DashboardSlide";
import { Fade } from "@material-ui/core";
import DashboardWrapper from "./DashboardWrapper";
import FormAbo from "../../../../ModuleAbo/FormAbo";

const DashboardViewPrint = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  return (
    <Fade in={true} timeout={400}>
      <DashboardContainer>
        {props.type === "print" && (
          <DashboardHeader
            total={props.data.length}
            translate={translate}
            open={open}
          />
        )}
        {/* <FormAbo
          record={props.agency}
          $uppercase={"true"}
          $size={"true"}
          noDivider={true}
          refresh={props.refresh}
          context={"dashboard"}
        /> */}
        {/* {props.type === "print" && props.data
          ? props.data.length !== 0 && (
              <DashboardTopProjectContainer>
                <TopProjectText>
                  <TopProjectTextBold>
                    {translate("resources.dashboard.thenews")}
                  </TopProjectTextBold>
                </TopProjectText>
              </DashboardTopProjectContainer>
            )
          : null}
        <DashboardSLidesContainer>
          {props.type === "print" && props.data
            ? props.data.slice(0, 3).map((item, index) => {
                return (
                  <DashboardSlide
                    key={index}
                    item={item}
                    redirect={redirect}
                    open={open}
                  />
                );
              })
            : null}
        </DashboardSLidesContainer> */}
      </DashboardContainer>
    </Fade>
  );
};

export default DashboardWrapper(DashboardViewPrint);
