export const choices = {
  category: [
    { id: "Vente", name: "details.category.sale" },
    { id: "Location", name: "details.category.rental" },
    {
      id: "Locationsaisonnière",
      name: "details.category.seasonalrental",
    },
    { id: "Programme", name: "details.category.program" },
    { id: "Viager", name: "details.category.annuities" },
    {
      id: "Nue-propriété",
      name: "details.category.bareownership",
    },
    { id: "Enchère", name: "details.category.bid" },
  ],
  periodicity: [
    { id: "night", name: "details.night" },
    { id: "week", name: "details.week" },
    { id: "month", name: "details.month" },
  ],
};
