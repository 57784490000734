/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  AutoCompleteInput,
  DatePicker,
  FilterContainer,
  HeaderContainer,
  IconContainer,
  IconRefresh,
  TotalProjectContainer,
  TotalText,
  TotalTextBold,
  TotalTextColor,
} from "../../Utils/Styles/DashboardComponents.styles";
import { TextField } from "@material-ui/core";
import { startOfMonth } from "date-fns";
import { useTranslate } from "react-admin";

const DashboardHeader = (props) => {
  const translate = useTranslate();
  const {
    total,
    handleFilterUser,
    handleFilterDate,
    users,
    isUserData,
    open,
  } = props;
  const [selectedDateStart, setSelectedDateStart] = useState("");
  const [selectedDateEnd, setSelectedDateEnd] = useState("");

  useEffect(() => {
    if (selectedDateStart === "") {
      const firstOfMonth = startOfMonth(new Date());
      let years = firstOfMonth.getFullYear();
      let month =
        firstOfMonth.getMonth() + 1 >= 10
          ? firstOfMonth.getMonth() + 1
          : 0 + "" + (firstOfMonth.getMonth() + 1);
      let day = 0 + "" + firstOfMonth.getDate();
      let fullDate = years + "-" + month + "-" + day;
      setSelectedDateStart(fullDate);
      handleFilterDate("min", firstOfMonth.setHours(0, 0, 0, 0));
    }
  }, [selectedDateStart, handleFilterDate]);

  const defaultProps = {
    options: users.sort(function (a, b) {
      return a.firstname.localeCompare(b.firstname);
    }),
    getOptionLabel: (option) => {
      return `${option.firstname} ${option.lastname}`;
    },
  };

  const onChangeDate = (event) => {
    const myDate = new Date(event.target.value);
    myDate.setHours(0, 0, 0, 0);
    if (event.target.id === "dateStart") {
      setSelectedDateStart(event.target.value);
      handleFilterDate("min", myDate);
    } else {
      setSelectedDateEnd(event.target.value);
      handleFilterDate("max", myDate);
    }
  };

  const handleChangeUser = (event, value) => {
    if (value !== null) {
      handleFilterUser("userId", value._id);
    } else {
      handleFilterUser("userId", "");
    }
  };

  return (
    <HeaderContainer open={open}>
      <TotalProjectContainer open={open}>
        <TotalText>
          <TotalTextBold>{`${total} `}</TotalTextBold>
          {`${translate("resources.dashboard.admin.mandate")}${
            total > 1 ? "s" : ""
          }`}
          {`${isUserData ? " actif" : ""}`}
          {`${isUserData && total > 1 ? "s" : ""}`}
          {`${isUserData ? " sur" : ""}`}
          <TotalTextColor> visibble</TotalTextColor>{" "}
        </TotalText>
      </TotalProjectContainer>
      <FilterContainer open={open}>
        <IconContainer>
          <IconRefresh onClick={() => props.refresh()} />
        </IconContainer>
        <DatePicker
          id="dateStart"
          open={open}
          label={translate("resources.dashboard.datestart")}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeDate}
          value={selectedDateStart}
        />
        <DatePicker
          id="dateEnd"
          label={translate("resources.dashboard.dateend")}
          open={open}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onChangeDate}
          value={selectedDateEnd}
        />
        <AutoCompleteInput
          {...defaultProps}
          onChange={handleChangeUser}
          menu={open.toString()}
          disableClearable={false}
          noOptionsText={translate("resources.fields.noresult")}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate("resources.fields.agent").toUpperCase()}
              margin="normal"
              variant="filled"
            />
          )}
        />
      </FilterContainer>
    </HeaderContainer>
  );
};

export default DashboardHeader;
