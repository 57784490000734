import React from "react";

const Terrace = () => {
  return (
    <svg
      id="Groupe_295"
      width="43.264"
      height="45.26"
      viewBox="0 0 43.264 45.26"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_113"
            data-name="Rectangle 113"
            width="43.264"
            height="45.26"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        id="Rectangle_111"
        data-name="Rectangle 111"
        d="M-1-1H37.606V5.537H-1ZM35.621,1H.985V3.537H35.621Z"
        transform="translate(3.323 13.511)"
        fill="#fff"
      />
      <path
        id="Rectangle_112"
        data-name="Rectangle 112"
        d="M-1-1H22.105V5.537H-1ZM20.13,1H.975V3.537H20.13Z"
        transform="translate(11.199 25.1)"
        fill="#fff"
      />
      <path
        id="Ligne_9"
        data-name="Ligne 9"
        d="M1,7.955l-2-.032L-.874-.016l2,.032Z"
        transform="translate(21.74 17.61)"
        fill="#fff"
      />
      <g id="Groupe_292" data-name="Groupe 292">
        <g id="Groupe_291" data-name="Groupe 291" clipPath="url(#clip-path)">
          <path
            id="Ligne_10"
            data-name="Ligne 10"
            d="M.987,15.509H-1V0H.987Z"
            transform="translate(21.869 29.526)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Tracé_603"
        data-name="Tracé 603"
        d="M65.041,94.8l-.7-1.873L68.416,91.4V87.368h2v5.413Z"
        transform="translate(-43.001 -58.161)"
        fill="#fff"
      />
      <path
        id="Tracé_604"
        data-name="Tracé 604"
        d="M54.928,95.354l-5.721-1.521V88.59h2v3.7l4.235,1.126Z"
        transform="translate(-33.376 -58.936)"
        fill="#fff"
      />
      <g id="Groupe_294" data-name="Groupe 294">
        <g id="Groupe_293" data-name="Groupe 293" clipPath="url(#clip-path)">
          <path
            id="Tracé_605"
            data-name="Tracé 605"
            d="M0,65H1.987V76.425l10.143-.236v7.2H1.987V87.7H0ZM10.143,81.4V78.223l-8.156.19V81.4Z"
            transform="translate(-0.655 -43.033)"
            fill="#fff"
          />
          <path
            id="Ligne_11"
            data-name="Ligne 11"
            d="M.987,5.9H-1V0H.987Z"
            transform="translate(10.487 39.363)"
            fill="#fff"
          />
          <path
            id="Ligne_12"
            data-name="Ligne 12"
            d="M.565,14.137l-1.137-1.63L18.534-.82,19.671.809Z"
            transform="translate(2.701 0.283)"
            fill="#fff"
          />
          <path
            id="Ligne_13"
            data-name="Ligne 13"
            d="M18.294,14.133-.577.806.569-.817,19.44,12.51Z"
            transform="translate(21.099 0.283)"
            fill="#fff"
          />
          <path
            id="Ligne_14"
            data-name="Ligne 14"
            d="M.859,13.813-.87,12.834,6.678-.493,8.407.486Z"
            transform="translate(14.261 0.281)"
            fill="#fff"
          />
          <path
            id="Ligne_15"
            data-name="Ligne 15"
            d="M7.048,13.487-.854.513.843-.52l7.9,12.973Z"
            transform="translate(21.337 0.399)"
            fill="#fff"
          />
          <path
            id="Tracé_606"
            data-name="Tracé 606"
            d="M104.854,60.515h1.987V84.7h-1.987V80.037h-10.8V72.5l10.8.251Zm0,14.223-8.816-.2V78.05h8.816Z"
            transform="translate(-62.922 -40.064)"
            fill="#fff"
          />
          <path
            id="Ligne_16"
            data-name="Ligne 16"
            d="M.987,6.281H-1V0H.987Z"
            transform="translate(32.13 38.979)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Terrace;
