import { Box } from "@material-ui/core";
import { TextInput } from "ra-ui-materialui";
import React from "react";

const Customer = (props) => {
  return (
    <div>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source={`${props.customer}.firstname`}
            resource="offers"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source={`${props.customer}.lastname`}
            resource="offers"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source={`${props.customer}.email`}
            resource="offers"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source={`${props.customer}.phonemobile`}
            resource="offers"
            fullWidth
          />
        </Box>
      </Box>
    </div>
  );
};

export default Customer;
