import styled from "styled-components";
//d
export const Container = styled.div`
  width: 296mm;
  height: 418mm;
  position: relative;
  background-color: #ffffff;
  margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
  zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 portrait;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const ContainerTop = styled.div`
  width: 100%;
  height: 707.8pt;
  display: flex;
  position: relative;
`;
export const ContainerTopLeft = styled.div`
  height: 100%;
  width: 647.37pt;
  position: relative;
`;

export const ImgBackGround = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;

  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const ContainerTopRight = styled.div`
  height: 100%;
  width: 194.63pt;
  display: flex;
  flex-direction: column;
`;

export const ContainerTopTitle = styled.div`
  margin-top: 20px;
  width: 238.99pt;
  height: 106.96pt;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 9%;
  right: 0%;
  background-color: ${(props) => props.colorPrimary};
  text-align: center;
`;

export const Triange = styled.div`
  margin-top: 20px;
  position: absolute;
  right: 195pt;
  top: 171pt;
  border-top: 0px solid transparent;
  border-bottom: 35.43pt solid transparent;
  border-right: 45.25pt solid ${(props) => props.colorPrimary};
`;
export const Type = styled.h3`
  color: ${(props) => props.colorTitle};
  margin-bottom: 0.1rem;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const Location = styled.h3`
  color: ${(props) => props.colorSubTitle};
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const ContainerTopRight1 = styled.div`
  width: 100%;
  height: 78%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100pt;
`;

export const Description = styled.p`
  margin-left: 2em;
  margin-right: 1em;
  text-align: left;
  white-space: pre-wrap;
  line-height: 115%;
  color: ${(props) => props.colorDesc};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const ContainerTopRight2 = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 233.33pt;
  height: 115.82pt;
  background-color: ${(props) => props.colorPrimary};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12pt;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const ContainerBottom = styled.div`
  height: 462.2pt;
  width: 100%;
`;
export const ContainerBottomTop = styled.div`
  height: 35%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20pt 20pt 0;
`;
export const ContainerBottomLast = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
`;
export const ContainerBottomLastLeft = styled.div`
  height: 100%;
  width: 65%;
  display: flex;
  overflow: hidden;
  position: relative;
`;
export const ContainerBottomLastRight = styled.div`
  height: 100%;
  width: 35%;
  padding-top: 30pt;
  display: flex;
  flex-direction: column;
`;

export const QrcodeContainer = styled.div`
  width: 120pt;
  height: 120pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Ref = styled.p`
  margin-bottom: 10pt;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const ExcluContainer = styled.div`
  padding: 5px 0;
  background-color: ${(props) => props.colorPrimary};
  width: 213pt;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  left: 0;
`;
export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};

  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const LogoContainer = styled.div`
  width: 100%;
  padding-top: 5pt;
  display: flex;
  justify-content: center;
`;
export const LogoAgence = styled.img`
  height: 60pt;
`;
