import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import ModuleAlurDetails from "../../ModulesElements/ModuleAlurDetails";
import ModulePrice from "../../ModulesElements/ModulePrice";
import ModuleQrCode from "../../ModulesElements/ModuleQrCode";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import {
  AlurContainer,
  Background,
  BlockDescContainer,
  CityContainer,
  CityText,
  Container,
  Description,
  DpeGesContainer,
  ExcluContainer,
  ExcluText,
  FinancialContainer,
  Footer,
  Header,
  ImagesContainer,
  Img,
  PriceContainer,
  QrcodeContainer,
} from "./Portrait.styles";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../ModulesElements/ModulesElements.styles";

const PersoPortrait = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const excludedValue = ["", undefined, null];

  const {
    imageFirst,
    brightness,
    saturation,
    mirror,
    color,
    project,
    colorTitle,
    colorPrimary,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    desc,
    colorPrice,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      mirror={mirror}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <Header
        src={
          agency.headerPortrait && agency.headerPortrait.length
            ? agency.headerPortrait[0].src
            : null
        }
        as={
          agency.headerPortrait && agency.headerPortrait.length ? "img" : "div"
        }
        colorPrimary={
          !excludedValue.includes(colorPrimary) ? colorPrimary : "black"
        }
      />
      <Background
        backgroundImage={`url(${
          agency.backgroundPortrait && agency.backgroundPortrait.length
            ? agency.backgroundPortrait[0].src
            : null
        })`}
      />
      <CityContainer>
        {project.agreement === choices.agreement[2].id && (
          <ExcluContainer colorPrimary={colorPrimary || "black"}>
            <ExcluText>{t("details.modalprint.exclu")}</ExcluText>
          </ExcluContainer>
        )}
        <CityText>
          <span
            style={{
              color: !excludedValue.includes(colorTitle) ? colorTitle : "black",
            }}
          >
            {t(
              choices.category.filter((item) => item.id === project.category)[0]
                .name
            )}
          </span>{" "}
          <span
            style={{
              color: !excludedValue.includes(colorTitle) ? colorTitle : "black",
            }}
          >
            {project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </span>{" "}
          -{" "}
          <span
            style={{
              color: !excludedValue.includes(colorSubTitle)
                ? colorSubTitle
                : "black",
            }}
          >
            {subTitleText}
          </span>
        </CityText>
      </CityContainer>
      <ImagesContainer colorPrimary={color.primary || "black"}>
        <Img
          src={imageFirst}
          brightness={brightness}
          saturation={saturation}
          colorPrimary={color.primary || "black"}
        />
      </ImagesContainer>
      <BlockDescContainer>
        <Description colorDesc={colorDesc || "black"}>
          {desc.substr(0, 450)}
        </Description>
      </BlockDescContainer>
      <FinancialContainer>
        <PriceContainer>
          <ModulePrice
            colorPrice={colorPrice || "black"}
            price={project.price}
            project={project}
          />
        </PriceContainer>
        <AlurContainer>
          <RenderContext project={project}>
            <ModuleAlurDetails
              project={project}
              colorDesc={colorDesc}
              price={project.price}
              defaultColor={"black"}
              smallMarginBottom={"0"}
              sizeText={"16px"}
            />
          </RenderContext>
          <div style={{ maxWidth: "290px" }}>
            <AlurText sizeText={"16px"} colorDesc={colorDesc || "black"}>
              Les informations sur les risques auxquels ce bien est exposé sont
              disponibles sur le site Géorisques : http://www.georisques.gouv.fr
            </AlurText>
          </div>
        </AlurContainer>
      </FinancialContainer>
      <DpeGesContainer>
        <RenderContext project={project}>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top: "0px",
              left: "40px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.17"
                  : "0.19"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"light"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "10px"
                  : "-46px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "270px"
                  : "300px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.40"
                  : "0.22"
              }
              theme={"light"}
            />
          </div>
        </RenderContext>
      </DpeGesContainer>
      {qrcodeEdit !== null && (
        <QrcodeContainer>
          <ModuleQrCode
            qrcode={qrcodeEdit}
            size={160}
            text={true}
            fontSize={"1.5rem"}
          />
        </QrcodeContainer>
      )}
      <Footer
        src={
          agency.footerPortrait && agency.footerPortrait.length
            ? agency.footerPortrait[0].src
            : null
        }
        as={
          agency.footerPortrait && agency.footerPortrait.length ? "img" : "div"
        }
        colorPrimary={color.primary || "black"}
      />
    </Container>
  );
});

export default PersoPortrait;
