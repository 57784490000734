import { Box, Button, Toolbar, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { SaveButton, useRedirect, useTranslate } from "react-admin";
import PrintIcon from "@material-ui/icons/Print";
import {
  ButtonDelete3D,
  DeleteBtn,
} from "../../../Utils/Styles/ProjectList.styles";
import { deleteFolderContents } from "../../../../../../firebase/firebase.function";
import { config } from "../../../../../../constants/constant";

import "../../../Utils/Styles/projectList.css";
import ModalShare from "./ModalShare";
import { useAlert } from "react-alert";
import { PrintButton } from "./ProjectForm.styles";
import { errorsHandler } from "../../../Utils/errorsHandler";

const ToolbarForm = (props) => {
  const alert = useAlert();
  const translate = useTranslate();
  const { formProps, enableSaveAddress, initialValues, agency } = props;
  const redirect = useRedirect();
  const [show, setShow] = useState(false);

  const sendOtherData = (formProps) => {
    const path = `new/${formProps.record._id}/tiles`;
    deleteFolderContents(path);
    redirect("/projects");
  };

  const submit = () => {
    // props.setLoading(true);
    formProps.handleSubmitWithRedirect();
  };

  const onSuccess = () => {
    // props.setLoading(false);
    if (formProps.record.reference) {
      alert.show(translate("resources.alert.changessaved"));
      props.setErrors({
        reference: false,
        state: false,
        category: false,
        type: false,
        price: false,
        area: false,
      });
      redirect(false);
    } else {
      props.setErrors({
        reference: false,
        state: false,
        category: false,
        type: false,
        price: false,
        area: false,
      });
      redirect("/projects");
    }
  };

  const failure = (error) => {
    // props.setLoading(false);
    alert.error(translate("resources.fields.completeall"));
    errorsHandler(error, props.setErrors);
  };

  if (!agency) return null;
  else {
    return (
      <Toolbar>
        <Box display="flex" width="100%">
          <SaveButton
            saving={formProps.saving}
            handleSubmitWithRedirect={submit}
            disabled={enableSaveAddress === false ? false : formProps.pristine}
            transform={(data) => ({ ...data, ...initialValues })}
            onSuccess={onSuccess}
            onFailure={failure}
          />
          {formProps.record._id && props.ads && (
            <Button
              target="_blank"
              href={
                config.APP_BASE +
                "/visite360/" +
                agency.slug +
                "/" +
                formProps.record.slug
              }
            >
              {translate("resources.fields.show360").toUpperCase()}
            </Button>
          )}
          {formProps.record.id && (
            <Tooltip
              title={
                formProps.record.images.length
                  ? ""
                  : translate("resources.fields.noimages")
              }
              placement="top"
              arrow
            >
              <PrintButton
                color="default"
                startIcon={<PrintIcon />}
                noimages={formProps.record.images.length ? "false" : "true"}
                onClick={
                  formProps.record.images.length
                    ? () => redirect(`/print/${formProps.record.id}`)
                    : null
                }
              >
                {translate("resources.fields.print")}
              </PrintButton>
            </Tooltip>
          )}
          {formProps.record.id && props.ads && (
            <Button color="default" onClick={() => setShow(true)}>
              {translate("resources.fields.share")}
            </Button>
          )}
          <div className="small-screen">
            {formProps.record.scenes && props.ads && (
              // DELETE SCENES & SLUG
              <ButtonDelete3D
                onSuccess={() => sendOtherData(formProps)}
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                transform={(data) => ({ ...data, scenes: null })}
                label={translate("resources.fields.delete360")}
                className="button-3D"
              />
            )}
            <DeleteBtn
              label={translate("resources.fields.delete")}
              basePath={formProps.basePath}
              record={formProps.record}
              undoable={false}
              className="delete-btn"
            />
          </div>
        </Box>

        {show && (
          <ModalShare
            infoProject={formProps.record}
            agency={agency}
            setShow={setShow}
          />
        )}
      </Toolbar>
    );
  }
};

export default ToolbarForm;
