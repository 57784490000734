import { Box } from "@material-ui/core";
import { ArrayInput, SimpleFormIterator, TextInput } from "react-admin";
import React from "react";
import { arrayForm } from "../../../Utils/Styles/Agency.mui.styles";

const CamerasForm = (props) => {
  const styles = arrayForm();

  return (
    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
      <ArrayInput source="cameras" resource="agencies" className={styles.root}>
        <SimpleFormIterator>
          <TextInput source="cameras.name" resource="agencies" />
          <TextInput source="cameras.id" resource="agencies" />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

export default CamerasForm;
