import React from "react";
// CSS
import {
  Container,
  ContainerImg,
  LogoContainer,
  LogoAgence,
  ExcluContainer,
  Exclu,
  QrcodeContainer,
  EnergyContainer,
  InfoContainer,
  InfoContainerLeft,
  InfoContainerRight,
  ContainerPrice,
  InfoType,
  InfoCity,
  Ref,
  ContainerAlur,
  AlurText,
  DpeTextContainer,
} from "./SingapourLandscape.styles";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText as AlurMore } from "../../Modules/ModulesElements.styles";

const SingapourTemplateLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    qrcode,
    qrcodeMode,
    titleTextFont,
    subTitleTextFont,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      brightness={brightness}
      saturation={saturation}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <ContainerImg
        src={imageFirst}
        brightness={brightness < 110 ? brightness : brightness - 10}
        saturation={saturation}
      />
      {isBanner === "true" && (
        <ExcluContainer colorPrimary={color.primary || "white"}>
          <Exclu
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </Exclu>
        </ExcluContainer>
      )}
      <LogoContainer>
        {agency.images && agency.images.length ? (
          <LogoAgence src={agency.images[0].src} alt="logo agence" />
        ) : null}
      </LogoContainer>
      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <QrcodeContainer>
          <QRCode value={qrcodeEdit} size={140} />
        </QrcodeContainer>
      )}
      <RenderContext project={project}>
        <EnergyContainer
          colorPrimary={color.primary ? `${color.primary}bf` : "#ffffffbf"}
        >
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "50px"
                  : "20px",
              left:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "110px"
                  : "40px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.22"
                  : "0.25"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"light"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "90px"
                  : "5px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "145px"
                  : "120px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.52"
                  : "0.35"
              }
              theme={"light"}
            />
          </div>
          <DpeTextContainer>
            <AlurText colorDesc={colorDesc}>
              {t("modalprint.dpeMinMaxInfo")} :{" "}
              {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                <>
                  {`${t("modalprint.between")} ${project.dpeCoutMinConso}€ ${t(
                    "modalprint.and"
                  )} ${project.dpeCoutMaxConso}€ ${t("modalprint.perYear")} `}
                </>
              ) : (
                t("details.modalprint.undisclosed")
              )}{" "}
              {project.dpeCoutMinConso &&
                project.dpeCoutMinConso !== "" &&
                project.dpeAnneRefConso &&
                project.dpeAnneRefConso !== "" &&
                t("modalprint.refYear") +
                  " " +
                  project.dpeAnneRefConso +
                  " " +
                  t("modalprint.refYearEnd")}
            </AlurText>
          </DpeTextContainer>
        </EnergyContainer>
      </RenderContext>
      <InfoContainer
        colorPrimary={color.primary ? `${color.primary}bf` : "#ffffffbf"}
      >
        <InfoContainerLeft>
          <ContainerPrice>
            <InfoType
              colorTitle={colorTitle || "black"}
              size={titleTextFont.size}
              weight={titleTextFont.weight}
              family={titleTextFont.font}
            >
              {project.type && project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </InfoType>
            <InfoCity
              colorSubTitle={colorSubTitle || "black"}
              size={subTitleTextFont.size}
              weight={subTitleTextFont.weight}
              family={subTitleTextFont.font}
            >
              {subTitleText}
            </InfoCity>
            <ModulePrice
              colorPrice={colorPrice || "black"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </ContainerPrice>
        </InfoContainerLeft>
        <InfoContainerRight>
          <Ref
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            REF: {refText}
          </Ref>
          <ContainerAlur>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={"black"}
                price={project.price}
                sizeText={"12pt"}
                noDpeInfo
              />
              <AlurMore sizeText={"12pt"} colorDesc={colorDesc || "black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurMore>
            </RenderContext>
          </ContainerAlur>
        </InfoContainerRight>
      </InfoContainer>
    </Container>
  );
});

export default SingapourTemplateLandscape;
