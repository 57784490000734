import React from "react";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import {
  Container,
  ContainerTop,
  ContainerTopLeft,
  ImgBackGround,
  ContainerTopRight,
  ContainerTopTitle,
  Triange,
  Type,
  Location,
  ContainerTopRight1,
  Description,
  ContainerTopRight2,
  IconContainer,
  IconText,
  ContainerBottom,
  ContainerBottomTop,
  ContainerBottomLast,
  QrcodeContainer,
  ContainerBottomLastLeft,
  ContainerBottomLastRight,
  Ref,
  ExcluContainer,
  ExcluText,
  LogoContainer,
  LogoAgence,
} from "./BarceloneTemplatePortait.styles";
import QRCode from "react-qr-code";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { useTranslation } from "react-i18next";
import RenderContext from "../../ModulesElements/RenderContext";
import { AlurText } from "../../Modules/ModulesElements.styles";

const BarceloneTemplatePortrait = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;
  const DpeGesNoValue = ["null", "vi", "V.I", "VI"];

  const excludedValue = ["", undefined, null];

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      mirror={mirror}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <ContainerTop>
        <ContainerTopLeft>
          <ImgBackGround
            src={imageFirst}
            brightness={brightness}
            saturation={saturation}
          />
        </ContainerTopLeft>
        <ContainerTopTitle colorPrimary={color.primary || "#d82527"}>
          <div>
            <Type
              colorTitle={
                !excludedValue.includes(colorTitle) ? colorTitle : "white"
              }
              size={titleTextFont.size}
              weight={titleTextFont.weight}
              family={titleTextFont.font}
            >
              {project.type !== titleText
                ? titleText
                : choices.type.filter((item) => item.id === titleText).length
                ? t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )
                : ""}
            </Type>
            <Location
              colorSubTitle={
                !excludedValue.includes(colorSubTitle) ? colorSubTitle : "white"
              }
              size={subTitleTextFont.size}
              weight={subTitleTextFont.weight}
              family={subTitleTextFont.font}
            >
              {subTitleText}
            </Location>
          </div>
        </ContainerTopTitle>
        <Triange colorPrimary={color.primary || "#9f0e10"}></Triange>
        <ContainerTopRight>
          <LogoContainer>
            {agency.images && agency.images.length ? (
              <LogoAgence src={agency.images[0].src} alt="logo agence" />
            ) : null}
          </LogoContainer>
          <ContainerTopRight1>
            <IconContainer>
              <Surface color={colorSecondary || "black"} size={60} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.area.total} m2
              </IconText>
            </IconContainer>
            {project.rooms && project.rooms > 0 ? (
              <IconContainer>
                <Rooms color={colorSecondary || "black"} size={60} />
                <IconText colorSecondary={colorSecondary || "black"}>
                  {project.rooms} {t("modalprint.rooms")}
                </IconText>
              </IconContainer>
            ) : null}
            {project.bedrooms && project.bedrooms > 0 ? (
              <IconContainer>
                <Chambres color={colorSecondary || "black"} size={60} />
                <IconText colorSecondary={colorSecondary || "black"}>
                  {project.bedrooms} {t("modalprint.bedrooms")}
                </IconText>
              </IconContainer>
            ) : null}
            {project.sdb && project.sdb > 0 ? (
              <IconContainer>
                <Sdb color={colorSecondary || "black"} size={60} />
                <IconText colorSecondary={colorSecondary || "black"}>
                  {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
                </IconText>
              </IconContainer>
            ) : null}
          </ContainerTopRight1>
          <ContainerTopRight2 colorPrimary={color.primary || "#d82527"}>
            <ModulePrice
              colorPrice={colorPrice || "white"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "38pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </ContainerTopRight2>
        </ContainerTopRight>
        {isBanner === "true" && (
          <ExcluContainer colorPrimary={color.primary || "#d82527"}>
            <ExcluText
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </ExcluText>
          </ExcluContainer>
        )}
      </ContainerTop>
      <ContainerBottom>
        <ContainerBottomTop>
          {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
            <QrcodeContainer>
              <QRCode value={qrcodeEdit} size={140} />
            </QrcodeContainer>
          )}
          <Description
            colorDesc={colorDesc !== "" ? colorDesc : "black"}
            size={descFont.size}
            weight={descFont.weight}
            family={descFont.font}
          >
            {desc}
          </Description>
        </ContainerBottomTop>
        <ContainerBottomLast>
          <ContainerBottomLastLeft>
            <RenderContext project={project}>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "60px",
                  left: "25px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.22"
                      : "0.25"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "56px"
                      : "-15px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "295px"
                      : "330px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.50"
                      : "0.30"
                  }
                  theme={"light"}
                />
              </div>
            </RenderContext>
          </ContainerBottomLastLeft>
          <ContainerBottomLastRight>
            <Ref
              size={refFont.size}
              weight={refFont.weight}
              family={refFont.font}
              color={refFont.color}
            >
              Ref: {refText}{" "}
            </Ref>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={colorDesc || "black"}
                price={price}
                sizeText={"12pt"}
              />
              <AlurText sizeText={"12pt"} colorDesc={colorDesc || "black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurText>
            </RenderContext>
          </ContainerBottomLastRight>
        </ContainerBottomLast>
      </ContainerBottom>
    </Container>
  );
});

export default BarceloneTemplatePortrait;
