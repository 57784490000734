import React from "react";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";

const AreaGraph = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={540}
        height={105}
        data={props.data}
        margin={{
          top: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          interval="preserveStartEnd"
          dataKey="name"
          fontSize={"10"}
          color={"#5463fa"}
          axisLine={false}
          label={{ position: "insideBottomRight" }}
        />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={props.label}
          stroke="#5463fa"
          fill="#5463fab8"
          strokeWidth="4"
          tick={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaGraph;
