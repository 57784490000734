import React from "react";
import {
  DateValueContainer,
  InputContainer,
  Label,
  DateRangeContainer,
  Span,
} from "./InputDate.styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { DateRange } from "react-date-range";
import RangeSelector from "./RangeSelector";
import { useState } from "react";
import { useLocale, useTranslate } from "react-admin";
import { fr, enUS, it, es, pt, de, nl } from "react-date-range/dist/locale";

const getLanguage = (locale) => {
  if (locale.substring(0, 2) === "fr") return fr;
  else if (locale.substring(0, 2) === "en") return enUS;
  else if (locale.substring(0, 2) === "it") return it;
  else if (locale.substring(0, 2) === "es") return es;
  else if (locale.substring(0, 2) === "pt") return pt;
  else if (locale.substring(0, 2) === "de") return de;
  else if (locale.substring(0, 2) === "nl") return nl;
};

const getDate = (value) => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formatted = `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year}`;
  return formatted;
};

export const ThirdStepStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1999,
    "& .MuiPopover-paper": {
      top: "165px !important",
      left: "285px !important",
      display: "flex",
    },
  },
}));

const InputDate = ({
  dateStart,
  handleFilterDate,
  dateEnd,
  datePicker,
  setDatePicker,
  handleMultiDate,
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  today,
  yesterday,
  active,
  setActive,
}) => {
  const translate = useTranslate();
  const locale = useLocale();
  const [picker, setPicker] = useState();
  const classes = ThirdStepStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPicker(false);
    setAnchorEl(null);
  };
  return (
    <InputContainer>
      <Label>{translate("resources.data.filter")}</Label>
      <DateValueContainer onClick={handleClick}>
        <Span>
          {getDate(dateStart)} - {getDate(dateEnd)}
        </Span>
        <ArrowDropDownIcon
          style={{
            position: "absolute",
            right: "5px",
            cursor: "pointer",
            color: "rgba(0, 0, 0, 0.54)",
          }}
          onClick={handleClick}
        />
      </DateValueContainer>
      <Popover
        id={id}
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <RangeSelector
          setPicker={setPicker}
          thisWeek={thisWeek}
          lastWeek={lastWeek}
          thisMonth={thisMonth}
          lastMonth={lastMonth}
          today={today}
          yesterday={yesterday}
          setState={setDatePicker}
          handleFilterDate={handleFilterDate}
          active={active}
          setActive={setActive}
        />
        <DateRangeContainer open={picker}>
          <DateRange
            showDateDisplay={false}
            ranges={datePicker}
            onChange={handleMultiDate}
            locale={getLanguage(locale)}
          />
        </DateRangeContainer>
      </Popover>
    </InputContainer>
  );
};

export default InputDate;
