export const filterTips = (category, value, state, handleChangeFilter) => {
  if (category === "userId") {
    if (value !== "") {
      handleChangeFilter({ userId: [value] });
    } else {
      handleChangeFilter({ userId: [] });
    }
  }

  if (category === "scenes") {
    if (value.checked) {
      handleChangeFilter({ scenes: [true] });
    } else {
      handleChangeFilter({ scenes: [] });
    }
  }
  if (category === "medias") {
    if (value.checked) {
      handleChangeFilter({ medias: [true] });
    } else {
      handleChangeFilter({ medias: [] });
    }
  }
  if (category === "mediasurl") {
    if (value.checked) {
      handleChangeFilter({ mediasurl: [true] });
    } else {
      handleChangeFilter({ mediasurl: [] });
    }
  }
  if (category === "category") {
    if (value !== "") {
      handleChangeFilter({ category: [value] });
    } else {
      handleChangeFilter({ category: [] });
    }
  }
  if (category === "type") {
    if (value !== "") {
      handleChangeFilter({ type: [value] });
    } else {
      handleChangeFilter({ type: [] });
    }
  }
  if (category === "state") {
    if (value !== "") {
      handleChangeFilter({ state: [value] });
    } else {
      handleChangeFilter({ state: [] });
    }
  }

  if (category === "price") {
    if (value !== "") {
      handleChangeFilter({ price: { min: value.min, max: value.max } });
    } else {
      handleChangeFilter({ price: {} });
    }
  }
  if (category === "areamin") {
    if (value !== "") {
      handleChangeFilter({
        area: {
          min: Number(value),
          max: state.area ? (state.area.max ? state.area.max : null) : null,
        },
      });
    } else {
      handleChangeFilter({
        area: {
          min: null,
          max: state.area ? (state.area.max ? state.area.max : null) : null,
        },
      });
    }
  }

  if (category === "areamax") {
    if (value !== "") {
      handleChangeFilter({
        area: {
          min: state.area ? (state.area.min ? state.area.min : null) : null,
          max: Number(value),
        },
      });
    } else {
      handleChangeFilter({
        area: {
          min: state.area ? (state.area.min ? state.area.min : null) : null,
          max: null,
        },
      });
    }
  }

  if (category === "rooms") {
    if (value.name !== "5") {
      if (value.checked) {
        handleChangeFilter({
          rooms: {
            min: state.rooms
              ? state.rooms.min
                ? [...state.rooms.min, value.name]
                : [value.name]
              : [value.name],
            max: state.rooms ? state.rooms.max : null,
          },
        });
      } else {
        const newArray = state.rooms
          ? state.rooms.min.filter((item) => item !== value.name)
          : [];
        if (newArray.length !== 0) {
          handleChangeFilter({
            rooms: { min: [...newArray], max: state.rooms.max },
          });
        } else {
          handleChangeFilter({
            rooms: { min: null, max: state.rooms.max },
          });
        }
      }
    } else {
      let arrayToSet = [
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ];
      if (value.checked) {
        handleChangeFilter({
          rooms: {
            min: state.rooms
              ? state.rooms.min
                ? [...state.rooms.min, ...arrayToSet]
                : [...arrayToSet]
              : [...arrayToSet],
          },
        });
      } else {
        const newArray = state.rooms
          ? state.rooms.min.filter((item) => !arrayToSet.includes(item))
          : [];
        if (newArray.length !== 0) {
          handleChangeFilter({
            rooms: {
              min: [...newArray],
            },
          });
        } else {
          handleChangeFilter({
            rooms: {
              min: null,
            },
          });
        }
      }
    }
  }

  if (category === "bedrooms") {
    if (value.name !== "5") {
      if (value.checked) {
        handleChangeFilter({
          bedrooms: {
            min: state.bedrooms
              ? state.bedrooms.min
                ? [...state.bedrooms.min, Number(value.name)]
                : [Number(value.name)]
              : [Number(value.name)],
            max: state.bedrooms ? state.bedrooms.max : null,
          },
        });
      } else {
        const newArray = state.bedrooms
          ? state.bedrooms.min.filter((item) => item !== value.name)
          : [];
        if (newArray.length !== 0) {
          handleChangeFilter({
            bedrooms: {
              min: [...newArray],
              max: state.bedrooms.max,
            },
          });
        } else {
          handleChangeFilter({
            bedrooms: { min: null, max: state.bedrooms.max },
          });
        }
      }
    } else {
      let arrayToSet = [
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
      ];
      if (value.checked) {
        handleChangeFilter({
          bedrooms: {
            min: state.bedrooms
              ? state.bedrooms.min
                ? [...state.bedrooms.min, ...arrayToSet]
                : [...arrayToSet]
              : [...arrayToSet],
          },
        });
      } else {
        const newArray = state.bedrooms
          ? state.bedrooms.min.filter((item) => !arrayToSet.includes(item))
          : [];
        if (newArray.length !== 0) {
          handleChangeFilter({
            bedrooms: {
              min: [...newArray],
            },
          });
        } else {
          handleChangeFilter({
            bedrooms: {
              min: null,
            },
          });
        }
      }
    }
  }
};

export const filterData = (data, query) => {
  const priceMinMax = ["price"];
  const areaMinMax = ["area"];
  const multipleRooms = ["rooms", "bedrooms"];
  const hasScenes = ["scenes"];
  const hasMedias = ["medias"];
  const hasMediasUrl = ["mediasurl"];
  const hasUserId = ["userId"];
  const filteredData = data.filter((item) => {
    for (let key in query) {
      if (item[key] === undefined) {
        return false;
      } else if (priceMinMax.includes(key)) {
        if (query[key]["min"] !== null && item[key] < query[key]["min"]) {
          return false;
        }
        if (query[key]["max"] !== null && item[key] > query[key]["max"]) {
          return false;
        }
      } else if (areaMinMax.includes(key)) {
        if (
          query[key]["min"] !== null &&
          item[key]["total"] < query[key]["min"]
        ) {
          return false;
        }
        if (
          query[key]["max"] !== null &&
          item[key]["total"] > query[key]["max"]
        ) {
          return false;
        }
      } else if (multipleRooms.includes(key)) {
        if (
          query[key]["min"] !== null &&
          !query[key]["min"].includes(item[key])
        ) {
          return false;
        }
      } else if (hasScenes.includes(key)) {
        if (query[key] !== null && !item[key]) {
          return false;
        }
      } else if (hasMedias.includes(key)) {
        if (query[key] !== null && !item.medias.length) {
          return false;
        }
      } else if (hasMediasUrl.includes(key)) {
        if (query[key] !== null && !item.mediasurl.length) {
          return false;
        }
      } else if (hasUserId.includes(key)) {
        if (query[key] !== null && !query[key].includes(item[key])) {
          return false;
        }
      } else if (!query[key].includes(item[key])) {
        return false;
      }
    }
    return true;
  });
  return filteredData;
};

export const buildFilter = (filter) => {
  let query = {};
  for (let keys in filter) {
    if (
      filter[keys].constructor === Object ||
      (filter[keys].constructor === Array && filter[keys].length > 0)
    ) {
      query[keys] = filter[keys];
    }
  }
  return query;
};
