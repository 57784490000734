import React, { useEffect, useRef } from "react";

// Icons
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import GridOnIcon from "@material-ui/icons/GridOn";
import KingBedIcon from "@material-ui/icons/KingBed";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

// CSS
import "./index.css";
import Dpe from "../../Components/NewDpeGes/Dpe";
import Ges from "../../Components/NewDpeGes/Ges";
import { choices } from "./dataChoices";
import { useTranslation } from "react-i18next";

const DpeGesNoValue = ["null", "vi", "V.I", "VI"];

const ModalDetails = (props) => {
  const { infoProject, getAllClick } = props;
  //infoProject
  const { t } = useTranslation();

  useEffect(() => {
    function handler(event) {
      if (containerPlanRef.current?.contains(event.target)) {
        getAllClick("countClickPlan");
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [getAllClick, infoProject]);

  const containerPlanRef = useRef();

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  const getCategory = (value) => {
    const result = choices.category.filter((obj) => {
      return obj.id === value;
    });
    return result;
  };

  // const getTopGes = (letter, value, valueGes) => {
  //   if (valueGes) {
  //     return "16px";
  //   } else {
  //     if (letter || value) return "36px";
  //     else return "16px";
  //   }
  // };

  return (
    <div
      className="fade modal show"
      id="modal-details"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalDetailsLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalDetailsLabel">
              {t("sidebar.popoverDetails")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body px-0 pb-0">
            <div className="row no-gutters">
              <div className="col-12 ">
                <div className="border border-white">
                  <div
                    className="my-auto p-4"
                    style={{ maxHeight: "88vh", overflow: "scroll" }}
                  >
                    {infoProject.category && (
                      <h2 className="text-center">
                        {t(getCategory(infoProject.category)[0].name)}
                      </h2>
                    )}
                    <h5 className="mt-5 text-center">
                      {infoProject.type} - {infoProject.city}
                    </h5>
                    {infoProject.localisation ? (
                      <h5 className="text-center">
                        {infoProject.localisation}
                      </h5>
                    ) : null}
                    {!infoProject.hideprice && (
                      <h2 className="font-weight-light mt-5 text-center">
                        {getPrice(infoProject.price)}{" "}
                        {infoProject.currency ? infoProject.currency : "€"}
                        {infoProject.category === choices.category[1].id && (
                          <span
                            className="font-weight-light text-center"
                            style={{ fontSize: "20px" }}
                          >
                            {t("details.modalprint.chargespermonth")}
                          </span>
                        )}
                        {infoProject.periodicity &&
                          infoProject.category === choices.category[2].id && (
                            <span
                              className="font-weight-light text-center"
                              style={{ fontSize: "20px" }}
                            >
                              {t(
                                choices.periodicity.filter(
                                  (item) => item.id === infoProject.periodicity
                                )[0].name
                              )}
                            </span>
                          )}
                      </h2>
                    )}
                    <br />
                    {infoProject.sharedCommission ? (
                      <p className="text-center">
                        {t("details.fees.feesShared")}{" "}
                      </p>
                    ) : infoProject.commission === false ? (
                      <p className="text-center">
                        {t("details.fees.feestext")}{" "}
                        {t("details.fees.feestypeseller")}
                      </p>
                    ) : null}

                    {infoProject.category === choices.category[1].id && (
                      <p className="text-center">
                        {t("details.modalprint.monthlycharge")} :{" "}
                        {infoProject.monthlycharge > 0
                          ? `${infoProject.monthlycharge} ${
                              infoProject.currency ? infoProject.currency : "€"
                            }`
                          : t("details.modalprint.undisclosed")}{" "}
                      </p>
                    )}
                    {infoProject.category === choices.category[1].id && (
                      <p className="text-center">
                        {t("details.modalprint.fees")} :{" "}
                        {infoProject.fees > 0
                          ? `${infoProject.fees} ${
                              infoProject.currency ? infoProject.currency : "€"
                            }`
                          : t("details.modalprint.undisclosed")}{" "}
                      </p>
                    )}
                    {infoProject.commission || infoProject.sharedCommission
                      ? infoProject.category === choices.category[0].id && (
                          <p className="text-center">
                            {t("details.modalprint.commission")} :{" "}
                            {infoProject.fees !== ""
                              ? infoProject.fees +
                                (infoProject.feespercent
                                  ? " %"
                                  : ` ${
                                      infoProject.currency
                                        ? infoProject.currency
                                        : "€"
                                    }`)
                              : t("details.modalprint.undisclosed")}{" "}
                          </p>
                        )
                      : null}

                    {(infoProject.sharedCommission || infoProject.commission) &&
                    infoProject.category === choices.category[0].id &&
                    infoProject.amountWithoutHonorary ? (
                      <p className="text-center">
                        {t("details.modalprint.excludefees")} :{" "}
                        {infoProject.amountWithoutHonorary}{" "}
                        {infoProject.currency ? infoProject.currency : "€"}
                      </p>
                    ) : null}

                    {infoProject.nblotcopro > 0 && (
                      <p className="text-center">
                        {t("details.modalprint.copropriete")}{" "}
                        {infoProject.nblotcopro} {t("details.modalprint.lots")}
                      </p>
                    )}
                    {infoProject.feescopro ? (
                      <p className="text-center">
                        {t("modalprint.feescopro")} :{" "}
                        {infoProject.feescopro
                          ? infoProject.feescopro + infoProject.currency
                            ? infoProject.currency
                            : "€"
                          : t("modalprint.undisclosed")}
                      </p>
                    ) : null}
                    {infoProject.category === choices.category[1].id && (
                      <p className="text-center">
                        {t("details.modalprint.feesetatdeslieux")} :{" "}
                        {infoProject.feesetatdeslieux > 0
                          ? infoProject.feesetatdeslieux +
                            ` ${
                              infoProject.currency ? infoProject.currency : "€"
                            }`
                          : t("details.modalprint.undisclosed")}{" "}
                      </p>
                    )}
                    {infoProject.category === choices.category[1].id ? (
                      <p className="text-center">
                        {t("details.modalprint.feesdepotgarantie")} :{" "}
                        {infoProject.feesdepotgarantie > 0
                          ? infoProject.feesdepotgarantie +
                            ` ${
                              infoProject.currency ? infoProject.currency : "€"
                            }`
                          : t("details.modalprint.undisclosed")}{" "}
                      </p>
                    ) : null}
                    {infoProject.procsyndicale && (
                      <p className="text-center">
                        {t("details.modalprint.procsyndicale")} :{" "}
                        {t("details.modalprint.yes")}
                      </p>
                    )}
                    <p className="text-center">
                      {t("modalprint.dpeMinMaxInfo")} :{" "}
                      {infoProject.dpeCoutMinConso &&
                      infoProject.dpeCoutMinConso !== "" ? (
                        <>
                          {`${t("modalprint.between")} ${
                            infoProject.dpeCoutMinConso
                          }€ ${t("modalprint.and")} ${
                            infoProject.dpeCoutMaxConso
                          }€ ${t("modalprint.perYear")} `}
                        </>
                      ) : (
                        t("details.modalprint.undisclosed")
                      )}
                    </p>
                    {infoProject.dpeCoutMinConso &&
                      infoProject.dpeCoutMinConso !== "" &&
                      infoProject.dpeAnneRefConso &&
                      infoProject.dpeAnneRefConso !== "" && (
                        <p className="text-center">
                          {t("modalprint.refYear")}{" "}
                          {infoProject.dpeAnneRefConso}{" "}
                          {t("modalprint.refYearEnd")}
                        </p>
                      )}

                    <p className="mt-1 font-weight-light text-center">{`Ref: ${infoProject.reference}`}</p>

                    <div className="row no-gutters mt-5 text-center">
                      <div className="col-4">
                        <GridOnIcon />
                        <div className="mt-2 border-right border-white">
                          {infoProject.area.total} m²
                        </div>
                      </div>
                      <div className="col-4">
                        <MeetingRoomIcon />
                        <div className="mt-2 border-right border-white">
                          {infoProject.rooms}{" "}
                          {infoProject.rooms > 1
                            ? t("details.room") + "s"
                            : t("details.room")}
                        </div>
                      </div>
                      <div className="col-4">
                        <KingBedIcon />
                        <div className="mt-2">
                          {infoProject.bedrooms}{" "}
                          {infoProject.bedrooms > 1
                            ? t("details.bedroom") + "s"
                            : t("details.bedroom") + "s"}
                        </div>
                      </div>
                    </div>
                    <div className="row no-gutters mt-5 text-justify">
                      {infoProject.comments.length > 0 &&
                        infoProject.comments.map((item, idx) => (
                          <p key={idx}>{item.comments.comment}</p>
                        ))}
                    </div>
                    {/* {infoProject.dpeLetter || infoProject.dpeValue ? ( */}
                    <div
                      className="mt-3 d-flex"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          height: "fit-content",
                          position: "absolute",
                          top:
                            DpeGesNoValue.includes(infoProject.dpeLetter) ||
                            !infoProject.dpeLetter
                              ? "0px"
                              : "20px",
                          left:
                            DpeGesNoValue.includes(infoProject.dpeLetter) ||
                            !infoProject.dpeLetter
                              ? "40px"
                              : "0px",
                        }}
                      >
                        <Dpe
                          letter={
                            infoProject.dpeLetter
                              ? DpeGesNoValue.includes(infoProject.dpeLetter)
                                ? "vi"
                                : infoProject.dpeLetter
                              : "vi"
                          }
                          conso={infoProject.dpeValue}
                          emissions={infoProject.gesValue}
                          zoom={
                            DpeGesNoValue.includes(infoProject.dpeLetter) ||
                            !infoProject.dpeLetter
                              ? "0.15"
                              : "0.17"
                          }
                          topTitle={"logement extrêmement performant"}
                          bottomTitle={"logement extrêmement peu performant"}
                          redInfoTop={"passoire"}
                          redInfoBottom={"énergetique"}
                          label={"consommation"}
                          secondLabel={"émissions"}
                          subLabel={"(énergie primaire)"}
                          subValue={"kWh/m2/an"}
                          secondSubValue={"kg CO₂/m²/an"}
                          theme={"light"}
                        />
                      </div>
                      <div
                        style={{
                          height: "fit-content",
                          position: "absolute",
                          top:
                            DpeGesNoValue.includes(infoProject.gesLetter) ||
                            !infoProject.gesLetter
                              ? "26px"
                              : "28px",
                          left:
                            DpeGesNoValue.includes(infoProject.gesLetter) ||
                            !infoProject.gesLetter
                              ? "250px"
                              : "230px",
                        }}
                      >
                        <Ges
                          letter={
                            infoProject.gesLetter
                              ? DpeGesNoValue.includes(infoProject.gesLetter)
                                ? "vi"
                                : infoProject.gesLetter
                              : "vi"
                          }
                          emissions={infoProject.gesValue}
                          zoom={
                            DpeGesNoValue.includes(infoProject.gesLetter) ||
                            !infoProject.gesLetter
                              ? "0.33"
                              : "0.20"
                          }
                          theme={"light"}
                        />
                      </div>
                    </div>
                    {/* ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetails;
