export const choices = {
  abo: [
    { id: "print", name: "resources.agencies.fields.abo.print" },
    { id: "display", name: "resources.agencies.fields.abo.display" },
    { id: "agent", name: "resources.agencies.fields.abo.ads.agent" },
    { id: "agence", name: "resources.agencies.fields.abo.ads.agence" },
    { id: "business", name: "resources.agencies.fields.abo.ads.business" },
    { id: "teams", name: "resources.agencies.fields.abo.ads.teams" },
    { id: "leader", name: "resources.agencies.fields.abo.ads.leader" },
  ],
  state: [
    { id: "active", name: "resources.agencies.fields.abo.state.active" },
    { id: "waiting", name: "resources.agencies.fields.abo.state.waiting" },
    { id: "inactive", name: "resources.agencies.fields.abo.state.inactive" },
    { id: "trial", name: "resources.agencies.fields.abo.state.trial" },
  ],
  lang: [
    { id: "fr-FR", name: "FR" },
    { id: "en-US", name: "EN" },
    { id: "notaire", name: "NOTAIRE" },
  ],
  socialnetworks: [
    { id: "facebook", name: "Facebook" },
    { id: "instagram", name: "Instagram" },
    { id: "youtube", name: "Youtube" },
    { id: "twitter", name: "Twitter" },
    { id: "whatsapp", name: "WhatsApp" },
    { id: "skype", name: "Skype" },
    { id: "linkedin", name: "LinkedIn" },
  ],
  externalApi: [
    { id: "vmdisplay", name: "VM Display +" },
    { id: "vmlive", name: "VM Live" },
  ],
};
