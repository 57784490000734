import styled from "styled-components";

export const Container = styled.div`
  && {
    height: 297mm;
    min-height: 297mm;
    max-height: 297mm;
    width: 210mm;
    min-width: 210mm;
    max-width: 210mm;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;

    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A4 portrait;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      /* size: auto; */
      margin: 20mm;
    }
  }
`;
export const TopContainer = styled.div`
  padding: 10px 25px 10px 20px;
  display: flex;
  align-items: center;
`;
export const AgencyLogo = styled.img`
  width: auto;
  height: 60px;
`;
export const AgencyNameText = styled.p`
  font-size: 19pt;
  margin: 0;
  font-weight: 600;
  margin-left: auto;
`;
export const HeroContainer = styled.div`
  width: 100%;
  height: 36%;
  position: relative;
`;
export const ImageHero = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CategoryContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f3f4;
  border-top-right-radius: 27px;
  min-width: 200px;
  height: 90px;
  padding: 0 25px;
`;

export const CategoryText = styled.p`
  margin: 0;
  font-size: 20pt;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
`;

export const LogoContainer = styled.div`
  background-color: #f2f3f4;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

export const BottomContainer = styled.div`
  display: flex;
  height: 43%;
`;
export const BottomInnerContainer = styled.div`
  position: relative;
  width: 50%;
  padding: 25px 25px 0;
  display: flex;
  flex-direction: column;
`;
export const TypeText = styled.p`
  font-size: 25.3pt;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
`;
export const CityText = styled.p`
  font-size: 19.2pt;
  font-weight: 500;
  text-transform: uppercase;
`;
export const Description = styled.p`
  font-size: 11.1pt;
  font-weight: 400;
  margin-top: 10px;
  word-break: break-all;
`;
export const ContactText = styled.p`
  font-size: 19pt;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const LabelContact = styled.p`
  font-size: 11pt;
  font-weight: 500;
  margin-bottom: 0;
`;
export const RefText = styled.p`
  font-size: 19pt;
  font-weight: 500;
  text-transform: uppercase;
`;

export const AlurContainer = styled.div`
  padding-top: 10px;
`;
export const DpeGesContainer = styled.div``;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconText = styled.p`
  font-size: 11.5pt;
  color: black;
  margin-bottom: 0;
  margin-left: 15px;
`;
export const DpeTextContainer = styled.div`
  position: absolute;
  bottom: -15px;
  left: 30px;
  max-width: 350px;
`;
export const AlurText = styled.p`
  font-size: 10.3pt;
  font-weight: initial;
  color: black;
  margin-bottom: 0;
  margin-top: 0;
`;
