import React from "react";
import {
  getDataAgency,
  getDataProject,
  postDataProject,
} from "../../utils/axiosRequest";

const LeadSmallGridWrapper = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        data: [],
      };
    }

    getProjectLinkToLeads = async (data) => {
      const awaitData = data.offer.map(async (offer) => {
        const fetch = await getDataProject(offer.projectId, data.agenceId);
        const fetchAgency = await getDataAgency(data.agenceId);
        this.setState((prev) => ({
          data: [
            ...prev.data,
            {
              project: fetch.data.data[0],
              offer: offer,
              agency: fetchAgency.data,
            },
          ],
        }));
      });
      return Promise.all(awaitData).then((completed) => {
        return true;
      });
    };

    sendCode = async (data) => {
      const code = Math.floor(1000 + Math.random() * 9000);
      const udpateProject = await postDataProject(data, code, this.props.data);
      return udpateProject;
    };

    async componentDidMount() {
      const getData = await this.getProjectLinkToLeads(this.props.data);
      if (getData) {
        this.setState({ loading: false });
      }
    }

    render() {
      if (this.state.loading) {
        return <h1>...</h1>;
      } else {
        return (
          <WrappedComponent
            {...this.props}
            data={this.state.data}
            sendCode={this.sendCode}
            dataSupp={this.props.data}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
};

export default LeadSmallGridWrapper;
