import React from "react";
import { TextValue } from "../../../../../../../Utils/Styles/BillingInformation.styles";

const Stripe = (props) => {
  const type = props.record.billingInformation.billingMethod.stripe.type;
  const last4 = props.record.billingInformation.billingMethod.stripe.last4;
  const month = props.record.billingInformation.billingMethod.stripe.expMonth;
  const year = props.record.billingInformation.billingMethod.stripe.expYear;
  return (
    <>
      {!props.noDivSpace && <div style={{ height: "15px" }} />}
      <TextValue>{type}</TextValue>

      <TextValue>
        <span>**** **** ****</span> {last4}
      </TextValue>
      <TextValue>
        {month >= 10 ? month : "0" + month} / {year}
      </TextValue>
    </>
  );
};

export default Stripe;
