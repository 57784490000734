import React from "react";
import { RangeItem, RangeSelectorContainer } from "./RangeSelector.styles";
import { useTranslate } from "react-admin";

const RangeSelector = ({
  setState,
  handleFilterDate,
  setPicker,
  today,
  yesterday,
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  active,
  setActive,
}) => {
  const translate = useTranslate();
  const handleRange = (value, range) => {
    setActive(value);
    if (value !== "perso") {
      setState((prev) => [
        {
          ...prev[0],
          startDate: range.start,
          endDate: range.end,
        },
      ]);
      handleFilterDate("min", range.start.toISOString());
      handleFilterDate("max", range.end.toISOString());
    } else {
      setPicker(true);
    }
  };

  return (
    <RangeSelectorContainer>
      <RangeItem
        onClick={() => handleRange("today", today)}
        active={active === "today"}
      >
        {translate("resources.data.today")}
      </RangeItem>
      <RangeItem
        onClick={() => handleRange("yesterday", yesterday)}
        active={active === "yesterday"}
      >
        {translate("resources.data.yesterday")}
      </RangeItem>
      <RangeItem
        onClick={() => handleRange("thisWeek", thisWeek)}
        active={active === "thisWeek"}
      >
        {translate("resources.data.thisWeek")}
      </RangeItem>
      <RangeItem
        onClick={() => handleRange("lastWeek", lastWeek)}
        active={active === "lastWeek"}
      >
        {translate("resources.data.lastWeek")}
      </RangeItem>
      <RangeItem
        onClick={() => handleRange("thisMonth", thisMonth)}
        active={active === "thisMonth"}
      >
        {translate("resources.data.thismonth")}
      </RangeItem>
      <RangeItem
        onClick={() => handleRange("lastMonth", lastMonth)}
        active={active === "lastMonth"}
      >
        {translate("resources.data.lastmonth")}
      </RangeItem>
      <RangeItem
        onClick={() => handleRange("perso")}
        active={active === "perso"}
      >
        {translate("resources.data.custom")}
      </RangeItem>
    </RangeSelectorContainer>
  );
};

export default RangeSelector;
