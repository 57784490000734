export const getCamera = (data, camera) => {
  if (camera !== "") {
    const array = data.filter((item) => {
      return item.cameraId === camera;
    });
    return array;
  } else {
    return data;
  }
};
export const getTraffic = (data) => {
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count = count + data[i].bodyCount + data[i].faceCount;
  }
  return count;
};

export const getDateFilter = (filterDate, data) => {
  const getDataEndActual = () => {
    const start = new Date();
    var lastDay = new Date(start.getFullYear(), start.getMonth() + 1, 0);
    return lastDay;
  };
  const dateStart = new Date(filterDate.min);
  const dateEnd = filterDate.max
    ? new Date(filterDate.max)
    : getDataEndActual();
  dateStart.setHours(0, 0, 0, 0);
  dateEnd.setHours(0, 0, 0, 0);
  dateEnd.setDate(dateEnd.getDate() + 1);

  const date = data.filter((item) => {
    const itemDate = new Date(item.date);
    return itemDate.getTime() >= dateStart.getTime() &&
      itemDate.getTime() <= dateEnd
      ? dateEnd.getTime()
      : null;
  });
  return date;
};

export const getViewAudience = (data) => {
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count =
      count +
      data[i].face.duration5Count +
      data[i].face.duration10Count +
      data[i].face.duration20Count +
      data[i].face.duration30Count +
      data[i].face.duration60Count +
      data[i].face.duration180Count +
      data[i].face.duration300Count;
  }
  return count;
};
export const getAudienceByAge = (data) => {
  let first = 0;
  let second = 0;
  let third = 0;
  let fourth = 0;
  let fifth = 0;
  for (let i = 0; i < data.length; i++) {
    first = first + data[i].face.age18Count;
    second = second + data[i].face.age30Count;
    third = third + data[i].face.age40Count;
    fourth = fourth + data[i].face.age50Count;
    fifth = fifth + data[i].face.age60Count;
  }
  return [
    { name: "-20", values: first },
    { name: "25 - 35", values: second },
    { name: "35 - 45", values: third },
    { name: "45 - 55", values: fourth },
    { name: "55 +", values: fifth },
  ];
};

export const getMiddleTime = (data, audience) => {
  let time = 0;
  for (let i = 0; i < data.length; i++) {
    time = time + 5 * data[i].face.duration5Count;
    time = time + 10 * data[i].face.duration10Count;
    time = time + 20 * data[i].face.duration20Count;
    time = time + 30 * data[i].face.duration30Count;
    time = time + 60 * data[i].face.duration60Count;
    time = time + 180 * data[i].face.duration180Count;
    time = time + 300 * data[i].face.duration300Count;
  }
  return time !== 0 ? time / audience : 0;
};

export const getLeads = (data) => {
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count = count + data[i].face.duration20Count;
    count = count + data[i].face.duration30Count;
    count = count + data[i].face.duration60Count;
    count = count + data[i].face.duration180Count;
    count = count + data[i].face.duration300Count;
  }
  return count;
};
export const getInterested = (data) => {
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    count = count + data[i].face.duration5Count;
    count = count + data[i].face.duration10Count;
  }
  return count;
};

export const getInterestedPourcent = (data) => {
  const value = data.filter((item) => {
    return (
      item.type === "face" &&
      Number(item.first_position_z) >= 0 &&
      Number(item.first_position_z) <= 3 &&
      Number(item.visualisation_time) >= 5 &&
      Number(item.visualisation_time) <= 10
    );
  });
  if (value.length === 0) {
    return 0;
  } else {
    return (value.length / data.length) * 100;
  }
};
export const getTouchPourcent = (data, traffic) => {
  // console.log(value);
  if (data === 0) {
    return 0;
  } else if (traffic === 0) {
    return 100;
  } else {
    return (data / traffic) * 100;
  }
};
// data for recharts

export const getTrafficChart = (data, translate) => {
  const dataToSendTraffic = [];
  for (let i = 0; i < data.length; i++) {
    const date = new Date(data[i].date);
    date.setHours(0, 0, 0, 0);
    const day = date.getDate();
    const month = date.getMonth();
    const object = {
      name: day + "/" + (month + 1),
      Valeur: data[i].bodyCount + data[i].faceCount,
      more: date.getDay(),
      fullDate: date,
    };
    dataToSendTraffic.push(object);
  }
  return dataToSendTraffic;
};
export const getTrafficHourChart = (data) => {
  const sortedTraffic = data.reduce((acc, item) => {
    const date = new Date(item.first_detection);
    const hour = date.getHours();
    const fullTime = `${hour < 10 ? "0" + hour : hour}h00}`;
    (acc[fullTime] || (acc[fullTime] = [])).push(item);
    return acc;
  }, {});

  const dataToSendTraffic = [];

  for (let i = 0; i < data.length; i++) {
    const date = new Date(Object.values(sortedTraffic)[i][0].first_detection);
    const hour = date.getHours();
    const number = Object.values(sortedTraffic)[i].length;

    dataToSendTraffic.push({
      name: `${hour < 10 ? "0" + hour : hour}h00`,
      Valeur: number,
      more: date,
    });
  }
  return dataToSendTraffic;
};

export const getAudienceChart = (data) => {
  const dataToSend = [];
  for (let i = 0; i < data.length; i++) {
    const date = new Date(data[i].date);
    date.setHours(0, 0, 0, 0);
    const day = date.getDate();
    const month = date.getMonth();
    const object = {
      name: `${day < 10 ? "0" + day : day}/${month + 1}`,
      Valeur:
        data[i].face.duration5Count +
        data[i].face.duration10Count +
        data[i].face.duration20Count +
        data[i].face.duration30Count +
        data[i].face.duration60Count +
        data[i].face.duration180Count +
        data[i].face.duration300Count,
      more: date.getDay(),
      fullDate: date,
    };
    dataToSend.push(object);
  }
  return dataToSend;
};

export const getTrafficBestDay = (traffiChart) => {
  const max = traffiChart.length
    ? traffiChart.reduce((prev, current) =>
        prev.Valeur > current.Valeur ? prev : current
      )
    : null;

  const bestDay = () => {
    if (max && max.more === 0) {
      return "Dimanche";
    }
    if (max && max.more === 1) {
      return "Lundi";
    }
    if (max && max.more === 2) {
      return "Mardi";
    }
    if (max && max.more === 3) {
      return "Mercredi";
    }
    if (max && max.more === 4) {
      return "Jeudi";
    }
    if (max && max.more === 5) {
      return "Vendredi";
    }
    if (max && max.more === 6) {
      return "Samedi";
    } else return "";
  };
  return bestDay;
};

export const getAudienceGenderMiddle = (data) => {
  let totalHommes = 0;
  let totalFemmes = 0;

  for (let i = 0; i < data.length; i++) {
    totalHommes = totalHommes + data[i].face.manCount;
    totalFemmes = totalFemmes + data[i].face.womanCount;
  }
  const total = totalHommes + totalFemmes;
  const pourcentHomme = (totalHommes / total) * 100;
  const pourcentFemmes = (totalFemmes / total) * 100;

  return {
    hommes: data.length ? pourcentHomme : 0,
    femmes: data.length ? pourcentFemmes : 0,
  };
};

export const getAudienceMiddle = (audienceChart) => {
  let total = 0;
  audienceChart.map((item) => {
    total = total + item.Valeur;
    return item;
  });
  const middle = total !== 0 ? total / audienceChart.length : 0;
  return Math.floor(middle);
};
