/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

const useLoading = (props, setScenes, setCount) => {
  const [loadingTexture, setLoadingTexture] = useState("false");
  const [loadingTransition, setLoadingTransition] = useState(false);

  useEffect(() => {
    if (props.scenes) {
      const udpateCurrent = props.scenes.map((item, idx) => {
        if (idx === 0) {
          item.current = true;
        } else {
          item.current = false;
        }
        return item;
      });
      setScenes(udpateCurrent);
      if (props.autorotate) {
        let current = 1;
        const length = props.scenes.length - 1;
        setInterval(() => {
          setLoadingTransition(true);
          const udpateCurrent = props.scenes.map((item, idx) => {
            if (idx === current) {
              item.current = true;
            } else {
              item.current = false;
            }
            return item;
          });
          setCount(1);
          setScenes(udpateCurrent);
          current++;
          if (current > length) current = 0;
          setTimeout(() => {
            setLoadingTransition(false);
          }, 200);
        }, 20000);
      }
    }
  }, [props, setCount, setScenes]);
  return {
    loadingTransition,
    loadingTexture,
    setLoadingTransition,
  };
};

export default useLoading;
