import styled from "styled-components";

export const StatisticsBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  background-color: transparent;
  transition: all 0.3s ease;
  grid-gap: 30px;
  justify-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    margin: auto;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: auto;
  }
`;

export const BoxContent = styled.div`
  width: 230px;
  background-color: white;
  transition: all 0.2s ease;
  animation: show 0.2s ease forwards;
  line-height: 2;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: 0.2s ease;
  padding: 2rem 0;
  border: 1px solid #9b9b9b66;

  @media screen and (max-width: 600px) {
    min-width: 300px;
    max-width: 400px;
  }
`;
export const BoxTitleContainer = styled.div``;

export const BoxTitle = styled.p`
  font-weight: 300;
  color: #34495ed6;
  margin-bottom: 0.2rem;
  text-align: center;
`;
export const BoxNumberContainer = styled.div``;

export const BoxNumber = styled.h1`
  font-weight: 700;
  color: #34495e;
  text-align: center;
`;
