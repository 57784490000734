export const aboText = [
  {
    text: "resources.agencies.fields.aboList.print",
    index: 0,
    name: "print",
    popupIndex: 0,
    id: "print",
  },
  {
    text: "resources.agencies.fields.aboList.printinclude",
    index: 1,
    name: "print-include",
    popupIndex: 0,
    id: "print",
  },
  {
    text: "resources.agencies.fields.aboList.display",
    index: 2,
    name: "display",
    popupIndex: 1,
    id: "display",
  },
  {
    text: "resources.agencies.fields.aboList.display",
    index: 3,
    name: "data",
    popupIndex: 2,
    id: "data",
  },
];
export const adsText = [
  {
    text: "resources.agencies.fields.aboList.ads.agent",
    index: 0,
    price: 49,
    popupIndex: 3,
    id: "ads",
    name: "agent",
  },
  {
    text: "resources.agencies.fields.aboList.ads.agence",
    index: 1,
    price: 59,
    popupIndex: 4,
    id: "ads",
    name: "agence",
  },
  {
    text: "resources.agencies.fields.aboList.ads.business",
    index: 2,
    price: 79,
    popupIndex: 5,
    id: "ads",
    name: "business",
  },
  {
    text: "resources.agencies.fields.aboList.ads.team",
    index: 3,
    price: 129,
    popupIndex: 6,
    id: "ads",
    name: "team",
  },
  {
    text: "resources.agencies.fields.aboList.ads.leader",
    index: 4,
    price: 299,
    popupIndex: 7,
    id: "ads",
    name: "leader",
  },
];
