import { config } from "../../../../constants/constant";
import axios from "axios";

export const getDataProject = async (query, queryValue) => {
  const url =
    config.API_BASE +
    "/projects?%24limit=-1&%24sort%5BcreatedAt%5D=-1&" +
    query +
    "=" +
    queryValue;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "projectList",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getUserData = async (id) => {
  const params = {
    method: "GET",
    url: config.API_BASE + "/users/" + id,
    headers: {
      Authorization: "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getDataLike = async (query, queryValue, likeValue) => {
  const filter =
    "%24or%5B0%5D%5Bcity%5D%5B%24options%5D=igm&%24or%5B0%5D%5Bcity%5D%5B%24regex%5D=" +
    likeValue +
    "&%24or%5B1%5D%5Btype%5D%5B%24options%5D=igm&%24or%5B1%5D%5Btype%5D%5B%24regex%5D=" +
    likeValue +
    "&%24or%5B2%5D%5Breference%5D%5B%24options%5D=igm&%24or%5B2%5D%5Breference%5D%5B%24regex%5D=" +
    likeValue +
    "&%24or%5B3%5D%5Bpostalcode%5D%5B%24options%5D=igm&%24or%5B3%5D%5Bpostalcode%5D%5B%24regex%5D=" +
    likeValue +
    "&%24or%5B4%5D%5Blocation%5D%5B%24options%5D=igm&%24or%5B4%5D%5Blocation%5D%5B%24regex%5D=" +
    likeValue +
    "&%24or%5B5%5D%5Bcategory%5D%5B%24options%5D=igm&%24or%5B5%5D%5Bcategory%5D%5B%24regex%5D=" +
    likeValue +
    "&%24or%5B6%5D%5Bstatemandat%5D%5B%24options%5D=igm&%24or%5B6%5D%5Bstatemandat%5D%5B%24regex%5D=" +
    likeValue;

  // "&%24limit=-1";

  const url =
    config.API_BASE + "/projects?" + filter + "&" + query + "=" + queryValue;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const deleteProject = async (query) => {
  const url = config.API_BASE + "/projects/" + query;
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);

  const params = {
    method: "DELETE",
    url,
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getLeadsData = async (agenceId) => {
  const url = config.API_BASE + "/contacts?%24limit=-1&agenceId=" + agenceId;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const getDataAgency = async (agenceId) => {
  const url = config.API_BASE + "/agencies/" + agenceId;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const update360Projects = async (projectId) => {
  const url = config.API_BASE + "/projects/" + projectId;
  const params = {
    method: "POST",
    url,
    data: { scenes: [] },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
