import React from "react";
import { useTranslate } from "react-admin";
import {
  ButtonContainer,
  ActionBtn,
} from "../../../../../../Utils/Styles/BillingInformation.styles";

import IBAN from "iban";

const ActionContainer = (props) => {
  const translate = useTranslate();

  const isDisabled = () => {
    if (
      props.state.rib &&
      props.state.rib.iban !== "" &&
      props.state.rib.bic !== "" &&
      IBAN.isValid(props.state.rib.iban) &&
      props.state.mode !== "stripe"
    ) {
      return false;
    } else if (props.state.mode !== "debit" && props.state.stripe.active) {
      return false;
    } else if (props.stripeDelete) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ButtonContainer>
      <ActionBtn right="true" onClick={() => props.handleClose()}>
        {translate("resources.fields.cancel")}
      </ActionBtn>
      <ActionBtn
        active="true"
        onClick={() => props.saveAddress()}
        disabled={isDisabled()}
      >
        {translate("resources.fields.save")}
      </ActionBtn>
    </ButtonContainer>
  );
};

export default ActionContainer;
