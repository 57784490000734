import React from "react";
import { useTranslate } from "react-admin";
import {
  TitleNumberOfUser,
  Container,
  ValueContainer,
  NumberValueContainer,
  ValueText,
} from "../../Utils/Styles/ItemManager.styles";

const ModuleFree = (props) => {
  const translate = useTranslate();
  return (
    <Container>
      <NumberValueContainer width={"true"}>
        <TitleNumberOfUser></TitleNumberOfUser>
        <ValueContainer
          margin="false"
          background={"true"}
          cursor="true"
          column="true"
        >
          <ValueText mycolor={"true"}>{"VISIBBLE DISPLAY"}</ValueText>
          {props.name === "display" && (
            <ValueText mycolor={"true"} small="true">
              {translate("resources.agencies.maintenance")}
            </ValueText>
          )}
        </ValueContainer>
      </NumberValueContainer>
    </Container>
  );
};

export default ModuleFree;
