/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next/";
import { config } from "../constants/constant";

const validDomain = ["vitrinemedia", "marchello"];

const useData = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(false);
  const [userChange, setUserChange] = useState(false);
  const [ui, setUi] = useState({
    project: {
      modeView: false,
      modeViewPage: 0,
      rowsPerPage: 25,
    },
    sideBarVisibble: true,
  });
  const [agency, setAgency] = useState(false);
  const [type, setType] = useState(false);
  const valueUi = useMemo(() => ({ ui, setUi }), [ui, setUi]);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const valueChange = useMemo(() => ({ userChange, setUserChange }), [
    userChange,
    setUserChange,
  ]);
  const agencyValue = useMemo(() => ({ agency, setAgency }), [
    agency,
    setAgency,
  ]);
  const typeValue = useMemo(() => ({ type, setType }), [type, setType]);
  const [loading, setLoading] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [locale, setLocale] = useState("fr-FR");

  useEffect(() => {
    const authenticate = async (payload) => {
      const url = config.API_BASE + "/authentication";
      const params = {
        method: "POST",
        url,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        const response = await axios(params);
        if (response.status === 200 || response.status === 201) {
          if (
            response.data.user.email.includes("vitrinemedia") ||
            response.data.user.email.includes("marchello")
          ) {
            if (response.data.user.isVerified) {
              if (response.data.user.isActivated) {
                response.data.user.accessToken = response.data.accessToken;
                const data = response.data.user;
                window.localStorage.setItem(
                  config.ACCESS_TOKEN_NAME,
                  response.data.accessToken
                );
                return data;
              } else {
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
            return false;
          }
        }
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    async function whenReloadAuth() {
      if (window.localStorage && !user) {
        const token = window.localStorage.getItem(config.ACCESS_TOKEN_NAME);
        if (token) {
          const payload = { strategy: "jwt", accessToken: token };
          const test = await authenticate(payload);
          if (test) {
            if (test.isActivated && test.isVerified) {
              setUser(test);
              setIsLogged(true);
              setLoading(false);
            } else if (test.error) {
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
        }
      } else if (user) {
        setIsLogged(true);
        setLoading(false);
      } else if (!window.localStorage && !user) {
        setLoading(false);
      }
    }
    if (user) {
      setIsLogged(true);
      setLoading(false);
    } else {
      whenReloadAuth();
    }
  }, [i18n, user]);

  return {
    user,
    setUser,
    valueUi,
    typeValue,
    value,
    valueChange,
    agencyValue,
    loading,
    setLoading,
    isLogged,
    setIsLogged,
    locale,
    setLocale,
  };
};

export default useData;
