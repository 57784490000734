export const dateOptions = [
  { value: 5, label: "offer.offertime.five" },
  { value: 10, label: "offer.offertime.ten" },
];
export const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: state.isFocused ? "1px solid #ced4da !important" : "",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "blue" : "black",
    backgroundColor: "white",
  }),
};

export const conditionsText = [
  "offer.conditionschoices.first",
  "offer.conditionschoices.second",
  "offer.conditionschoices.third",
  "offer.conditionschoices.fourth",
];

export const financialText = [
  "offer.fundingchoices.first",
  "offer.fundingchoices.second",
  "offer.fundingchoices.third",
];
