const exclude = ["Berlin", "Singapour"];

export const disabledAction = (
  step,
  orientation,
  model,
  imageFirst,
  imageSupp
) => {
  if (step === 1 && orientation === false) return true;
  else if (step === 2 && model === false) return true;
  else if (step === 3 && imageFirst === false) return true;
  else if (step === 3 && !exclude.includes(model) && imageSupp.length === 0)
    return true;
  else return false;
};
