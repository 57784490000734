import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import AreaGarden from "../../../../../assets/LogoTemplates/AreaGarden";
import Terrace from "../../../../../assets/LogoTemplates/Terrace";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import QRCode from "react-qr-code";

import {
  AlurContainer,
  AlurText,
  BackgroundImage,
  BottomContainer,
  BottomLastContainer,
  Container,
  CoproContainer,
  DpeContainer,
  DpeText,
  EnergyTextContainer,
  Footer,
  Honoraire,
  HonoraryContainer,
  IconContainer,
  IconsContainer,
  IconText,
  Price,
  PriceContainer,
  QrcodeContainer,
  SecondTitle,
  SoldBanner,
  SoldBannerText,
  TitleContainer,
  TitleText,
} from "./styles";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";

const MadridLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    bannerFont,
    mirror,
    banner,
    titleText2,
    titleTextFont,
    colorTitle2,
    color,
    project,
    colorTitle,
    titleText,
    subTitleText,
    colorPrice,
    price,
    priceFont,
    refFont,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  const [currency] = useState(project.currency ? project.currency : "€");

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <BackgroundImage
        src={imageFirst}
        brightness={brightness}
        saturation={saturation}
      />
      {banner && banner.length ? (
        <SoldBanner colorPrimary={color.primary}>
          <SoldBannerText
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </SoldBannerText>
        </SoldBanner>
      ) : null}
      <Footer />
      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <QrcodeContainer>
          <QRCode value={qrcodeEdit} size={120} />
        </QrcodeContainer>
      )}

      <RenderContext project={project}>
        <DpeContainer>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top: "-5px",
              left:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "22px"
                  : "-5px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.16"
                  : "0.18"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"light"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0px"
                  : "-53px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "225px"
                  : "235px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.4"
                  : "0.22"
              }
              theme={"light"}
            />
          </div>
        </DpeContainer>
        <EnergyTextContainer>
          <DpeText>
            {t("modalprint.dpeMinMaxInfo")} :{" "}
            {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
              <>
                {`${t("modalprint.between")} ${project.dpeCoutMinConso}€ ${t(
                  "modalprint.and"
                )} ${project.dpeCoutMaxConso}€ ${t("modalprint.perYear")} `}
              </>
            ) : (
              t("details.modalprint.undisclosed")
            )}
            {project.dpeCoutMinConso &&
              project.dpeCoutMinConso !== "" &&
              project.dpeAnneRefConso &&
              project.dpeAnneRefConso !== "" &&
              `${t("modalprint.refYear")} ${project.dpeAnneRefConso}
                ${t("modalprint.refYearEnd")}`}
          </DpeText>
        </EnergyTextContainer>
      </RenderContext>
      <BottomContainer>
        <TitleContainer>
          <TitleText
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
            colorTitle={colorTitle || "black"}
          >
            {project.type !== titleText
              ? titleText
              : t(
                  choices.type.filter((item) => item.id === titleText)[0].name
                )}{" "}
            {subTitleText}
          </TitleText>
          <SecondTitle colorTitle2={colorTitle2}>{titleText2}</SecondTitle>
        </TitleContainer>
        <IconsContainer>
          <IconContainer>
            <Surface color={colorSecondary || "black"} size={30} />
            <IconText>Superficie : {project.area.total} m2</IconText>
          </IconContainer>
          {project.gardenArea && project.gardenArea !== "" ? (
            <IconContainer>
              <AreaGarden color={colorSecondary || "black"} size={30} />
              <IconText>Terrain : {project.gardenArea} m2</IconText>
            </IconContainer>
          ) : null}
          {project.rooms && project.rooms > 0 ? (
            <IconContainer>
              <Rooms color={colorSecondary || "black"} size={30} />
              <IconText>
                {t("modalprint.rooms")} : {project.rooms}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer>
              <Chambres color={colorSecondary || "black"} size={30} />
              <IconText>
                {t("modalprint.bedrooms")} : {project.bedrooms}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer>
              <Sdb color={colorSecondary || "black"} size={30} />
              <IconText>
                {t("modalprint.sdb")} : {project.sdb}
              </IconText>
            </IconContainer>
          ) : null}
          {project.terrace && project.terrace > 0 ? (
            <IconContainer>
              <Terrace color={colorSecondary || "black"} size={30} />
              <IconText>{t("modalprint.terrace")}</IconText>
            </IconContainer>
          ) : null}
        </IconsContainer>
        <BottomLastContainer>
          <PriceContainer>
            <Price
              colorPrice={colorPrice || "black"}
              size={priceFont.size !== "" ? `${priceFont.size}px` : "30px"}
              weight={priceFont.weight || 600}
              family={priceFont.family || "'Lato', sans-serif !important"}
            >
              PRIX :{project.hideprice && t("modalprint.consult")}
              {!project.hideprice &&
                `${
                  project.category === choices.category[2].id
                    ? t("details.modalprint.startAt")
                    : ""
                } ${getPrice(price)} ${
                  project.currency ? project.currency : "€"
                }`}{" "}
              {project.category === choices.category[1].id && !project.hideprice
                ? " " + t("modalprint.chargespermonth")
                : ""}
              {project.periodicity &&
                project.category === choices.category[2].id &&
                " " +
                  t(
                    choices.periodicity.filter(
                      (item) => item.id === project.periodicity
                    )[0].name
                  )}
            </Price>
            {project.category === choices.category[0].id &&
            !project.hideprice ? (
              <Honoraire
                weight={priceFont.weight || 400}
                size={
                  priceFont.size !== "" ? `${priceFont.size - 10}px` : "14px"
                }
                family={priceFont.family || "'Lato', sans-serif !important"}
                colorPrice={colorPrice || "black"}
              >
                {project.sharedCommission
                  ? t("details.fees.feesShared")
                  : project.commission
                  ? "Honoraires TTC à la charge de l'acheteur"
                  : "Honoraires TTC à la charge du vendeur"}
              </Honoraire>
            ) : null}
            <Honoraire
              size={refFont.size || "18px"}
              weight={refFont.weight || 600}
              family={refFont.font || "'Open Sans' sans-serif"}
              color={refFont.color || "black"}
            >
              N° Mandat: {project.reference}
            </Honoraire>
            <div style={{ maxWidth: "220px" }}>
              <AlurText sizeText={"12pt"} colorDesc={"black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurText>
            </div>
          </PriceContainer>
          <AlurContainer>
            <HonoraryContainer>
              {project.category === choices.category[1].id ? (
                <AlurText>
                  {t("details.modalprint.monthlycharge")} :{" "}
                  {project.monthlycharge > 0
                    ? project.monthlycharge + " " + currency
                    : t("modalprint.undisclosed")}{" "}
                </AlurText>
              ) : null}
              {project.category === choices.category[1].id ? (
                <AlurText>
                  Honoraires à la charge du locataire :{" "}
                  {project.fees > 0
                    ? project.fees + " " + currency
                    : t("modalprint.undisclosed")}{" "}
                </AlurText>
              ) : null}
              {project.category === choices.category[1].id ? (
                <AlurText>
                  {t("modalprint.feesetatdeslieux")} :{" "}
                  {project.feesetatdeslieux > 0
                    ? project.feesetatdeslieux + " " + currency
                    : t("modalprint.undisclosed")}{" "}
                </AlurText>
              ) : null}
              {project.category === choices.category[1].id ? (
                <AlurText>
                  {t("details.modalprint.feesdepotgarantie")} :{" "}
                  {project.feesdepotgarantie > 0
                    ? project.feesdepotgarantie + " " + currency
                    : t("modalprint.undisclosed")}{" "}
                </AlurText>
              ) : null}
              {(project.commission || project.sharedCommission) &&
              project.category === choices.category[0].id ? (
                <AlurText>
                  Honoraires TTC:{" "}
                  {project.fees !== ""
                    ? project.fees +
                      (project.feespercent ? " %" : " " + currency)
                    : t("modalprint.undisclosed")}{" "}
                </AlurText>
              ) : null}
              {(project.sharedCommission || project.commission) &&
              project.category === choices.category[0].id &&
              project.amountWithoutHonorary ? (
                <AlurText>
                  Prix hors honoraires :{" "}
                  {getPrice(project.amountWithoutHonorary)} {currency}
                </AlurText>
              ) : null}
            </HonoraryContainer>
            <CoproContainer>
              {project.nblotcopro > 0 ? (
                <>
                  <AlurText>
                    {t("modalprint.copropriete")} {project.nblotcopro}{" "}
                    {t("modalprint.lots")}
                  </AlurText>
                  <AlurText>
                    {t("modalprint.feescopro")} :{" "}
                    {project.feescopro
                      ? project.feescopro + currency
                      : t("modalprint.undisclosed")}
                  </AlurText>
                  {project.category === choices.category[0].id &&
                    project.type !== choices.type[1].id &&
                    project.type !== choices.type[2].id &&
                    project.type !== choices.type[3].id && (
                      <AlurText>
                        {project.procsyndicale
                          ? "Procédure syndicale en cours"
                          : "Pas de procédure en cours"}
                      </AlurText>
                    )}
                </>
              ) : null}
            </CoproContainer>
          </AlurContainer>
        </BottomLastContainer>
      </BottomContainer>
    </Container>
  );
});

export default MadridLandscape;
