/* eslint-disable no-unused-vars */
import React from "react";

import BarceloneTemplatePortrait from "../../TemplatePrint/Barcelone/BarceloneTemplatePortrait/BarceloneTemplatePortrait";
import BarceloneTemplateLandscape from "../../TemplatePrint/Barcelone/BarceloneTemplateLandscape/BarceloneTemplateLandscape";
import LondresTemplateLandscape from "../../TemplatePrint/Londres/LondresTemplateLandscape/LondresTemplateLandscape";
import LondresTemplatePortrait from "../../TemplatePrint/Londres/LondresTemplatePortrait/LondresTemplatePortrait";
import ParisTemplateLandscape from "../../TemplatePrint/Paris/ParisTemplateLandscape/ParisTemplateLandscape";
import ParisTemplatePortrait from "../../TemplatePrint/Paris/ParisTemplatePortrait/ParisTemplatePortrait";
import BerlinTemplateLandscape from "../../TemplatePrint/Berlin/BerlinTemplateLandscape/BerlinTemplateLandscape";
import BerlinTemplatePortrait from "../../TemplatePrint/Berlin/BerlinTemplatePortrait/BerlinTemplatePortrait";
import CuppertinoTemplateLandscape from "../../TemplatePrint/Cuppertino/CuppertinoTemplateLandscape/CuppertinoTemplateLandscape";
import CuppertinoTemplatePortrait from "../../TemplatePrint/Cuppertino/CuppertinoTemplatePortrait/CuppertinoTemplatePortrait";
import SingapourTemplateLandscape from "../../TemplatePrint/Singapour/SingapourTemplateLandscape/SingapourTemplateLandscape";
import SingapourTemplatePortrait from "../../TemplatePrint/Singapour/SingapourTemplatePortrait/SingapourTemplatePortrait";
import PersoLandscape from "../../TemplatePrint/Perso/PersoLandscape/PersoLandscape";
import BersoLandscape from "../../TemplatePrint/BersoImmo/Landscape/BersoLandscape";
import GosselinLandscape from "../../TemplatePrint/Gosselin/Landscape/GosselinLandscape";
import PersoPortrait from "../../TemplatePrint/Perso/PersoPortrait/PersoPortrait";
import SydneyTemplateLandscape from "../../TemplatePrint/Sydney/SydneyTemplateLandscape/SydneyTemplateLandscape";
import SydneyTemplatePortrait from "../../TemplatePrint/Sydney/SydneyTemplatePortrait/SydneyTemplatePortrait";
import LoftAndHouseLandscape from "../../TemplatePrint/LoftAndHouse/LoftAndHouseLandscape/LoftAndHouseLandscape";
import IphLandscape from "../../TemplatePrint/Iph/Landscape/IphLandscape";
import IphLandscapeSecond from "../../TemplatePrint/IphSecond/Landscape/IphLandscapeSecond";
import MadridLandscape from "../../TemplatePrint/Madrid/Landscape/MadridLandscape";
import SapeneLandscape from "../../TemplatePrint/Sapene/Landscape/SapeneLandscape";
import { useTemplatesStore } from "../../../../store/TemplatesStore";
import ElfiLandscape from "../../TemplatePrint/Elfi/Landscape/ElfiLandscape";

const TemplatesList = React.forwardRef((props, ref) => {
  const template = useTemplatesStore();

  console.log(template);

  return (
    <>
      {template.model === "Londres" && template.orientation === "landscape" && (
        <LondresTemplateLandscape
          ref={ref.londresLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Londres" && template.orientation === "portrait" && (
        <LondresTemplatePortrait
          ref={ref.londresPortraitRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Paris" && template.orientation === "landscape" && (
        <ParisTemplateLandscape
          ref={ref.parisLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Paris" && template.orientation === "portrait" && (
        <ParisTemplatePortrait
          ref={ref.parisPortraitRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Barcelone" &&
        template.orientation === "landscape" && (
          <BarceloneTemplateLandscape
            ref={ref.barceloneLandscapeRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Barcelone" &&
        template.orientation === "portrait" && (
          <BarceloneTemplatePortrait
            ref={ref.barcelonePortraitRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Berlin" && template.orientation === "landscape" && (
        <BerlinTemplateLandscape
          ref={ref.berlinLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Berlin" && template.orientation === "portrait" && (
        <BerlinTemplatePortrait
          ref={ref.berlinPortraitRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Cupertino" &&
        template.orientation === "landscape" && (
          <CuppertinoTemplateLandscape
            ref={ref.cuppertinoLandscapeRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Cupertino" &&
        template.orientation === "portrait" && (
          <CuppertinoTemplatePortrait
            ref={ref.cuppertinoPortraitRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Singapour" &&
        template.orientation === "landscape" && (
          <SingapourTemplateLandscape
            ref={ref.singapourLandscapeRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Singapour" &&
        template.orientation === "portrait" && (
          <SingapourTemplatePortrait
            ref={ref.singapourPortraitRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Sydney" && template.orientation === "landscape" && (
        <SydneyTemplateLandscape
          ref={ref.sydneyLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Sydney" && template.orientation === "portrait" && (
        <SydneyTemplatePortrait
          ref={ref.sydneyPortraitRef}
          isPreview={false}
          {...template}
        />
      )}

      {template.model === "Perso" && template.orientation === "landscape" && (
        <PersoLandscape
          ref={ref.persoLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Perso" && template.orientation === "portrait" && (
        <PersoPortrait
          ref={ref.persoPortraitRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Bersot Immobilier" &&
        template.orientation === "landscape" && (
          <BersoLandscape
            ref={ref.bersotLandscapeRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Elfi" && template.orientation === "landscape" && (
        <ElfiLandscape
          ref={ref.elfiLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Osenat" && template.orientation === "landscape" && (
        <GosselinLandscape
          ref={ref.gosselinLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Loft And House" &&
        template.orientation === "landscape" && (
          <LoftAndHouseLandscape
            ref={ref.loftAndHouseLandscapeRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "IPH" && template.orientation === "landscape" && (
        <IphLandscape
          ref={ref.iphLandscapeRef}
          {...props}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "IPH Second" &&
        template.orientation === "landscape" && (
          <IphLandscapeSecond
            ref={ref.iphSecondLandscapeRef}
            isPreview={false}
            {...template}
          />
        )}
      {template.model === "Madrid" && template.orientation === "landscape" && (
        <MadridLandscape
          ref={ref.madridLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
      {template.model === "Sapene" && template.orientation === "landscape" && (
        <SapeneLandscape
          ref={ref.sapeneLandscapeRef}
          isPreview={false}
          {...template}
        />
      )}
    </>
  );
});

export default TemplatesList;
