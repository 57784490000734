import { Box } from "@material-ui/core";
import React from "react";
import {
  BooleanInput,
  DateInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin";
import Areas from "./Areas";
import Rooms from "./Rooms";

const OtherInformations = (props) => {
  const { choices } = props;
  return (
    <div>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="availability"
            resettable
            resource="projects"
            choices={choices.availability}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="activities" resource="projects" fullWidth />
        </Box>
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <DateInput source="availableat" resource="projects" fullWidth />
        </Box> */}
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="nblotcopro"
            resource="projects"
            min={0}
            step={1}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="cadastre" resource="projects" fullWidth />
        </Box> */}
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="block" resource="projects" fullWidth />
        </Box> */}
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}></Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="procsyndicale" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="copropriete" resource="projects" />
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="style" resource="projects" fullWidth />
        </Box> */}
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="standing"
            resettable
            resource="projects"
            choices={choices.standing}
            fullWidth
          />
        </Box> */}
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="viewtype"
            resettable
            resource="projects"
            choices={choices.viewtype}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="viewlandscape"
            resettable
            resource="projects"
            choices={choices.viewlandscape}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="construction"
            resettable
            resource="projects"
            choices={choices.construction}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <DateInput source="deliveredat" resource="projects" fullWidth />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="floor" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="floors"
            resource="projects"
            min={0}
            step={1}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="waterhotdevice"
            resettable
            resource="projects"
            choices={choices.waterhotdevice}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="waterhotaccess"
            resettable
            resource="projects"
            choices={choices.waterhotaccess}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="waterwaste"
            resettable
            resource="projects"
            choices={choices.waterwaste}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="condition"
            resettable
            resource="projects"
            choices={choices.condition}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="orientations"
            resettable
            resource="projects"
            choices={choices.orientations}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="proximity"
            resettable
            resource="projects"
            choices={choices.proximity}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="services"
            resettable
            resource="projets"
            choices={choices.services}
            fullWidth
          />
        </Box>
      </Box>
      <Rooms {...props} />
      <Areas {...props} choices={choices} />
    </div>
  );
};

export default OtherInformations;
