/* eslint-disable no-unused-vars */
import { Popover, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import ColorChoice from "../ColorChoice";
import { TextFieldContainer } from "../Styles/Content.styles";
import { FontIcon } from "../Styles/StepFour.styles";
import { ColorCube } from "../Styles/StepFour.styles";
import FontManager from "./FontManager";

const DescComponent = () => {
  const { t } = useTranslation();
  const {
    setColorDesc,
    descFont,
    setDescFont,
    desc,
    colorDesc,
    setDesc,
  } = useTemplatesStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewFont, setViewFont] = React.useState(null);

  const handleChangeColor = (color) => {
    setColorDesc(color.hex);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeSize = (setter) => {
    if (setter === "add") {
      if (descFont.size !== 24 || descFont.size === "") {
        setDescFont((prev) => ({
          ...prev,
          size:
            prev.size === "" || prev.size === 16
              ? 20
              : prev.size === 20
              ? 24
              : 16,
        }));
      }
    } else if (setter === "remove") {
      if (descFont.size !== 16) {
        setDescFont((prev) => ({
          ...prev,
          size: prev.size === "" ? 16 : prev.size === 24 ? 20 : 16,
        }));
      }
    }
  };
  const handleChangeWeight = (setter) => {
    if (setter === "add") {
      if (descFont.weight < 600 || descFont.weight === "") {
        setDescFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight + 100 : 500,
        }));
      }
    } else if (setter === "remove") {
      if (descFont.weight > 400 || descFont.weight === "") {
        setDescFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight - 100 : 400,
        }));
      }
    }
  };

  const handleChangeFont = (e) => {
    setDescFont((prev) => ({
      ...prev,
      font: e.target.value,
    }));
  };

  const onChangeDesc = (event) => {
    setDesc(event.target.value);
  };

  return (
    <>
      <TextFieldContainer margin={"2rem auto"}>
        <TextField
          id="outlined-multiline-static"
          label={t("modalprint.description")}
          placeholder={t("modalprint.descmax")}
          multiline
          rows={8}
          value={desc}
          variant="outlined"
          onChange={onChangeDesc}
          fullWidth
          inputProps={{
            maxLength: 450,
          }}
          InputProps={{
            endAdornment: (
              <div>
                <ColorCube color={colorDesc || "black"} onClick={handleClick} />
                <FontIcon onClick={() => setViewFont(!viewFont)} margin />
              </div>
            ),
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ColorChoice
            color={colorDesc}
            handleChangeColor={handleChangeColor}
          />
        </Popover>
        {viewFont && (
          <FontManager
            open={viewFont}
            handleChangeSize={handleChangeSize}
            handleChangeWeight={handleChangeWeight}
            handleChangeFont={handleChangeFont}
            police={descFont.font}
          />
        )}
      </TextFieldContainer>
    </>
  );
};

export default DescComponent;
