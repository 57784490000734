import React from "react";
import { useTranslate } from "react-admin";
import {
  ButtonContainer,
  ActionBtn,
} from "../../Utils/Styles/ItemManager.styles";

const ActionContainer = (props) => {
  const translate = useTranslate();
  return (
    <ButtonContainer>
      {props.action === "delete" ? (
        <ActionBtn mycolor="red" onClick={() => props.cancelSubscription()}>
          {translate("resources.fields.cancelsubscription")}
        </ActionBtn>
      ) : (
        <>
          <ActionBtn right="true" onClick={() => props.handleClose()}>
            {translate("resources.fields.cancel")}
          </ActionBtn>
          <ActionBtn
            active="true"
            onClick={() => props.saveSubscription()}
            disabled={
              props.name === "ads" && props.state.ads.subtype === null
                ? true
                : false
            }
          >
            {translate("resources.fields.save")}
          </ActionBtn>
        </>
      )}
    </ButtonContainer>
  );
};

export default ActionContainer;
