/* eslint-disable no-unused-vars */
import React from "react";
import { CombineContext } from "../../../store/UserProvider";
import Loader from "../../Loader/Loader";
import { getAgency, getUserFromAgency } from "./Utils/axiosRequest";

function WrapperList(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        userSelect: null,
        loading: true,
        agency: null,
      };
    }

    static contextType = CombineContext;

    refresh = async () => {
      const user = await this.context;
      if (user.valueChange.userChange) {
        this.setState({ userSelect: user.valueChange.userChange });
      } else {
        this.setState({ userSelect: user.value.user });
      }
      if (
        this.state.userSelect &&
        this.state.userSelect.permissions !== "utilisateur" &&
        this.state.userSelect.agenceId
      ) {
        const getAgencyDetails = await getAgency(
          this.state.userSelect.agenceId
        );
        if (getAgencyDetails.status === 200) {
          this.setState({ agency: getAgencyDetails.data });
          const userFromAgency = await getUserFromAgency(
            this.state.userSelect.agenceId
          );
          this.setState({ numberOfUser: userFromAgency.data.length });
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    };

    async componentDidMount() {
      await this.refresh();
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    render() {
      if (this.state.loading === true && this.state.userSelect === null) {
        return <Loader />;
      } else {
        return (
          <WrappedComponent {...this.state} {...this.props}>
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
}

export default WrapperList;
