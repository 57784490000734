import styled from "styled-components";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { colorPrimary } from "../../GlobalStyles";
export const ContainerFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff36;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CodeButton = styled.button`
  position: absolute;
  z-index: 1100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  justify-content: center;
  color: white;
  width: 200px;
  background-color: ${colorPrimary};
  border: 1px solid transparent;
  padding: 8px 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
`;

export const UnlockBtn = styled(LockOpenIcon)`
  && {
    color: white;
    font-size: 20px;
  }
`;
export const SpanBtn = styled.span`
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`;
export const SpanIcon = styled.span`
  display: inherit;
  margin-left: -4px;
  margin-right: 8px;
`;
