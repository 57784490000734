import React from "react";
import New360 from "./new360/new360";
import No360View from "./No360View";

const ViewComponent = (props) => {
  if (props.project.length) {
    return (
      <New360
        scenes={props.project}
        autorotate={props.autorotate}
        noMenu={props.noMenu}
        isMobile={props.isMobile}
      />
    );
  } else {
    return <No360View infoProject={props.infoProject} />;
  }
};

export default ViewComponent;
