/* eslint-disable no-unused-vars */
import {
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import React, { useEffect, useState } from "react";
import { colorPrimary } from "../../../../../../GlobalStyles";

const GreenCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MediasUrlFilter = (props) => {
  const translate = useTranslate();

  const [state, setState] = useState({
    one: false,
  });

  useEffect(() => {
    if (props.isReset) {
      setState({
        one: false,
      });
    }
  }, [props.isReset]);

  const handleChange = (event) => {
    setState({
      ...state,
      one: event.target.checked,
    });
    props.handleFilter("mediasurl", event.target);
  };

  return (
    <div style={{ marginTop: "1rem", display: "flex" }}>
      <div style={{ marginTop: "-13px", width: "35px" }}>
        <FormControlLabel
          control={
            <GreenCheckbox checked={state.one} onChange={handleChange} />
          }
        />
      </div>

      <Typography
        style={{
          fontSize: "0.75rem",
          letterSpacing: "0.08333em",
        }}
      >
        {translate("resources.projects.withmedias").toUpperCase()}
      </Typography>
    </div>
  );
};

export default MediasUrlFilter;
