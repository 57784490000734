import React from "react";
import {
  LoaderContainer,
  LoaderContainerChild,
  LoaderDotElement,
  LoaderText,
} from "./Loader.styles";
import { useTranslation } from "react-i18next";

const Loader = (props) => {
  const { t } = useTranslation();
  return (
    <LoaderContainer minHeight={props.minHeight ? props.minHeight : false}>
      <LoaderContainerChild>
        {!props.noText ? (
          <LoaderText>
            {props.text ? props.text : t("admin.fields.loader")}
          </LoaderText>
        ) : null}
        <LoaderDotElement />
        <LoaderDotElement />
        <LoaderDotElement />
      </LoaderContainerChild>
    </LoaderContainer>
  );
};

export default Loader;
