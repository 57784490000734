/* eslint-disable no-unused-vars */
import React from "react";
import { useMutation, useRefresh } from "react-admin";
import { useGetAbo } from "../../../../hooks/useGetAbo";
import { ActionBtn } from "../../Utils/Styles/ItemManager.styles";

const prev = (record) => {
  if (record.abo) return record.abo;
  else return [];
};

const ApproveButton = (props) => {
  const { getTheTypeAbo } = useGetAbo();
  const refresh = useRefresh();

  const [approve, { loading }] = useMutation(
    {
      type: "update",
      resource: "agencies",
      payload: {
        id: props.record._id,
        data: {
          ...props.record,
          abo: {
            ...prev(props.record),
            ...props.state,
          },
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        props.showMessage("resources.alert.changessaved");
        const response = getTheTypeAbo(data, prev(props.record));
        if (response === "close") {
          if (props.context && props.context === "dashboard") {
            props.refresh();
            props.setModal(false);
          } else {
            props.setModal(false);
            refresh();
          }
        }
      },
    }
  );
  return (
    <ActionBtn
      onClick={approve}
      disabled={loading}
      active={props.confirmAction === "save" ? "true" : "false"}
      mycolor={props.confirmAction === "save" ? "false" : "red"}
    >
      {props.confirmAction === "save"
        ? props.translate("resources.fields.save")
        : props.translate("resources.fields.cancelsubscription")}
    </ActionBtn>
  );
};

export default ApproveButton;
