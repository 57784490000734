/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  sanitizeListRestProps,
} from "react-admin";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    userSelect,
    numberOfUser,
    agency,
    ...rest
  } = props;
  const { basePath } = useListContext();
  const [numberMax, setNumberMax] = React.useState(1);
  let admin = ["super_admin", "admin"];

  // React.useEffect(() => {
  //   if (agency) {
  //     switch (agency.abo.ads) {
  //       case "agent":
  //         setNumberMax(1);
  //         break;
  //       case "agence":
  //         setNumberMax(3);
  //         break;
  //       case "business":
  //         setNumberMax(10);
  //         break;
  //       case "teams":
  //         setNumberMax(15);
  //         break;
  //       case "leader":
  //         setNumberMax(25);
  //         break;
  //       default:
  //         setNumberMax(1);
  //     }
  //   }
  // }, [agency]);

  if (userSelect.permissions === "utilisateur") {
    return <div />;
  } else {
    if (admin.includes(userSelect.permissions)) {
      return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          <CreateButton basePath={basePath} />
        </TopToolbar>
      );
    } else {
      return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          {/* {numberOfUser < numberMax && <CreateButton basePath={basePath} />} */}
          <CreateButton basePath={basePath} />
        </TopToolbar>
      );
    }
  }
};
export default ListActions;
