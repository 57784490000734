import { storage } from "./firebase.config";

export const uploadImg = async (files, resource, formElement) => {
  return Promise.all(
    files.map(async (file, index) => {
      let nomFichier;
      let rawFichier;

      if (!file.rawFile) {
        nomFichier = index + 1 + ".jpg";
        rawFichier = file;
      } else {
        nomFichier = file.rawFile.name;
        rawFichier = file.rawFile;
      }

      const uploadTask = storage
        .ref()
        .child(resource)
        .child(formElement)
        .child(nomFichier)
        .put(rawFichier);

      const url = await new Promise((resolve) => {
        uploadTask.on(
          "stated_changed",
          () => {},
          (error) => console.log(error.code),
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          }
        );
      });

      return {
        src: url,
        name: nomFichier,
        resource: resource + "/" + formElement,
      };
    })
  );
};

export const deleteFolderContents = async (path) => {
  const ref = storage.ref(path);
  ref
    .listAll()
    .then((dir) => {
      dir.items.forEach((fileRef) => {
        deleteFile(ref.fullPath, fileRef.name);
      });
      dir.prefixes.forEach((folderRef) => {
        deleteFolderContents(folderRef.fullPath);
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteFile = async (pathToFile, fileName) => {
  const ref = storage.ref(pathToFile);
  const childRef = ref.child(fileName);
  return childRef.delete();
};

export const deleteFiles = async (files) => {
  files.forEach((file) => {
    deleteFile(file.resource, file.name);
  });
  return true;
};

export const uploadToolsImage = async (parent, item) => {
  const ref = storage.ref();

  const task = ref.child(parent).put(item);

  return await task
    .then((snapshot) => snapshot.ref.getDownloadURL())
    .then((url) => {
      return url;
    });
};
