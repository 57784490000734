import styled from "styled-components";
import { Autocomplete } from "@material-ui/lab";

import FavoriteIcon from "@material-ui/icons/Favorite";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TextField } from "@material-ui/core";
import { colorTertiary } from "../../../../../GlobalStyles";

// THEME COLOR GREEN

const primary = "#2fbcee";

// DASHBOARD

export const DashboardContainer = styled.div`
  padding-top: 4rem;
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

// HEADER DASHBOARD

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media screen and (max-width: 1183px) {
    flex-direction: ${({ open }) => (open ? "column" : "initial")};
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const TotalProjectContainer = styled.div`
  @media screen and (max-width: 1183px) {
    margin-bottom: ${({ open }) => (open ? "20px" : "initial")};
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
  }
`;

export const TotalText = styled.p`
  margin-left: 5px;
  margin-bottom: 0;

  @media screen and (max-width: 600px) {
    text-align: center;
    margin-left: unset;
  }
`;
export const TotalTextBold = styled.b``;

export const TotalTextColor = styled.span`
  color: ${primary};
`;
export const FilterContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1051px) {
    flex-direction: ${({ open }) => (open ? "column" : "initial")};
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const AutoCompleteInput = styled(Autocomplete)`
  && {
    width: 255px;
    .MuiFormControl-marginNormal {
      margin-top: 0;
      /* border: 1px solid #9b9b9b66; */
      box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);
      border-radius: 5px;
      margin-bottom: 0px;
    }
    .MuiFilledInput-underline:before {
      border-bottom: unset;
    }

    .MuiFilledInput-underline:after {
      border-bottom: unset;
    }
    .MuiFilledInput-root {
      border-radius: 10px;
    }
    .MuiFormLabel-root {
      font-size: 0.8rem;
      line-height: unset;
      margin-left: 10px;
    }
    .MuiIconButton-root {
      background-color: rgba(0, 0, 0, 0.04);
      color: #2fbcee;
      text-decoration: none;
    }
    @media screen and (max-width: 1051px) {
      width: ${({ menu }) => (menu === "true" ? "100%" : "255px")};
    }
    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
  & .MuiAutocomplete-clearIndicator {
    margin-right: 5px;
  }
`;

export const DateText = styled.p`
  text-align: right;
  margin-right: 5px;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;
export const DateTextBold = styled.b``;

// DASHBOARD BOX

export const DashboardBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.template}, 1fr);
  justify-content: center;
  background-color: transparent;
  transition: all 0.3s ease;
  grid-gap: 30px;
  justify-items: center;
  padding-top: 1rem;
  padding-bottom: 2.5rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    margin: auto;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: auto;
  }
`;
export const BoxContent = styled.div`
  width: 230px;
  height: 85%auto;
  background-color: white;
  transition: all 0.3s ease-in-out;
  line-height: 2;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem 0;
  /* border: 1px solid #9b9b9b66; */
  box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);

  &:hover {
    box-shadow: 0 18px 38px rgb(0 0 0 / 10%), 0 7px 15px rgb(0 0 0 / 10%);
  }

  @media screen and (max-width: 600px) {
    min-width: 300px;
    max-width: 400px;
  }
`;
export const BoxTitleContainer = styled.div``;

export const BoxTitle = styled.p`
  font-weight: 300;
  color: #34495ed6;
  margin-bottom: 0.2rem;
  text-align: center;
`;
export const BoxNumberContainer = styled.div``;

export const BoxNumber = styled.h1`
  font-weight: 700;
  color: #34495e;
  text-align: center;
`;

// DASHBOARD TOP PROJECTS

export const DashboardTopProjectContainer = styled.div``;

export const TopProjectText = styled.p`
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const TopProjectTextBold = styled.b`
  text-transform: uppercase;
`;

// DASHBOARD TOP PROJECTS SLIDE

export const DashboardSLidesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  background-color: transparent;
  grid-gap: 30px;
  justify-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    margin: auto;
  }
  @media screen and (max-width: 868px) {
    grid-template-columns: 1fr;
  }
`;

export const SlideContainer = styled.div``;

export const SlideImageContainer = styled.div`
  width: 300px;
  line-height: 2;
  position: relative;
  border-radius: 10px;
  transition: 0.2s ease;
  margin-bottom: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);

  &:hover {
    box-shadow: 0 18px 38px rgb(0 0 0 / 10%), 0 7px 15px rgb(0 0 0 / 10%);
  }

  @media screen and (max-width: 1100px) {
    width: 400px;
  }
  @media screen and (max-width: 868px) {
    width: 500px;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
  }
`;
export const SlideImage = styled.img`
  width: 300px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  -o-object-fit: cover;

  @media screen and (max-width: 1100px) {
    width: 400px;
    height: 250px;
  }
  @media screen and (max-width: 868px) {
    width: 500px;
    height: 350px;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
    height: 300px;
  }
`;
export const SlideTitleContainer = styled.div``;

export const SlideTitle = styled.p`
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 500;
`;
export const SlideDetailsContainer = styled.div``;

export const SlideDetailText = styled.p`
  color: #a29e9e;
  margin-bottom: 0;
  font-weight: lighter;
`;

export const LikeContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  width: 100%;
`;
export const LikeTagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 4px 0.4rem;
`;
export const HeartIcon = styled(FavoriteIcon)`
  && {
    font-size: 22px;
    color: ${colorTertiary};
  }
`;
export const TextTagHeart = styled.span`
  margin-right: 3px;
  font-size: 18px;
  font-weight: 600;
`;
export const ViewIcon = styled(VisibilityIcon)`
  && {
    font-size: 22px;
  }
`;
export const StateContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 0;
  margin-right: 8px;
`;
export const StateIcon = styled(Brightness1RoundedIcon)`
  && {
    font-size: 16px;
    color: ${({ mycolor }) =>
      mycolor === "red"
        ? "#CC0000"
        : mycolor === "orange"
        ? "#D57100"
        : "#2fd4a7"};
  }
`;

export const DatePicker = styled(TextField)`
  && {
    padding: 0 5px;
    margin-right: 15px;
    background-color: white;
    /* border: 1px solid #9b9b9b66; */
    box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);

    border-radius: 5px;
    .MuiFormControl-root {
      background-color: white;
      border: 1px solid #9b9b9b66;
      border-radius: 10px;
    }
    .MuiInputLabel-root {
      margin-top: 7px;
      margin-left: 15px;
      font-size: 12px;
    }
    .MuiInputBase-root {
      border-radius: 10px;
    }
    .MuiInput-underline:before {
      border: unset;
    }
    .MuiInputBase-input {
      padding: 10px;
      border-radius: 10px;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border: unset;
    }
    .MuiInput-underline:after {
      border: unset;
    }
    .MuiFormLabel-root.Mui-focused {
      color: #2fbcee !important;
    }
    @media screen and (max-width: 1051px) {
      margin-bottom: ${({ open }) => (open ? "20px" : "initial")};
      margin-right: ${({ open }) => (open ? "unset" : "30px")};
    }
    @media screen and (max-width: 960px) {
      margin-bottom: 20px;
      margin-right: unset;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;

  @media screen and (max-width: 960px) {
    margin-bottom: 20px;
  }
`;
export const IconRefresh = styled(RefreshIcon)`
  && {
    color: #34495e;
    cursor: pointer;
  }
`;
