import styled from "styled-components";
import { colorPrimary, colorTertiary } from "../../../../../GlobalStyles";

export const ContainerStepThree = styled.div`
  width: 100%;
`;

export const TitleSection = styled.h5`
  margin: ${(props) => props.margin};
  font-weight: 900;
  text-align: center;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "initial")};
`;
export const Br = styled.br``;

export const TitleContainerWithAlert = styled.div`
  min-height: 40px;
`;

export const AlertContainer = styled.div`
  background-color: ${(props) =>
    props.success ? colorPrimary : colorTertiary};
  border-radius: 10px;
  text-align: center;
  padding: 0.4rem;
  max-width: 320px;
  margin: auto;
`;
export const AlertText = styled.span`
  color: white;
  font-weight: 900;
  text-transform: uppercase;
`;
