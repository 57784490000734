/* eslint-disable no-unused-vars */
import { Popover, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import ColorChoice from "../ColorChoice";
import { TextFieldContainer } from "../Styles/Content.styles";
import { ColorCube, FontIcon } from "../Styles/StepFour.styles";
import FontManager from "./FontManager";

const BannerComponent = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewFont, setViewFont] = React.useState(null);
  const { setBannerFont, bannerFont, banner, setBanner } = useTemplatesStore();

  const handleChangeColor = (color) => {
    setBannerFont((prev) => ({ ...prev, color: color.hex }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeSize = (setter) => {
    if (setter === "add") {
      if (bannerFont.size < 50 || bannerFont.size === "") {
        setBannerFont((prev) => ({
          ...prev,
          size: prev.size !== "" ? prev.size + 1 : 16,
        }));
      }
    } else if (setter === "remove") {
      if (bannerFont.size > 0 || bannerFont.size === "") {
        setBannerFont((prev) => ({
          ...prev,
          size: prev.size !== "" ? prev.size - 1 : 14,
        }));
      }
    }
  };
  const handleChangeWeight = (setter) => {
    if (setter === "add") {
      if (bannerFont.weight < 900 || bannerFont.weight === "") {
        setBannerFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight + 100 : 600,
        }));
      }
    } else if (setter === "remove") {
      if (bannerFont.weight > 200 || bannerFont.weight === "") {
        setBannerFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight - 100 : 400,
        }));
      }
    }
  };

  const handleChangeFont = (e) => {
    setBannerFont((prev) => ({
      ...prev,
      font: e.target.value,
    }));
  };

  const onChangeBanner = (event) => {
    setBanner(event.target.value);
  };

  return (
    <TextFieldContainer margin={"1rem auto"}>
      <TextField
        id="outlined-multiline-static"
        label={t("modalprint.banner")}
        value={banner}
        variant="outlined"
        onChange={onChangeBanner}
        fullWidth
        InputProps={{
          endAdornment: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ColorCube color={bannerFont.color} onClick={handleClick} />
              <FontIcon onClick={() => setViewFont(!viewFont)} margin={false} />
            </div>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice
          color={bannerFont.color}
          handleChangeColor={handleChangeColor}
        />
      </Popover>
      {viewFont && (
        <FontManager
          open={viewFont}
          handleChangeSize={handleChangeSize}
          handleChangeWeight={handleChangeWeight}
          handleChangeFont={handleChangeFont}
          police={bannerFont.font}
        />
      )}
    </TextFieldContainer>
  );
};

export default BannerComponent;
