import { Popover } from "@material-ui/core";
import React, { useState } from "react";
import { ColorCube } from "../../../../Utils/Styles/Agency.styles";
import ColorChoice from "../ColorChoice";
import {
  ColorInput,
  LabelColorInput,
  ResetButton,
} from "./ColorPickerInput.styles";
import ClearIcon from "@material-ui/icons/Clear";

const ColorPrimaryComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isReset, setIsReset] = useState(false);

  const handleChangeColor = (color) => {
    props.onChangeColor(props.setter, color.hex);
  };

  const resetValue = () => {
    props.onChangeColor(props.setter, "");
    setIsReset(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <LabelColorInput>{props.label}</LabelColorInput>
      <ColorInput
        fullWidth
        defaultValue={props.default}
        value={props.color}
        InputProps={{
          endAdornment: (
            <>
              <ColorCube
                color={isReset ? props.color : props.color || props.default}
                onClick={handleClick}
              />
              <ResetButton as={ClearIcon} onClick={resetValue} />
            </>
          ),
          value: props.color,
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice
          color={props.color ? props.color : props.default}
          handleChangeColor={handleChangeColor}
        />
      </Popover>
    </>
  );
};

export default ColorPrimaryComponent;
