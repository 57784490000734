import React from "react";
import { Toolbar, SaveButton } from "react-admin";

const PostCreateToolbar = (props) => {
  return (
    <Toolbar>
      <SaveButton
        transform={(data) => ({ ...data, ...props.initialValues })}
        submitOnEnter={false}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        disabled={props.enableSaveAddress === false ? false : props.pristine}
      />
    </Toolbar>
  );
};
export default PostCreateToolbar;
