import { Slider } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTemplatesStore } from "../../../../store/TemplatesStore";
import {
  HdrContainer,
  ImageRender,
  ImageRenderContainer,
  SliderBrightnessContainer,
  SliderContainer,
  SliderSaturationContainer,
  SliderTextName,
} from "./Styles/Hdr.styles";

const Hdr = (props) => {
  const { t } = useTranslation();
  const { project, brightness, saturation } = useTemplatesStore();
  return (
    <HdrContainer>
      <SliderContainer>
        <SliderBrightnessContainer>
          <SliderTextName>
            {t("modalprint.brightness").toUpperCase()}
          </SliderTextName>
          <Slider
            value={brightness}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={5}
            marks
            min={100}
            max={125}
            onChange={props.handleBrightnessChange}
          />
        </SliderBrightnessContainer>
        <SliderSaturationContainer>
          <SliderTextName>
            {t("modalprint.saturation").toUpperCase()}
          </SliderTextName>
          <Slider
            value={saturation}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={5}
            marks
            min={100}
            max={150}
            onChange={props.handleSaturationChange}
          />
        </SliderSaturationContainer>
      </SliderContainer>

      <ImageRenderContainer>
        <ImageRender
          src={project.images[0].src}
          alt="filter-render"
          brightness={brightness}
          saturation={saturation}
        />
      </ImageRenderContainer>
    </HdrContainer>
  );
};

export default Hdr;
