/* eslint-disable no-unused-vars */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import React, { useState } from "react";
import OfferSmallGridWrapper from "./OfferSmallGridWrapper";
import OfferSmallRow from "./OfferSmallRow";
import ModalPdf from "./PdfElements/ModalPdf";

const columns = [
  {
    id: "show",
    label: "resources.fields.show",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "accroche",
    label: "resources.fields.teaser",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "reference",
    label: "resources.projects.fields.reference",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "category",
    label: "resources.projects.fields.category",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "price",
    label: "resources.projects.fields.price",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "date",
    label: "resources.fields.date",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "pdf",
    label: "PDF",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "utilisateur",
    label: "resources.fields.agent",
    minWidth: 200,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const LeadsSmallGrid = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { data } = props;
  const [page, setPage] = React.useState(0);
  const [modal, setModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [row, setRow] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  <span>{translate(column.label).toUpperCase()}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <OfferSmallRow
                    key={idx}
                    index={idx}
                    row={row}
                    setRow={setRow}
                    setModal={setModal}
                    dataSupp={props.dataSupp}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ModalPdf
        modal={modal}
        setModal={setModal}
        informations={data[row].offer.informations}
        customer={props.dataSupp.customer}
        secondCustomer={props.dataSupp.secondCustomer}
        infoProject={data[row].project}
        agency={data[row].agency}
        codeVerify={props.dataSupp.codeVerify}
        row={data[row]}
      />
    </Paper>
  );
};

export default OfferSmallGridWrapper(LeadsSmallGrid);
