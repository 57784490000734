/* eslint-disable no-unused-vars */
import * as React from "react";

import {
  List,
  Datagrid,
  TextField,
  EmailField,
  useGetIdentity,
  Pagination,
} from "react-admin";
import { UserChangeContext, UserContext } from "../../../store/UserProvider";
import Empty from "./Components/Empty";
import { ContactListStyle } from "./utils/Styles/ContactList.mui.styles";

const OfferPagination = (props) => (
  <Pagination
    rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 1000]}
    {...props}
  />
);

const OfferList = (props) => {
  const classes = ContactListStyle();
  const { user, setUser } = React.useContext(UserContext);
  const { userChange, setUserChange } = React.useContext(UserChangeContext);
  const [selected, setSelected] = React.useState({
    user: user,
    setUser: setUser,
  });

  React.useEffect(() => {
    if (userChange) {
      setSelected({ user: userChange, setUser: setUserChange });
    }
  }, [userChange, setUserChange]);

  const queryShow = () => {
    if (
      (selected.user.permissions === "manager" ||
        selected.user.permissions === "utilisateur") &&
      selected.user.agenceId
    ) {
      return { agenceId_$in: selected.user.agenceId };
    } else {
      return { _id_$in: selected.user._id };
    }
  };
  return (
    <List
      {...props}
      exporter={false}
      filter={queryShow()}
      empty={<Empty />}
      perPage={50}
      pagination={<OfferPagination />}
    >
      <Datagrid rowClick="edit">
        <TextField source="customer.firstname" />
        <TextField source="customer.lastname" />
        <TextField
          source="customer.phonemobile"
          headerClassName={classes.hiddenOnSmallScreens}
          cellClassName={classes.hiddenOnSmallScreens}
        />
        <EmailField source="customer.email" />
      </Datagrid>
    </List>
  );
};

export default OfferList;
