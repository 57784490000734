import React, { useState } from "react"; // useContext
import TitleBar from "../../Components/TitleBar";
import SidebarMobile from "../../Components/Sidebar/SidebarMobile";
import ModalDetails from "../../Components/ModalDetails";
import ModalGallery from "../../Components/ModalGallery";
import ModalMedias from "../../Components/ModalMedias/Test";
import ModalMap from "../../Components/ModalMap";
import ModalContact from "../../Components/ModalContact";
import ModalShare from "../../Components/ModalShare";

// CSS
import { Fade } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CookieComponent from "../../Components/360/CookieComponent";
import No360View from "../../Components/360/No360View";
import useShow from "./hooks/useShow";
import ModalOffer from "../../Components/ModalOffer";
import ModalFiles from "../../Components/ModalFiles";
import ViewComponent from "../../Components/360/ViewComponent";
import { ContainerHome360 } from "./VisibbleViewerMobile/Utils/Styles/MobileView.styles";

const Home360 = (props) => {
  const { t, i18n } = useTranslation();
  const {
    project,
    infoProject,
    countView,
    user,
    agency,
    getAllClick,
    only360,
  } = props;
  const [isLarge, setIsLarge] = useState(false);
  const { show } = useShow();

  return (
    <Fade in={true} timeout={400}>
      <ContainerHome360>
        {project ? (
          <ViewComponent {...props} show={show} isMobile={true} />
        ) : (
          <No360View infoProject={infoProject} />
        )}
        <TitleBar
          project={infoProject}
          agency={agency}
          show={show}
          user={user}
          only360={only360}
        />

        <SidebarMobile
          setIsLarge={setIsLarge}
          isLarge={isLarge}
          countView={countView}
          infoProject={infoProject}
          user={user}
          show={show}
          getAllClick={getAllClick}
          isOld={project.scenes ? true : project.length ? false : true}
          setSelectView={props.setSelectView}
        />

        <CookieComponent i18n={i18n} translate={t} />
        <ModalMap infoProject={infoProject} />

        <ModalDetails
          infoProject={infoProject}
          getAllClick={getAllClick}
          agency={agency}
        />
        <ModalFiles
          infoProject={infoProject}
          getAllClick={getAllClick}
          agency={agency}
          secureUnlock={props.secureUnlock}
        />
        <ModalGallery infoProject={infoProject} />
        <ModalMedias infoProject={infoProject} getAllClick={getAllClick} />
        <ModalContact
          infoProject={infoProject}
          agency={agency}
          isVmlive={props.isVmlive}
          agencyEmail={props.agencyEmail}
        />
        <ModalShare infoProject={infoProject} />
        <ModalOffer infoProject={infoProject} agency={agency} />
      </ContainerHome360>
    </Fade>
  );
};

export default Home360;
