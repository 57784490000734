import React, { useContext, useEffect, useState } from "react";
import { Edit } from "react-admin";
import { CombineContext } from "../../../store/UserProvider";
import Loader from "../../Loader/Loader";
// COMPONENT
import ProjectForm from "./Components/Form/ProjectForm";
import ProjectFormEdit from "./Components/Form/ProjectFormEdit";

const ProjectEdit = (props) => {
  const [loading, setLoading] = useState(false);
  const [ads, setAds] = useState(false);
  const getType = useContext(CombineContext);

  const [errors, setErrors] = useState({
    reference: false,
    state: false,
    category: false,
    type: false,
    price: false,
    area: false,
    commentLang: false,
  });

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    if (getType.typeValue.type === "360" && isMounted) {
      setAds(true);
    } else {
      setAds(false);
    }
    setLoading(false);
    return () => {
      isMounted = false;
    };
  }, [getType.typeValue.type]);
  if (ads && !loading)
    return (
      <Edit undoable={false} {...props}>
        <ProjectForm
          {...props}
          errors={errors}
          setErrors={setErrors}
          setLoading={setLoading}
        />
      </Edit>
    );
  else if (!ads && !loading) {
    return (
      <Edit undoable={false} {...props}>
        <ProjectFormEdit
          {...props}
          errors={errors}
          setErrors={setErrors}
          setLoading={setLoading}
        />
      </Edit>
    );
  } else {
    return <Loader />;
  }
};

export default ProjectEdit;
