import { Typography } from "@material-ui/core";
import React from "react";
import InputRange from "react-input-range";
import { Fade } from "@material-ui/core";
import "react-input-range/lib/css/index.css";
import "./index.css";

class PriceRentFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value5: {
        min: 0,
        max: 10000,
      },
    };
  }
  submitValue = (value) => {
    this.props.handleFilter("price", value);
  };

  render() {
    return (
      <Fade in={true} timeout={300} unmountOnExit>
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          <Typography
            style={{
              fontSize: "0.75rem",
              letterSpacing: "0.08333em",
              marginBottom: "1.5rem",
            }}
          >
            LOYER
          </Typography>

          <InputRange
            draggableTrack
            maxValue={10000}
            minValue={0}
            formatLabel={(value) => `${value} €`}
            onChange={(value) => this.setState({ value5: value })}
            onChangeComplete={(value) => this.submitValue(value)}
            value={this.state.value5}
          />
        </div>
      </Fade>
    );
  }
}

export default PriceRentFilter;
