/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormContainer,
  FormContainerFilter,
  FormGroup,
  FormInner,
  Input,
  Logo,
  LogoContainer,
  SendButton,
  TitleForm,
  TitleProject,
  TitleAgence,
} from "./Form.styles";
import { Fade } from "@material-ui/core";
import { useAlert } from "react-alert";
import {
  getContactExist,
  sendNewRequestContact,
  sendRequestContact,
} from "./axiosRequests";
import FormWrapper from "./FormWrapper";
import LangSwitcher from "./LangSwitcher";

const Form = (props) => {
  const alert = useAlert();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonemobile: "",
    context: props.context,
  });
  const price = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumSignificantDigits: 3,
  }).format(props.project.price);

  const handleChange = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const submit = async () => {
    if (
      state.email.length &&
      state.lastname.length &&
      state.firstname.length &&
      state.phonemobile.length
    ) {
      const dataFetch = await getContactExist(state.email);
      if (dataFetch.total > 0) {
        const agencyExist = await props.isAgency(dataFetch.data);
        if (agencyExist.length) {
          const leadsExist = await props.isLeads(agencyExist[0]);

          const sendContactForm = await sendRequestContact(
            agencyExist[0],
            props.project,
            props.agency,
            state,
            i18n.language
          );
          if (
            sendContactForm.status === 200 ||
            sendContactForm.status === 201
          ) {
            alert.show(`${t("alert.securepage.accessrequest")}`);
            setState({
              firstname: "",
              lastname: "",
              email: "",
              phonemobile: "",
            });
            setTimeout(() => {
              localStorage.setItem(props.project._id, "code");
              props.changeView("formMail");
            }, 2000);
          } else {
            alert.error(`${t("alert.error")}`);
          }
        } else {
          const sendContactForm = await sendNewRequestContact(
            props.project,
            props.agency,
            state,
            i18n.language
          );
          if (
            sendContactForm.status === 200 ||
            sendContactForm.status === 201
          ) {
            alert.show(`${t("alert.securepage.accessrequest")}`);
            setTimeout(() => {
              localStorage.setItem(props.project._id, "code");
              props.changeView("formMail");
            }, 2000);
            setState({
              firstname: "",
              lastname: "",
              email: "",
              phonemobile: "",
            });
          } else {
            alert.error(`${t("alert.error")}`);
          }
        }
      } else {
        const sendContactForm = await sendNewRequestContact(
          props.project,
          props.agency,
          state,
          i18n.language
        );
        if (sendContactForm.status === 200 || sendContactForm.status === 201) {
          alert.show(`${t("alert.securepage.accessrequest")}`);
          setTimeout(() => {
            localStorage.setItem(props.project._id, "code");
            props.changeView("formMail");
          }, 2000);
          setState({
            firstname: "",
            lastname: "",
            email: "",
            phonemobile: "",
          });
        } else {
          alert.error(`${t("alert.error")}`);
        }
      }
    } else {
      alert.error(`${t("alert.fields-required")}`);
    }
  };

  return (
    <Fade in={true} timeout={300}>
      <FormContainer
        image={
          props.project.images && props.project.images.length
            ? props.project.images[0].src
            : null
        }
      >
        <FormContainerFilter />
        <FormInner margin={props.margin}>
          <LogoContainer>
            <Logo
              src={
                "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-1-1-1536x478.png"
              }
              alt="logo"
            />
          </LogoContainer>
          <TitleAgence>{props.agency.name}</TitleAgence>
          <TitleProject>
            {props.project.type} {t("securepage.in")} {props.project.city}{" "}
            {t("securepage.of")} {props.project.area.total} m²{" "}
            {t("securepage.atthepriceof")} {price}
          </TitleProject>
          <TitleForm>
            {t("securepage.foraccess")}{" "}
            {props.context !== "doc"
              ? t("securepage.atthevisit")
              : t("securepage.atthefiles")}{" "}
            {t("securepage.mustlogin")}
          </TitleForm>
          <FormGroup>
            <Input
              type="text"
              name="lastname"
              placeholder={t("contact.form.lastname")}
              onChange={handleChange}
              value={state.lastname}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="firstname"
              placeholder={t("contact.form.firstname")}
              onChange={handleChange}
              value={state.firstname}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder={t("contact.form.email")}
              onChange={handleChange}
              value={state.email}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="phone"
              name="phonemobile"
              placeholder={t("contact.form.phonemobile")}
              onChange={handleChange}
              value={state.phonemobile}
            />
          </FormGroup>
          <FormGroup>
            <SendButton onClick={submit}>
              {t("securepage.wanttoaccess")}{" "}
              {props.context !== "doc"
                ? t("securepage.atthevisit")
                : t("securepage.atthefiles")}
            </SendButton>
          </FormGroup>
          <FormGroup>
            <LangSwitcher />
          </FormGroup>
        </FormInner>
      </FormContainer>
    </Fade>
  );
};

export default FormWrapper(Form);
