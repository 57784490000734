import React from "react";

const Ascenseur = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_517"
            data-name="Rectangle 517"
            width="36"
            height="36"
            transform="translate(-0.229)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="Groupe_1679" data-name="Groupe 1679" transform="translate(0.229)">
        <g id="Groupe_1644" data-name="Groupe 1644" clipPath="url(#clip-path)">
          <path
            id="Tracé_2924"
            data-name="Tracé 2924"
            d="M7.189,35.73H.632a.929.929,0,0,1-.632-1Q.008,17.864,0,1C0,.286.285,0,1,0Q17.859,0,34.72,0a.928.928,0,0,1,1,.632V35.1a.989.989,0,0,1-.628.628H28.536c-.713-.3-.553-.953-.584-1.512H7.779a2.946,2.946,0,0,1-.058.882,2.509,2.509,0,0,1-.532.63m26.963-1.566V1.577H1.58V34.157H6.213v-.372q0-12.454,0-24.908c0-.867.248-1.112,1.127-1.112H28.405c.852,0,1.106.253,1.106,1.1q0,12.454,0,24.908v.393ZM17.07,9.353H7.791V32.584H17.07Zm10.871,0H18.66V32.586h9.281Z"
            transform="translate(0 0.08)"
            fill={color}
          />
          <path
            id="Tracé_2925"
            data-name="Tracé 2925"
            d="M121.586,35.99c-.331.347-.612.654-.909.947a.78.78,0,1,1-1.106-1.08q.692-.712,1.4-1.4a.793.793,0,0,1,1.228-.007q.728.7,1.428,1.43a.783.783,0,0,1,.054,1.148.774.774,0,0,1-1.15-.056c-.31-.3-.6-.615-.95-.978"
            transform="translate(-108.387 -30.962)"
            fill={color}
          />
          <path
            id="Tracé_2926"
            data-name="Tracé 2926"
            d="M223.7,35.476c.333-.346.615-.654.914-.945a.8.8,0,0,1,1.176-.087.789.789,0,0,1-.073,1.176c-.462.474-.929.944-1.406,1.4a.786.786,0,0,1-1.227,0q-.715-.69-1.406-1.4a.781.781,0,1,1,1.107-1.081c.3.293.583.6.916.942"
            transform="translate(-201.181 -31.022)"
            fill={color}
          />
          <path
            id="Tracé_2927"
            data-name="Tracé 2927"
            d="M341.215,170.413a.754.754,0,0,1,.77.764.774.774,0,0,1-.771.772.8.8,0,0,1-.761-.756.773.773,0,0,1,.762-.78"
            transform="translate(-309.384 -154.782)"
            fill={color}
          />
          <path
            id="Tracé_2928"
            data-name="Tracé 2928"
            d="M341.982,205.11a.756.756,0,0,1-.762.772.776.776,0,0,1-.77-.773.8.8,0,0,1,.753-.763.772.772,0,0,1,.779.764"
            transform="translate(-309.383 -185.619)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default Ascenseur;
