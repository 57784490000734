import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import Hotspot from "./Hotspot";
import HotspotInfo from "./HotspotInfo";

const Scene = React.forwardRef((props, ref) => {
  const meshRef = useRef();
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered, props]);

  const click = (link) => {
    props.setCount((prev) => prev.count + 1);
    props.setLoadingTransition(true);
    setTimeout(() => {
      props.setCurrentLink(link);
    }, 100);
  };

  return (
    <group dispose={null}>
      <ambientLight intensity={1.2} />
      <directionalLight />
      {props.item.type === "360" && (
        <mesh ref={meshRef}>
          <sphereBufferGeometry
            attach="geometry"
            args={[500, 60, 40]}
            color="black"
          />
          <meshBasicMaterial
            attach="material"
            map={props.texture}
            side={THREE.DoubleSide}
          />
        </mesh>
      )}
      {props.item.type === "flat" || props.item.type === "plan" ? (
        <mesh ref={meshRef} scale={[100, 100, 1]} castShadow receiveShadow>
          <planeBufferGeometry attach="geometry" args={[0.031, 0.017]} />
          <meshBasicMaterial
            attach="material"
            map={props.texture}
            side={THREE.DoubleSide}
            reflectivity={1000}
          />
        </mesh>
      ) : null}
      {!props.autorotate &&
      props.item &&
      props.item.hotspotsLink &&
      props.item.hotspotsLink.length
        ? props.item.hotspotsLink.map((item, idx) => {
            return (
              <Hotspot
                key={idx}
                hovered={hovered}
                setHovered={setHovered}
                click={click}
                item={item}
                scenes={props.scenes}
              />
            );
          })
        : null}
      {!props.autorotate &&
      props.item &&
      props.item.hotspotsInfo &&
      props.item.hotspotsInfo.length
        ? props.item.hotspotsInfo.map((item, idx) => {
            return (
              <HotspotInfo
                key={idx}
                hovered={hovered}
                setHovered={setHovered}
                click={click}
                item={item}
                ref={{
                  canvasRef: ref.canvasRef,
                }}
              />
            );
          })
        : null}
    </group>
  );
});

export default Scene;
