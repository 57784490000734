/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ProjectSmallRow from "./ProjectSmallRow";
import { useTranslate } from "react-admin";
import {
  CombineContext,
  UiContext,
} from "../../../../../../../store/UserProvider";

const columns = [
  {
    id: "show",
    label: "resources.fields.show",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "reference",
    label: "resources.projects.fields.reference",
    minWidth: 90,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "city",
    label: "resources.projects.fields.city",
    minWidth: 90,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "price",
    label: "resources.projects.fields.price",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "area",
    label: "resources.projects.fields.area.total.short",
    minWidth: 90,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "rooms",
    label: "resources.projects.fields.rooms.short",
    minWidth: 0,
    maxWidth: 65,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "bedrooms",
    label: "resources.projects.fields.bedrooms.short",
    minWidth: 90,
    maxWidth: 90,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "edit",
    label: "resources.fields.edit",
    minWidth: 80,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "360",
    label: "resources.fields.create360.short",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const ProjectSmallForm = (props) => {
  const { ui, setUi } = useContext(UiContext);
  const translate = useTranslate();
  const classes = useStyles();
  const { data } = props;
  const getType = useContext(CombineContext);

  const [ads, setAds] = useState(false);
  useEffect(() => {
    const role = ["super_admin", "admin"];
    if (
      role.includes(props.user.permissions) ||
      getType.typeValue.type === "360"
    ) {
      setAds(true);
    }
  }, [getType.typeValue.type, props.user.permissions]);

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setUi((prevState) => ({
      ...prevState,
      project: { ...prevState.project, modeViewPage: newPage },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setUi((prevState) => ({
      ...prevState,
      project: {
        ...prevState.project,
        modeViewPage: 0,
        rowsPerPage: +event.target.value,
      },
    }));
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.id === "360" && !ads) {
                  return null;
                } else {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      }}
                    >
                      <span>{translate(column.label).toUpperCase()}</span>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(
                ui.project.modeViewPage * ui.project.rowsPerPage,
                ui.project.modeViewPage * ui.project.rowsPerPage +
                  ui.project.rowsPerPage
              )
              .map((row, idx) => {
                return (
                  <ProjectSmallRow
                    key={idx}
                    row={row}
                    slugAgence={props.slugAgence}
                    ads={ads}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={ui.project.rowsPerPage}
        page={ui.project.modeViewPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ProjectSmallForm;
