import React from "react";

import FacebookIcon from "@material-ui/icons/Facebook";
import LinkIcon from "@material-ui/icons/Link";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from "react-share";

import { config } from "../../../../../../../constants/constant";

// CSS
import "./index.css";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslate } from "react-admin";

export default function ModalShare(props) {
  const translate = useTranslate();
  const { infoProject, agency, setShow } = props;
  const link =
    config.APP_BASE + "/visite360/" + agency.slug + "/" + infoProject.slug;

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth>
      <DialogContent>
        <div className="modal-body pt-0 px-4 modal-light">
          <h4 className="mb-3">{translate("resources.modal.share.title")}</h4>
          <FacebookShareButton
            url={link}
            className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
          >
            <span className="mr-auto">Facebook</span>
            <span className="">
              <FacebookIcon fontSize="large" />
            </span>
          </FacebookShareButton>
          <div
            className="share-item d-flex align-items-center p-2 bg-light rounded mb-2"
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            <span className="mr-auto">
              {translate("resources.modal.share.copylink")}
            </span>
            <span className="">
              <LinkIcon fontSize="large" />
            </span>
          </div>
          <WhatsappShareButton
            url={link}
            className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
          >
            <span className="mr-auto">WhatsApp</span>
            <span className="">
              <WhatsAppIcon fontSize="large" />
            </span>
          </WhatsappShareButton>

          <FacebookMessengerShareButton
            url={link}
            // appId="" // Facebook application id obligatoire
            className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
          >
            <span className="mr-auto">Messenger</span>
            <span className="">
              <FacebookMessengerIcon
                round="true"
                size={36}
                bgStyle={{ fill: "#212529" }}
                iconFillColor="white"
              />
            </span>
          </FacebookMessengerShareButton>
          <EmailShareButton
            url={link}
            formTarget="target_blank"
            subject={translate("resources.modal.share.subjectmail")}
            body={translate("resources.modal.share.bodymail")}
            className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
          >
            <span className="mr-auto">E-mail</span>
            <span className="">
              <MailOutlinedIcon fontSize="large" />
            </span>
          </EmailShareButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}
