import { makeStyles } from "@material-ui/core/styles";

export const ModalPriceStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      maxWidth: "100%",
      margin: "32px",
    },
    "& .MuiDialogTitle-root": {
      textAlign: "center",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
    },
  },
}));
