import React from "react";
import { Box, Toolbar } from "@material-ui/core";
import { SaveButton } from "react-admin";

const ToolbarOffer = (props) => {
  return (
    <Toolbar>
      <Box display="flex" width="100%">
        <SaveButton
          saving={props.formProps.saving}
          handleSubmitWithRedirect={props.formProps.handleSubmitWithRedirect}
          disabled={props.formProps.pristine}
          redirect="list"
        />
      </Box>
    </Toolbar>
  );
};

export default ToolbarOffer;
