/* eslint-disable no-unused-vars */
import React from "react";
import {
  HeaderContainer,
  TotalProjectContainer,
  TotalText,
  TotalTextBold,
  TotalTextColor,
} from "../../Utils/Styles/DashboardComponents.styles";

import { useTranslate } from "react-admin";

const DashboardHeader = (props) => {
  const translate = useTranslate();
  const { total, open } = props;

  return (
    <HeaderContainer open={open}>
      <TotalProjectContainer open={open}>
        <TotalText>
          <TotalTextBold>{`${total} `}</TotalTextBold>
          {`${translate("resources.dashboard.admin.mandate")}${
            total > 1 ? "s" : ""
          }`}
          <TotalTextColor> visibble</TotalTextColor>{" "}
        </TotalText>
      </TotalProjectContainer>
    </HeaderContainer>
  );
};

export default DashboardHeader;
