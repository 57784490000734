import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const usePrint = () => {
  const printPdf = async (pdfName, nodeId) => {
    const doc = new jsPDF("p", "mm");
    const canvas = await html2canvas(document.getElementById(nodeId), {
      scale: 1,
      onclone: (document, element) => {
        // element.style.setProperty(
        //   "zoom",
        //   (1 / window.devicePixelRatio) * 100 + "%"
        // );
      },
      useCORS: true,
    });
    doc.addImage(canvas.toDataURL(), "jpg", 0, 0);
    doc.save(`${pdfName}.pdf`);
  };

  return printPdf;
};
