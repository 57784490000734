import React from "react";
import { Box, Toolbar } from "@material-ui/core";
import { SaveButton } from "react-admin";

const ToolbarContact = (props) => {
  const { formProps } = props;

  return (
    <Toolbar>
      <Box display="flex" width="100%">
        <SaveButton
          saving={formProps.saving}
          handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
          disabled={formProps.pristine}
          redirect="list"
        />
      </Box>
    </Toolbar>
  );
};

export default ToolbarContact;
