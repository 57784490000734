import React from "react";
import {
  AgencyLogo,
  AlurText,
  BottomContainer,
  BottomContainerLeft,
  BottomContainerRight,
  Category,
  ContainerAlur,
  ContainerPrice,
  DpeTextContainer,
  IconContainer,
  IconText,
  QrcodeContainer,
} from "./BerlinTemplatePortrait.styles";
import {
  Container,
  TitleContainer,
  TitleTop,
  TitleTopLeft,
  Title,
  TitleTopRight,
  Ref,
  TitleBottom,
  TitleBottomLeftContainer,
  TitleBottomRightContainer,
  TitleBottomLeft,
  ImgContainer,
  Img,
  Exclu,
  ExcluContainer,
  ContainerLogo,
  ContainerDescription,
  Description,
} from "./BerlinTemplatePortrait.styles";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import QRCode from "react-qr-code";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText as AlurMore } from "../../Modules/ModulesElements.styles";

const BerlinTemplatePortrait = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      mirror={mirror}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <TitleContainer>
        <TitleTop>
          <TitleTopLeft>
            {agency.images.length ? (
              <AgencyLogo src={agency.images[0].src} alt="logo" />
            ) : null}
          </TitleTopLeft>
          <TitleTopRight>
            <Category>
              {t(
                choices.category.filter(
                  (item) => item.id === project.category
                )[0].name
              )}
            </Category>
          </TitleTopRight>
        </TitleTop>
        <TitleBottom colorPrimary={color.primary || "#1E1E1E"}>
          <TitleBottomLeftContainer>
            <TitleBottomLeft
              colorTitle={colorTitle || "white"}
              size={titleTextFont.size}
              weight={titleTextFont.weight}
              family={titleTextFont.font}
            >
              {project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </TitleBottomLeft>
            <Title
              colorSubTitle={colorSubTitle || "white"}
              size={subTitleTextFont.size}
              weight={subTitleTextFont.weight}
              family={subTitleTextFont.font}
            >
              {subTitleText}
            </Title>
          </TitleBottomLeftContainer>
          <TitleBottomRightContainer>
            <Ref
              size={refFont.size}
              weight={refFont.weight}
              family={refFont.font}
              color={refFont.color}
            >
              {t("modalprint.reference")} : {refText}
            </Ref>
          </TitleBottomRightContainer>
        </TitleBottom>
      </TitleContainer>
      <ImgContainer>
        {isBanner === "true" && (
          <ExcluContainer colorPrimary={color.primary || "#C30010"}>
            <Exclu
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </Exclu>
          </ExcluContainer>
        )}
        <Img src={imageFirst} brightness={brightness} saturation={saturation} />
      </ImgContainer>
      <ContainerLogo>
        <IconContainer>
          <Surface color={colorSecondary || "black"} size={55} />
          <IconText colorSecondary={colorSecondary || "black"}>
            {project.area.total} m2
          </IconText>
        </IconContainer>
        {project.rooms && project.rooms > 0 ? (
          <IconContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Rooms color={colorSecondary || "black"} size={55} />
            <IconText colorSecondary={colorSecondary || "black"}>
              {project.rooms} {t("modalprint.rooms")}
            </IconText>
          </IconContainer>
        ) : null}
        {project.bedrooms && project.bedrooms > 0 ? (
          <IconContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Chambres color={colorSecondary || "black"} size={55} />
            <IconText colorSecondary={colorSecondary || "black"}>
              {project.bedrooms} {t("modalprint.bedrooms")}
            </IconText>
          </IconContainer>
        ) : null}
        {project.sdb && project.sdb > 0 ? (
          <IconContainer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Sdb color={colorSecondary || "black"} size={55} />
            <IconText colorSecondary={colorSecondary || "black"}>
              {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
            </IconText>
          </IconContainer>
        ) : null}
      </ContainerLogo>
      <ContainerDescription colorPrimary={color.primary || "#1E1E1E"}>
        <Description
          size={descFont.size}
          weight={descFont.weight}
          family={descFont.font}
          colorDesc={colorDesc || "white"}
        >
          {desc}
        </Description>
      </ContainerDescription>
      <BottomContainer>
        <BottomContainerLeft>
          <ContainerPrice>
            <ModulePrice
              colorPrice={colorPrice || "black"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </ContainerPrice>
          <ContainerAlur>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={"black"}
                price={price}
                sizeText={"11pt"}
                noDpeInfo
              />
              <AlurMore sizeText={"11pt"} colorDesc={colorDesc || "black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurMore>
            </RenderContext>
          </ContainerAlur>
          {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
            <QrcodeContainer>
              <QRCode value={qrcodeEdit} size={130} />
            </QrcodeContainer>
          )}
        </BottomContainerLeft>
        <BottomContainerRight>
          <RenderContext project={project}>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                top: "150px",
                left: "35px",
              }}
            >
              <Dpe
                letter={
                  project.dpeLetter
                    ? DpeGesNoValue.includes(project.dpeLetter)
                      ? "vi"
                      : project.dpeLetter
                    : "vi"
                }
                conso={project.dpeValue}
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "0.22"
                    : "0.25"
                }
                topTitle={"logement extrêmement performant"}
                bottomTitle={"logement extrêmement peu performant"}
                redInfoTop={"passoire"}
                redInfoBottom={"énergetique"}
                label={"consommation"}
                secondLabel={"émissions"}
                subLabel={"(énergie primaire)"}
                subValue={"kWh/m2/an"}
                secondSubValue={"kg CO₂/m²/an"}
                theme={"light"}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                bottom:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "125px"
                    : "55px",
                left:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "320px"
                    : "365px",
              }}
            >
              <Ges
                letter={
                  project.gesLetter
                    ? DpeGesNoValue.includes(project.gesLetter)
                      ? "vi"
                      : project.gesLetter
                    : "vi"
                }
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "0.50"
                    : "0.30"
                }
                theme={"light"}
              />
            </div>
            <DpeTextContainer>
              <AlurText colorDesc={colorDesc}>
                {t("modalprint.dpeMinMaxInfo")} :{" "}
                {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                  <>
                    {`${t("modalprint.between")} ${
                      project.dpeCoutMinConso
                    }€ ${t("modalprint.and")} ${project.dpeCoutMaxConso}€ ${t(
                      "modalprint.perYear"
                    )} `}
                  </>
                ) : (
                  t("details.modalprint.undisclosed")
                )}{" "}
                {project.dpeCoutMinConso &&
                  project.dpeCoutMinConso !== "" &&
                  project.dpeAnneRefConso &&
                  project.dpeAnneRefConso !== "" &&
                  t("modalprint.refYear") +
                    " " +
                    project.dpeAnneRefConso +
                    " " +
                    t("modalprint.refYearEnd")}
              </AlurText>
            </DpeTextContainer>
          </RenderContext>
        </BottomContainerRight>
      </BottomContainer>
    </Container>
  );
});

export default BerlinTemplatePortrait;
