import React from "react";
import { CombineContext } from "../../../../../store/UserProvider";
import { Redirect } from "react-router-dom";
const formWrapper = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: null,
      };
    }

    static contextType = CombineContext;

    async componentDidMount() {
      if (this.props.record && !this.props.record.reference) {
        this.setState({ fromImport: false });
      }
      const context = await this.context;
      let userSelect;
      if (context.valueChange.userChange) {
        userSelect = context.valueChange.userChange;
      } else {
        userSelect = context.value.user;
      }

      if (userSelect.agenceId) {
        this.setState({ agency: context.agencyValue.agency });
      }
      if (
        userSelect.agenceId !== this.props.record.agenceId &&
        this.props.record.id
      ) {
        this.setState({ errorMandat: true });
      }
      if (userSelect) {
        this.setState({ user: userSelect });
        const record = this.props.record;
        const basePath = this.props.basePath;
        // IF RECORD EDIT MODE
        if (record && basePath === "/projects") {
          // NOT USER IN RECORD SET USER
          if (record.userId === undefined) {
            this.setState({ userId: userSelect._id });
          }
          // NOT AGENCEID AND USER HAS AGENCEID AND IT'S PROJECTS SET AGENCEID
          if (
            record.agenceId === undefined &&
            userSelect.agenceId !== undefined
          ) {
            this.setState({ agenceId: userSelect.agenceId });
          }
          // ELSE CREATE MODE
        } else {
          // SET USER
          this.setState({ userId: userSelect._id });
          // IF USER HAVE AGENCEID SET AGENCEID
          if (
            userSelect.agenceId !== undefined &&
            this.props.basePath === "/projects"
          ) {
            this.setState({ agenceId: userSelect.agenceId });
          }
        }
      }
    }

    onPlaceSelect = (value) => {
      if (value !== null) {
        this.setState({
          addressfull: value.properties.formatted,
          address: value.properties.address_line1,
          postalcode: value.properties.postcode,
          city: value.properties.city,
          country: value.properties.country,
          lat: value.properties.lat,
          lon: value.properties.lon,
          enableSaveAddress: false,
        });
      }
    };

    handleChangeMetaTitle = (e) => {
      if (e.target.value) {
        this.setState({
          metatitle: e.target.value,
          enableSaveAddress: false,
        });
      } else {
        this.setState({ metatitle: "", enableSaveAddress: true });
      }
    };
    handleChangeMetaDescription = (e) => {
      if (e.target.value) {
        this.setState({
          metadescription: e.target.value,
          enableSaveAddress: false,
        });
      } else {
        this.setState({ metadescription: "", enableSaveAddress: true });
      }
    };
    handleChangeSlug = (e) => {
      if (e.target.value) {
        this.setState({ slug: e.target.value, enableSaveAddress: false });
      } else {
        this.setState({ slug: "", enableSaveAddress: true });
      }
    };
    render() {
      if (this.state.userId === null && this.state.user === null) {
        return null;
      } else {
        return (
          <>
            {this.state.errorMandat && <Redirect to="/" />}
            <WrappedComponent
              initialValues={this.state}
              history={this.props.history}
              onPlaceSelect={this.onPlaceSelect}
              enableSaveAddress={
                this.state.enableSaveAddress && this.state.enableSaveAddress
              }
              user={this.state.user}
              agency={this.state.agency}
              handleChangeMetaTitle={this.handleChangeMetaTitle}
              handleChangeMetaDescription={this.handleChangeMetaDescription}
              handleChangeSlug={this.handleChangeSlug}
              {...this.props}
            >
              {this.props.children}
            </WrappedComponent>
          </>
        );
      }
    }
  };
};

export default formWrapper;
