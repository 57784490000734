import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./i18n/en.json";
import translationFR from "./i18n/fr.json";
import translationIT from "./i18n/it.json";
import translationES from "./i18n/es.json";
import translationPT from "./i18n/pt.json";
import translationDE from "./i18n/de.json";
import translationNL from "./i18n/nl.json";
import notaireFR from "./i18n/notaireFR.json";

let defaultLanguage = "fr-FR";

// the translations
const resources = {
  "fr-FR": {
    translation: translationFR,
  },
  "en-US": {
    translation: translationEN,
  },
  "it-IT": {
    translation: translationIT,
  },
  "es-ES": {
    translation: translationES,
  },
  "pt-PT": {
    translation: translationPT,
  },
  "de-DE": {
    translation: translationDE,
  },
  "nl-NL": {
    translation: translationNL,
  },
  notaire: {
    translation: notaireFR,
  },
};

const storageLanguage = localStorage.getItem("i18nextLng");
if (storageLanguage) {
  defaultLanguage = storageLanguage;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    keySeparator: ".", // to support nested translations
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
