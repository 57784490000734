import styled from "styled-components";

export const ColorChoiceContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const PickerContainer = styled.div`
  && {
    margin-right: ${({ marginRight }) =>
      marginRight ? marginRight : "initial"};
    text-align: center;
  }
`;

export const PickerTextName = styled.p``;
