/* eslint-disable no-unused-vars */
import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { config } from "../../../constants/constant";
import { deleteFolderContents } from "../../../firebase/firebase.function";
import { uploadImage, uploadVideo, uploadFiles } from "./uploadFunction";
import { getProjectWithAgenceId } from "./axiosRequests";
// const httpClient = fetchUtils.fetchJson;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("caller", "dataProvider");
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = (user) => {
  const queryOperators = [
    "$gte",
    "$lte",
    "$ne",
    "$sort",
    "$or",
    "$nin",
    "$in",
    "$like",
    "$search",
  ];

  const options = {
    user: {
      authenticated: true,
      token: user ? user.accessToken : "",
    },
  };
  const query = {};

  return {
    getList: (resource, params) => {
      for (const prop of Object.getOwnPropertyNames(query)) {
        delete query[prop];
      }

      const { page, perPage } = params.pagination || {};
      const { field, order } = params.sort || {};
      if (perPage && page) {
        query.$limit = perPage;
        query.$skip = perPage * (page - 1);
      }
      if (order) {
        query[`$sort[${field}]`] = `${order === "ASC" ? 1 : -1}`;
      }
      const filters = params.filter || null;
      if (filters) {
        const filter = {};
        for (const property in filters) {
          for (let index = 0; index < queryOperators.length; ++index) {
            const operator = queryOperators[index];
            if (property.includes(operator)) {
              if (operator === "$like") {
                const fields = property.split("_")[0].split("-");
                for (let i = 0; i < fields.length; i++) {
                  filter[`$or[${i}][${fields[i]}][$regex]`] = filters[property];
                  filter[`$or[${i}][${fields[i]}][$options]`] = "igm";
                }
              } else if (operator === "$search") {
                filter["$text[$search]"] = filters[property];
              } else {
                filter[`${property.replace("_" + operator, "")}[${operator}]`] =
                  filters[property];
              }
            }
          }
        }
        Object.assign(query, filter);
      }

      console.log("here");
      const url = `${config.API_BASE}/${resource}?${stringify(query)}`;
      return httpClient(url, options).then(({ headers, json }) => {
        return {
          data: json.data.map((element) => ({ ...element, id: element._id })),
          total: json.total,
        };
      });
    },

    getOne: (resource, params) => {
      return httpClient(`${config.API_BASE}/${resource}/${params.id}`, options)
        .then(({ json }) => {
          return {
            data: { ...json, id: json._id },
          };
        })
        .catch((error) => {
          return error;
        });
    },

    getMany: (resource, params) => {
      const ids = params.ids || [];
      for (let i = 0; i < ids.length; i++) {
        query["_id[$in]"] = ids[i];
      }
      const url = `${config.API_BASE}/${resource}?${stringify(query)}`;
      return httpClient(url, options).then(({ json }) => ({
        data: json.data.map((element) => ({ ...element, id: element._id })),
        total: json.total,
      }));
    },

    getManyReference: (resource, params) => {
      const query = {};
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      if (perPage && page) {
        query.$limit = perPage;
        query.$skip = perPage * (page - 1);
      }
      if (order) {
        query[`$sort[${field}]`] = `${order === "ASC" ? 1 : -1}`;
      }
      if (params.target && params.id) {
        query[params.target] = params.id;
      }

      const url = `${config.API_BASE}/${resource}?${stringify(query)}`;
      return httpClient(url, options).then(({ headers, json }) => ({
        data: json.data.map((element) => ({ ...element, id: element._id })),
        total: json.total,
      }));
    },

    update: (resource, params) => {
      return httpClient(`${config.API_BASE}/${resource}/${params.id}`, {
        method: "PATCH",
        body: JSON.stringify(params.data),
        user: {
          authenticated: true,
          token: user.accessToken,
        },
      }).then(({ json }) => ({ data: { ...json, id: json._id } }));
    },

    updateMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      return httpClient(`${config.API_BASE}/${resource}?${stringify(query)}`, {
        method: "PUT",
        body: JSON.stringify(params.data),
        user: {
          authenticated: true,
          token: user.accessToken,
        },
      }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
      delete params.data.user;
      return httpClient(`${config.API_BASE}/${resource}`, {
        method: "POST",
        body: JSON.stringify(params.data),
        user: {
          authenticated: true,
          token: user.accessToken,
        },
      }).then(({ json }) => ({
        data: {
          ...params.data,
          id: json._id,
        },
      }));
    },

    delete: (resource, params) => {
      return httpClient(`${config.API_BASE}/${resource}/${params.id}`, {
        method: "DELETE",
        user: {
          authenticated: true,
          token: user.accessToken,
        },
      }).then(({ json }) => ({
        data: { result: "deleted" },
      }));
    },

    deleteMany: (resource, params) => {
      let query = "";
      for (let i = 0; i < params.ids.length; i++) {
        query += "$or[" + i + "][_id]=" + params.ids[i] + "&";
      }

      return httpClient(`${config.API_BASE}/${resource}?${query}`, {
        method: "DELETE",
        body: JSON.stringify(params.data),
        user: {
          authenticated: true,
          token: user.accessToken,
        },
      }).then(({ json }) => ({ data: json }));
    },
  };
};

const myDataProvider = (user) => {
  if (!user) {
    user = {
      accessToken: "",
    };
  }

  return {
    ...dataProvider(user),
    create: async (resource, params) => {
      if (!params.data.images && !params.data.medias && !params.data.files) {
        return dataProvider(user).create(resource, params);
      }
      return dataProvider(user)
        .create(resource, params)
        .then(async (response) => {
          let newVideo;
          let newVid;
          let dataImg;
          let newPics;
          let newFiles;
          let newFls;
          if (params.data.images) {
            dataImg = await uploadImage(
              params,
              resource,
              response.data.id,
              "images"
            );
            newPics = dataImg.newPics;
          } else {
            newPics = [];
          }
          if (params.data.medias) {
            newVideo = await uploadVideo(params, resource, response.data.id);
            newVid = newVideo.newVid;
          } else {
            newVid = [];
          }
          if (params.data.files) {
            newFiles = await uploadFiles(params, resource, response.data.id);
            newFls = newFiles.newf;
          } else {
            newFls = [];
          }

          return Promise.all(newPics, newVid, newFls).then(() => {
            return dataProvider(user).update(resource, {
              id: response.data.id,
              data: {
                images: [...newPics],
                medias: [...newVid],
                files: [...newFls],
              },
            });
          });
        });
    },

    update: async (resource, params) => {
      if (
        !params.data.images &&
        !params.data.medias &&
        !params.data.header &&
        !params.data.footer &&
        !params.data.background &&
        !params.data.headerPortrait &&
        !params.data.footerPortrait &&
        !params.data.backgroundPortrait
      ) {
        // fallback to the default implementation
        await deleteFolderContents(resource + "/" + params.data.id);
        return dataProvider(user).update(resource, params);
      }

      if (
        (params.data.images &&
          params.data.medias &&
          params.data.files &&
          params.data.header &&
          params.data.footer &&
          params.data.background &&
          !params.data.headerPortrait &&
          !params.data.footerPortrait &&
          !params.data.backgroundPortrait) ||
        params.data.images ||
        params.data.medias ||
        params.data.files ||
        params.data.header ||
        params.data.footer ||
        params.data.background ||
        params.data.headerPortrait ||
        params.data.footerPortrait ||
        params.data.backgroundPortrait
      ) {
        let dataVideo = false;
        let newVid;
        let formerVideo;
        let dataFiles;
        let newFls;
        let formerFiles;

        const dataImg = await uploadImage(params, resource, null, "images");
        const dataHeader = await uploadImage(params, resource, null, "header");
        const dataFooter = await uploadImage(params, resource, null, "footer");
        const dataBackground = await uploadImage(
          params,
          resource,
          null,
          "background"
        );
        const dataHeaderPortrait = await uploadImage(
          params,
          resource,
          null,
          "headerPortrait"
        );
        const dataFooterPortrait = await uploadImage(
          params,
          resource,
          null,
          "footerPortrait"
        );
        const dataBackgroundPortrait = await uploadImage(
          params,
          resource,
          null,
          "backgroundPortrait"
        );

        if (params.data.medias) {
          dataVideo = await uploadVideo(params, resource);
          newVid = dataVideo.newVid;
          formerVideo = dataVideo.formerMedias;
        }
        if (params.data.files) {
          dataFiles = await uploadFiles(params, resource);
          newFls = dataFiles.newf;
          formerFiles = dataFiles.formerFiles;
        }
        const newPics = dataImg.newPics;
        const formerPictures = dataImg.formerPictures;

        return Promise.all(newPics).then(() => {
          return dataProvider(user).update(resource, {
            ...params,
            data: {
              ...params.data,
              images: [...formerPictures, ...newPics],
              medias: dataVideo ? [...formerVideo, ...newVid] : null,
              files: dataFiles ? [...formerFiles, ...newFls] : null,
              header: [...dataHeader.formerPictures, ...dataHeader.newPics],
              footer: [...dataFooter.formerPictures, ...dataFooter.newPics],
              background: [
                ...dataBackground.formerPictures,
                ...dataBackground.newPics,
              ],
              headerPortrait: [
                ...dataHeaderPortrait.formerPictures,
                ...dataHeaderPortrait.newPics,
              ],
              footerPortrait: [
                ...dataFooterPortrait.formerPictures,
                ...dataFooterPortrait.newPics,
              ],
              backgroundPortrait: [
                ...dataBackgroundPortrait.formerPictures,
                ...dataBackgroundPortrait.newPics,
              ],
            },
          });
        });
      }
    },

    delete: async (resource, params) => {
      await deleteFolderContents(resource + "/" + params.id);

      return dataProvider(user).delete(resource, params);
    },

    deleteMany: async (resource, params) => {
      for (let i = 0; i < params.ids.length; i++) {
        await deleteFolderContents(resource + "/" + params.ids[i]);
      }
      return dataProvider(user).deleteMany(resource, params);
    },
  };
};

export default myDataProvider;
