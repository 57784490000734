import React, { useEffect, useRef } from "react";
import {
  IconType,
  MenuContainer,
  MenuContainerInner,
  MenuLinkItem,
  MenuLinkItemImg,
  MenuLinks,
  MenuLinksInner,
  MenuTopShape,
  OpenMenuIcon,
  SceneNameContainer,
  SceneNameText,
} from "./Menu.style";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import knob from "../../assets/gallery-knob.svg";
import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

const Menu = (props) => {
  const scrollRef = useRef();
  const click = (index) => {
    props.setCount((prev) => prev.count + 1);
    props.setLoadingTransition(true);
    setTimeout(() => {
      props.setCurrentLink(index);
    }, 100);
  };

  useEffect(() => {
    scrollRef.current.addEventListener(
      "mousewheel",
      function (e) {
        const isTouchPad = e.wheelDeltaY
          ? e.wheelDeltaY === -3 * e.deltaY
          : e.deltaMode === 0;
        if (!isTouchPad) {
          this.scrollLeft -= e.wheelDelta;
          e.preventDefault();
        }
      },
      false
    );
  }, []);

  return (
    <MenuContainer open={props.autorotate ? true : props.open}>
      <MenuContainerInner>
        {!props.autorotate && (
          <MenuTopShape src={knob} onClick={() => props.setOpen(!props.open)}>
            <OpenMenuIcon as={ExpandMoreIcon} open={props.open} />
          </MenuTopShape>
        )}
        <MenuLinks>
          <MenuLinksInner ref={scrollRef} center={props.scenes.length}>
            {props.scenes.map((item, idx) => {
              return (
                <MenuLinkItem
                  onClick={() => click(item.id)}
                  key={idx}
                  isActive={props.scenes[idx].current === true}
                >
                  <MenuLinkItemImg
                    src={item.finalUrl ? item.finalUrl : item.url}
                    open={props.open}
                  />
                  <IconType
                    as={
                      item.type === "360" ? ThreeDRotationIcon : PhotoCameraIcon
                    }
                  />
                  <SceneNameContainer>
                    <SceneNameText>{item.name}</SceneNameText>
                  </SceneNameContainer>
                </MenuLinkItem>
              );
            })}
          </MenuLinksInner>
        </MenuLinks>
      </MenuContainerInner>
    </MenuContainer>
  );
};

export default Menu;
