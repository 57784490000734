import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  font-family: ${(props) => props.fontFamily};
  transform: translate(-50%, -50%) scale(${(props) => props.zoom});
  background-color: ${(props) => props.color};
`;

export const TopTitle = styled.h3`
  position: absolute;
  top: 45px;
  left: 460px;
  font-size: 18pt;
  margin: 3px 0;
  color: #01a06c;
`;

export const Img = styled.img``;

export const ValueContainer = styled.div`
  position: absolute;
  left: 50px;
  display: flex;
  width: 410px;
  height: 255px;
  top: ${(props) => props.top};
`;

export const LeftContainer = styled.div`
  width: 54%;
  margin-right: 5px;
`;
export const RightContainer = styled.div`
  width: 46%;
`;

export const Label = styled.h3`
  font-size: 18pt;
  margin: 3px 0;
  color: ${(props) => props.color};
  visibility: ${(props) => props.visibility};
`;

export const ConsoContainer = styled.div`
  margin-top: 15px;
  height: 185px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Value = styled.h2`
  font-size: 55pt;
  font-weight: 900;
  margin: 0px;
  position: relative;
  color: ${(props) => props.color};
`;

export const SubValue = styled.h2`
  font-size: 18pt;
  font-weight: 700;
  margin: 0px;
  color: ${(props) => props.color};
  text-align: center;
`;

export const SpanSubValue = styled.span`
  font-size: 50pt;
  font-weight: 500;
  position: absolute;
  top: 3px;
  margin-left: 3px;
`;

export const RedInfoContainer = styled.div`
  position: absolute;
  left: 260px;
  top: 800px;
`;

export const RedInfoText = styled.p`
  font-size: 20pt;
  font-weight: 800;
  margin: 2px 0;
  text-align: ${(props) => props.align};
  color: #939598;
  color: ${(props) => (props.dark ? "white" : "#939598")};
`;

export const BottomTitle = styled.h3`
  position: absolute;
  top: 960px;
  left: 460px;
  font-size: 19pt;
  margin: 3px 0;
  letter-spacing: 1px;
  color: #d71d1f;
`;
export const Svg = styled.div``;
