import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import "../../Utils/Styles/modal.css";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "95%",
  },
}));

const Modal = (props) => {
  const translate = useTranslate();
  const alert = useAlert();
  const { choiceImport, importMandats, setModal, modal } = props;
  const [selected, setSelected] = useState("");
  const classes = useStyles();

  useEffect(() => {}, [choiceImport, modal]);

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const cancel = () => {
    setModal(false);
    props.setLoading(false);
  };

  const sendChoice = async () => {
    setModal(false);
    const data = await importMandats(selected);
    if (data) {
      alert.show(translate("resources.alert.finishImport"));
      setModal(false);
      await props.refreshList();
    }
  };

  if (choiceImport.length === 0) {
    return <div />;
  } else {
    return (
      <div>
        <Dialog open={modal}>
          <DialogTitle>
            {translate("resources.agencies.selectagency")}
          </DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-dialog-select-label">
                  {translate("resources.agencies.tabname")}
                </InputLabel>
                <Select
                  labelId="demo-dialog-select-label"
                  id="demo-dialog-select-label"
                  value={selected}
                  onChange={handleChange}
                  input={<Input />}
                  fullWidth
                >
                  {choiceImport &&
                    choiceImport.map((choice, idx) => {
                      return (
                        <MenuItem value={choice._id} key={idx}>
                          {choice.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancel()}>
              {translate("resources.fields.cancel").toUpperCase()}
            </Button>
            <Button
              disabled={selected === ""}
              onClick={() => sendChoice()}
              color="primary"
            >
              {translate("resources.fields.validate").toUpperCase()}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

export default Modal;
