import React, { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Body,
  Button,
  ButtonText,
  ChatIconSvg,
  Container,
  Header,
  Hero,
  HeroInfoBody,
  HeroInfoBodyContainer,
  HeroInfoBodyRef,
  HeroInfoContainer,
  HeroInfoTitleContainer,
  HeroTitle,
  Icon360,
  IconContainer,
  ImageHeader,
  ImageHero,
  PhoneIconSvg,
  SendIconSvg,
} from "./VisibbleViewerMobile/Utils/Styles/MobileView.styles";
import ContactForm from "../../Components/360/ContactForm";
import { choice } from "./Utils/dataChoice";
import {
  colorPrimary,
  colorQuaternary,
  colorTertiary,
} from "../../GlobalStyles";
import PhoneIcon from "@material-ui/icons/Phone";
import ForumIcon from "@material-ui/icons/Forum";
import SendIcon from "@material-ui/icons/Send";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ModalContact from "../../Components/ModalContact";
import Home360 from "./Home360";
import ModalGallery from "../../Components/ModalGallery";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PdfDetails from "./PdfDetails";
import { usePrint } from "../../hooks/usePrint";

const HomeQrCode = (props) => {
  const { t, i18n } = useTranslation();
  const [selectView, setSelectView] = useState("home");
  const pdfRef = createRef(null);
  const printPdf = usePrint();

  useEffect(() => {
    const enums = ["fr-FR", "en-US", "it-IT"];
    const value = navigator.language === "en-GB" ? "en-US" : navigator.language;
    if (enums.includes(value)) {
      i18n.changeLanguage(navigator.language);
    } else {
      i18n.changeLanguage("fr-FR");
    }
  }, [i18n]);

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      {selectView === "home" && (
        <Container backdrop={props.secureForm}>
          {props.secureForm && (
            <ContactForm
              agency={props.agency}
              project={props.infoProject}
              setSend={props.setSend}
              match={props.match}
            />
          )}
          <Header>
            <ImageHeader
              src={
                props.agency.images && props.agency.images.length
                  ? props.agency.images[0].src
                  : "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-1-1-1536x478.png"
              }
              alt="logo"
            />
          </Header>
          <Hero>
            {
              <ImageHero
                src={
                  props.infoProject.images
                    ? props.infoProject.images[0].src
                    : null
                }
                alt="apercu du bien"
              />
            }
            <HeroInfoContainer>
              <HeroInfoTitleContainer>
                <HeroTitle>
                  {choice.type.filter(
                    (item) => item.id === props.infoProject.type
                  ).length
                    ? t(
                        choice.type.filter(
                          (item) => item.id === props.infoProject.type
                        )[0].name
                      )
                    : props.infoProject.type}
                  {" - "}
                  {t(
                    choice.category.filter(
                      (item) => item.id === props.infoProject.category
                    )[0].name
                  )}{" "}
                  {props.agency.name}
                </HeroTitle>
              </HeroInfoTitleContainer>
              <HeroInfoBodyContainer>
                <HeroInfoBody>{`${getPrice(props.infoProject.price)} ${
                  props.infoProject.devise ? props.infoProject.devise : "€"
                }${
                  props.infoProject.category === "Location" ||
                  props.infoProject.category === "Rental"
                    ? "/mois C.C"
                    : ""
                }`}</HeroInfoBody>
                <HeroInfoBodyRef>
                  Réf: {props.infoProject.reference}
                </HeroInfoBodyRef>
              </HeroInfoBodyContainer>
            </HeroInfoContainer>
          </Hero>
          <Body>
            {props.is360 && (
              <Button
                $backgroundColor={
                  props.vmlive
                    ? colorQuaternary
                    : props.is360
                    ? colorTertiary
                    : colorPrimary
                }
                onClick={() => setSelectView("360")}
              >
                <IconContainer>
                  <Icon360>360°</Icon360>
                </IconContainer>
                <ButtonText>{t("mobileView.visit")}</ButtonText>
              </Button>
            )}
            {props.user &&
              (props.user.phonemobile || props.user.whatsapp) &&
              !props.vmlive && (
                <Button
                  as={"a"}
                  href={`https://api.whatsapp.com/send?phone=${
                    props.user.whatsapp
                      ? props.user.whatsapp
                      : props.user.phonemobile
                  }`}
                  target="target_blank"
                  $backgroundColor={!props.is360 ? colorPrimary : "white"}
                  border={
                    props.vmlive
                      ? colorQuaternary
                      : props.is360
                      ? colorTertiary
                      : null
                  }
                >
                  <IconContainer>
                    <ChatIconSvg
                      $color={
                        props.vmlive
                          ? colorQuaternary
                          : props.is360
                          ? colorTertiary
                          : null
                      }
                      as={ForumIcon}
                    />
                  </IconContainer>
                  <ButtonText>{t("mobileView.chatwithagency")}</ButtonText>
                </Button>
              )}
            <Button
              data-toggle="modal"
              data-target="#modal-contact"
              $backgroundColor={
                props.vmlive
                  ? colorQuaternary
                  : props.user &&
                    (props.user.phonemobile || props.user.whatsapp)
                  ? "white"
                  : props.is360
                  ? colorTertiary
                  : colorPrimary
              }
              border={
                props.vmlive
                  ? null
                  : props.user &&
                    (props.user.phonemobile || props.user.whatsapp)
                  ? props.is360
                    ? colorTertiary
                    : colorPrimary
                  : null
              }
            >
              <IconContainer>
                <SendIconSvg
                  $color={
                    props.vmlive
                      ? null
                      : props.user &&
                        (props.user.phonemobile || props.user.whatsapp)
                      ? props.is360
                        ? colorTertiary
                        : colorPrimary
                      : null
                  }
                  as={SendIcon}
                />
              </IconContainer>
              <ButtonText>{t("mobileView.leavemessage")}</ButtonText>
            </Button>
            {props.agency.calendar ? (
              <Button
                as={"a"}
                target="target_blank"
                href={props.agency.calendar}
                $backgroundColor={
                  props.vmlive
                    ? colorQuaternary
                    : props.user &&
                      (props.user.phonemobile || props.user.whatsapp)
                    ? "white"
                    : props.is360
                    ? colorTertiary
                    : colorPrimary
                }
                border={
                  props.vmlive
                    ? null
                    : props.user &&
                      (props.user.phonemobile || props.user.whatsapp)
                    ? props.is360
                      ? colorTertiary
                      : colorPrimary
                    : null
                }
              >
                <IconContainer>
                  <SendIconSvg
                    $color={
                      props.vmlive
                        ? null
                        : props.user &&
                          (props.user.phonemobile || props.user.whatsapp)
                        ? props.is360
                          ? colorTertiary
                          : colorPrimary
                        : null
                    }
                    as={EventNoteIcon}
                  />
                </IconContainer>
                <ButtonText>{t("mobileView.rdv")}</ButtonText>
              </Button>
            ) : null}
            {props.agency.phone && (
              <Button
                as={"a"}
                href={`tel:${props.agency.phone}`}
                $backgroundColor={"white"}
                border={
                  props.vmlive
                    ? colorQuaternary
                    : props.is360
                    ? colorTertiary
                    : colorPrimary
                }
              >
                <IconContainer>
                  <PhoneIconSvg
                    $color={
                      props.vmlive
                        ? colorQuaternary
                        : props.is360
                        ? colorTertiary
                        : colorPrimary
                    }
                    as={PhoneIcon}
                  />
                </IconContainer>
                <ButtonText>{t("mobileView.callagency")}</ButtonText>
              </Button>
            )}

            {props.vmlive && (
              <Button
                $backgroundColor={"white"}
                data-toggle="modal"
                data-target="#modal-gallery"
                border={
                  props.vmlive
                    ? colorQuaternary
                    : props.is360
                    ? colorTertiary
                    : colorPrimary
                }
              >
                <IconContainer>
                  <ChatIconSvg
                    $color={
                      props.vmlive
                        ? colorQuaternary
                        : props.is360
                        ? colorTertiary
                        : colorPrimary
                    }
                    as={PhotoLibraryIcon}
                  />
                </IconContainer>
                <ButtonText>{t("mobileView.morepicture")}</ButtonText>
              </Button>
            )}
            {props.infoProject.images && (
              <Button
                $backgroundColor={"white"}
                onClick={() => printPdf("fiche", "pdf-page")}
                border={
                  props.vmlive
                    ? colorQuaternary
                    : props.is360
                    ? colorTertiary
                    : colorPrimary
                }
              >
                <IconContainer>
                  <ChatIconSvg
                    $color={
                      props.vmlive
                        ? colorQuaternary
                        : props.is360
                        ? colorTertiary
                        : colorPrimary
                    }
                    as={PictureAsPdfIcon}
                  />
                </IconContainer>
                <ButtonText>{t("mobileView.downloadDetails")}</ButtonText>
              </Button>
            )}
            {props.agency.propertyValue && props.agency.propertyValue.length ? (
              <Button
                $backgroundColor={"white"}
                as="a"
                href={props.agency.propertyValue}
                target="_blank"
                border={
                  props.vmlive
                    ? colorQuaternary
                    : props.is360
                    ? colorTertiary
                    : colorPrimary
                }
              >
                <IconContainer>
                  <ChatIconSvg
                    $color={
                      props.vmlive
                        ? colorQuaternary
                        : props.is360
                        ? colorTertiary
                        : colorPrimary
                    }
                    as={EuroSymbol}
                  />
                </IconContainer>
                <ButtonText>{t("mobileView.estimatePropertyShort")}</ButtonText>
              </Button>
            ) : null}
          </Body>
        </Container>
      )}
      {selectView === "360" && (
        <Home360
          secureUnlock={props.secureUnlock}
          getAllClick={props.getAllClick}
          setSelectView={setSelectView}
          {...props}
        />
      )}
      {props.infoProject.images && <PdfDetails ref={pdfRef} {...props} />}
      <ModalContact
        infoProject={props.infoProject}
        agency={props.agency}
        isMobile
        isVmlive={props.vmlive}
        agencyEmail={props.vmlive ? props.agency.email : null}
      />
      <ModalGallery infoProject={props.infoProject} />
    </div>
  );
};

export default HomeQrCode;
