/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import React from "react";
import {
  BooleanInput,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useTranslate,
} from "react-admin";
import Localisation from "./Localisation";

const InfoGeneral = (props) => {
  const { choices } = props;
  const translate = useTranslate();

  return (
    <>
      {/* BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="reference"
            resource="projects"
            fullWidth
            error={props.errors.reference}
            label={translate("resources.projects.fields.reference") + " *"}
            helperText={
              props.errors.reference ? "ra.validation.required" : null
            }
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="state"
            choices={choices.state}
            resource="projects"
            resettable
            fullWidth
            error={props.errors.state}
            label={translate("resources.projects.fields.state") + " *"}
            helperText={props.errors.state ? "ra.validation.required" : null}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="price"
            resource="projects"
            label={translate("resources.projects.fields.price") + " *"}
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
            error={props.errors.price}
            helperText={props.errors.price ? "ra.validation.required" : null}
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="agreement"
            choices={choices.agreement}
            resource="projects"
            resettable
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="type"
            choices={choices.type}
            resource="projects"
            resettable
            fullWidth
            error={props.errors.type}
            helperText={props.errors.type ? "ra.validation.required" : null}
            label={translate("resources.projects.fields.type") + " *"}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="category"
            resettable
            choices={choices.category}
            resource="projects"
            fullWidth
            error={props.errors.category}
            helperText={props.errors.category ? "ra.validation.required" : null}
            label={translate("resources.projects.fields.category") + " *"}
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="rooms"
            min={0}
            step={0.5}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="bedrooms"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="area.total"
            resource="projects"
            min={0}
            step={0.1}
            fullWidth
            error={props.errors.area}
            helperText={props.errors.area ? "ra.validation.required" : null}
            label={translate("resources.projects.fields.area.total") + " *"}
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}></Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="constructionyear"
            resource="projects"
            min={1900}
            max={3000}
            step={1}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="heatingdevice"
            resettable
            resource="projects"
            choices={choices.heating}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="heatingtype"
            resettable
            resource="projects"
            // choices={choices.heatingtype}
            fullWidth
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="dpeLetter"
            resettable
            resource="projects"
            choices={choices.dpeLetter}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="dpeValue"
            resource="projects"
            min={0}
            step={1}
            fullWidth
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="gesLetter"
            resettable
            resource="projects"
            choices={choices.dpeLetter}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="gesValue"
            resource="projects"
            min={0}
            step={1}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="dpeCoutMinConso" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="dpeCoutMaxConso" resource="projects" fullWidth />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="dpeAnneRefConso" resource="projects" fullWidth />
        </Box>
      </Box>
      {props.record && props.record.category === choices.category[2].id && (
        <Box
          display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}
          mb={4}
        >
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="periodicity"
              resource="projects"
              fullWidth
              choices={choices.periodicity}
            />
          </Box>
        </Box>
      )}
      <Box display={{ xs: "block", sm: "flex" }} mt={3}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <BooleanInput source="acceptOffer" resource="projects" fullWidth />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "flex" }} mt={3}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="lang"
            resource="projects"
            choices={choices.lang}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}></Box>
      <Localisation {...props} />
    </>
  );
};

export default InfoGeneral;
