import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import { ControlsButtonContainer, ControlsButtonSvg } from "./Pano.styles";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
// import { useMediaQuery, useTheme } from "@material-ui/core";
const ControlsButton = (props) => {
  const [fullscreen, setFullscreen] = useState(false);
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up("sm"));
  let mql = matchMedia("(max-width: 500px), (max-height: 500px)");

  const handleFullScreen = () => {
    setFullscreen(true);
    props.fullSizeAction();
  };
  const handleFullScreenExit = () => {
    setFullscreen(false);
    props.fullSizeActionExit();
  };

  useEffect(() => {
    const exitHandler = (e) => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullscreen(false);
        props.setOpen(false);
      }
    };
    if (fullscreen) {
      document.addEventListener("fullscreenchange", exitHandler);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullscreen]);

  return (
    <ControlsButtonContainer
      open={props.open}
      minWidth={mql.matches ? "100px" : "130px"}
    >
      <ControlsButtonSvg
        as={AddCircleOutlineIcon}
        onClick={props.fov >= 20 ? () => props.setFov(props.fov - 10) : null}
      />
      <ControlsButtonSvg
        as={RemoveCircleOutlineRoundedIcon}
        onClick={props.fov <= 70 ? () => props.setFov(props.fov + 10) : null}
      />
      {!mql.matches && (
        <ControlsButtonSvg
          as={!fullscreen ? FullscreenIcon : FullscreenExitIcon}
          onClick={
            fullscreen ? () => handleFullScreenExit() : () => handleFullScreen()
          }
        />
      )}
    </ControlsButtonContainer>
  );
};

export default ControlsButton;
