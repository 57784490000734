export const choices = {
  permissions: [
    {
      id: "super_admin",
      name: "resources.users.fields.permissions.super_admin",
    },
    { id: "admin", name: "resources.users.fields.permissions.admin" },
    {
      id: "utilisateur",
      name: "resources.users.fields.permissions.utilisateur",
    },
    { id: "manager", name: "resources.users.fields.permissions.manager" },
  ],
  socialnetworks: [
    { id: "facebook", name: "Facebook" },
    { id: "instagram", name: "Instagram" },
    { id: "youtube", name: "Youtube" },
    { id: "twitter", name: "Twitter" },
    { id: "whatsapp", name: "WhatsApp" },
    { id: "skype", name: "Skype" },
    { id: "linkedin", name: "LinkedIn" },
  ],
};
