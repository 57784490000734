import React from "react";
import PersoLandscape from "./PersoLandscape/PersoLandscape";
import PersoPortrait from "./PersoPortrait/PersoPortrait";

const index = React.forwardRef(
  ({ isPreview, isMini, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <PersoLandscape
          isPreview={isPreview}
          isMini={isMini}
          ref={ref}
          {...otherProps}
        />
      );
    } else {
      return (
        <PersoPortrait
          isPreview={isPreview}
          isMini={isMini}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
