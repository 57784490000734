import React from "react";
import { SkeletonEdit } from "../../../../../../../Utils/Styles/BillingInformation.styles";

const SkeletonItem = (props) => {
  return (
    <>
      {!props.noDivSpace && <div style={{ height: "15px" }} />}
      <SkeletonEdit variant="rect" width={130} height={8} />
      <SkeletonEdit variant="rect" width={150} height={8} />
    </>
  );
};

export default SkeletonItem;
