import { config } from "../../../constants/constant";

const authProvider = (user, setUser) => {
  return {
    login: (params) => Promise.resolve(),
    logout: () => {
      setUser(null);
      localStorage.removeItem(config.ACCESS_TOKEN_NAME);
      window.location.href = "/sign-in-admin";
      return Promise.resolve();
    },
    checkAuth: () => Promise.resolve(),
    checkError: (error) => Promise.resolve(),
    getPermissions: () => {
      const role = user !== null && user.permissions ? user.permissions : null;
      return Promise.resolve(role);
    },
    getIdentity: () => {
      if (!user) {
        return null;
      }
      const { _id, lastname, firstname, permissions } = user;
      const fullName = lastname + " " + firstname;
      return { _id, fullName, permissions };
    },
  };
};

export default authProvider;
