import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 297mm;
  height: 420mm;
  background-color: white;
  margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
  zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 portrait;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: ${(props) => props.backgroundImage};
`;
export const QrcodeContainer = styled.div`
  position: absolute;
  bottom: 170px;
  right: 15mm;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33%;
`;

export const FinancialContainer = styled.div`
  padding-top: 1rem;
  position: absolute;
  top: 918px;
  right: 46px;
`;

export const ExcluContainer = styled.div`
  width: 181.17pt;
  height: 47.82pt;
  background-color: ${(props) => props.colorPrimary};
  position: absolute;
  left: 0;
  top: 107pt;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ExcluText = styled.p`
  font-size: 24pt;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  text-align: center;
  margin-bottom: 0;
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 25px;
`;

export const AlurContainer = styled.div`
  padding: 1rem 0 0 2rem;
`;
export const DpeGesContainer = styled.div`
  width: 60%;
  height: 200px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 175px;
  left: 0;
  overflow: hidden;
`;

export const Header = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 297mm;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
  background-color: ${(props) => props.colorPrimary};
  z-index: 1800;
`;
export const Footer = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 297mm;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
  z-index: 1800;
`;
export const CityContainer = styled.div`
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 126px;
  width: 100%;
`;
export const CityText = styled.h2`
  font-size: 50px;
  color: black;
  margin-bottom: 0;
  text-transform: uppercase;
`;
export const ImagesContainer = styled.div`
  padding: 0.5rem 0;
  width: 100%;
  height: 40%;
  grid-template-rows: 100%;
  position: absolute;
  top: 260px;
`;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const BlockDescContainer = styled.div`
  display: flex;
  position: absolute;
  top: 924px;
  width: 60%;
  padding: 1rem 2rem;
`;
export const Description = styled.p`
  && {
    font-size: 20px;
    text-align: left;
    color: ${(props) => props.colorDesc};
    white-space: pre-wrap;
  }
`;
