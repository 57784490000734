import React from "react";
import a from "../assets/GES_A.png";
import b from "../assets/GES_B.png";
import c from "../assets/GES_C.png";
import d from "../assets/GES_D.png";
import e from "../assets/GES_E.png";
import f from "../assets/GES_F.png";
import g from "../assets/GES_G.png";
import vi from "../assets/GES_VIERGE.png";
import aWhite from "../assets/GES_A_WHITE.png";
import bWhite from "../assets/GES_B_WHITE.png";
import cWhite from "../assets/GES_C_WHITE.png";
import dWhite from "../assets/GES_D_WHITE.png";
import eWhite from "../assets/GES_E_WHITE.png";
import fWhite from "../assets/GES_F_WHITE.png";
import gWhite from "../assets/GES_G_WHITE.png";
import {
  BottomTitle,
  Container,
  FirstTitle,
  Img,
  SubValue,
  TopTitle,
  Value,
  ValueContainer,
} from "./styles";

const Ges = ({ letter, emissions, zoom, theme }) => {
  const render = (l, theme) => {
    switch (l) {
      case "A":
        return theme === "dark" ? aWhite : a;
      case "B":
        return theme === "dark" ? bWhite : b;
      case "C":
        return theme === "dark" ? cWhite : c;
      case "D":
        return theme === "dark" ? dWhite : d;
      case "E":
        return theme === "dark" ? eWhite : e;
      case "F":
        return theme === "dark" ? fWhite : f;
      case "G":
        return theme === "dark" ? gWhite : g;
      case "vi":
        return vi;
      default:
        return theme === "dark" ? aWhite : a;
    }
  };

  const getTop = (l) => {
    switch (l) {
      case "A":
        return { top: "115px", left: "325px" };
      case "B":
        return { top: "165px", left: "355px" };
      case "C":
        return { top: "215px", left: "385px" };
      case "D":
        return { top: "265px", left: "425px" };
      case "E":
        return { top: "315px", left: "460px" };
      case "F":
        return { top: "365px", left: "470px" };
      case "G":
        return { top: "415px", left: "470px" };
      default:
        return a;
    }
  };

  return (
    <Container zoom={zoom} fontFamily={"'Fira Sans', sans-serif"}>
      {letter !== "vi" && (
        <>
          <FirstTitle theme={theme}>
            * Dont émissions de gaz à effet de serre
          </FirstTitle>
          <TopTitle>peu d’émissions de CO₂</TopTitle>
        </>
      )}
      <Img src={render(letter, theme)} alt="ges" />
      {letter !== "vi" && (
        <>
          <ValueContainer
            top={getTop(letter.toUpperCase()).top}
            left={getTop(letter.toUpperCase()).left}
          >
            <Value theme={theme}>{emissions}</Value>
            <SubValue theme={theme}>kg CO₂/m²/an</SubValue>
          </ValueContainer>
          <BottomTitle>émissions de CO₂ très importantes</BottomTitle>
        </>
      )}
    </Container>
  );
};

export default Ges;
