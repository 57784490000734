export const PrintColorBackground = "#bcd151";

export const DisplayColorBackground = "#ef8454";

export const AdsColorBackground = "#1e4c92";

export const colorPrimary = "#2fbcee"; // blue

export const colorSecondary = "#bcd151"; // green

export const colorTertiary = "#ef8454"; // orange

export const colorQuaternary = "#1e4c92"; // dark blue

export const appBarColor = "#22292f";

export const visibble360Color = "#ef8454"; // orange

export const visibblePrintColor = "#bcd151"; // green

export const visibbleDisplayColor = "#2fbcee"; // blue

export const visibbledataColor = "#0e9bff"; // blue alt

export const visibbleAuditColor = "#fbc300"; // orange
