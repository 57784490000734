import { makeStyles } from "@material-ui/core/styles";
import { colorPrimary } from "../../../../../GlobalStyles";

export const CssTextField = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: colorPrimary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colorPrimary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colorPrimary,
    },
    "& input": {
      backgroundColor: "unset !important",
    },
    "& .MuiInputBase-inputMultiline": {
      paddingRight: "43px",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#f44336",
    },
  },
  formControl: {
    width: "95%",
  },
}));
