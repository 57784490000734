import styled from "styled-components";

export const Container = styled.div`
  && {
    height: 296mm;
    width: 420mm;
    min-width: 420mm;
    background-color: #ffffff;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const ContainerLeft = styled.div`
  && {
    height: 100%;
    width: 727.89pt;
    position: relative;
  }
`;

export const BackGroundImg = styled.img`
  && {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
  }
`;

export const ContainerLeftDescription = styled.div`
  && {
    width: 310pt;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 42%;
    right: 0;
    transform: translate(0%, -50%);
  }
`;

export const ExcluContainer = styled.div`
  && {
    padding: 0.5rem;
    width: 100%;
    background-color: ${(props) => props.colorPrimary};
  }
`;

export const Exclu = styled.p`
  && {
    margin-bottom: 0;
    text-align: center;
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const TitleContainer = styled.div`
  && {
    width: 100%;
    height: 25%;
    background-color: white;
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0.5em;
  }
`;

export const Titletop = styled.h2`
  && {
    color: ${(props) => props.colorTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const TitleBottom = styled.h2`
  && {
    color: ${(props) => props.colorSubTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const DescriptionContainer = styled.div`
  && {
    width: 100%;
    height: 300pt;
    overflow: hidden;
    background-color: #0000009e;
  }
`;

export const Description = styled.p`
  && {
    line-height: 115%;
    margin: 1em 2em;
    color: ${(props) => props.colorDesc};
    text-align: left;
    white-space: pre-wrap;
    max-height: 250pt;
    overflow: hidden;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const ContainerRight = styled.div`
  height: 100%;
  width: 462.66pt;
`;

export const DivLine = styled.div`
  border-bottom: 2px solid black;
  width: 100%;
  margin-bottom: 30pt;
`;

export const ContainerRightDescription = styled.div`
  padding: 0 25pt;
  width: 100%;
`;
export const ContainerCategory = styled.div`
  padding: 0 0 25pt;
  width: 100%;
`;
export const Category = styled.p`
  font-size: 30pt;
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
`;

export const ContainerPrice = styled.div`
  padding: 0 0 40pt;
  width: 100%;
`;
export const ContainerAlur = styled.div`
  width: 100%;
  min-height: 150pt;
`;

export const Title = styled.h2`
  && {
    text-transform: uppercase;
    color: black;
    font-size: 3.5rem;
  }
`;

export const Ref = styled.p`
  && {
    margin-bottom: 10pt;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
    color: ${(props) => (props.color !== "" ? props.color : "black")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15pt;
`;

export const AgencyLogo = styled.img`
  height: 107px;
`;
export const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: -15pt;
  margin-right: 15pt;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const EnergyContainer = styled.div`
  display: flex;
  height: 290pt;
  overflow: hidden;
  position: relative;
`;
export const QrcodeContainer = styled.div`
  width: 135pt;
  height: 135pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15pt;
  left: 15pt;
`;
