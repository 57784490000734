import React from "react";
import { Button } from "../Utils/Offer.styles";
import {
  GlobalContainer,
  InformationsContainer,
  InnerContainer,
  ObjectContainer,
  Text,
  Signature,
  SignatureContainer,
  LogoContainer,
  Logo,
  TextCertif,
} from "../Utils/PdfPreview.styles";
import CustomerDetails from "./PdfElements/CustomerDetails";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/360-logo.png";
import { usePrint } from "../../../hooks/usePrint";

const PdfPreview = (props) => {
  const { t, i18n } = useTranslation();

  const printPdf = usePrint();

  const isConditions = () => {
    if (props.state.informations.conditions[1].length) {
      return true;
    } else {
      return false;
    }
  };
  const date = (props, isOptions) => {
    const event = new Date(props);

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return event.toLocaleDateString(i18n.language, isOptions ? options : {});
  };

  const dateToday = () => {
    const event = new Date();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const todayDate = event.toLocaleDateString(i18n.language, options);

    return todayDate;
  };

  return (
    <>
      <GlobalContainer id="ici">
        <InformationsContainer>
          <InnerContainer padding={"6mm 10mm"} width={"55%"} margin={0}>
            <Text weight={true}>
              {props.state.customer.firstname} {props.state.customer.lastname}
            </Text>
            <Text weight={true}>{props.state.customer.address}</Text>
            <Text weight={true}>
              {props.state.customer.postalcode} {props.state.customer.city}
            </Text>
            <Text weight={true}>{props.state.customer.phonemobile}</Text>
            <Text weight={true}>{props.state.customer.email}</Text>
          </InnerContainer>
          <InnerContainer padding={"6mm 0 0 25mm"} width={"45%"}>
            <Text weight={true}>{props.agency.name}</Text>
            <Text weight={true}>{props.agency.address}</Text>
            <Text weight={true}>
              {props.agency.postalcode} {props.agency.city}
            </Text>
          </InnerContainer>
        </InformationsContainer>
        <ObjectContainer>
          <InnerContainer
            flex={true}
            padding={"0 0 0 0"}
            width={"100%"}
            margin={0}
          >
            <InnerContainer padding={"0 0 0 55%"} width={"unset"} margin={0}>
              <Text weight={false} align={false}>
                {i18n.language === "fr-FR" ? "le" : ""} {dateToday()}{" "}
                {t("offer.pdftext.at")} {props.state.customer.city}
              </Text>
            </InnerContainer>
          </InnerContainer>
          <Text weight={true} style={{ marginTop: "2em" }}>
            {t("offer.pdftext.object")} {props.infoProject.city}
          </Text>
        </ObjectContainer>
        <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>{t("offer.pdftext.sir")},</Text>
        </InnerContainer>
        <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            {t("offer.pdftext.visitplace")}{" "}
            {props.state.informations.addressOffer}{" "}
            {t("offer.pdftext.visitdate")}{" "}
            {date(props.state.informations.visiteDate, true)},{" "}
            {t("offer.pdftext.purchaseoffer")}.
          </Text>
        </InnerContainer>
        <InnerContainer padding={"1.5mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>{t("offer.pdftext.undersigned")}</Text>
          <ul>
            <CustomerDetails state={props.state.customer} date={date} />
            {props.state.secondCustomer.firstname !== "" && (
              <CustomerDetails state={props.state.secondCustomer} date={date} />
            )}
          </ul>
        </InnerContainer>
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>{t("offer.pdftext.acquire")} :</Text>
          <ul>
            <li>{props.infoProject.type}</li>
            <li>{props.state.informations.addressOffer}</li>
            <li>{props.infoProject.area.total} m²</li>
            <li>{props.infoProject.rooms} pièce(s)</li>
          </ul>
        </InnerContainer>
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            {t("offer.pdftext.offerprice")}{" "}
            {props.state.informations.offerLetter}.{" "}
            {t("offer.pdftext.civilcode")}
          </Text>
        </InnerContainer>
        {isConditions() && (
          <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
            <Text weight={false}>{t("offer.pdftext.suspensive")}</Text>
            <ul>
              <li>{t(props.state.informations.conditions[1])}</li>
              {props.state.informations.conditions[2].length ? (
                <li>{t(props.state.informations.conditions[2])}</li>
              ) : null}
              {props.state.informations.conditions[3].length ? (
                <li>{t(props.state.informations.conditions[3])}</li>
              ) : null}
              {props.state.informations.conditions[4].length ? (
                <li>{t(props.state.informations.conditions[4])}</li>
              ) : null}
            </ul>
          </InnerContainer>
        )}
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            {t("offer.pdftext.offervalidtime")}{" "}
            {props.state.informations.offerTime}.
          </Text>
        </InnerContainer>
        {props.state.informations.financialType !== "" && (
          <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
            <Text weight={false}>{t("offer.pdftext.term")}</Text>
            <ul>
              <li>{t(props.state.informations.financialType)}</li>
            </ul>
          </InnerContainer>
        )}
        <InnerContainer padding={"2mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>{t("offer.pdftext.allottedtime")}</Text>
        </InnerContainer>
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>{t("offer.pdftext.respect")}</Text>
        </InnerContainer>
        <SignatureContainer width={"100%"}>
          <InnerContainer padding={"5mm 10mm 0"} width={"auto"} margin={0}>
            <Text weight={false}>
              {props.state.customer.lastname} {props.state.customer.firstname}
            </Text>
            {props.step === 5 && (
              <Signature weight={false}>
                {props.state.customer.lastname} {props.state.customer.firstname}
              </Signature>
            )}
          </InnerContainer>
          {props.state.secondCustomer.lastname !== "" && (
            <InnerContainer padding={"5mm 10mm 0"} width={"auto"} margin={0}>
              <Text weight={false}>
                {props.state.secondCustomer.lastname}{" "}
                {props.state.secondCustomer.firstname}
              </Text>
              {props.step === 5 && (
                <Signature weight={false}>
                  {props.state.secondCustomer.lastname}{" "}
                  {props.state.secondCustomer.firstname}
                </Signature>
              )}
            </InnerContainer>
          )}
        </SignatureContainer>
        {props.step === 5 && (
          <LogoContainer>
            <Logo src={logo} alt="logo" />
            <TextCertif weight={false}>
              {t("offer.pdftext.certified")} {dateToday()}{" "}
              {t("offer.pdftext.at")} {props.theDateCertif}
            </TextCertif>
          </LogoContainer>
        )}
      </GlobalContainer>
      {props.step === 5 && (
        <Button
          style={{ marginTop: "5px" }}
          onClick={() => printPdf("Offre d'achat", "ici")}
          typeOfButton={true}
        >
          {t("offer.download").toUpperCase()}
        </Button>
      )}
    </>
  );
};

export default PdfPreview;
