import styled from "styled-components";
import { colorPrimary } from "../../../../GlobalStyles";
import { Button } from "@material-ui/core";

export const ModeButton = styled.button`
  color: ${(props) => (props.selected ? colorPrimary : "rgba(0, 0, 0, 0.87)")};
  border: none;
  text-transform: uppercase;
  line-height: 1.75;
  border-radius: 20px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  background-color: transparent;
`;
export const TitlePrintContainer = styled.div`
  text-align: center;
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 24px;
`;
export const TitlePrint = styled.h2`
  font-size: 1.25rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: 0;
`;

export const PrintBodyContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 24px 2rem 24px;
  min-height: 65vh;
`;
export const PrintActionContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 20px 50px;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`;
export const PrintContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem;
  max-width: 100%;
  height: 100%;
  background-color: white;
`;

export const LinkBtn = styled(Button)`
  && {
    color: ${(props) => props.$color};
    font-size: 1rem;
    background-color: transparent;
    min-width: unset;
    padding: 6px 8px;
    margin-right: ${(props) => (props.$marginRight ? "3rem" : "0")};

    &:hover {
      color: ${(props) => props.$color};
      background-color: transparent;
    }
  }
`;
export const HomeBtn = styled.svg`
  && {
    color: black;
    font-size: 13px;
    margin-right: 3px;
  }
`;
export const HomeButtonText = styled.span`
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-size: 0.875rem;
`;
