import "react-date-range/dist/theme/default.css"; // theme css file
import "react-date-range/dist/styles.css"; // main style file
import React from "react";
import Audience from "./Audience";
import {
  BodyContainer,
  Container,
  SelectCamera,
  ToolbarContainer,
} from "./styles";
import Traffic from "./Traffic";
import { fr } from "react-date-range/dist/locale";
import useRanges from "./InputDate/useRanges";
import InputDate from "./InputDate/InputDate";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import useDataFilter from "./useDataFilter";
import useGetData from "./useGetData";
import Loader from "../../../Loader/Loader";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 155,
    "& .MuiInputLabel-outlined": {
      paddingTop: "3px",
    },
  },
}));
const View = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { data, cameras, loading } = useGetData();
  const {
    thisWeek,
    lastWeek,
    thisMonth,
    lastMonth,
    yesterday,
    today,
  } = useRanges();
  const {
    datePicker,
    setDatePicker,
    selectedCamera,
    traffic,
    trafficChart,
    trafficHourChart,
    trafficBestDay,
    viewAudience,
    audienceChart,
    audienceMiddle,
    audienceGenderMiddle,
    audienceByAge,
    middleTime,
    leads,
    interested,
    interestedPourcent,
    touchPourcent,
    handleFilterDate,
    handleSelectedCamera,
    handleMultiDate,
    daysLength,
  } = useDataFilter({ data, cameras, thisMonth });

  const [active, setActive] = useState("thisMonth");

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <ToolbarContainer>
        <div style={{ display: "flex" }}>
          <InputDate
            dateStart={datePicker[0].startDate}
            dateEnd={datePicker[0].endDate}
            datePicker={datePicker}
            handleMultiDate={handleMultiDate}
            fr={fr}
            thisWeek={thisWeek}
            lastWeek={lastWeek}
            thisMonth={thisMonth}
            lastMonth={lastMonth}
            today={today}
            yesterday={yesterday}
            setDatePicker={setDatePicker}
            handleFilterDate={handleFilterDate}
            active={active}
            setActive={setActive}
          />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              {translate("resources.data.camera")}
            </InputLabel>
            <SelectCamera
              value={selectedCamera}
              onChange={handleSelectedCamera}
              label={translate("resources.data.camera")}
            >
              {cameras.map((item, idx) => (
                <MenuItem key={idx} value={item.cameras.id}>
                  {item.cameras.id}
                </MenuItem>
              ))}
            </SelectCamera>
          </FormControl>
        </div>
      </ToolbarContainer>
      <BodyContainer>
        <Traffic
          {...props}
          traffic={traffic}
          trafficBestDay={trafficBestDay}
          trafficChartData={trafficChart}
          trafficHourChartData={trafficHourChart}
        />
        <Audience
          {...props}
          viewAudience={viewAudience}
          daysLength={daysLength}
          audienceMiddle={audienceMiddle}
          audienceByAge={audienceByAge}
          audienceChartData={audienceChart}
          audienceGenderMiddle={audienceGenderMiddle}
          leads={leads}
          interested={interested}
          interestedPourcent={interestedPourcent}
          middleTime={middleTime}
          touchPourcent={touchPourcent}
        />
      </BodyContainer>
    </Container>
  );
};

export default View;
