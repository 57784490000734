import styled from "styled-components";

export const VideoStyle = styled.video`
  && {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    max-width: 850px;
    -webkit-box-shadow: 2px 2px 1px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease;
  }
`;
