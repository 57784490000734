/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import { useTranslate } from "ra-core";
import React from "react";
import StatisticsBox from "./Components/StatisticsBox";
import { StatisticsBoxContainer } from "./Statistics.styles";
import StatisticsWrapper from "./StatisticsWrapper";

const Statistics = (props) => {
  const translate = useTranslate();

  const { data, leads } = props;
  return (
    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mt={1} p={5}>
      <StatisticsBoxContainer>
        <StatisticsBox
          title={translate("resources.dashboard.numberofleads")}
          number={leads}
        />
        <StatisticsBox
          title={translate("resources.dashboard.numberofviews")}
          number={data.view.data[0].countView.length}
        />
        <StatisticsBox
          title={translate("resources.dashboard.numberofclick")}
          number={data.view.data[0].countClick.length}
        />
        <StatisticsBox
          title={translate("resources.dashboard.numberofclickwhatsapp")}
          number={data.view.data[0].countClickWhatsapp.length}
        />
        <StatisticsBox
          title={translate("resources.dashboard.numberofviewvideo")}
          number={data.view.data[0].countClickVideo.length}
        />
        <StatisticsBox
          title={translate("resources.dashboard.numberofclickphone")}
          number={data.view.data[0].countClickPhone.length}
        />
      </StatisticsBoxContainer>
    </Box>
  );
};

export default StatisticsWrapper(Statistics);
