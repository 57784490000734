const local = {
  API_BASE: "//api.v360.local",
  APP_BASE: "http://v360.local",
  ACCESS_TOKEN_NAME: "login_access_token_local",
  TINYMCE_KEY: "2fv4uctjztw13emqett9q28rgnutjehawaxjtnhq38qfjpoe",
  EXTERNAL_API: {
    vmdisplay: "https://www.vitrilia-display.fr/api",
    vmlive: "https://api.vmlive.vitrinemedia.com",
  },
  CHAT: "4aa05ca8-5cf9-4dd7-9f27-550558011f49",
  GEOAPIFY: "b86240f790ca4d8cabe24d1dfbe8e81f",
  FIREBASE: {
    apiKey: "AIzaSyCo9cKfVNH3MjwYVxa-MQqYwDmSGJj20xg",
    authDomain: "visibble-bucket.firebaseapp.com",
    projectId: "visibble-bucket",
    storageBucket: "visibble-bucket.appspot.com",
    messagingSenderId: "741513354687",
    appId: "1:741513354687:web:68067f67bc91041cb7550a",
  },
  GOOGLE_OAuth:
    "906261651559-rk8crp0ofatu8o8spdn3mo9uqifkqt9n.apps.googleusercontent.com",
  GOOGLE_ANALYTICS: "",
  STRIPE_KEY:
    "pk_test_51Hn4ZuIDiiWP9tqXQGXfmWxh2EsO6cDqEEh8qUpAaeYS42dzDUAHwHd86C6CT7ggzj3U7fVMHPTUK30MkE48Ck6J00yT9dzkYW",
  STRIPE: {
    taxe: "txr_1JUetSIDiiWP9tqXqEJ86V5o",
    usersupp: "price_1JcEJqIDiiWP9tqXHLi3MzVg",
    mandatsupp: "price_1JcDOgIDiiWP9tqXHNI97eP3",
    screensupp: "price_1JcDPqIDiiWP9tqXCMnjDKvv",
    print: "price_1JU7sQIDiiWP9tqXwAcUdSQy",
    display: "price_1JcDM5IDiiWP9tqXDl1GLmPi",
    agent: "price_1JUepVIDiiWP9tqXsJ96Z8BZ",
    agence: "price_1JY4zRIDiiWP9tqXXpulAZVN",
    business: "price_1JY516IDiiWP9tqX5E3gJwsy",
    team: "price_1JY52RIDiiWP9tqXWAiePn8S",
    leader: "price_1JY52zIDiiWP9tqXQucmV4hZ",
  },
};

const qa = {
  API_BASE: "//api.v360.qa.vmds.eu",
  APP_BASE: "https://v360.qa.vmds.eu",
  ACCESS_TOKEN_NAME: "login_access_token_dev",
  TINYMCE_KEY: "2fv4uctjztw13emqett9q28rgnutjehawaxjtnhq38qfjpoe",
  EXTERNAL_API: {
    vmdisplay: "https://www.vitrilia-display.fr/api",
    vmlive: "https://api.vmlive.vitrinemedia.com",
  },
  CHAT: "4aa05ca8-5cf9-4dd7-9f27-550558011f49",
  GEOAPIFY: "b86240f790ca4d8cabe24d1dfbe8e81f",
  FIREBASE: {
    apiKey: "AIzaSyCo9cKfVNH3MjwYVxa-MQqYwDmSGJj20xg",
    authDomain: "visibble-bucket.firebaseapp.com",
    projectId: "visibble-bucket",
    storageBucket: "visibble-bucket.appspot.com",
    messagingSenderId: "741513354687",
    appId: "1:741513354687:web:68067f67bc91041cb7550a",
  },
  GOOGLE_OAuth:
    "906261651559-rk8crp0ofatu8o8spdn3mo9uqifkqt9n.apps.googleusercontent.com",
  GOOGLE_ANALYTICS: "",
};

const prod = {
  API_BASE: "https://api-v360.visibble.io",
  APP_BASE: "https://app.visibble.io",
  ACCESS_TOKEN_NAME: "login_access_token_prod",
  TINYMCE_KEY: "xz5grj06e6j5wzuvbborzxnroqr448lw3vkkhqdznrt6ck0l",
  EXTERNAL_API: {
    vmdisplay: "https://www.vitrilia-display.fr/api",
    vmlive: "https://api.vmlive.vitrinemedia.com",
  },
  CHAT: "4aa05ca8-5cf9-4dd7-9f27-550558011f49",
  // GEOAPIFY: "b119e7c652ed42e1a866b62fd230a3cd",
  GEOAPIFY: "b86240f790ca4d8cabe24d1dfbe8e81f",
  FIREBASE: {
    apiKey: "AIzaSyCMF4U4Mb5lg3_RBu0ZN66j0kJYxxO1XOs",
    authDomain: "visibble.firebaseapp.com",
    projectId: "visibble",
    storageBucket: "visibble.appspot.com",
    messagingSenderId: "906261651559",
    appId: "1:906261651559:web:e6d06ce1d1bfefe571b4c2",
    measurementId: "G-PNT19RVCR4",
  },
  GOOGLE_OAuth:
    "906261651559-rk8crp0ofatu8o8spdn3mo9uqifkqt9n.apps.googleusercontent.com",
  GOOGLE_ANALYTICS: "UA-2555091-13",
  STRIPE_KEY:
    "pk_test_51Hn4ZuIDiiWP9tqXQGXfmWxh2EsO6cDqEEh8qUpAaeYS42dzDUAHwHd86C6CT7ggzj3U7fVMHPTUK30MkE48Ck6J00yT9dzkYW",
};

export const config =
  process.env.REACT_APP_ENV === "qa"
    ? qa
    : process.env.REACT_APP_ENV === "production"
    ? prod
    : local;
