import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import AreaGarden from "../../../../../assets/LogoTemplates/AreaGarden";
import Terrace from "../../../../../assets/LogoTemplates/Terrace";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import QRCode from "react-qr-code";
import LogoLigne from "../../../../../assets/LogoTemplates/Sapene/LOGO-ligne.svg";
import logoPing from "../../../../../assets/LogoTemplates/Sapene/cochon.svg";

import {
  AlurContainer,
  AlurText,
  ImageFirst,
  Container,
  DpeContainer,
  DpeText,
  EnergyTextContainer,
  HonoraryContainer,
  IconContainer,
  IconsContainer,
  IconText,
  Price,
  PriceContainer,
  QrcodeContainer,
  SoldBanner,
  SoldBannerText,
  ImageSuppSmall,
  ImageSupp,
  ImageBottom,
  ImagePing,
  DescContainer,
  DescText,
  SoldBannerSecond,
  SoldBannerTextSecond,
} from "./styles";
import RenderContext from "../../ModulesElements/RenderContext";
import Garage from "../../../../../assets/LogoTemplates/Sapene/Garage";
import Orientation from "../../../../../assets/LogoTemplates/Sapene/Orientation";
import Ascenseur from "../../../../../assets/LogoTemplates/Sapene/Ascenseur";
import { DpeGesNoValue } from "../../DpeGesNoValue";

const SapeneLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    imageSupp,
    colorSecondary,
    bannerFontSecond,
    bannerSecond,
    isBannerSecond,
    icons,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  const [currency] = useState(project.currency ? project.currency : "€");

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  const iconsRender = (name, valeur) => {
    switch (name) {
      case "area":
        return (
          <>
            <Surface color={colorSecondary || "#F1B2CD"} size={35} />
            <IconText>Superficie : {valeur} m2</IconText>
          </>
        );
      case "rooms":
        return (
          <>
            <Rooms color={colorSecondary || "#F1B2CD"} size={35} />
            <IconText>
              {t("modalprint.rooms")} : {valeur}
            </IconText>
          </>
        );
      case "bedrooms":
        return (
          <>
            <Chambres color={colorSecondary || "#F1B2CD"} size={35} />
            <IconText>
              {t("modalprint.bedrooms")} : {valeur}
            </IconText>
          </>
        );
      case "sdb":
        return (
          <>
            <Sdb color={colorSecondary || "#F1B2CD"} size={35} />
            <IconText>
              {t("modalprint.sdb")} : {valeur}
            </IconText>
          </>
        );
      case "gardenArea":
        return (
          <>
            <AreaGarden color={colorSecondary || "#F1B2CD"} size={35} />
            <IconText>Terrain : {valeur} m2</IconText>
          </>
        );
      case "balcony":
        return (
          <>
            <Terrace color={colorSecondary || "#F1B2CD"} size={"35"} />
            <IconText>Balcon</IconText>
          </>
        );
      case "terrace":
        return (
          <>
            <Terrace color={colorSecondary || "#F1B2CD"} size={"35"} />
            <IconText>{t("modalprint.terrace")}</IconText>
          </>
        );
      case "ascenceur":
        return (
          <>
            <Ascenseur
              width="35"
              height="34"
              color={colorSecondary || "#F1B2CD"}
            />
            <IconText>Ascenceur</IconText>
          </>
        );
      case "garage":
        return (
          <>
            <Garage
              width="35"
              height="35"
              color={colorSecondary || "#F1B2CD"}
            />
            <IconText>Pkg/garage</IconText>
          </>
        );
      case "orientation":
        return (
          <>
            <Orientation
              width="35"
              height="35"
              color={colorSecondary || "#F1B2CD"}
            />
            <IconText>Orientation : {valeur}</IconText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <ImageFirst
        src={imageFirst}
        brightness={brightness}
        saturation={saturation}
      />
      <ImageSuppSmall
        src={imageSupp[0]}
        brightness={brightness}
        saturation={saturation}
        top={"541px"}
        left={"5px"}
      />
      <ImageSuppSmall
        src={imageSupp[1]}
        brightness={brightness}
        saturation={saturation}
        top={"541px"}
        left={"402px"}
      />
      <ImageSupp
        src={imageSupp[2]}
        brightness={brightness}
        saturation={saturation}
        top={"6px"}
        left={"797px"}
      />
      <ImageSupp
        src={imageSupp[3]}
        brightness={brightness}
        saturation={saturation}
        top={"274px"}
        left={"797px"}
      />
      <IconsContainer>
        {icons
          .filter((item) => item.active !== false)
          .map((item, idx) => (
            <IconContainer key={idx}>
              {iconsRender(item.name, item.valeur)}
            </IconContainer>
          ))}
      </IconsContainer>
      {isBanner !== "false" && banner && banner.length ? (
        <SoldBanner colorPrimary={color.primary}>
          <SoldBannerText
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </SoldBannerText>
        </SoldBanner>
      ) : null}
      {isBannerSecond !== "false" && bannerSecond && bannerSecond.length ? (
        <SoldBannerSecond colorPrimary={color.primary}>
          <SoldBannerTextSecond
            size={bannerFontSecond.size}
            weight={bannerFontSecond.weight}
            family={bannerFontSecond.font}
            color={bannerFontSecond.color}
          >
            {bannerSecond}
          </SoldBannerTextSecond>
        </SoldBannerSecond>
      ) : null}

      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <QrcodeContainer>
          <QRCode value={qrcodeEdit} size={110} />
        </QrcodeContainer>
      )}

      <RenderContext project={project}>
        <DpeContainer>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top: "20px",
              left: "35px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.20"
                  : "0.22"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"dark"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0px"
                  : "-60px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "290px"
                  : "310px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.43"
                  : "0.25"
              }
              theme={"dark"}
            />
          </div>
        </DpeContainer>
        <EnergyTextContainer>
          <DpeText>
            {t("modalprint.dpeMinMaxInfo")} :{" "}
            {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
              <>
                {`${t("modalprint.between")} ${project.dpeCoutMinConso}€ ${t(
                  "modalprint.and"
                )} ${project.dpeCoutMaxConso}€ ${t("modalprint.perYear")} `}
              </>
            ) : (
              t("details.modalprint.undisclosed")
            )}
          </DpeText>
          {project.dpeCoutMinConso &&
            project.dpeCoutMinConso !== "" &&
            project.dpeAnneRefConso &&
            project.dpeAnneRefConso !== "" && (
              <DpeText>
                {t("modalprint.refYear")} {project.dpeAnneRefConso}{" "}
                {t("modalprint.refYearEnd")}
              </DpeText>
            )}
          <DpeText>
            Les informations sur les risques auxquels ce bien est exposé sont
            disponibles sur le site Géorisques : http://www.georisques.gouv.fr
          </DpeText>
        </EnergyTextContainer>
      </RenderContext>
      <ImagePing src={logoPing} />
      <ImageBottom src={LogoLigne} />
      <DescContainer>
        <DescText
          colorDesc={colorDesc || "white"}
          size={descFont.size}
          weight={descFont.weight}
          family={descFont.font}
        >
          {desc}
        </DescText>
      </DescContainer>
      <AlurContainer>
        {project.category === choices.category[1].id ? (
          <AlurText>
            Honoraires à la charge du locataire :{" "}
            {project.fees > 0
              ? project.fees + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.category === choices.category[1].id ? (
          <AlurText>
            {t("modalprint.feesetatdeslieux")} :{" "}
            {project.feesetatdeslieux > 0
              ? project.feesetatdeslieux + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.category === choices.category[0].id && !project.commission ? (
          <AlurText>Honoraires à la charge du vendeur</AlurText>
        ) : null}
        {project.category === choices.category[0].id && project.commission ? (
          <AlurText>Honoraires à la charge de l'acquéreur</AlurText>
        ) : null}

        {(project.commission || project.sharedCommission) &&
        project.category === choices.category[0].id ? (
          <AlurText>
            Honoraires:{" "}
            {project.fees !== ""
              ? project.fees + (project.feespercent ? " %" : " " + currency)
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {(project.sharedCommission || project.commission) &&
        project.category === choices.category[0].id &&
        project.amountWithoutHonorary ? (
          <AlurText>
            Prix hors honoraires : {getPrice(project.amountWithoutHonorary)}{" "}
            {currency}
          </AlurText>
        ) : null}
      </AlurContainer>
      <HonoraryContainer>
        {project.category === choices.category[1].id ? (
          <AlurText>
            {t("details.modalprint.monthlycharge")} :{" "}
            {project.monthlycharge > 0
              ? project.monthlycharge + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.category === choices.category[1].id ? (
          <AlurText>
            {t("details.modalprint.feesdepotgarantie")} :{" "}
            {project.feesdepotgarantie > 0
              ? project.feesdepotgarantie + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.nblotcopro > 0 && project.type !== choices.type[1].id ? (
          <AlurText>
            {t("modalprint.copropriete")} {project.nblotcopro}{" "}
            {t("modalprint.lots")}
          </AlurText>
        ) : null}
        {project.category === choices.category[0].id &&
        project.type !== choices.type[1].id ? (
          <AlurText>
            Charges de copropriété :{" "}
            {project.feescopro
              ? project.feescopro + currency + " / an"
              : t("modalprint.undisclosed")}
          </AlurText>
        ) : null}
        {project.category === choices.category[0].id &&
        project.type !== choices.type[1].id &&
        project.type !== choices.type[2].id &&
        project.type !== choices.type[3].id ? (
          <AlurText>
            {project.procsyndicale
              ? "Procédure en cours : oui"
              : "Procédure en cours : non"}
          </AlurText>
        ) : null}
      </HonoraryContainer>
      <PriceContainer>
        <Price
          colorPrice={colorPrice || "white"}
          size={priceFont.size !== "" ? `${priceFont.size}px` : "30px"}
          weight={priceFont.weight || 700}
          family={priceFont.family || "'Open Sans', sans-serif !important"}
        >
          PRIX :{project.hideprice && t("modalprint.consult")}
          {!project.hideprice &&
            `${
              project.category === choices.category[2].id
                ? t("details.modalprint.startAt")
                : ""
            } ${getPrice(price)} ${
              project.currency ? project.currency : "€"
            }`}{" "}
          {project.category === choices.category[1].id && !project.hideprice
            ? " " + t("modalprint.chargespermonth")
            : ""}
          {project.periodicity &&
            project.category === choices.category[2].id &&
            " " +
              t(
                choices.periodicity.filter(
                  (item) => item.id === project.periodicity
                )[0].name
              )}
          {project.category === choices.category[0].id && project.commission
            ? " HAI"
            : null}
        </Price>
      </PriceContainer>
    </Container>
  );
});

export default SapeneLandscape;
