import React from "react";
import { SketchPicker } from "react-color";
import { ColorChoiceContainer, PickerContainer } from "./ColorChoice.styles";
const ColorChoice = (props) => {
  return (
    <ColorChoiceContainer>
      <PickerContainer marginRight={"unset"}>
        <SketchPicker color={props.color} onChange={props.handleChangeColor} />
      </PickerContainer>
    </ColorChoiceContainer>
  );
};

export default ColorChoice;
