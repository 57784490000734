import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  font-family: ${(props) => props.fontFamily};
  /* zoom: ${(props) => props.zoom}; */
  transform: translate(-50%, -50%) scale(${(props) => props.zoom});
`;
export const FirstTitle = styled.h2`
  position: absolute;
  top: 130px;
  left: 105px;
  font-size: 24pt;
  margin: 3px 0;
  letter-spacing: 1px;
  max-width: 395px;
  text-align: left;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
`;
export const TopTitle = styled.h3`
  position: absolute;
  top: 260px;
  left: 105px;
  font-size: 18pt;
  margin: 3px 0;
  color: #8cb4d3;
`;

export const Img = styled.img``;

export const ValueContainer = styled.div`
  position: absolute;
  display: flex;
  width: 410px;
  height: 255px;
  align-items: flex-end;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
`;
export const Value = styled.h2`
  font-size: 41pt;
  font-weight: 900;
  margin: 0px;
  position: relative;
  line-height: 31pt;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
`;
export const SubValue = styled.h2`
  font-size: 14pt;
  font-weight: 700;
  margin: 0px;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
`;
export const BottomTitle = styled.h3`
  position: absolute;
  top: 700px;
  left: 105px;
  font-size: 19pt;
  margin: 3px 0;
  letter-spacing: 1px;
  color: #271b35;
  max-width: 280px;
  text-align: left;
`;
