import { TableCell, TableRow } from "@material-ui/core";
import React from "react";

const Row = (props) => {
  const { row } = props;
  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell align={"center"}>{row.firstname}</TableCell>
      <TableCell align={"center"}>{row.lastname}</TableCell>
      <TableCell align={"center"}>{row.email}</TableCell>
    </TableRow>
  );
};

export default Row;
