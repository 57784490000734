import { createTheme, makeStyles, withStyles } from "@material-ui/core/styles";
import { Card as MuiCard } from "@material-ui/core";

export const agencyListStyle = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-sizeSmall": {
      textAlign: "center",
    },
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hiddenOnXSmallScreens: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const Card = withStyles(() => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "20em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export const arrayForm = makeStyles((theme) => ({
  root: {
    "& li span button": {
      marginTop: "1rem",
    },
  },
}));
