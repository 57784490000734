import { useLoader, useThree } from "@react-three/fiber";
import { useEffect } from "react";
import * as THREE from "three";

function Preloader(props) {
  const { gl } = useThree();
  const maps = useLoader(
    THREE.TextureLoader,
    props.store.map((entry) => (entry.finalUrl ? entry.finalUrl : entry.url))
  );
  useEffect(() => maps.forEach(gl.initTexture), [gl, maps]);
  return null;
}
export default Preloader;
