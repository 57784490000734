import React, { useContext, useEffect, useState } from "react";
// REACT ADMIN
import { Create, useTranslate } from "react-admin";
// COMPONENT
import ProjectFormCreate from "./Components/Form/ProjectFormCreate";
import ProjectForm from "./Components/Form/ProjectForm";
import Card from "@material-ui/core/Card";
import { Button } from "@material-ui/core";
import { AgencyContext, CombineContext } from "../../../store/UserProvider";
import Loader from "../../Loader/Loader";

const SelectButton = (props) => {
  return (
    <Button
      style={{ borderRadius: "unset", padding: "10px 20px" }}
      onClick={() => props.action(true)}
    >
      {props.text}
    </Button>
  );
};

const ProjectCreate = (props) => {
  const translate = useTranslate();
  const [isSimple, setIsSimple] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  const agency = useContext(AgencyContext);
  const getType = useContext(CombineContext);

  const [errors, setErrors] = useState({
    reference: false,
    state: false,
    category: false,
    type: false,
    price: false,
    area: false,
    commentLang: false,
  });

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    if (getType.typeValue.type === "360" && isMounted) {
      setLoading(false);
    } else {
      if (getType.typeValue.type === "print" && isMounted) {
        setIsSimple(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [agency, getType.typeValue.type]);

  if (loading) {
    return <Loader />;
  } else if (isSimple) {
    return (
      <Create {...props}>
        <ProjectFormCreate
          {...props}
          errors={errors}
          setErrors={setErrors}
          setLoading={setLoading}
        />
      </Create>
    );
  } else if (isAdvanced) {
    return (
      <Create {...props}>
        <ProjectForm
          {...props}
          errors={errors}
          setErrors={setErrors}
          setLoading={setLoading}
        />
      </Create>
    );
  } else {
    return (
      <>
        <Card style={{ padding: "1rem", margin: "auto" }}>
          <SelectButton
            action={setIsSimple}
            text={translate("resources.projects.quickcreation").toUpperCase()}
          />
          <SelectButton
            action={setIsAdvanced}
            text={translate(
              "resources.projects.advancedcreation"
            ).toUpperCase()}
          />
        </Card>
      </>
    );
  }
};

export default ProjectCreate;
