import React from "react";
import ModulePrice from "../../Modules/ModulePrice";
import LogoElfi from "../../../../../assets/LogoTemplates/Bersot/logo-elfi.png";
import Group280 from "../../../../../assets/LogoTemplates/Bersot/Group280";
import {
  AlurContainer,
  BackgroundImage,
  BottomBanner,
  BottomCenterContainer,
  BottomCenterInner,
  BottomLeftContainer,
  BottomRightContainer,
  Container,
  EnergyContainer,
  EnergyText,
  EnergyTextContainer,
  Exclu,
  ExcluContainer,
  GlobalQrcodeLogo,
  IconContainer,
  IconText,
  LeftContainer,
  Location,
  Logo280Container,
  LogoContainer,
  QrcodeContainer,
  QrcodeText,
  Ref,
  RightContainer,
  Title,
  TopBanner,
} from "./styles";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import AreaGarden from "../../../../../assets/LogoTemplates/Bersot/AreaGarden";
import Terrace from "../../../../../assets/LogoTemplates/Bersot/Terrace";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import Cars from "../../../../../assets/LogoTemplates/Bersot/Cars";
import RenderContext from "../../ModulesElements/RenderContext";
import Alur from "./Alur";
import { DpeGesNoValue } from "../../DpeGesNoValue";

const ElfiLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      background={imageFirst}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <BackgroundImage
        src={imageFirst}
        brightness={brightness}
        saturation={saturation}
      />
      {isBanner === "true" && (
        <ExcluContainer colorPrimary={"#3FA535"}>
          <Exclu
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </Exclu>
        </ExcluContainer>
      )}
      <TopBanner>
        <LeftContainer>
          <Title
            colorTitle={colorTitle || "white"}
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            {titleText}
          </Title>
          <Location
            colorSubTitle={colorSubTitle || "white"}
            size={subTitleTextFont.size}
            weight={subTitleTextFont.weight}
            family={subTitleTextFont.font}
          >
            {subTitleText}
          </Location>
        </LeftContainer>
        <RightContainer>
          <ModulePrice
            colorPrice={colorPrice || "white"}
            price={price}
            project={project}
            align={"right"}
            sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "41px"}
            weight={priceFont.weight}
            family={priceFont.font}
            sizeHonoraire={"15px"}
            sizeSpanPrice={"20px"}
          />
          <Ref
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            Réf : {refText}
          </Ref>
        </RightContainer>
      </TopBanner>
      <BottomBanner>
        <BottomLeftContainer>
          <RenderContext project={project}>
            <EnergyContainer>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "0px",
                  left:
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "20px"
                      : "5px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.19"
                      : "0.21"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"dark"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "105px"
                      : "65px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "230px"
                      : "260px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.43"
                      : "0.20"
                  }
                  theme={"dark"}
                />
              </div>
            </EnergyContainer>
            <EnergyTextContainer>
              <EnergyText>
                {t("modalprint.dpeMinMaxInfo")} :{" "}
                {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                  <>
                    {`${t("modalprint.between")} ${
                      project.dpeCoutMinConso
                    }€ ${t("modalprint.and")} ${project.dpeCoutMaxConso}€ ${t(
                      "modalprint.perYear"
                    )} `}
                  </>
                ) : (
                  t("details.modalprint.undisclosed")
                )}
              </EnergyText>
              {project.dpeCoutMinConso &&
                project.dpeCoutMinConso !== "" &&
                project.dpeAnneRefConso &&
                project.dpeAnneRefConso !== "" && (
                  <EnergyText>
                    {t("modalprint.refYear")} {project.dpeAnneRefConso}{" "}
                    {t("modalprint.refYearEnd")}
                  </EnergyText>
                )}
              <EnergyText>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </EnergyText>
            </EnergyTextContainer>
          </RenderContext>
        </BottomLeftContainer>
        <BottomCenterContainer>
          <BottomCenterInner>
            <IconContainer>
              <Surface color={colorSecondary || "white"} size={45} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.area.total} m2
              </IconText>
            </IconContainer>
            {project.rooms && project.rooms > 0 ? (
              <IconContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Rooms color={colorSecondary || "white"} size={45} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {project.rooms} {t("modalprint.rooms")}
                </IconText>
              </IconContainer>
            ) : null}
            {project.bedrooms && project.bedrooms > 0 ? (
              <IconContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Chambres color={colorSecondary || "white"} size={55} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {project.bedrooms} {t("modalprint.bedrooms")}
                </IconText>
              </IconContainer>
            ) : null}
            {project.sdb && project.sdb > 0 ? (
              <IconContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Sdb color={colorSecondary || "white"} size={55} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
                </IconText>
              </IconContainer>
            ) : null}
            {project.gardenArea && project.gardenArea !== "" ? (
              <IconContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <AreaGarden color={colorSecondary || "white"} size={45} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {project.gardenArea} m2
                </IconText>
              </IconContainer>
            ) : null}
            {project.terrace && project.terrace > 0 ? (
              <IconContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Terrace color={colorSecondary || "white"} size={45} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {t("modalprint.terrace")}
                </IconText>
              </IconContainer>
            ) : null}
            {project.garage && project.garage > 0 ? (
              <IconContainer
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Cars color={colorSecondary || "white"} size={45} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {project.garage}
                </IconText>
              </IconContainer>
            ) : null}
          </BottomCenterInner>
          <AlurContainer>
            <RenderContext project={project}>
              <Alur project={project} />
            </RenderContext>
          </AlurContainer>
        </BottomCenterContainer>
        <BottomRightContainer>
          <GlobalQrcodeLogo>
            <QrcodeText>Scannez-moi !</QrcodeText>
            <QrcodeContainer>
              {project.category !== "Locationsaisonnière" &&
                qrcodeEdit !== null && <QRCode value={qrcodeEdit} size={100} />}
            </QrcodeContainer>
            <Logo280Container>
              <Group280 />
            </Logo280Container>
          </GlobalQrcodeLogo>
          <LogoContainer>
            <img
              src={LogoElfi}
              alt=""
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </LogoContainer>
        </BottomRightContainer>
      </BottomBanner>
    </Container>
  );
});

export default ElfiLandscape;
