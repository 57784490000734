import { Box, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
} from "react-admin";
import { ShowGoogleBtn } from "../../../Utils/Styles/ProjectList.styles";
import { config } from "../../../../../../constants/constant";
import MetaPreview from "./MetaPreview";
import { UrlInputText } from "./ProjectForm.styles";

const Seo = (props) => {
  const translate = useTranslate();
  const {
    formProps,
    initialValues,
    handleChangeMetaTitle,
    handleChangeMetaDescription,
    handleChangeSlug,
  } = props;
  const [showGoogleView, setShowGoogleView] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [slug, setSlug] = useState(false);

  useEffect(() => {
    if (initialValues.slug) {
      setSlug(initialValues.slug);
    } else {
      setSlug(formProps.record.slug);
    }
    if (
      ((initialValues.metatitle ||
        initialValues.metadescription ||
        initialValues.slug) &&
        initialValues.metatitle !== "" &&
        initialValues.metadescription !== "" &&
        initialValues.slug) ||
      formProps.record.slug !== ""
    ) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
      setShowGoogleView(false);
    }
    if (initialValues.metatitle) {
      setShowBtn(true);
    } else {
      if (
        initialValues.metatitle !== "" ||
        initialValues.metadescription !== ""
      ) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
        setShowGoogleView(false);
      }
    }
  }, [initialValues, formProps.record.slug]);

  if (!slug) {
    return null;
  } else {
    return (
      <>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={4}>
          <TextField
            fullWidth
            defaultValue={initialValues.slug || formProps.record.slug}
            onChange={handleChangeSlug}
            label="Slug"
          />
        </Box>

        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={4}>
          <UrlInputText
            fullWidth
            value={`${config.APP_BASE}/visite360/${props.agency.slug}/${slug}`}
            label="Url"
            disabled
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={4}>
          <ArrayInput
            source="keywords"
            resource="projects"
            className="arrayInput-product"
          >
            <SimpleFormIterator>
              <TextInput source="keyword" resource="projects" />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={4}>
          <TextField
            label="Meta titre"
            variant="filled"
            defaultValue={initialValues.metatitle || formProps.record.metatitle}
            onChange={handleChangeMetaTitle}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={5}>
          <TextField
            label="Meta description"
            variant="filled"
            defaultValue={
              initialValues.metadescription || formProps.record.metadescription
            }
            onChange={handleChangeMetaDescription}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          {showBtn && (
            <ShowGoogleBtn onClick={() => setShowGoogleView(!showGoogleView)}>
              {translate(
                "resources.projects.typographies.previewgoogle"
              ).toUpperCase()}
            </ShowGoogleBtn>
          )}
          {showGoogleView && (
            <MetaPreview
              initialValues={initialValues}
              config={config}
              formProps={formProps}
              agency={props.agency}
            />
          )}
        </Box>
      </>
    );
  }
};

export default Seo;
