import React from "react";
import Customer from "../Components/Customer";
import { IconContainer, MoreIcon, SpanText } from "../Utils/Offer.styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { useTranslation } from "react-i18next";
import { colorPrimary } from "../../../GlobalStyles";

const StepOne = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Customer
        number={"1"}
        customer={"customer"}
        handleChange={props.handleChange}
        state={props.state.customer}
      />
      {props.showSecondCustomer && (
        <Customer
          customer={"secondCustomer"}
          handleChange={props.handleChange}
          state={props.state.secondCustomer}
          number={"2"}
        />
      )}
      <IconContainer>
        <MoreIcon
          as={props.showSecondCustomer ? RemoveCircleIcon : AddCircleIcon}
          onClick={() => props.setShowSecondCustomer(!props.showSecondCustomer)}
          colored={props.showSecondCustomer ? "#ff5868" : colorPrimary}
        />
        <SpanText>
          {props.showSecondCustomer
            ? t("offer.deletebuyer")
            : t("offer.addbuyer")}
        </SpanText>
      </IconContainer>
    </>
  );
};

export default StepOne;
