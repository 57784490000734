import React from "react";
import {
  Container,
  BackgroundContainer,
  LogoContainer,
  LogoAgence,
  ExcluContainer,
  ExcluText,
  InfoContainer,
  InfoLeftContainer,
  InfoType,
  InfoCity,
  InfoRef,
  InfoRightContainer,
  IconContainer,
  IconText,
  BottomContainer,
  SlideContainer,
  SlideItem,
  SlideImage,
  InfoContainerAlt,
  InfoContainerAltLeft,
  AlurContainer,
  QrcodeContainer,
  LastContainer,
  DpeContainer,
  DescContainer,
  DescText,
  BackgroundImage,
} from "./styles";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import QRCode from "react-qr-code";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";

import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../ModulesElements/ModulesElements.styles";

const LondresTemplatePortrait = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
    imageSupp,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      style={{ transform: mirror ? "scaleX(-1)" : "initial" }}
      marginAuto={props.isPreview}
      ref={ref}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      className={props.isPreview ? "" : "d-print-flex d-none"}
    >
      <BackgroundContainer>
        <BackgroundImage
          src={imageFirst}
          brightness={brightness}
          saturation={saturation}
        />
        <LogoContainer>
          {agency.images && agency.images.length ? (
            <LogoAgence src={agency.images[0].src} alt="logo agence" />
          ) : null}
        </LogoContainer>
        {isBanner === "true" && (
          <ExcluContainer colorPrimary={color.primary || "#18374a"}>
            <ExcluText
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </ExcluText>
          </ExcluContainer>
        )}
      </BackgroundContainer>
      <InfoContainer colorPrimary={color.primary || "#18374a"}>
        <InfoLeftContainer>
          <InfoType colorTitle={colorTitle || "white"}>
            {project.type && project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </InfoType>
          <InfoCity colorSubTitle={colorSubTitle || "white"}>
            {subTitleText}
          </InfoCity>
          <InfoRef
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            REF: {refText}
          </InfoRef>
        </InfoLeftContainer>
        <InfoRightContainer>
          <IconContainer>
            <Surface color={colorSecondary || "white"} size={45} />
            <IconText colorSecondary={colorSecondary || "white"}>
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Rooms color={colorSecondary || "white"} size={45} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Chambres color={colorSecondary || "white"} size={45} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Sdb color={colorSecondary || "white"} size={45} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </InfoRightContainer>
      </InfoContainer>
      <BottomContainer>
        <SlideContainer>
          <SlideItem right>
            <SlideImage
              src={imageSupp[0]}
              brightness={brightness < 110 ? brightness : brightness - 10}
              saturation={saturation}
              alt="rooms"
            />
          </SlideItem>
          <SlideItem right>
            <SlideImage
              src={imageSupp.length > 1 ? imageSupp[1] : imageSupp[0]}
              brightness={brightness < 110 ? brightness : brightness - 10}
              saturation={saturation}
              alt="rooms"
            />
          </SlideItem>
          <SlideItem right={false}>
            <SlideImage
              src={
                imageSupp.length > 2
                  ? imageSupp[2]
                  : imageSupp.length > 1
                  ? imageSupp[1]
                  : imageSupp[0]
              }
              brightness={brightness < 110 ? brightness : brightness - 10}
              saturation={saturation}
              alt="rooms"
            />
          </SlideItem>
        </SlideContainer>
        <InfoContainerAlt colorPrimary={color.primary || "#18374a"}>
          <InfoContainerAltLeft>
            <ModulePrice
              colorPrice={colorPrice || "white"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </InfoContainerAltLeft>
          <AlurContainer>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={colorPrice || "white"}
                price={project.price}
                defaultColor={"white"}
                sizeText={"11pt"}
              />
              <AlurText sizeText={"11pt"} colorDesc={colorDesc || "white"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurText>
            </RenderContext>
          </AlurContainer>
          {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
            <QrcodeContainer>
              <QRCode value={qrcodeEdit} size={130} />
            </QrcodeContainer>
          )}
        </InfoContainerAlt>
        <LastContainer>
          <DpeContainer>
            <RenderContext project={project}>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "148px",
                  left: "35px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.22"
                      : "0.25"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "20px"
                      : "-50px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "295px"
                      : "365px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.50"
                      : "0.30"
                  }
                  theme={"light"}
                />
              </div>
            </RenderContext>
          </DpeContainer>
          <DescContainer>
            <DescText
              colorDesc={colorDesc || "black"}
              size={descFont.size}
              weight={descFont.weight}
              family={descFont.font}
            >
              {desc}
            </DescText>
          </DescContainer>
        </LastContainer>
      </BottomContainer>
    </Container>
  );
});

export default LondresTemplatePortrait;
