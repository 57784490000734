/* eslint-disable no-unused-vars */
import React from "react";
import a from "../assets/DPE_A.png";
import b from "../assets/DPE_B.png";
import c from "../assets/DPE_C.png";
import d from "../assets/DPE_D.png";
import e from "../assets/DPE_E.png";
import f from "../assets/DPE_F.png";
import g from "../assets/DPE_G.png";
import vi from "../assets/DPE_VIERGE.png";
import aWhite from "../assets/DPE_A_WHITE.png";
import bWhite from "../assets/DPE_B_WHITE.png";
import cWhite from "../assets/DPE_C_WHITE.png";
import dWhite from "../assets/DPE_D_WHITE.png";
import eWhite from "../assets/DPE_E_WHITE.png";
import fWhite from "../assets/DPE_F_WHITE.png";
import gWhite from "../assets/DPE_G_WHITE.png";
import {
  BottomTitle,
  ConsoContainer,
  Container,
  Img,
  Label,
  LeftContainer,
  RedInfoContainer,
  RedInfoText,
  RightContainer,
  SpanSubValue,
  SubValue,
  TopTitle,
  Value,
  ValueContainer,
} from "./styles";

const Dpe = ({
  theme,
  letter,
  conso,
  emissions,
  zoom,
  topTitle,
  bottomTitle,
  redInfoTop,
  redInfoBottom,
  label,
  secondLabel,
  subLabel,
  subValue,
  secondSubValue,
}) => {
  const render = (l, theme) => {
    switch (l) {
      case "A":
        return theme === "dark" ? aWhite : a;
      case "B":
        return theme === "dark" ? bWhite : b;
      case "C":
        return theme === "dark" ? cWhite : c;
      case "D":
        return theme === "dark" ? dWhite : d;
      case "E":
        return theme === "dark" ? eWhite : e;
      case "F":
        return theme === "dark" ? fWhite : f;
      case "G":
        return theme === "dark" ? gWhite : g;
      case "vi":
        return vi;
      default:
        return theme === "dark" ? aWhite : a;
    }
  };

  const getTop = (l) => {
    switch (l) {
      case "A":
        return "20px";
      case "B":
        return "130px";
      case "C":
        return "241px";
      case "D":
        return "352px";
      case "E":
        return "462px";
      case "F":
        return "572px";
      case "G":
        return "682px";
      default:
        return a;
    }
  };

  return (
    <Container
      zoom={zoom}
      fontFamily={"'Fira Sans', sans-serif"}
      color={"transparent"}
    >
      {letter !== "vi" && <TopTitle>{topTitle}</TopTitle>}
      <Img src={render(letter, theme)} alt="dpe" />
      {letter !== "vi" && (
        <ValueContainer top={getTop(letter)}>
          <LeftContainer>
            <Label
              color={theme === "dark" ? "white" : "black"}
              visibility="visible"
            >
              {label}
            </Label>
            <Label
              color={theme === "dark" ? "white" : "black"}
              visibility="visible"
            >
              {subLabel}
            </Label>
            <ConsoContainer>
              <Value color={theme === "dark" ? "white" : "black"}>
                {conso}
              </Value>
              <SubValue color={theme === "dark" ? "white" : "black"}>
                {subValue}
              </SubValue>
            </ConsoContainer>
          </LeftContainer>
          <RightContainer>
            <Label color="#939598" visibility="hidden">
              ""
            </Label>
            <Label
              color={theme === "dark" ? "white" : "black"}
              visibility="visible"
            >
              {secondLabel}
            </Label>
            <ConsoContainer>
              <Value color={theme === "dark" ? "white" : "black"}>
                {emissions} <SpanSubValue>*</SpanSubValue>
              </Value>
              <SubValue color={theme === "dark" ? "white" : "black"}>
                {secondSubValue}
              </SubValue>
            </ConsoContainer>
          </RightContainer>
        </ValueContainer>
      )}
      {letter !== "F" && letter !== "G" && letter !== "vi" && (
        <RedInfoContainer>
          <RedInfoText align="right">{redInfoTop}</RedInfoText>
          <RedInfoText align="left">{redInfoBottom}</RedInfoText>
        </RedInfoContainer>
      )}
      {letter !== "vi" && <BottomTitle>{bottomTitle}</BottomTitle>}
    </Container>
  );
};

export default Dpe;
