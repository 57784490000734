import { createTheme, makeStyles } from "@material-ui/core/styles";
import {
  appBarColor,
  colorPrimary,
  colorTertiary,
} from "../../../GlobalStyles";

export const THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#2fbcee",
    },
    secondery: {
      main: "#3d4852",
    },
  },
  typography: {
    h6: {
      fontWeight: 700,
      fontFamily: "Open Sans, sans-serif",
    },
    h5: {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Open Sans, sans-serif",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        borderRadius: "20px",
        minWidth: "100px",
      },
      contained: {
        text: {
          // Some CSS
          color: "white",
        },
      },
      text: {
        padding: "6px 16px",
      },
      containedPrimary: {
        color: "white",
        backgroundColor: colorTertiary,
        "&:hover": {
          color: "white",
          backgroundColor: colorTertiary,
        },
      },
    },
    MuiLink: {
      underlineHover: {
        "&:hover": {
          color: "unset",
          textDecoration: "none",
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Open Sans, sans-serif",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Open Sans, sans-serif",
      },
    },
    MuiButtonBase: {
      root: {
        padding: "6px 16px",
        "&:hover": {
          color: colorPrimary,
          textDecoration: "none",
        },
        marginRight: "10px",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: appBarColor,
      },
    },
    RaSaveButton: {
      leftIcon: {
        display: "none",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        paddingTop: "2rem",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "white",
        },
        "&.Mui-focused": {
          backgroundColor: "white",
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "white",
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        padding: "5px",
      },
      scroller: {},
    },
    RaLayout: {
      content: {
        width: "60%",
      },
      root: {
        minWidth: "unset",
      },
    },
    RaSimpleFormIterator: {
      form: {
        marginTop: "1rem",
      },
    },
    RaCreateButton: {
      floating: {
        top: "85px",
        left: "18px",
        color: "white",
        width: "35px",
        height: "35px",
      },
    },
  },
});

export const useStyles = makeStyles({
  input: {
    width: "99%",
    backgroundColor: "none",
  },
  box: {
    width: "49%",
  },
  globalBox: {
    padding: "2rem",
  },
});
