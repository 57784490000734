import React from "react";
import CollapseList from "../Collapse/CollapseList";
import {
  Container,
  NumberValueContainer,
  TitleNumberOfUser,
  ValueContainer,
} from "../../Utils/Styles/ItemManager.styles";
import { useTranslate } from "react-admin";

const ModuleAds = (props) => {
  const translate = useTranslate();
  return (
    <Container>
      <NumberValueContainer width="true">
        <TitleNumberOfUser>
          {translate("resources.agencies.fields.subscription")}
        </TitleNumberOfUser>
        <ValueContainer>
          <CollapseList
            adsAboChange={props.adsAboChange}
            state={props.state}
            callInfoPage={props.callInfoPage}
          />
        </ValueContainer>
      </NumberValueContainer>
    </Container>
  );
};

export default ModuleAds;
