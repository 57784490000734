import styled from "styled-components";

export const Container = styled.div`
  && {
    width: 420mm;
    height: 296mm;
    background-color: ${(props) => props.colorPrimary};
    display: flex;
    flex-direction: column;
    position: relative;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url("${props.imageFirst}")`};
  background-size: cover;
  background-position: center;
`;

export const SubtitleContainer = styled.div`
  position: absolute;
  top: 127pt;
  left: 0;
  min-width: 168pt;
  background-color: ${(props) => props.color};
  padding: 5pt 10pt;
  height: 39pt;
`;
export const Subtitle = styled.p`
  margin: 0;
  color: ${(props) => props.color};
  text-align: right;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
export const CatTypeContainer = styled.div`
  position: absolute;
  top: 166pt;
  left: 0;
  background-color: ${(props) => props.color};
  padding: 3pt 15pt;
  min-height: 48pt;
`;
export const CatTypeText = styled.p`
  color: ${(props) => props.color};
  text-align: center;
  margin: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const PriceContainer = styled.div`
  position: absolute;
  top: 215pt;
  left: 0;
  min-height: 65pt;
  min-width: 296pt;
  background-color: ${(props) => props.color};
  padding: 3pt 15pt;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DpeGesContainer = styled.div`
  position: absolute;
  top: 603pt;
  left: 0;
  display: flex;
  background-color: ${(props) => props.color};
  width: 534.67pt;
  height: 235pt;
  max-height: 241pt;
  overflow: hidden;
`;

export const RefContainer = styled.div`
  position: absolute;
  top: 506pt;
  right: 0;
  min-width: 197pt;
  height: 49pt;
  background-color: ${(props) => props.color};
  padding: 15pt 14pt;
`;
export const RefText = styled.p`
  margin: 0;
  text-align: left;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16pt")};
  color: ${(props) => (props.color !== "" ? props.color : "black")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "700")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const IconsContainer = styled.div`
  position: absolute;
  top: 555pt;
  right: 0;
  max-width: 575pt;
  height: 48pt;
  background-color: ${(props) => props.color};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: start;
  align-items: center;
  padding: 0pt 10pt;
`;
export const AlurContainer = styled.div`
  position: absolute;
  top: 603pt;
  right: 0;
  min-width: 280pt;
  max-width: 619pt;
  background-color: ${(props) => props.color};
  padding: 5pt 15pt;
  text-align: left;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
`;
export const IconText = styled.p`
  font-size: 11pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: ${(props) => props.marginLeft};
`;
export const QrcodeContainer = styled.div`
  width: 120pt;
  height: 120pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5pt;
  right: 0pt;
`;
export const LogoContainer = styled.div`
  width: 167pt;
  position: absolute;
  right: 15pt;
  top: 20pt;
  z-index: 10;
`;
export const LogoAgence = styled.img`
  width: 100%;
`;
export const ExcluContainer = styled.div`
  && {
    z-index: 10;
    padding: 0.5rem;
    width: 204pt;
    background-color: ${(props) => props.colorPrimary};
    position: absolute;
    top: 40pt;
    left: 0pt;
  }
`;
export const Exclu = styled.p`
  && {
    margin-bottom: 0;
    text-align: center;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;
