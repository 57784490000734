import React from "react";
import { useTranslation } from "react-i18next";
import { colorPrimary } from "../../../GlobalStyles";
import { useTemplatesStore } from "../../../store/TemplatesStore";
import { LinkBtn } from "./Styles/ModalPrint.styles";
import { disabledAction } from "./Utils/disabledAction";

const ActionToCall = ({ printLandscape }) => {
  const { t } = useTranslation();
  const {
    step,
    handleStep,
    orientation,
    model,
    imageFirst,
    imageSupp,
  } = useTemplatesStore();
  return (
    <LinkBtn
      disabled={disabledAction(
        // CHECK IF USER COMPLETE ALL ITEMS
        step,
        orientation,
        model,
        imageFirst,
        imageSupp.length
      )}
      onClick={
        // ACTION STEP 4 PRINT OR NEXT STEP
        step === 4 ? () => printLandscape() : () => handleStep()
      }
      $color={colorPrimary}
      $marginRight={"true"}
    >
      {step !== 4 ? t("modalprint.next") : t("modalprint.export")}
    </LinkBtn>
  );
};

export default ActionToCall;
