import { useTranslate } from "react-admin";
import React from "react";

const Empty = (props) => {
  const t = useTranslate();
  return (
    <div
      style={{
        color: "inherit",
        margin: "0 1em",
        opacity: "0.5",
        textAlign: "center",
      }}
    >
      <svg
        className="MuiSvgIcon-root RaEmpty-icon"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        style={{ width: "9em", height: "9em" }}
      >
        <path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10z"></path>
      </svg>
      <p
        style={{
          marginBottom: "16px",
          fontSize: "2.125rem",
          fontFamily: "Open Sans, sans-serif",
          fontWeight: "400",
          lineHeight: "1.235",
          letterSpacing: "0.00735em",
        }}
      >
        {t("resources.offers.empty")}
      </p>
    </div>
  );
};

export default Empty;
