import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { TextFieldContainer } from "../Styles/Content.styles";
import { colorPrimary } from "../../../../../GlobalStyles";
import { useTranslation } from "react-i18next";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";

const CustomRadio = withStyles({
  root: {
    color: colorPrimary,
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

const BannerShowComponent = (props) => {
  const { setIsBanner, isBanner } = useTemplatesStore();
  const { t } = useTranslation();
  return (
    <>
      <TextFieldContainer margin={"1.5rem auto 0"}>
        <RadioGroup
          value={isBanner}
          onChange={(e) => setIsBanner(e.target.value)}
        >
          <FormControlLabel
            value={"true"}
            control={<CustomRadio />}
            label={t("modalprint.showBanner")}
          />
          <FormControlLabel
            value={"false"}
            control={<CustomRadio />}
            label={t("modalprint.dontshowBanner")}
          />
        </RadioGroup>
      </TextFieldContainer>
    </>
  );
};

export default BannerShowComponent;
