import styled from "styled-components";

export const Container = styled.div`
  && {
    position: relative;
    height: 296.5mm;
    width: 420mm;
    min-width: 420mm;
    background: #000000 0% 0% no-repeat padding-box;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    overflow: hidden;
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;
export const BackgroundImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const LogoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 180px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
`;

export const QrcodeContainer = styled.div`
  width: 128px;
  height: 128px;
  background-color: white;
  position: absolute;
  bottom: 60px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Logo = styled.img`
  width: 100%;
`;

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  height: 385px;
  background-color: #ffffff8c;
  bottom: 0;
  left: 0;
`;

export const DpeContainer = styled.div`
  position: absolute;
  top: 848px;
  left: 1170px;
  width: 331px;
  height: 175px;
  display: flex;
  overflow: hidden;
`;

export const EnergyTextContainer = styled.div`
  position: absolute;
  top: 1038px;
  left: 1190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  width: 331px;
`;

export const DpeText = styled.p`
  margin-bottom: 0px;
  color: black;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif !important;
`;
export const SoldBanner = styled.div`
  height: 142px;
  min-width: 156px;
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;
export const SoldBannerText = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "30px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "500")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const BottomContainer = styled.div`
  width: 880px;
  height: 340px;
  position: absolute;
  bottom: 30px;
  left: 200px;
`;
export const TitleContainer = styled.div``;
export const TitleText = styled.h2`
  margin-bottom: 0;
  color: ${(props) => props.colorTitle};
  margin-top: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "30px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const IconsContainer = styled.div`
  display: flex;
  margin-top: 22px;
`;
export const IconContainer = styled.div`
  display: flex;
  margin-right: 20px;
  align-items: center;
`;
export const IconText = styled.p`
  font-size: 14px;
  color: black;
  margin-bottom: 0;
  margin-left: 9px;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif !important;
`;

export const BottomLastContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const PriceContainer = styled.div``;

export const Price = styled.p`
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.family};
  color: ${(props) => props.colorPrice};
  margin-bottom: 0;
  font-weight: ${(props) => props.weight};
`;
export const Honoraire = styled.p`
  color: ${(props) => props.colorPrice};
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.family};
  text-align: left;
  font-weight: ${(props) => props.weight};
  margin-top: 0;
  margin-bottom: 2px;
`;
export const AlurContainer = styled.div`
  display: flex;
`;
export const HonoraryContainer = styled.div`
  padding-left: 35px;
`;
export const AlurText = styled.p`
  && {
    color: black;
    font-size: 14px;
    font-family: "Open Sans", sans-serif !important;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
  }
`;
export const CoproContainer = styled.div`
  padding-left: 35px;
`;

export const SecondTitle = styled.h3`
  font-size: 26px;
  color: ${(props) => (props.colorTitle2 !== "" ? props.colorTitle2 : "black")};
  margin-bottom: 15px;
  margin-top: 8px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
`;
