import React from "react";
import { Create, TabbedForm, FormTab, useGetIdentity } from "react-admin";
import UserForm from "./Components/UserForm";
import ProfileForm from "../Profile/ProfileForm";
import UserToolbar from "./Components/UserToolbar";
import formWrapper from "./Components/FormWrapper";

const UserCreate = (props) => {
  const {
    handleChangeAgency,
    enableSave,
    initialValues,
    agency,
    ...otherProps
  } = props;
  const { identity, loading: identityLoading } = useGetIdentity();
  if (identityLoading) return <>Loading...</>;
  return (
    <Create {...otherProps} basePath="/users">
      <TabbedForm
        toolbar={
          <UserToolbar initialValues={initialValues} enableSave={enableSave} />
        }
      >
        <FormTab label="resources.users.tabname">
          <ProfileForm
            agency={agency}
            handleChangeAgency={handleChangeAgency}
          />
          {identity !== undefined && identity.permissions.includes("admin") && (
            <UserForm />
          )}
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default formWrapper(UserCreate);
