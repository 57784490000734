import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  List,
  Datagrid,
  TextField,
  CloneButton,
  RichTextField,
} from "react-admin";

const useStyles = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hiddenOnXSmallScreens: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const CloneElement = ({ record }) => {
  return record ? (
    <CloneButton
      to={{
        pathname: "/templates/create",
        search: `?source=${JSON.stringify(omitElement(record))}`,
      }}
    />
  ) : null;
};
const encodeText = (str) => {
  let newString = str
    .replace(/\n/g, "")
    .replace(/[\t ]+</g, "<")
    .replace(/>[\t ]+</g, "><")
    .replace(/>[\t ]+$/g, ">")
    .replace(/"/g, '\\"')
    .replace(/:/g, ":");
  return encodeURIComponent(newString);
};
const omitElement = ({ _id, htmlContent, ...rest }) => {
  rest.htmlContent = encodeText(htmlContent);
  return rest;
};

const TemplateList = (props) => {
  const classes = useStyles();

  return (
    <List {...props} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="templateName" />
        <TextField source="subject" />
        <RichTextField
          source="htmlContent"
          stripTags
          headerClassName={classes.hiddenOnSmallScreens}
          cellClassName={classes.hiddenOnSmallScreens}
        />
        <TextField
          source="lang"
          headerClassName={classes.hiddenOnSmallScreens}
          cellClassName={classes.hiddenOnSmallScreens}
        />
        <CloneElement />
      </Datagrid>
    </List>
  );
};

export default TemplateList;
