/* eslint-disable no-unused-vars */
import React from "react";
import { Fade } from "@material-ui/core";
import { useSelector } from "react-redux";

import {
  DashboardBoxContainer,
  DashboardContainer,
} from "../../Utils/Styles/DashboardComponents.styles";
import DashboardBox from "../Dashboard360/DashboardBox";
import DashboardHeaderAdmin from "./DashboardHeaderAdmin";
import DashboardWrapperAdmin from "./DashWrapperAdmin";
import { inferTypeFromValues, useTranslate } from "react-admin";
import DashConsole from "./DashConsole";
import useDataAdmin from "../../hooks/useDataAdmin";
import Loader from "../../../../Loader/Loader";

const DashboardViewAdmin = (props) => {
  const translate = useTranslate();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const state = useDataAdmin();
  // if (state.loading) {
  //   return <Loader />;
  // }
  return (
    <Fade in={true} timeout={400}>
      <DashboardContainer>
        <DashboardHeaderAdmin open={open} />
        <DashboardBoxContainer template={3}>
          <DashboardBox
            number={state.projects}
            title={`${translate("resources.dashboard.admin.mandate")}${
              props.projects > 1 ? "s" : ""
            }`}
            loading={state.loading}
          />
          <DashboardBox
            number={state.agencies}
            title={translate("resources.dashboard.admin.agencies")}
            loading={state.loading}
          />
          <DashboardBox
            number={state.users}
            title={translate("resources.dashboard.admin.users")}
            loading={state.loading}
          />
          <DashboardBox
            number={state.viite360}
            title={translate("resources.dashboard.admin.360")}
            loading={state.loading}
          />
        </DashboardBoxContainer>
        {/* <button onClick={() => test.functionImport.test("test")}>test</button> */}
        {/* <DashConsole agencies={props.agencies} /> */}
      </DashboardContainer>
    </Fade>
  );
};

export default DashboardViewAdmin;
