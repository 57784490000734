import React from "react";
import { Create, TabbedForm, FormTab } from "react-admin";
import AgencyForm from "./Components/Form/AgencyForm";
import formWrapper from "./Components/Form/formWrapper";
import AgencyToolbar from "./Components/Form/AgencyToolbar";

const AgencyCreate = ({ permissions, ...props }) => {
  const {
    initialValues,
    enableSaveAddress,
    onPlaceSelect,
    onChangeColor,
    ...otherProps
  } = props;
  return (
    <Create {...otherProps}>
      <TabbedForm
        toolbar={
          <AgencyToolbar
            initialValues={props.initialValues}
            enableSaveAddress={props.enableSaveAddress}
            handleSubmitWithRedirect={props.handleSubmitWithRedirect}
            permissions={permissions}
          />
        }
        // sanitizeEmptyValues={false}
        redirect="list"
      >
        <FormTab label="resources.agencies.tabname">
          <AgencyForm {...props} onPlaceSelect={onPlaceSelect} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default formWrapper(AgencyCreate);
