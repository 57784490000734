/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";

// CSS
import "./index.css";
import Wrapper from "./Wrapper";
import {
  getContactExist,
  sendNewRequestContact,
  sendRequestContact,
  sendRequestVmLive,
} from "./requests";
import { colorQuaternary } from "../../GlobalStyles";

function ContactForm(props) {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const { project, agency, isLeads, isAgency, isVmlive } = props;
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonemobile: "",
    message: "",
    rdv: false,
  });
  useEffect(() => {}, [project, state]);

  const handleChange = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const submit = async () => {
    if (
      state.email.length &&
      state.lastname.length &&
      state.firstname.length &&
      state.phonemobile.length
    ) {
      if (isVmlive) {
        const sendEmail = await sendRequestVmLive(
          project,
          state,
          props.agencyEmail
        );
        if (sendEmail.status === 200 || sendEmail.status === 201) {
          alert.show(`${t("alert.contact-sent")}`);
          setState({
            firstname: "",
            lastname: "",
            email: "",
            phonemobile: "",
            message: "",
            rdv: false,
          });
        } else {
          alert.error(`${t("alert.error")}`);
        }
      } else {
        const dataFetch = await getContactExist(state.email);

        if (dataFetch.total > 0) {
          const agencyExist = await isAgency(dataFetch.data);
          if (agencyExist.length) {
            const leadsExist = await isLeads(agencyExist[0]);
            // if (leadsExist.length) {
            //   alert.show(`${t("alert.contact-alreadysent")}`);
            // } else {
            const sendContactForm = await sendRequestContact(
              agencyExist[0],
              project,
              agency,
              state,
              i18n.language
            );
            if (
              sendContactForm.status === 200 ||
              sendContactForm.status === 201
            ) {
              alert.show(`${t("alert.contact-sent")}`);
              setState({
                firstname: "",
                lastname: "",
                email: "",
                phonemobile: "",
                message: "",
                rdv: false,
              });
            } else {
              alert.error(`${t("alert.error")}`);
            }
          } else {
            const sendContactForm = await sendNewRequestContact(
              project,
              agency,
              state,
              i18n.language
            );
            if (
              sendContactForm.status === 200 ||
              sendContactForm.status === 201
            ) {
              alert.show(`${t("alert.contact-sent")}`);
              setState({
                firstname: "",
                lastname: "",
                email: "",
                phonemobile: "",
                message: "",
                rdv: false,
              });
            } else {
              alert.error(`${t("alert.error")}`);
            }
          }
        } else {
          const sendContactForm = await sendNewRequestContact(
            project,
            agency,
            state,
            i18n.language
          );
          if (
            sendContactForm.status === 200 ||
            sendContactForm.status === 201
          ) {
            alert.show(`${t("alert.contact-sent")}`);
            setState({
              firstname: "",
              lastname: "",
              email: "",
              phonemobile: "",
              message: "",
              rdv: false,
            });
          } else {
            alert.error(`${t("alert.error")}`);
          }
        }
      }
    } else {
      alert.error(`${t("alert.fields-required")}`);
    }
  };

  const buttonStyle = {
    backgroundColor: colorQuaternary,
    color: "white",
    borderRadius: "5px",
    width: "100%",
    padding: "0.75rem",
    fontWeight: "700",
  };
  return (
    <div id="contact-form" className="border border-white p-3 p-md-5">
      <h2 className="text-center mb-0">
        {t("contact.form.title").toUpperCase()}
      </h2>
      <div className="pt-3 pt-md-5 mx-0">
        <div className="form-group">
          <input
            type="text"
            name="lastname"
            className="contact form-control"
            placeholder={t("contact.form.lastname")}
            onChange={handleChange}
            value={state.lastname}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="firstname"
            className="contact form-control"
            placeholder={t("contact.form.firstname")}
            onChange={handleChange}
            value={state.firstname}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            name="email"
            className="contact form-control"
            placeholder={t("contact.form.email")}
            onChange={handleChange}
            value={state.email}
          />
        </div>
        <div className="form-group">
          <input
            type="phone"
            name="phonemobile"
            placeholder={t("contact.form.phonemobile")}
            value={state.phonemobile}
            onChange={handleChange}
            className="contact form-control"
          />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            name="message"
            placeholder={t("contact.form.message")}
            value={state.message}
            onChange={handleChange}
            className="contact form-control"
          />
        </div>
        <div className="contact custom-control custom-checkbox">
          <input
            className="contact custom-control-input"
            type="checkbox"
            onChange={() =>
              handleChange({
                target: { name: "rdv", value: !state.rdv },
              })
            }
            checked={state.rdv === true}
            id="contactCheck1"
            name="contactCheck1"
          />
          <label
            className="contact custom-control-label"
            htmlFor="contactCheck1"
          >
            {t("contact.form.contactCheck1")}
          </label>
        </div>
        <button
          type="button"
          className="btn contact mt-3"
          style={buttonStyle}
          onClick={submit}
        >
          {t("contact.form.send").toUpperCase()}
        </button>
      </div>
    </div>
  );
}
export default Wrapper(ContactForm);
