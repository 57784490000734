import React from "react";
import { Edit } from "react-admin";
import OfferForm from "./Components/Form/OfferForm";

const OfferEdit = (props) => {
  return (
    <Edit undoable={false} {...props}>
      <OfferForm />
    </Edit>
  );
};

export default OfferEdit;
