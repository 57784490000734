import React from "react";
import { TextValue } from "../../../../../../../Utils/Styles/BillingInformation.styles";

const Debit = (props) => {
  return (
    <>
      {!props.noDivSpace && <div style={{ height: "15px" }} />}
      <TextValue>
        {props.record.billingInformation.billingMethod.rib.iban}
      </TextValue>
      <TextValue>
        {props.record.billingInformation.billingMethod.rib.bic}
      </TextValue>
    </>
  );
};

export default Debit;
