import React from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  withStyles,
} from "@material-ui/core";
import { TextFieldContainer } from "../Styles/Content.styles";
import { colorPrimary } from "../../../../../GlobalStyles";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import { useTranslation } from "react-i18next";

const CustomRadio = withStyles({
  root: {
    color: colorPrimary,
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Radio {...props} />);

const QrCodeComponent = () => {
  const { t } = useTranslation();
  const {
    qrcode,
    setQrcode,
    qrcodeMobile,
    setQrcodeMobile,
    qrcodeMode,
    setQrcodeMode,
  } = useTemplatesStore();

  const onChangeQrCode = (event) => {
    setQrcode(event.target.value);
  };

  const onChangeQrCodeMobile = (event) => {
    setQrcodeMobile(event.target.value);
  };

  const onChangeQrCodeMode = (event) => {
    setQrcodeMode(event.target.value);
  };

  return (
    <>
      <TextFieldContainer margin={"1rem auto 0"}>
        <TextField
          id="outlined-multiline-static"
          label={t("modalprint.qrcode")}
          type="text"
          value={qrcode}
          variant="outlined"
          onChange={onChangeQrCode}
          fullWidth
        />
      </TextFieldContainer>
      <TextFieldContainer margin={"1rem auto 0"}>
        <TextField
          id="outlined-multiline-static"
          label={t("modalprint.qrcodeMobile")}
          type="text"
          value={qrcodeMobile}
          variant="outlined"
          onChange={onChangeQrCodeMobile}
          fullWidth
        />
      </TextFieldContainer>
      <TextFieldContainer margin={"1.5rem auto 0"}>
        <RadioGroup value={qrcodeMode} onChange={onChangeQrCodeMode}>
          <FormControlLabel
            value="not"
            control={<CustomRadio />}
            label={t("modalprint.notshowQrcode")}
          />
          <FormControlLabel
            value="qrcode"
            control={<CustomRadio />}
            label="Qrcode 360"
          />
          <FormControlLabel
            value="qrcodeMobile"
            control={<CustomRadio />}
            label={t("modalprint.qrcodeMobile")}
          />
        </RadioGroup>
      </TextFieldContainer>
    </>
  );
};

export default QrCodeComponent;
