import React, { useState } from "react";
import {
  FormWithRedirect,
  SelectInput,
  TextInput,
  useTranslate,
} from "react-admin";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Fade,
} from "@material-ui/core";
import Language from "../../../enums/Language";
import TinyMCEInput from "../../TinyMCE";
import ToolbarTemplate from "./ToolbarTemplate";

const TemplateForm = (props) => {
  const translate = useTranslate();
  const [enable, setEnable] = useState(true);
  return (
    <FormWithRedirect
      {...props}
      redirect="list"
      render={(formProps) => {
        return (
          <Fade in={true} timeout={300}>
            <Card>
              <form>
                <CardContent className="Form-Project">
                  <Box>
                    <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px" mt="20px">
                      <Typography variant="h6" gutterBottom>
                        {translate("resources.templates.title")}
                      </Typography>

                      <Divider light style={{ marginBottom: "10px" }} />
                      <Box display={{ xs: "block", sm: "flex" }}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <TextInput
                            source="templateName"
                            resource="templates"
                          />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <SelectInput
                            source="lang"
                            resource="templates"
                            choices={Language}
                            translateChoice={false}
                          />
                        </Box>
                      </Box>
                      <Box display={{ xs: "block", sm: "flex" }}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <TextInput
                            source="subject"
                            resource="templates"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box display={{ xs: "block", sm: "flex" }}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <TinyMCEInput
                            source="htmlContent"
                            resource="templates"
                            fullWidth
                            setEnable={setEnable}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
                <ToolbarTemplate formProps={formProps} enable={enable} />
              </form>
            </Card>
          </Fade>
        );
      }}
    />
  );
};

export default TemplateForm;
