import styled from "styled-components";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SingleBedIcon from "@material-ui/icons/SingleBed";
import BathtubIcon from "@material-ui/icons/Bathtub";

export const Price = styled.h3`
  && {
    font-size: ${(props) => props.sizePrice};
    font-weight: bold;
    text-transform: uppercase;
    color: ${(props) => props.colorPrice};
    margin-bottom: 0;
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;
export const Honoraire = styled.small`
  && {
    font-size: ${(props) => props.sizeHonoraire};
    font-weight: bold;
    color: ${(props) => props.colorPrice};
    margin-top: 2pt;
    margin-bottom: 0;
  }
`;
export const SpanPrice = styled.span`
  font-size: ${(props) => props.sizeSpanPrice};
  margin-right: ${(props) => (props.right ? "5px" : "0")};
`;

export const AlurText = styled.p`
  font-size: ${(props) => props.sizeText};
  font-weight: initial;
  color: ${(props) => props.colorDesc};
  line-height: 18pt;
  margin-bottom: 0;
`;

export const ContainerQrCode = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  border: solid 5px white;
  border-radius: 5px;
  display: ${(props) => props.display};
  align-items: center;
`;

export const ContainerTextQrCode = styled.div`
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  width: 150px;
`;

export const TextQrCode = styled.p`
  font-weight: 900;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  text-align: center;
  color: ${(props) => props.colorDesc};
`;

export const DivLogo = styled.div`
  && {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
`;

export const LogoSurface = styled(AspectRatioIcon)`
  && {
    font-size: 3.5rem;
    margin-bottom: 0.3rem;
    align-self: center;
    color: ${(props) => props.colorprimary};
  }
`;

export const TextLogo = styled.small`
  && {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.colorDesc};
  }
`;

export const LogoRoom = styled(DashboardIcon)`
  && {
    font-size: 3.5rem;
    margin-bottom: 0.3rem;
    align-self: center;
    color: ${(props) => props.colorprimary};
  }
`;

export const LogoBedRoom = styled(SingleBedIcon)`
  && {
    font-size: 3.5rem;
    margin-bottom: 0.3rem;
    align-self: center;
    color: ${(props) => props.colorprimary};
  }
`;

export const LogoBath = styled(BathtubIcon)`
  && {
    font-size: 3.5rem;
    margin-bottom: 0.3rem;
    align-self: center;
    color: ${(props) => props.colorprimary};
  }
`;
export const ExcluContainer = styled.div`
  position: absolute;
  z-index: 1999;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  width: ${(props) => (props.width ? props.width : "unset")};
`;

export const ExcluTitle = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "30px")};
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-weight: bolder;
`;
