/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "../../Utils/Styles/index.css";
import { useRedirect, useTranslate } from "react-admin";
import { useSelector } from "react-redux";
import DashboardBox from "./DashboardBox";
import DashboardHeader from "./DashboardHeader";

import {
  DashboardBoxContainer,
  DashboardContainer,
  DashboardSLidesContainer,
  DashboardTopProjectContainer,
  TopProjectText,
  TopProjectTextBold,
} from "../../Utils/Styles/DashboardComponents.styles";
import DashboardSlide from "./DashboardSlide";
import { Fade } from "@material-ui/core";
import DashboardWrapper from "./DashboardWrapper";
import FormAbo from "../../../../ModuleAbo/FormAbo";

const DashboardView = (props) => {
  const {
    handleFilterUser,
    handleFilterDate,
    projects,
    leadsData,
    viewData,
    users,
    isUserData,
  } = props;
  const [countViewPourcent, setCountViewPourcent] = useState(0);
  const translate = useTranslate();
  const redirect = useRedirect();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  return (
    <Fade in={true} timeout={400}>
      <DashboardContainer>
        <DashboardHeader
          total={projects.length}
          translate={translate}
          handleFilterUser={handleFilterUser}
          handleFilterDate={handleFilterDate}
          users={users}
          isUserData={isUserData}
          open={open}
          refresh={props.refresh}
        />
        <DashboardBoxContainer template={4}>
          <DashboardBox
            number={leadsData}
            pourcent={props.pourcentLead}
            pourcentShow={props.pourcentShow}
            actualPourcent={props.actualPourcentLead}
            title={translate("resources.dashboard.numberofleads")}
          />
          <DashboardBox
            number={viewData.countView}
            title={translate("resources.dashboard.numberofviews")}
            pourcent={props.pourcent.countView}
            pourcentShow={props.pourcentShow}
            actualPourcent={props.actualPourcent.countView}
          />
          <DashboardBox
            number={viewData.countClick}
            title={translate("resources.dashboard.numberofclick")}
            pourcent={props.pourcent.countClick}
            pourcentShow={props.pourcentShow}
            actualPourcent={props.actualPourcent.countClick}
          />
          <DashboardBox
            number={viewData.countClickWhatsapp}
            title={translate("resources.dashboard.numberofclickwhatsapp")}
            pourcent={props.pourcent.countClickWhatsapp}
            pourcentShow={props.pourcentShow}
            actualPourcent={props.actualPourcent.countClickWhatsapp}
          />
          <DashboardBox
            number={viewData.countClickVideo}
            title={translate("resources.dashboard.numberofviewvideo")}
            pourcent={props.pourcent.countClickVideo}
            pourcentShow={props.pourcentShow}
            actualPourcent={props.actualPourcent.countClickVideo}
          />
          <DashboardBox
            number={viewData.countClickPhone}
            title={translate("resources.dashboard.numberofclickphone")}
            pourcent={props.pourcent.countClickPhone}
            pourcentShow={props.pourcentShow}
            actualPourcent={props.actualPourcent.countClickPhone}
          />
        </DashboardBoxContainer>
        {/* <FormAbo
          record={props.agency}
          $uppercase={"true"}
          $size={"true"}
          noDivider={true}
          refresh={props.refresh}
          context={"dashboard"}
        /> */}
        {/* {projects
          ? projects.length !== 0 && (
              <DashboardTopProjectContainer>
                <TopProjectText>
                  <TopProjectTextBold>
                    {translate("resources.dashboard.thenews")}
                  </TopProjectTextBold>
                </TopProjectText>
              </DashboardTopProjectContainer>
            )
          : null}
        <DashboardSLidesContainer>
          {projects
            ? projects.slice(0, 3).map((item, index) => {
                return (
                  <DashboardSlide
                    key={index}
                    item={item}
                    redirect={redirect}
                    open={open}
                  />
                );
              })
            : null}
        </DashboardSLidesContainer> */}
      </DashboardContainer>
    </Fade>
  );
};

export default DashboardWrapper(DashboardView);
