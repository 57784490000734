import React, { useState } from "react";
import {
  FormContainer,
  FormContainerFilter,
  FormGroup,
  FormInner,
  Input,
  Logo,
  LogoContainer,
  SendButton,
  TitleCode,
} from "./Form.styles";
import { Fade } from "@material-ui/core";
import { useAlert } from "react-alert";
import { useTranslation } from "react-i18next";

const FormCode = (props) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  const submit = async () => {
    const response = await props.secureUnlock(code);
    if (response && response.error === "code") {
      alert.error(`${t("alert.securepage.invalidcode")}`);
    } else {
      if (props.context === "doc") {
        props.setActivated(true);
        props.setModal(false);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setCode(value);
  };

  return (
    <FormContainer image={props.project.images[0].src}>
      <FormContainerFilter />
      <Fade in={true} timeout={400}>
        <FormInner margin={props.margin}>
          <LogoContainer>
            <Logo
              src={
                "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-1-1-1536x478.png"
              }
              alt="logo"
            />
          </LogoContainer>
          <TitleCode>{t("securepage.entercode")}</TitleCode>
          <FormGroup>
            <Input
              type="text"
              name="code"
              placeholder={t("securepage.yourcode")}
              onChange={handleChange}
              value={code}
            />
          </FormGroup>
          <FormGroup>
            <SendButton onClick={submit}>{t("securepage.send")}</SendButton>
          </FormGroup>
        </FormInner>
      </Fade>
    </FormContainer>
  );
};

export default FormCode;
