import React from "react";
import BarceloneTemplateLandscape from "./BarceloneTemplateLandscape/BarceloneTemplateLandscape";
import BarceloneTemplatePortrait from "./BarceloneTemplatePortrait/BarceloneTemplatePortrait";

const Index = React.forwardRef(
  ({ isMini, isPreview, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <BarceloneTemplateLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    } else if (orientation === "portrait") {
      return (
        <BarceloneTemplatePortrait
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default Index;
