import React from "react";
import { Create } from "react-admin";
import TemplateForm from "./TemplateForm";

const TemplateCreate = (props) => (
  <Create {...props}>
    <TemplateForm {...props} />
  </Create>
);

export default TemplateCreate;
