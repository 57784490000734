import React from "react";
import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { aboText, adsText } from "../AboText";
import { useTranslate } from "react-admin";
import { styleOne } from "./Collapse.mui.styles";
import { ListItemContainer } from "../../Utils/Styles/Agency.styles";
// import { getOneYear, nowList } from "../../../../../Utils/features";

const CollapseOne = (props) => {
  const translate = useTranslate();
  const classes = styleOne(props.type);

  const type = props.record.type === "ads" ? "subtype" : "type";
  return (
    <Collapse in={true}>
      <List component="nav" className={classes.root}>
        {adsText
          .concat(aboText)
          .filter((item) => item.name === props.record[type])
          .map((item, idx) => (
            <ListItemContainer key={idx}>
              <ListItem key={idx}>
                {item.name === "print-include" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      primary={translate(
                        "resources.agencies.fields.aboList.printinclude"
                      )}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      primary={
                        item.name.toUpperCase() +
                        " " +
                        (props.record.total +
                          "€" +
                          " " +
                          translate("resources.agencies.fields.aboList.cycle"))
                      }
                      style={{ fontSize: "14px" }}
                    />
                    {props.record.type !== "display" &&
                    props.record.usersupp &&
                    props.record.usersupp !== 0 ? (
                      <p
                        style={{
                          color: "#34495ed9",
                          fontSize: "14px",
                          marginBottom: "0.05rem",
                        }}
                      >
                        {translate(
                          "resources.agencies.fields.numberofuser.shortbis"
                        ) +
                          " - " +
                          props.record.usersupp}
                      </p>
                    ) : null}
                    {props.record.type === "display" &&
                    props.record.screensupp &&
                    props.record.screensupp !== 0 ? (
                      <p
                        style={{
                          color: "#34495ed9",
                          fontSize: "14px",
                          marginBottom: "0.05rem",
                        }}
                      >
                        {translate(
                          "resources.agencies.fields.nomberofscreen.short"
                        ) +
                          " - " +
                          props.record.screensupp}
                      </p>
                    ) : null}
                    {props.record.type !== "display" &&
                    props.record.mandatsupp &&
                    props.record.mandatsupp !== 0 ? (
                      <p
                        style={{
                          color: "#34495ed9",
                          fontSize: "14px",
                          marginBottom: "0.05rem",
                        }}
                      >
                        {translate(
                          "resources.agencies.fields.numberofmandats.shortbis"
                        ) +
                          " - " +
                          props.record.mandatsupp}
                      </p>
                    ) : null}
                  </div>
                )}
              </ListItem>
            </ListItemContainer>
          ))}
      </List>
      {/* {props.record.type !== "print-include" && (
        <ExpandContainer nopadding="true">
          <ExpandText size={"0.9rem"}>
            {translate("resources.agencies.nextinvoicing").toUpperCase()}
          </ExpandText>
          <ExpandText size={"0.9rem"}>
            {props.record.dateStart ? nowList(props.record.dateStart) : "-"}
          </ExpandText>
        </ExpandContainer>
      )} */}
      {/* <ExpandContainer>
        <ExpandText>DATE DE DÉMARRAGE</ExpandText>
        <ExpandText>
          {props.record.abo.ads.dateStart
            ? nowList(props.record.abo.ads.dateStart)
            : "-"}
        </ExpandText>
      </ExpandContainer>
      {props.record.abo.ads.dateStart && (
        <ExpandContainer nopadding="true">
          <ExpandText>DATE DE FIN</ExpandText>
          <ExpandText>
            {props.record.abo.ads.dateStart
              ? getOneYear(props.record.abo.ads.dateStart)
              : "-"}
          </ExpandText>
        </ExpandContainer>
      )} */}
    </Collapse>
  );
};

export default CollapseOne;
