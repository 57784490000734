/* eslint-disable no-unused-vars */
import * as React from "react";
import { Popover } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { CombineContext } from "../../../store/UserProvider";
import {
  DescItem,
  ItemTextContainer,
  SwitchIcon,
  SwitchIconContainer,
  SwitchItemContainer,
  SwitchItemsContainer,
  SwitchItemsContainerTitle,
  TitleItem,
} from "./AboSwitcher.styles";
import { makeStyles } from "@material-ui/styles";
import { useGetAbo } from "./useGetAbo";
import { useRedirect } from "ra-core";
import {
  colorPrimary,
  colorTertiary,
  colorSecondary,
  colorQuaternary,
  visibbledataColor,
} from "../../../GlobalStyles";

export const ThirdStepStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1999,
    "& .MuiPopover-paper": {
      top: "70px !important",
    },
  },
}));

const AboSwitcher = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const classes = ThirdStepStyles();
  const abo = React.useContext(CombineContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getTheTypeAbo } = useGetAbo();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (value) => {
    handleClose();
    abo.typeValue.setType(value);
    redirect("/");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const noView = ["empty", null, undefined];
  return (
    <>
      {!noView.includes(getTheTypeAbo(abo.agencyValue.agency)) && (
        <div>
          <SwitchIconContainer>
            <SwitchIcon onClick={handleClick} />
          </SwitchIconContainer>
          <Popover
            id={id}
            className={classes.root}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SwitchItemsContainer>
              <SwitchItemsContainerTitle>
                {translate("resources.aboSwitcher.title")}
              </SwitchItemsContainerTitle>
              {getTheTypeAbo(abo.agencyValue.agency) === "360" && (
                <SwitchItemContainer
                  color={colorTertiary}
                  active={abo.typeValue.type === "360"}
                  onClick={() => handleChange("360")}
                >
                  <ItemTextContainer>
                    <TitleItem
                      active={abo.typeValue.type === "360"}
                      color={colorTertiary}
                    >
                      360
                    </TitleItem>
                    <DescItem>
                      {translate("resources.aboSwitcher.360")}
                    </DescItem>
                  </ItemTextContainer>
                </SwitchItemContainer>
              )}
              <SwitchItemContainer
                color={colorSecondary}
                active={abo.typeValue.type === "print"}
                onClick={() => handleChange("print")}
              >
                <ItemTextContainer>
                  <TitleItem
                    active={abo.typeValue.type === "print"}
                    color={colorSecondary}
                  >
                    Print
                  </TitleItem>
                  <DescItem>
                    {translate("resources.aboSwitcher.print")}
                  </DescItem>
                </ItemTextContainer>
              </SwitchItemContainer>
              <SwitchItemContainer
                color={colorQuaternary}
                onClick={() =>
                  window.open(
                    "https://auth.vmlive.vitrinemedia.com/auth/login",
                    "_blank"
                  )
                }
              >
                <ItemTextContainer>
                  <TitleItem color={colorQuaternary}>Display</TitleItem>
                  <DescItem>
                    {translate("resources.aboSwitcher.display")}
                  </DescItem>
                </ItemTextContainer>
              </SwitchItemContainer>
              <SwitchItemContainer
                color={visibbledataColor}
                active={abo.typeValue.type === "data"}
                onClick={() => handleChange("data")}
              >
                <ItemTextContainer>
                  <TitleItem
                    active={abo.typeValue.type === "data"}
                    color={visibbledataColor}
                  >
                    Data
                  </TitleItem>
                  <DescItem>{translate("resources.aboSwitcher.data")}</DescItem>
                </ItemTextContainer>
              </SwitchItemContainer>
              <SwitchItemContainer
                color={colorPrimary}
                onClick={() =>
                  window.open(
                    "https://visibble.io/solutions/audit-digital/",
                    "_blank"
                  )
                }
              ></SwitchItemContainer>
            </SwitchItemsContainer>
          </Popover>
        </div>
      )}
    </>
  );
};

export default AboSwitcher;
