import styled from "styled-components";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PhoneIcon from "@material-ui/icons/Phone";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { colorTertiary } from "../../GlobalStyles";

export const InfosContainer = styled.div`
  && {
    width: ${(props) => (props.mobile ? "40px" : "35px")};
    height: ${(props) => (props.mobile ? "40px" : "35px")};
    color: ${(props) => (props.color ? "white" : "inherit")};

    & .fa-lg {
      font-size: 1.03em;
    }
  }
`;

export const PlayCircleFWI = styled(PlayCircleFilledWhiteIcon)`
  && {
    font-size: 24px;
  }
`;

export const FavIcon = styled(FavoriteIcon)`
  && {
    font-size: 24px;
    color: ${colorTertiary};
  }
`;

export const FavBorderIcon = styled(FavoriteBorderIcon)`
  && {
    font-size: 24px;
  }
`;

export const VisblIcon = styled(VisibilityIcon)`
  && {
    font-size: 24px;
  }
`;

export const PhoneUI = styled(PhoneIcon)`
  && {
    color: white;
    font-size: 24px;
  }
`;
export const PdfIcon = styled(PictureAsPdfIcon)`
  && {
    color: ${(props) => (props.$mobile ? "black" : "white")};
    font-size: 24px;
  }
`;

export const MenuBurger = styled.svg`
  && {
    position: absolute;
    top: 33px;
    right: 22px;
    font-size: 2.3rem;
    color: white;
    cursor: pointer;
    transform: ${(props) =>
      props.$toggle ? "rotate(180deg)" : "rotate(0deg)"};
    transition: transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    @media screen and (max-width: 600px) {
      top: 6px;
    }
  }
`;
