/* eslint-disable no-unused-vars */
import { Html } from "@react-three/drei";
import React, { useEffect, useState } from "react";
import InfoIcon from "@material-ui/icons/Info";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  ContainerSvg,
  HotspotInfoContainer,
  HotspotInfoContent,
  HotspotInfoContentText,
  HotspotInfoHeader,
  HotspotInfoText,
  HotspotInfoTextContainer,
  InfoSvg,
} from "./Hotspot.style";
const HotspotInfo = React.forwardRef((props, ref) => {
  const [hovered, setHovered] = useState(false);
  const [hoveredHeader, setHoveredHeader] = useState(false);
  const [hoveredContent, setHoveredContent] = useState(false);
  const [hoveredDesc, setHoveredDesc] = useState(false);
  const [hoveredText, setHoveredText] = useState(false);

  const handleClick = () => {
    if (!hovered) {
      setHovered(true);
      setHoveredHeader(true);
      setHoveredText(true);
      setTimeout(() => {
        setHoveredContent(true);
        setHoveredDesc(true);
      }, 400);
    } else {
      setHoveredDesc(false);
      setHoveredContent(false);
      setTimeout(() => {
        setHovered(false);
        setHoveredHeader(false);
        setHoveredText(false);
      }, 400);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (hovered) {
        if (e.target === ref.canvasRef.current) {
          setHoveredDesc(false);
          setHoveredContent(false);
          setTimeout(() => {
            setHovered(false);
            setHoveredHeader(false);
            setHoveredText(false);
          }, 400);
        }
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  });

  return (
    <mesh position={props.item.position}>
      <Html center zIndexRange={[1000, 0]}>
        <HotspotInfoContainer hovered={hoveredHeader}>
          <HotspotInfoHeader
            hovered={hoveredHeader}
            isText={props.item.description === "" ? true : false}
          >
            <ContainerSvg hovered={hovered}>
              <InfoSvg
                as={hovered ? CancelIcon : InfoIcon}
                hovered={hovered}
                onClick={() => handleClick()}
              />
            </ContainerSvg>
            <HotspotInfoTextContainer hovered={hoveredText}>
              <HotspotInfoText hovered={hoveredText}>
                {props.item.title}
              </HotspotInfoText>
            </HotspotInfoTextContainer>
          </HotspotInfoHeader>
          <HotspotInfoContent
            hovered={hoveredContent}
            isText={props.item.description !== "" ? true : false}
          >
            <HotspotInfoContentText
              hovered={hoveredDesc}
              minWidth={props.item.description.length}
            >
              {props.item.description}
            </HotspotInfoContentText>
          </HotspotInfoContent>
        </HotspotInfoContainer>
        {/* </HotspotRoundContainer> */}
      </Html>
    </mesh>
  );
});

export default HotspotInfo;
