import styled from "styled-components";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SendIcon from "@material-ui/icons/Send";
import { colorPrimary } from "../../../../../GlobalStyles";

export const ShowIcon = styled(VisibilityIcon)`
  && {
    color: ${colorPrimary};
    cursor: pointer;
  }
`;
export const SendCodeIcon = styled(SendIcon)`
  && {
    color: ${colorPrimary};
    cursor: pointer;
  }
`;
