import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CssTextField } from "../Utils/input.mui.styles";
import { FormGroup, TitleForm } from "../Utils/Offer.styles";

const StepFour = (props) => {
  const { t } = useTranslation();
  const classes = CssTextField();
  return (
    <>
      <div style={{ marginTop: "2rem" }} />
      <TitleForm>{t("offer.stepfour")}</TitleForm>

      <FormGroup className={classes.root}>
        <TextField
          type="text"
          id="informations"
          name="code"
          label={"votre code"}
          onChange={props.handleChange}
          value={props.state.informations.code}
          fullWidth
        />
      </FormGroup>
    </>
  );
};

export default StepFour;
