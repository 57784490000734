import React, { useContext, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useTranslate, useRedirect } from "react-admin";
import { config } from "../../../../constants/constant";
import { UserChangeContext } from "../../../../store/UserProvider";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { choices } from "../../User/Utils/data-choices";
import useData from "./hook/useData";
import { CircularContainer } from "../AboSwitcher.styles";

const Modal = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { setUserChange } = useContext(UserChangeContext);
  const [selected, setSelected] = useState("");
  const { dataFetch, loading } = useData();
  const getPermissions = (value) => {
    const result = choices.permissions.filter((obj) => {
      return obj.id === value;
    });
    return result;
  };

  const defaultProps = {
    options: dataFetch.sort(function (a, b) {
      return a.firstname.localeCompare(b.firstname);
    }),
    getOptionLabel: (option) => {
      return `${option.firstname} ${option.lastname} ${
        option.agenceId
          ? `(${translate(getPermissions(option.permissions)[0].name)} - ${
              option.agenceId.name
            })`
          : ""
      }`;
    },
  };

  const cancel = () => {
    props.setModal(false);
    props.closeModal();
  };

  const handleChange = (event, value) => {
    setSelected(value);
  };

  const changeUser = async () => {
    props.closeModal();
    props.setModal(false);

    try {
      const payload = {
        headers: {
          Authorization: localStorage.getItem(config.ACCESS_TOKEN_NAME),
        },
      };
      const res = await axios.get(
        config.API_BASE + "/users/" + selected._id,
        payload
      );

      const token = await axios.get(
        config.API_BASE + "/users/getToken/" + selected._id,
        payload
      );
      res.data.accessToken = token.data;
      // localStorage.setItem(config.ACCESS_TOKEN_NAME, token.data);
      setUserChange(res.data);
      redirect("/");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Dialog open={true} fullWidth>
        <DialogTitle>{translate("resources.modal.user.title")}</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularContainer>
              <CircularProgress />
            </CircularContainer>
          ) : (
            <Autocomplete
              {...defaultProps}
              onChange={handleChange}
              noOptionsText={translate("resources.fields.noresult")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate("resources.fields.search")}
                  margin="normal"
                  variant="filled"
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancel()}>
            {translate("resources.fields.cancel")}
          </Button>
          <Button
            disabled={selected === ""}
            onClick={() => changeUser()}
            color="primary"
          >
            {translate("resources.fields.validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
