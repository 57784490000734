import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import { NumberInput, useTranslate } from "react-admin";

// HR COMPONENT
const Hr = () => {
  return <Divider light style={{ marginBottom: "20px" }} />;
};

const Rooms = (props) => {
  const translate = useTranslate();

  return (
    <div style={{ marginTop: "15px" }}>
      <Typography variant="h6" gutterBottom>
        {translate("resources.projects.typographies.rooms")}
      </Typography>
      <Hr />
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="cuisine"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="sdb"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="sde"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="wc"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="garage"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="cave"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="terrace"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
    </div>
  );
};

export default Rooms;
