import React from "react";
import { Edit } from "react-admin";
import TemplateForm from "./TemplateForm";

const TemplateEdit = (props) => (
  <Edit resource="templates" undoable={false} {...props}>
    <TemplateForm />
  </Edit>
);

export default TemplateEdit;
