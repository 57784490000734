import React from "react";
import { useTranslate } from "react-admin";
import AreaGraph from "./AreaGraph";
import Doughnuts from "./Doughnuts";
import { HorizontalGraph } from "./HorizontalGraph";
import {
  BodyItemContainer,
  Box,
  BoxContainer,
  BoxGraphContainer,
  BoxInner,
  BoxInnerItem,
  BoxLabel,
  BoxTitle,
  BoxValue,
  GraphValue,
  GraphValueContainer,
  Line,
  Shape,
  SpanShape,
  TitleContainer,
  TitleSection,
  TitleSectionContainer,
} from "./styles";

const Audience = (props) => {
  const translate = useTranslate();

  const data = {
    datasets: [
      {
        label: "",
        data:
          props.audienceGenderMiddle !== null
            ? [
                props.audienceGenderMiddle.femmes,
                props.audienceGenderMiddle.hommes,
              ]
            : null,
        backgroundColor: ["#5463fa", "#ffd950"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <BodyItemContainer marginRight={"0%"}>
      <TitleSectionContainer>
        <TitleContainer>
          <TitleSection>{translate("resources.data.audience")}</TitleSection>
        </TitleContainer>
        <Line />
      </TitleSectionContainer>
      <BoxContainer marginTop="20px">
        <Box width={"49%"} marginRight={"2%"} minHeight="110px">
          <BoxInner display="flex">
            <BoxInnerItem width="30%">
              <Shape>
                <SpanShape>👀</SpanShape>
              </Shape>
            </BoxInnerItem>
            <BoxInnerItem width="70%">
              <BoxTitle>
                {translate("resources.data.audience").toUpperCase()}
              </BoxTitle>
              <BoxValue>{props.viewAudience}</BoxValue>
              <BoxLabel>{translate("resources.data.audiencelegend")}</BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
        <Box width={"49%"} marginRight={"0%"} minHeight="110px">
          <BoxInner display="flex">
            <BoxInnerItem width="30%">
              <Shape>
                <SpanShape small>👨👩‍🦰</SpanShape>
              </Shape>
            </BoxInnerItem>
            <BoxInnerItem width="70%">
              <BoxTitle>
                {translate("resources.data.audienceperday").toUpperCase()}
              </BoxTitle>
              <BoxValue>
                {props.viewAudience !== 0
                  ? Math.round(props.viewAudience / props.daysLength)
                  : 0}
              </BoxValue>
              <BoxLabel>
                {translate("resources.data.audienceperdaylegend")}
              </BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
      </BoxContainer>
      <BoxContainer marginTop="10px">
        <Box width={"100%"} marginRight={"0%"} minHeight="130px">
          <BoxInner display="block">
            <BoxTitle marginLeft={"5px"}>
              {translate("resources.data.statistics").toUpperCase()}
            </BoxTitle>
            <div style={{ height: "15px" }} />
            <BoxInnerItem width="100%" center>
              <BoxTitle> {translate("resources.data.youtouch")}</BoxTitle>
              <BoxValue>{props.touchPourcent.toFixed(2)}%</BoxValue>
              <BoxLabel>{translate("resources.data.youtouchlegend")}</BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
      </BoxContainer>
      <BoxContainer marginTop="10px">
        <Box width={"49%"} marginRight={"2%"} minHeight="95px">
          <BoxInner display="flex">
            <BoxInnerItem width="30%">
              <Shape>
                <SpanShape>⏱️</SpanShape>
              </Shape>
            </BoxInnerItem>
            <BoxInnerItem width="70%">
              <BoxTitle>
                {translate("resources.data.watchingtime").toUpperCase()}
              </BoxTitle>
              <BoxValue>{Math.round(props.middleTime)} sec</BoxValue>
              <BoxLabel>
                ({translate("resources.data.watchingtimelegend")})
              </BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
        <Box width={"49%"} marginRight={"0%"} minHeight="95px">
          <BoxInner display="flex">
            <BoxInnerItem width="100%" center>
              <BoxValue>{props.interested}</BoxValue>
              <BoxTitle>{translate("resources.data.people")}</BoxTitle>
              <BoxLabel>
                {translate("resources.data.statisticslegend1")}
              </BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
      </BoxContainer>
      <BoxContainer marginTop="10px">
        <Box width={"49%"} marginRight={"2%"} minHeight="100px">
          <BoxInner display="flex">
            <BoxInnerItem width="100%" center>
              <BoxValue>{props.leads}</BoxValue>
              <BoxTitle>{translate("resources.data.people")}</BoxTitle>
              <BoxLabel>
                {translate("resources.data.statisticslegend2")}
              </BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
        <Box width={"49%"} marginRight={"0%"} minHeight="100px">
          <BoxInner display="flex">
            <BoxInnerItem width="100%" center>
              <BoxValue>
                {props.leads !== 0
                  ? ((props.leads / props.viewAudience) * 100).toFixed(2)
                  : "0.00"}
                %
              </BoxValue>
              <BoxLabel>
                {translate("resources.data.statisticslegend3")}
              </BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
      </BoxContainer>
      <BoxContainer marginTop="10px">
        <Box width={"39%"} marginRight={"2%"} minHeight="155px">
          <BoxInner display="block">
            <BoxTitle marginLeft={"5px"}>
              {translate("resources.data.distribution").toUpperCase()}
            </BoxTitle>
            <BoxGraphContainer display="flex">
              <Doughnuts data={data} />
              <GraphValueContainer>
                <GraphValue>
                  👨{" "}
                  {props.audienceGenderMiddle !== null
                    ? props.audienceGenderMiddle.hommes.toFixed(0)
                    : "0"}
                  %
                </GraphValue>
                <GraphValue>
                  👩{" "}
                  {props.audienceGenderMiddle !== null
                    ? props.audienceGenderMiddle.femmes.toFixed(0)
                    : "0"}
                  %
                </GraphValue>
              </GraphValueContainer>
            </BoxGraphContainer>
          </BoxInner>
        </Box>
        <Box width={"59%"} marginRight={"0%"} minHeight="155px">
          <BoxInner>
            <BoxTitle marginLeft="5px">
              {translate("resources.data.distributionbyage").toUpperCase()}
            </BoxTitle>
            <BoxGraphContainer>
              <HorizontalGraph
                data={
                  props.audienceByAge.length
                    ? [
                        props.audienceByAge[0].values,
                        props.audienceByAge[1].values,
                        props.audienceByAge[2].values,
                        props.audienceByAge[3].values,
                        props.audienceByAge[4].values,
                      ]
                    : []
                }
              />
            </BoxGraphContainer>
          </BoxInner>
        </Box>
      </BoxContainer>
      <BoxContainer marginTop="10px">
        <Box width={"100%"} marginRight={"0%"} minHeight="140px">
          <BoxInner display="block">
            <BoxTitle marginLeft={"5px"}>
              {translate("resources.data.audience").toUpperCase()}
            </BoxTitle>
            <div style={{ height: "10px" }} />
            <BoxGraphContainer display="flex" width="100%" height={"100px"}>
              <AreaGraph
                data={props.audienceChartData.sort(
                  (a, b) => new Date(a.fullDate) - new Date(b.fullDate)
                )}
                label="Valeur"
              />
            </BoxGraphContainer>
          </BoxInner>
        </Box>
      </BoxContainer>
    </BodyItemContainer>
  );
};

export default Audience;
