import React from "react";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import ModulePrice from "../../ModulesElements/ModulePrice";
import ModuleAlurDetails from "../../ModulesElements/ModuleAlurDetails";
import {
  Container,
  Header,
  Footer,
  ContentContainer,
  CityContainer,
  CityText,
  ImagesContainer,
  Img,
  DescContainer,
  BlockDescContainer,
  Description,
  ExcluContainer,
  ExcluText,
} from "./Perso.styles";
import ModuleQrCode from "../../ModulesElements/ModuleQrCode";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../ModulesElements/ModulesElements.styles";

const PersoLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const excludedValue = ["", undefined, null];

  const {
    imageFirst,
    brightness,
    saturation,
    mirror,
    color,
    colorSecondary,
    project,
    colorTitle,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    desc,
    colorPrice,
    imageSupp,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      mirror={mirror}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <div
        style={{
          position: "absolute",
          height: "292mm",
          width: "420mm",
          zIndex: "1000",
          backgroundImage: `url(${
            agency.background && agency.background.length
              ? agency.background[0].src
              : null
          })`,
        }}
      />
      <Header
        src={
          agency.header && agency.header.length ? agency.header[0].src : null
        }
        as={agency.header && agency.header.length ? "img" : "div"}
        colorPrimary={color.primary || "black"}
      />
      <Footer
        src={
          agency.footer && agency.footer.length ? agency.footer[0].src : null
        }
        as={agency.footer && agency.footer.length ? "img" : "div"}
        colorPrimary={color.primary || "black"}
      />
      <ContentContainer>
        <CityContainer>
          <CityText>
            <span
              style={{
                color: !excludedValue.includes(colorTitle)
                  ? colorTitle
                  : "black",
              }}
            >
              {t(
                choices.category.filter(
                  (item) => item.id === project.category
                )[0].name
              )}
            </span>{" "}
            <span
              style={{
                color: !excludedValue.includes(colorTitle)
                  ? colorTitle
                  : "black",
              }}
            >
              {project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </span>{" "}
            -{" "}
            <span
              style={{
                color: !excludedValue.includes(colorSubTitle)
                  ? colorSubTitle
                  : "black",
              }}
            >
              {subTitleText}
            </span>
          </CityText>
        </CityContainer>
        <ImagesContainer>
          <Img
            src={imageFirst}
            brightness={brightness}
            saturation={saturation}
            colorPrimary={color.primary || "black"}
          />
          <Img
            src={imageSupp[0]}
            brightness={brightness < 110 ? brightness : brightness - 10}
            saturation={saturation}
            colorPrimary={color.primary || "black"}
          />
          {project.agreement === choices.agreement[2].id && (
            <ExcluContainer colorPrimary={colorSecondary || "black"}>
              <ExcluText>{t("details.modalprint.exclu")}</ExcluText>
            </ExcluContainer>
          )}
        </ImagesContainer>
        <DescContainer>
          <BlockDescContainer>
            <RenderContext project={project}>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "43px",
                  left: "5px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.17"
                      : "0.19"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "56px"
                      : "-2px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "230px"
                      : "253px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.40"
                      : "0.23"
                  }
                  theme={"light"}
                />
              </div>
            </RenderContext>
          </BlockDescContainer>
          <BlockDescContainer>
            <div
              style={{
                width: "100%",
                paddingTop: "1rem",
                paddingRight: "2rem",
              }}
            >
              <Description colorDesc={colorDesc || "black"}>
                {desc.substr(0, 450)}
              </Description>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "1rem",
                }}
              >
                <div>
                  <ModulePrice
                    colorPrice={colorPrice || "black"}
                    price={project.price}
                    project={project}
                  />
                </div>
                <div style={{ width: "50%", padding: "1rem 0 0 6rem" }}>
                  <RenderContext project={project}>
                    <ModuleAlurDetails
                      project={project}
                      colorDesc={colorDesc}
                      price={project.price}
                      defaultColor={"black"}
                      smallMarginBottom={"0"}
                      sizeText={"17px"}
                    />
                  </RenderContext>
                  <AlurText sizeText={"17px"} colorDesc={colorDesc || "black"}>
                    Les informations sur les risques auxquels ce bien est exposé
                    sont disponibles sur le site Géorisques :
                    http://www.georisques.gouv.fr
                  </AlurText>
                </div>
              </div>
            </div>
          </BlockDescContainer>
        </DescContainer>
      </ContentContainer>
      {qrcodeEdit !== null && (
        <div
          style={{
            position: "absolute",
            top: "213mm",
            right: "15mm",
            zIndex: 1000,
          }}
        >
          <ModuleQrCode qrcode={qrcodeEdit} size={120} />
        </div>
      )}
    </Container>
  );
});

export default PersoLandscape;
