import React, { useEffect } from "react";
import {
  Toolbar,
  SaveButton,
  useGetIdentity,
  useLocale,
  useSetLocale,
} from "react-admin";

const UserToolbar = (props) => {
  const { identity, loading: identityLoading } = useGetIdentity();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const { enableSave, initialValues } = props;
  useEffect(() => {
    if (props.record.id && props.record.lang !== locale) {
      setLocale(props.record.lang);
      localStorage.setItem("i18nextLng", props.record.lang);
    }
  }, [locale, props.record.id, props.record.lang, setLocale]);

  if (identityLoading) return <>Loading...</>;
  return (
    <Toolbar>
      <SaveButton
        disabled={enableSave === false ? false : props.pristine}
        transform={(data) => ({ ...data, ...initialValues })}
        submitOnEnter={false}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        redirect={identity.permissions !== "utilisateur" ? "list" : null}
      />
    </Toolbar>
  );
};
export default UserToolbar;
