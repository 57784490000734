import { withStyles, Card as MuiCard } from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
// createMuiTheme
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1115,
      lg: 1280,
      xl: 1920,
    },
  },
});
export const Card = withStyles(() => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export const UserListStyle = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  hiddenOnXSmallScreens: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
