import GoogleMapReact from "google-map-react";
import React from "react";
import PropTypes from "prop-types";
import Marker from "./Marker";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { useTranslation } from "react-i18next";

const ModalMap = (props) => {
  const { t } = useTranslation();
  const { infoProject } = props;
  // const infoProject = {
  //   city: "Paris",
  // };

  return (
    <div
      className="fade modal show"
      id="modal-map"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalMapLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalMapLabel">
              {t("sidebar.popoverMap")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA8PTYv_p1VddcjuUDE92IaubBdrZ1g6Q0",
              }}
              defaultCenter={{
                lat: infoProject.lat ? parseFloat(infoProject.lat) : 59.95,
                lng: infoProject.lon ? parseFloat(infoProject.lon) : 30.33,
              }}
              defaultZoom={15}
              // yesIWantToUseGoogleMapApiInternals
            >
              <Marker
                lat={infoProject.lat ? parseFloat(infoProject.lat) : 59.955413}
                lng={infoProject.lon ? parseFloat(infoProject.lon) : 30.337844}
                text={
                  infoProject.location ? infoProject.location : infoProject.city
                }
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalMap.propTypes = {
  infoProject: PropTypes.object,
};

export default ModalMap;
