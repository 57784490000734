import styled from "styled-components";

export const Container = styled.div`
  && {
    height: 296mm;
    width: 420mm;
    display: flex;
    background-color: white;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    padding: 8px 0 5px 0;

    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 portrait;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const ContainerLeft = styled.div`
  && {
    width: 34%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0 35pt;
  }
`;

export const ContainerVisibble = styled.div`
  && {
    width: 100%;
    display: flex;
    padding-top: 15pt;
  }
`;

export const Visibble = styled.img`
  && {
    width: 180px;
  }
`;

export const ContainerTitle = styled.div`
  && {
    width: 100%;
    padding-top: 10pt;
  }
`;

export const Title = styled.h4`
  && {
    color: ${(props) => props.colorTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;
export const SubTitle = styled.h4`
  && {
    color: ${(props) => props.colorSubTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const Ref = styled.p`
  && {
    margin-bottom: 10pt;
    text-align: left;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
    color: ${(props) => (props.color !== "" ? props.color : "black")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const ContainerDescription = styled.div`
  width: 100%;
  padding: 25pt 0;
  border-top: solid 1px;
  max-height: 259pt;
  overflow: hidden;
`;

export const Description = styled.p`
  && {
    padding-bottom: 2em;
    color: ${(props) => props.colorDesc};
    text-align: left;
    white-space: pre-wrap;
    line-height: 115%;
    font-weight: 500;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const ContainerPrice = styled.div`
  && {
    width: 100%;
    border-top: solid 1px;
    margin-top: 0pt;
    padding: 20pt 0;
  }
`;

export const ContainerDetails = styled.div`
  padding: 5pt 0;
  width: 100%;
`;

export const Details = styled.p`
  && {
    font-size: 100%;
    margin-bottom: 0;
  }
`;

export const ContainerDetailsRight = styled.div`
  && {
    width: 45%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid black;
    color: ${(props) => props.colorDesc};
  }
`;

export const ContainerRight = styled.div`
  && {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerImg = styled.div`
  && {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

export const ImgProperty = styled.img`
  && {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
  }
`;

export const ContainerBottom = styled.div`
  height: 256.81pt;
  width: 518pt;
  display: flex;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  right: 0;
  background-color: #ffffff78;
`;
export const IconsContainer = styled.div`
  background-color: ${(props) => props.colorPrimary};
  width: 404pt;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15pt;
  padding: 15pt;
  margin-left: -34pt;
  margin-top: auto;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 0)};
`;
export const IconText = styled.p`
  font-size: 12pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: ${(props) => props.marginLeft};
`;
export const ExcluContainer = styled.div`
  padding: 5px 0;
  background-color: #ffffff94;
  width: 200pt;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 20pt;
`;
export const ExcluText = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const QrcodeContainer = styled.div`
  width: 120pt;
  height: 120pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15pt;
  left: 15pt;
`;
