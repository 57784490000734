import React from "react";
import QRCode from "react-qr-code";
import {
  LondresTemplateContainer,
  BackgroundContainer,
  LogoContainer,
  LogoAgence,
  ExcluContainer,
  ExcluText,
  QrcodeContainer,
  InfoContainer,
  InfoLeftContainer,
  InfoType,
  InfoCity,
  InfoRef,
  InfoContainerPrice,
  InfoRightContainer,
  IconContainer,
  IconText,
  DescContainer,
  DescText,
  AlurContainer,
  DpeContainer,
  BackgroundImage,
} from "./styles";

import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { useTranslation } from "react-i18next";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../ModulesElements/ModulesElements.styles";

const LondresTemplateLandscape = React.forwardRef((props, ref) => {
  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  console.log(color);

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;
  const { t } = useTranslation();
  return (
    <LondresTemplateContainer
      ref={ref}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      brightness={brightness}
      saturation={saturation}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      mirror={mirror}
      background={imageFirst}
    >
      <BackgroundContainer>
        <BackgroundImage
          src={imageFirst}
          brightness={brightness}
          saturation={saturation}
        />
        <LogoContainer>
          {agency.images && agency.images.length ? (
            <LogoAgence src={agency.images[0].src} alt="logo agence" />
          ) : null}
        </LogoContainer>
        {isBanner === "true" && (
          <ExcluContainer colorPrimary={color.primary || "#18374a"}>
            <ExcluText
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </ExcluText>
          </ExcluContainer>
        )}
        {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
          <QrcodeContainer>
            <QRCode value={qrcodeEdit} size={145} />
          </QrcodeContainer>
        )}
      </BackgroundContainer>
      <InfoContainer colorPrimary={color.primary || "#18374a"}>
        <InfoLeftContainer>
          <InfoType
            colorTitle={colorTitle || "white"}
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            {project.type && project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </InfoType>
          <InfoCity
            colorSubTitle={colorSubTitle || "white"}
            size={subTitleTextFont.size}
            weight={subTitleTextFont.weight}
            family={subTitleTextFont.font}
          >
            {subTitleText}
          </InfoCity>
          <InfoRef
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            REF: {refText}
          </InfoRef>
        </InfoLeftContainer>
        <InfoContainerPrice>
          <ModulePrice
            colorPrice={colorPrice || "white"}
            price={price}
            project={project}
            align={"left"}
            sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
            weight={priceFont.weight}
            family={priceFont.font}
            sizeHonoraire={"12pt"}
            sizeSpanPrice={"20pt"}
            priceFont
          />
        </InfoContainerPrice>
        <InfoRightContainer>
          <IconContainer>
            <Surface color={colorSecondary || "white"} size={43} />
            <IconText colorSecondary={colorSecondary || "white"}>
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Rooms color={colorSecondary || "white"} size={43} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Chambres color={colorSecondary || "white"} size={43} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Sdb color={colorSecondary || "white"} size={43} />
              <IconText colorSecondary={colorSecondary || "white"}>
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </InfoRightContainer>
        <DescContainer>
          <DescText
            colorDesc={colorDesc || "white"}
            size={descFont.size}
            weight={descFont.weight}
            family={descFont.font}
          >
            {desc}
          </DescText>
        </DescContainer>
        <AlurContainer>
          <RenderContext project={project}>
            <ModuleAlurDetails
              project={project}
              colorDesc={colorDesc || "white"}
              price={project.price}
              defaultColor={"white"}
              sizeText={"13pt"}
            />
            <AlurText sizeText={"13pt"} colorDesc={colorDesc || "white"}>
              Les informations sur les risques auxquels ce bien est exposé sont
              disponibles sur le site Géorisques : http://www.georisques.gouv.fr
            </AlurText>
          </RenderContext>
        </AlurContainer>
        <DpeContainer>
          <RenderContext project={project}>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                top: "60px",
                left: "15px",
              }}
            >
              <Dpe
                letter={
                  project.dpeLetter
                    ? DpeGesNoValue.includes(project.dpeLetter)
                      ? "vi"
                      : project.dpeLetter
                    : "vi"
                }
                conso={project.dpeValue}
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "0.22"
                    : "0.25"
                }
                topTitle={"logement extrêmement performant"}
                bottomTitle={"logement extrêmement peu performant"}
                redInfoTop={"passoire"}
                redInfoBottom={"énergetique"}
                label={"consommation"}
                secondLabel={"émissions"}
                subLabel={"(énergie primaire)"}
                subValue={"kWh/m2/an"}
                secondSubValue={"kg CO₂/m²/an"}
                theme={"dark"}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                bottom:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "20px"
                    : "-50px",
                left:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "295px"
                    : "330px",
              }}
            >
              <Ges
                letter={
                  project.gesLetter
                    ? DpeGesNoValue.includes(project.gesLetter)
                      ? "vi"
                      : project.gesLetter
                    : "vi"
                }
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "0.50"
                    : "0.30"
                }
                theme={"dark"}
              />
            </div>
          </RenderContext>
        </DpeContainer>
      </InfoContainer>
    </LondresTemplateContainer>
  );
});

export default LondresTemplateLandscape;
