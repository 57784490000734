import React, { useContext } from "react";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { TextQrCode, ContainerTextQrCode } from "./ModulesElements.styles";
import { CombineContext } from "../../../../store/UserProvider";
const ModuleQrCode = (props) => {
  const { t } = useTranslation();
  const data = useContext(CombineContext);

  return (
    <>
      {props.text && (
        <ContainerTextQrCode>
          <TextQrCode
            colorDesc={props.colorDesc !== "" ? props.colorDesc : "black"}
            fontSize={props.fontSize}
          >
            {data.typeValue.type === "360"
              ? t("modalprint.qrcodetext")
              : t("details.modalprint.qrcodetextPrint")}
          </TextQrCode>
        </ContainerTextQrCode>
      )}
      <QRCode value={props.qrcode} size={props.size || 161} />
    </>
  );
};

export default ModuleQrCode;
