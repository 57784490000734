import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
// CSS
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { useTranslation } from "react-i18next";
import { VideoStyle } from "./ModalMedias.styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: "1rem",
  },
  gridList: {
    width: "90%",
    maxHeight: 650,
    "&::-webkit-scrollbar": { width: "0 !important" },
    msOverflowStyle: "none",
  },
}));

const ModalMedias = (props) => {
  const { infoProject, getAllClick } = props;
  const medias = [...infoProject.medias, ...infoProject.mediasurl];

  const { t } = useTranslation();
  const classes = useStyles();
  const containerVideoRef = useRef();

  useEffect(() => {
    function handler(event) {
      if (containerVideoRef.current?.contains(event.target)) {
        getAllClick("countClickVideo");
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [getAllClick]);

  return (
    <div
      className="fade modal show"
      id="modal-medias"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalMediaLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalGalleryLabel">
              {t("sidebar.popoverMedia")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body">
            <div className={classes.root}>
              <GridList cellHeight={230} className={classes.gridList} cols={1}>
                {medias &&
                  medias.map((media, index) => {
                    let iframeSrc;
                    if (media.mediasurl && media.mediasurl.type === "youtube")
                      iframeSrc =
                        "https://www.youtube.com/embed/" +
                        media.mediasurl.source;
                    if (media.mediasurl && media.mediasurl.type === "vimeo")
                      iframeSrc =
                        "https://player.vimeo.com/video/" +
                        media.mediasurl.source;
                    return (
                      <GridListTile key={index} cols={1}>
                        <div key={index} style={{ height: "100%" }}>
                          {media.src ? (
                            <VideoStyle controls ref={containerVideoRef}>
                              {/* <video controls style={videoStyle}> */}
                              <source src={media.src} />
                              {/* </video> */}
                            </VideoStyle>
                          ) : null}
                          {media.mediasurl && (
                            <iframe
                              ref={containerVideoRef}
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                              src={iframeSrc}
                              frameBorder="0"
                              allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="video"
                            ></iframe>
                          )}
                        </div>
                      </GridListTile>
                    );
                  })}
              </GridList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalMedias.propTypes = {
  infoProject: PropTypes.object,
};

export default ModalMedias;
