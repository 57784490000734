import React from "react";
import { Edit } from "react-admin";
import ContactForm from "./Components/Form/ContactForm";

const ContactEdit = (props) => {
  return (
    <Edit undoable={false} {...props}>
      <ContactForm />
    </Edit>
  );
};

export default ContactEdit;
