import React from "react";

const Rooms = ({ color, size }) => {
  return (
    <svg
      id="icons_room"
      data-name="icons room"
      xmlns="http://www.w3.org/2000/svg"
      width={size.toString()}
      height={size.toString()}
      viewBox="0 0 27.71 27.71"
    >
      <path
        id="Tracé_14"
        data-name="Tracé 14"
        d="M1386.195,276.422h-27.71v-27.71h27.71Zm-26.6-1.108h25.493V249.82h-25.493Z"
        transform="translate(-1358.485 -248.712)"
        fill={color}
      />
      <path
        id="Ligne_1"
        data-name="Ligne 1"
        d="M1375.706,259.208H1374.6v-9.422h1.108Z"
        transform="translate(-1362.128 -248.955)"
        fill={color}
      />
      <path
        id="Ligne_2"
        data-name="Ligne 2"
        d="M1375.706,280.4H1374.6V268.763h1.108Z"
        transform="translate(-1362.128 -253.245)"
        fill={color}
      />
      <path
        id="Ligne_3"
        data-name="Ligne 3"
        d="M1390.9,269.513h-9.144V268.4h9.144Z"
        transform="translate(-1363.747 -253.164)"
        fill={color}
      />
    </svg>
  );
};

export default Rooms;
