import React from "react";
import SydneyTemplateLandscape from "./SydneyTemplateLandscape/SydneyTemplateLandscape";
import SydneyTemplatePortrait from "./SydneyTemplatePortrait/SydneyTemplatePortrait";

const index = React.forwardRef(
  ({ isPreview, isMini, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <SydneyTemplateLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    } else {
      return (
        <SydneyTemplatePortrait
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
