import * as React from "react";
import { createElement } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources, useTranslate } from "react-admin";
import { withRouter } from "react-router-dom";
import { UserChangeContext, UserContext } from "../../../store/UserProvider";

const Menu = ({ onMenuClick, logout }) => {
  const t = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const [admin, setAdmin] = React.useState(false);
  const { user } = React.useContext(UserContext);
  const { userChange } = React.useContext(UserChangeContext);

  React.useEffect(() => {
    const role = ["super_admin", "admin"];
    if (userChange) {
      if (role.includes(userChange.permissions)) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    } else {
      if (role.includes(user.permissions)) {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    }
  }, [admin, user, userChange]);

  if (admin) {
    return (
      <div style={{ marginTop: "1rem" }}>
        {resources.map((resource) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={t(resource.options.label)}
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
        {isXSmall && logout}
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: "1rem" }}>
        {resources
          .filter((resource) => resource.name !== "users")
          .map((resource) => (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={t(resource.options.label)}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        {isXSmall && logout}
      </div>
    );
  }
};

export default withRouter(Menu);
