import React from "react";
import { useMutation } from "react-admin";
import { ActionBtn } from "../../../../../../Utils/Styles/BillingInformation.styles";

const ApproveButton = (props) => {
  const [approve, { loading }] = useMutation(
    {
      type: "update",
      resource: "agencies",
      payload: {
        id: props.record._id,
        data: {
          ...props.record,
          billingInformation: {
            ...props.record.billingInformation,
            billingMethod: { ...props.state },
            mode: props.state.mode,
          },
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        props.showMessage("resources.alert.changessaved");
        props.setModal(false);
      },
    }
  );
  return (
    <ActionBtn
      onClick={approve}
      disabled={loading}
      active="true"
      mycolor="false"
    >
      {props.translate("resources.fields.save")}
    </ActionBtn>
  );
};
export default ApproveButton;
