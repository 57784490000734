import styled from "styled-components";

export const Container = styled.div`
  && {
    height: 420mm;
    width: 297mm;
    display: flex;
    flex-direction: column;
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  }
  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 portrait;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const LogoContainer = styled.div`
  position: absolute;
  width: 170pt;
  top: 20px;
  right: 20px;
`;
export const LogoAgence = styled.img`
  width: 100%;
`;
export const ExcluContainer = styled.div`
  padding: 5px 0;
  background-color: ${(props) => props.colorPrimary};
  width: 198pt;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  position: absolute;
  right: 0;
`;
export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const InfoContainer = styled.div`
  width: 100%;
  height: 12%;
  background-color: ${(props) => props.colorPrimary};
  padding-left: 25px;
  padding-right: 15px;
  display: flex;
`;
export const InfoLeftContainer = styled.div`
  width: 35%;
  text-align: right;
`;

export const InfoType = styled.p`
  font-size: 27pt;
  font-weight: bold;
  color: ${(props) => props.colorTitle};
  margin-top: 15px;
  margin-bottom: 0;
`;
export const InfoCity = styled.p`
  font-size: 21pt;
  font-weight: initial;
  color: ${(props) => props.colorSubTitle};
  margin-top: 5px;
  margin-bottom: 0;
`;
export const InfoRef = styled.p`
  margin-top: 5px;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const InfoRightContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 65%;
  margin-left: 40px;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const BottomContainer = styled.div`
  height: 53%;
`;
export const SlideContainer = styled.div`
  width: 100%;
  display: flex;
  height: 26%;
`;
export const SlideItem = styled.div`
  display: flex;
  width: 33%;
  margin-right: ${(props) => (!props.right ? 0 : "10px")};
`;
export const SlideImage = styled.img`
  width: 100%;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const InfoContainerAlt = styled.div`
  width: 100%;
  height: 24%;
  background-color: ${(props) => props.colorPrimary};
  display: flex;
`;
export const InfoContainerAltLeft = styled.div`
  min-width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
`;
export const AlurContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const QrcodeContainer = styled.div`
  padding: 10px;
  background-color: white;
  margin: auto 15px auto auto;
`;
export const LastContainer = styled.div`
  height: 50%;
  display: flex;
`;
export const DpeContainer = styled.div`
  width: 56%;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const DescContainer = styled.div`
  width: 44%;
  padding: 15px 23px 15px 10px;
  max-height: 380px;
  overflow: hidden;
`;

export const DescText = styled.p`
  color: ${(props) => props.colorDesc};
  margin-top: 25px;
  margin-bottom: 0;
  line-height: 115%;
  white-space: pre-wrap;
  text-align: left;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
