import styled from "styled-components";

export const ColorChoiceContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const PickerContainer = styled.div`
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "initial")};
  text-align: center;
`;

export const PickerTextName = styled.p``;

export const ColorElement = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const EmptyDiv = styled.div``;
