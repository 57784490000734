import styled from "styled-components";
import { colorPrimary } from "../../../../../GlobalStyles";

export const Container = styled.div`
  padding: 1rem 1.5rem 0;
  min-width: 260px;
  opacity: ${(props) => (props.open ? "1" : "0")};
  transition: all 1s ease-in-out;
`;
export const ItemContainer = styled.div`
  display: flex;
  margin: 0 0px 10px;
`;
export const ItemInnerContainer = styled.div`
  display: flex;
  width: ${(props) => props.width};
`;
export const Label = styled.span`
  font-size: 1rem;
  font-weight: 700;
`;

export const IconContainer = styled.div`
  width: 50%;
`;
export const Icon = styled.svg`
  && {
    color: ${colorPrimary};
    cursor: pointer;
  }
`;
export const PoliceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0px 10px;
`;
