import React, { forwardRef, useContext, useEffect, useState } from "react";
import { AppBar, UserMenu, MenuItemLink, useTranslate } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import { useTranslation } from "react-i18next";
import logoVisibbleVitrineMedia from "../../../assets/logo-resize.png";
import { Toolbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  CombineContext,
  UserChangeContext,
  UserContext,
} from "../../../store/UserProvider";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import HelpIcon from "@material-ui/icons/Help";
import Modal from "./UserChangeAccount/ModalChangeAccount";
import LangSwitcher from "./LangSwitcher";
import AboSwitcher from "./AboSwitcher";
import { data } from "../../../constants/data";
import MenuExterneLink from "./MenuExterneLink";
import {
  appBarColor,
  visibble360Color,
  visibbledataColor,
  visibblePrintColor,
} from "../../../GlobalStyles";

const ConfigurationMenu = forwardRef(({ onClick }, ref) => {
  const { t } = useTranslation();
  const translate = useTranslate();
  const { user, setUser } = useContext(UserContext);
  const { userChange, setUserChange } = useContext(UserChangeContext);
  const [selected, setSelected] = useState({ user: user, setUser: setUser });
  const [modal, setModal] = useState(false);
  const closeModal = () => {
    onClick();
  };

  useEffect(() => {
    if (userChange) {
      setSelected({ user: userChange, setUser: setUserChange });
    }
  }, [userChange, setUserChange]);

  return (
    <div>
      <MenuItemLink
        ref={ref}
        to={selected.user ? `/users/${selected.user._id}` : "/"}
        primaryText={t("admin.profile.title")}
        leftIcon={<SettingsIcon />}
        onClick={onClick}
        sidebarIsOpen
      />
      {user !== undefined && user.permissions.includes("admin") && (
        <MenuItemLink
          to="#"
          primaryText={t("admin.profile.change")}
          leftIcon={<MeetingRoomIcon />}
          onClick={() => setModal(!modal)}
        />
      )}
      <MenuExterneLink
        Component={HelpIcon}
        href={"https://visibble.zendesk.com/hc/fr"}
        title={translate("resources.help.menuname")}
      />
      {modal && (
        <Modal modal={modal} setModal={setModal} closeModal={closeModal} />
      )}
    </div>
  );
});

const CustomUserMenu = (props) => {
  return (
    <UserMenu {...props} icon={<MyCustomIcon />}>
      <ConfigurationMenu />
    </UserMenu>
  );
};

const useStyles = makeStyles({
  avatar: {
    height: 30,
    width: 30,
  },
});

const MyCustomIcon = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const { userChange, setUserChange } = useContext(UserChangeContext);
  const [selected, setSelected] = useState({ user: user, setUser: setUser });
  useEffect(() => {
    if (userChange) {
      setSelected({ user: userChange, setUser: setUserChange });
    }
  }, [userChange, setUserChange]);
  return (
    <Avatar
      className={classes.avatar}
      src={
        selected.user.images && selected.user.images.length > 0
          ? selected.user.images[0].src
          : null
      }
    />
  );
};

const CustomAppBar = (props) => {
  const [logo, setLogo] = useState(logoVisibbleVitrineMedia);
  const [color, setColor] = useState(appBarColor);
  const abo = useContext(CombineContext);
  useEffect(() => {
    if (abo.typeValue.type === "print") {
      setLogo(data.whiteLogo.logoPrint);
      setColor(visibblePrintColor);
    } else if (abo.typeValue.type === "360") {
      setLogo(data.whiteLogo.logo360);
      setColor(visibble360Color);
    } else if (abo.typeValue.type === "data") {
      setLogo(data.whiteLogo.logoData);
      setColor(visibbledataColor);
    } else {
      setLogo(logoVisibbleVitrineMedia);
      setColor(appBarColor);
    }
  }, [abo]);

  return (
    <AppBar
      {...props}
      userMenu={<CustomUserMenu />}
      style={{ borderTop: `solid 5px ${color}` }}
    >
      <Toolbar style={{ flex: 1 }}>
        <Link to="/">
          <img src={logo} alt="logo" style={{ width: "125px" }} />
        </Link>
      </Toolbar>
      {abo.typeValue.type !== "admin" && <AboSwitcher />}
      <LangSwitcher />
    </AppBar>
  );
};

export default CustomAppBar;
