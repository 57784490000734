import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAlert } from "react-alert";
import { UserContext } from "../../../store/UserProvider";
// import Lang from "../../../Components/Lang";
import { config } from "../../../constants/constant";
import "../../../Authentication.css";
import SmallLoader from "../../../Components/Loader/SmallLoader";
import { useEffect } from "react";

const SignIn = (props) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  useEffect(() => {
    if (localStorage) {
      const token = localStorage.getItem(config.ACCESS_TOKEN_NAME);
      if (token) {
        const payload = { strategy: "jwt", accessToken: token };
        authenticate(payload);
      }
    }
  }, []);

  const handleSubmitClick = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (state.email.length && state.password.length) {
      const payload = {
        email: state.email,
        password: state.password,
        strategy: "local",
      };
      try {
        const response = await axios.post(
          `${config.API_BASE}/authentication`,
          payload
        );

        if (response.status === 200 || response.status === 201) {
          if (
            response.data.user.email.includes("vitrinemedia") ||
            response.data.user.email.includes("marchello")
          ) {
            if (response.data.user.isVerified) {
              if (response.data.user.isActivated) {
                setState((prevState) => ({
                  ...prevState,
                }));
                response.data.user.accessToken = response.data.accessToken;
                setUser(response.data.user);

                localStorage.setItem(
                  config.ACCESS_TOKEN_NAME,
                  response.data.accessToken
                );
                if (
                  props.history.location.state &&
                  props.history.location.state.prevPath
                ) {
                  props.history.push(props.history.location.state.prevPath);
                } else {
                  props.history.push("/adminonly");
                }
              } else {
                alert.error(`${t("alert.not-activated")}`);
              }
            } else {
              alert.error(`${t("alert.not-verified")}`);
            }
          } else {
            props.history.push("/404");
          }
        } else {
          alert.error(`${t("alert.error")}`);
        }
      } catch (error) {
        console.log(error);
        if (state.email.length && state.password.length) {
          alert.error(`${t("alert.authenticate-false")}`);
        } else {
          localStorage.removeItem(config.ACCESS_TOKEN_NAME);
          alert.error(`${t("alert.session-false")}`);
        }
      }
    } else {
      alert.error(`${t("alert.email-password")}`);
    }
    setLoading(false);
  };
  const authenticate = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.API_BASE}/authentication`,
        payload
      );

      if (response.status === 200 || response.status === 201) {
        if (
          response.data.user.email.includes("vitrinemedia") ||
          response.data.user.email.includes("marchello")
        ) {
          if (response.data.user.isVerified) {
            if (response.data.user.isActivated) {
              setState((prevState) => ({
                ...prevState,
              }));
              response.data.user.accessToken = response.data.accessToken;
              setUser(response.data.user);

              localStorage.setItem(
                config.ACCESS_TOKEN_NAME,
                response.data.accessToken
              );
              if (
                props.history.location.state &&
                props.history.location.state.prevPath
              ) {
                props.history.push(props.history.location.state.prevPath);
              } else {
                props.history.push("/adminonly");
              }
            } else {
              alert.error(`${t("alert.not-activated")}`);
            }
          } else {
            alert.error(`${t("alert.not-verified")}`);
          }
        } else {
          props.history.push("/404");
        }
      } else {
        alert.error(`${t("alert.error")}`);
      }
    } catch (error) {
      console.log(error);
      if (state.email.length && state.password.length) {
        alert.error(`${t("alert.authenticate-false")}`);
      } else {
        localStorage.removeItem(config.ACCESS_TOKEN_NAME);
        alert.error(`${t("alert.session-false")}`);
      }
    }

    setLoading(false);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        {/* <form> */}
        <h3>
          <p>{t("sign.signin")}</p>
        </h3>

        <div className="form-group">
          <label>{t("sign.email-address")}</label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            placeholder={t("sign.email-address-placeholder")}
            onChange={handleChange}
            value={state.email}
          />
        </div>

        <div className="form-group">
          <label>{t("sign.password")}</label>
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            placeholder={t("sign.password-placeholder")}
            onChange={handleChange}
            value={state.password}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: " center",
            alignItems: "center",
            width: "100%",
            borderRadius: ".25rem",
            backgroundColor: "#2fbcee",
            padding: ".600rem .75rem",
            marginTop: 25,
          }}
          onClick={handleSubmitClick}
        >
          {loading ? (
            <SmallLoader />
          ) : (
            <p style={{ color: "white", margin: 0 }}>{t("sign.signin")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
