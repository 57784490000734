import { TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CssTextField } from "../Utils/input.mui.styles";
import {
  FormGroup,
  SectionContainer,
  TitleSection,
} from "../Utils/Offer.styles";

const Customer = (props) => {
  const classes = CssTextField();
  const { t } = useTranslation();

  return (
    <SectionContainer className={classes.root}>
      <TitleSection>
        {t("offer.buyer")} n°{props.number}
      </TitleSection>
      <FormGroup small={true}>
        <TextField
          fullWidth
          id={props.customer}
          type="text"
          name="lastname"
          label={t("contact.form.lastname")}
          onChange={props.handleChange}
          value={props.state.lastname}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="text"
          name="firstname"
          label={t("contact.form.firstname")}
          onChange={props.handleChange}
          height={"calc(1.5em + 0.75rem + 2px)"}
          value={props.state.firstname}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="text"
          name="address"
          height={"calc(1.5em + 0.75rem + 2px)"}
          label={t("contact.form.address")}
          onChange={props.handleChange}
          value={props.state.address}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="text"
          name="postalcode"
          height={"calc(1.5em + 0.75rem + 2px)"}
          label={t("contact.form.postalcode")}
          onChange={props.handleChange}
          value={props.state.postalcode}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="text"
          name="city"
          height={"calc(1.5em + 0.75rem + 2px)"}
          label={t("contact.form.city")}
          onChange={props.handleChange}
          value={props.state.city}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type={"date"}
          height={"calc(1.5em + 0.75rem + 2px)"}
          name="birthDate"
          label={t("contact.form.birthDate")}
          onChange={props.handleChange}
          value={props.state.birthDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="text"
          height={"calc(1.5em + 0.75rem + 2px)"}
          name="birthPlace"
          label={t("contact.form.birthPlace")}
          onChange={props.handleChange}
          value={props.state.birthPlace}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="email"
          name="email"
          height={"calc(1.5em + 0.75rem + 2px)"}
          label={t("contact.form.email")}
          onChange={props.handleChange}
          value={props.state.email}
        />
      </FormGroup>
      <FormGroup>
        <TextField
          fullWidth
          id={props.customer}
          type="tel"
          height={"calc(1.5em + 0.75rem + 2px)"}
          name="phonemobile"
          label={t("contact.form.phonemobile")}
          onChange={props.handleChange}
          value={props.state.phonemobile}
        />
      </FormGroup>
    </SectionContainer>
  );
};

export default Customer;
