export const isOffers = async (data, reference) => {
  const find = data.offer.filter((offer) => {
    if (offer.reference === reference) {
      return true;
    } else {
      return false;
    }
  });
  return find;
};
export const isAgency = async (data, agenceId) => {
  const find = data.filter((item) => {
    if (item.agenceId === agenceId) {
      return true;
    } else {
      return false;
    }
  });
  return find;
};
