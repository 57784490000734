import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import $ from "jquery";

/**
 * Marker Google map
 */
const Marker = (props) => {
  const { text } = props;
  const markerRef = useRef(null);
  useEffect(() => {
    // Initialize all tooltips
    $(markerRef.current).tooltip({
      boundary: "window",
      placement: "top",
      trigger: "hover",
    });
    // $(markerRef.current).tooltip('show');
    return () => {
      $(".tooltip").remove();
    };
  }, []);
  return (
    <div
      className=""
      ref={markerRef}
      data-toggle="tooltip"
      data-placement="top"
      title={text}
    >
      <LocationOnIcon fontSize="large" style={{ color: "#34495e" }} />
    </div>
  );
};

Marker.propTypes = {
  /**
   * Marker's description
   */
  text: PropTypes.string,
};

export default Marker;
