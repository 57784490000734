import React from "react";
import Grid from "./Grid";
import UserFromAgencyWrapper from "./UserFromAgencyWrapper";

const UserFromAgency = (props) => {
  if (props.users.length === 0 || props.users === undefined) {
    return (
      <div style={{ padding: "2rem", height: "100%" }}>
        <p style={{ textTransform: "uppercase" }}>Aucun utilisateur</p>
      </div>
    );
  } else {
    return <Grid users={props.users} />;
  }
};

export default UserFromAgencyWrapper(UserFromAgency);
