import React from "react";
import { useTranslation } from "react-i18next";
import { choices } from "../../PrintPage/Utils/dataChoices";
import { Price, Honoraire, SpanPrice } from "./ModulesElements.styles";
const ModulePrice = (props) => {
  const { t } = useTranslation();
  const excludedValue = ["", undefined, null];

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <>
      <Price
        family={props.family}
        align={props.align}
        colorPrice={
          !excludedValue.includes(props.colorPrice) ? props.colorPrice : "white"
        }
        size={props.size}
        weight={props.weight}
      >
        {props.project.hideprice && t("modalprint.consult")}
        {!props.project.hideprice &&
          `${
            props.project.category === choices.category[2].id
              ? t("details.modalprint.startAt")
              : ""
          } ${getPrice(props.price)} ${
            props.project.currency ? props.project.currency : "€"
          }`}{" "}
        {props.project.category === choices.category[1].id &&
        !props.project.hideprice ? (
          <SpanPrice size={props.size}>
            {t("modalprint.chargespermonth")}
          </SpanPrice>
        ) : (
          ""
        )}
        {props.project.periodicity &&
          props.project.category === choices.category[2].id && (
            <SpanPrice
              className="font-weight-light text-center"
              style={{ fontSize: "20px" }}
            >
              {t(
                choices.periodicity.filter(
                  (item) => item.id === props.project.periodicity
                )[0].name
              )}
            </SpanPrice>
          )}
      </Price>
      {props.project.category === choices.category[0].id &&
      !props.project.hideprice ? (
        <Honoraire
          size={props.size}
          colorPrice={
            !excludedValue.includes(props.colorPrice)
              ? props.colorPrice
              : "white"
          }
        >
          {props.project.sharedCommission
            ? t("details.fees.feesShared")
            : props.project.commission
            ? "Honoraires TTC à la charge de l'acheteur"
            : "Honoraires TTC à la charge du vendeur"}
        </Honoraire>
      ) : null}
    </>
  );
};

export default ModulePrice;
