import React from "react";
import { useTranslate } from "react-admin";
import {
  ButtonContainer,
  ActionBtn,
} from "../../../../../../Utils/Styles/BillingInformation.styles";

const ActionContainer = (props) => {
  const translate = useTranslate();

  const isDisabled = () => {
    if (
      props.stateAddress.name !== "" &&
      props.stateAddress.address !== "" &&
      props.stateAddress.city !== "" &&
      props.stateAddress.postalcode !== "" &&
      props.stateAddress.country !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ButtonContainer>
      <ActionBtn right="true" onClick={() => props.handleClose()}>
        {translate("resources.fields.cancel")}
      </ActionBtn>
      <ActionBtn
        active="true"
        onClick={() => props.saveAddress()}
        disabled={isDisabled()}
      >
        {translate("resources.fields.save")}
      </ActionBtn>
    </ButtonContainer>
  );
};

export default ActionContainer;
