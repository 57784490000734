import React from "react";
import {
  Container,
  Icon,
  IconContainer,
  ItemContainer,
  ItemInnerContainer,
  Label,
  PoliceContainer,
} from "../Styles/fontManager.styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const FontManager = ({
  police,
  open,
  handleChangeFont,
  handleChangeSize,
  handleChangeWeight,
}) => {
  const classes = useStyles();

  return (
    <Container open={open}>
      <ItemContainer>
        <ItemInnerContainer width={"70%"}>
          <Label>Taille :</Label>
        </ItemInnerContainer>
        <ItemInnerContainer width={"30%"}>
          <IconContainer>
            <Icon as={AddCircleIcon} onClick={() => handleChangeSize("add")} />
          </IconContainer>
          <IconContainer>
            <Icon
              as={RemoveCircleIcon}
              onClick={() => handleChangeSize("remove")}
            />
          </IconContainer>
        </ItemInnerContainer>
      </ItemContainer>
      <ItemContainer>
        <ItemInnerContainer width={"70%"}>
          <Label>Grosseur :</Label>
        </ItemInnerContainer>
        <ItemInnerContainer width={"30%"}>
          <IconContainer>
            <Icon
              as={AddCircleIcon}
              onClick={() => handleChangeWeight("add")}
            />
          </IconContainer>
          <IconContainer>
            <Icon
              as={RemoveCircleIcon}
              onClick={() => handleChangeWeight("remove")}
            />
          </IconContainer>
        </ItemInnerContainer>
      </ItemContainer>
      <PoliceContainer>
        <ItemInnerContainer width={"100%"}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Police
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={police}
              onChange={handleChangeFont}
              label="Police"
            >
              <MenuItem value="">
                <em>Aucune</em>
              </MenuItem>
              <MenuItem value={"'Lato', sans-serif"}>Lato</MenuItem>
              <MenuItem value={"'Montserrat', sans-serif"}>Montserrat</MenuItem>
              <MenuItem value={"'Open Sans', sans-serif"}>Open sans</MenuItem>
              <MenuItem value={"'Poppins', sans-serif"}>Poppins</MenuItem>
              <MenuItem value={"'Oswald', sans-serif"}>Oswald</MenuItem>
              <MenuItem value={"'Playfair Display', serif"}>
                Playfair Display
              </MenuItem>
              <MenuItem value={"'Bellefair', serif"}>Bellefair</MenuItem>
              <MenuItem value={"'Pacifico', cursive"}>Pacifico</MenuItem>
              <MenuItem value={"'Pinyon Script', cursive"}>
                Pinyon Script
              </MenuItem>
            </Select>
          </FormControl>
        </ItemInnerContainer>
      </PoliceContainer>
    </Container>
  );
};

export default FontManager;
