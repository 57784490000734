import React from "react";
import { getProject } from "../../../../../../controllers/Projects/controller";
import {
  getUsersData,
  postNewUserInProject,
} from "../../../Utils/axiosRequest";

const ProjectSmallGridWrapper = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    changeUserInCharge = async (id, data) => {
      const sendUserData = await postNewUserInProject(id, data);
      return sendUserData;
    };

    async componentDidMount() {
      const data = await getProject(
        "/projects?%24limit=-1&agenceId=" + this.props.id,
        "projectList"
      );
      if (data && data.data && data.data.data && data.data.data.length) {
        this.setState({ data: data.data.data });
        const fetch = await getUsersData();
        const userFromAgence = fetch.data.filter((item) => {
          if (
            item.agenceId &&
            item.agenceId._id.toString() === this.props.id.toString()
          ) {
            return item;
          } else {
            return null;
          }
        });
        this.setState({ users: userFromAgence });
      }
    }

    render() {
      if (!this.state.data && !this.state.users) {
        return (
          <div style={{ padding: "2rem", height: "100%" }}>
            <p style={{ textTransform: "uppercase" }}>Aucun mandat</p>
          </div>
        );
      } else {
        return (
          <WrappedComponent
            {...this.props}
            data={this.state.data}
            id={this.props.id}
            users={this.state.users}
            changeUserInCharge={this.changeUserInCharge}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
};

export default ProjectSmallGridWrapper;
