import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../constants/constant";
import {
  deleteTemplateRequest,
  fetchAgencyData,
  fetchProjectData,
  registerTemplate,
  udpateTemplateRequest,
} from "../pages/Print/PrintPage/Utils/axiosRequest";
import { choices } from "./dataChoices";
import { useHistory } from "react-router-dom";
import { UserChangeContext, UserContext } from "./UserProvider";

const TemplatesStore = React.createContext();
TemplatesStore.displayName = "TemplatesStore";

export const useTemplatesStore = () => React.useContext(TemplatesStore);

export const TemplatesStoreProvider = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [project, setProject] = useState(null);
  const [agency, setAgency] = useState(false);
  const [step, setStep] = useState(1);
  const [mode, setMode] = useState("create");
  const [favoriteId, setFavoriteId] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [message, setMessage] = useState(null);
  const [orientation, setOrientation] = useState(false);
  const [format, setFormat] = useState(null);
  const [model, setModel] = useState(false);
  const [imageFirst, setImageFirst] = useState("false");
  const [imageSupp, setImageSupp] = useState([]);
  const [color, setColor] = useState({
    primary: "",
    secondary: "",
    desc: "",
    title: "",
    subTitle: "",
    title2: "",
    price: "",
  });
  const [colorSecondary, setColorSecondary] = useState("");
  const [colorDesc, setColorDesc] = useState("");
  const [colorTitle, setColorTitle] = useState("");
  const [colorTitle2, setColorTitle2] = useState("");
  const [colorSubTitle, setColorSubTitle] = useState("");
  const [colorPrice, setColorPrice] = useState("");
  const [desc, setDesc] = useState("");
  const [descFont, setDescFont] = useState({
    size: "",
    font: "",
    weight: "",
  });
  const [banner, setBanner] = useState("");
  const [bannerFont, setBannerFont] = useState({
    size: "",
    font: "",
    weight: "",
    color: "",
  });
  const [bannerSecond, setBannerSecond] = useState("");
  const [bannerFontSecond, setBannerFontSecond] = useState({
    size: "",
    font: "",
    weight: "",
    color: "",
  });
  const [categoryText, setCategoryText] = useState("");
  const [categoryFont, setCategoryFont] = useState({
    size: "",
    font: "",
    weight: "",
    color: "",
  });
  const [refText, setRefText] = useState("");
  const [refFont, setRefFont] = useState({
    size: "",
    font: "",
    weight: "",
    color: "",
  });
  const [isBanner, setIsBanner] = useState("false");
  const [isBannerSecond, setIsBannerSecond] = useState("false");
  const [titleText, setTitleText] = useState("");
  const [titleTextFont, setTitleTextFont] = useState({
    size: "",
    font: "",
    weight: "",
  });
  const [subTitleText, setSubTitleText] = useState("");
  const [subTitleTextFont, setSubTitleTextFont] = useState({
    size: "",
    font: "",
    weight: "",
  });
  const [titleText2, setTitleText2] = useState("");
  const [titleText2Font, setTitleText2Font] = useState({
    size: "",
    font: "",
    weight: "",
  });
  const [price, setPrice] = useState("");
  const [priceFont, setPriceFont] = useState({
    size: "",
    font: "",
    weight: "",
  });
  const [icons, setIcons] = useState([
    { name: "area", valeur: "", active: false },
    { name: "rooms", valeur: "", active: false },
    { name: "bedrooms", valeur: "", active: false },
    { name: "sdb", valeur: "", active: false },
    { name: "gardenArea", valeur: "", active: false },
  ]);
  const [areaFloor, setAreaFloor] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [qrcodeMobile, setQrcodeMobile] = useState("");
  const [qrcodeMode, setQrcodeMode] = useState("qrcode");
  const [titlePage, setTitlePage] = useState("");
  const [mirror, setMirror] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const [saturation, setSaturation] = useState(100);
  const { user } = useContext(UserContext);
  const { userChange } = useContext(UserChangeContext);
  const [selected, setSelected] = useState({
    user: user,
  });

  useEffect(() => {
    if (step === 1) {
      setTitlePage(t("modalprint.pageOne"));
    } else if (step === 2) {
      setTitlePage(t("modalprint.pageTwo"));
    } else if (step === 3) {
      setTitlePage(t("modalprint.pageThree"));
    } else if (step === 4) {
      setTitlePage(t("modalprint.pageFour"));
    }
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  }, [message, project, selected, step, t, userChange]);

  const handleStep = () => {
    setStep(step + 1);
  };

  const getData = async (id) => {
    setStep(1);
    setMode("create");
    setIsBannerSecond(false);
    setIsBanner(false);
    setOrientation(false);
    setModel(false);
    setLoading(true);
    setFavoriteId(null);
    setIsSaved(false);
    setMessage(null);
    setQrcodeMode("qrcode");
    setBrightness(100);
    setSaturation(100);
    setMirror(false);
    setAreaFloor("");
    setIcons([
      { name: "area", valeur: "", active: false },
      { name: "rooms", valeur: "", active: false },
      { name: "bedrooms", valeur: "", active: false },
      { name: "sdb", valeur: "", active: false },
      { name: "gardenArea", valeur: "", active: false },
    ]);
    setPriceFont({
      size: "",
      font: "",
      weight: "",
    });
    setTitleText2Font({
      size: "",
      font: "",
      weight: "",
    });
    setSubTitleTextFont({
      size: "",
      font: "",
      weight: "",
    });
    setTitleTextFont({
      size: "",
      font: "",
      weight: "",
    });
    setRefFont({
      size: "",
      font: "",
      weight: "",
      color: "",
    });
    setCategoryFont({
      size: "",
      font: "",
      weight: "",
      color: "",
    });
    setBannerFontSecond({
      size: "",
      font: "",
      weight: "",
      color: "",
    });
    setBannerFont({
      size: "",
      font: "",
      weight: "",
      color: "",
    });
    setDescFont({
      size: "",
      font: "",
      weight: "",
    });
    const response = await fetchProjectData(id);
    if (response && response.data) {
      setProject(response.data);
      setDesc(
        response.data.comments &&
          response.data.comments &&
          response.data.comments.length
          ? response.data.comments[0].comments.comment &&
            response.data.comments[0].comments.comment.length
            ? response.data.comments[0].comments.comment.substr(0, 450)
            : ""
          : ""
      );
      setTitleText2(response.data.metatitle);
      setCategoryText(
        t(
          choices.category.filter(
            (item) => item.id === response.data.category
          )[0].name
        )
      );
      let array = [...icons];
      if (response.data.area.total) {
        array[0] = {
          name: "area",
          valeur: `${response.data.area.total}`,
          active: true,
        };
      }
      if (response.data.rooms && response.data.rooms.length) {
        array[1] = {
          name: "rooms",
          valeur: `${response.data.rooms}`,
          active: true,
        };
      }
      if (response.data.bedrooms) {
        array[2] = {
          name: "bedrooms",
          valeur: `${response.data.bedrooms}`,
          active: true,
        };
      }
      if (response.data.sdb && response.data.sdb.length) {
        array[3] = {
          name: "sdb",
          valeur: `${response.data.sdb}`,
          active: true,
        };
      }
      if (response.data.gardenArea) {
        array[4] = {
          name: "gardenArea",
          valeur: `${response.data.gardenArea}`,
          active: true,
        };
      }
      setIcons([...array]);

      setRefText(response.data.reference);
      setTitleText(response.data.type);
      setSubTitleText(response.data.city);
      setPrice(response.data.price);
      if (userChange) {
        setSelected({ user: userChange });
      }
      if (
        selected.user.permissions !== "super_admin" &&
        selected.user.agenceId &&
        selected.user.agenceId !== response.data.agenceId
      ) {
        history.push("/admin");
      }

      const responseAgency = await fetchAgencyData(response.data.agenceId);
      setAgency(responseAgency.data);
      if (responseAgency.data.colorPrimary !== "") {
        setColor((prev) => ({
          ...prev,
          primary: responseAgency.data.colorPrimary || "",
        }));
      }
      if (responseAgency.data.colorSecondary !== "") {
        setColorSecondary(responseAgency.data.colorSecondary);
      }
      if (responseAgency.data.colorTitle !== "") {
        setColorTitle(responseAgency.data.colorTitle);
      }
      if (responseAgency.data.colorSubTitle !== "") {
        setColorSubTitle(responseAgency.data.colorSubTitle);
      }
      if (responseAgency.data.colorPrice !== "") {
        setColorPrice(responseAgency.data.colorPrice);
      }
      if (responseAgency.data.colorDesc !== "") {
        setColorDesc(responseAgency.data.colorDesc);
      }
      if (responseAgency.data.slug) {
        setQrcode(
          `${config.APP_BASE}/visite360/${responseAgency.data.slug}/${response.data.slug}`
        );
        setQrcodeMobile(
          `${config.APP_BASE}/${responseAgency.data.slug}/${response.data.reference}`
        );
      }

      setLoading(false);
    } else {
      setNoResult(true);
    }
  };

  const recordTemplate = async () => {
    const data = {
      model: model,
      orientation: orientation,
      format: format,
      banner,
      bannerFont,
      bannerSecond,
      bannerFontSecond,
      imageFirst: imageFirst,
      imageSupp: imageSupp,
      color,
      colorTitle2,
      colorSecondary: colorSecondary,
      colorDesc: colorDesc,
      colorTitle: colorTitle,
      colorSubtitle: colorSubTitle,
      colorPrice: colorPrice,
      desc: desc,
      descFont,
      categoryText,
      categoryFont,
      refText,
      isBanner,
      isBannerSecond,
      refFont,
      titleText: titleText,
      titleTextFont,
      subTitleText: subTitleText,
      subTitleTextFont,
      price: price,
      priceFont,
      mirror: mirror,
      brightness: brightness,
      saturation: saturation,
      qrcode: qrcode,
      icons,
      areaFloor,
      qrcodeMobile: qrcodeMobile,
      qrcodeMode: qrcodeMode,
      index: Math.random().toString(36).substr(2, 9),
    };
    setIsSaved(true);
    const response = await registerTemplate(project, data);
    if (response.status === 200) {
      setProject(response.data);
      setMessage(t("alert.createsuccess"));
    }
  };

  const udpateTemplate = async () => {
    const data = {
      model: model,
      orientation: orientation,
      format: format,
      banner,
      bannerFont,
      bannerSecond,
      bannerFontSecond,
      imageFirst: imageFirst,
      imageSupp: imageSupp,
      color: color,
      colorTitle2,
      colorSecondary: colorSecondary,
      colorDesc: colorDesc,
      colorTitle: colorTitle,
      colorSubtitle: colorSubTitle,
      colorPrice: colorPrice,
      desc: desc,
      descFont,
      categoryText,
      categoryFont,
      refText,
      isBanner,
      isBannerSecond,
      refFont,
      titleText: titleText,
      titleTextFont,
      subTitleText: subTitleText,
      subTitleTextFont,
      price: price,
      priceFont,
      mirror: mirror,
      brightness: brightness,
      saturation: saturation,
      qrcode: qrcode,
      icons,
      areaFloor,
      qrcodeMobile: qrcodeMobile,
      qrcodeMode: qrcodeMode,
      index: favoriteId,
    };
    setIsSaved(true);
    const response = await udpateTemplateRequest(project, data);
    if (response.status === 200) {
      setProject(response.data);
      setMessage(t("alert.changessaved"));
    }
  };

  const deleteTemplate = async (project, data) => {
    const response = await deleteTemplateRequest(project, data);
    if (response.status === 200) {
      setProject(response.data);
      setMessage(t("alert.changessaved"));
      if (response.data.templates && response.data.templates.length === 0) {
        setStep(1);
        setMode("create");
      }
    }
  };

  // SETFAVORITETOPRINT
  const setValueFromFavorite = async (item, setter, printLandscape) => {
    console.log(item, "item");
    setModel(item.model);
    setOrientation(item.orientation);
    setFormat(item.format);
    setBanner(item.banner);
    setBannerFont(item.bannerFont);
    setBannerSecond(item.bannerSecond);
    setBannerFontSecond(item.bannerFontSecond);
    setImageFirst(item.imageFirst);
    setImageSupp(item.imageSupp);
    setColor(item.color);
    setColorTitle2(item.colorTitle2);
    setColorSecondary(item.colorSecondary);
    setColorDesc(item.setColorDesc);
    setColorTitle(item.colorTitle);
    setColorSubTitle(item.colorSubTitle);
    setColorPrice(item.colorPrice);
    setDesc(item.desc);
    setDescFont(item.descFont);
    setCategoryText(item.categoryText);
    setCategoryFont(item.categoryFont);
    setRefText(item.refText);
    setIsBanner(item.isBanner);
    setIsBannerSecond(item.isBannerSecond);
    setRefFont(item.refFont);
    setTitleText(item.titleText);
    setTitleTextFont(item.titleTextFont);
    setSubTitleText(item.subTitleText);
    setSubTitleTextFont(item.subTitleTextFont);
    setPrice(item.price);
    setPriceFont(item.priceFont);
    setMirror(item.mirror);
    setBrightness(item.brightness);
    setSaturation(item.saturation);
    setQrcode(item.qrcode);
    setIcons(item.icons);
    setAreaFloor(item.areaFloor);
    setQrcodeMobile(item.qrcodeMobile);
    setQrcodeMode(item.qrcodeMode);
    setFavoriteId(item.index);
    setIsSaved(true);
  };

  return (
    <TemplatesStore.Provider
      value={{
        loading,
        noResult,
        project,
        setProject,
        agency,
        mode,
        setMode,
        favoriteId,
        setFavoriteId,
        isSaved,
        setIsSaved,
        message,
        setMessage,
        orientation,
        setOrientation,
        format,
        setFormat,
        model,
        setModel,
        step,
        setStep,
        imageFirst,
        setImageFirst,
        imageSupp,
        setImageSupp,
        color,
        setColor,
        colorSecondary,
        setColorSecondary,
        colorDesc,
        setColorDesc,
        colorTitle,
        setColorTitle,
        colorSubTitle,
        setColorSubTitle,
        colorPrice,
        setColorPrice,
        price,
        setPrice,
        desc,
        setDesc,
        titleText,
        setTitleText,
        subTitleText,
        setSubTitleText,
        qrcode,
        qrcodeMobile,
        setQrcodeMobile,
        setQrcode,
        qrcodeMode,
        setQrcodeMode,
        titlePage,
        mirror,
        setMirror,
        brightness,
        setBrightness,
        saturation,
        setSaturation,
        descFont,
        setDescFont,
        titleTextFont,
        setTitleTextFont,
        subTitleTextFont,
        setSubTitleTextFont,
        priceFont,
        setPriceFont,
        banner,
        setBanner,
        bannerFont,
        setBannerFont,
        bannerSecond,
        setBannerSecond,
        bannerFontSecond,
        setBannerFontSecond,
        isBanner,
        setIsBanner,
        isBannerSecond,
        setIsBannerSecond,
        titleText2,
        setTitleText2,
        titleText2Font,
        setTitleText2Font,
        colorTitle2,
        setColorTitle2,
        categoryText,
        setCategoryText,
        categoryFont,
        setCategoryFont,
        refText,
        setRefText,
        refFont,
        setRefFont,
        areaFloor,
        setAreaFloor,
        icons,
        setIcons,
        recordTemplate,
        udpateTemplate,
        deleteTemplate,
        setValueFromFavorite,
        handleStep,
        getData,
      }}
    >
      {children}
    </TemplatesStore.Provider>
  );
};
