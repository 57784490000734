import { config } from "../../../constants/constant";
import axios from "axios";

export const getOfferExist = async (email) => {
  const url = config.API_BASE + "/offers?email=" + email;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const postOfferData = async (
  data,
  code,
  projectId,
  agenceId,
  reference,
  dateCertif,
  lang
) => {
  const url = config.API_BASE + "/offers";
  const secondCustomer =
    data.secondCustomer.lastname !== "" ? data.secondCustomer : null;
  const params = {
    method: "POST",
    data: {
      lang: lang,
      email: data.customer.email,
      agenceId: agenceId,
      offer: [
        {
          projectId: projectId,
          reference: reference,
          dateCertif: dateCertif,
          date: new Date(),
          informations: {
            addressOffer: data.informations.addressOffer,
            visiteDate: data.informations.visiteDate,
            offerLetter: data.informations.offerLetter,
            offerTime: data.informations.offerTime.label,
            financialType:
              data.informations.financialType !== ""
                ? data.informations.financialType
                : null,
            todayDate: data.informations.todayDate,
            conditions:
              data.informations.conditions[1] !== ""
                ? {
                    1: data.informations.conditions[1],
                    2:
                      data.informations.conditions[2] !== ""
                        ? data.informations.conditions[2]
                        : null,
                    3:
                      data.informations.conditions[3] !== ""
                        ? data.informations.conditions[3]
                        : null,
                    4:
                      data.informations.conditions[4] !== ""
                        ? data.informations.conditions[4]
                        : null,
                  }
                : null,
          },
        },
      ],
      customer: { ...data.customer },
      secondCustomer:
        data.secondCustomer.lastname !== ""
          ? {
              ...secondCustomer,
            }
          : null,
      code: code,
    },
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const udpateOfferData = async (
  data,
  code,
  projectId,
  agenceId,
  reference,
  offer,
  dateCertif,
  lang
) => {
  const url = config.API_BASE + "/offers/" + offer._id;
  const secondCustomer =
    data.secondCustomer.lastname !== "" ? data.secondCustomer : null;
  const params = {
    method: "PATCH",
    data: {
      lang: lang,
      email: data.customer.email,
      agenceId: agenceId,
      offer: [
        ...offer.offer,
        {
          projectId: projectId,
          reference: reference,
          dateCertif: dateCertif,
          date: new Date(),
          informations: {
            addressOffer: data.informations.addressOffer,
            visiteDate: data.informations.visiteDate,
            offerLetter: data.informations.offerLetter,
            offerTime: data.informations.offerTime.label,
            financialType:
              data.informations.financialType !== ""
                ? data.informations.financialType
                : null,
            todayDate: data.informations.todayDate,
            conditions:
              data.informations.conditions[1] !== ""
                ? {
                    1: data.informations.conditions[1],
                    2:
                      data.informations.conditions[2] !== ""
                        ? data.informations.conditions[2]
                        : null,
                    3:
                      data.informations.conditions[3] !== ""
                        ? data.informations.conditions[3]
                        : null,
                    4:
                      data.informations.conditions[4] !== ""
                        ? data.informations.conditions[4]
                        : null,
                  }
                : null,
          },
        },
      ],
      customer: { ...data.customer },
      secondCustomer:
        data.secondCustomer.lastname !== ""
          ? {
              ...secondCustomer,
            }
          : null,
      code: code,
      codeVerify: false,
      template: "offer-code",
    },
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const patchOfferData = async (id, emailnotifications, userId, lang) => {
  const url = config.API_BASE + "/offers/" + id;
  const params = {
    method: "PATCH",
    data: {
      codeVerify: true,
      emailnotifications,
      userId,
      template: "offer-send",
      lang: lang,
    },
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
