import styled from "styled-components";
import { colorSecondary } from "../../../../GlobalStyles";

export const GlobalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 52vh;

  @media screen and (max-width: ${(props) => props.breakWidth + "px"}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContainerFormatA4 = styled.div`
  margin-top: 15px;
  margin-left: auto;
  margin-right: 40px;
  @media screen and (max-width: ${(props) => props.breakWidth + "px"}) {
    margin-left: unset;
    margin-right: unset;
  }
`;
export const ContainerFormatA3 = styled.div`
  margin-top: 15px;
  margin-right: auto;
  @media screen and (max-width: ${(props) => props.breakWidth + "px"}) {
    margin-right: unset;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ matchesXs }) => (matchesXs ? "column" : "initial")};
  justify-content: center;
  align-items: center;
  min-height: ${({ matchesXs }) => (matchesXs ? "initial" : "16rem")};
  margin-bottom: 35px;
`;
export const ContainerText = styled.div``;

export const TextFormat = styled.p`
  font-size: 18px;
  font-weight: 900;
  text-align: center;
`;
export const LandscapeContainerA4 = styled.div`
  margin: 15px;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 12px;
  background-color: ${({ isSelect, format }) =>
    isSelect === "landscape" && format === "A4" ? colorSecondary : "black"};
  width: auto;
`;
export const LandscapeContainerA3 = styled.div`
  margin: 15px;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 12px;
  background-color: ${({ isSelect, format }) =>
    isSelect === "landscape" && format === "A3" ? colorSecondary : "black"};
  width: auto;
`;

export const LandscapeImage = styled.img`
  width: auto;
  height: 108px;
  border-radius: 8px;
`;
export const LandscapeImageA3 = styled.img`
  width: auto;
  height: 150px;
  border-radius: 8px;
`;

export const PortraitContainerA4 = styled.div`
  margin: 15px;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 12px;
  background-color: ${({ isSelect, format }) =>
    isSelect === "portrait" && format === "A4" ? colorSecondary : "black"};
  height: auto;
`;
export const PortraitContainerA3 = styled.div`
  margin: 15px;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 12px;
  background-color: ${({ isSelect, format }) =>
    isSelect === "portrait" && format === "A3" ? colorSecondary : "black"};
  height: auto;
`;

export const PortraitImage = styled.img`
  width: 105px;
  height: 185px;
  border-radius: 10px;
`;

export const PortraitImageA3 = styled.img`
  width: 150px;
  height: 250px;
  border-radius: 10px;
`;
export const TextShop = styled.p`
  margin-bottom: ${(props) => props.marginBottom};
  color: ${(props) => (props.green ? colorSecondary : "black")};
`;

export const Link = styled.a`
  && {
    color: ${colorSecondary};
    text-decoration: none;
  }
`;
