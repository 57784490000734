/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  ActionContainer,
  FormContainer,
  FormInner,
  TitleForm,
  Button,
} from "./Utils/Offer.styles";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import { validateCustomer, validateInformations } from "./Utils/Validator";
import StepThree from "./Steps/StepThree";
import StepFour from "./Steps/StepFour";
import {
  getOfferExist,
  patchOfferData,
  postOfferData,
  udpateOfferData,
} from "./Utils/axiosRequests";
import StepFive from "./Steps/StepFive";
import { useAlert } from "react-alert";
import StepSix from "./Steps/StepSix";
import { isAgency, isOffers } from "./Utils/Functions";

const Offer = (props) => {
  const alert = useAlert();
  const { t, i18n } = useTranslation();
  const [showSecondCustomer, setShowSecondCustomer] = useState(false);
  const [theDateCertif, setTheDateCertif] = useState("");
  const [step, setStep] = useState(1);
  const [code, setCode] = useState("");
  const [id, setId] = useState("");
  const [state, setState] = useState({
    customer: {
      firstname: "",
      lastname: "",
      address: "",
      postalcode: "",
      city: "",
      birthDate: "",
      birthPlace: "",
      email: "",
      phonemobile: "",
    },
    secondCustomer: {
      firstname: "",
      lastname: "",
      address: "",
      postalcode: "",
      city: "",
      birthDate: "",
      birthPlace: "",
      email: "",
      phonemobile: "",
    },
    informations: {
      addressOffer: "",
      visiteDate: "",
      offerLetter: "",
      conditions: {
        1: "",
        2: "",
        3: "",
        4: "",
      },
      offerTime: "",
      financialType: "",
      todayDate: "",
      code: "",
    },
  });

  useEffect(() => {
    if (state.informations.todayDate === "") {
      const event = new Date();

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const todayDate = event.toLocaleDateString("fr-FR", options);
      const dataToSend = {
        target: {
          value: todayDate,
          id: "informations",
          name: "todayDate",
        },
      };
      handleChange(dataToSend);
    }
  }, [state]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (event.target.id === "customer") {
      setState((prevState) => ({
        ...prevState,
        customer: { ...prevState.customer, [event.target.name]: value },
      }));
    } else if (event.target.id === "secondCustomer") {
      setState((prevState) => ({
        ...prevState,
        secondCustomer: {
          ...prevState.secondCustomer,
          [event.target.name]: value,
        },
      }));
    } else if (event.target.id === "informations") {
      setState((prevState) => ({
        ...prevState,
        informations: {
          ...prevState.informations,
          [event.target.name]: value,
        },
      }));
    } else if (event.target.id === "conditions") {
      setState((prevState) => ({
        ...prevState,
        informations: {
          ...prevState.informations,
          conditions: {
            ...prevState.informations.conditions,
            [event.target.name]: value,
          },
        },
      }));
    }
  };

  const verifyCode = async () => {
    if (state.informations.code === code) {
      const patchData = await patchOfferData(
        id,
        props.agency.emailnotifications,
        props.infoProject.userId,
        i18n.language
      );
      if (patchData.status === 200) {
        setStep(step + 1);
      }
    } else {
      alert.error("Ce code n'est pas valable");
    }
  };

  const dateCertif = () => {
    const event = new Date();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const todayDate = event.toLocaleTimeString("fr-FR");

    return todayDate;
  };

  const sendData = async () => {
    const codeToSend = Math.floor(1000 + Math.random() * 9000);
    const checkIfExist = await getOfferExist(state.customer.email);
    if (checkIfExist.total > 0) {
      const agencyExist = await isAgency(checkIfExist.data, props.agency._id);
      if (agencyExist.length) {
        const leadsExist = await isOffers(
          agencyExist[0],
          props.infoProject.reference
        );
        if (leadsExist.length) {
          alert.show(`${t("alert.contact-alreadysent")}`);
        } else {
          const awaitData = await udpateOfferData(
            state,
            codeToSend,
            props.infoProject._id,
            props.agency._id,
            props.infoProject.reference,
            agencyExist[0],
            dateCertif(),
            i18n.language
          );
          if (awaitData.status === 200) {
            setId(awaitData.data._id);
            setCode(awaitData.data.code);
            setTheDateCertif(dateCertif());
            setStep(step + 1);
          } else {
            alert.error(`${t("alert.error")}`);
          }
        }
      } else {
        const awaitData = await postOfferData(
          state,
          codeToSend,
          props.infoProject._id,
          props.agency._id,
          props.infoProject.reference,
          dateCertif(),
          i18n.language
        );
        if (awaitData.status === 201) {
          setId(awaitData.data._id);
          setCode(awaitData.data.code);
          setTheDateCertif(dateCertif());
          setStep(step + 1);
        } else {
          alert.error(`${t("alert.error")}`);
        }
      }
    } else {
      const awaitData = await postOfferData(
        state,
        codeToSend,
        props.infoProject._id,
        props.agency._id,
        props.infoProject.reference,
        dateCertif(),
        i18n.language
      );
      if (awaitData.status === 201) {
        setId(awaitData.data._id);
        setCode(awaitData.data.code);
        setTheDateCertif(dateCertif());
        setStep(step + 1);
      } else {
        alert.error(`${t("alert.error")}`);
      }
    }
  };

  const actionStep = () => {
    setStep(step + 1);
  };

  return (
    <FormContainer>
      <FormInner
        max={step >= 3 && step !== 4 ? (step === 6 ? "40vw" : "60vw") : "40vw"}
        margin={"12rem"}
      >
        {step === 1 && (
          <StepOne
            handleChange={handleChange}
            showSecondCustomer={showSecondCustomer}
            setShowSecondCustomer={setShowSecondCustomer}
            state={state}
          />
        )}
        {step === 2 && (
          <StepTwo
            handleChange={handleChange}
            state={state}
            setState={setState}
          />
        )}
        {step === 3 && (
          <StepThree
            state={state}
            infoProject={props.infoProject}
            agency={props.agency}
            step={step}
          />
        )}
        {step === 4 && <StepFour state={state} handleChange={handleChange} />}

        {step === 5 && (
          <StepFive
            state={state}
            infoProject={props.infoProject}
            agency={props.agency}
            step={step}
            theDateCertif={theDateCertif}
          />
        )}
        {step === 6 && <StepSix />}
        <ActionContainer>
          {step !== 6 && (
            <Button
              disabled={step === 1 ? true : step > 3 ? true : false}
              onClick={() => setStep(step - 1)}
              typeOfButton={false}
            >
              {t("offer.return")}
            </Button>
          )}
          {step !== 6 && (
            <Button
              disabled={
                step === 1
                  ? !validateCustomer(state, showSecondCustomer)
                  : step === 2
                  ? !validateInformations(state.informations)
                  : false
              }
              onClick={
                step === 3
                  ? () => sendData()
                  : step === 4
                  ? () => verifyCode()
                  : () => actionStep()
              }
              typeOfButton={true}
            >
              {step <= 2
                ? t("offer.next")
                : step === 3
                ? t("offer.electronicsign")
                : step === 4
                ? t("offer.validate")
                : t("offer.send")}
            </Button>
          )}
        </ActionContainer>
      </FormInner>
    </FormContainer>
  );
};

Offer.propTypes = {
  infoProject: PropTypes.object,
  agency: PropTypes.object,
};

export default Offer;
