import styled from "styled-components";

export const GlobalContainer = styled.div`
  width: 210mm;
  height: 297mm;
  padding: 5mm;
  margin: 0 auto;
  position: relative;
  font-size: 10pt;
  zoom: 0.6;
`;

export const InformationsContainer = styled.div`
  display: flex;
`;
export const InnerContainer = styled.div`
  display: ${({ flex }) => (flex ? "flex" : "block")};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => props.margin};
  width: ${(props) => props.width};
`;
export const SignatureContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 15mm 10mm 0;
  width: ${(props) => props.width};
`;
export const Text = styled.h1`
  font-size: 1em;
  font-family: sans-serif;
  font-weight: ${(props) => (props.weight ? 600 : 400)};
  text-align: ${({ align }) => (align ? "right" : "inherit")};
  max-width: ${({ maxWidth }) => (maxWidth ? "120px" : "unset")};
`;
export const TextCertif = styled.h1`
  font-size: 1em;
  font-family: sans-serif;
  font-weight: ${(props) => (props.weight ? 600 : 400)};
  text-align: center;
  max-width: ${({ maxWidth }) => (maxWidth ? "120px" : "unset")};
`;
export const Signature = styled.h1`
  font-size: 1em;
  font-family: "Bad Script", cursive;
  font-weight: ${(props) => (props.weight ? 600 : 400)};
`;
export const ObjectContainer = styled.div`
  padding-left: 10mm;
`;
export const CheckedIcon = styled.svg`
  && {
    color: ${(props) => props.mycolor};
  }
`;
export const LogoContainer = styled.div`
  position: absolute;
  bottom: 20mm;
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-left: 8mm;
`;
export const Logo = styled.img`
  width: 90px;
  height: auto;
  margin: 0 auto 0.3rem;
`;

export const StepThreeElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 520px) {
    align-items: flex-start;
  }
`;
