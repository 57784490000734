import React from "react";

const No360View = ({ infoProject }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
    >
      {infoProject.images && infoProject.images.length ? (
        <img
          style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          src={infoProject.images[0].src}
          alt="background"
        />
      ) : null}
    </div>
  );
};

export default No360View;
