import styled from "styled-components";

export const PanoContainer = styled.div`
  && {
    position: relative;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    flex-grow: 1;
    min-width: 0;
    background: #000000cf;
    overflow: hidden;
    transition: all 0.3 ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    & canvas {
      min-height: 100vh;
      transition: all 0.2s ease-in-out;
    }
  }
`;

export const ControlsButtonContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.open ? "-65px" : "65px")};
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  justify-content: space-around;
  min-width: ${(props) => props.minWidth};
  background-color: #00000069;
  padding: 0.3rem 0.6rem;
  border-radius: 50px;
  transition-delay: 100ms;
  transition: bottom 500ms ease-in-out;
  overflow: hidden;
`;

export const ControlsButtonSvg = styled.svg`
  && {
    color: white;
    cursor: pointer;
  }
`;
