const Language = [
  { id: "fr-FR", code: "fr-FR", shortcode: "fr", name: "Français" },
  { id: "en-US", code: "en-US", shortcode: "us", name: "English" },
  { id: "it-IT", code: "it-IT", shortcode: "it", name: "Italian" },
  { id: "es-ES", code: "es-ES", shortcode: "es", name: "Spanish" },
  { id: "pt-PT", code: "pt-PT", shortcode: "pt", name: "Portuguese" },
  { id: "de-DE", code: "de-DE", shortcode: "de", name: "German" },
  { id: "nl-NL", code: "nl-NL", shortcode: "nl", name: "Dutch" },
  { id: "notaire", code: "notaire", shortcode: "notaire", name: "Notaire" },
];
export default Language;
