import { Popover } from "@material-ui/core";
import React from "react";
import ColorChoice from "../ColorChoice";
import { ColorCube, TitleSection } from "../Styles/StepFour.styles";
import { ColorElement, EmptyDiv } from "../Styles/ColorChoice.styles";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import { useTranslation } from "react-i18next";

const ColorPrimaryComponent = () => {
  const { t } = useTranslation();
  const { setColor, color } = useTemplatesStore();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangeColor = (color) => {
    setColor((prev) => ({ ...prev, primary: color.hex }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <ColorElement>
      <EmptyDiv />
      <TitleSection>{t("modalprint.colorprimary").toUpperCase()}</TitleSection>
      <ColorCube color={color.primary} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice
          color={color.primary}
          handleChangeColor={handleChangeColor}
        />
      </Popover>
    </ColorElement>
  );
};

export default ColorPrimaryComponent;
