/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { Button } from "@material-ui/core";
import { update360Projects } from "../../../Utils/axiosRequest";

const Links360 = (props) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    const response = await update360Projects(props.record.id);
  };

  return (
    <div>
      <Button onClick={() => handleDelete()}>
        {loading ? "Veuillez patientez" : "Supprimer la visite 360"}
      </Button>
    </div>
  );
};

export default Links360;
