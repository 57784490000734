import React from "react";
import BersoLandscape from "./Landscape/BersoLandscape";

const index = React.forwardRef(
  ({ isMini, isPreview, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <BersoLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
