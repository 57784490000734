export const validateCustomer = (state, isSecond) => {
  if (
    !state.customer.lastname.length ||
    !state.customer.firstname.length ||
    !state.customer.address.length ||
    !state.customer.postalcode.length ||
    !state.customer.city.length ||
    !state.customer.birthDate.length ||
    !state.customer.birthPlace.length ||
    !state.customer.email.length ||
    !state.customer.phonemobile.length
  ) {
    return false;
  } else {
    if (!isSecond) return true;
    else {
      if (
        !state.secondCustomer.lastname.length ||
        !state.secondCustomer.firstname.length ||
        !state.secondCustomer.address.length ||
        !state.secondCustomer.postalcode.length ||
        !state.secondCustomer.city.length ||
        !state.secondCustomer.birthDate.length ||
        !state.secondCustomer.birthPlace.length ||
        !state.secondCustomer.email.length ||
        !state.secondCustomer.phonemobile.length
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
};

export const validateInformations = (state) => {
  if (
    !state.visiteDate.length ||
    !state.offerLetter.length ||
    state.offerTime === ""
  ) {
    return false;
  } else {
    return true;
  }
};
