import styled from "styled-components";

export const Container = styled.div`
  && {
    width: 297mm;
    height: 420mm;
    background-color: ${(props) => props.colorPrimary};
    display: flex;
    flex-direction: column;
    position: relative;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
  }
`;

export const ContainerImg = styled.div`
  && {
    width: 100%;
    height: 592.94pt;
    position: relative;
    object-fit: cover;
  }
`;

export const ImgCover = styled.img`
  && {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
  }
`;
export const BodyContainer = styled.div`
  height: 636.81pt;
  width: 761.34pt;
  background-color: white;
  position: absolute;
  top: 528pt;
  left: 43pt;
  display: flex;
`;

export const BodyContainerLeft = styled.div`
  width: 330pt;
  padding-top: 44pt;
`;
export const BodyContainerRight = styled.div`
  width: 430pt;
  padding-top: 44pt;
  padding-left: 20pt;
`;

export const ExcluContainer = styled.div`
  && {
    z-index: 10;
    padding: 0.5rem;
    width: 204pt;
    background-color: ${(props) => props.colorPrimary};
    position: absolute;
    top: 40pt;
    left: 0pt;
  }
`;
export const Exclu = styled.p`
  && {
    margin-bottom: 0;
    text-align: center;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
    color: ${(props) => (props.color !== "" ? props.color : "black")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;
export const Category = styled.p`
  font-size: 30pt;
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
  text-align: center;
`;
export const EnergyContainer = styled.div`
  width: 100%;
  height: 496pt;
  overflow: hidden;
`;
export const InfoType = styled.p`
  color: ${(props) => props.colorTitle};
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
export const InfoCity = styled.p`
  color: ${(props) => props.colorSubTitle};
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
export const DescContainer = styled.div`
  margin-top: 30pt;
  padding-right: 30pt;
`;
export const Description = styled.p`
  && {
    line-height: 115%;
    color: ${(props) => props.colorDesc};
    text-align: left;
    white-space: pre-wrap;
    max-height: 157pt;
    overflow: hidden;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;
export const ContainerPrice = styled.div`
  && {
    width: 100%;
    margin-top: 35pt;
  }
`;
export const ContainerDetails = styled.div`
  padding-top: 20pt;
  width: 100%;
`;
export const QrcodeContainer = styled.div`
  width: 120pt;
  height: 120pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15pt;
  right: 15pt;
`;
export const LogoContainer = styled.div`
  width: 167pt;
  position: absolute;
  right: 15pt;
  top: 20pt;
  z-index: 10;
`;
export const LogoAgence = styled.img`
  width: 100%;
`;
export const RefText = styled.p`
  margin: 0;
  text-align: center;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
  color: ${(props) => (props.color !== "" ? props.color : "black")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
