// import { colorTertiary } from "../../../GlobalStyles";
import { choices } from "../Utils/dataChoices";

export const getStateColor = (record) => {
  if (
    record === choices.state[0].id ||
    record === choices.state[3].id ||
    record === choices.state[1].id
  )
    return "rgb(57, 192, 186)";
  // else if (record === choices.state[1].id) return colorTertiary;
  else return "#CC0000";
};

export const getButtonColor = (record) => {
  if (
    record === choices.state[0].id ||
    record === choices.state[1].id ||
    record === choices.state[3].id
  )
    return "true";
  else return "false";
};
export const getUserIncluded = (id, category) => {
  if (category === "users") {
    switch (id) {
      case "print":
        return 1;
      case "data":
        return 1;
      case "display":
        return 1;
      case "agent":
        return 1;
      case "agence":
        return 3;
      case "business":
        return 10;
      case "teams":
        return 15;
      case "leader":
        return 25;
      default:
        return 0;
    }
  } else if (category === "screen") {
    return 1;
  } else {
    switch (id) {
      case "print":
        return 100;
      case "display":
        return 100;
      case "agent":
        return 25;
      case "agence":
        return 75;
      case "business":
        return 150;
      case "teams":
        return 250;
      case "leader":
        return 500;
      default:
        return 0;
    }
  }
};
export const getAboPrice = (id) => {
  switch (id) {
    case "print":
      return 19;
    case "data":
      return 119;
    case "display":
      return 49;
    case "agent":
      return 49;
    case "agence":
      return 59;
    case "business":
      return 79;
    case "team":
      return 129;
    case "leader":
      return 299;
    default:
      return 0;
  }
};
