import { Button, Divider } from "@material-ui/core";
import styled from "styled-components";
import { colorPrimary, colorTertiary } from "../../../../../GlobalStyles";

export const ItemManagerContainer = styled.div`
  padding: 1rem;
  margin-bottom: 10px;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Title = styled.p`
  margin-bottom: 0;
  font-size: 1.4rem;
  text-align: center;
  color: #34495ed9;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const ItemContainer = styled.div`
  position: relative;
  border: 1px solid ${colorPrimary};
  padding: 1.5rem;
  border-radius: 10px;
`;

export const LabelContainer = styled.div``;

export const TitleNumberOfUser = styled.p`
  margin-bottom: ${(props) => (props.smallBottom ? "0rem" : "0.7rem")};
  font-size: ${(props) => (props.size ? "1.1rem" : "0.9rem")};
  margin-left: 5px;
  color: #34495ed9;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  text-align: ${(props) => (props.align ? "center" : "left")};
`;

export const NumberValueContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => (props.width ? "100%" : "50%")};
`;
export const ValueContainer = styled.div`
  position: relative;
  margin-top: ${(props) => (props.margin ? "21px" : "0")};
  padding: 0.5rem;
  min-height: 66px;
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.column ? "column" : "initial")};
  align-items: center;
  background-color: ${(props) =>
    props.background === "true" ? colorPrimary : "transparent"};

  border: solid 1px ${colorPrimary};
  border-radius: 9px;
  cursor: ${(props) => (props.cursor ? "pointer" : "initial")};
`;
export const ValueText = styled.span`
  font-size: ${(props) => (props.small ? "11px" : "1rem")};
  color: ${(props) => (props.mycolor === "true" ? "white" : "#34495ed9")};
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  margin-right: ${(props) => (props.margin ? "15px" : "0")};
`;
export const ValueTextSmall = styled.span`
  font-size: 0.8rem;
  color: #34495ed9;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
`;

export const AddRemoveUserSuppContainer = styled.div`
  right: 15px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AddRemoveIcon = styled.svg`
  && {
    color: ${colorPrimary};
    transform: ${(props) => (props.rotate ? "rotate(180deg)" : "initial")};
    cursor: pointer;
  }
`;

export const BillingContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const BillingValueContainer = styled.div`
  position: relative;
  margin-top: ${(props) => (props.margin ? "21px" : "0")};
  padding: 1rem 0.9rem;
  min-height: 66px;
  background-color: ${(props) =>
    props.background === "true" ? colorPrimary : "transparent"};
  border: solid 1px ${colorPrimary};
  border-radius: 9px;
`;

export const TitleBilling = styled.p`
  margin-bottom: 0.7rem;
  font-size: 0.9rem;
  margin-left: 5px;
  color: #34495ed9;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
`;

export const BillingElement = styled.div`
  position: relative;
  min-height: ${(props) => (props.minheight ? props.minheight : "initial")};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "0")};
  border-radius: 4px;
  margin-bottom: ${(props) => (props.bottom ? "1rem" : "0")};
  background-color: ${(props) =>
    props.background ? colorPrimary + "61" : "transparent"};
`;

export const TitleElement = styled.p`
  font-size: 0.8rem;
  margin-left: 5px;
  color: #6b7a88;
  margin-bottom: 0.5rem;
  position: ${(props) => (props.absolute ? "absolute" : "initial")};
  font-weight: ${(props) => (props.weight ? "900" : "500")};
  right: ${(props) => (props.absolute ? "100px" : "0")};
  top: ${(props) => (props.top ? props.top : "initial")};
  font-family: "Open Sans", sans-serif;
`;
export const PriceElement = styled.p`
  position: absolute;
  margin-bottom: 0.5rem;
  right: 15px;
  top: ${(props) => (props.top ? props.top : "initial")};

  font-size: 0.9rem;
  color: #6b7a88;
  font-weight: ${(props) => (props.weight ? "900" : "500")};
  font-family: "Open Sans", sans-serif;
`;

export const Hr = styled(Divider)`
  && {
    margin-left: 6px;
    margin-right: 0;
    background-color: rgb(0 0 0 / 13%);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-end")};
  padding: 1rem;
`;

export const ActionBtn = styled(Button)`
  && {
    background-color: ${(props) =>
      props.active === "true" ? colorTertiary : "rgba(0, 0, 0, 0.04)"};
    color: ${(props) =>
      props.active === "true"
        ? "white"
        : props.mycolor === "red"
        ? "red"
        : colorPrimary};
    border-radius: 9px;
    min-width: 85px;
    padding: 8px 16px;
    margin-right: ${(props) => (props.right ? "10px" : "0")};

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;
