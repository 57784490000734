import React, { useContext } from "react";
import { Resource } from "react-admin";
import {
  UserCreate,
  UserEdit,
  UserList,
} from "../../Components/Administration/User";
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
} from "../../Components/Administration/Project";
import {
  TemplateCreate,
  TemplateEdit,
  TemplateList,
} from "../../Components/Administration/Template";
import {
  AgencyCreate,
  AgencyEdit,
  AgencyList,
} from "../../Components/Administration/Agency";
import {
  ContactEdit,
  ContactList,
} from "../../Components/Administration/Contact";
import { OfferEdit, OfferList } from "../../Components/Administration/Offer";
import UserIcon from "@material-ui/icons/Group";
import FolderIcon from "@material-ui/icons/Folder";
import LayersIcon from "@material-ui/icons/Layers";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ContactsIcon from "@material-ui/icons/Contacts";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "../../Components/Administration/Dashboard";
import Data from "../../Components/Administration/Data";
import BarChartIcon from "@material-ui/icons/BarChart";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { CombineContext } from "../../store/UserProvider";

const Resources = (user) => {
  const data = useContext(CombineContext);

  let role = ["super_admin", "admin", "manager"];

  const optionsName = (label) =>
    user !== null && user.permissions && user.permissions.includes("admin")
      ? { label: `resources.${label}.menuname_admin` }
      : { label: `resources.${label}.menuname` };

  const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
      let x = a[key];
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  let resourcesDefault = [
    <Resource
      name="home"
      key="resource-AA"
      options={optionsName("dashboard")}
      list={Dashboard}
      icon={DashboardIcon}
    />,
    <Resource
      name="users"
      key="resource-users"
      options={optionsName("users")}
      icon={UserIcon}
      edit={UserEdit}
      list={UserList}
      create={
        user !== null && user.permissions && role.includes(user.permissions)
          ? UserCreate
          : null
      }
    />,
    <Resource
      name="agencies"
      key="resource-agencies"
      options={optionsName("agencies")}
      list={AgencyList}
      icon={BusinessCenterIcon}
      edit={AgencyEdit}
      create={
        user !== null && user.permissions && user.permissions.includes("admin")
          ? AgencyCreate
          : null
      }
    />,
  ];

  let resourcesPrint = [
    <Resource
      name="projects"
      key="resource-AB"
      options={optionsName("projects")}
      list={ProjectList}
      icon={FolderIcon}
      edit={ProjectEdit}
      create={ProjectCreate}
    />,
  ];

  const resourcesAdmin = [
    <Resource
      name="templates"
      key="resource-templates"
      options={optionsName("templates")}
      list={TemplateList}
      icon={LayersIcon}
      edit={TemplateEdit}
      create={TemplateCreate}
    />,
  ];
  const resourcesAds = [
    <Resource
      name="offers"
      key="resource-offers"
      options={optionsName("offers")}
      list={OfferList}
      icon={CreditCardIcon}
      edit={OfferEdit}
    />,
    <Resource
      name="contacts"
      key="resource-contacts"
      options={optionsName("contacts")}
      list={ContactList}
      icon={ContactsIcon}
      edit={ContactEdit}
    />,
  ];
  const resourceData = [
    <Resource
      name="agencies"
      key="resource-agencies"
      options={optionsName("agencies")}
      list={AgencyList}
      icon={BusinessCenterIcon}
      edit={AgencyEdit}
      create={
        user !== null && user.permissions && user.permissions.includes("admin")
          ? AgencyCreate
          : null
      }
    />,
    <Resource
      name="data"
      key="resource-data"
      options={optionsName("data")}
      list={Data}
      icon={BarChartIcon}
    />,
  ];
  if (user !== null && user.permissions && user.permissions.includes("admin")) {
    resourcesDefault = resourcesDefault.concat(resourcesAdmin);
  } else if (user != null && data.typeValue.type === "360") {
    resourcesDefault = resourcesDefault.concat(resourcesAds, resourcesPrint);
  } else if (user !== null && data.typeValue.type === "print") {
    resourcesDefault = resourcesDefault.concat(resourcesPrint);
  } else if (user !== null && data.typeValue.type === "data") {
    resourcesDefault = resourceData;
  }

  return sortByKey(resourcesDefault, "key");
};

export default Resources;
