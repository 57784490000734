/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  TextInput,
  useTranslate,
  SelectInput,
  ImageInput,
  ImageField,
  PasswordInput,
  required,
  useGetIdentity,
} from "react-admin";
import { Autocomplete } from "@material-ui/lab";

import {
  Box,
  CardContent,
  Divider,
  Typography,
  Fade,
  TextField,
} from "@material-ui/core";
import Language from "../../../enums/Language";

import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { CombineContext } from "../../../store/UserProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const ProfileForm = (props) => {
  const translate = useTranslate();
  const { agency, handleChangeAgency } = props;
  const [value, setValue] = useState(null);
  const classes = useStyles();
  const [agencySupp, setAgencySupp] = useState([]);
  const data = useContext(CombineContext);
  const [admin, setAdmin] = useState(false);
  const { identity, loading: identityLoading } = useGetIdentity();

  useEffect(() => {
    const role = ["super_admin", "admin"];
    let user;
    if (data.valueChange.userChange) {
      user = data.valueChange.userChange;
    } else {
      user = data.value.user;
    }
    if (role.includes(user.permissions)) {
      setAdmin(true);
    }
    // if (agencySupp) {
    //   console.log(agencySupp);
    // }
    if (props.record.agenceId) {
      const myAgence = agency.filter(
        (item) => item._id === props.record.agenceId
      );
      setValue(myAgence[0]);
    }
  }, [
    props.record,
    agency,
    handleChangeAgency,
    agencySupp,
    data.valueChange.userChange,
    data.value.user,
  ]);

  if (identityLoading) return <>Loading...</>;

  const handleChange = (event, value) => {
    setAgencySupp(value);
  };

  const defaultProps = {
    options: agency.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    }),
    getOptionLabel: (option) => {
      return `${option.name}`;
    },
  };

  const change = (event, value) => {
    handleChangeAgency(event, value);
    setValue(value);
  };

  return (
    <Fade in={true} timeout={300}>
      <CardContent className="Form-Project">
        <Box>
          {/* INFORMATION Part */}
          <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px" mt="20px">
            <Typography variant="h6" gutterBottom>
              {translate("resources.users.title.profile")}
            </Typography>

            <Divider light style={{ marginBottom: "10px" }} />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="firstname" resource="users" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="lastname" resource="users" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="email"
                  resource="users"
                  inputProps={{
                    readOnly: true,
                    onFocus: (event) => (event.target.readOnly = false),
                  }}
                  fullWidth
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="phonehome" resource="users" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="phonemobile" resource="users" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="whatsapp" resource="users" fullWidth />
              </Box>
            </Box>
            {props.record.id && (
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    source="lang"
                    resource="users"
                    choices={Language}
                    translateChoice={false}
                  />
                </Box>
              </Box>
            )}

            <Box display={{ xs: "block", sm: "flex" }}>
              <ImageInput
                source="images"
                label="avatar"
                resource="users"
                accept="image/*"
                style={{ marginTop: "20px" }}
              >
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
            {admin && (
              <Box display={{ xs: "block", sm: "flex" }} mb={5}>
                <Autocomplete
                  {...defaultProps}
                  onChange={(event, newValue) => {
                    change(event, newValue);
                  }}
                  value={value}
                  style={{ minWidth: "347px" }}
                  disableClearable={false}
                  noOptionsText={translate("resources.fields.noresult")}
                  fullWidth={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("resources.agencies.tabname")}
                      margin="normal"
                      variant="filled"
                    />
                  )}
                />
              </Box>
            )}
            {props.record.id && (
              <Box display={{ xs: "block", sm: "flex" }}>
                <PasswordInput
                  source="password"
                  label={translate("resources.users.fields.newpassword")}
                  style={{ minWidth: "347px" }}
                  resource="users"
                  inputProps={{
                    readOnly: true,
                    onFocus: (event) => (event.target.readOnly = false),
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>{" "}
        {/* end */}
      </CardContent>
    </Fade>
  );
};

export default ProfileForm;
