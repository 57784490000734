import React, { useState } from "react";
import { Fade, useMediaQuery } from "@material-ui/core";

// IMPORT TEMPLATE INDEX
import LondresTemplate from "../../TemplatePrint/Londres";
import ParisTemplate from "../../TemplatePrint/Paris";
import BarceloneTemplate from "../../TemplatePrint/Barcelone";
import BerlinTemplate from "../../TemplatePrint/Berlin";
import CuppertinoTemplate from "../../TemplatePrint/Cuppertino";
import SingapourTemplate from "../../TemplatePrint/Singapour";
import PersoTemplate from "../../TemplatePrint/Perso/PersoLandscape/PersoLandscape";
import PrintIcon from "@material-ui/icons/Print";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { ModeButton } from "../Styles/ModalPrint.styles";
import { useTranslation } from "react-i18next";
import BersotTemplate from "../../TemplatePrint/BersoImmo";
import GosselinTemplate from "../../TemplatePrint/Gosselin";
import LoftAndHouseTemplate from "../../TemplatePrint/LoftAndHouse";
import IphTemplate from "../../TemplatePrint/Iph";
import IphTemplateSecond from "../../TemplatePrint/IphSecond";
import MadridTemplate from "../../TemplatePrint/Madrid";
import SapeneTemplate from "../../TemplatePrint/Sapene";
import SydneyTemplate from "../../TemplatePrint/Sydney";

import {
  Container,
  Row,
  RowInner,
  Tile,
  NotAvailableContainer,
  TileMedia,
  TileDetails,
  TileTitle,
  Icons,
  IconDelete,
} from "./MyFavorites.styles";
import { useTemplatesStore } from "../../../../store/TemplatesStore";

const Template = ({ model, ...props }) => {
  switch (model) {
    case "Londres":
      return <LondresTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Paris":
      return <ParisTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Barcelone":
      return <BarceloneTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Berlin":
      return <BerlinTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Cuppertino":
      return <CuppertinoTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Singapour":
      return <SingapourTemplate isMini={"0.25"} isPreview={true} {...props} />;

    case "Perso":
      return <PersoTemplate isMini={"0.25"} isPreview={true} {...props} />;

    case "Bersot Immobilier":
      return <BersotTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Roland Gosselin & Associés":
      return <GosselinTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Sydney":
      return <SydneyTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Loft And House":
      return (
        <LoftAndHouseTemplate isMini={"0.25"} isPreview={true} {...props} />
      );
    case "IPH":
      return <IphTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "IPH Second":
      return <IphTemplateSecond isMini={"0.25"} isPreview={true} {...props} />;
    case "Madrid":
      return <MadridTemplate isMini={"0.25"} isPreview={true} {...props} />;
    case "Sapene":
      return <SapeneTemplate isMini={"0.25"} isPreview={true} {...props} />;

    default:
      return null;
  }
};

const Index = ({ handleValueFromFavorite, favoriteAction }) => {
  const { project, deleteTemplate, agency } = useTemplatesStore();
  const [mode, setMode] = useState("landscape");
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:1285px)");
  const matchesXs = useMediaQuery("(max-width:600px)");

  return (
    <div
      style={{
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        {project.templates &&
        project.templates.find((item) => item.orientation === "landscape") ? (
          <ModeButton
            selected={mode === "landscape"}
            onClick={() => setMode("landscape")}
          >
            {t("modalprint.landscape")}
          </ModeButton>
        ) : null}

        {project.templates &&
        project.templates.find((item) => item.orientation === "portrait") ? (
          <ModeButton
            selected={mode === "portrait"}
            onClick={() => setMode("portrait")}
          >
            {t("modalprint.portrait")}
          </ModeButton>
        ) : null}
      </div>
      <Fade in={true} timeout={1000}>
        <Container>
          <Row
            alignItems={mode === "portrait" ? "initial" : "center"}
            justifyContent={
              mode === "portrait" &&
              project.templates.filter((item) => item.orientation === mode)
                .length <= 5 &&
              !matches
                ? "center"
                : mode === "landscape" &&
                  project.templates.filter((item) => item.orientation === mode)
                    .length <= 3 &&
                  !matches
                ? "center"
                : "flex-start"
            }
          >
            <RowInner>
              {project.templates &&
                project.templates
                  .filter((item) => item.orientation === mode)
                  .map((item, idx) => {
                    return (
                      <Tile
                        width={mode !== "portrait" ? "400px" : "285px"}
                        marginRight={matchesXs ? "30px" : "10px"}
                        key={idx}
                      >
                        <NotAvailableContainer>
                          <Icons
                            as={CreateIcon}
                            onClick={() => {
                              handleValueFromFavorite(item, "edit");
                              favoriteAction("edit");
                            }}
                          />
                          <Icons
                            as={PrintIcon}
                            onClick={() => {
                              handleValueFromFavorite(item, "print");
                              favoriteAction("print");
                            }}
                          />
                          <IconDelete
                            as={DeleteIcon}
                            onClick={() => deleteTemplate(project, item)}
                          />
                        </NotAvailableContainer>
                        <TileMedia
                          width={
                            item.orientation !== "portrait" ? "400px" : "285px"
                          }
                        >
                          <Template
                            key={idx}
                            {...item}
                            agency={agency}
                            project={project}
                          />
                        </TileMedia>
                        <TileDetails>
                          <TileTitle>{item.model}</TileTitle>
                        </TileDetails>
                      </Tile>
                    );
                  })}
            </RowInner>
          </Row>
        </Container>
      </Fade>
    </div>
  );
};

export default Index;
