import React from "react";
import { get24hPast, nowList } from "../Utils/features";

const LastImportField = (props) => {
  if (props.record.lastImport) {
    return (
      <span
        style={{
          fontSize: "0.875rem",
          fontFamily: "Open Sans, sans-serif",
          fontWeight: "400",
          lineHeight: "1.43",
          letterSpacing: "0.01071em",
          color:
            get24hPast(props.record.lastImport) === true ? "red" : "initial",
        }}
      >
        {nowList(props.record.lastImport)}
      </span>
    );
  } else {
    return null;
  }
};

export default LastImportField;
