import React, { useContext, useEffect, useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import {
  Btn,
  Btn360,
  CameraIcon,
  CardActionContainer,
  CardContainer,
  CardContentImage,
  CardContentInfoContainer,
  InfoContainer,
  LikeTagContainer,
  InfoItemsContainer,
  CardContentPlace,
  CityTextBold,
  Image,
  ImageTagContainer,
  StateContainer,
  LinkImage,
  SpanCityText,
  SpanPriceText,
  SpanLocationText,
  TextTagHeart,
  Tag360Container,
  TagContainer,
  TextTag,
  GridIcon,
  TextVideoTag,
  VideoTagContainer,
  Visite360Icon,
  RoomIcon,
  BedIcon,
  LikeContainer,
  ViewIcon,
  BtnEdit,
  PencilIcon,
  SpanBtnEditText,
  SpanPriceTextMonth,
  StateIcon,
  ClickIcon,
  PhoneUI,
  ClickWhatsapp,
} from "../../../Utils/Styles/ProjectList.styles";
import { choices } from "../../../Utils/dataChoices";
import PrintIcon from "@material-ui/icons/Print";

import { Grow } from "@material-ui/core";
import { CombineContext } from "../../../../../../store/UserProvider";

const View360Button = ({ record, slugAgence, translate }) => {
  if (record.scenes && record.scenes.length) {
    return (
      <Btn href={`360/edit/${record._id}`}>
        {translate("resources.fields.edit360")}
      </Btn>
    );
  } else {
    return (
      <Btn360 href={`/360/edit/${record._id}`} target="_blank">
        {translate("resources.fields.create360").toUpperCase()}
      </Btn360>
    );
  }
};
const EditProjectButton = ({ record, translate, ads }) => {
  const redirect = useRedirect();
  return (
    <BtnEdit
      resource="projects"
      onClick={() => redirect(`/projects/${record._id}`)}
      ads={ads}
    >
      <PencilIcon />
      <SpanBtnEditText>
        {translate("resources.fields.edit").toUpperCase()}
      </SpanBtnEditText>
    </BtnEdit>
  );
};
const PrintButton = ({ record, translate, ads }) => {
  const redirect = useRedirect();
  return (
    <BtnEdit
      resource="projects"
      onClick={() => redirect(`/print/${record._id}`)}
      ads={ads}
    >
      <PrintIcon />
      <SpanBtnEditText>
        {translate("resources.fields.print").toUpperCase()}
      </SpanBtnEditText>
    </BtnEdit>
  );
};

const ProjectPoster = (props) => {
  const translate = useTranslate();
  const [ads, setAds] = useState(false);
  const data = useContext(CombineContext);

  useEffect(() => {
    const role = ["super_admin", "admin"];
    if (
      role.includes(props.user.permissions) ||
      data.typeValue.type === "360"
    ) {
      setAds(true);
    }
  }, [data.typeValue.type, props.user.permissions]);

  const { record, open, handleImageLoaded, slugAgence } = props;
  return (
    <Grow in={true} timeout={300} exit={true}>
      <CardContainer open={open}>
        <CardContentImage>
          <LinkImage
            href={ads ? `visite360/${slugAgence}/${record.slug}` : null}
            target={ads ? "_blank" : null}
          >
            <Image
              src={
                record.images && record.images.length !== 0
                  ? record.images[0].src
                  : "https://fakeimg.pl/350x240/?text=" +
                    translate("resources.fields.comingsoon")
              }
              alt="mandat"
              onLoad={handleImageLoaded}
            />
          </LinkImage>
          <TagContainer>
            {record.images && record.images.length > 0 && (
              <ImageTagContainer>
                <CameraIcon />
                <TextTag>{record.images.length}</TextTag>
              </ImageTagContainer>
            )}

            {(ads && record.medias && record.medias.length > 0) ||
              (ads && record.mediasurl && record.mediasurl.length > 0 && (
                <VideoTagContainer>
                  <TextVideoTag>
                    {record.medias.length + record.mediasurl.length} Vidéo
                    {record.medias.length > 1 ? "s" : null}
                  </TextVideoTag>
                </VideoTagContainer>
              ))}
            {ads && record.scenes && (
              <Tag360Container>
                <Visite360Icon />
                <TextTag>{translate("resources.fields.360")}</TextTag>
              </Tag360Container>
            )}
          </TagContainer>
          <StateContainer>
            <StateIcon
              mycolor={
                record.state === choices.state[1].id //en préparation
                  ? "orange"
                  : record.state === choices.state[3].id //terminé
                  ? "red"
                  : record.state === choices.state[4].id
                  ? "black"
                  : "inherit" //en diffusion
              }
            />
          </StateContainer>
          <LikeContainer>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "180px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {ads && record.view.total === 1 && (
                <>
                  <LikeTagContainer>
                    <TextTagHeart>
                      {record.view.data[0].countView.length}
                    </TextTagHeart>
                    <ViewIcon />
                  </LikeTagContainer>
                  {record.view.data[0].countClick.length > 0 && (
                    <LikeTagContainer>
                      <TextTagHeart>
                        {record.view.data[0].countClick.length}
                      </TextTagHeart>
                      <ClickIcon />
                    </LikeTagContainer>
                  )}
                  {record.view.data[0].countClickPhone.length > 0 && (
                    <LikeTagContainer>
                      <TextTagHeart>
                        {record.view.data[0].countClickPhone.length}
                      </TextTagHeart>
                      <PhoneUI />
                    </LikeTagContainer>
                  )}
                  {record.view.data[0].countClickWhatsapp &&
                    record.view.data[0].countClickWhatsapp.length > 0 && (
                      <LikeTagContainer>
                        <TextTagHeart>
                          {record.view.data[0].countClickWhatsapp.length}
                        </TextTagHeart>
                        <ClickWhatsapp />
                      </LikeTagContainer>
                    )}
                </>
              )}
            </div>
          </LikeContainer>
        </CardContentImage>
        <CardContentInfoContainer ads={ads}>
          <InfoContainer>
            <InfoItemsContainer>
              <div>
                <GridIcon />
              </div>
              <div>
                <TextTag>{record.area.total} m²</TextTag>
              </div>
            </InfoItemsContainer>
            <InfoItemsContainer>
              <div>
                <RoomIcon />
              </div>
              <div>
                <TextTag>
                  {record.rooms}{" "}
                  {record.rooms > 1
                    ? translate("resources.fields.rooms") + "s"
                    : translate("resources.fields.rooms")}
                </TextTag>
              </div>
            </InfoItemsContainer>
            <InfoItemsContainer>
              <div>
                <BedIcon />
              </div>
              <div>
                <TextTag>
                  {record.bedrooms}{" "}
                  {record.bedrooms > 1
                    ? translate("resources.fields.bedrooms") + "s"
                    : translate("resources.fields.bedrooms")}
                </TextTag>
              </div>
            </InfoItemsContainer>
          </InfoContainer>
          <CardContentPlace>
            <div>
              <div style={{ marginBottom: "5px" }}>
                <SpanCityText>
                  <CityTextBold>{record.city}</CityTextBold>
                </SpanCityText>
              </div>
              <div style={{ maxWidth: "191px" }}>
                <SpanLocationText>ref: {record.reference}</SpanLocationText>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <SpanPriceText>{`${record.price} ${
                record.currency ? record.currency : "€"
              } `}</SpanPriceText>
              {record.category === "Location" && (
                <div>
                  <SpanPriceTextMonth>
                    /{translate("resources.fields.month")}
                  </SpanPriceTextMonth>
                </div>
              )}
              {record.periodicity &&
                record.category === choices.category[2].id && (
                  <div>
                    <SpanPriceTextMonth>
                      /
                      {translate(
                        choices.periodicity.filter(
                          (item) => item.id === record.periodicity
                        )[0].name
                      )}
                    </SpanPriceTextMonth>
                  </div>
                )}
            </div>
          </CardContentPlace>
          <CardActionContainer ads={ads}>
            {ads && (
              <View360Button
                record={record}
                slugAgence={slugAgence}
                translate={translate}
              />
            )}
            <EditProjectButton
              record={record}
              translate={translate}
              ads={ads}
            />
            {!ads && (
              <PrintButton record={record} translate={translate} ads={ads} />
            )}
          </CardActionContainer>
        </CardContentInfoContainer>
      </CardContainer>
    </Grow>
  );
};

export default ProjectPoster;
