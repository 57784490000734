import logoDataBlack from "../assets/visibbleDataBlack.png";
import logoDataWhite from "../assets/whiteLogo/visibbleData.png";
import logo360 from "../assets/logo/logo360.png";
import logoPrint from "../assets/logo/logoPrint.png";
import logoDisplay from "../assets/logo/logoDisplay.png";
import logoAudit from "../assets/logo/logoAudit.png";
import logo360White from "../assets/whiteLogo/visibble360.png";
import logoPrintWhite from "../assets/whiteLogo/visibblePrint.png";

export const data = {
  logo: {
    logo360,
    logoPrint,
    logoDisplay,
    logoAudit,
    logoData: logoDataBlack,
  },
  whiteLogo: {
    logo360: logo360White,
    logoPrint: logoPrintWhite,
    logoData: logoDataWhite,
  },
};
