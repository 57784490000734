import React from "react";
import { Helmet } from "react-helmet";

const meta = require("./meta.json");

const MetaDecorator = ({ title, description, imageUrl }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:url"
        content={
          meta.hostname + window.location.pathname + window.location.search
        }
      />
    </Helmet>
  );
};

export default MetaDecorator;
