import React from "react";
import Loader from "../../../../Loader/Loader";
import { getAgency, getProjectFromApi } from "../../Utils/axiosRequests";

function DashboardWrapper(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this._isMounted = false;
      this.state = {
        data: [],
        loading: true,
        users: false,
      };
    }

    refresh = async () => {
      const agency = await getAgency(this.props.agency._id);
      this.setState({ agency: agency.data });
      const fetch = await getProjectFromApi("agenceId", this.props.agency._id);
      if (fetch.data && fetch.data.data) {
        this.setState({ data: fetch.data.data });
      } else {
        this.setState({ data: [] });
      }
      this.setState({ loading: false });
    };

    async componentDidMount() {
      this._isMounted = true;
      this._isMounted && (await this.refresh());
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    render() {
      if (this.state.loading) {
        return <Loader />;
      } else {
        return (
          <WrappedComponent
            {...this.state}
            refresh={this.refresh}
            type={this.props.type}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
}

export default DashboardWrapper;
