import React, { useEffect, useState } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import * as THREE from "three";
import Scene from "./Scene";

const Switcher = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true);
  const [index, set] = useState(null);
  const [position, setPosition] = useState(null);
  const { gl } = useThree();

  const maps = useLoader(
    THREE.TextureLoader,
    props.store.map((entry) => (entry.finalUrl ? entry.finalUrl : entry.url))
  );
  maps.map((item) => {
    item.wrapS = THREE.RepeatWrapping;
    item.repeat.x = -1;
    item.minFilter = THREE.LinearFilter;
    item.magFilter = THREE.LinearFilter;
    item.anisotropy = gl.capabilities.getMaxAnisotropy();
    return true;
  });

  useEffect(() => {
    if (props.store) {
      const find = props.store.findIndex((item) => item.current === true);
      set(find);
      if (
        props.store[find] &&
        props.store[find].hotspot &&
        props.store[find].hotspot.length
      ) {
        setPosition(props.store[find].position);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.store, maps]);

  if (loading) {
    return null;
  } else {
    return (
      <Scene
        setCurrentLink={props.setCurrentLink}
        position={position}
        texture={maps[index]}
        item={props.store[index]}
        setLoadingTransition={props.setLoadingTransition}
        ref={{ canvasRef: ref.canvasRef, panoRef: ref.panoRef }}
        scenes={props.store}
        setCount={props.setCount}
        autorotate={props.autorotate}
      />
    );
  }
});

export default Switcher;
