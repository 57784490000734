import React from "react";
import { useTranslate } from "react-admin";
import AreaGraph from "./AreaGraph";

import {
  BodyItemContainer,
  Box,
  BoxContainer,
  BoxGraphContainer,
  BoxInner,
  BoxInnerItem,
  BoxLabel,
  BoxTitle,
  BoxValue,
  Line,
  Shape,
  SpanShape,
  TitleContainer,
  TitleSection,
  TitleSectionContainer,
} from "./styles";

const Traffic = (props) => {
  const translate = useTranslate();

  return (
    <BodyItemContainer marginRight={"3%"}>
      <TitleSectionContainer>
        <TitleContainer>
          <TitleSection>
            {translate("resources.data.traffictitle").toUpperCase()}
          </TitleSection>
        </TitleContainer>
        <Line />
      </TitleSectionContainer>
      <BoxContainer marginTop="20px">
        <Box width={"49%"} marginRight={"2%"} minHeight="110px">
          <BoxInner display="flex">
            <BoxInnerItem width="30%">
              <Shape>
                <SpanShape>🚶‍♂️</SpanShape>
              </Shape>
            </BoxInnerItem>
            <BoxInnerItem width="70%">
              <BoxTitle>{translate("resources.data.traffic")}</BoxTitle>
              <BoxValue>{props.traffic !== 0 ? props.traffic : "0"}</BoxValue>
              <BoxLabel>{translate("resources.data.trafficlegend")}</BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
        <Box width={"49%"} marginRight={"0%"} minHeight="110px">
          <BoxInner display="flex">
            <BoxInnerItem width="30%">
              <Shape>
                <SpanShape>📅</SpanShape>
              </Shape>
            </BoxInnerItem>
            <BoxInnerItem width="70%">
              <BoxTitle>
                {translate("resources.data.moreattentive").toUpperCase()}
              </BoxTitle>
              <BoxValue>{props.trafficBestDay}</BoxValue>
              <BoxLabel>
                {translate("resources.data.moreattentivelegend")}
              </BoxLabel>
            </BoxInnerItem>
          </BoxInner>
        </Box>
      </BoxContainer>

      <BoxContainer marginTop="10px">
        <Box width={"100%"} marginRight={"0%"} minHeight="155px">
          <BoxInner display="block">
            <BoxTitle marginLeft={"5px"}>
              {translate("resources.data.attendance").toUpperCase()}
            </BoxTitle>
            <div style={{ height: "10px" }} />
            <BoxGraphContainer display="flex" width="100%" height={"115px"}>
              <AreaGraph
                data={props.trafficChartData.sort(
                  (a, b) => new Date(a.fullDate) - new Date(b.fullDate)
                )}
                label="Valeur"
              />
            </BoxGraphContainer>
          </BoxInner>
        </Box>
      </BoxContainer>
      {/* <BoxContainer marginTop="10px">
        <Box width={"100%"} marginRight={"0%"} minHeight="165px">
          <BoxInner display="block">
            <BoxTitle marginLeft={"5px"}>
              {translate("resources.data.attendancebyhour").toUpperCase()}
            </BoxTitle>
            <div style={{ height: "20px" }} />
            <BoxGraphContainer display="flex" width="100%" height={"115px"}>
              <AreaGraph
                data={props.trafficHourChartData.sort(
                  (a, b) =>
                    Number(a.name.substring(0, 2)) -
                    Number(b.name.substring(0, 2))
                )}
                label="Valeur"
              />
            </BoxGraphContainer>
          </BoxInner>
        </Box>
      </BoxContainer> */}
    </BodyItemContainer>
  );
};

export default Traffic;
