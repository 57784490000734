import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ContactForm from "../ContactForm";
import PhoneIcon from "@material-ui/icons/Phone";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

// CSS
import "./index.css";

const ModalContact = (props) => {
  const { infoProject, agency } = props;
  const { t } = useTranslation();
  return (
    <div
      className="fade modal show"
      id="modal-contact"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalContactLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalContactLabel">
              {t("mobileView.leavemessage")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "88vh", overflow: "scroll" }}
          >
            <div className="pt-3 pb-3 pb-md-3">
              <ContactForm
                project={infoProject}
                agency={agency}
                isVmlive={props.isVmlive}
                agencyEmail={props.agencyEmail}
              />
            </div>
            {/* INFO SECTION */}
            <div className="text-center">
              {/* AGENCY INFO */}
              <div>
                {agency != null && (
                  <>
                    {agency.name && <h2 className="">{agency.name}</h2>}
                    {agency.address && (
                      <h5 className="mt-2">{agency.address}</h5>
                    )}
                    <h5 className="mt-2 text-uppercase">
                      {agency.postalcode ? agency.postalcode : null}{" "}
                      {agency.city ? agency.city : null}
                    </h5>
                    <a className="phonemobile" href={`tel:${agency.phone}`}>
                      <PhoneIcon style={{ marginBottom: "0.2rem" }} />
                      {""} {agency.phone}
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalContact.propTypes = {
  infoProject: PropTypes.object,
  agency: PropTypes.object,
  user: PropTypes.object,
};

export default ModalContact;
