import React from "react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { useTranslation } from "react-i18next";
import Offer from "../../pages/Offer/Offer";

const ModalOffer = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className="fade modal show"
      id="modal-offer"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalMapLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-more-width modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalMapLabel">
              {t("offer.title")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body">
            <Offer infoProject={props.infoProject} agency={props.agency} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOffer;
