import styled from "styled-components";

export const Container = styled.div`
  && {
    position: relative;
    height: 296.5mm;
    width: 420mm;
    min-width: 420mm;
    background: #000000 0% 0% no-repeat padding-box;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    overflow: hidden;
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;
export const BackgroundImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const LogoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 180px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
`;

export const QrcodeContainer = styled.div`
  width: 128px;
  height: 128px;
  background-color: white;
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Logo = styled.img`
  width: 100%;
`;

export const Footer = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`;

export const DpeContainer = styled.div`
  position: absolute;
  top: 800px;
  left: 1190px;
  width: 370px;
  height: 167px;
  overflow: hidden;
  display: flex;
`;

export const EnergyTextContainer = styled.div`
  position: absolute;
  top: 1000px;
  left: 1190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  width: 331px;
`;

export const DpeText = styled.p`
  margin-bottom: 5px;
  color: white;
  font-size: 12px;
  font-family: "Lato", sans-serif !important;
`;
export const SoldBanner = styled.div`
  height: 142px;
  min-width: 156px;
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;
export const SoldBannerText = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "30px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "500")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Lato', sans-serif"} !important;
`;

export const BottomContainer = styled.div`
  width: 820px;
  height: 200px;
  position: absolute;
  bottom: 5px;
  left: 225px;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const TitleText = styled.h2`
  margin-bottom: 0;
  color: ${(props) => props.colorTitle};
  margin-top: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "28px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "700")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Lato', sans-serif"} !important;
`;
export const IconsContainer = styled.div`
  display: flex;
  margin-top: 22px;
`;
export const IconContainer = styled.div`
  display: flex;
  margin-right: 25px;
  align-items: center;
`;
export const IconText = styled.p`
  font-size: 14px;
  color: white;
  margin-bottom: 0;
  font-family: "Lato", sans-serif !important;
`;
export const Icon = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: 9px;
`;

export const BottomLastContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const PriceContainer = styled.div``;

export const Price = styled.p`
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.family};
  color: ${(props) => props.colorPrice};
  margin-bottom: 0;
`;
export const Honoraire = styled.small`
  && {
    color: ${(props) => props.colorPrice};
    font-size: ${(props) => props.size};
    font-family: ${(props) => props.family};
    text-align: center;
  }
`;
export const AlurContainer = styled.div`
  display: flex;
`;
export const HonoraryContainer = styled.div`
  padding-left: 45px;
`;
export const AlurText = styled.p`
  && {
    color: white;
    font-size: 14px;
    font-family: "Lato", sans-serif !important;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
  }
`;
export const CoproContainer = styled.div`
  padding-left: 35px;
`;
