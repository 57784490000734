import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { DeleteProjectsBtn } from "../../../Utils/Styles/ProjectList.styles";
import { useTranslate } from "react-admin";

const Modal = (props) => {
  const translate = useTranslate();
  const { data, deleteMany, setModal } = props;

  const cancel = () => {
    setModal(false);
  };

  return (
    <div>
      <Dialog open={true}>
        <DialogContent>
          <Typography align="center" style={{ fontWeight: "900" }}>
            {translate("resources.fields.deleteconfirm")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <DeleteProjectsBtn onClick={() => cancel()}>
            {translate("resources.fields.cancel")}
          </DeleteProjectsBtn>
          <Button onClick={() => deleteMany(data)} color="primary">
            {translate("resources.fields.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
