import React from "react";
import Error from "../../../Components/Error/Error";
import MetaDecorator from "../../../Components/360/MetaDecorator";
import Loader from "../../../Components/Loader/Loader";
import { getNumberClickData, postClickCount } from "./Utils/axiosRequests";
import { manageState } from "./Utils/wrapperFeatures";

function Wrapper(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        project: null,
        infoProject: null,
        countView: null,
        user: null,
        agency: null,
        view: null,
        countClick: 0,
        noResult: false,
        noDiffuse: false,
        isActivated: false,
        secureForm: false,
        vmlive: false,
      };
    }
    // GET DATA FROM API WITH PARAMS ID
    getAllClick = async (target) => {
      const nbClick = await getNumberClickData(this.state.view._id);
      if (nbClick) {
        await postClickCount(nbClick, target);
      }
    };

    setSend = () => {
      this.setState({ secureForm: false });
    };

    setData = (data) => {
      this.setState({ ...data });
    };

    async componentDidMount() {
      await manageState(
        this.props.match.params.token,
        this.props.match.params.reference,
        null,
        this.setData
      );
    }

    render() {
      if (this.state.project === null && !this.state.noResult) {
        return <Loader />;
      } else if (this.state.noResult) {
        return (
          <Error
            noDiffuse={this.state.noDiffuse}
            slugAgence={this.state.slugAgence}
            project={this.state.infoProject}
          />
        );
      } else {
        return (
          <>
            <MetaDecorator
              title={
                this.state.infoProject.metatitle
                  ? this.state.infoProject.metatitle
                  : this.state.infoProject.name
              }
              description={this.state.infoProject.metadescription}
              imageUrl={
                this.state.infoProject.images
                  ? this.state.infoProject.images[0].src
                  : null
              }
            />
            <WrappedComponent
              {...this.state}
              getAllClick={this.getAllClick}
              secureUnlock={this.secureUnlock}
              setSend={this.setSend}
              {...this.props}
            >
              {this.props.children}
            </WrappedComponent>
          </>
        );
      }
    }
  };
}
export default Wrapper;
