import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: false,
    },
  },
  elements: {
    bar: {
      borderRadius: 8,
    },
  },
  scales: {
    x: {
      grid: {
        borderWidth: 0,
        display: false,
      },
      ticks: {
        font: { size: 10 },
        max: 100,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
};

export const HorizontalGraph = (props) => {
  const labels = ["- 20", "25 - 35", "35 - 45", "45 - 55", "55 +"];
  const data = {
    labels,
    datasets: [
      {
        barPercentage: 0.3,
        data: props.data,
        backgroundColor: "#5463fa",
      },
    ],
  };
  return (
    <div style={{ width: "280px", height: "110px", marginTop: "5px" }}>
      <Bar options={options} data={data} />
    </div>
  );
};
