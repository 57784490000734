import { config } from "../../constants/constant";
import axios from "axios";

export const getAgencyData = async (query) => {
  const url = config.API_BASE + "/agencies?slug=" + query;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getNumberClickData = async (id) => {
  const url = config.API_BASE + "/view/" + id;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const postClickCount = async (data, target) => {
  const url = config.API_BASE + "/view/" + data._id;
  const params = {
    method: "PATCH",
    url,
    data: {
      [target]: [...data[target], { data: 1, date: new Date() }],
      countClick: [...data.countClick, { data: 1, date: new Date() }],
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const postCountView = async (
  id,
  projectId,
  userId,
  oldData,
  agenceId
) => {
  let url;
  let data;
  if (id !== null) {
    url = config.API_BASE + "/view/" + id;
    data = {
      countView: [...oldData, { data: 1, date: new Date() }],
      agenceId: agenceId,
    };
  } else {
    url = config.API_BASE + "/view";
    data = {
      countView: [{ data: 1, date: new Date() }],
      projectId,
      userId: userId,
      agenceId: agenceId,
    };
  }
  const params = {
    method: id !== null ? "PATCH" : "POST",
    url,
    data,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const getContactExist = async (email) => {
  const url = config.API_BASE + "/contacts?email=" + email;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const sendNewRequestContact = async (project, agency, state, lang) => {
  const data = {
    lang: lang,
    agenceId: project.agenceId,
    leads: [
      {
        reference: project.reference,
        date: new Date(),
        code: true,
        context: state.context,
        lang: lang,
      },
    ],
    phonemobile: state.phonemobile,
    email: state.email,
    firstname: state.firstname,
    lastname: state.lastname,
    template: "contact-form-code",
    reference: project.reference,
    userId: project.userId,
    emailnotifications: agency.emailnotifications,
  };
  const url = config.API_BASE + "/contacts";
  const params = {
    method: "POST",
    url,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
  }
};

export const sendRequestContact = async (
  leads,
  project,
  agency,
  state,
  lang
) => {
  const leadsDetails = leads.leads;
  const data = {
    lang: lang,
    agenceId: project.agenceId,
    leads: [
      ...leadsDetails,
      {
        reference: project.reference,
        date: new Date(),
        code: true,
        context: state.context,
        lang: lang,
      },
    ],
    phonemobile: state.phonemobile,
    email: state.email,
    firstname: state.firstname,
    lastname: state.lastname,
    template: "contact-form-code",
    reference: project.reference,
    userId: project.userId,
    emailnotifications: agency.emailnotifications,
  };
  const url = config.API_BASE + "/contacts/" + leads._id;
  const params = {
    method: "PATCH",
    url,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
  }
};
