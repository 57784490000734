import { Select } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  padding: 1.5rem;
  height: 100%;
`;
export const ToolbarContainer = styled.div`
  display: flex;
  padding: 25px 15px;
  width: auto;
`;
export const BodyContainer = styled.div`
  display: flex;
  height: auto;
  flex-wrap: wrap;
  padding-left: 15px;
`;
export const BodyItemContainer = styled.div`
  width: 47%;
  margin-right: ${(props) => props.marginRight};
  height: 100%;
  min-width: 492px;

  @media screen and (max-width: 600px) {
    margin-right: 0;
    padding: 0 15px;
  }
`;

export const TitleSectionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  padding: 20px 0;
`;

export const TitleContainer = styled.div`
  padding: 0 20px 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: #fafafa;
`;

export const TitleSection = styled.p`
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #22292f;
  text-transform: uppercase;
`;
export const Line = styled.div`
  height: 2px;
  margin-left: 10px;
  width: 100%;
  background-color: #22292f;
`;

export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop};

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  width: ${(props) => props.width};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.minHeight};
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  margin-right: ${(props) => props.marginRight};

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: ${(props) => props.marginRight};
  }
`;
export const BoxInner = styled.div`
  display: ${(props) => props.display};
  padding: 10px 0 5px;
  justify-content: center;
`;
export const BoxInnerItem = styled.div`
  width: ${(props) => props.width};
  text-align: ${(props) => (props.center ? "center" : "left")};
`;
export const Shape = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: #e2e5fe;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SpanShape = styled.span`
  font-size: ${(props) => (props.small ? "18px" : "26px")};
`;

export const BoxTitle = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: grey;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 15px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0")};
`;
export const BoxValue = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
  color: black;
  margin-bottom: 0;
  line-height: 25px;
`;
export const BoxLabel = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: grey;
  margin: 2px 0;
  line-height: 14px;
`;
export const BoxGraphContainer = styled.div`
  display: ${(props) => props.display};
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
`;
export const GraphValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
`;
export const GraphValue = styled.p`
  font-size: 0.95rem;
  font-weight: 400;
  color: grey;
  margin-bottom: 10px;
  line-height: 15px;
`;
export const SelectCamera = styled(Select)`
  && {
    & .MuiSelect-select {
      background-color: white;
      box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    & .MuiSelect-icon {
      top: 21px;
    }
  }
`;
