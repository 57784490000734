import styled from "styled-components";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const backdrop = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  content: '""',
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  zIndex: 1900,
};

export const Container = styled.div`
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-y: ${(props) => (props.backdrop ? "hidden" : "scroll")};
  &::after {
    ${(props) => (props.backdrop ? backdrop : null)}
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 15px 10px;
`;

export const ImageHeader = styled.img`
  width: auto;
  max-height: 110px;
`;

export const Hero = styled.div`
  position: relative;
  padding: 5px;
  width: 100%;
`;

export const ImageHero = styled.img`
  width: 100%;
  height: auto;
  min-height: 250px;
  object-fit: cover;
  @media only screen and (min-width: 700px) {
    max-height: 500px;
  }
`;

export const HeroInfoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #00000057;
  width: 93%;
  height: auto;
  padding: 10px 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  @media only screen and (min-width: 700px) {
    width: 40%;
    padding: 50px 0;
    background-color: #00000094;
  }
`;

export const HeroInfoTitleContainer = styled.div`
  text-align: center;
  padding: 0 25px 2px;
`;

export const HeroTitle = styled.span`
  text-transform: uppercase;
  font-size: 6.2vw;
  font-weight: 900;
  color: white;

  @media only screen and (min-width: 700px) {
    font-size: 35px;
  }
`;

export const HeroInfoBodyContainer = styled.div`
  text-align: center;
`;

export const HeroInfoBody = styled.p`
  font-size: 6.2vw;
  font-weight: 500;
  color: white;
  margin-top: 0;
  margin-bottom: 0;

  @media only screen and (min-width: 700px) {
    font-size: 35px;
  }
`;
export const HeroInfoBodyRef = styled.p`
  font-size: 6vw;
  font-weight: 500;
  color: white;
  margin-top: 0;
  margin-bottom: 0;

  @media only screen and (min-width: 700px) {
    font-size: 28px;
  }
`;

export const Body = styled.div`
  padding: 20px;
  @media only screen and (min-width: 700px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

export const Button = styled.button`
  border-radius: 10px;
  padding: 20px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$backgroundColor};
  border: solid ${(props) => (props.border ? "2px " + props.border : "0px")};
  margin-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  & span {
    color: ${(props) => (props.border ? props.border : "white")};
  }

  @media only screen and (min-width: 700px) {
    grid-template-columns: 35% 65%;
    max-width: 650px;
  }
`;
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const PhoneIconSvg = styled.svg`
  && {
    color: ${(props) => (props.$color ? props.$color : "white")};
    font-size: 6.2vw;

    @media only screen and (min-width: 700px) {
      font-size: 28px;
    }
  }
`;
export const ChatIconSvg = styled.svg`
  && {
    color: ${(props) => (props.$color ? props.$color : "white")};
    font-size: 6.2vw;

    @media only screen and (min-width: 700px) {
      font-size: 28px;
    }
  }
`;
export const SendIconSvg = styled.svg`
  && {
    color: ${(props) => (props.$color ? props.$color : "white")};
    font-size: 6.2vw;

    @media only screen and (min-width: 700px) {
      font-size: 28px;
    }
  }
`;

export const Icon360 = styled.span`
  font-size: 22px;
  color: ${(props) => (props.color ? props.color : "white")};

  @media only screen and (min-width: 700px) {
    font-size: 28px;
  }
`;

export const ButtonText = styled.span`
  font-size: 4.5vw;
  text-align: left;

  @media only screen and (min-width: 700px) {
    font-size: 28px;
  }
`;

export const PropertyValueContainer = styled.div`
  margin: 1.5rem 0 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PropertyValueLink = styled.a`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 900;
  color: black;
  cursor: pointer;

  &:hover {
    color: black;
  }

  @media only screen and (min-width: 700px) {
    font-size: 1.5rem;
  }
`;

export const ArrowIcon = styled(ArrowRightAltIcon)`
  && {
    margin-left: 15px;
    font-size: 2.3rem;
    color: black;
    cursor: pointer;

    @media only screen and (min-width: 700px) {
      font-size: 2.5rem;
    }
  }
`;

export const ContainerHome360 = styled.div`
  height: 100%;
  background-color: #34495e;

  &::-webkit-scrollbar {
    width: 0.5px;
  }
`;
