import React from "react";
import {
  HeaderContainer,
  TotalProjectContainer,
  TotalText,
  TotalTextBold,
  TotalTextColor,
} from "../../Utils/Styles/DashboardComponents.styles";

const DashboardHeaderAdmin = (props) => {
  return (
    <HeaderContainer open={props.open}>
      <TotalProjectContainer open={props.open}>
        <TotalText>
          <TotalTextBold>Admin</TotalTextBold>
          <TotalTextColor> visibble</TotalTextColor>{" "}
        </TotalText>
      </TotalProjectContainer>
    </HeaderContainer>
  );
};

export default DashboardHeaderAdmin;
