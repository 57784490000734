import React from "react";
import {
  BodyContainer,
  BodyContainerLeft,
  BodyContainerRight,
  Category,
  Container,
  ContainerDetails,
  ContainerImg,
  ContainerPrice,
  DescContainer,
  Description,
  EnergyContainer,
  Exclu,
  ExcluContainer,
  ImgCover,
  InfoCity,
  InfoType,
  LogoAgence,
  LogoContainer,
  QrcodeContainer,
  RefText,
} from "./SingapourPortrait.styles";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import QRCode from "react-qr-code";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../Modules/ModulesElements.styles";

const SingapourTemplatePortrait = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    qrcode,
    qrcodeMode,
    titleTextFont,
    subTitleTextFont,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
      colorPrimary={color.primary || "#EBEAEA"}
    >
      <LogoContainer>
        {agency.images && agency.images.length ? (
          <LogoAgence src={agency.images[0].src} alt="logo agence" />
        ) : null}
      </LogoContainer>
      {isBanner === "true" && (
        <ExcluContainer colorPrimary={color.primary || "white"}>
          <Exclu
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </Exclu>
        </ExcluContainer>
      )}
      <ContainerImg>
        <ImgCover
          src={imageFirst}
          brightness={brightness < 110 ? brightness : brightness - 10}
          saturation={saturation}
        />
      </ContainerImg>
      <BodyContainer>
        <BodyContainerLeft>
          <Category>
            {t(
              choices.category.filter((item) => item.id === project.category)[0]
                .name
            )}
          </Category>
          <RefText
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            Ref: {refText}
          </RefText>
          <EnergyContainer>
            <RenderContext project={project}>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "180px",
                  left:
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "130px"
                      : "40px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.22"
                      : "0.26"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "135px"
                      : "5px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "145px"
                      : "120px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.54"
                      : "0.35"
                  }
                  theme={"light"}
                />
              </div>
            </RenderContext>
          </EnergyContainer>
        </BodyContainerLeft>
        <BodyContainerRight>
          <InfoType
            colorTitle={colorTitle || "black"}
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            {project.type && project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </InfoType>
          <InfoCity
            colorSubTitle={colorSubTitle || "black"}
            size={subTitleTextFont.size}
            weight={subTitleTextFont.weight}
            family={subTitleTextFont.font}
          >
            {subTitleText}
          </InfoCity>
          <DescContainer>
            <Description
              colorDesc={colorDesc || "black"}
              size={descFont.size}
              weight={descFont.weight}
              family={descFont.font}
            >
              {desc}
            </Description>
          </DescContainer>
          <ContainerPrice>
            <ModulePrice
              colorPrice={colorPrice || "black"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "35pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"15pt"}
            />
          </ContainerPrice>

          <ContainerDetails>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={colorDesc || "black"}
                price={price}
                sizeText={"12pt"}
              />
              <AlurText sizeText={"12pt"} colorDesc={colorDesc || "black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurText>
            </RenderContext>
          </ContainerDetails>
          {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
            <QrcodeContainer>
              <QRCode value={qrcodeEdit} size={130} />
            </QrcodeContainer>
          )}
        </BodyContainerRight>
      </BodyContainer>
    </Container>
  );
});

export default SingapourTemplatePortrait;
