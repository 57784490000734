/* eslint-disable no-unused-vars */
import React from "react";
import { TextInput, FormWithRedirect, useTranslate } from "react-admin";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Fade,
  Typography,
} from "@material-ui/core";
import ToolbarOffer from "./ToolbarOffer";
import OfferSmallGrid from "../offerSmallGrid/OfferSmallGrid";
import Customer from "./Customer";

const OfferForm = (props) => {
  const translate = useTranslate();

  return (
    <FormWithRedirect
      {...props}
      redirect="list"
      render={(formProps) => {
        return (
          <Fade in={true} timeout={300}>
            <Card>
              <form>
                <CardContent className="Form-Project">
                  <Box>
                    {/* INFORMATION Part */}
                    <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px" mt="20px">
                      <Typography variant="h6" gutterBottom>
                        {translate("resources.offers.title")}
                      </Typography>

                      <Divider light style={{ marginBottom: "10px" }} />
                      <Customer customer={"customer"} />
                      {formProps.record.secondCustomer !== null && (
                        <>
                          <Typography variant="h6" gutterBottom>
                            {translate("resources.offers.title")}
                          </Typography>
                          <Customer customer={"secondCustomer"} />
                        </>
                      )}
                      <Box display={{ xs: "block", sm: "flex" }} mt={3}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <OfferSmallGrid data={formProps.record} />
                        </Box>
                      </Box>
                      <Box display={{ xs: "block", sm: "flex" }} mt={4}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <TextInput
                            multiline
                            source="comment"
                            resource="offers"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* end */}
                </CardContent>
                <ToolbarOffer formProps={formProps} />
              </form>
            </Card>
          </Fade>
        );
      }}
    />
  );
};

export default OfferForm;
