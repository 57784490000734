import React, { useContext, useEffect, useState } from "react";
import { TabbedForm, FormTab, Edit } from "react-admin";
import AgencyForm from "./Components/Form/AgencyForm";
import TemplateForm from "./Components/Form/TemplateForm";
import formWrapper from "./Components/Form/formWrapper";
import ProjectSmallGrid from "./Components/TabbedFormItems/SmallGrid/ProjectSmallGrid";
import AgencyToolbar from "./Components/Form/AgencyToolbar";
import UserFromAgency from "./Components/TabbedFormItems/UserFromAgency/UserFromAgency";
// import SubscriptionPage from "./Components/TabbedFormItems/SubscriptionPage/SubscriptionPage";
import CamerasForm from "./Components/TabbedFormItems/Cameras/CamerasForm";
import { CombineContext } from "../../../store/UserProvider";

const AgencyEdit = ({ permissions, ...props }) => {
  const store = useContext(CombineContext);
  const [user, setUser] = useState(null);
  const {
    initialValues,
    enableSaveAddress,
    onPlaceSelect,
    userSelect,
    agency,
    onChangeColor,
    ...otherProps
  } = props;
  const role = ["admin", "super_admin"];
  useEffect(() => {
    if (store) {
      let user;
      if (store.valueChange && store.valueChange.userChange) {
        user = store.valueChange.userChange;
      } else {
        user = store.value.user;
      }
      setUser(user);
    }
  }, [store]);

  return (
    <Edit undoable={false} {...otherProps} basePath="/agencies">
      <TabbedForm
        toolbar={
          <AgencyToolbar
            initialValues={props.initialValues}
            enableSaveAddress={props.enableSaveAddress}
            handleSubmitWithRedirect={props.handleSubmitWithRedirect}
            permissions={permissions}
          />
        }
        // sanitizeEmptyValues={false}
      >
        <FormTab label="resources.agencies.tabname">
          <AgencyForm
            {...props}
            onPlaceSelect={onPlaceSelect}
            initialValues={initialValues}
            agency={agency}
            userSelect={userSelect}
          />
        </FormTab>

        {/* <FormTab label="resources.agencies.tabname.billing">
          <SubscriptionPage data={props} />
        </FormTab> */}
        {user && role.includes(user.permissions) && (
          <FormTab label="resources.agencies.tabname.cameras">
            <CamerasForm data={props} />
          </FormTab>
        )}
        <FormTab label="Template">
          <TemplateForm
            {...props}
            onPlaceSelect={onPlaceSelect}
            initialValues={initialValues}
            agency={agency}
            userSelect={userSelect}
          />
        </FormTab>
        <FormTab label="resources.users.tabname">
          <UserFromAgency id={props.id} />
        </FormTab>
        <FormTab label="resources.projects.tabname">
          <ProjectSmallGrid id={props.id} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default formWrapper(AgencyEdit);
