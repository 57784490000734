import React from "react";

const Surface = ({ color, width, height }) => {
  return (
    <svg
      id="Groupe_1"
      data-name="Groupe 1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.71 27.71"
    >
      <g
        id="icons_surface"
        data-name="icons surface"
        transform="translate(0 0)"
      >
        <path
          id="Tracé_9"
          data-name="Tracé 9"
          d="M1298.852,268.626h-1.108v-.643h1.108Zm0-1.474h-1.108v-1.108h1.108Zm0-1.939h-1.108V264.1h1.108Zm0-1.94h-1.108v-.787h.233v-.554h.875Zm-1.707-.233h-1.108v-1.108h1.108Zm-1.94,0H1294.1v-1.108h1.108Zm-1.939,0h-1.109v-1.108h1.109Z"
          transform="translate(-1281.373 -251.701)"
          fill={color}
        />
        <path
          id="Tracé_10"
          data-name="Tracé 10"
          d="M1308.92,266.192l-10.53-.023v-1.108l9.422.021V249.82h-15.261l.021,9.422h-1.108l-.023-10.53h17.48Z"
          transform="translate(-1281.21 -248.712)"
          fill={color}
        />
        <path
          id="Tracé_11"
          data-name="Tracé 11"
          d="M1295.7,279.411h-17.48v-17.48h11.128v1.108h-10.02V278.3h15.263v-10.02h1.108Z"
          transform="translate(-1278.222 -251.701)"
          fill={color}
        />
        <path
          id="Tracé_12"
          data-name="Tracé 12"
          d="M1282.888,273.65a10.533,10.533,0,0,0-.033-1.07h.632l.033.638h0a1.374,1.374,0,0,1,1.291-.731,1.168,1.168,0,0,1,1.108.792h0a1.7,1.7,0,0,1,.443-.515,1.358,1.358,0,0,1,.876-.277c.521,0,1.3.344,1.3,1.718v2.333h-.7v-2.216c0-.765-.277-1.219-.859-1.219a.925.925,0,0,0-.848.654,1.156,1.156,0,0,0-.055.36v2.444h-.71v-2.395c0-.632-.282-1.108-.826-1.108a.971.971,0,0,0-.892.715.937.937,0,0,0-.06.354v2.389h-.7Z"
          transform="translate(-1279.27 -254.087)"
          fill={color}
        />
        <path
          id="Tracé_13"
          data-name="Tracé 13"
          d="M1293.316,271.789l.516-.471c.681-.621,1.03-1.008,1.03-1.424a.554.554,0,0,0-.549-.558.547.547,0,0,0-.071,0,1.178,1.178,0,0,0-.687.255l-.171-.377a1.665,1.665,0,0,1,1-.344.96.96,0,0,1,1.1.8,1.17,1.17,0,0,1,.012.128c0,.621-.466,1.07-1.109,1.613l-.255.228h1.4v.471h-2.217Z"
          transform="translate(-1281.633 -253.268)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Surface;
