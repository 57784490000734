import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  TextInput,
  SelectInput,
  useTranslate,
  PasswordInput,
} from "react-admin";

const FormToken = (props) => {
  const translate = useTranslate();

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {translate("resources.agencies.token")}
      </Typography>
      <Divider light style={{ marginBottom: "15px" }} />
      <Box
        display={{ xs: "block", sm: "flex" }}
        style={{ alignItems: "center" }}
      >
        <SelectInput
          source="apiTokenName"
          resource="agencies"
          choices={props.choices.externalApi}
          onChange={props.changeApi}
        />
        {props.record.apiTokenValue || props.apiName === "vmlive" ? (
          <TextInput source="apiTokenValue" resource="agencies" fullWidth />
        ) : (
          <Box display={{ xs: "block", sm: "flex" }}>
            <TextInput source="apiTokenUsername" />
            <PasswordInput
              source="apiTokenPassword"
              inputProps={{
                readOnly: true,
                onFocus: (event) => (event.target.readOnly = false),
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default FormToken;
