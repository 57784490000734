import React, { useEffect } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
// Alert
import Administration from "./pages/Administration";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
// Storage
import {
  UserContext,
  UserChangeContext,
  CombineContext,
  UiContext,
  AgencyContext,
} from "./store/UserProvider";
import { config } from "./constants/constant";
// Pages
// CSS
import "./App.css";
// import { routes } from "./routes";
import useData from "./hooks/useData";
import { TemplatesStoreProvider } from "./store/TemplatesStore";
import VmViewerMobile from "./pages/View360Mobile/VmViewerMobile";
import IndexErrorPage from "./pages/IndexErrorPage";
import Loader from "./Components/Loader/Loader";
import SignIn from "./pages/Sign/SignIn";

const history = createBrowserHistory();
if (config.GOOGLE_ANALYTICS) {
  ReactGA.initialize(config.GOOGLE_ANALYTICS);
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

function App() {
  // PROVIDER
  const {
    valueUi,
    value,
    valueChange,
    loading,
    isLogged,
    agencyValue,
    typeValue,
    locale,
    setLocale,
    setIsLogged,
  } = useData();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
    console.log(
      "Analytics : ",
      window.location.pathname + window.location.search
    );
  }, []);

  const options = {
    timeout: 7000,
    position: positions.TOP_CENTER,
    containerStyle: {
      zIndex: 1900,
    },
  };

  return (
    <UserContext.Provider value={value}>
      <UserChangeContext.Provider value={valueChange}>
        <AgencyContext.Provider value={agencyValue}>
          <UiContext.Provider value={valueUi}>
            <CombineContext.Provider
              value={{ value, valueChange, agencyValue, typeValue }}
            >
              <TemplatesStoreProvider>
                <Router history={history}>
                  <Provider
                    template={AlertTemplate}
                    {...options}
                    history={history}
                  >
                    <div className="App">
                      <Switch>
                        <Route exact path="/">
                          <IndexErrorPage />
                        </Route>
                        <Route
                          exact
                          path="/vmlive/:token/:reference"
                          render={(props) => <VmViewerMobile {...props} />}
                        />
                        <Route
                          exact
                          path={"/sign-in-admin"}
                          render={(props) => (
                            <SignIn
                              {...props}
                              setLocale={setLocale}
                              setIsLogged={setIsLogged}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={"/adminonly"}
                          render={(props) =>
                            loading ? (
                              <Loader />
                            ) : isLogged ? (
                              <Administration {...props} locale={locale} />
                            ) : (
                              <Redirect to="/sign-in-admin" />
                            )
                          }
                        />
                        <Route path="*">
                          <IndexErrorPage />
                        </Route>
                      </Switch>
                    </div>
                  </Provider>
                </Router>
              </TemplatesStoreProvider>
            </CombineContext.Provider>
          </UiContext.Provider>
        </AgencyContext.Provider>
      </UserChangeContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
