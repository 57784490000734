import { deleteFiles, uploadImg } from "../../../firebase/firebase.function";

export const uploadImage = async (params, resource, id, setter) => {
  let idSend;
  if (id) {
    idSend = id;
  } else {
    idSend = params.data.id;
  }
  let files = params.data[setter];
  if (!Array.isArray(files)) {
    files = [files];
  }

  const prevImages =
    params.previousData !== undefined ? params.previousData[setter] : false; // Les données de l'ancien formulaire
  const newPictures = params.data[setter]
    ? files.filter((p) => p.rawFile instanceof File)
    : []; // Les nouvelles images en cours d'upload
  const formerPictures = params.data[setter]
    ? files.filter((p) => !(p.rawFile instanceof File))
    : []; // Les images deja uploadés et qui persistent
  const removeImages = prevImages
    ? prevImages.filter(
        ({ src: id1 }) => !formerPictures.some(({ src: id2 }) => id2 === id1)
      )
    : []; // L'intersection des Anciennes valeurs - Les images qui restent
  if (removeImages && removeImages.length)
    await deleteFiles(removeImages, resource);
  const newPics =
    newPictures.length !== 0
      ? await uploadImg(newPictures, resource, idSend)
      : [];
  const response = { newPics, formerPictures };
  return response;
};

export const uploadVideo = async (params, resource, id) => {
  let idSend;
  if (id) {
    idSend = id;
  } else {
    idSend = params.data.id;
  }
  let files = params.data.medias;
  if (!Array.isArray(files)) {
    files = [files];
  }

  const prevMedias =
    params.previousData !== undefined ? params.previousData.medias : false; // Les données de l'ancien formulaire
  const newMedias = files.filter((p) => p.rawFile instanceof File); // Les nouvelles Medias en cours d'upload
  const formerMedias = files.filter((p) => !(p.rawFile instanceof File)); // Les Medias deja uploadés et qui persistent
  const removeMedias = prevMedias
    ? prevMedias.filter(
        ({ src: id1 }) => !formerMedias.some(({ src: id2 }) => id2 === id1)
      )
    : []; // L'intersection des Anciennes valeurs - Les Medias qui restent
  if (removeMedias && removeMedias.length)
    await deleteFiles(removeMedias, resource);
  const newVid =
    newMedias.length !== 0 ? await uploadImg(newMedias, resource, idSend) : [];
  const response = { newVid, formerMedias };
  return response;
};
export const uploadFiles = async (params, resource, id) => {
  let idSend;
  if (id) {
    idSend = id;
  } else {
    idSend = params.data.id;
  }
  let files = params.data.files;
  if (!Array.isArray(files)) {
    files = [files];
  }

  const prevMedias =
    params.previousData !== undefined ? params.previousData.files : false; // Les données de l'ancien formulaire
  const newMedias = files.filter((p) => p.rawFile instanceof File); // Les nouvelles Medias en cours d'upload
  const formerFiles = files.filter((p) => !(p.rawFile instanceof File)); // Les Medias deja uploadés et qui persistent
  const removeMedias = prevMedias
    ? prevMedias.filter(
        ({ src: id1 }) => !formerFiles.some(({ src: id2 }) => id2 === id1)
      )
    : []; // L'intersection des Anciennes valeurs - Les Medias qui restent
  if (removeMedias && removeMedias.length)
    await deleteFiles(removeMedias, resource);
  const newf =
    newMedias.length !== 0 ? await uploadImg(newMedias, resource, idSend) : [];
  const response = { newf, formerFiles };
  return response;
};
