/* eslint-disable no-unused-vars */
import { FormControl, InputAdornment, Typography } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";

import React, { useEffect, useState } from "react";
import { Fade } from "@material-ui/core";
import { useTranslate } from "react-admin";

const AreaFilter = (props) => {
  const translate = useTranslate();
  const [click, setClick] = useState(false);
  const [values, setValues] = useState({
    min: "",
    max: "",
  });
  const [error, setError] = useState({
    min: false,
    max: false,
  });

  useEffect(() => {
    if (props.isReset) {
      setValues({
        min: "",
        max: "",
      });
    }
  }, [props.isReset]);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    if (event.target.name === "min") {
      props.handleFilter("areamin", event.target.value);
    } else if (event.target.name === "max") {
      props.handleFilter("areamax", event.target.value);
    }
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Typography
        onClick={() => setClick(!click)}
        style={{
          cursor: "pointer",
          fontSize: "0.75rem",
          letterSpacing: "0.08333em",
          marginBottom: "0.5rem",
        }}
      >
        {translate("resources.projects.fields.area").toUpperCase()}
      </Typography>
      <Fade in={click} timeout={300} unmountOnExit>
        <div>
          <FormControl variant="outlined">
            <FormHelperText id="outlined-weight-helper-text">
              Min
            </FormHelperText>
            <OutlinedInput
              type="number"
              style={{ width: "160px", maxHeight: "50px" }}
              id="outlined-adornment-weight"
              name="min"
              error={error.min}
              value={values.min}
              onChange={(event) => handleChange(event)}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "min",
              }}
              labelWidth={0}
            />
          </FormControl>
          <FormControl variant="outlined">
            <FormHelperText id="outlined-weight-helper-text">
              Max
            </FormHelperText>
            <OutlinedInput
              style={{
                width: "160px",
                marginBottom: "0.5rem",
                maxHeight: "50px",
              }}
              type="number"
              error={error.max}
              id="outlined-adornment-weight"
              name="max"
              value={values.max}
              onChange={(event) => handleChange(event)}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "max",
              }}
              labelWidth={0}
            />
          </FormControl>
        </div>
      </Fade>
    </div>
  );
};

export default AreaFilter;
