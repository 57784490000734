import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  overscroll-behavior-x: none;
  overflow-x: scroll;
  min-height: 40vh;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
`;

export const TileDetails = styled.div`
  margin-top: 5px;
  font-size: 10px;
  background-color: #183848;
  transition: 450ms opacity;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const NotAvailableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  transition: 450ms all;
`;
export const Icons = styled.svg`
  && {
    display: none;
    color: #183848;
    font-size: 3.8rem;
    transition: 450ms all;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export const IconDelete = styled.svg`
  && {
    display: none;
    color: red;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const Tile = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${(props) => props.marginRight};
  font-size: 20px;
  transition: 450ms all;
  transform-origin: center left;
  border-radius: 10px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};

  &:hover ${TileDetails} {
    opacity: 1;
  }

  &:hover ~ & {
    transform: translate3d(75px, 0, 0);
  }
  &:hover ${NotAvailableContainer} {
    background-color: #ffffffc2;
  }
  &:hover ${Icons} {
    display: block;
  }
  &:hover ${IconDelete} {
    display: block;
  }
`;

export const RowInner = styled.div`
  display: flex;
  transition: 450ms transform;
  font-size: 0;
  margin: 55px 0;
  padding-bottom: 10px;
  padding-left: 15px;

  &:hover {
    transform: translate3d(-10px, 0, 0);
  }

  /* &:hover ${Tile} {
    opacity: 0.3;
  } */

  &:hover ${Tile}:hover {
    transform: scale(1.2);
    opacity: 1;
  }
`;

export const TileMedia = styled.div`
  width: ${(props) => props.width};
  border: ${(props) => props.border};
`;

export const Media = styled.img`
  -o-object-fit: cover;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: ${(props) => props.width};
  border: ${(props) => props.border};
`;

export const TileTitle = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 900;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
`;
