import React from "react";

const MenuExterneLink = (props) => {
  const { Component, href, title } = props;
  return (
    <a
      aria-current="page"
      style={{ color: "rgba(0, 0, 0, 0.54)" }}
      target={"target_blank"}
      className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink-root-31 MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button RaMenuItemLink-active-32"
      tabIndex="-1"
      role="menuitem"
      aria-disabled="false"
      href={href}
    >
      <div
        className="MuiListItemIcon-root RaMenuItemLink-icon-33"
        style={{ minWidth: "40px" }}
      >
        <Component />
        <title>{title}</title>
      </div>
      {title}
      <span className="MuiTouchRipple-root"></span>
    </a>
  );
};

export default MenuExterneLink;
