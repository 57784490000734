import { useEffect, useState } from "react";
import {
  getAllAgencies,
  getAllProject,
  getAllUsers,
} from "../Utils/axiosRequests";

const useDataAdmin = () => {
  const [state, setState] = useState({
    loading: true,
    projects: 0,
    agencies: 0,
    users: 0,
    viite360: 0,
  });

  const getData = async () => {
    const allProjects = await getAllProject();
    const allAgencies = await getAllAgencies();
    const allUsers = await getAllUsers();

    const allScenes = allProjects.data.data.filter((item) => {
      if (item.scenes && item.scenes.length) {
        return item;
      } else if (item.scenes && item.scenes.scenes) {
        return item;
      } else {
        return null;
      }
    });
    setState({
      projects: allProjects.data.data.length,
      agencies: allAgencies.data.length,
      users: allUsers.data.length,
      viite360: allScenes.length,
      loading: false,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return state;
};

export default useDataAdmin;
