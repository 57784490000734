import React from "react";
import View from "./Components/View";
import { useGetIdentity } from "ra-core";

const Data = (props) => {
  const { loading: identityLoading } = useGetIdentity();
  // const role = ["super_admin", "admin"];
  if (identityLoading) return <>Loading...</>;
  return <View {...props} />;
};

export default Data;
