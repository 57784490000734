/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { FormWithRedirect, useTranslate } from "react-admin";

// DATA FOR EACH SELECT
import { choices } from "../../Utils/dataChoices";
// THEME CSS
import { NavBar } from "../../Utils/Styles/ProjectList.styles";
import InfoGeneral from "./ProjectFormItems/InfoGeneral";
import FinancialsData from "./ProjectFormItems/FinancialsData";
import formWrapper from "./formWrapper";
import OtherInformations from "./ProjectFormItems/OtherInformations";
import Medias from "./ProjectFormItems/Medias";
import Comments from "./ProjectFormItems/Comments";
import Seo from "./ProjectFormItems/Seo";
import ToolbarForm from "./ProjectFormItems/ToolbarForm";

import InfoIcon from "@material-ui/icons/Info";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import WebIcon from "@material-ui/icons/Web";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import Loader from "../../../../Loader/Loader";
import { Fade } from "@material-ui/core";
import Statistics from "./ProjectFormItems/Statistics/Statistics";
import Files from "./ProjectFormItems/Files";
import { useContext } from "react";
import { UserContext } from "../../../../../store/UserProvider";
import Links360 from "./ProjectFormItems/360links";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    paddingBottom: "10px",
  },
}));

function ScrollableTabsButtonAuto(props) {
  const translate = useTranslate();
  const {
    enableSaveAddress,
    initialValues,
    handleChangeMetaTitle,
    handleChangeMetaDescription,
    handleChangeSlug,
    user,
    agency,
  } = props;
  const [ads, setAds] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const { user: userOrigin } = useContext(UserContext);

  useEffect(() => {
    const isValid = ["active", "waiting", "trial"];
    if (props.user) {
      if (
        props.user.permissions === "admin" ||
        props.user.permissions === "super_admin" ||
        props.user.permissions === "manager"
      ) {
        setIsAuth(true);
      }
      const role = ["super_admin", "admin"];
      if (
        role.includes(props.user.permissions) ||
        (props.agency &&
          props.agency.abo &&
          props.agency.abo.ads &&
          isValid.includes(props.agency.abo.ads.state))
      ) {
        setAds(true);
      }
    }
  }, [props.agency, props.user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormWithRedirect
      {...props}
      redirect="list"
      // theme={THEME}
      render={(formProps) => {
        if (!loading) {
          return (
            <Fade in={true} timeout={300}>
              <div className={classes.root}>
                <NavBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      label={translate(
                        "resources.projects.tabform.informations"
                      ).toUpperCase()}
                      icon={<InfoIcon />}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={translate(
                        "resources.projects.tabform.financialdatas"
                      ).toUpperCase()}
                      icon={<MonetizationOnIcon />}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={translate(
                        "resources.projects.tabform.other"
                      ).toUpperCase()}
                      icon={<NoteAddIcon />}
                      {...a11yProps(2)}
                    />
                    <Tab
                      label={translate(
                        "resources.projects.tabform.media"
                      ).toUpperCase()}
                      icon={<PhotoLibraryIcon />}
                      {...a11yProps(3)}
                    />
                    {ads && (
                      <Tab
                        label={translate(
                          "resources.projects.tabform.files"
                        ).toUpperCase()}
                        icon={<PhotoLibraryIcon />}
                        {...a11yProps(4)}
                      />
                    )}
                    <Tab
                      label={translate(
                        "resources.projects.tabform.descriptions"
                      ).toUpperCase()}
                      icon={<ModeCommentIcon />}
                      {...a11yProps(5)}
                    />
                    {ads && (
                      <Tab label="SEO" icon={<WebIcon />} {...a11yProps(6)} />
                    )}
                    {formProps.record.id && ads && (
                      <Tab
                        label={translate(
                          "resources.projects.tabform.statistics"
                        ).toUpperCase()}
                        icon={<EqualizerIcon />}
                        {...a11yProps(7)}
                      />
                    )}
                    {userOrigin && userOrigin.permissions.includes("admin") && (
                      <Tab
                        label={"360".toUpperCase()}
                        icon={<PhotoLibraryIcon />}
                        {...a11yProps(8)}
                      />
                    )}
                  </Tabs>
                </NavBar>
                {/* INFORMATIONS GENERALES */}
                <TabPanel value={value} index={0}>
                  <InfoGeneral
                    {...props}
                    choices={choices}
                    ads={ads}
                    formProps={formProps}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <FinancialsData {...props} choices={choices} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <OtherInformations {...props} choices={choices} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Medias {...props} choices={choices} ads={ads} />
                </TabPanel>
                <TabPanel value={value} index={ads ? 4 : 5}>
                  <Files {...props} choices={choices} />
                </TabPanel>
                <TabPanel value={value} index={ads ? 4 : 5}>
                  <Files {...props} choices={choices} />
                </TabPanel>
                <TabPanel value={value} index={ads ? 5 : 4}>
                  <Comments {...props} choices={choices} />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <Seo
                    {...props}
                    formProps={formProps}
                    initialValues={initialValues}
                    handleChangeMetaTitle={handleChangeMetaTitle}
                    handleChangeMetaDescription={handleChangeMetaDescription}
                    handleChangeSlug={handleChangeSlug}
                    user={user}
                  />
                </TabPanel>
                {formProps.record.id && (
                  <TabPanel value={value} index={7}>
                    <Statistics {...props} {...formProps} />
                  </TabPanel>
                )}
                <TabPanel value={value} index={8}>
                  <Links360 {...props} {...formProps} />
                </TabPanel>
                <ToolbarForm
                  initialValues={initialValues}
                  enableSaveAddress={enableSaveAddress}
                  formProps={formProps}
                  setLoading={setLoading}
                  user={user}
                  agency={agency}
                  ads={ads}
                  {...props}
                />
              </div>
            </Fade>
          );
        } else {
          return <Loader />;
        }
      }}
    />
  );
}

export default formWrapper(ScrollableTabsButtonAuto);
