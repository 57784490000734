import React from "react";
import CookieConsent from "react-cookie-consent";
import consentFR from "../../assets/consentFR.pdf";
import consentEN from "../../assets/consentEN.pdf";
import { colorPrimary } from "../../GlobalStyles";
const CookieComponent = ({ translate, i18n }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText={translate("cookie.accept").toUpperCase()}
      cookieName="consent"
      style={{
        background: "rgb(43 55 59 / 90%)",
        alignItems: "center",
        padding: "1.5rem",
      }}
      buttonStyle={{
        color: "white",
        fontSize: "14px",
        backgroundColor: colorPrimary,
        borderRadius: "10px",
        padding: "8px 18px",
        fontWeight: 900,
        marginRight: "75px",
      }}
    >
      <div style={{ fontSize: "14px" }}>
        {translate("cookie.text")}{" "}
        <a
          href={i18n.language === "fr-FR" ? consentFR : consentEN}
          target="target_blank"
        >
          {translate("cookie.consult")}
        </a>
      </div>
    </CookieConsent>
  );
};

export default CookieComponent;
