import React from "react";
import BerlinTemplateLandscape from "./BerlinTemplateLandscape/BerlinTemplateLandscape";
import BerlinTemplatePortrait from "./BerlinTemplatePortrait/BerlinTemplatePortrait";

const index = React.forwardRef(
  ({ isMini, isPreview, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <BerlinTemplateLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    } else {
      return (
        <BerlinTemplatePortrait
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
