import styled, { keyframes } from "styled-components";
import {
  Button,
  Divider,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { colorPrimary } from "../../../../GlobalStyles";

export const UrlInputText = styled(TextField)`
  && {
    .MuiFormLabel-root.Mui-disabled {
      color: initial;
    }
    .MuiInputBase-root.Mui-disabled {
      color: initial;
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: solid;
    }
  }
`;
export const DateInputText = styled(TextField)`
  && {
    .MuiFormLabel-root.Mui-disabled {
      color: initial;
    }
    .MuiInputBase-root.Mui-disabled {
      color: initial;
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: solid;
    }
  }
`;
export const SelectUser = styled(Select)`
  && {
    .MuiSelect-select.MuiSelect-select {
      padding-right: 5px;
    }
  }
`;
export const ShowIcon = styled(VisibilityIcon)`
  && {
    color: ${colorPrimary};
    cursor: pointer;
    font-size: ${(props) => (props.$size === "true" ? "1.1rem" : "1.5rem")};
    margin-right: ${(props) => (props.$right === "true" ? "3px" : "0")};
  }
`;
export const ColorCube = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1px #c4c4c4;
  background-color: ${(props) => props.color} !important;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

const bounceAnimation = keyframes`
 0% { transform: translateY(-2px) }
 50% { transform: translateY(2px) }
 100% { transform: translateY(-2px) }
`;

export const AboContainer = styled.div`
  position: relative;
  background-color: white;
  padding-bottom: 1rem;
  border-radius: 10px;
  max-height: 500px;
  height: 100%;
  margin: 1rem 0 0;
  min-width: 256.5px;
  max-width: 320px;
  padding: 40px 26px;
  box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  border-radius: 20px 20px 20px 20px;
  transition: all 0.4s ease;
  animation-duration: 1.5s;
  animation-delay: 0.3s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: normal, alternate;

  &:hover {
    box-shadow: 0 18px 38px rgb(0 0 0 / 10%), 0 7px 15px rgb(0 0 0 / 10%);
    animation-name: ${bounceAnimation};
    border-style: solid;
    border-width: 0px 0px 5px 0px;
    border-color: ${(props) => props.color};
  }

  @media screen and (max-width: 900px) {
    max-width: 500px;
  }
  @media screen and (max-width: 600px) {
    max-width: 400px;
  }
`;

export const AboTitleContainer = styled.div`
  && {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: ${(props) => props.background} !important;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
`;
export const AboDescContainer = styled.div`
  && {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 1rem 0 1rem;
    background-color: ${(props) => props.background} !important;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
`;
export const AboTitle = styled.p`
  color: white;
  text-align: center;
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 800;
`;
export const InfoIcon = styled.svg`
  && {
    position: absolute;
    right: 15px;
    margin-top: 1px;
    font-size: 22px;
    color: white;
    cursor: pointer;
  }
`;

export const StateAboContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  border-radius: 10px;
`;

export const StateIcon = styled.svg`
  && {
    font-size: 16px;
    margin-top: 0.2188em;
    color: ${(props) => props.mycolor};
  }
`;
export const StateText = styled.p`
  margin-bottom: 0;
  font-size: 1rem;
  margin-left: 5px;
  color: #34495ed9;
  font-weight: 500;
`;

export const ListItemContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CheckedIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

export const CheckedIcon = styled.svg`
  && {
    color: ${(props) => props.mycolor};
    cursor: ${(props) => (props.pointer ? "pointer" : "initial")};
  }
`;

export const ExpandContainer = styled.div`
  padding: ${(props) => (props.nopadding ? "0" : "1rem 0")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const ExpandIcon = styled.svg`
  && {
    color: #34495ed9;
    transform: ${(props) => (props.rotate ? "rotate(180deg)" : "initial")};
    cursor: pointer;
  }
`;

export const ExpandText = styled.p`
  margin-bottom: 0.5rem;
  font-size: ${(props) => props.size};
  color: #34495ed9;
`;
export const EngagementContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;
export const EngagementItemContainer = styled.div`
  border: solid 1px
    ${(props) => (props.selected ? "rgb(22, 209, 209)" : "#34495ed9")};
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin-right: 5px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? "rgb(22, 209, 209) !important" : "transparent"};
`;
export const EngagementText = styled.p`
  margin-bottom: 0;
  font-size: 1rem;
  color: ${(props) => (props.selected ? "white" : "#34495ed9")};
`;

export const ManageBtn = styled(Button)`
  && {
    background-color: ${(props) =>
      props.active === "true" ? "rgba(0, 0, 0, 0.04)" : props.background};
    color: ${(props) => (props.active === "true" ? props.background : "white")};
    border-radius: 9px;
    min-width: 85px;
    padding: 8px 16px;
    margin-right: 0;
    font-size: 13px;

    &:hover {
      color: white;
      background-color: ${(props) => props.background};
    }
  }
`;
export const SidebarVisibble = styled(Button)`
  && {
    display: inline-flex;
    align-items: center;
    padding: 4px 5px;
    font-size: 0.8125rem;
    color: ${colorPrimary};
    line-height: 1.75;
    border-radius: 20px;
    letter-spacing: 0.02857em;
  }
`;
export const HrDivider = styled(Divider)`
  && {
    margin-bottom: 10px;
  }
`;
export const TitleSection = styled(Typography)`
  && {
    text-transform: ${(props) =>
      props.$uppercase === "true" ? "uppercase" : "initial"};
    font-size: ${(props) => (props.$size === "true" ? "16px" : "20px")};
  }
`;
