import styled from "styled-components";
import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Input,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { DeleteButton, EditButton, SaveButton } from "react-admin";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import ThreeSixtyIcon from "@material-ui/icons/ThreeSixty";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import GridOnIcon from "@material-ui/icons/GridOn";
import KingBedIcon from "@material-ui/icons/KingBed";
import AppBar from "@material-ui/core/AppBar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CreateIcon from "@material-ui/icons/Create";
import SearchIcon from "@material-ui/icons/Search";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import MouseIcon from "@material-ui/icons/Mouse";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { colorPrimary, colorTertiary } from "../../../../../GlobalStyles";

// PROJECT LIST

export const ContainerProjectList = styled.div``;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: ${({ matches }) => (matches ? "column" : "initial")};
`;

export const SearchInput = styled(Input)`
  && {
    padding: 0.5rem;
  }
`;

export const SearchInputIcon = styled(SearchIcon)`
  && {
    color: rgba(0, 0, 0, 0.26);
  }
`;

export const SibarFilter = styled.div`
  order: -1;
  width: ${({ matches }) => (matches ? "100%" : "15em")};
  margin-right: 1em;
  overflow: hidden;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: white;
  padding: 1.5rem;
  display: flex;
  flex-direction: ${({ matches }) => (matches ? "initial" : "column")};
  justify-content: ${({ matches }) => (matches ? "center" : "false")};
`;

export const ToolBarContainer = styled(Toolbar)`
  && {
    max-width: 915px;
    margin-left: auto;
    display: flex;
    padding-left: 0;
    align-items: center;
    padding-right: 0;
    justify-content: flex-end;
    min-height: 70px;
  }
`;

export const ToolBarAction = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 8px;
  justify-content: flex-end;
`;

export const ListPage = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => (props.modeView ? "0" : "2rem")};
  background-color: ${(props) => (props.modeView ? "transparent" : "white")};
  border-radius: 4px;
  flex: 1 1 auto;
  overflow: inherit;
  position: relative;
  box-shadow: ${(props) =>
    props.modeView
      ? "none"
      : "0px 2px 1px -1px rgb(0 0 0 / 20%),0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-gap: 30px;
  background-color: transparent;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-items: center;
  justify-content: center;

  @media screen and (min-width: 1100px) {
    grid-template-columns: repeat(
      auto-fill,
      ${({ open }) => (open ? "380px" : "330px")}
    );
  }
  @media screen and (max-width: 998px) {
    grid-template-columns: repeat(auto-fill, 370px);
  }
`;
export const CardContainer = styled(Card)`
  && {
    transition: all 0.3s ease;
    width: 350px;

    @media screen and (min-width: 1100px) {
      width: ${({ open }) => (open ? "380px" : "330px")};
    }
    @media screen and (max-width: 998px) {
      width: 370px;
    }
  }
`;
export const CardContentImage = styled(CardContent)`
  && {
    padding: unset;
    position: relative;
    min-height: 240px;
  }
`;
export const Image = styled.img`
width: 100%;
line-height: 2;
position: relative;
transition: 0.2s ease;
height: 240px;
filter: brightness(70%);
object-fit: cover;
--o-object-fit: cover
transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

&:hover {
filter: brightness(100%);
}
`;
export const ContainerVisibble360 = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;
export const LinkImage = styled.a``;
export const TagContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  left: 0;
  margin-bottom: 7px;
  width: 100%;
`;
export const StateContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 0;
  margin-right: 8px;
`;
export const LikeContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  width: 100%;
  min-height: 80px;
`;
export const InfoContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  min-height: 65px;
  background-color: #34495e;
`;
export const ImageTagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #34495e;
  color: white;
  padding: 4px 0.4rem;
`;

export const LikeTagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 4px 0.4rem;
`;
export const InfoItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 1px 0.4rem;

  &:before {
    content: "";
    position: absolute;
    height: 25px;
    width: 35%;
    border-right: 1px solid white;
  }
`;
export const VideoTagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #34495e;
  color: white;
  padding: 4px 0.4rem;
  margin-left: 4px;
`;
export const Tag360Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #34495e;
  color: white;
  padding: 4px 0.4rem;
  margin-left: 4px;
`;
export const CameraIcon = styled(PhotoCameraIcon)`
  && {
    font-size: 18px;
    color: white;
  }
`;
export const GridIcon = styled(GridOnIcon)`
  && {
    font-size: 16px;
    color: white;
  }
`;
export const RoomIcon = styled(MeetingRoomIcon)`
  && {
    font-size: 16px;
    color: white;
  }
`;
export const BedIcon = styled(KingBedIcon)`
  && {
    font-size: 16px;
    color: white;
  }
`;
export const Visite360Icon = styled(ThreeSixtyIcon)`
  && {
    font-size: 18px;
    color: white;
  }
`;
export const ViewIcon = styled(VisibilityIcon)`
  && {
    font-size: 22px;
  }
`;
export const ClickIcon = styled(MouseIcon)`
  && {
    font-size: 18px;
  }
`;
export const PhoneUI = styled(PhoneIcon)`
  && {
    color: white;
    font-size: 19px;
  }
`;
export const ClickWhatsapp = styled(WhatsAppIcon)`
  && {
    color: white;
    font-size: 20px;
    margin-left: 1px;
  }
`;

export const StateIcon = styled(Brightness1RoundedIcon)`
  && {
    font-size: 16px;
    color: ${({ mycolor }) =>
      mycolor === "red"
        ? "#CC0000"
        : mycolor === "orange"
        ? "#D57100"
        : mycolor === "black"
        ? "black"
        : "#2fd4a7"};
  }
`;

export const TextTag = styled.span`
  margin-left: 5px;
  font-size: 12px;
  font-weight: initial;
`;
export const TextTagHeart = styled.span`
  margin-right: 3px;
  font-size: 18px;
  font-weight: 600;
`;

export const TextVideoTag = styled.span`
  font-size: 12px;
  font-weight: initial;
`;

export const IconRotation = styled.img`
  font-size: 4rem;
  width: 80px;
  color: white;
`;
export const CardContentInfoContainer = styled(CardContent)`
  && {
    padding: 0;
    line-height: 1;
    text-align: center;

    &:last-child {
      padding-bottom: ${(props) => (props.ads ? "5px" : "0")};
    }
  }
`;
export const CardContentTypeAndPrice = styled(CardContent)`
  && {
    padding: 10px 10px 0 10px;
    display: flex;
    justify-content: space-between;
  }
`;
export const PriceText = styled.p`
  margin-bottom: unset;
`;
export const CardContentArea = styled(CardContent)`
  && {
    padding: 0 10px 0 10px;
  }
`;
export const AreaText = styled.p`
  font-size: 14px;
  letter-spacing: 2px;
  line-height: unset;
`;
export const CardContentPlace = styled(CardContent)`
  && {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 80px;
  }
`;
export const SpanCityText = styled.span`
  font-size: 18px;
  line-height: unset;
  color: #00b9ff;
`;
export const CityTextBold = styled.b`
  font-weight: 400;
`;
export const SpanLocationText = styled.span`
  font-size: 15px;
  line-height: unset;
  font-weight: 400;
  padding-top: 5px;
`;
export const SpanPriceText = styled.span`
  font-size: 18px;
  line-height: unset;
  font-weight: 400;
  padding-top: 5px;
`;
export const SpanPriceTextMonth = styled.span`
  font-size: 12px;
  line-height: unset;
  font-weight: 400;
  padding-left: 4px;
  color: #000000a1;
`;
export const CardActionContainer = styled(CardActions)`
  && {
    text-align: right;
    justify-content: center;
    padding: ${(props) => (!props.ads ? "8px 0 0" : "8px 0")};
  }
`;
export const EditBtn = styled(EditButton)`
  && {
    background-color: ${colorPrimary};
    color: white;
    border-radius: 10px;
    font-size: 0.875rem;
    padding: 6px 16px !important;
    margin-left: 0 !important;
  }
`;
export const Btn = styled(Button)`
  && {
    background-color: ${colorTertiary};
    color: white;
    border-radius: 10px;
    margin-right: 10px;
    min-width: ${(props) => (props.minwidth ? "85px" : "100px")};
    padding: ${(props) => (props.padding ? "4px 8px" : "6px 16px")};
  }
`;
export const Btn360 = styled(Button)`
  && {
    background-color: ${colorTertiary};
    color: white;
    border-radius: 10px;
    margin-right: 10px;
  }
`;
export const Btn360SmallRow = styled(Button)`
  && {
    background-color: ${colorTertiary};
    color: white;
    margin-right: 10px;
    border-radius: 0;
    padding: 12px 16px;
  }
`;
export const BtnEdit = styled(Button)`
  && {
    background-color: ${colorPrimary};
    color: white;
    border-radius: ${(props) => (!props.ads ? "0" : "10px")};
    margin-bottom: 0;
    margin-left: 0 !important;
    margin-right: 0px;
    width: ${(props) => (!props.ads ? "100%" : "initial")};
    height: ${(props) => (!props.ads ? "48px" : "initial")};
  }
`;
export const BtnEditSmallRow = styled(Button)`
  && {
    background-color: ${colorPrimary};
    color: white;
    margin-bottom: 0;
    margin-left: 0 !important;
    margin-right: 0px;
    width: 100%;
    height: 48px;
    border-radius: 0;
  }
`;

export const SpanBtnEditText = styled.span`
  padding-left: 0.5em;
`;

export const PencilIcon = styled(CreateIcon)`
  && {
    font-size: 20px;
  }
`;

export const MoreItems = styled(Button)`
  && {
    background-color: ${colorPrimary};
    color: white;
    margin: auto;

    &:focus {
      outline: none;
    }
  }
`;
export const TextBtnMoreItems = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

export const LoadingCircle = styled(CircularProgress)`
  color: white;
  align-self: center;
  width: 25px;
  height: 25px;
`;

export const NoResultTypo = styled(Typography)`
  && {
    margin: 2rem;
  }
`;

// PROJECT FORM

export const NavBar = styled(AppBar)`
  && {
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
      0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background-color: white;
  }
`;

export const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  grid-gap: 20px;
  background-color: transparent;
  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-items: center;
`;

export const InsideInput = styled.span`
  margin-top: 18px;
  font-weight: inherit;
  font-family: inherit;
`;

export const InsideInputSlug = styled.span`
  font-weight: inherit;
  font-family: inherit;
  min-width: 365px;
`;

export const ButtonDelete3D = styled(SaveButton)`
  && {
    background-color: white;
    color: #f44336;
    padding: 6px 10px;
    font-size: 0.8125rem;
    box-shadow: unset;

    &:hover {
      background-color: rgba(244, 67, 54, 0.12);
      color: ${colorPrimary};
      box-shadow: unset;
    }
  }
`;
export const DeleteBtn = styled(DeleteButton)`
  && {
    padding: 6px 10px;
  }
`;
// META PREVIEW

export const MetaDescriptionContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  background-color: white;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2),
    0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;
export const GoogleTitleContainer = styled.div`
  width: 100%;
`;

export const GoogleTitle = styled.h3`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.2rem;
`;
export const MetaTitle = styled.h1`
  font-size: 1.4rem;
  color: #1a0dab;
`;
export const MetaLink = styled.h1`
  font-size: 1.1rem;
  color: ${colorPrimary};
`;
export const MetaDescription = styled.h1`
  font-size: 1.1rem;
  font-weight: 400;
  color: #929ca9;
`;

export const ShowGoogleBtn = styled(Button)`
  && {
    background-color: #00b9ff;
    color: white;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

// FILTERS

export const DeleteFilterButtonContainer = styled.div`
  margin-top: 1.6rem;
`;

export const DeleteFilterBtn = styled(Button)`
  && {
    color: red;
    padding: 0;
    min-width: unset;

    &:hover {
      color: red;
      background-color: transparent;
    }
  }
`;
export const DeleteProjectsBtn = styled(Button)`
  && {
    color: red;
    padding: 4px 5px;
    min-width: unset;
    font-size: 0.8125rem;

    &:hover {
      color: red;
      background-color: transparent;
    }
  }
`;

export const ModeView = styled.svg`
  color: #34495e;
  margin-left: ${(props) => (props.left ? "5px" : "0")};
  cursor: pointer;
`;

export const ShowIcon = styled(VisibilityIcon)`
  && {
    color: ${colorPrimary};
    cursor: pointer;
  }
`;
