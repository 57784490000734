import styled from "styled-components";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { colorPrimary } from "../../../../../GlobalStyles";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
export const ContainerStepFour = styled.div``;

export const ContainerAll = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  @media screen and (max-width: 1409px) {
    justify-content: inherit;
  }
`;

export const TitleSection = styled.h5`
  margin: ${(props) => props.margin};
  font-weight: 900;
  text-align: center;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "initial")};
  margin-bottom: 0;
`;
export const MirrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  max-width: 500px;
`;
export const ContainerEdit = styled.div`
  margin: 2rem auto;
  max-height: ${(props) => props.maxHeight};
  max-width: 620px;
  min-width: 450px;
  overflow-y: ${(props) => props.overflow};
  padding: 1rem;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${colorPrimary};
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colorPrimary};
  }
  ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 0px;
    box-shadow: inset 7px 10px 12px #f0f0f0;
  }
  @media screen and (max-width: ${(props) => props.breakWidth + "px"}) {
    margin-top: 4rem;
    max-height: unset;
    overflow: initial;
  }
`;
export const ColorCube = styled.div`
  width: 20px;
  height: 20px;
  border: solid 1px #c4c4c4;
  background-color: ${(props) => props.color};
  margin-left: 15px;
  border-radius: 4px;
  cursor: pointer;
`;

export const LineBreakIcon = styled(KeyboardReturnIcon)`
  color: black;
  margin-left: 15px;
  margin-top: 10px;
  cursor: pointer;
`;
export const FontIcon = styled(FontDownloadIcon)`
  && {
    margin-left: ${(props) => (props.margin ? "13px" : "5px")};
    margin-top: ${(props) => (props.margin ? "10px" : "0")};
    cursor: pointer;
  }
`;
