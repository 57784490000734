import styled from "styled-components";

export const ToolsContainer = styled.div`
  display: flex;
  height: 100vh;
  max-height: ${(props) => (props.isMobile ? "91vh" : "unset")};
  height: -webkit-fill-available;
  opacity: 1;
  transition-delay: 0.2s;
  transition: opacity 0.1s ease;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
`;

export const PanoControllerContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
