import React from "react";
import { useSelector } from "react-redux";

// IMPORT TEMPLATE INDEX
import LondresTemplate from "../../TemplatePrint/Londres";
import ParisTemplate from "../../TemplatePrint/Paris";
import BarceloneTemplate from "../../TemplatePrint/Barcelone";
import BerlinTemplate from "../../TemplatePrint/Berlin";
import CuppertinoTemplate from "../../TemplatePrint/Cuppertino";
import SingapourTemplate from "../../TemplatePrint/Singapour";
import SydneyTemplate from "../../TemplatePrint/Sydney";
import PersoTemplate from "../../TemplatePrint/Perso";
import BersotTemplate from "../../TemplatePrint/BersoImmo";
import ElfiTemplate from "../../TemplatePrint/Elfi";
import GosselinTemplate from "../../TemplatePrint/Gosselin";
import LoftAndHouseTemplate from "../../TemplatePrint/LoftAndHouse";
import IphTemplate from "../../TemplatePrint/Iph";
import IphTemplateSecond from "../../TemplatePrint/IphSecond";
import MadridTemplate from "../../TemplatePrint/Madrid";
import SapeneTemplate from "../../TemplatePrint/Sapene";
import {
  ContainerAll,
  ContainerEdit,
  ContainerStepFour,
  MirrorContainer,
  TitleSection,
} from "./Styles/StepFour.styles";
import Content from "./Content";
import ColorPrimaryComponent from "./Items/ColorPrimaryComponent";
import ColorSecondaryComponent from "./Items/ColorSecondaryComponent";
import { FormControlLabel, Switch } from "@material-ui/core";
import { ModeButton } from "../Styles/ModalPrint.styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import ColorBannerComponent from "./Items/ColorBannerComponent";
import { choices } from "../Utils/dataChoices";
import { useTemplatesStore } from "../../../../store/TemplatesStore";

// CALL THE GOOD TEMPLATE INDEX FOR PREVIEW
const Template = () => {
  const template = useTemplatesStore();

  switch (template.model) {
    case "Londres":
      return <LondresTemplate isPreview={true} {...template} />;
    case "Paris":
      return <ParisTemplate {...template} isPreview={true} />;
    case "Barcelone":
      return <BarceloneTemplate {...template} isPreview={true} />;
    case "Berlin":
      return <BerlinTemplate {...template} isPreview={true} />;
    case "Cupertino":
      return <CuppertinoTemplate {...template} isPreview={true} />;
    case "Singapour":
      return <SingapourTemplate {...template} isPreview={true} />;
    case "Perso":
      return <PersoTemplate {...template} isPreview={true} />;
    case "Bersot Immobilier":
      return <BersotTemplate {...template} isPreview={true} />;
    case "Elfi":
      return <ElfiTemplate {...template} isPreview={true} />;
    case "Osenat":
      return <GosselinTemplate {...template} isPreview={true} />;
    case "Sydney":
      return <SydneyTemplate {...template} isPreview={true} />;
    case "Loft And House":
      return <LoftAndHouseTemplate {...template} isPreview={true} />;
    case "IPH":
      return <IphTemplate {...template} isPreview={true} />;
    case "IPH Second":
      return <IphTemplateSecond {...template} isPreview={true} />;
    case "Madrid":
      return <MadridTemplate {...template} isPreview={true} />;
    case "Sapene":
      return <SapeneTemplate {...template} isPreview={true} />;
    default:
      return null;
  }
};

const StepFour = () => {
  const { t } = useTranslation();
  const {
    isSaved,
    project,
    setIsBanner,
    setBannerSecond,
    setIsBannerSecond,
    model,
    categoryText,
    setBanner,
    setMirror,
    orientation,
    recordTemplate,
    udpateTemplate,
    mirror,
  } = useTemplatesStore();
  const exludesPrimary = [
    "Bersot Immobilier",
    "Roland Gosselin & Associés",
    "Loft And House",
    "IPH",
    "IPH Second",
  ];
  const exludesSecondary = [
    "Bersot Immobilier",
    "Roland Gosselin & Associés",
    "Singapour",
    "Paris",
    "Loft And House",
    "IPH",
    "IPH Second",
    "Madrid",
    "Sapene",
  ];
  const includesColorBanner = ["Roland Gosselin & Associés", "Loft And House"];

  const [breakWidth, setBreakWidth] = useState(1352);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    if (open) {
      setBreakWidth(1400);
    } else {
      setBreakWidth(1352);
    }
    if (project.agreement === choices.agreement[2].id) {
      setBanner(t("details.modalprint.exclu"));
      setIsBanner("true");
    }
    if (model === "Sapene") {
      setBanner(categoryText);
      setIsBanner("true");
      setBannerSecond(project.city);
      setIsBannerSecond("true");
    }
  }, [
    categoryText,
    model,
    open,
    project.agreement,
    project.city,
    setBanner,
    setBannerSecond,
    setIsBanner,
    setIsBannerSecond,
    t,
  ]);

  const getMargin = () => {
    if (open && orientation === "landscape") {
      return true;
    } else {
      return false;
    }
  };

  const onChangeMirror = (event) => {
    setMirror(event.target.checked);
  };

  return (
    <ContainerStepFour orientation={orientation}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <ModeButton onClick={() => recordTemplate()}>
          {t("common.saveasnew")}
        </ModeButton>
        {isSaved && (
          <ModeButton onClick={() => udpateTemplate()}>
            {t("common.savechanges")}
          </ModeButton>
        )}
      </div>
      <ContainerAll>
        <Template />
        <ContainerEdit
          breakWidth={breakWidth}
          maxHeight={orientation === "landscape" ? "480px" : "unset"}
          overflow={orientation === "landscape" ? "scroll" : "hidden"}
          marginTop={getMargin()}
        >
          {!exludesPrimary.includes(model) && <ColorPrimaryComponent />}
          {includesColorBanner.includes(model) && <ColorBannerComponent />}
          {!exludesSecondary.includes(model) && <ColorSecondaryComponent />}
          <TitleSection margin={0}>
            {t("modalprint.content").toUpperCase()}
          </TitleSection>
          <Content />
          <MirrorContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={mirror}
                  onChange={onChangeMirror}
                  color="primary"
                />
              }
              label={t("modalprint.mirroreffect")}
            />
          </MirrorContainer>
        </ContainerEdit>
      </ContainerAll>
    </ContainerStepFour>
  );
};

export default StepFour;
