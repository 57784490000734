import styled from "styled-components";
import { colorPrimary } from "../../../../../GlobalStyles";
export const MenuContainer = styled.div`
  position: absolute;
  bottom: ${(props) => (props.open ? "0" : "-152px")};
  left: 0;
  right: 0;
  transition-delay: 200ms;
  transition: bottom 400ms ease-in-out;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 500;
`;

export const MenuContainerInner = styled.div`
  height: 152px;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
`;

export const MenuTopShape = styled.div`
  position: absolute;
  top: -39px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 138px;
  height: 40px;
  background-image: url(${(props) => props.src});
  cursor: pointer;
`;

export const OpenMenuIcon = styled.svg`
  && {
    position: absolute;
    top: -10%;
    left: 32%;
    color: white;
    font-size: 3rem;
    transform: ${(props) => (props.open ? "rotate(0)" : "rotate(180deg)")};
    transition-delay: 200ms;
    transition: transform 400ms ease-in-out;
  }
`;

export const MenuLinks = styled.div`
  transform: translateX(0px);
  user-select: none;
  height: 100%;
  transition-delay: 200ms;
  transition: transform 400ms ease-in-out;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
`;
export const MenuLinksInner = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 90%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b3afb3;
  }
  &::-webkit-scrollbar-track {
    background: #000000;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }

  @media screen and (min-width: 1301px) {
    justify-content: ${(props) =>
      props.center <= 4 ? "center" : "flex-start"};
  }
  @media screen and (max-width: 1300px) {
    justify-content: ${(props) =>
      props.center <= 2 ? "center" : "flex-start"};
  }
  @media screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`;
export const MenuLinkItem = styled.div`
  position: relative;
  min-width: 260px;
  height: 130px;
  margin: 5px;
  border-radius: 6px;
  border: ${(props) => (props.isActive ? "1.5px solid #a4a2a2" : null)};
  @media screen and (max-width: 945px) {
    min-width: 210px;
  }
`;
export const MenuLinkItemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  transform: ${(props) => (props.open ? "scaleX(1)" : "scaleX(O)")};
  transition-delay: 200ms;
  transition: transform 400ms ease-in-out;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
`;

export const IconType = styled.svg`
  && {
    color: white;
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translate(-50%);
  }
`;
export const SceneNameContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, 50%);
  min-width: 100px;
  border-radius: 50px;
  background-color: ${colorPrimary}42;
  border: solid 1px ${colorPrimary};
  padding: 0.3rem;
  transition-delay: 100ms;
  transition: all 300ms ease-in-out;
  overflow: hidden;
`;

export const SceneNameText = styled.h2`
  font-size: 16px;
  color: white;
  font-weight: 500;
  text-align: center;
  outline: 0px solid transparent;
  margin: 0;
  flex: 2;
  line-height: 1;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
`;
