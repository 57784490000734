import fr from "./fr.json";
import en from "./en.json";
import es from "./es.json";
import it from "./it.json";
import pt from "./pt.json";
import de from "./de.json";
import nl from "./nl.json";
import notaire from "./notaireFR.json";
const enumLang = {
  fr: fr,
  en: en,
  es: es,
  it: it,
  pt: pt,
  de: de,
  nl: nl,
  notaire: notaire,
};
export default enumLang;
