/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { CreateButton, useTranslate, Button } from "react-admin";
import { useAlert } from "react-alert";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import {
  DeleteProjectsBtn,
  ToolBarAction,
  ToolBarContainer,
  ModeView,
} from "../../../Utils/Styles/ProjectList.styles";
import { deleteFolderContents } from "../../../../../../firebase/firebase.function";
import { deleteProject } from "../../../Utils/axiosRequest";
import Modal from "./Modal";
import {
  UiContext,
  UserChangeContext,
  UserContext,
} from "../../../../../../store/UserProvider";
import BallotIcon from "@material-ui/icons/Ballot";
import AppsIcon from "@material-ui/icons/Apps";
import { colorPrimary } from "../../../../../../GlobalStyles";

const InfoNbProjects = ({ data, translate }) => {
  if (data > 1) {
    return (
      <span>
        {data} {translate("resources.dashboard.admin.mandate") + "s"}{" "}
        <span style={{ color: colorPrimary }}>Visibble</span>
      </span>
    );
  } else {
    return (
      <span>
        {data} {translate("resources.dashboard.admin.mandate")}{" "}
        <span style={{ color: colorPrimary }}>Visibble</span>
      </span>
    );
  }
};

const Toolbar = (props) => {
  const translate = useTranslate();
  const alert = useAlert();
  const [modalConfirmDelete, setModalConfirmDelete] = useState();
  const [number, setNumber] = useState(0);
  const { ui, setUi } = useContext(UiContext);
  const { user } = useContext(UserContext);
  const { userChange } = useContext(UserChangeContext);
  const [selected, setSelected] = useState({ user: user });

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (userChange) {
        setSelected({ user: userChange });
      }
      setNumber(props.dataToShow.length);
    }
    return () => {
      isMounted = false;
    };
  }, [props.dataToShow, userChange]);

  const role = ["super_admin", "admin"];

  const deleteMany = async (data) => {
    setModalConfirmDelete(false);
    props.setLoading(true);
    const selectIds = data.map(async (item) => {
      await deleteFolderContents("projects/" + item._id);
      await deleteProject(item._id);
      return item._id;
    });
    return Promise.all(selectIds).then((data) => {
      props.refreshList();
    });
  };

  const loadMandats = async () => {
    props.setLoading(true);
    const data = await props.importMandats(selected.user.agenceId);
    if (data) {
      alert.show(translate("resources.alert.finishImport"));
      props.setLoading(false);
      await props.refreshList();
    }
  };

  const loadMandatsAdmin = async (user) => {
    props.setLoading(true);
    const waitForData = await props.importMandats();
    if (waitForData) {
      if (waitForData.data) {
        props.setChoiceImport(waitForData.data);
        props.setModal(true);
      } else if (waitForData === "notoken") {
        props.setNoRegisterToken(true);
      } else if (waitForData === "invalidToken") {
        props.setInvalidToken(true);
      } else {
        props.setLoading(false);
        await props.refreshList();
      }
    } else {
      props.setLoading(false);
    }
  };

  return (
    <ToolBarContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: "23px",
          paddingBottom: "8px",
          marginRight: "10px",
        }}
      >
        <ModeView
          as={BallotIcon}
          left="true"
          onClick={
            !ui.project.modeView
              ? () =>
                  setUi((prevState) => ({
                    ...prevState,
                    project: {
                      ...prevState.project,
                      modeView: true,
                    },
                  }))
              : null
          }
        />
        <ModeView
          as={AppsIcon}
          left="true"
          onClick={
            ui.project.modeView
              ? () =>
                  setUi((prevState) => ({
                    ...prevState,
                    project: {
                      ...prevState.project,
                      modeView: false,
                    },
                  }))
              : null
          }
        />
      </div>
      <ToolBarAction>
        <div
          style={{
            padding: "0.28rem 5px",
            fontSize: "0.8125rem",
            textTransform: "uppercase",
            lineHeight: 1.75,
            marginRight: "5px",
          }}
        >
          <InfoNbProjects data={number} translate={translate} />
        </div>
        <CreateButton basePath="projects" />
        {
          role.includes(selected.user.permissions) && (
            <Button
              onClick={() => loadMandatsAdmin()}
              label={"resources.projects.importmandates"}
            >
              <ImportExportIcon />
            </Button>
          )
          // : (
          //   <Button
          //     onClick={() => loadMandats()}
          //     label={"resources.projects.importmandates"}
          //   >
          //     <ImportExportIcon />
          //   </Button>
          // )
        }
        {role.includes(user.permissions)
          ? props.data.length !== 0 && (
              <DeleteProjectsBtn
                onClick={() => setModalConfirmDelete(true)}
                disabled={props.loading}
              >
                {translate("resources.projects.deleteall")}
              </DeleteProjectsBtn>
            )
          : null}
        {modalConfirmDelete && (
          <Modal
            deleteMany={deleteMany}
            data={props.data}
            setModal={setModalConfirmDelete}
          />
        )}
      </ToolBarAction>
    </ToolBarContainer>
  );
};

export default Toolbar;
