import React from "react";
import { useTranslation } from "react-i18next";
import {
  BeforeAfter,
  FireworksContainer,
  SmileyIcon,
} from "../Utils/FireWorks.styles";
import { TitleSection } from "../Utils/Offer.styles";

const StepSix = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        minHeight: "30vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <FireworksContainer>
        <BeforeAfter />
        <BeforeAfter after={true} />
      </FireworksContainer>
      <TitleSection color={true} maxWidth={true} align={true} size={true}>
        {t("offer.stepsix")}
      </TitleSection>
      <span style={{ textAlign: "center", marginTop: "1rem" }}>
        <SmileyIcon />
      </span>
    </div>
  );
};

export default StepSix;
