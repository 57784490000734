import { TextField, Button } from "@material-ui/core";
import styled from "styled-components";
import { colorPrimary } from "../../../../../../GlobalStyles";

const noRipple = {
  transition: "none !important",
  animation: "none !important",
};

export const UrlInputText = styled(TextField)`
  && {
    .MuiFormLabel-root.Mui-disabled {
      color: initial;
    }
    .MuiInputBase-root.Mui-disabled {
      color: initial;
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: solid;
    }
  }
`;
export const PrintButton = styled(Button)`
  && {
    &:hover {
      color: ${(props) => (props.noimages === "true" ? "black" : colorPrimary)};
      background-color: ${(props) =>
        props.noimages === "true" ? "unset" : "rgba(0, 0, 0, 0.04)"};
    }
    ${(props) => (props.noimages === "true" ? noRipple : null)}
  }
`;
