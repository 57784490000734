// import { Button, Divider } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import {
  colorPrimary,
  colorSecondary,
  colorTertiary,
} from "../../../../../GlobalStyles";

export const ItemContainer = styled.div`
  position: relative;
  border: ${(props) => (props.noBorder ? "initial" : "1px solid #9b9b9b66")};
  padding: ${(props) => (props.smallPadding ? "1rem 1.5rem" : "1.5rem")};
  border-radius: 10px;
  min-height: ${(props) => props.minHeight};
  transition: all 0.3s ease-in-out;
  box-shadow: ${(props) =>
    props.boxShadow ? "0px 30px 60px 0px rgb(145 152 156 / 9%)" : null};

  &:hover {
    box-shadow: ${(props) =>
      props.boxShadow
        ? "0 18px 38px rgb(0 0 0 / 10%), 0 7px 15px rgb(0 0 0 / 10%)"
        : "null"};
  }
`;
export const Label = styled.p`
  margin-bottom: 0;
  font-size: ${(props) => (props.size ? "1.1rem" : "0.9rem")};
  margin-left: 5px;
  color: #34495ed9;
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
  text-align: ${(props) => (props.align ? "center" : "left")};
`;
export const TextValue = styled.p`
  margin-bottom: 0;
  font-size: ${(props) => (props.size ? "0.9" : "0.8rem")};
  margin-left: 5px;
  color: #34495ed9;
  font-weight: ${(props) => (props.weight ? "900" : "500")};
  font-family: "Open Sans", sans-serif;
`;

export const EditPencilIcon = styled.svg`
  color: #34495ed9;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
`;

export const SkeletonEdit = styled(Skeleton)`
  && {
    margin-left: 5px;
    margin-bottom: 11px;
  }
`;
export const BillingManagerContainer = styled.div`
  padding: 1rem;
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Title = styled.p`
  margin-bottom: 0;
  font-size: 1.4rem;
  text-align: center;
  color: #34495ed9;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-end")};
  padding: 1rem;
`;

export const ActionBtn = styled(Button)`
  && {
    background-color: ${(props) =>
      props.active === "true" ? colorTertiary : "rgba(0, 0, 0, 0.04)"};
    color: ${(props) =>
      props.active === "true"
        ? "white"
        : props.mycolor === "red"
        ? "red"
        : colorPrimary};
    border-radius: 9px;
    min-width: 85px;
    padding: 8px 16px;
    margin-right: ${(props) => (props.right ? "10px" : "0")};

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;
export const ModuleAdressContainer = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  min-height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;
export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
`;

export const CardFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LabelCard = styled.p`
  margin-bottom: 0rem;
  font-size: ${(props) => (props.size ? "1.1rem" : "0.9rem")};
  margin-left: 5px;
  color: #6b7c93;
  letter-spacing: 0.025em;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  text-align: left;
`;

export const SaveCardBtn = styled(Button)`
  && {
    margin: auto;
    color: ${(props) => (props.active === "true" ? "red" : colorPrimary)};
    &:hover {
      background-color: transparent;
      color: ${(props) => (props.active === "true" ? "red" : colorPrimary)};
    }
  }
`;

export const AlertContainer = styled.div`
  background-color: ${colorTertiary} !important;
  border-radius: 5px;
  text-align: center;
  padding: 0.4rem;
  max-width: 320px;
  margin: 0 auto 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AlertText = styled.span`
  color: white;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
`;

export const SuccessIcon = styled.svg`
  color: ${colorSecondary};
`;
