import { Html } from "@react-three/drei";
import React from "react";
import {
  ArrowContainer,
  CircleContainer,
  HotspotContainer,
  RoundContainer,
} from "./Hotspot.style.js";
import { Tooltip, withStyles } from "@material-ui/core";

import round from "../../assets/linke.png";
import arrow from "../../assets/link.png";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: "white",
    fontSize: "15px",
    fontWeight: 900,
  },
}))(Tooltip);

const Hospot = (props) => {
  const target = props.scenes.filter(
    (item) => item.id === props.item.idTarget
  )[0];

  return (
    <mesh
      position={props.item.position}
      onPointerOver={() => props.setHovered(true)}
      onPointerOut={() => props.setHovered(false)}
    >
      <Html center zIndexRange={[999, 0]}>
        <HotspotContainer>
          {props.item.type === "perso" && (
            <HtmlTooltip
              title={props.item.linkName}
              placement="top"
              // open={true}
            >
              <CircleContainer onClick={() => props.click(props.item.idTarget)}>
                <img
                  src={target.finalUrl ? target.finalUrl : target.url}
                  alt={props.item.linkName}
                />
              </CircleContainer>
            </HtmlTooltip>
          )}
          {props.item.type === "round" && (
            <HtmlTooltip title={props.item.linkName} placement="top">
              <RoundContainer onClick={() => props.click(props.item.idTarget)}>
                <img src={round} alt={props.item.linkName} />
              </RoundContainer>
            </HtmlTooltip>
          )}
          {props.item.type === "arrow" && (
            <div>
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  marginBottom: 0,
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                {props.item.linkName}
              </p>
              <ArrowContainer onClick={() => props.click(props.item.idTarget)}>
                <img src={arrow} alt={props.item.linkName} />
              </ArrowContainer>
            </div>
          )}
        </HotspotContainer>
      </Html>
    </mesh>
  );
};

export default Hospot;
