import React, { useState } from "react";
import { Box } from "@material-ui/core";

import {
  ItemContainer,
  Label,
  EditPencilIcon,
} from "../../../../Utils/Styles/BillingInformation.styles";
import EditIcon from "@material-ui/icons/Edit";
import BillingMethodManager from "./BillingManager/BillingMethodManager/BillingMethodManager";
import { useTranslate } from "react-admin";
import { useAlert } from "react-alert";
import Debit from "./BillingManager/BillingMethodManager/itemsShow/Debit";
import Stripe from "./BillingManager/BillingMethodManager/itemsShow/Stripe";
import SkeletonItem from "./BillingManager/BillingMethodManager/itemsShow/SkeletonItem";

const BillingMethod = (props) => {
  const translate = useTranslate();
  const alert = useAlert();
  const [modal, setModal] = useState(false);

  const showMessage = (message) => {
    alert.show(translate(message));
  };
  const billingInformation = props.record.billingInformation;

  return (
    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
      <ItemContainer
        noBorder={props.noBorder}
        minHeight={props.minHeight}
        smallPadding={props.smallPadding}
        boxShadow={props.boxShadow}
      >
        <Label>{translate("resources.fields.billingMethod")}</Label>
        <div style={{ marginTop: "1rem" }}>
          {billingInformation &&
          billingInformation.mode === "debit" &&
          billingInformation.billingMethod &&
          billingInformation.billingMethod.rib ? (
            <Debit record={props.record} noDivSpace={props.noDivSpace} />
          ) : billingInformation &&
            billingInformation.mode === "stripe" &&
            billingInformation.billingMethod &&
            billingInformation.billingMethod.stripe &&
            billingInformation.billingMethod.stripe.active ? (
            <Stripe record={props.record} noDivSpace={props.noDivSpace} />
          ) : (
            <SkeletonItem noDivSpace={props.noDivSpace} />
          )}
        </div>
        {props.edit && (
          <EditPencilIcon as={EditIcon} onClick={() => setModal(true)} />
        )}
      </ItemContainer>
      {modal && (
        <BillingMethodManager
          setModal={setModal}
          {...props}
          showMessage={showMessage}
        />
      )}
    </Box>
  );
};

export default BillingMethod;
