import React from "react";
import nodalview from "../assets/nodalview.png";
import logoend from "../assets/logoend.png";
import content from "../assets/content.png";
import heart from "../assets/handsheart.png";
import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
 0% { transform: translateY(150%) }
 100% { transform: translateY(0) }
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open-Sans", sans-serif;
  box-sizing: border-box;
  @media (max-width: 615px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;
export const Back = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: ${(props) => `url(${props.cover})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
`;

export const ContainerInner = styled.div`
  max-width: 615px;
  width: 100%;
  padding: 0 25px;
  box-sizing: border-box;
  z-index: 2;
  animation-name: ${breatheAnimation};
  animation-duration: 3s;

  @media (max-width: 615px) {
    z-index: 2;
    padding: 25px 30px 50px;
  }
`;
export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 2rem;
  box-sizing: border-box;
  @media (max-width: 615px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  background-color: #dee7ff;
  width: 48%;
  border-radius: 6px;
  padding: 20px 20px 30px;
  box-sizing: border-box;
  @media (max-width: 615px) {
    width: 100%;
  }
`;

const IndexErrorPage = () => {
  return (
    <Main cover={content}>
      <Back cover={content} />
      <ContainerInner>
        <h1>À nos chers utilisateurs,</h1>
        <p style={{ lineHeight: "20px" }}>
          nous avons le regret de vous annoncer que les solutions Visibble Print
          et 360 sont définitivement fermées.
        </p>
        <p style={{ lineHeight: "20px" }}>
          L'arrêt de ces services nous permettra, notamment, de développer des
          fonctionnalités plus actuelles et avancées sur{" "}
          <a
            style={{ textDecoration: "underline" }}
            rel="noreferrer"
            target="_blank"
            href={
              "http://visibble.io/?utm_source=referral&utm_medium=appvisibble360&utm_campaign=findeservice"
            }
          >
            Visibble Display
          </a>
          , notre solution d'affichage dynamique.
        </p>
        <p style={{ lineHeight: "20px" }}>
          Afin de faciliter cette transition, nous vous recommandons les
          solutions suivantes :
        </p>
        <BoxContainer>
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img
                src={nodalview}
                style={{ width: "auto", height: "40px" }}
                alt="nodalview"
              />
            </div>
            <p style={{ fontSize: "15px" }}>Vous étiez sur Visibble 360 ?</p>
            <p style={{ fontSize: "15px" }}>
              Nous vous recommandons de contacter notre Partenaire Nodalview
              auprès duquel vous pouvez prendre un rendez-vous.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2A4A9C",
                borderRadius: 6,
                marginTop: 20,
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  "https://meetings.hubspot.com/isabelle-fueyo",
                  "_blank"
                )
              }
            >
              <p style={{ fontSize: 14, color: "white", margin: "14px 0" }}>
                Je prends un rendez-vous
              </p>
            </div>
          </Box>
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img
                src={logoend}
                style={{ width: "auto", height: "39px" }}
                alt="nodalview"
              />
            </div>
            <p style={{ fontSize: "15px" }}>Vous étiez sur Visibble Print ?</p>
            <p style={{ fontSize: "15px" }}>
              Nous vous recommandons de vous diriger vers Yisual. Solution de
              communication visuelle pour les agents immobiliers.
            </p>
            <div
              onClick={() => window.open("https://www.yisual.fr/", "_blank")}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2A4A9C",
                borderRadius: 6,
                marginTop: 20,
                cursor: "pointer",
              }}
            >
              <p style={{ fontSize: 14, color: "white", margin: "14px 0" }}>
                Je découvre Yisual
              </p>
            </div>
          </Box>
        </BoxContainer>
        <p style={{ lineHeight: "20px" }}>
          Notre service client reste naturellement à votre entière disposition
          pour toute question ou besoin d'assistance,{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "#007bff",
              cursor: "pointer",
            }}
            onClick={() =>
              window.open("mailto:hello@vitrinemedia.com", "_blank")
            }
          >
            contactez-nous
          </span>
          .
        </p>
        <p style={{ lineHeight: "20px" }}>
          Merci pour votre fidélité tout au long de notre parcours ensemble
          <span style={{ marginLeft: 5 }}>
            <img
              src={heart}
              alt="heart"
              style={{ height: 18, width: "auto" }}
            />
          </span>
        </p>
      </ContainerInner>
    </Main>
  );
};

export default IndexErrorPage;
