/* eslint-disable no-unused-vars */
import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";

const Hr = () => {
  return <Divider light style={{ marginBottom: "20px" }} />;
};

const Create = (props) => {
  const translate = useTranslate();

  const { choices } = props;
  return (
    <>
      {/* BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="metatitle"
            label="Titre de l'annonce"
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="reference"
            resource="projects"
            fullWidth
            label={translate("resources.projects.fields.reference") + " *"}
            error={props.errors.reference}
            helperText={
              props.errors.reference ? "ra.validation.required" : null
            }
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="state"
            choices={choices.state}
            resource="projects"
            resettable
            fullWidth
            error={props.errors.state}
            helperText={props.errors.state ? "ra.validation.required" : null}
            label={translate("resources.projects.fields.state") + " *"}
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="category"
            resettable
            choices={choices.category}
            resource="projects"
            fullWidth
            error={props.errors.category}
            helperText={props.errors.category ? "ra.validation.required" : null}
            label={translate("resources.projects.fields.category") + " *"}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="type"
            choices={choices.type}
            resource="projects"
            resettable
            fullWidth
            label={translate("resources.projects.fields.type") + " *"}
            error={props.errors.type}
            helperText={props.errors.type ? "ra.validation.required" : null}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="price"
            resource="projects"
            fullWidth
            InputProps={{ endAdornment: "€" }}
            label={translate("resources.projects.fields.price") + " *"}
            error={props.errors.price}
            helperText={props.errors.price ? "ra.validation.required" : null}
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="rooms"
            min={0}
            step={0.5}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="bedrooms"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="sdb"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
      {/*  BLOCK */}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="dpeLetter"
            resettable
            resource="projects"
            choices={choices.dpeLetter}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="dpeValue"
            resource="projects"
            min={0}
            step={1}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="gesLetter"
            resettable
            resource="projects"
            choices={choices.dpeLetter}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="gesValue"
            resource="projects"
            min={0}
            step={1}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="dpeCoutMinConso" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="dpeCoutMaxConso" resource="projects" fullWidth />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="dpeAnneRefConso" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="agreement"
            choices={choices.agreement}
            resource="projects"
            resettable
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="currency"
            resource="projects"
            fullWidth
            choices={choices.devise}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="area.total"
            resource="projects"
            min={0}
            step={0.1}
            fullWidth
            // validate={required}
            error={props.errors.area}
            helperText={props.errors.area ? "ra.validation.required" : null}
            label={translate("resources.projects.fields.area.total") + " *"}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="gardenArea"
            resource="projects"
            min={0}
            step={0.1}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        {props.record && props.record.category === choices.category[2].id && (
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="periodicity"
              resource="projects"
              fullWidth
              choices={choices.periodicity}
            />
          </Box>
        )}
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="garage"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <NumberInput
            source="terrace"
            min={0}
            step={1}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
      {/* LOI ALUR */}

      <Typography variant="h6" gutterBottom mt="15px">
        {translate("resources.projects.tabform.alur")}
      </Typography>
      <Hr />
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="nblotcopro" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="montantquotepartannuelle"
            resource="projects"
            fullWidth
            InputProps={{ endAdornment: "€" }}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="detailprocsyndicale"
            resource="projects"
            fullWidth
            multiline
          />
        </Box>
      </Box>

      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="procsyndicale" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="copropriete" resource="projects" />
      </Box>
      <br />
      {/* FINANCIAL DATA */}
      <Typography variant="h6" gutterBottom>
        {translate("resources.projects.tabform.financialdatas")}
      </Typography>
      <Hr />

      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="fees" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feesetatdeslieux"
            resource="projects"
            InputProps={{ endAdornment: "€" }}
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feescopro"
            resource="projects"
            fullWidth
            InputProps={{ endAdornment: "€" }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feesdepotgarantie"
            resource="projects"
            fullWidth
            InputProps={{ endAdornment: "€" }}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="period"
            resettable
            resource="projects"
            choices={choices.period}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="monthlycharge"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
      </Box>

      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="commission" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="sharedCommission" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="feespercent" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="hideprice" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <TextInput
          source="amountWithoutHonorary"
          resource="projects"
          fullWidth
          InputProps={{
            endAdornment: props.record.currency ? props.record.currency : "€",
          }}
        />
      </Box>
      <br />
      {/* COMMENTS */}
      <Typography variant="h6" gutterBottom>
        {translate("resources.projects.tabform.descriptions")}
      </Typography>
      <Hr />
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput
          source="comments"
          resource="projects"
          className="arrayInput-product"
        >
          <SimpleFormIterator>
            <SelectInput
              source="comments.language"
              resource="projects"
              choices={choices.commentsLanguage}
              defaultValue={choices.commentsLanguage[0].id}
            />
            <TextInput source="comments.title" resource="projects" />
            <TextInput source="comments.subtitle" resource="projects" />
            <TextInput
              source="comments.comment"
              resource="projects"
              multiline
              fullWidth
              placeholder="Entrer pour aller à la ligne"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <Typography variant="h6" gutterBottom mt="15px">
        {translate("resources.projects.typographies.localisation")}
      </Typography>
      <Hr />
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <TextInput source="city" resource="projects" fullWidth />
      </Box>
    </>
  );
};

export default Create;
