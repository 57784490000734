import styled from "styled-components";
import AppsIcon from "@material-ui/icons/Apps";

export const SwitchIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 65px;
  padding: 2px 0 0;
`;

export const SwitchIcon = styled(AppsIcon)`
  && {
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
export const SwitchItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  min-width: 315px;
`;

export const SwitchItemsContainerTitle = styled.p`
  font-weight: 700;
  margin-bottom: 0;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
`;

export const TitleItem = styled.p`
  font-size: 18px;
  font-weight: 900;
  color: ${(props) => (props.active ? props.color : "black")};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const SwitchItemContainer = styled.div`
  background-color: ${(props) => (props.active ? "#fafafa" : "transparent")};
  cursor: pointer;
  display: flex;
  border-left: ${(props) =>
    props.active ? `solid 8px ${props.color}` : "solid 8px transparent"};
  padding-left: 15px;
  padding-right: 15px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #fafafa;
    border-left: solid 8px ${(props) => props.color};
  }
  &:hover ${TitleItem} {
    color: ${(props) => props.color};
  }
`;

export const ItemTextContainer = styled.div``;
export const DescItem = styled.p`
  font-size: 15px;
  color: #b2b2b2;
  margin-bottom: 10px;
`;
export const CircularContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.2rem;
`;
