import React from "react";
import ColorPrimaryComponent from "./ColorPicker/Items/ColorPrimaryComponent";
import { ImageInput, ImageField, useTranslate } from "react-admin";
import { Box } from "@material-ui/core";

const TemplateForm = (props) => {
  const translate = useTranslate();
  const { onChangeColor } = props;

  return (
    <div>
      {props.record.id && (
        <>
          <Box
            display={{ xs: "block", sm: "flex" }}
            mt={2}
            alignItems="center"
            width={1}
          >
            <Box flex={1} mb={3} mr={{ xs: 0, sm: "0.5em" }} width={1}>
              <ColorPrimaryComponent
                onChangeColor={onChangeColor}
                color={props.initialValues.colorPrimary}
                setter={"colorPrimary"}
                label={translate("resources.agencies.fields.color.primary")}
                default={props.record.colorPrimary}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={3} width={1}>
              <ColorPrimaryComponent
                onChangeColor={onChangeColor}
                color={props.initialValues.colorSecondary}
                setter={"colorSecondary"}
                label={translate("resources.agencies.fields.color.secondary")}
                default={props.record.colorSecondary}
              />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }} alignItems="center">
            <Box flex={1} mb={3} mr={{ xs: 0, sm: "0.5em" }} width="100%">
              <ColorPrimaryComponent
                onChangeColor={onChangeColor}
                color={props.initialValues.colorTitle}
                setter={"colorTitle"}
                label={translate("resources.agencies.fields.color.title")}
                default={props.record.colorTitle}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={3} width="100%">
              <ColorPrimaryComponent
                onChangeColor={onChangeColor}
                color={props.initialValues.colorSubTitle}
                setter={"colorSubTitle"}
                label={translate("resources.agencies.fields.color.subtitle")}
                default={props.record.colorSubTitle}
              />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }} alignItems="center">
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={3} width={"100%"}>
              <ColorPrimaryComponent
                onChangeColor={onChangeColor}
                color={props.initialValues.colorPrice}
                setter={"colorPrice"}
                label={translate("resources.agencies.fields.color.price")}
                default={props.record.colorPrice}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={3} width={"100%"}>
              <ColorPrimaryComponent
                onChangeColor={onChangeColor}
                color={props.initialValues.colorDesc}
                setter={"colorDesc"}
                label={translate("resources.agencies.fields.color.description")}
                default={props.record.colorDesc}
              />
            </Box>
          </Box>{" "}
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ImageInput source="header" resource="agencies" accept="image/*">
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ImageInput source="footer" resource="agencies" accept="image/*">
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ImageInput
                source="background"
                resource="agencies"
                accept="image/*"
              >
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ImageInput
                source="headerPortrait"
                resource="agencies"
                accept="image/*"
              >
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ImageInput
                source="footerPortrait"
                resource="agencies"
                accept="image/*"
              >
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ImageInput
                source="backgroundPortrait"
                resource="agencies"
                accept="image/*"
              >
                <ImageField source="src" title="title" src="src" />
              </ImageInput>
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default TemplateForm;
