import React from "react";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import {
  Container,
  ContainerTop,
  ContainerBottom,
  ContainerTopLeft,
  ImgBackGround,
  ContainerTopRight,
  ContainerTopRight1,
  ContainerTopRight2,
  Description,
  ContainerBottomLeft,
  RefContainer,
  Ref,
  ContainerBottomCenter,
  ContainerTopTitle,
  Type,
  Location,
  Triange,
  ContainerBottomRight,
  QrcodeContainer,
  LogoContainer,
  // LogoAgence,
  ExcluContainer,
  ExcluText,
  CategoryContainer,
  CategoryText,
  IconContainer,
  IconText,
} from "./BarceloneTemplate.styles";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import RenderContext from "../../ModulesElements/RenderContext";
import { AlurText } from "../../Modules/ModulesElements.styles";

const BarceloneTemplate = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    categoryFont,
    categoryText,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  const excludedValue = ["", undefined, null];

  const DpeGesNoValue = ["null", "vi", "V.I", "VI"];

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      $marginAuto={props.isPreview}
      mirror={mirror}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <ContainerTop>
        <ContainerTopLeft>
          <ImgBackGround
            src={imageFirst}
            brightness={brightness}
            saturation={saturation}
          />
          {isBanner === "true" && (
            <ExcluContainer colorPrimary={color.primary || "#d82527"}>
              <ExcluText
                size={bannerFont.size}
                weight={bannerFont.weight}
                family={bannerFont.font}
                color={bannerFont.color}
              >
                {banner}
              </ExcluText>
            </ExcluContainer>
          )}
          <CategoryContainer colorPrimary={color.primary || "#d82527"}>
            <CategoryText
              size={categoryFont.size}
              weight={categoryFont.weight}
              family={categoryFont.font}
              color={categoryFont.color}
            >
              {categoryText}
            </CategoryText>
          </CategoryContainer>
          {project.category !== "Locationsaisonnière" &&
          qrcodeEdit !== null &&
          qrcodeEdit !== "" ? (
            <QrcodeContainer>
              <QRCode value={qrcodeEdit} size={140} />
            </QrcodeContainer>
          ) : null}
        </ContainerTopLeft>
        <ContainerTopTitle colorPrimary={color.primary || "#d82527"}>
          <div>
            <Type
              colorTitle={colorTitle || "white"}
              size={titleTextFont.size}
              weight={titleTextFont.weight}
              family={titleTextFont.font}
            >
              {project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </Type>
            <Location
              colorSubTitle={
                !excludedValue.includes(colorSubTitle) ? colorSubTitle : "white"
              }
              size={subTitleTextFont.size}
              weight={subTitleTextFont.weight}
              family={subTitleTextFont.font}
            >
              {subTitleText}
            </Location>
          </div>
        </ContainerTopTitle>
        <Triange colorPrimary={color.primary || "#9f0e10"}></Triange>
        <ContainerTopRight>
          {agency.images && agency.images.length ? (
            <LogoContainer image={agency.images[0].src}></LogoContainer>
          ) : null}
          <ContainerTopRight1>
            <Description
              colorDesc={colorDesc}
              size={descFont.size}
              weight={descFont.weight}
              family={descFont.font}
            >
              {desc}
            </Description>
          </ContainerTopRight1>
          <ContainerTopRight2 colorPrimary={color.primary || "#d82527"}>
            <ModulePrice
              colorPrice={colorPrice || "white"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "38pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </ContainerTopRight2>
        </ContainerTopRight>
      </ContainerTop>
      <ContainerBottom>
        <ContainerBottomLeft>
          <RenderContext project={project}>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                top: "20px",
                left: "25px",
              }}
            >
              <Dpe
                letter={
                  project.dpeLetter
                    ? DpeGesNoValue.includes(project.dpeLetter)
                      ? "vi"
                      : project.dpeLetter
                    : "vi"
                }
                conso={project.dpeValue}
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "0.22"
                    : "0.25"
                }
                topTitle={"logement extrêmement performant"}
                bottomTitle={"logement extrêmement peu performant"}
                redInfoTop={"passoire"}
                redInfoBottom={"énergetique"}
                label={"consommation"}
                secondLabel={"émissions"}
                subLabel={"(énergie primaire)"}
                subValue={"kWh/m2/an"}
                secondSubValue={"kg CO₂/m²/an"}
                theme={"light"}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                bottom:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "56px"
                    : "-15px",
                left:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "295px"
                    : "330px",
              }}
            >
              <Ges
                letter={
                  project.gesLetter
                    ? DpeGesNoValue.includes(project.gesLetter)
                      ? "vi"
                      : project.gesLetter
                    : "vi"
                }
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "0.50"
                    : "0.30"
                }
                theme={"light"}
              />
            </div>
          </RenderContext>
        </ContainerBottomLeft>
        <ContainerBottomCenter>
          <RefContainer>
            <Ref
              size={refFont.size}
              weight={refFont.weight}
              family={refFont.font}
              color={refFont.color}
            >
              Ref: {refText}{" "}
            </Ref>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={colorDesc || "black"}
                price={price}
                sizeText={"11pt"}
              />
            </RenderContext>
            <AlurText sizeText={"11pt"} colorDesc={colorDesc || "black"}>
              Les informations sur les risques auxquels ce bien est exposé sont
              disponibles sur le site Géorisques : http://www.georisques.gouv.fr
            </AlurText>
          </RefContainer>
        </ContainerBottomCenter>
        <ContainerBottomRight>
          <IconContainer>
            <Surface color={colorSecondary || "black"} size={70} />
            <IconText colorSecondary={colorSecondary || "black"}>
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Rooms color={colorSecondary || "black"} size={70} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Chambres color={colorSecondary || "black"} size={70} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Sdb color={colorSecondary || "black"} size={70} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </ContainerBottomRight>
      </ContainerBottom>
    </Container>
  );
});

export default BarceloneTemplate;
