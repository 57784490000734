import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import {
  PencilIcon,
  BtnEditSmallRow,
  Btn360SmallRow,
} from "../../../../Utils/Styles/ProjectList.styles";
import { useRedirect } from "ra-core";
import { useTranslate } from "react-admin";

const EditProjectButton = ({ id }) => {
  const redirect = useRedirect();
  return (
    <BtnEditSmallRow
      resource="projects"
      onClick={() => redirect(`/projects/${id}`)}
    >
      <PencilIcon />
    </BtnEditSmallRow>
  );
};

const View360Button = ({ row, slugAgence, translate, redirect }) => {
  if (row.scenes && row.scenes.length) {
    return (
      <Btn360SmallRow
        href={`/360/edit/${row._id}`}
        target="_blank"
        minwidth="true"
        padding="true"
      >
        {translate("resources.fields.edit").toUpperCase()}
      </Btn360SmallRow>
    );
  } else {
    return (
      <Btn360SmallRow
        href={`/360/edit/${row._id}`}
        minwidth="true"
        padding="true"
      >
        {translate("resources.fields.create").toUpperCase()}
      </Btn360SmallRow>
    );
  }
};

const ProjectSmallRow = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { row } = props;

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell align={"center"}>
        <a
          href={`visite360/${props.slugAgence}/${row.slug}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={
              row.images && row.images.length
                ? row.images[0].src
                : "https://fakeimg.pl/350x240/?text=" +
                  translate("resources.fields.comingsoon")
            }
            alt={"preview"}
            style={{ width: "65px", borderRadius: "4px", cursor: "pointer" }}
          />
        </a>
      </TableCell>
      <TableCell align={"center"}>
        <p style={{ width: "90px", wordWrap: "break-word", marginBottom: "0" }}>
          {row.reference}
        </p>
      </TableCell>
      <TableCell align={"center"}>
        <p style={{ width: "90px", wordWrap: "break-word", marginBottom: "0" }}>
          {row.city}
        </p>
      </TableCell>
      <TableCell align={"center"}>{`${row.price} ${
        row.currency ? row.currency : "€"
      }`}</TableCell>
      <TableCell align={"center"}>{`${row.area.total} m²`}</TableCell>
      <TableCell align={"center"}>{row.rooms}</TableCell>
      <TableCell align={"center"}>{row.bedrooms}</TableCell>
      <TableCell align={"center"}>
        <EditProjectButton id={row._id} />
      </TableCell>
      {props.ads && (
        <TableCell align={"center"}>
          <View360Button
            row={row}
            translate={translate}
            redirect={redirect}
            slugAgence={props.slugAgence}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default ProjectSmallRow;
