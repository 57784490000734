import React from "react";
import { colorSecondary } from "../../../../GlobalStyles";
import { useTemplatesStore } from "../../../../store/TemplatesStore";
import {
  MediaChoiceImage,
  RowChoiceImage,
  RowInnerChoiceImage,
  TileChoiceImage,
  TileMediaChoiceImage,
} from "./Styles/ImageChoice.styles";
const ImagesSuppChoice = ({ handleImageSupp }) => {
  const { project, imageSupp } = useTemplatesStore();
  return (
    <RowChoiceImage>
      <RowInnerChoiceImage>
        {project.images.map((item, idx) => {
          return (
            <TileChoiceImage
              key={idx}
              onClick={() => handleImageSupp(item.src)}
              width={"400px"}
              marginRight={"30px"}
              backgroundColor={
                imageSupp[0] === item.src ||
                imageSupp[1] === item.src ||
                imageSupp[2] === item.src ||
                imageSupp[3] === item.src
                  ? colorSecondary
                  : "unset"
              }
            >
              <TileMediaChoiceImage>
                <MediaChoiceImage
                  src={item.src}
                  alt={item.name}
                  width={"400px"}
                  border={
                    imageSupp[0] === item.src ||
                    imageSupp[1] === item.src ||
                    imageSupp[2] === item.src ||
                    imageSupp[3] === item.src
                      ? `solid 5px ${colorSecondary}`
                      : "unset"
                  }
                />
              </TileMediaChoiceImage>
            </TileChoiceImage>
          );
        })}
      </RowInnerChoiceImage>
    </RowChoiceImage>
  );
};

export default ImagesSuppChoice;
