import React, { useState } from "react";
import { useForm } from "react-final-form";
import { Editor } from "@tinymce/tinymce-react";
import * as _ from "lodash";
import { config } from "../../constants/constant";

const TinyMCEInput = ({ source, ...props }) => {
  const form = useForm();
  const firstInit = _.get(form.getState().initialValues, source, "");
  const [init] = useState(firstInit);

  const debouncedOnChange = _.debounce((text) => {
    form.change(source, text);
    props.setEnable(false);
  }, 500);
  return (
    <Editor
      apiKey={config.TINYMCE_KEY}
      init={{
        plugins: [
          "autoresize advlist autolink lists link image charmap preview searchreplace visualblocks code fullscreen table paste code wordcount",
        ],
        menubar: true,
        statusbar: true,
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap | fullscreen  preview  | insertfile image link | code ",
      }}
      initialValue={init}
      onEditorChange={(content) => {
        debouncedOnChange(content);
      }}
    />
  );
};

export default TinyMCEInput;
