export const choices = {
  devise: [
    { id: "€", name: "Euro" },
    { id: "$", name: "Dollar" },
    { id: "£", name: "Livre sterling" },
    { id: "¥", name: "Yen" },
    { id: "₪", name: "Shekel" },
    { id: "₣", name: "Franc suisse" },
  ],
  periodicity: [
    { id: "night", name: "resources.projects.fields.periodicity.night" },
    { id: "week", name: "resources.projects.fields.periodicity.week" },
    { id: "month", name: "resources.projects.fields.periodicity.month" },
  ],
  lang: [
    { id: "fr-FR", name: "FR" },
    { id: "en-US", name: "EN" },
  ],
  state: [
    { id: "inprogress", name: "resources.projects.fields.state.inprogress" },
    { id: "waiting", name: "resources.projects.fields.state.waiting" },
    { id: "secure", name: "resources.projects.fields.state.secure" },
    { id: "finish", name: "resources.projects.fields.state.finished" },
    { id: "broadcast", name: "resources.projects.fields.state.broadcast" },
  ],
  ranking: [
    { id: "1", name: "resources.projects.fields.ranking.1" },
    { id: "2", name: "resources.projects.fields.ranking.2" },
    { id: "3", name: "resources.projects.fields.ranking.3" },
    { id: "4", name: "resources.projects.fields.ranking.4" },
    { id: "5", name: "resources.projects.fields.ranking.5" },
  ],
  category: [
    { id: "Vente", name: "resources.projects.fields.category.sale" },
    { id: "Location", name: "resources.projects.fields.category.rental" },
    {
      id: "Locationsaisonnière",
      name: "resources.projects.fields.category.seasonalrental",
    },
    { id: "Programme", name: "resources.projects.fields.category.program" },
    { id: "Viager", name: "resources.projects.fields.category.annuities" },
    {
      id: "Nue-propriété",
      name: "resources.projects.fields.category.bareownership",
    },
    { id: "Enchère", name: "resources.projects.fields.category.bid" },
  ],
  subcategory: [
    { id: "unitsale", name: "resources.projects.fields.subcategory.unitsale" },
    { id: "bulksale", name: "resources.projects.fields.subcategory.bulksale" },
    { id: "empty", name: "resources.projects.fields.subcategory.empty" },
    {
      id: "furnished",
      name: "resources.projects.fields.subcategory.furnished",
    },
    {
      id: "flatsharing",
      name: "resources.projects.fields.subcategory.flatsharing",
    },
    { id: "student", name: "resources.projects.fields.subcategory.student" },
  ],
  type: [
    { id: "Appartement", name: "resources.projects.fields.type.apartment" },
    { id: "Maison", name: "resources.projects.fields.type.house" },
    { id: "Maison/villa", name: "resources.projects.fields.type.house.villa" },
    {
      id: "Maison prestige",
      name: "resources.projects.fields.type.house.prestige",
    },
    { id: "Terrain", name: "resources.projects.fields.type.field" },
    { id: "Commerce", name: "resources.projects.fields.type.store" },
    { id: "Local", name: "resources.projects.fields.type.local" },
    { id: "Garage", name: "resources.projects.fields.type.garage" },
    { id: "Parking", name: "resources.projects.fields.type.parking" },
    { id: "Box", name: "resources.projects.fields.type.box" },
    { id: "Parking/box", name: "resources.projects.fields.type.parkingbox" },
    { id: "Immeuble", name: "resources.projects.fields.type.building" },
    { id: "Bureau", name: "resources.projects.fields.type.office" },
    { id: "Bateau", name: "resources.projects.fields.type.boat" },
    {
      id: "Locaux d'activité",
      name: "resources.projects.fields.type.activitypremises",
    },
    { id: "Entrepot", name: "resources.projects.fields.type.warehouse" },
    { id: "Cave", name: "resources.projects.fields.type.cellar" },
  ],
  subtype: [
    { id: "triplex", name: "resources.projects.fields.subtype.triplex" },
    {
      id: "buildingland",
      name: "resources.projects.fields.subtype.buildingland",
    },
    {
      id: "inconstructibleland",
      name: "resources.projects.fields.subtype.inconstructibleland",
    },
  ],
  agreement: [
    { id: "simple", name: "resources.projects.fields.agreement.simple" },
    {
      id: "co-exclusive",
      name: "resources.projects.fields.agreement.co-exclusive",
    },
    { id: "exclusive", name: "resources.projects.fields.agreement.exclusive" },
  ],
  period: [
    { id: "day", name: "resources.projects.fields.period.day" },
    { id: "week", name: "resources.projects.fields.period.week" },
    { id: "fortnight", name: "resources.projects.fields.period.fortnight" },
    { id: "month", name: "resources.projects.fields.period.month" },
    { id: "year", name: "resources.projects.fields.period.year" },
  ],
  viewtype: [
    { id: "vis-a-vis", name: "resources.projects.fields.viewtype.vis-a-vis" },
    { id: "overview", name: "resources.projects.fields.viewtype.overview" },
    { id: "clear", name: "resources.projects.fields.viewtype.clear" },
    { id: "panoramic", name: "resources.projects.fields.viewtype.panoramic" },
    { id: "external", name: "resources.projects.fields.viewtype.external" },
  ],
  viewlandscape: [
    { id: "sea", name: "resources.projects.fields.viewlandscape.sea" },
    {
      id: "mountains",
      name: "resources.projects.fields.viewlandscape.mountains",
    },
    { id: "hills", name: "resources.projects.fields.viewlandscape.hills" },
    {
      id: "greenery",
      name: "resources.projects.fields.viewlandscape.greenery",
    },
    {
      id: "monument",
      name: "resources.projects.fields.viewlandscape.monument",
    },
  ],
  construction: [
    { id: "wood", name: "resources.projects.fields.construction.wood" },
    {
      id: "engineeredwood",
      name: "resources.projects.fields.construction.engineeredwood",
    },
    { id: "stone", name: "resources.projects.fields.construction.stone" },
    { id: "concrete", name: "resources.projects.fields.construction.concrete" },
    {
      id: "reinforcedconcrete",
      name: "resources.projects.fields.construction.reinforcedconcrete",
    },
  ],
  typeOfUnit: [
    { id: "meters", name: "resources.projects.fields.area.typeofunit.meter" },
    { id: "foot", name: "resources.projects.fields.area.typeofunit.foot" },
    { id: "ha", name: "resources.projects.fields.area.typeofunit.ha" },
  ],
  typeOfValue: [
    { id: "field", name: "resources.projects.fields.area.typeofvalue.field" },
    {
      id: "habitation",
      name: "resources.projects.fields.area.typeofvalue.habitation",
    },
  ],
  heating: [
    { id: "convector", name: "resources.projects.fields.heating.convector" },
    { id: "underfloor", name: "resources.projects.fields.heating.underfloor" },
    { id: "radiator", name: "resources.projects.fields.heating.radiator" },
    {
      id: "airconditioner",
      name: "resources.projects.fields.heating.airconditioner",
    },
    { id: "stove", name: "resources.projects.fields.heating.stove" },
  ],
  heatingaccess: [
    {
      id: "individual",
      name: "resources.projects.fields.heatingaccess.individual",
    },
    {
      id: "collective",
      name: "resources.projects.fields.heatingaccess.collective",
    },
    { id: "mixed", name: "resources.projects.fields.heatingaccess.mixed" },
    { id: "urban", name: "resources.projects.fields.heatingaccess.urban" },
  ],
  heatingtype: [
    { id: "Gaz", name: "resources.projects.fields.heatingtype.gas" },
    { id: "Fioul", name: "resources.projects.fields.heatingtype.fuel" },
    { id: "Mazout", name: "resources.projects.fields.heatingtype.mazout" },
    {
      id: "Electrique",
      name: "resources.projects.fields.heatingtype.electric",
    },
    { id: "Bois", name: "resources.projects.fields.heatingtype.wood" },
  ],
  waterhotdevice: [
    { id: "solar", name: "resources.projects.fields.waterhotdevice.solar" },
    {
      id: "waterheater",
      name: "resources.projects.fields.waterhotdevice.waterheater",
    },
    { id: "boiler", name: "resources.projects.fields.waterhotdevice.boiler" },
  ],
  waterhotaccess: [
    {
      id: "individual",
      name: "resources.projects.fields.waterhotaccess.individual",
    },
    {
      id: "collective",
      name: "resources.projects.fields.waterhotaccess.collective",
    },
  ],
  waterwaste: [
    {
      id: "septictank",
      name: "resources.projects.fields.waterwaste.septictank",
    },
    {
      id: "allinthesewer",
      name: "resources.projects.fields.waterwaste.allinthesewer",
    },
  ],
  condition: [
    { id: "torefresh", name: "resources.projects.fields.condition.torefresh" },
    { id: "good", name: "resources.projects.fields.condition.good" },
  ],
  standing: [
    { id: "standing", name: "resources.projects.fields.standing.standing" },
    { id: "luxury", name: "resources.projects.fields.standing.luxury" },
  ],
  availability: [
    { id: "free", name: "resources.projects.fields.availability.free" },
    { id: "rented", name: "resources.projects.fields.availability.rented" },
    { id: "sold", name: "resources.projects.fields.availability.sold" },
  ],
  orientations: [
    { id: "Nord", name: "resources.projects.fields.orientations.north" },
    { id: "Sud", name: "resources.projects.fields.orientations.south" },
    { id: "Est", name: "resources.projects.fields.orientations.east" },
    { id: "Ouest", name: "resources.projects.fields.orientations.west" },
    {
      id: "Nord-Est",
      name: "resources.projects.fields.orientations.northeast",
    },
    {
      id: "Nord-Ouest",
      name: "resources.projects.fields.orientations.northwest",
    },
    {
      id: "Sud-Est",
      name: "resources.projects.fields.orientations.southeast",
    },
    {
      id: "Sud-Ouest",
      name: "resources.projects.fields.orientations.southwest",
    },
  ],
  services: [
    { id: "internet", name: "resources.projects.fields.services.internet" },
    { id: "chimney", name: "resources.projects.fields.services.chimney" },
  ],
  proximity: [
    { id: "bus", name: "resources.projects.fields.proximity.bus" },
    {
      id: "busstation",
      name: "resources.projects.fields.proximity.busstation",
    },
    { id: "gare", name: "resources.projects.fields.proximity.gare" },
    { id: "subway", name: "resources.projects.fields.proximity.subway" },
  ],
  tags: [
    { id: "selection", name: "resources.projects.fields.tags.selection" },
    { id: "favorite", name: "resources.projects.fields.tags.favorite" },
    {
      id: "mainresidence",
      name: "resources.projects.fields.tags.mainresidence",
    },
  ],
  mediasType: [
    { id: "youtube", name: "resources.projects.fields.mediasurl.youtube" },
    { id: "vimeo", name: "resources.projects.fields.mediasurl.vimeo" },
    { id: "url", name: "Url" },
  ],
  commentsLanguage: [
    { id: "fr", name: "resources.projects.fields.comments.language.fr" },
    { id: "en", name: "resources.projects.fields.comments.language.en" },
    { id: "es", name: "resources.projects.fields.comments.language.es" },
  ],
  areasType: [
    { id: "bedroom", name: "resources.projects.fields.areasType.bedroom" },
    { id: "salon", name: "resources.projects.fields.areasType.salon" },
    { id: "cuisine", name: "resources.projects.fields.areasType.cuisine" },
    { id: "bathroom", name: "resources.projects.fields.areasType.bathroom" },
    {
      id: "showerroom",
      name: "resources.projects.fields.areasType.showerroom",
    },
    { id: "parking", name: "resources.projects.fields.areasType.parking" },
    { id: "garage", name: "resources.projects.fields.areasType.garage" },
  ],
  areasFlooring: [
    { id: "parquet", name: "resources.projects.fields.areasFlooring.parquet" },
    { id: "tiles", name: "resources.projects.fields.areasFlooring.tiles" },
  ],
  dpeLetter: [
    { id: "A", name: "resources.projects.fields.dpeLetter.a" },
    { id: "B", name: "resources.projects.fields.dpeLetter.b" },
    { id: "C", name: "resources.projects.fields.dpeLetter.c" },
    { id: "D", name: "resources.projects.fields.dpeLetter.d" },
    { id: "E", name: "resources.projects.fields.dpeLetter.e" },
    { id: "F", name: "resources.projects.fields.dpeLetter.f" },
    { id: "G", name: "resources.projects.fields.dpeLetter.g" },
    { id: "VI", name: "VI" },
  ],
};
