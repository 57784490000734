import { Box } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

const Comments = (props) => {
  const { choices } = props;
  return (
    <>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <ArrayInput
          source="comments"
          resource="projects"
          className="arrayInput-product"
        >
          <SimpleFormIterator>
            <SelectInput
              source="comments.language"
              resettable
              resource="projects"
              choices={choices.commentsLanguage}
              defaultValue={1}
            />
            <TextInput source="comments.title" resource="projects" />
            <TextInput source="comments.subtitle" resource="projects" />
            <TextInput
              source="comments.comment"
              resource="projects"
              multiline
              fullWidth
              placeholder="Entrer pour aller à la ligne"
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
    </>
  );
};

export default Comments;
