/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Divider } from "@material-ui/core";
import { ModalManager } from "../../Utils/Styles/ModalManager.mui.styles";
import {
  ItemManagerContainer,
  Container,
  TitleContainer,
  Title,
  ButtonContainer,
  ItemContainer,
  TitleNumberOfUser,
  ActionBtn,
} from "../../Utils/Styles/ItemManager.styles";
import { useTranslate } from "react-admin";
import ModuleAdsSelect from "./ModuleAdsSelect";
import Module from "./Module";
import ModuleFree from "./ModuleFree";
import ModuleBilling from "./ModuleBilling";
import ActionContainer from "./ActionContainer";
import ApproveButton from "./ApprouveButton";
import { getAboPrice } from "../features";
import {
  AdsColorBackground,
  DisplayColorBackground,
  PrintColorBackground,
} from "../../../../GlobalStyles";

const ItemManager = (props) => {
  const translate = useTranslate();
  const classes = ModalManager();
  const [confirmAction, setConfirmAction] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [state, setState] = useState({
    print: {
      state: "inactive",
      type: "print",
      total: 19,
    },
    display: {
      state: "inactive",
      type: "display",
      total: 49,
    },
    data: {
      state: "inactive",
      type: "data",
      total: 119,
    },
    ads: {
      state: "inactive",
      type: "ads",
      subtype: null,
      total: 0,
    },
    mandatsupp: 0,
    usersupp: 0,
    screensupp: 0,
  });

  useEffect(() => {
    initValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initValue = () => {
    if (
      props.record &&
      props.record.abo &&
      props.record.abo &&
      props.record.abo[props.name]
    ) {
      setState((prevState) => ({
        ...prevState,
        ...props.record.abo,
        [props.name]: {
          ...prevState[props.name],
          ...props.record.abo[props.name],
          total:
            props.record.abo[props.name].total === 0 ||
            props.record.abo[props.name].total === undefined
              ? prevState[props.name].total
              : props.record.abo[props.name].total,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        ...props.record.abo,
      }));
    }
    if (
      props.name === "ads" &&
      props.record.abo &&
      props.record.abo &&
      props.record.abo.ads &&
      props.record.abo.ads.subtype
    ) {
      setState((prevState) => ({
        ...prevState,
        [props.name]: {
          ...prevState[props.name],
          total: getAboPrice(props.record.abo.ads.subtype),
        },
      }));
    }
  };

  const handleAddOrRemove = (subcategory, name, setter) => {
    if (setter === "add") {
      setState((prevState) => ({
        ...prevState,

        [subcategory]:
          prevState[subcategory] + (subcategory !== "mandatsupp" ? 1 : 20),
      }));
    } else if (setter === "remove" && state[subcategory] !== 0) {
      setState((prevState) => ({
        ...prevState,

        [subcategory]:
          prevState[subcategory] - (subcategory !== "mandatsupp" ? 1 : 20),
      }));
    }
  };

  const adsAboChange = (name, id, price) => {
    if (state[id].subtype !== name) {
      setState((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          subtype: name,
          state: "waiting",
          total: price,
        },
        print: {
          ...prevState.print,
          type: "print-include",
          total: 0,
          state: "waiting",
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          subtype: null,
          state: "inactive",
          total: 0,
        },
        print: { ...prevState.print, type: "print", state: "inactive" },
      }));
    }
  };
  const aboChange = () => {
    setState((prevState) => ({
      ...prevState,
      [props.name]: {
        ...prevState[props.name],
        state: "waiting",
      },
    }));
  };

  const cancelSubscription = () => {
    setState((prevState) => ({
      ...prevState.abo,
      mandatsupp:
        props.name === "ads" || props.name === "print"
          ? 0
          : prevState.mandatsupp,
      usersupp:
        props.name === "ads" || props.name === "print" ? 0 : prevState.usersupp,
      screensupp: props.name === "display" ? 0 : prevState.screensupp,
      [props.name]: {
        ...prevState[props.name],
        state: "inactive",
        subtype: null,
        total: 0,
      },
      [props.name === "ads" ? "print" : null]:
        props.name === "ads"
          ? { ...prevState.print, type: "print", state: "inactive" }
          : null,
    }));
  };

  const handleClose = () => {
    props.setModal(false);
  };

  const cancelConfirmOrSubscription = () => {
    initValue();
    setConfirm(false);
    setConfirmAction("");
  };

  const cancelSub = () => {
    cancelSubscription();
    setConfirmAction("cancel");
    setConfirm(true);
  };
  const saveSubscription = () => {
    if (props.name !== "ads") {
      aboChange();
    }
    setConfirmAction("save");
    setConfirm(true);
  };

  return (
    <Dialog open={true} className={classes.root}>
      {!confirm ? (
        <DialogContent>
          <ItemManagerContainer>
            <TitleContainer>
              <Title>
                {translate("resources.fields.manageSubscription").toUpperCase()}{" "}
                <span
                  style={{
                    color:
                      props.name === "print"
                        ? PrintColorBackground
                        : props.name === "display"
                        ? DisplayColorBackground
                        : AdsColorBackground,
                  }}
                >
                  VISIBBLE
                </span>
              </Title>
            </TitleContainer>
          </ItemManagerContainer>
          <Divider variant="middle" light style={{ marginBottom: "10px" }} />
          {props.name === "ads" && (
            <ModuleAdsSelect
              adsAboChange={adsAboChange}
              state={state}
              callInfoPage={props.callInfoPage}
            />
          )}
          {props.name !== "display" && (
            <Module
              category={"users"}
              subcategory={"usersupp"}
              placeholder={"resources.agencies.fields.numberofuser.short"}
              secondPlaceholder={
                "resources.agencies.fields.numberofuser.shortbis"
              }
              include={"resources.fields.include"}
              price={5}
              state={state}
              handleAddOrRemove={handleAddOrRemove}
              subtype={state.ads.subtype}
              name={props.name}
            />
          )}
          {props.name === "display" && (
            <Module
              category={"screen"}
              subcategory={"screensupp"}
              placeholder={"resources.agencies.fields.nomberofscreen"}
              secondPlaceholder={
                "resources.agencies.fields.nomberofscreen.short"
              }
              include={"resources.fields.include"}
              price={10}
              state={state}
              handleAddOrRemove={handleAddOrRemove}
              subtype={state.ads.subtype}
              name={props.name}
            />
          )}
          {props.name !== "display" && props.name !== "data" && (
            <Module
              category={"mandats"}
              subcategory={"mandatsupp"}
              placeholder={"resources.agencies.fields.numberofmandats"}
              secondPlaceholder={
                "resources.agencies.fields.numberofmandats.shortbis"
              }
              include={"resources.fields.include"}
              price={0.45}
              state={state}
              handleAddOrRemove={handleAddOrRemove}
              subtype={state.ads.subtype}
              name={props.name}
            />
          )}
          {props.name === "display" && (
            <ModuleFree
              placeholder={"resources.agencies.subscriptiontype"}
              name={props.name}
              state={state}
            />
          )}
          <ModuleBilling
            name={props.name}
            state={state}
            subtype={state.ads.subtype}
          />
          <ActionContainer
            handleClose={handleClose}
            saveSubscription={saveSubscription}
            state={state}
            name={props.name}
          />
          {/* {state[props.name].state !== "inactive" && (
            <>
              <Divider
                variant="middle"
                light
                style={{ marginBottom: "10px" }}
              />
              <ActionContainer action="delete" cancelSubscription={cancelSub} />
            </>
          )} */}
        </DialogContent>
      ) : (
        <Container>
          <ItemContainer width="true">
            <TitleNumberOfUser size="true" align="true">
              {confirmAction === "save"
                ? translate("resources.alert.confirmsave")
                : translate("resources.fields.cancelconfirm")}
            </TitleNumberOfUser>
            <ButtonContainer center="true">
              <ActionBtn
                right="true"
                onClick={() => cancelConfirmOrSubscription()}
              >
                {translate("resources.fields.cancel")}
              </ActionBtn>

              <ApproveButton
                record={props.record}
                setModal={props.setModal}
                modal={props.modal}
                state={state}
                name={props.name}
                translate={translate}
                showMessage={props.showMessage}
                confirmAction={confirmAction}
                refresh={props.refresh}
                context={props.context}
              />
            </ButtonContainer>
          </ItemContainer>
        </Container>
      )}
    </Dialog>
  );
};

export default ItemManager;
