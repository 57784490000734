import { makeStyles } from "@material-ui/core/styles";
import { colorPrimary } from "../../../GlobalStyles";

export const CssTextField = makeStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      color: colorPrimary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colorPrimary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colorPrimary,
    },
    "& input": {
      backgroundColor: "unset !important",
    },
    "& .MuiInputBase-inputMultiline": {
      paddingRight: "43px",
    },
    // inputRight: {
    //   "& input": {
    //     paddingRight: "42px",
    //   },
    // },
  },
  formControl: {
    width: "100%",
  },
}));
