import React from "react";
import { CombineContext } from "../../../../../store/UserProvider";
import Loader from "../../../../Loader/Loader";
import {
  getAgency,
  getLeadsFromApi,
  getProjectFromApi,
  getUsersData,
  getViewData,
} from "../../Utils/axiosRequests";
import {
  buildFilter,
  filterData,
  filterDataView,
  filterTips,
  filterDataLeads,
  filterPourcentPrevious,
  filterPourcentActual,
  filterPourcentPreviousLead,
  filterPourcentActualLead,
} from "../../Utils/filterTips";

function DashboardWrapper(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this._isMounted = false;
      this.state = {
        data: [],
        leads: [],
        viewData: [],
        isUserData: false,
        loading: true,
        users: false,
        filter: {
          userId: [],
        },
        filterDate: {
          min: null,
          max: null,
        },
        pourcentShow: true,
        countDate: 0,
      };
    }

    static contextType = CombineContext;

    reset = () => {
      this.setState({ loading: true, countDate: 0, filter: { userId: [] } });
    };

    handleChangeFilter = (value) => {
      this.setState((prev) => ({
        filter: { ...prev.filter, ...value },
      }));
      // this.refresh();
    };

    handleFilterUser = async (category, value) => {
      if (value !== "") {
        this.setState({ isUserData: true });
      } else {
        this.setState({ isUserData: false });
      }
      this.setState({ isReset: false });
      filterTips(category, value, this.state.filter, this.handleChangeFilter);
    };

    handleFilterDate = async (category, value) => {
      this.setState((prev) => ({
        filterDate: { ...prev.filterDate, [category]: value },
        countDate: prev.countDate + 1,
      }));
    };

    refresh = async () => {
      this.reset();
      const user = await this.context;
      let userSelect;
      if (user.valueChange.userChange) {
        userSelect = user.valueChange.userChange;
      } else {
        userSelect = user.value.user;
      }
      let query;
      let queryValue;

      if (userSelect.agenceId) {
        query = "agenceId";
        queryValue = userSelect.agenceId;
      } else {
        query = "userId";
        queryValue = userSelect._id;
      }
      if (userSelect) {
        const agency = await getAgency(userSelect.agenceId);
        this.setState({ agency: agency.data });
        const fetchUser = await getUsersData(queryValue);
        this.setState({ users: fetchUser.data });
        const fetchLead = await getLeadsFromApi(query, queryValue);
        this.setState({ leads: fetchLead });
        const fetch = await getProjectFromApi(query, queryValue);
        if (!fetch.data.data) {
          this.setState({ data: [] });
        } else {
          this.setState({ data: fetch.data.data });
        }
        const fetchView = await getViewData(queryValue);
        if (fetchView && fetchView.data.length > 0) {
          this.setState({ viewData: fetchView.data });
        }

        this.setState({ loading: false });
      }
    };

    async componentDidMount() {
      this._isMounted = true;
      this._isMounted && (await this.refresh());
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    render() {
      if (this.state.loading) {
        return <Loader />;
      } else {
        let queryfilLeads = buildFilter(this.state.filter);
        let resultLeads = filterData(this.state.leads, queryfilLeads);
        let resultLeadsWithDate = filterDataLeads(
          resultLeads,
          this.state.filterDate
        );
        let queryfil = buildFilter(this.state.filter);
        let resultProject = filterData(this.state.data, queryfil);
        let resultViewUserId = filterData(this.state.viewData, queryfil);
        let resultViewData = filterDataView(
          resultViewUserId,
          this.state.filterDate
        );
        let pourcent = filterPourcentPrevious(
          resultViewUserId,
          this.state.filterDate
        );
        let pourcentActual = filterPourcentActual(
          resultViewUserId,
          this.state.filterDate
        );
        let pourcentLead = filterPourcentPreviousLead(
          resultLeads,
          this.state.filterDate
        );
        let actualPourcentLead = filterPourcentActualLead(
          resultLeads,
          this.state.filterDate
        );

        return (
          <WrappedComponent
            {...this.state}
            leadsData={resultLeadsWithDate}
            projects={resultProject}
            viewData={resultViewData}
            pourcent={pourcent}
            actualPourcent={pourcentActual}
            pourcentLead={pourcentLead}
            actualPourcentLead={actualPourcentLead}
            handleFilterUser={this.handleFilterUser}
            handleFilterDate={this.handleFilterDate}
            pourcentShow={this.state.countDate < 2 ? true : false}
            refresh={this.refresh}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
}

export default DashboardWrapper;
