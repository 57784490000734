import React, { useState } from "react";
import { MenuItem, TableCell, TableRow } from "@material-ui/core";
import { SelectUser, ShowIcon } from "../../../Utils/Styles/Agency.styles";
import { useRedirect } from "ra-core";
import { useAlert } from "react-alert";
import { choices } from "../../../../Project/Utils/dataChoices";
import { useTranslate } from "react-admin";

const ProjectSmallRow = (props) => {
  const alert = useAlert();
  const translate = useTranslate();
  const redirect = useRedirect();
  const { row, users, changeUserInCharge } = props;
  const [value, setValue] = useState(false);

  const handleChange = async (event) => {
    setValue(event.target.value);
    const response = await changeUserInCharge(row._id, event.target.value);
    if (response.status === 200) {
      alert.show(translate("resources.alert.attributionsuccess"));
    }
  };

  const getCategory = (value) => {
    const result = choices.category.filter((obj) => {
      return obj.id === value;
    });
    return result;
  };

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell align={"center"}>
        {<ShowIcon onClick={() => redirect(`/projects/${row._id}`)} />}
      </TableCell>
      <TableCell align={"center"}>{row.slug.substr(0, 40) + "..."}</TableCell>
      <TableCell align={"center"}>{row.reference}</TableCell>
      <TableCell align={"center"}>
        {row.category && row.category !== ""
          ? translate(getCategory(row.category)[0].name)
          : ""}
      </TableCell>
      <TableCell align={"center"}>{`${row.price} €`}</TableCell>
      <TableCell align={"center"}>{row.rooms}</TableCell>
      <TableCell align={"center"}>
        {users.length ? (
          <SelectUser
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value ? value : row.userId}
            onChange={handleChange}
            fullWidth
            disableUnderline
          >
            {users
              .sort(function (a, b) {
                return a.firstname.localeCompare(b.firstname);
              })
              .map((item) => {
                return (
                  <MenuItem key={item._id} value={item._id}>
                    {`${item.firstname} ${item.lastname}`}
                  </MenuItem>
                );
              })}
          </SelectUser>
        ) : (
          translate("resources.fields.noresultusers")
        )}
      </TableCell>
    </TableRow>
  );
};

export default ProjectSmallRow;
