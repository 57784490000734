import styled from "styled-components";
import { colorTertiary } from "../../../GlobalStyles";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1999;
  padding: 1rem;
  width: 90%;
  max-width: 600px;
  background-color: #fff;
`;
export const TitleForm = styled.p`
  margin: 0.5rem 0;
`;

export const InputContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  margin-bottom: 1.5rem;
  border-radius: 5px;
  border: solid 1px #dbdada;
  padding: 0.4rem 0.6rem;
`;
export const Button = styled.button`
  background-color: ${colorTertiary};
  color: white;
  border-radius: 5px;
  width: 90%;
  padding: 0.75rem;
  font-weight: 700;
  border: solid 1px transparent;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%);

  &:focus {
    box-shadow: none;
    outline: none;
  }
`;
