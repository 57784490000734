import React, { useState } from "react";
import { Box, Divider } from "@material-ui/core";
import { useTranslate } from "react-admin";
import Price from "./InfoPrice/Price";
import Item from "./AboItems/Item";
import { TitleSection } from "./Utils/Styles/Agency.styles";
import {
  colorPrimary,
  colorSecondary,
  colorTertiary,
  visibbledataColor,
} from "../../GlobalStyles";

const FormAbo = (props) => {
  const translate = useTranslate();
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const callInfoPage = (index) => {
    setIndex(index);
    setShow(true);
  };

  return (
    <Box flex={2} mb="20px">
      <TitleSection
        variant="h6"
        gutterBottom
        $uppercase={props.$uppercase}
        $size={props.$size}
      >
        {translate("resources.agencies.abo")}
      </TitleSection>
      {!props.noDivider && <Divider light style={{ marginBottom: "10px" }} />}

      <Box
        display={{ xs: "block", md: "block", sm: "flex" }}
        flexWrap="wrap"
        justifyContent={"flex-start"}
      >
        {/* ADS MODULE */}
        <Item
          callInfoPage={callInfoPage}
          name={"ads"}
          {...props}
          logo={
            "https://visibble.io/wp-content/uploads/2021/07/Fichier-1licence.svg"
          }
          text={translate("resources.agencies.fields.abo.360.text")}
          desc={translate("resources.agencies.fields.abo.360.desc")}
          color={colorTertiary}
        />
        {/* PRINT MODULE */}
        <Item
          callInfoPage={callInfoPage}
          name={"print"}
          {...props}
          logo={"https://visibble.io/wp-content/uploads/2021/07/pintlogo.svg"}
          text={translate("resources.agencies.fields.abo.print.text")}
          desc={translate("resources.agencies.fields.abo.print.desc")}
          color={colorSecondary}
        />
        {/* DISPLAY MODULE */}
        <Item
          callInfoPage={callInfoPage}
          name={"display"}
          {...props}
          logo={
            "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-DISPLAY.png"
          }
          text={translate("resources.agencies.fields.abo.display.text")}
          desc={translate("resources.agencies.fields.abo.display.desc")}
          color={colorPrimary}
        />
        <Item
          callInfoPage={callInfoPage}
          name={"data"}
          {...props}
          logo={
            "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-DATA-768x169.png"
          }
          text={translate("resources.agencies.fields.abo.data.text")}
          desc={translate("resources.agencies.fields.abo.data.desc")}
          color={visibbledataColor}
        />
      </Box>
      {show && <Price setShow={setShow} index={index} />}
    </Box>
  );
  // }
};

export default FormAbo;
