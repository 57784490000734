import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { choices } from "../../PrintPage/Utils/dataChoices";
import { AlurText } from "./ModulesElements.styles";

const ModuleAlurDetails = (props) => {
  const { t } = useTranslation();
  const [currency] = useState(
    props.project.currency ? props.project.currency : "€"
  );

  return (
    <>
      {props.project.nblotcopro > 0 ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.copropriete")} {props.project.nblotcopro}{" "}
          {t("modalprint.lots")} - {t("modalprint.feescopro")} :{" "}
          {props.project.feescopro
            ? props.project.feescopro + currency
            : t("modalprint.undisclosed")}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("details.modalprint.monthlycharge")} :{" "}
          {props.project.monthlycharge > 0
            ? props.project.monthlycharge + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.fees")} :{" "}
          {props.project.fees > 0
            ? props.project.fees + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {(props.project.commission || props.project.sharedCommission) &&
      props.project.category === choices.category[0].id ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("details.modalprint.commission")} :{" "}
          {props.project.fees !== ""
            ? props.project.fees +
              (props.project.feespercent ? " %" : " " + currency)
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {(props.project.sharedCommission || props.project.commission) &&
      props.project.category === choices.category[0].id &&
      props.project.amountWithoutHonorary ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.excludefees")} : {props.project.amountWithoutHonorary}{" "}
          {currency}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.feesetatdeslieux")} :{" "}
          {props.project.feesetatdeslieux > 0
            ? props.project.feesetatdeslieux + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={props.colorDesc}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("details.modalprint.feesdepotgarantie")} :{" "}
          {props.project.feesdepotgarantie > 0
            ? props.project.feesdepotgarantie + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[0].id &&
        props.project.type !== choices.type[1].id &&
        props.project.type !== choices.type[2].id &&
        props.project.type !== choices.type[3].id && (
          <AlurText
            colorDesc={props.colorDesc}
            smallMarginBottom={props.smallMarginBottom}
            sizeText={props.sizeText}
          >
            {props.project.procsyndicale
              ? "Procédure syndicale en cours"
              : "Pas de procédure en cours"}
          </AlurText>
        )}

      {!props.noDpeInfo && (
        <>
          <AlurText
            colorDesc={props.colorDesc}
            smallMarginBottom={props.smallMarginBottom}
            sizeText={props.sizeText}
          >
            {t("modalprint.dpeMinMaxInfo")} :{" "}
            {props.project.dpeCoutMinConso &&
            props.project.dpeCoutMinConso !== "" ? (
              <>
                {`${t("modalprint.between")} ${
                  props.project.dpeCoutMinConso
                }€ ${t("modalprint.and")} ${props.project.dpeCoutMaxConso}€ ${t(
                  "modalprint.perYear"
                )} `}
              </>
            ) : (
              t("details.modalprint.undisclosed")
            )}
          </AlurText>
          {props.project.dpeCoutMinConso &&
            props.project.dpeCoutMinConso !== "" &&
            props.project.dpeAnneRefConso &&
            props.project.dpeAnneRefConso !== "" && (
              <AlurText
                colorDesc={props.colorDesc}
                smallMarginBottom={props.smallMarginBottom}
                sizeText={props.sizeText}
              >
                {t("modalprint.refYear")} {props.project.dpeAnneRefConso}{" "}
                {t("modalprint.refYearEnd")}
              </AlurText>
            )}
        </>
      )}
    </>
  );
};

export default ModuleAlurDetails;
