import React, { useContext } from "react";
import "./Utils/Styles/index.css";
import DashboardView from "./DashboardComponents/Dashboard360/DashboardView";
import { useGetIdentity } from "ra-core";
import DashboardViewAdmin from "./DashboardComponents/Admin/DashboardViewAdmin";
import { CombineContext } from "../../../store/UserProvider";
import DashboardViewPrint from "./DashboardComponents/BashboardPrint/DashboardViewPrint";

const Dashboard = (props) => {
  const data = useContext(CombineContext);
  const { identity, loading: identityLoading } = useGetIdentity();
  const role = ["super_admin", "admin"];
  if (identityLoading) return <></>;
  if (!role.includes(identity.permissions)) {
    if (data.typeValue.type === "360") {
      return <DashboardView {...props} />;
    } else {
      return (
        <DashboardViewPrint
          {...props}
          agency={data.agencyValue.agency}
          type={data.typeValue.type}
        />
      );
    }
  } else {
    return <DashboardViewAdmin />;
  }
};

export default Dashboard;
