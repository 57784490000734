import React from "react";
// CSS
import {
  Container,
  TopBanner,
  ContainerLogo,
  Logo,
  ContainerImg,
  ImgProperty,
  ContainerDescription,
  ContainerTitle,
  Title,
  Line1,
  ContainerResume,
  Resume,
  Line2,
  ContainerBottom,
  ContainerBottomLeft,
  ContainerBottomRight,
  Ref,
  ContainerGallery,
  Gallery,
  SubTitle,
  Gallery2,
  Img,
  QrCodeContainerInner,
  IconContainer,
  IconText,
  IconsContainer,
  ContainerDescTop,
  ContainerPrice,
  ExcluContainer,
  ExcluText,
  DpeTextContainer,
  AlurText,
} from "./CupertinoPortrait.styles";
import QrcodeContainer from "./QrcodeContainer";
import QRCode from "react-qr-code";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { useTranslation } from "react-i18next";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import ModulePrice from "../../Modules/ModulePrice";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText as AlurMore } from "../../Modules/ModulesElements.styles";

const CuppertinoPreviewPortrait = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
    imageSupp,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <TopBanner>
        <ContainerLogo>
          {agency.images && agency.images.length ? (
            <Logo src={agency.images[0].src} />
          ) : null}
        </ContainerLogo>
      </TopBanner>
      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <>
          <QrcodeContainer />
          <QrCodeContainerInner>
            <QRCode
              value={qrcodeEdit}
              size={105}
              style={{
                position: "absolute",
                zIndex: "20",
                right: "121pt",
                top: "28pt",
              }}
            />
          </QrCodeContainerInner>
        </>
      )}

      <ContainerImg>
        {isBanner === "true" && (
          <ExcluContainer>
            <ExcluText
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </ExcluText>
          </ExcluContainer>
        )}
        <ImgProperty
          src={imageFirst}
          brightness={brightness}
          saturation={saturation}
        />
        <ContainerGallery>
          <Gallery>
            <Img
              src={imageSupp[0]}
              brightness={brightness < 110 ? brightness : brightness - 10}
              saturation={saturation}
            />
          </Gallery>
          <Gallery2>
            <Img
              src={imageSupp.length > 1 ? imageSupp[1] : imageSupp[0]}
              brightness={brightness < 110 ? brightness : brightness - 10}
              saturation={saturation}
            />
          </Gallery2>
        </ContainerGallery>
      </ContainerImg>
      <ContainerDescription>
        <ContainerDescTop>
          <ContainerTitle>
            <Title
              colorTitle={colorTitle !== "" ? colorTitle : "black"}
              size={titleTextFont.size}
              weight={titleTextFont.weight}
              family={titleTextFont.font}
            >
              {project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </Title>
            <SubTitle
              size={subTitleTextFont.size}
              weight={subTitleTextFont.weight}
              family={subTitleTextFont.font}
              colorSubTitle={colorSubTitle !== "" ? colorSubTitle : "black"}
            >
              {subTitleText}
            </SubTitle>
            <Ref
              size={refFont.size}
              weight={refFont.weight}
              family={refFont.font}
              color={refFont.color}
            >
              réf : {refText}
            </Ref>
          </ContainerTitle>
          <ContainerPrice>
            <ModulePrice
              colorPrice={colorPrice || "black"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </ContainerPrice>
        </ContainerDescTop>
        <Line1></Line1>
        <ContainerResume>
          <Resume
            colorDesc={colorDesc !== "" ? colorDesc : "black"}
            size={descFont.size}
            weight={descFont.weight}
            family={descFont.font}
          >
            {desc}
          </Resume>
        </ContainerResume>
        <Line2></Line2>
        <ContainerBottom>
          <ContainerBottomLeft
            colorDesc={colorDesc !== "" ? colorDesc : "black"}
          >
            {/* ALUR DETAILS */}
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={colorDesc || "black"}
                price={price}
                sizeText={"12pt"}
                noDpeInfo
              />
              <AlurMore sizeText={"12pt"} colorDesc={colorDesc || "black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurMore>
            </RenderContext>
            <IconsContainer colorPrimary={color.primary || "black"}>
              <IconContainer>
                <Surface color={colorSecondary || "white"} size={55} />
                <IconText colorSecondary={colorSecondary || "white"}>
                  {project.area.total} m2
                </IconText>
              </IconContainer>
              {project.rooms && project.rooms > 0 ? (
                <IconContainer>
                  <Rooms color={colorSecondary || "white"} size={55} />
                  <IconText colorSecondary={colorSecondary || "white"}>
                    {project.rooms} {t("modalprint.rooms")}
                  </IconText>
                </IconContainer>
              ) : null}
              {project.bedrooms && project.bedrooms > 0 ? (
                <IconContainer>
                  <Chambres color={colorSecondary || "white"} size={55} />
                  <IconText colorSecondary={colorSecondary || "white"}>
                    {project.bedrooms} {t("modalprint.bedrooms")}
                  </IconText>
                </IconContainer>
              ) : null}
              {project.sdb && project.sdb > 0 ? (
                <IconContainer>
                  <Sdb color={colorSecondary || "white"} size={55} />
                  <IconText colorSecondary={colorSecondary || "white"}>
                    {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
                  </IconText>
                </IconContainer>
              ) : null}
            </IconsContainer>
          </ContainerBottomLeft>
          <ContainerBottomRight>
            <RenderContext project={project}>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top:
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "85px"
                      : "45px",
                  left:
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "100px"
                      : "38px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.22"
                      : "0.28"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "37px"
                      : "-40px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "400px"
                      : "410px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.50"
                      : "0.34"
                  }
                  theme={"light"}
                />
              </div>
              <DpeTextContainer>
                <AlurText colorDesc={colorDesc}>
                  {t("modalprint.dpeMinMaxInfo")} :{" "}
                  {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                    <>
                      {`${t("modalprint.between")} ${
                        project.dpeCoutMinConso
                      }€ ${t("modalprint.and")} ${project.dpeCoutMaxConso}€ ${t(
                        "modalprint.perYear"
                      )} `}
                    </>
                  ) : (
                    t("details.modalprint.undisclosed")
                  )}{" "}
                  {project.dpeCoutMinConso &&
                    project.dpeCoutMinConso !== "" &&
                    project.dpeAnneRefConso &&
                    project.dpeAnneRefConso !== "" &&
                    t("modalprint.refYear") +
                      " " +
                      project.dpeAnneRefConso +
                      " " +
                      t("modalprint.refYearEnd")}
                </AlurText>
              </DpeTextContainer>
            </RenderContext>
          </ContainerBottomRight>
        </ContainerBottom>
      </ContainerDescription>
    </Container>
  );
});

export default CuppertinoPreviewPortrait;
