import React, { useEffect, useState } from "react";
import { ModalManager } from "../../../../../../Utils/Styles/ModalManager.mui.styles";
import { Dialog, DialogContent, Divider } from "@material-ui/core";
import {
  BillingManagerContainer,
  TitleContainer,
  Title,
  Container,
  ItemContainer,
  Label,
  ButtonContainer,
  ActionBtn,
} from "../../../../../../Utils/Styles/BillingInformation.styles";
import ActionContainer from "./ActionContainer";
import ModuleRib from "./Iban/ModuleRib";
import ModuleSelect from "./ModuleSelect";
import ApproveButton from "./ApprouveBtn";
import { useTranslate } from "react-admin";

const BillingAdressManager = (props) => {
  const translate = useTranslate();
  const [confirmAction, setConfirmAction] = useState(false);
  const classes = ModalManager();
  const [state, setState] = useState({
    rib: {
      iban: "",
      bic: "",
    },
    stripe: { active: false, stripeId: null },
  });

  useEffect(() => {
    if (
      props.record &&
      props.record.billingInformation &&
      props.record.billingInformation.billingMethod
    ) {
      setState({
        ...props.record.billingInformation.billingMethod,
        stripe: {
          active: false,
        },
        rib:
          props.record.billingInformation.billingMethod.rib === null
            ? { iban: "", bic: "" }
            : { ...props.record.billingInformation.billingMethod.rib },
      });
    }
  }, [props.record]);

  const saveAddress = () => {
    setConfirmAction(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      stripe: null,
      rib: {
        ...prevState.rib,
        [id]: value,
      },
    }));
  };

  const setMode = (mode) => {
    setState((prevState) => ({
      ...prevState,
      mode,
    }));
  };

  const handleClose = () => {
    props.setModal(false);
  };

  return (
    <Dialog open={true} className={classes.root}>
      {!confirmAction ? (
        <DialogContent>
          <BillingManagerContainer>
            <TitleContainer>
              <Title>
                {translate(
                  "resources.fields.manageBillingMethod"
                ).toUpperCase()}
              </Title>
            </TitleContainer>
          </BillingManagerContainer>
          <Divider variant="middle" light style={{ marginBottom: "10px" }} />
          <ModuleSelect state={state} setMode={setMode} />

          {state.mode === "debit" && (
            <ModuleRib handleChange={handleChange} state={state} />
          )}

          <ActionContainer
            handleClose={handleClose}
            saveAddress={saveAddress}
            aboValue={props.aboValue}
            state={state}
          />
        </DialogContent>
      ) : (
        <Container>
          <ItemContainer width="true">
            <Label size="true" align="true">
              {translate("resources.alert.confirmsave")}
            </Label>
            <ButtonContainer center="true">
              <ActionBtn right="true" onClick={() => setConfirmAction(false)}>
                {translate("resources.fields.cancel")}
              </ActionBtn>

              <ApproveButton
                record={props.record}
                setModal={props.setModal}
                aboValue={props.aboValue}
                state={state}
                translate={translate}
                showMessage={props.showMessage}
                confirmAction={confirmAction}
              />
            </ButtonContainer>
          </ItemContainer>
        </Container>
      )}
    </Dialog>
  );
};

export default BillingAdressManager;
