import React from "react";
import { TextField } from "@material-ui/core";
import {
  ModuleAdressContainer,
  FormGroup,
} from "../../../../../../../Utils/Styles/BillingInformation.styles";
import { useTranslate } from "ra-core";
import { CssTextField } from "../../../../../../../Utils/Styles/input.mui.styles";
import { formatIban } from "./formatIban";
import IBAN from "iban";

const ModuleRib = (props) => {
  const translate = useTranslate();
  const classesInput = CssTextField();

  return (
    <ModuleAdressContainer className={classesInput.root}>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="iban"
          label={translate("resources.agencies.fields.iban")}
          onChange={props.handleChange}
          value={formatIban(props.state.rib.iban)}
          error={
            props.state.rib.iban !== "" &&
            IBAN.isValid(props.state.rib.iban) === false
          }
          helperText={
            props.state.rib.iban !== "" &&
            IBAN.isValid(props.state.rib.iban) === false
              ? translate("resources.agencies.errorIban")
              : " "
          }
        />
      </FormGroup>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="bic"
          label={translate("resources.agencies.fields.bic")}
          onChange={props.handleChange}
          value={props.state.rib.bic}
        />
      </FormGroup>
    </ModuleAdressContainer>
  );
};

export default ModuleRib;
