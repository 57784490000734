import React, { useEffect, useState } from "react";
import {
  BoxContent,
  BoxNumber,
  BoxNumberContainer,
  BoxTitle,
  BoxTitleContainer,
} from "../../Utils/Styles/DashboardComponents.styles";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import { CircularProgress, Fade } from "@material-ui/core";
const DashboardBox = (props) => {
  const { number, title } = props;
  const [pourcent, setPourcent] = useState("0%");

  useEffect(() => {
    setPourcent(`${relDiff(props.pourcent, props.actualPourcent)}`);
  }, [props]);

  function relDiff(a, b) {
    if (a === 0) {
      a = 1;
      b = b + 1;
    }
    const number = 100 * ((b - a) / a);
    const finalNumber = number.toFixed(0);
    if (finalNumber > -1000 && finalNumber < 1000) {
      return finalNumber + "%";
    } else {
      return "∞";
    }
  }
  return (
    <BoxContent>
      <BoxTitleContainer>
        <BoxTitle>{title}</BoxTitle>
      </BoxTitleContainer>
      <BoxNumberContainer>
        <BoxNumber>
          {!props.loading ? number : <CircularProgress size={20} />}
        </BoxNumber>
      </BoxNumberContainer>
      <Fade in={props.pourcentShow} timeout={400} unmountOnExit={true}>
        <div className="content--poucentage-container text-center">
          <p
            className={`content--pourcentage ${
              props.pourcent > props.actualPourcent ? "color-down" : "color-up"
            }`}
            style={{ marginRight: pourcent !== "0%" ? "2.7rem" : "unset" }}
          >
            {pourcent !== "0%" && (
              <span className="content--icon">
                {props.pourcent > props.actualPourcent ? (
                  <TrendingDownIcon style={{ fontSize: "2.5rem" }} />
                ) : (
                  <TrendingUpIcon style={{ fontSize: "2.5rem" }} />
                )}
              </span>
            )}
            {pourcent}
          </p>
        </div>
      </Fade>
    </BoxContent>
  );
};

export default DashboardBox;
