import React from "react";
import QRCode from "react-qr-code";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import ModulePrice from "../../Modules/ModulePrice";
import {
  Container,
  ImgContainer,
  ImgProperty,
  ContainerDescription,
  ContainerDescription1,
  Title,
  Location,
  ContainerDescription2,
  ContainerDescription3,
  ContainerDescription3Top,
  ContainerDescription3Bottom,
  Description,
  Ref,
  IconContainer,
  IconText,
  QrcodeContainer,
  AlurContainer,
  ContainerBottomLeft,
  ContainerBottomRight,
  LogoContainer,
  LogoAgence,
  PriceContainer,
  ExcluContainer,
  ExcluText,
  DpeTextContainer,
  AlurText,
} from "./ParisTemplatePortrait.styles";
import { useTranslation } from "react-i18next";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText as AlurMore } from "../../Modules/ModulesElements.styles";

const ParisTemplatePortrait = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    qrcode,
    qrcodeMode,
    colorSecondary,
    titleTextFont,
    subTitleTextFont,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      mirror={mirror}
      ref={ref}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-block d-none"}
    >
      <ImgContainer>
        <ImgProperty
          src={imageFirst}
          brightness={brightness}
          saturation={saturation}
        />
        {isBanner === "true" && (
          <ExcluContainer colorPrimary={color.primary || "#beaf87"}>
            <ExcluText
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </ExcluText>
          </ExcluContainer>
        )}
        <LogoContainer>
          {agency.images && agency.images.length ? (
            <LogoAgence src={agency.images[0].src} alt="logo agence" />
          ) : null}
        </LogoContainer>
        <PriceContainer colorPrimary={color.primary || "#beaf87"}>
          <ModulePrice
            colorPrice={colorPrice || "white"}
            price={price}
            project={project}
            align={"left"}
            sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
            weight={priceFont.weight}
            family={priceFont.font}
            sizeHonoraire={"12pt"}
            sizeSpanPrice={"17pt"}
            nextline
          />
        </PriceContainer>
      </ImgContainer>

      <ContainerDescription colorSecondary={colorSecondary || "#F0F0F0"}>
        <ContainerDescription1>
          <Title
            colorTitle={colorTitle || "#000000"}
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            {project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </Title>
          <Location
            colorSubTitle={colorSubTitle || "#BEAF87"}
            size={subTitleTextFont.size}
            weight={subTitleTextFont.weight}
            family={subTitleTextFont.font}
          >
            {subTitleText}
          </Location>
        </ContainerDescription1>
        <ContainerDescription2>
          <IconContainer>
            <Surface color={color.primary || "#beaf87"} size={45} />
            <IconText colorDesc={colorDesc || "black"}>
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Rooms color={color.primary || "#beaf87"} size={45} />
              <IconText colorDesc={colorDesc || "black"}>
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Chambres color={color.primary || "#beaf87"} size={45} />
              <IconText colorDesc={colorDesc || "black"}>
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Sdb color={color.primary || "#beaf87"} size={45} />
              <IconText colorDesc={colorDesc || "black"}>
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </ContainerDescription2>
      </ContainerDescription>
      <ContainerDescription3>
        <ContainerDescription3Top>
          <Description
            colorDesc={colorDesc !== "" ? colorDesc : "#000000"}
            size={descFont.size}
            weight={descFont.weight}
            family={descFont.font}
          >
            {desc}
          </Description>
        </ContainerDescription3Top>

        <ContainerDescription3Bottom>
          <ContainerBottomLeft>
            <Ref
              size={refFont.size}
              weight={refFont.weight}
              family={refFont.font}
              color={refFont.color}
            >
              REF: {refText}
            </Ref>
            <AlurContainer>
              <RenderContext project={project}>
                <ModuleAlurDetails
                  project={project}
                  colorDesc={colorDesc || "black"}
                  price={project.price}
                  sizeText={"12pt"}
                  noDpeInfo
                />
                <AlurMore sizeText={"12pt"} colorDesc={colorDesc || "black"}>
                  Les informations sur les risques auxquels ce bien est exposé
                  sont disponibles sur le site Géorisques :
                  http://www.georisques.gouv.fr
                </AlurMore>
              </RenderContext>
            </AlurContainer>
            {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
              <QrcodeContainer>
                <QRCode value={qrcodeEdit} size={130} />
              </QrcodeContainer>
            )}
          </ContainerBottomLeft>
          <ContainerBottomRight>
            <RenderContext project={project}>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "80px",
                  left: "90px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.22"
                      : "0.25"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "85px"
                      : "10px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "360px"
                      : "415px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.50"
                      : "0.30"
                  }
                  theme={"light"}
                />
              </div>
              <DpeTextContainer>
                <AlurText colorDesc={colorDesc}>
                  {t("modalprint.dpeMinMaxInfo")} :{" "}
                  {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                    <>
                      {`${t("modalprint.between")} ${
                        project.dpeCoutMinConso
                      }€ ${t("modalprint.and")} ${project.dpeCoutMaxConso}€ ${t(
                        "modalprint.perYear"
                      )} `}
                    </>
                  ) : (
                    t("details.modalprint.undisclosed")
                  )}{" "}
                  {project.dpeCoutMinConso &&
                    project.dpeCoutMinConso !== "" &&
                    project.dpeAnneRefConso &&
                    project.dpeAnneRefConso !== "" &&
                    t("modalprint.refYear") +
                      " " +
                      project.dpeAnneRefConso +
                      " " +
                      t("modalprint.refYearEnd")}
                </AlurText>
              </DpeTextContainer>
            </RenderContext>
          </ContainerBottomRight>
        </ContainerDescription3Bottom>
      </ContainerDescription3>
    </Container>
  );
});

export default ParisTemplatePortrait;
