import styled from "styled-components";

export const Container = styled.div`
  && {
    position: relative;
    height: 296.5mm;
    width: 420mm;
    min-width: 420mm;
    background: #000000 0% 0% no-repeat padding-box;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    overflow: hidden;
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;
export const ImageFirst = styled.img`
  position: absolute;
  top: 6px;
  left: 5px;
  object-fit: cover;
  width: 785px;
  height: 528px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const ImageSuppSmall = styled.img`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  object-fit: cover;
  width: 388px;
  height: 272px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const ImageSupp = styled.img`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  object-fit: cover;
  width: 784px;
  height: 260px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const ImageBottom = styled.img`
  position: absolute;
  top: 1040px;
  left: 30px;
  width: 1532px;
  height: 43px;
`;
export const ImagePing = styled.img`
  position: absolute;
  top: 852px;
  left: 622px;
  width: 53px;
  height: 36.5px;
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 180px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
`;

export const QrcodeContainer = styled.div`
  width: 115px;
  height: 115px;
  background-color: white;
  position: absolute;
  top: 895.58px;
  left: 1445.15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Logo = styled.img`
  width: 100%;
`;

export const DpeContainer = styled.div`
  position: absolute;
  top: 822px;
  left: 18px;
  width: 510px;
  height: 241px;
  display: flex;
  overflow: hidden;
`;

export const EnergyTextContainer = styled.div`
  position: absolute;
  top: 910px;
  left: 541px;
  display: flex;
  flex-direction: column;
  width: 239px;
  height: 153px;
`;

export const DpeText = styled.p`
  margin-bottom: 0px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif !important;
`;

export const DescContainer = styled.div`
  width: 784px;
  height: 230px;
  position: absolute;
  top: 648px;
  left: 797px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const DescText = styled.p`
  color: ${(props) => props.colorDesc};
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 115%;
  text-align: left;
  white-space: pre-wrap;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "20px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const SoldBanner = styled.div`
  height: 63.8px;
  min-width: 519.2px;
  position: absolute;
  top: 40px;
  left: -180px;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  transform: rotate(-45deg);
`;
export const SoldBannerSecond = styled.div`
  height: 63.8px;
  min-width: 590px;
  position: absolute;
  top: 96px;
  left: -160px;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  transform: rotate(-45deg);
`;
export const SoldBannerText = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "#F1B2CD")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "35px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "900")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const SoldBannerTextSecond = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "35px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "900")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const BottomContainer = styled.div`
  width: 880px;
  height: 235px;
  position: absolute;
  bottom: 30px;
  left: 225px;
`;
export const TitleContainer = styled.div``;
export const TitleText = styled.h2`
  margin-bottom: 0;
  color: ${(props) => props.colorTitle};
  margin-top: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "30px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 541px;
  left: 797px;
  width: 810px;
  height: 96px;
  padding: 0 5px;
`;
export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  align-items: center;
`;
export const IconText = styled.p`
  font-size: 14px;
  color: white;
  margin-bottom: 0;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif !important;
`;

export const BottomLastContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const PriceContainer = styled.div`
  width: 396px;
  height: 41px;
  position: absolute;
  left: 797px;
  top: 1022px;
`;

export const Price = styled.p`
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.family};
  color: ${(props) => props.colorPrice};
  margin-bottom: 0;
  font-weight: ${(props) => props.weight};
`;
export const Honoraire = styled.p`
  color: ${(props) => props.colorPrice};
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.family};
  text-align: left;
  font-weight: ${(props) => props.weight};
  margin-top: 0;
  margin-bottom: 2px;
`;
export const AlurContainer = styled.div`
  width: 303px;
  height: 100px;
  position: absolute;
  left: 810px;
  top: 910px;
`;
export const HonoraryContainer = styled.div`
  width: 303px;
  height: 100px;
  position: absolute;
  left: 1130px;
  top: 910px;
`;
export const AlurText = styled.p`
  && {
    color: white;
    font-size: 15px;
    font-family: "Open Sans", sans-serif !important;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 400;
  }
`;
export const CoproContainer = styled.div`
  padding-left: 35px;
`;

export const SecondTitle = styled.h3`
  font-size: 26px;
  color: ${(props) => (props.colorTitle2 !== "" ? props.colorTitle2 : "black")};
  margin-bottom: 15px;
  margin-top: 8px;
  font-weight: 400;
  font-family: "Lato", sans-serif !important;
`;
