import styled from "styled-components";

export const Container = styled.div`
  && {
    border: ${(props) => (props.isPreview ? "solid 1px" : "unset")};
    position: relative;
    height: 296.5mm;
    width: 420mm;
    min-width: 420mm;
    background: white;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    overflow: hidden;
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;
export const BackgroundImage = styled.img`
  position: absolute;
  top: 85px;
  left: 29px;
  object-fit: cover;
  width: 1529px;
  height: 733px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const LogoContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 1313px;
  width: 245px;
  height: 63px;
  display: flex;
  justify-content: center;
`;

export const QrcodeContainer = styled.div`
  width: 128px;
  height: 128px;
  background-color: white;
  position: absolute;
  top: 691px;
  left: 1430px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Logo = styled.img`
  width: 100%;
`;

export const DpeContainer = styled.div`
  position: absolute;
  top: 801px;
  left: 29px;
  width: 496px;
  height: 249px;
  overflow: hidden;
  display: flex;
`;

export const EnergyTextContainer = styled.div`
  position: absolute;
  top: 1045px;
  left: 40px;
  width: 410px;
`;

export const DpeText = styled.p`
  margin-bottom: 5px;
  color: black;
  font-size: 12px;
  font-family: "Montserrat", sans-serif !important;
`;
export const SoldBanner = styled.div`
  height: 142px;
  min-width: 156px;
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;
export const SoldBannerText = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "30px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "500")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Lato', sans-serif"} !important;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  top: 10px;
  left: 29px;
`;
export const TitleText = styled.h2`
  margin-bottom: 0;
  color: ${(props) => props.colorTitle};
  margin-top: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "60px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "600")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Montserrat', sans-serif"} !important;
`;
export const SubTitleText = styled.h2`
  margin-bottom: 10px;
  color: ${(props) => props.colorTitle};
  margin-top: 0;
  margin-left: 30px;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "25px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "600")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Montserrat', sans-serif"} !important;
`;
export const IconsContainer = styled.div`
  position: absolute;
  top: 841px;
  left: 1120px;
  width: 130px;
  display: grid;
  grid-template-rows: 50px 50px 50px 50px 50px;
`;
export const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
`;
export const IconText = styled.p`
  font-size: 16px;
  color: black;
  margin-bottom: 0;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
`;
export const Icon = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const PriceContainer = styled.div`
  position: absolute;
  top: 841px;
  left: 1301px;
  width: 281px;
  height: 36px;
`;

export const Price = styled.p`
  font-size: ${(props) => props.size};
  font-family: ${(props) => props.family};
  color: ${(props) => props.colorPrice};
  margin-bottom: 0;
`;
export const Honoraire = styled.small`
  && {
    color: ${(props) => props.colorPrice};
    font-size: ${(props) => props.size};
    font-family: ${(props) => props.family};
    text-align: center;
  }
`;
export const AlurContainer = styled.div`
  position: absolute;
  top: 890px;
  left: 1301px;
  width: 259px;
`;

export const AlurText = styled.p`
  && {
    color: black;
    font-size: 12px;
    font-family: "Montserrat", sans-serif !important;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 0;
    font-weight: 400;
  }
`;

export const TitleDescContainer = styled.div`
  width: 486px;
  position: absolute;
  top: 831px;
  left: 519px;
`;
export const TitleDesc = styled.p`
  font-size: 25px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 0;
`;

export const DescContainer = styled.div`
  position: absolute;
  top: 873px;
  left: 519px;
  width: 540px;
  height: 205px;
`;
export const DescText = styled.p`
  color: ${(props) => props.colorDesc};
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 130%;
  text-align: left;
  white-space: pre-wrap;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Montserrat', sans-serif"} !important;
`;
