import React from "react";
import { useTranslation } from "react-i18next";
import { choices } from "../../PrintPage/Utils/dataChoices";
import { Price, Honoraire, SpanPrice } from "./ModulesElements.styles";
const ModulePrice = (props) => {
  const { t } = useTranslation();

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <>
      <Price
        colorPrice={props.colorPrice}
        sizePrice={props.project.hideprice ? "25pt" : props.sizePrice}
        weight={props.weight}
        family={props.family}
      >
        {props.project.hideprice && t("modalprint.consult")}
        {!props.project.hideprice &&
        props.project.category === choices.category[2].id ? (
          <>
            <SpanPrice sizeSpanPrice={props.sizeSpanPrice} right>
              {t("details.modalprint.startAt") + " "}
            </SpanPrice>
            {props.nextline && <br />}
          </>
        ) : (
          ""
        )}
        {!props.project.hideprice &&
          `${getPrice(props.price)} ${
            props.project.currency ? props.project.currency : "€"
          }`}{" "}
        {props.project.category === choices.category[1].id &&
        !props.project.hideprice ? (
          <SpanPrice sizeSpanPrice={props.sizeSpanPrice}>
            {t("modalprint.chargespermonth")}
          </SpanPrice>
        ) : (
          ""
        )}
        {props.project.periodicity &&
          props.project.category === choices.category[2].id && (
            <SpanPrice sizeSpanPrice={props.sizeSpanPrice}>
              {t(
                choices.periodicity.filter(
                  (item) => item.id === props.project.periodicity
                )[0].name
              )}
            </SpanPrice>
          )}
      </Price>
      {props.project.category === choices.category[0].id &&
      !props.project.hideprice ? (
        <Honoraire
          sizeHonoraire={props.sizeHonoraire}
          colorPrice={props.colorPrice}
        >
          {props.project.sharedCommission
            ? t("details.fees.feesShared")
            : props.project.commission
            ? t("details.modalprint.commission")
            : t("details.modalprint.commissionseller")}
        </Honoraire>
      ) : null}
    </>
  );
};

export default ModulePrice;
