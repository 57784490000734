import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import "../../Utils/Styles/modal.css";

const ModalToken = (props) => {
  const { setNoRegisterToken, setInvalidToken, setLoading, message } = props;

  const refresh = async () => {
    setLoading(false);
    setNoRegisterToken(false);
    setInvalidToken(false);
    // await props.refreshList();
  };

  return (
    <div>
      <Dialog open={true}>
        <DialogContent>
          <Typography align="center">
            {message
              ? "Votre token a expiré, merci de vous reconnecter"
              : "Merci de vous connecter au module d'import de votre profil"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => refresh()} color="primary">
            Merci
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalToken;
