/* eslint-disable no-unused-vars */
import { TextField } from "@material-ui/core";
import React from "react";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import { TextFieldContainer } from "../Styles/Content.styles";

const AreaFloorComponent = (props) => {
  const { areaFloor, setAreaFloor } = useTemplatesStore();
  return (
    <TextFieldContainer margin={"1rem auto"}>
      <TextField
        id="outlined-multiline-static"
        label={"Superficie au sol"}
        value={areaFloor}
        variant="outlined"
        onChange={(e) => setAreaFloor(e.target.value)}
        fullWidth
      />
    </TextFieldContainer>
  );
};

export default AreaFloorComponent;
