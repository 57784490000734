import React from "react";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import { adsText } from "../AboText";
import { CheckedIcon } from "../../Utils/Styles/Agency.styles";
import { style } from "./Collapse.mui.styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { colorSecondary } from "../../../../GlobalStyles";

const CollapseList = (props) => {
  const translate = useTranslate();
  const classes = style();

  return (
    <>
      <Collapse in={true} className={classes.collapse}>
        <List
          component="nav"
          aria-label="secondary mailbox folders"
          className={classes.root}
        >
          {adsText.map((item, idx) => (
            <div
              key={idx}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ListItem
                key={idx}
                button
                onClick={() =>
                  props.adsAboChange(item.name, item.id, item.price)
                }
              >
                <ListItemIcon>
                  <CheckedIcon
                    as={
                      props.state[item.id].subtype === item.name
                        ? CheckCircleIcon
                        : CheckCircleOutlineIcon
                    }
                    mycolor={
                      props.state[item.id].subtype === item.name
                        ? colorSecondary
                        : "#757575"
                    }
                    pointer="true"
                  />
                </ListItemIcon>
                <ListItemText primary={translate(item.text)} />
              </ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: "16px",
                }}
              >
                <CheckedIcon
                  as={InfoOutlinedIcon}
                  mycolor={"#757575"}
                  onClick={() => props.callInfoPage(item.popupIndex)}
                  pointer="true"
                />
              </div>
            </div>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default CollapseList;
