import { useTranslate } from "react-admin";
import React from "react";
import {
  DeleteFilterBtn,
  DeleteFilterButtonContainer,
} from "../../../Utils/Styles/ProjectList.styles";

const ResetButton = (props) => {
  const translate = useTranslate();
  const handleReset = () => {
    props.setIsLocation(false);
    props.setIsSale(false);
    props.resetFilter();
  };

  return (
    <DeleteFilterButtonContainer>
      <DeleteFilterBtn
        onClick={() => handleReset()}
        disabled={props.data.length === 0 ? true : false}
      >
        {translate("resources.fields.reset")}
      </DeleteFilterBtn>
    </DeleteFilterButtonContainer>
  );
};

export default ResetButton;
