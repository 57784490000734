import React, { useState } from "react";
import { Box } from "@material-ui/core";

import {
  ItemContainer,
  Label,
  TextValue,
  EditPencilIcon,
  SkeletonEdit,
} from "../../../../Utils/Styles/BillingInformation.styles";
import EditIcon from "@material-ui/icons/Edit";
import BillingAddressManager from "./BillingManager/BillingAdressManager/BillingAdressManager";
import { useTranslate } from "react-admin";
import { useAlert } from "react-alert";

const Address = (props) => {
  const translate = useTranslate();
  const alert = useAlert();
  const [modal, setModal] = useState(false);

  const showMessage = (message) => {
    alert.show(translate(message));
  };

  return (
    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
      <ItemContainer
        noBorder={props.noBorder}
        minHeight={props.minHeight}
        smallPadding={props.smallPadding}
        boxShadow={props.boxShadow}
      >
        <Label>{translate("resources.fields.addressBilling")}</Label>
        <div style={{ marginTop: "1rem" }}>
          {props.record &&
          props.record.billingInformation &&
          props.record.billingInformation.billingAddress &&
          props.record.billingInformation.billingAddress.name ? (
            <TextValue>
              {props.record.billingInformation.billingAddress.name.toUpperCase()}
            </TextValue>
          ) : (
            <SkeletonEdit variant="rect" width={130} height={8} />
          )}
          {props.record &&
          props.record.billingInformation &&
          props.record.billingInformation.billingAddress &&
          props.record.billingInformation.billingAddress.address ? (
            <TextValue>
              {props.record.billingInformation.billingAddress.address}
            </TextValue>
          ) : (
            <SkeletonEdit variant="rect" width={150} height={8} />
          )}
          {props.record &&
          props.record.billingInformation &&
          props.record.billingInformation.billingAddress &&
          props.record.billingInformation.billingAddress.city ? (
            <TextValue>
              {props.record.billingInformation.billingAddress.city.toUpperCase() +
                ", " +
                props.record.billingInformation.billingAddress.postalcode}
            </TextValue>
          ) : (
            <SkeletonEdit variant="rect" width={150} height={8} />
          )}
          {props.record &&
          props.record.billingInformation &&
          props.record.billingInformation.billingAddress &&
          props.record.billingInformation.billingAddress.country ? (
            <TextValue>
              {props.record.billingInformation.billingAddress.country.toUpperCase()}
            </TextValue>
          ) : (
            <SkeletonEdit variant="rect" width={130} height={8} />
          )}
        </div>
        {props.edit && (
          <EditPencilIcon as={EditIcon} onClick={() => setModal(true)} />
        )}
      </ItemContainer>
      {modal && (
        <BillingAddressManager
          setModal={setModal}
          {...props}
          showMessage={showMessage}
        />
      )}
    </Box>
  );
};

export default Address;
