export const now = (lastImport) => {
  const date = new Date(lastImport);
  const day = date.getDate() >= 10 ? date.getDate() : 0 + "" + date.getDate();
  const month =
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : 0 + "" + (date.getMonth() + 1);
  const year = date.getFullYear();
  const minutes =
    date.getMinutes() >= 10 ? date.getMinutes() : 0 + "" + date.getMinutes();
  const hours =
    date.getHours() >= 10 ? date.getHours() : 0 + "" + date.getHours();
  const dateFormat =
    year + "-" + month + "-" + day + "T" + hours + ":" + minutes;
  return dateFormat;
};
export const nowList = (lastImport) => {
  const date = new Date(lastImport);
  const day = date.getDate() >= 10 ? date.getDate() : 0 + "" + date.getDate();
  const month =
    date.getMonth() + 1 >= 10
      ? date.getMonth() + 1
      : 0 + "" + (date.getMonth() + 1);
  const year = date.getFullYear();
  const dateFormat = day + "/" + month + "/" + year;
  return dateFormat;
};

export const get24hPast = (lastImport) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const dayAgo = new Date().getTime();
  const dateNow = new Date(lastImport).getTime() + oneDay;

  return dateNow < dayAgo;
};

export const getOneYear = (date) => {
  const now = new Date(date);
  const oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));

  const day =
    oneYearFromNow.getDate() >= 10
      ? oneYearFromNow.getDate()
      : 0 + "" + oneYearFromNow.getDate();
  const month =
    oneYearFromNow.getMonth() + 1 >= 10
      ? oneYearFromNow.getMonth() + 1
      : 0 + "" + (oneYearFromNow.getMonth() + 1);
  const year = oneYearFromNow.getFullYear();
  const dateFormat = day + "/" + month + "/" + year;
  return dateFormat;
};

export const queryShow = (selected) => {
  const role = ["super_admin", "admin"];
  if (role.includes(selected.user.permissions)) {
    return null;
  } else {
    if (selected.user.agenceId !== undefined) {
      if (selected.user.agenceId !== null) {
        return { _id_$in: [selected.user.agenceId] };
      } else {
        return { _id_$in: [selected.user._id] };
      }
    } else {
      return { _id_$in: [selected.user._id] };
    }
  }
};
