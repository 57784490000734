import { makeStyles } from "@material-ui/core/styles";

export const ModalPrintStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      position: "relative",
      width: "80%",
      maxWidth: "80%",
      margin: "15px",
      padding: "0",
    },
  },
}));
