import { Popover, TextField } from "@material-ui/core";
import React from "react";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import ColorChoice from "../ColorChoice";
import { TextFieldContainer } from "../Styles/Content.styles";
import { ColorCube, FontIcon } from "../Styles/StepFour.styles";
import FontManager from "./FontManager";

const RefComponent = () => {
  const { setRefFont, refFont, refText, setRefText } = useTemplatesStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewFont, setViewFont] = React.useState(null);

  const handleChangeColor = (color) => {
    setRefFont((prev) => ({ ...prev, color: color.hex }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeSize = (setter) => {
    if (setter === "add") {
      if (refFont.size < 50 || refFont.size === "") {
        setRefFont((prev) => ({
          ...prev,
          size: prev.size !== "" ? prev.size + 1 : 16,
        }));
      }
    } else if (setter === "remove") {
      if (refFont.size > 0 || refFont.size === "") {
        setRefFont((prev) => ({
          ...prev,
          size: prev.size !== "" ? prev.size - 1 : 14,
        }));
      }
    }
  };
  const handleChangeWeight = (setter) => {
    if (setter === "add") {
      if (refFont.weight < 900 || refFont.weight === "") {
        setRefFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight + 100 : 600,
        }));
      }
    } else if (setter === "remove") {
      if (refFont.weight > 200 || refFont.weight === "") {
        setRefFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight - 100 : 400,
        }));
      }
    }
  };

  const handleChangeFont = (e) => {
    setRefFont((prev) => ({
      ...prev,
      font: e.target.value,
    }));
  };

  return (
    <TextFieldContainer margin={"1rem auto"}>
      <TextField
        id="outlined-multiline-static"
        label={"Référence"}
        value={refText}
        variant="outlined"
        onChange={(e) => setRefText(e.target.value)}
        fullWidth
        InputProps={{
          endAdornment: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ColorCube color={refFont.color} onClick={handleClick} />
              <FontIcon onClick={() => setViewFont(!viewFont)} margin={false} />
            </div>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice
          color={refFont.color}
          handleChangeColor={handleChangeColor}
        />
      </Popover>
      {viewFont && (
        <FontManager
          open={viewFont}
          handleChangeSize={handleChangeSize}
          handleChangeWeight={handleChangeWeight}
          handleChangeFont={handleChangeFont}
          police={refFont.font}
        />
      )}
    </TextFieldContainer>
  );
};

export default RefComponent;
