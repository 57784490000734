import { useTranslate } from "react-admin";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useSelector } from "react-redux";

import Loader from "../../../../../Loader/Loader";
import {
  Container,
  ListPage,
  LoadingCircle,
  MoreItems,
  NoResultTypo,
  TextBtnMoreItems,
} from "../../../Utils/Styles/ProjectList.styles";
import ProjectPoster from "./ProjectPoster";
import ProjectSmallGrid from "./Grid/ProjectSmallGrid";
import { UiContext } from "../../../../../../store/UserProvider";

const ListView = (props) => {
  const { ui } = useContext(UiContext);

  const translate = useTranslate();
  const containerListRef = useRef();
  const { agency, user } = props;
  const itemPerPage = 12;
  const basePath = "projects";
  const [items, setItems] = useState(itemPerPage);
  const [loadingBtn, setLoadingBtn] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const getMoreItem = () => {
    setLoadingBtn(true);
    if (imageLoaded) {
      setTimeout(() => {
        setItems(items + itemPerPage);
        setLoadingBtn(false);
      }, 800);
    }
  };

  useEffect(() => {
    function handler(event) {
      if (
        containerListRef.current &&
        window.innerHeight + window.scrollY >=
          containerListRef.current.offsetHeight + 200
      ) {
        getMoreItem();
      }
    }
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  });
  return (
    <ListPage
      modeView={
        ui.project.modeView && props.dataToShow.length !== 0 ? true : false
      }
    >
      {props.loading ? (
        <Loader />
      ) : props.dataToShow.length === 0 ? (
        <NoResultTypo variant="body2" gutterBottom>
          {translate("resources.fields.noresult")}
        </NoResultTypo>
      ) : ui.project.modeView ? (
        <ProjectSmallGrid
          data={props.dataToShow}
          slugAgence={agency ? agency.slug : null}
          agency={agency}
          user={user}
        />
      ) : (
        <Container open={open} ref={containerListRef}>
          {props.dataToShow
            .slice(0, items)
            // .reverse()
            .map((record, id) => (
              <ProjectPoster
                key={id}
                record={record}
                open={open}
                basePath={basePath}
                handleImageLoaded={handleImageLoaded}
                slugAgence={agency ? agency.slug : null}
                agency={agency}
                user={user}
              />
            ))}
        </Container>
      )}

      {props.loading ? null : props.dataToShow.length === 0 ? null : props
          .dataToShow.length > itemPerPage &&
        items < props.dataToShow.length &&
        !ui.project.modeView ? (
        <MoreItems onClick={getMoreItem}>
          {loadingBtn ? (
            <LoadingCircle size="25" />
          ) : (
            <TextBtnMoreItems>
              {translate("resources.projects.loadmoremandates")}
            </TextBtnMoreItems>
          )}
        </MoreItems>
      ) : null}
    </ListPage>
  );
};

export default ListView;
