import styled from "styled-components";

export const Footer = styled.div`
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2rem;
  background-color: #22292f;
`;

export const CgvContainer = styled.div``;

export const CgvText = styled.p`
  color: white;
  margin-bottom: 5px;
  margin-top: 0;
  text-align: center;
`;
export const CgvLink = styled.a`
  color: #2fbcee;
  &:hover {
    color: #2fbcee;
  }
`;
export const CopyrightContainer = styled.div``;

export const CopyrightText = styled.p`
  font-size: 18px;
  color: white;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
`;
