/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { Admin, useLocale } from "react-admin";
import { useTranslation } from "react-i18next";
import { QueryClient } from "@tanstack/react-query";

import {
  AgencyContext,
  CombineContext,
  UserChangeContext,
  UserContext,
} from "../../store/UserProvider";
import authProvider from "../../Components/Administration/Utils/authProvider";
import dataProvider from "../../Components/Administration/Utils/dataProvider";
import MyRoutes from "../../Components/Administration/Utils/MyRoutes";
import SignIn from "../Sign/SignIn";
import MyLayout from "../../Components/Administration/Utils/MyLayout";
import Resources from "./resources";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import frenchMessages from "ra-language-french";
import italianMessages from "ra-language-italian";
import germanMessages from "ra-language-german";
import dutchMessages from "ra-language-dutch";
import spanishMessages from "ra-language-spanish";
import portugueseMessages from "ra-language-portuguese-pt";

import domainMessages from "../../Components/Administration/i18n";
import "./index.css";
import { THEME } from "../../Components/Administration/Utils/Theme";
import Error from "../../Components/Error/Error";
import cgv from "../../assets/CGV-CGU-Visibble.pdf";
// import cgvEN from "../../assets/cgvEN.pdf";
import {
  CgvContainer,
  CgvLink,
  CgvText,
  Footer,
  CopyrightContainer,
  CopyrightText,
} from "./Administration.styles";
import Menu from "../../Components/Administration/Utils/Menu";
import { getAgency } from "./request";
import useData from "./hooks/useData";

const Administration = (props) => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const { userChange, setUserChange } = useContext(UserChangeContext);
  const { setAgency } = useContext(AgencyContext);
  const [selected, setSelected] = useState({ user: user, setUser: setUser });
  const [active, setActive] = useState(false);
  const abo = useContext(CombineContext);
  const locale = useLocale();

  const messages = {
    "fr-FR": { ...frenchMessages, ...domainMessages.fr },
    "en-US": { ...englishMessages, ...domainMessages.en },
    "it-IT": { ...italianMessages, ...domainMessages.it },
    "de-DE": { ...germanMessages, ...domainMessages.de },
    "nl-NL": { ...dutchMessages, ...domainMessages.nl },
    "es-ES": { ...spanishMessages, ...domainMessages.es },
    "pt-PT": { ...portugueseMessages, ...domainMessages.pt },
    notaire: { ...frenchMessages, ...domainMessages.notaire },
  };

  const i18nProvider = polyglotI18nProvider(
    (locale) => messages[locale],
    props.locale, // Default locale
    { allowMissing: true }
  );

  useEffect(() => {
    const getAgencyDetails = async (user) => {
      const role = ["admin", "super_admin"];
      if (user.agenceId) {
        const response = await getAgency(user.agenceId);
        if (response.status === 200) {
          setAgency(response.data);
          const agency = response.data;
          const valid = ["waiting", "active"];
          if (!active) {
            setActive(true);
            if (
              agency.abo &&
              agency.abo.ads &&
              valid.includes(agency.abo.ads.state)
            ) {
              abo.typeValue.setType("360");
            } else if (
              agency.abo &&
              agency.abo.ads &&
              !valid.includes(agency.abo.ads.state) &&
              agency.abo.print &&
              valid.includes(agency.abo.print.state)
            ) {
              abo.typeValue.setType("print");
            }
          }
        }
      } else {
        if (role.includes(user.permissions)) {
          abo.typeValue.setType("admin");
        }
      }
    };
    if (userChange) {
      setSelected({ user: userChange, setUser: setUserChange });
      getAgencyDetails(userChange);
    } else {
      if (user.agenceId) {
        getAgencyDetails(user);
      }
    }
  }, [userChange, setUserChange, setAgency, user, abo.typeValue, active]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10000,
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <>
      <Admin
        i18nProvider={i18nProvider}
        loginPage={SignIn}
        queryClient={queryClient}
        menu={Menu}
        dataProvider={dataProvider(selected.user)}
        authProvider={authProvider(selected.user, selected.setUser)}
        customRoutes={MyRoutes}
        layout={MyLayout}
        theme={THEME}
        catchAll={Error}
      >
        {Resources(selected.user)}
      </Admin>
      <Footer>
        <CgvContainer>
          <CgvText>
            {t("admin.cgv.text")} {""}
            <CgvLink href={cgv} target="target_blank">
              CGV
            </CgvLink>
          </CgvText>
        </CgvContainer>
        <CopyrightContainer>
          <CopyrightText>© Visibble 2021</CopyrightText>
        </CopyrightContainer>
      </Footer>
    </>
  );
};

export default Administration;
