import React from "react";
import PdfPreview from "../Components/PdfPreview";

const StepFive = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <PdfPreview
        state={props.state}
        infoProject={props.infoProject}
        agency={props.agency}
        step={props.step}
        theDateCertif={props.theDateCertif}
      />
    </div>
  );
};

export default StepFive;
