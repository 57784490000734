/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useEffect } from "react";
import {
  getAudienceByAge,
  getAudienceChart,
  getAudienceGenderMiddle,
  getAudienceMiddle,
  getCamera,
  getDateFilter,
  getInterested,
  getInterestedPourcent,
  getLeads,
  getMiddleTime,
  getTouchPourcent,
  getTraffic,
  getTrafficBestDay,
  getTrafficChart,
  getTrafficHourChart,
  getViewAudience,
} from "./helpers";

const useDataFilter = (props) => {
  const [filterDate, setFilterDate] = useState({
    min: props.thisMonth.start,
    max: props.thisMonth.end,
  });
  const [datePicker, setDatePicker] = useState([
    {
      startDate: props.thisMonth.start,
      endDate: props.thisMonth.end,
      key: "selection",
      color: "#5463fa",
    },
  ]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [traffic, setTraffic] = useState([]);
  const [trafficChart, setTrafficChart] = useState([]);
  const [trafficHourChart, setTrafficHourChart] = useState([]);
  const [trafficBestDay, setTrafficBestDay] = useState("");
  const [viewAudience, setViewAudience] = useState([]);
  const [audienceChart, setAudienceChart] = useState([]);
  const [audienceMiddle, setAudienceMiddle] = useState(0);
  const [audienceGenderMiddle, setAudienceGenderMiddle] = useState(0);
  const [audienceByAge, setAudienceByAge] = useState([]);
  const [middleTime, setMiddleTime] = useState(0);
  const [leads, setLeads] = useState([]);
  const [interested, setInterested] = useState([]);
  const [interestedPourcent, setInterestedPourcent] = useState(0);
  const [touchPourcent, setTouchPourcent] = useState(0);
  const [daysLength, setDaysLength] = useState(0);

  useEffect(() => {
    if (filterDate) {
      const camera = getCamera(props.data, selectedCamera);
      const date = getDateFilter(filterDate, camera);
      setDaysLength(date.length);
      const t = getTraffic(date);
      setTraffic(t);
      const tc = getTrafficChart(date);
      setTrafficChart(tc);
      // const thc = getTrafficHourChart(date);
      // setTrafficHourChart(thc);
      const tbd = getTrafficBestDay(tc);
      setTrafficBestDay(tbd);
      const va = getViewAudience(date);
      setViewAudience(va);
      const ac = getAudienceChart(date);
      setAudienceChart(ac);
      // const am = getAudienceMiddle(ac);
      // setAudienceMiddle(am);
      const aba = getAudienceByAge(date);
      setAudienceByAge(aba);
      const agm = getAudienceGenderMiddle(date);
      setAudienceGenderMiddle(agm);
      const mt = getMiddleTime(date, va);
      setMiddleTime(mt);
      const l = getLeads(date);
      setLeads(l);
      const i = getInterested(date);
      setInterested(i);
      // const ip = getInterestedPourcent(date);
      // setInterestedPourcent(ip);
      const tp = getTouchPourcent(va, t);
      setTouchPourcent(tp);
    }

    if (selectedCamera === "" && props.cameras && props.cameras.length) {
      setSelectedCamera(props.cameras[0].cameras.id);
    }
  }, [filterDate, props.cameras, props.data, selectedCamera]);

  const handleFilterDate = async (category, value) => {
    setFilterDate((prev) => ({
      ...prev,
      [category]: value,
    }));
  };
  const handleSelectedCamera = (event) => {
    console.log(event.target.value);
    setSelectedCamera(event.target.value);
  };

  const handleMultiDate = (ranges) => {
    const { selection } = ranges;
    setDatePicker([selection]);
    handleFilterDate("min", selection.startDate.toISOString());
    handleFilterDate("max", selection.endDate.toISOString());
  };

  return {
    datePicker,
    setDatePicker,
    selectedCamera,
    traffic,
    trafficChart,
    trafficHourChart,
    trafficBestDay,
    viewAudience,
    audienceChart,
    audienceMiddle,
    audienceGenderMiddle,
    audienceByAge,
    middleTime,
    leads,
    interested,
    interestedPourcent,
    touchPourcent,
    handleFilterDate,
    handleSelectedCamera,
    handleMultiDate,
    daysLength,
  };
};

export default useDataFilter;
