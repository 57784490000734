import React, { useState } from "react";
import formWrapper from "./formWrapper";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Create from "./ProjectFormItems/Create";
import ToolbarForm from "./ProjectFormItems/ToolbarForm";
import { NavBar } from "../../Utils/Styles/ProjectList.styles";
import { FormWithRedirect, useTranslate } from "ra-core";
import { Fade } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { choices } from "../../Utils/dataChoices";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Medias from "./ProjectFormItems/Medias";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    paddingBottom: "10px",
  },
}));

const ProjectFormEdit = (props) => {
  const { enableSaveAddress, initialValues, user } = props;
  const [value, setValue] = useState(0);
  const [ads] = useState(false);
  const classes = useStyles();
  const translate = useTranslate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <FormWithRedirect
      {...props}
      redirect="list"
      // theme={THEME}
      render={(formProps) => {
        return (
          <Fade in={true} timeout={300}>
            <div className={classes.root}>
              <NavBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    label="Informations"
                    icon={<InfoIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={translate(
                      "resources.projects.tabform.media"
                    ).toUpperCase()}
                    icon={<PhotoLibraryIcon />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </NavBar>
              {/* INFORMATIONS GENERALES */}
              <TabPanel value={value} index={0}>
                <Create {...props} choices={choices} formProps={formProps} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Medias {...props} choices={choices} ads={ads} />
              </TabPanel>
              <ToolbarForm
                initialValues={initialValues}
                enableSaveAddress={enableSaveAddress}
                formProps={formProps}
                user={user}
                ads={ads}
                {...props}
              />
            </div>
          </Fade>
        );
      }}
    />
  );
};

export default formWrapper(ProjectFormEdit);
