import React from "react";
import PdfPreview from "../Components/PdfPreview";
import { StepThreeElement } from "../Utils/PdfPreview.styles";

const StepThree = (props) => {
  return (
    <StepThreeElement>
      <PdfPreview
        state={props.state}
        infoProject={props.infoProject}
        agency={props.agency}
        step={props.step}
      />
    </StepThreeElement>
  );
};

export default StepThree;
