import React from "react";
import { useTranslation } from "react-i18next";
import { colorPrimary, colorSecondary } from "../../../../GlobalStyles";
import { useTemplatesStore } from "../../../../store/TemplatesStore";
import {
  MediaChoiceImage,
  RowChoiceImage,
  RowInnerChoiceImage,
  TileChoiceImage,
  TileMediaChoiceImage,
} from "./Styles/ImageChoice.styles";
import { TitleSection } from "./Styles/StepThree.styles";

const FirstImageChoice = ({ handleImage }) => {
  const { project, imageFirst } = useTemplatesStore();
  const { t } = useTranslation();

  return (
    <div>
      <TitleSection margin={"0"}>
        {t("modalprint.mainimage").toUpperCase()}
      </TitleSection>
      <RowChoiceImage margin={"true"}>
        <RowInnerChoiceImage>
          {project.images.map((item, idx) => {
            return (
              <TileChoiceImage
                key={idx}
                onClick={() => handleImage(item.src)}
                marginRight={"30px"}
                width={"400px"}
                backgroundColor={
                  imageFirst === item.src ? colorPrimary : "unset"
                }
              >
                <TileMediaChoiceImage>
                  <MediaChoiceImage
                    src={item.src}
                    alt={item.name}
                    width={"400px"}
                    border={
                      imageFirst === item.src
                        ? `solid 5px ${colorSecondary}`
                        : "unset"
                    }
                  />
                </TileMediaChoiceImage>
              </TileChoiceImage>
            );
          })}
        </RowInnerChoiceImage>
      </RowChoiceImage>
    </div>
  );
};

export default FirstImageChoice;
