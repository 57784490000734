import { Box } from "@material-ui/core";
import React from "react";
import { BooleanInput, SelectInput, TextInput } from "react-admin";

const FinancialsData = (props) => {
  const { choices } = props;

  return (
    <>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="period"
            resettable
            resource="projects"
            choices={choices.period}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="currency"
            resource="projects"
            fullWidth
            choices={choices.devise}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="contribution"
            InputProps={{ endAdornment: "%" }}
            resource="projects"
            fullWidth
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="fees"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.feespercent
                ? "%"
                : props.record.currency
                ? props.record.currency
                : "€",
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feesetatdeslieux"
            resource="projects"
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
            fullWidth
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feesvisite"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feescopro"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="feesdepotgarantie"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="deposit"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="montantquotepartannuelle"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="inventory"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="monthlycharge"
            resource="projects"
            fullWidth
            InputProps={{
              endAdornment: props.record.currency ? props.record.currency : "€",
            }}
          />
        </Box>
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <TextInput
          source="amountWithoutHonorary"
          resource="projects"
          fullWidth
          InputProps={{
            endAdornment: props.record.currency ? props.record.currency : "€",
          }}
        />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="commission" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="sharedCommission" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="feespercent" resource="projects" />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <BooleanInput source="hideprice" resource="projects" />
      </Box>
    </>
  );
};

export default FinancialsData;
