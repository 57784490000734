import { config } from "../../../../constants/constant";
import axios from "axios";

export const getDataProjectForAgency = async (query) => {
  const url = config.API_BASE + "/projects?%24limit=-1&agenceId=" + query;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "projectList",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getUsersData = async () => {
  const url = config.API_BASE + "/users?%24limit=-1";
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "modalUserChange",
      Authorization: bearer,
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const postNewUserInProject = async (id, data) => {
  const url = config.API_BASE + "/projects/" + id;
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);
  const params = {
    method: "PATCH",
    url,
    data: { userId: data },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getAgency = async (id) => {
  const url = config.API_BASE + "/agencies/" + id;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
