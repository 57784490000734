import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { AlurText } from "./styles";

const Alur = ({ project }) => {
  const { t } = useTranslation();

  const [currency] = useState(project.currency ? project.currency : "€");
  return (
    <>
      {project.category === choices.category[1].id ? (
        <>
          <AlurText>
            {t("details.modalprint.feesdepotgarantie")} :{" "}
            {project.feesdepotgarantie > 0
              ? project.feesdepotgarantie + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
          <AlurText>
            {t("Honoraires à la charge du locataire")} :{" "}
            {project.fees > 0
              ? project.fees + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
          <AlurText>
            {
              "Charges locatives prévisionnelles mensuelles avec régularisation annuelle"
            }{" "}
            :{" "}
            {project.monthlycharge > 0
              ? project.monthlycharge + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
          <AlurText>
            {t("modalprint.feesetatdeslieux")} :{" "}
            {project.feesetatdeslieux > 0
              ? project.feesetatdeslieux + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        </>
      ) : null}

      {project.category === choices.category[0].id ? (
        <>
          {project.nblotcopro > 0 ? (
            <AlurText>
              {t("modalprint.copropriete")} {project.nblotcopro}{" "}
              {t("modalprint.lots")} - {t("modalprint.feescopro")} :{" "}
              {project.feescopro
                ? project.feescopro + currency
                : t("modalprint.undisclosed")}
            </AlurText>
          ) : null}
          {project.commission || project.sharedCommission ? (
            <AlurText>
              {t("details.modalprint.commission")} :{" "}
              {project.fees !== ""
                ? project.fees + (project.feespercent ? " %" : " " + currency)
                : t("modalprint.undisclosed")}{" "}
            </AlurText>
          ) : null}
          {(project.sharedCommission || project.commission) &&
          project.amountWithoutHonorary ? (
            <AlurText>
              {t("modalprint.excludefees")} : {project.amountWithoutHonorary}{" "}
              {currency}
            </AlurText>
          ) : null}

          <AlurText>
            {project.procsyndicale &&
            project.type !== choices.type[1].id &&
            project.type !== choices.type[2].id &&
            project.type !== choices.type[3].id
              ? "Procédure syndicale en cours"
              : "Pas de procédure en cours"}
          </AlurText>
        </>
      ) : null}
    </>
  );
};

export default Alur;
