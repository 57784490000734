import React from "react";

import { Dialog } from "@material-ui/core";
import { ModalPrintStyles } from "./ModalPrint.mui.styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function ModalShare(props) {
  const classes = ModalPrintStyles();

  const handleClose = () => {
    props.setModal(false);
  };

  const handleChangeIndex = (setter) => {
    if (setter === "add" && props.index !== props.data.length - 1) {
      props.setIndex(props.index + 1);
    } else if (setter === "remove" && props.index > 0) {
      props.setIndex(props.index - 1);
    } else if (setter === "add" && props.index === props.data.length - 1) {
      props.setIndex(0);
    } else if (setter === "remove" && props.index === 0) {
      props.setIndex(props.data.length - 1);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      fullWidth
      className={classes.root}
    >
      <ArrowBackIosIcon
        onClick={() => handleChangeIndex("remove")}
        style={{
          position: "absolute",
          top: "50%",
          left: "75px",
          transform: "translate(-50%, -50%)",
          fontSize: "60px",
          color: "white",
          cursor: "pointer",
        }}
      >
        Remove
      </ArrowBackIosIcon>
      <ArrowForwardIosIcon
        onClick={() => handleChangeIndex("add")}
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translate(-50%, -50%)",
          fontSize: "60px",
          color: "white",
          cursor: "pointer",
        }}
      >
        Add
      </ArrowForwardIosIcon>
      <img
        src={props.data[props.index].src}
        alt={props.data[props.index].title}
        style={{ width: "auto", maxHeight: "88vh", objectFit: "cover" }}
      />
    </Dialog>
  );
}
