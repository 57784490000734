import React from "react";

const Sdb = ({ color, size }) => {
  return (
    <svg
      id="Groupe_7"
      data-name="Groupe 7"
      xmlns="http://www.w3.org/2000/svg"
      width={(size + 10).toString()}
      height={size.toString()}
      viewBox="0 0 36.176 27.71"
    >
      <path
        id="Tracé_1"
        data-name="Tracé 1"
        d="M1567.334,248.712c-2.969,0-5,1.7-7.055,3.809a10.134,10.134,0,0,0-6.065-2.34,8.289,8.289,0,0,0-5.419,1.491l-.668-.686-1.786,1.819,14.829,14.82,1.791-1.792-.668-.668a8.308,8.308,0,0,0,1.491-5.419,9.862,9.862,0,0,0-1.819-5.259c1.76-1.819,3.3-3.05,5.36-3.05h6.06v-2.723Zm-6.164,17.622-13.543-13.53.5-.5,13.542,13.538Zm1.706-6.547a7.455,7.455,0,0,1-1.241,4.723l-.01,0-12.174-12.189a7.454,7.454,0,0,1,4.723-1.232,9.176,9.176,0,0,1,6.028,2.583l.07.069A9.172,9.172,0,0,1,1562.876,259.787Zm4.458-9.257c-2.355,0-4.024,1.3-5.911,3.182l-.5-.536c1.918-1.95,3.774-3.569,6.364-3.569h5.182v.923Z"
        transform="translate(-1537.21 -248.712)"
        fill={color}
      />
      <path
        id="Tracé_2"
        data-name="Tracé 2"
        d="M1547.456,259.908a1.855,1.855,0,1,0,0,2.623A1.854,1.854,0,0,0,1547.456,259.908Zm-1.323,2.247a.941.941,0,1,1,.947-.936A.942.942,0,0,1,1546.133,262.155Z"
        transform="translate(-1536.745 -251.12)"
        fill={color}
      />
      <path
        id="Tracé_3"
        data-name="Tracé 3"
        d="M1552.762,265.227a1.855,1.855,0,1,0,0,2.623A1.855,1.855,0,0,0,1552.762,265.227Zm-1.323,2.247a.941.941,0,1,1,.946-.936A.941.941,0,0,1,1551.44,267.474Z"
        transform="translate(-1537.945 -252.323)"
        fill={color}
      />
      <path
        id="Tracé_4"
        data-name="Tracé 4"
        d="M1558.069,270.546a1.855,1.855,0,1,0,0,2.623A1.854,1.854,0,0,0,1558.069,270.546Zm-1.323,2.247a.941.941,0,1,1,.947-.936A.941.941,0,0,1,1556.746,272.793Z"
        transform="translate(-1539.145 -253.525)"
        fill={color}
      />
      <path
        id="Tracé_5"
        data-name="Tracé 5"
        d="M1542.583,264.769a1.855,1.855,0,1,0,0,2.623A1.854,1.854,0,0,0,1542.583,264.769Zm-1.323,2.247a.941.941,0,1,1,.947-.936A.941.941,0,0,1,1541.26,267.016Z"
        transform="translate(-1535.644 -252.219)"
        fill={color}
      />
      <path
        id="Tracé_6"
        data-name="Tracé 6"
        d="M1547.889,270.088a1.855,1.855,0,1,0,0,2.624A1.855,1.855,0,0,0,1547.889,270.088Zm-1.323,2.247a.941.941,0,1,1,.947-.936A.94.94,0,0,1,1546.566,272.335Z"
        transform="translate(-1536.843 -253.422)"
        fill={color}
      />
      <path
        id="Tracé_7"
        data-name="Tracé 7"
        d="M1553.2,275.408a1.855,1.855,0,1,0,0,2.623A1.854,1.854,0,0,0,1553.2,275.408Zm-1.323,2.247a.941.941,0,1,1,.947-.936A.941.941,0,0,1,1551.873,277.654Z"
        transform="translate(-1538.043 -254.624)"
        fill={color}
      />
      <path
        id="Tracé_8"
        data-name="Tracé 8"
        d="M1537.71,269.631a1.855,1.855,0,1,0,0,2.623A1.854,1.854,0,0,0,1537.71,269.631Zm-1.323,2.247a.941.941,0,1,1,.947-.936A.941.941,0,0,1,1536.387,271.877Z"
        transform="translate(-1534.542 -253.318)"
        fill={color}
      />
      <path
        id="Tracé_9"
        data-name="Tracé 9"
        d="M1543.016,274.95a1.855,1.855,0,1,0,0,2.623A1.855,1.855,0,0,0,1543.016,274.95Zm-1.323,2.247a.941.941,0,1,1,.946-.935A.94.94,0,0,1,1541.693,277.2Z"
        transform="translate(-1535.742 -254.521)"
        fill={color}
      />
      <path
        id="Tracé_10"
        data-name="Tracé 10"
        d="M1548.323,280.269a1.855,1.855,0,1,0,0,2.623A1.854,1.854,0,0,0,1548.323,280.269ZM1547,282.516a.941.941,0,1,1,.947-.936A.942.942,0,0,1,1547,282.516Z"
        transform="translate(-1536.941 -255.723)"
        fill={color}
      />
    </svg>
  );
};

export default Sdb;
