import { config } from "../../../../constants/constant";
import axios from "axios";

export const getDataProject = async (id, agenceId) => {
  const url =
    config.API_BASE +
    "/projects?%24limit=-1&_id=" +
    id +
    "&agenceId=" +
    agenceId;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "projectList",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const getDataAgency = async (id) => {
  const url = config.API_BASE + "/agencies/" + id;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const postDataProject = async (data, code, contact) => {
  const prevCode =
    data.project.validationCode && data.project.validationCode.length
      ? data.project.validationCode
      : [];
  const url = config.API_BASE + "/projects/" + data.project._id;
  const params = {
    method: "PATCH",
    url,
    data: {
      validationCode: [...prevCode, code],
      linkVisite:
        config.APP_BASE +
        "/visite360/" +
        data.agency.slug +
        "/" +
        data.project.slug,
      code: code,
      email: contact.email,
      firstname: contact.firstname,
      context: data.leads.context,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
