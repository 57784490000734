import axios from "axios";
import { config } from "../../../constants/constant";

export const getContactExist = async (email) => {
  const url = config.API_BASE + "/contacts?email=" + email;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
  }
};

export const sendRequestContact = async (
  leads,
  project,
  agency,
  state,
  lang
) => {
  const leadsDetails = leads.leads;
  const data = {
    lang: lang,
    agenceId: project.agenceId,
    leads: [
      ...leadsDetails,
      {
        reference: project.reference,
        date: new Date(),
        rdv: state.rdv,
        lang: lang,
      },
    ],
    phonemobile: state.phonemobile,
    email: state.email,
    firstname: state.firstname,
    lastname: state.lastname,
    template: "contact-form",
    reference: project.reference,
    userId: project.userId,
    emailnotifications: agency.emailnotifications,
  };
  const url = config.API_BASE + "/contacts/" + leads._id;
  const params = {
    method: "PATCH",
    url,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
  }
};
export const sendNewRequestContact = async (project, agency, state, lang) => {
  const data = {
    lang: lang,
    agenceId: project.agenceId,
    leads: [
      {
        reference: project.reference,
        date: new Date(),
        rdv: state.rdv,
        lang: lang,
      },
    ],
    phonemobile: state.phonemobile,
    email: state.email,
    firstname: state.firstname,
    lastname: state.lastname,
    template: "contact-form",
    reference: project.reference,
    userId: project.userId,
    emailnotifications: agency.emailnotifications,
  };
  const url = config.API_BASE + "/contacts";
  const params = {
    method: "POST",
    url,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
  }
};
