import { InputAdornment } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import {
  SearchInput,
  SearchInputIcon,
  SibarFilter,
} from "../../../Utils/Styles/ProjectList.styles";
import BedRoomsFilter from "./BedRoomsFilter";
import StateFilter from "./StateFilter";
import CategoryFilter from "./CategoryFilter";
import MediasFilter from "./MediasFilter";
import MediasUrlFilter from "./MediasUrlFilter";
import PriceRentFilter from "./PriceRentFilter";
import PriceSaleFilter from "./PriceSaleFilter";
import AreaFilter from "./AreaFilter";
import ResetButton from "./ResetButton";
import RoomsFilter from "./RoomsFilter";
import TypeFilter from "./TypeFilter";
import Visite360Filter from "./Visite360Filter";
import MyProjectsFilter from "./MyProjectsFilter";
import { useTranslate } from "react-admin";
import { CombineContext } from "../../../../../../store/UserProvider";

const SideBarFilter = (props) => {
  const translate = useTranslate();

  const [isLocation, setIsLocation] = useState(false);
  const [isSale, setIsSale] = useState(false);
  const [ads, setAds] = useState(false);
  const getType = useContext(CombineContext);

  useEffect(() => {
    const role = ["super_admin", "admin"];
    if (
      role.includes(props.user.permissions) ||
      getType.typeValue.type === "360"
    ) {
      setAds(true);
    }
  }, [props.user.permissions, getType.typeValue.type]);

  return (
    <SibarFilter matches={props.matches}>
      <SearchInput
        onChange={props.onChange}
        placeholder={translate("resources.fields.search")}
        style={{ width: props.matches ? "80%" : "initial" }}
        endAdornment={
          <InputAdornment position="end">
            <SearchInputIcon />
          </InputAdornment>
        }
      />

      {props.matches ? null : (
        <>
          <StateFilter
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
          />

          <CategoryFilter
            setIsLocation={setIsLocation}
            setIsSale={setIsSale}
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
          />
          {isLocation && (
            <PriceRentFilter handleFilter={props.handleFilterCategory} />
          )}
          {isSale && (
            <PriceSaleFilter handleFilter={props.handleFilterCategory} />
          )}
          <TypeFilter
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
          />
          <RoomsFilter
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
          />
          <BedRoomsFilter
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
          />
          <AreaFilter
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
          />
          {ads && (
            <Visite360Filter
              handleFilter={props.handleFilterCategory}
              isReset={props.isReset}
            />
          )}
          {ads && (
            <MediasFilter
              handleFilter={props.handleFilterCategory}
              isReset={props.isReset}
            />
          )}
          {ads && (
            <MediasUrlFilter
              handleFilter={props.handleFilterCategory}
              isReset={props.isReset}
            />
          )}
          <MyProjectsFilter
            handleFilter={props.handleFilterCategory}
            isReset={props.isReset}
            user={props.user}
          />
          <ResetButton
            resetFilter={props.resetFilter}
            setIsLocation={setIsLocation}
            setIsSale={setIsSale}
            data={props.data}
          />
        </>
      )}
    </SibarFilter>
  );
};

export default SideBarFilter;
