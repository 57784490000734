import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import ModulePrice from "../../Modules/ModulePrice";
import { choices } from "../../../PrintPage/Utils/dataChoices";

import {
  AlurContainer,
  AlurText,
  BackgroundImage,
  Container,
  CoproContainer,
  Description,
  DescriptionContainer,
  EnergyContainer,
  EnergyTextContainer,
  HonoraryContainer,
  IconContainer,
  IconsContainer,
  IconText,
  Logo,
  LogoContainer,
  PriceContainer,
  SoldBanner,
  SoldBannerText,
} from "./styles";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import RenderContext from "../../ModulesElements/RenderContext";

const GosselinLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
  } = props;
  const [currency] = useState(project.currency ? project.currency : "€");

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <BackgroundImage
        src={imageFirst}
        brightness={brightness}
        saturation={saturation}
      />
      {agency.images && agency.images.length ? (
        <LogoContainer>
          <Logo src={agency.images[0].src} />
        </LogoContainer>
      ) : null}
      <PriceContainer>
        <ModulePrice
          colorPrice={colorPrice || "black"}
          price={price}
          project={project}
          align={"left"}
          sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "38pt"}
          weight={priceFont.weight}
          family={priceFont.font}
          sizeHonoraire={"12pt"}
          sizeSpanPrice={"20pt"}
        />
      </PriceContainer>
      <DescriptionContainer>
        <Description
          colorDesc={colorDesc || "white"}
          size={descFont.size}
          weight={descFont.weight}
          family={descFont.font}
        >
          {desc}
        </Description>
      </DescriptionContainer>
      <IconsContainer>
        <IconContainer>
          <Surface color={"white"} size={70} />
          <IconText colorSecondary={"white"}>{project.area.total} m2</IconText>
        </IconContainer>
        {project.rooms && project.rooms > 0 ? (
          <IconContainer>
            <Rooms color={"white"} size={70} />
            <IconText colorSecondary={"white"}>
              {project.rooms} {t("modalprint.rooms")}
            </IconText>
          </IconContainer>
        ) : null}
        {project.bedrooms && project.bedrooms > 0 ? (
          <IconContainer>
            <Chambres color={"white"} size={70} />
            <IconText colorSecondary={"white"}>
              {project.bedrooms} {t("modalprint.bedrooms")}
            </IconText>
          </IconContainer>
        ) : null}
        {project.sdb && project.sdb > 0 ? (
          <IconContainer>
            <Sdb color={"white"} size={70} />
            <IconText colorSecondary={"white"}>
              {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
            </IconText>
          </IconContainer>
        ) : null}
      </IconsContainer>
      <RenderContext project={project}>
        <EnergyContainer>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top: "0px",
              left: "20px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.18"
                  : "0.19"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"dark"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "25px"
                  : "-15px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "55px"
                  : "330px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.4"
                  : "0.23"
              }
              theme={"dark"}
            />
          </div>
        </EnergyContainer>
        <AlurContainer>
          <HonoraryContainer>
            {project.category === choices.category[1].id ? (
              <AlurText colorDesc={colorDesc}>
                {t("modalprint.fees")} :{" "}
                {project.fees > 0
                  ? project.fees + " " + currency
                  : t("modalprint.undisclosed")}{" "}
              </AlurText>
            ) : null}
            {(project.commission || project.sharedCommission) &&
            project.category === choices.category[0].id ? (
              <AlurText colorDesc={colorDesc}>
                {t("details.modalprint.commission")} :{" "}
                {project.fees !== ""
                  ? project.fees + (project.feespercent ? " %" : " " + currency)
                  : t("modalprint.undisclosed")}{" "}
              </AlurText>
            ) : null}
            {(project.sharedCommission || project.commission) &&
            project.category === choices.category[0].id &&
            project.amountWithoutHonorary ? (
              <AlurText colorDesc={colorDesc}>
                {t("modalprint.excludefees")} :{" "}
                {getPrice(project.amountWithoutHonorary)} {currency}
              </AlurText>
            ) : null}
          </HonoraryContainer>
          <CoproContainer>
            {project.nblotcopro > 0 ? (
              <>
                <AlurText>
                  {t("modalprint.copropriete")} {project.nblotcopro}{" "}
                  {t("modalprint.lots")}
                </AlurText>
                <AlurText>
                  {t("modalprint.feescopro")} :{" "}
                  {project.feescopro
                    ? project.feescopro + currency
                    : t("modalprint.undisclosed")}
                </AlurText>
              </>
            ) : null}
          </CoproContainer>
          <EnergyTextContainer>
            <AlurText>
              {t("modalprint.dpeMinMaxInfo")} :{" "}
              {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                <>
                  {`${t("modalprint.between")} ${project.dpeCoutMinConso}€ ${t(
                    "modalprint.and"
                  )} ${project.dpeCoutMaxConso}€ ${t("modalprint.perYear")} `}
                </>
              ) : (
                t("details.modalprint.undisclosed")
              )}
            </AlurText>
            {project.dpeCoutMinConso &&
              project.dpeCoutMinConso !== "" &&
              project.dpeAnneRefConso &&
              project.dpeAnneRefConso !== "" && (
                <AlurText>
                  {t("modalprint.refYear")} {project.dpeAnneRefConso}{" "}
                  {t("modalprint.refYearEnd")}
                </AlurText>
              )}
          </EnergyTextContainer>
        </AlurContainer>
      </RenderContext>
      {banner && banner.length ? (
        <SoldBanner colorPrimary={color.primary}>
          <SoldBannerText
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </SoldBannerText>
        </SoldBanner>
      ) : null}
    </Container>
  );
});

export default GosselinLandscape;
