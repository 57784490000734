import {
  Checkbox,
  Popover,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import {
  MessageIcon,
  SendCodeIcon,
  ShowIcon,
} from "../../utils/Styles/Contact.styles";
import { useAlert } from "react-alert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { choices } from "../../../Project/Utils/dataChoices";
import { colorPrimary } from "../../../../../GlobalStyles";
import InfoIcon from "@material-ui/icons/Info";

const convertDate = (getDate) => {
  getDate = new Date(getDate);
  const date = new Intl.DateTimeFormat("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(getDate);
  return date;
};
const convertTime = (getDate) => {
  getDate = new Date(getDate);
  const date = new Intl.DateTimeFormat("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(getDate);
  return date;
};

const GreenCheckbox = withStyles({
  root: {
    marginRight: "unset",
    color: "black",
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const LeadsSmallRow = (props) => {
  const translate = useTranslate();
  const alert = useAlert();
  const redirect = useRedirect();
  const { row } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getCategory = (value) => {
    const result = choices.category.filter((obj) => {
      return obj.id === value;
    });
    return result;
  };

  const sendCodeWithReturn = async () => {
    const response = await props.sendCode(row);
    if (response.status === 200) {
      alert.show(translate("resources.alert.sentcodesuccess"));
    }
  };

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell align={"center"}>
        <ShowIcon
          as={VisibilityIcon}
          onClick={() => redirect(`/projects/${row.project._id}`)}
        />
      </TableCell>
      <TableCell align={"center"}>{row.project.reference}</TableCell>
      <TableCell align={"center"}>
        {translate(getCategory(row.project.category)[0].name)}
      </TableCell>
      <TableCell align={"center"}>{`${row.project.price} ${
        row.project.currency ? row.project.currency : "€"
      }`}</TableCell>
      <TableCell align={"center"}>{`${convertDate(
        row.leads.date
      )} à ${convertTime(row.leads.date)}`}</TableCell>
      <TableCell align={"center"}>
        <GreenCheckbox
          checked={row.leads.rdv}
          disabled
          inputProps={{
            "aria-label": "primary checkbox",
          }}
        />
      </TableCell>
      <TableCell align={"center"}>
        <MessageIcon as={InfoIcon} onClick={handleClick} />
      </TableCell>
      <TableCell align={"center"}>
        {row.leads.code && (
          <SendCodeIcon onClick={() => sendCodeWithReturn()} />
        )}
      </TableCell>
      {row.project.user ? (
        <TableCell align={"center"}>
          {row.project.user.firstname} {row.project.user.lastname}
        </TableCell>
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <p
          style={{ maxWidth: "300px", margin: "1rem", whiteSpace: "pre-line" }}
        >
          {row.leads.message}
        </p>
      </Popover>
    </TableRow>
  );
};

export default LeadsSmallRow;
