import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import { Input, Typography, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { colorPrimary } from "../../../GlobalStyles";
export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
`;

export const SibarFilter = styled.div`
  width: 100%;
  /* overflow: hidden; */
  box-shadow: 0 10px 22px 0 rgb(0 0 0 / 15%), 0 2px 4px 0 rgb(0 0 0 / 10%),
    inset 0 0 0 1px rgb(255 255 255 / 40%);
  background-color: white;
  padding: 1.3rem;
  max-height: 90px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 999;
`;

export const SearchInputIcon = styled(SearchIcon)`
  && {
    color: ${colorPrimary};
  }
`;

export const SearchInput = styled(Input)`
  && {
    flex: 1;
    padding: 1.2rem;
    border-radius: 50px;
    width: 400px;
    background-color: #f2f2f2;
    .MuiInputBase-input {
      background-color: #f2f2f2 !important;
      padding: 0.3rem;
    }
  }
`;

export const ListPage = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  border-radius: 4px;
  flex: 1 1 auto;
  overflow: inherit;
  position: relative;
  opacity: ${({ opacityPop }) => (opacityPop !== false ? "0.3" : "1")};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-gap: 40px;
  background-color: transparent;
  padding: 2rem 1rem;
  justify-items: center;
  justify-content: center;

  @media screen and (min-width: 1100px) {
    grid-template-columns: repeat(auto-fill, 360px);
  }
  @media screen and (max-width: 998px) {
    grid-template-columns: repeat(auto-fill, 370px);
  }
`;
export const TextBtnMoreItems = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

export const LoadingCircle = styled(CircularProgress)`
  color: white;
  align-self: center;
  width: 25px;
  height: 25px;
`;

export const NoResultTypo = styled(Typography)`
  && {
    margin: 2rem;
  }
`;

export const MoreItems = styled(Button)`
  && {
    background-color: ${colorPrimary};
    color: white;
    margin: auto;
    border-radius: 20px;

    &:focus {
      outline: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: ${colorPrimary};
    }
  }
`;
