/* eslint-disable no-unused-vars */
import React from "react";
import { Popover, TextField } from "@material-ui/core";
import ColorChoice from "../ColorChoice";
import { TextFieldContainer } from "../Styles/Content.styles";
import { ColorCube, FontIcon } from "../Styles/StepFour.styles";
import FontManager from "./FontManager";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import { useTranslation } from "react-i18next";

const PriceComponent = () => {
  const { t } = useTranslation();
  const {
    setColorPrice,
    priceFont,
    setPriceFont,
    price,
    colorPrice,
    setPrice,
  } = useTemplatesStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewFont, setViewFont] = React.useState(null);

  const handleChangeColor = (color) => {
    setColorPrice(color.hex);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChangeSize = (setter) => {
    if (setter === "add") {
      if (priceFont.size < 50 || priceFont.size === "") {
        setPriceFont((prev) => ({
          ...prev,
          size: prev.size !== "" ? prev.size + 1 : 16,
        }));
      }
    } else if (setter === "remove") {
      if (priceFont.size > 0 || priceFont.size === "") {
        setPriceFont((prev) => ({
          ...prev,
          size: prev.size !== "" ? prev.size - 1 : 14,
        }));
      }
    }
  };
  const handleChangeWeight = (setter) => {
    if (setter === "add") {
      if (priceFont.weight < 900 || priceFont.weight === "") {
        setPriceFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight + 100 : 600,
        }));
      }
    } else if (setter === "remove") {
      if (priceFont.weight > 200 || priceFont.weight === "") {
        setPriceFont((prev) => ({
          ...prev,
          weight: prev.weight !== "" ? prev.weight - 100 : 400,
        }));
      }
    }
  };

  const handleChangeFont = (e) => {
    setPriceFont((prev) => ({
      ...prev,
      font: e.target.value,
    }));
  };
  const onChangePrice = (event) => {
    setPrice(event.target.value);
  };

  return (
    <TextFieldContainer margin={"1rem auto"}>
      <TextField
        id="outlined-multiline-static"
        label={t("modalprint.price")}
        type="number"
        value={price}
        variant="outlined"
        onChange={onChangePrice}
        fullWidth
        InputProps={{
          endAdornment: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ColorCube color={colorPrice} onClick={handleClick} />
              <FontIcon onClick={() => setViewFont(!viewFont)} margin={false} />
            </div>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice color={colorPrice} handleChangeColor={handleChangeColor} />
      </Popover>
      {viewFont && (
        <FontManager
          open={viewFont}
          handleChangeSize={handleChangeSize}
          handleChangeWeight={handleChangeWeight}
          handleChangeFont={handleChangeFont}
          police={priceFont.font}
        />
      )}
    </TextFieldContainer>
  );
};

export default PriceComponent;
