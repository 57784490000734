/* eslint-disable no-unused-vars */
import { TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { ShowIcon } from "../../utils/Styles/Contact.styles";
import PdfPreview from "./PdfPreview";
import ModalPdf from "./PdfElements/ModalPdf";
import { choices } from "../../../Project/Utils/dataChoices";

const convertDate = (getDate) => {
  getDate = new Date(getDate);
  const date = new Intl.DateTimeFormat("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(getDate);
  return date;
};
const convertTime = (getDate) => {
  getDate = new Date(getDate);
  const date = new Intl.DateTimeFormat("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(getDate);
  return date;
};

const LeadsSmallRow = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const { row } = props;

  const showPdf = () => {
    props.setRow(props.index);
    props.setModal(true);
  };

  const getCategory = (value) => {
    const result = choices.category.filter((obj) => {
      return obj.id === value;
    });
    return result;
  };

  return (
    <>
      <TableRow role="checkbox" tabIndex={-1}>
        <TableCell align={"center"}>
          <ShowIcon onClick={() => redirect(`/projects/${row.project._id}`)} />
        </TableCell>
        <TableCell align={"center"}>
          {row.project.slug.substr(0, 40) + "..."}
        </TableCell>
        <TableCell align={"center"}>{row.project.reference}</TableCell>
        <TableCell align={"center"}>
          {translate(getCategory(row.project.category)[0].name)}
        </TableCell>
        <TableCell align={"center"}>{`${row.project.price} ${
          row.project.currency ? row.project.currency : "€"
        }`}</TableCell>
        <TableCell align={"center"}>{`${convertDate(
          row.offer.date
        )} à ${convertTime(row.offer.date)}`}</TableCell>

        <TableCell align={"center"}>
          <ShowIcon onClick={() => showPdf()} />
        </TableCell>
        {row.project.user ? (
          <TableCell align={"center"}>
            {row.project.user.firstname} {row.project.user.lastname}
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};

export default LeadsSmallRow;
