import * as React from "react";
import { useLocale, useSetLocale } from "ra-core";
import Flag from "react-world-flags";
import {
  FormControl,
  InputBase,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import i18n from "../../../i18n";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Open Sans, sans-serif"].join(","),
    "&:focus": {
      borderRadius: 4,
      backgroundColor: "transparent",
    },
  },
}))(InputBase);

const LocaleSwitcher = (props) => {
  const setLocale = useSetLocale();
  const locale = useLocale();

  const handleChange = (event) => {
    const { lang } = event.currentTarget.dataset;
    setLocale(event.target.value);
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };
  return (
    <div>
      <FormControl>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={locale}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value={"fr-FR"} data-lang="fr-FR">
            <Flag code="FR" height="16" />
          </MenuItem>
          <MenuItem value={"en-US"} data-lang="en-US">
            <Flag code="US" height="16" />
          </MenuItem>
          <MenuItem value={"it-IT"} data-lang="it-IT">
            <Flag code="IT" height="16" />
          </MenuItem>
          <MenuItem value={"es-ES"} data-lang="es-ES">
            <Flag code="ES" height="16" />
          </MenuItem>
          <MenuItem value={"pt-PT"} data-lang="pt-PT">
            <Flag code="PT" height="16" />
          </MenuItem>
          <MenuItem value={"de-DE"} data-lang="de-DE">
            <Flag code="DE" height="16" />
          </MenuItem>
          <MenuItem value={"nl-NL"} data-lang="nl-NL">
            <Flag code="NL" height="16" />
          </MenuItem>
          <MenuItem value={"notaire"} data-lang="notaire">
            <Flag code="FR" height="16" />{" "}
            <span style={{ marginLeft: "5px", paddingTop: "2px" }}>
              Notaire
            </span>
          </MenuItem>
          {/* <MenuItem value={"es"} data-lang="es-ES">
            <Flag code="ES" height="16" />
          </MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
};

export default LocaleSwitcher;
