import React, { useContext } from "react";
import { useRedirect, Edit, TabbedForm, FormTab } from "react-admin";
import PostCreateToolbar from "../Utils/PostCreateToolbar";
import ProfileForm from "./ProfileForm";

// Storage
import { UserContext } from "../../../store/UserProvider";

const ProfileEdit = ({ ...props }) => {
  const { user, setUser } = useContext(UserContext);
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    const newUser = { ...user, ...data };
    setUser(newUser);
    redirect("/");
  };
  return (
    <div>
      <Edit
        id={user._id}
        resource="users"
        basePath="/profile"
        onSuccess={onSuccess}
        undoable={false}
        {...props}
      >
        <TabbedForm toolbar={<PostCreateToolbar {...props} />}>
          <FormTab label="resources.users.tabname.profile">
            <ProfileForm {...props} />
          </FormTab>
        </TabbedForm>
      </Edit>
    </div>
  );
};

export default ProfileEdit;
