import React from "react";
import LondresTemplateLandscape from "./LondresTemplateLandscape/LondresTemplateLandscape";
import LondresTemplatePortrait from "./LondresTemplatePortrait/LondresTemplatePortrait";

const index = React.forwardRef(
  ({ isMini, isPreview, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <LondresTemplateLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    } else {
      return (
        <LondresTemplatePortrait
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
