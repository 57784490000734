import styled from "styled-components";

export const LondresTemplateContainer = styled.div`
  && {
    height: 296.5mm;
    width: 420mm;
    background-color: white;
    display: flex;
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
    position: relative;
  }
  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: landscape;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const BackgroundContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const LogoContainer = styled.div`
  position: absolute;
  width: 167pt;
  top: 30px;
  left: 50px;
`;
export const LogoAgence = styled.img`
  width: 100%;
`;

export const ExcluContainer = styled.div`
  position: absolute;
  padding: 5px 40px;
  background-color: ${(props) => props.colorPrimary};
  width: 218pt;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 185px;
  left: 0;
`;
export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const QrcodeContainer = styled.div`
  width: 135pt;
  height: 135pt;
  background-color: white;
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled.div`
  width: 40%;
  height: 100%;
  background-color: ${(props) => props.colorPrimary};
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 15px;
`;
export const InfoLeftContainer = styled.div`
  width: 100%;
  text-align: left;
  position: relative;
`;

export const InfoType = styled.p`
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  color: ${(props) => props.colorTitle};
  margin-top: 20px;
  margin-bottom: 0;
`;
export const InfoCity = styled.p`
  color: ${(props) => props.colorSubTitle};
  margin-top: 5px;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
export const InfoRef = styled.p`
  margin-top: 5px;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const InfoRightContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  margin-left: -28px;
  padding: 25px 0;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;

export const InfoContainerAlt = styled.div`
  width: 100%;
  height: 20%;
  background-color: #18374a;
  display: flex;
`;
export const InfoContainerPrice = styled.div`
  padding: 30px 0;
`;
export const AlurContainer = styled.div`
  padding: 10px 0 0;
  min-height: 120px;
`;

export const DpeContainer = styled.div`
  position: absolute;
  bottom: 8pt;
  right: 5pt;
  width: 445pt;
  overflow: hidden;
  display: flex;
  height: 30%;
`;

export const DescContainer = styled.div`
  padding: 0 10px 20px 0;
  max-height: 185px;
  min-height: 185px;
  overflow: hidden;
`;

export const DescText = styled.p`
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
  color: ${(props) => props.colorDesc};
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 115%;
  white-space: pre-wrap;
  text-align: left;
`;
