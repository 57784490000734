import styled from "styled-components";

export const HdrContainer = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem auto;
  max-width: 500px;
  padding-top: 1rem;
`;

export const SliderContainer = styled.div``;
export const SliderBrightnessContainer = styled.div``;
export const SliderSaturationContainer = styled.div``;

export const SliderTextName = styled.p``;

export const ImageRenderContainer = styled.div`
  margin-top: 1rem;
`;
export const ImageRender = styled.img`
  width: 100%;
  object-fit: cover;
  height: auto;
  max-height: 300px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
