// max 1190,55
import styled from "styled-components";

export const Container = styled.div`
  width: 297mm;
  height: 420mm;
  background-color: white;
  margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
  zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 portrait;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const ExcluContainer = styled.div`
  width: 181.17pt;
  height: 47.82pt;
  background-color: ${(props) => props.colorPrimary};
  position: absolute;
  left: 0;
  top: 20pt;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "24pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 620.5pt;
  position: relative;
`;
export const ImgProperty = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const ContainerDescription = styled.div`
  width: 100%;
  height: 118pt;
  display: flex;
  background-color: ${(props) => props.colorSecondary};
`;

export const ContainerDescription1 = styled.div`
  width: 40%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 30pt;
`;

export const Title = styled.h3`
  color: ${(props) => props.colorTitle};
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "26pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const Location = styled.h4`
  color: ${(props) => props.colorSubTitle};
  font-weight: 400;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "400")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const ContainerDescription2 = styled.div`
  width: 60%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding-right: 10pt;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorDesc};
  margin-top: 10px;
  margin-bottom: 0;
`;

export const ContainerDescription3 = styled.div`
  width: 100%;
  height: 450.51pt;
  padding: 0 32pt;
`;

export const ContainerDescription3Top = styled.div`
  min-height: 30%;
  max-height: 30%;
  padding-top: 20pt;
  overflow: hidden;
  width: 100%;
`;

export const Description = styled.p`
  text-align: left;
  line-height: 115%;
  color: ${(props) => props.colorDesc};
  white-space: pre-wrap;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const Ref = styled.small`
  text-align: left;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
  color: ${(props) => (props.color !== "" ? props.color : "black")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const ContainerDescription3Bottom = styled.div`
  height: 56%;
  width: 100%;
  display: flex;
`;
export const ContainerBottomLeft = styled.div`
  width: 35%;
`;
export const ContainerBottomRight = styled.div`
  display: flex;
  width: 65%;
  margin-top: -75px;
  position: relative;
  overflow: hidden;
`;

export const QrcodeContainer = styled.div`
  width: 135pt;
  height: 135pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10pt;
  margin-right: 15px;
  margin-top: 5pt;
`;

export const AlurContainer = styled.div`
  padding: 10pt 0 0;
  min-height: 120px;
`;
export const LogoContainer = styled.div`
  width: 167pt;
  position: absolute;
  right: 20pt;
  top: 20pt;
`;
export const LogoAgence = styled.img`
  width: 100%;
`;
export const PriceContainer = styled.div`
  position: absolute;
  width: 267.06pt;
  height: 85.5pt;
  background-color: ${(props) => props.colorPrimary};
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 100pt;
  right: 0;
`;
export const AlurText = styled.p`
  font-size: 12px;
  font-weight: initial;
  color: black;
  margin-bottom: 0;
  margin-top: 0;
`;
export const DpeTextContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 115px;
  max-width: 475px;
`;
