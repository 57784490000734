import { useState } from "react";

const useShow = () => {
  const [showScene, setShowScene] = useState(true);
  const [showOffer, setShowOffer] = useState(false);

  function show(params) {
    if (params === "scene") {
      setShowScene(true);
      setShowOffer(false);
    } else if (params === "offer") {
      setShowScene(false);
      setShowOffer(true);
    }
  }
  return { showOffer, showScene, show };
};

export default useShow;
