import React, { useContext } from "react";
import { CardContent } from "@material-ui/core";
import {
  Filter,
  FilterList,
  FilterListItem,
  SearchInput,
  useTranslate,
} from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  endOfYesterday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import { choices } from "./dataChoices";

import { Card } from "./Styles/Agency.mui.styles";
import { UiContext } from "../../../../store/UserProvider";

const LastCreatedFilter = () => {
  const translate = useTranslate();
  return (
    <FilterList
      label={translate("resources.fields.createdAt")}
      icon={<AccessTimeIcon />}
    >
      <FilterListItem
        label={translate("resources.date.today")}
        value={{
          createdAt_$gte: endOfYesterday().toISOString(),
          createdAt_$lte: undefined,
        }}
      />
      <FilterListItem
        label={translate("resources.date.thisWeek")}
        value={{
          createdAt_$gte: startOfWeek(new Date()).toISOString(),
          createdAt_$lte: undefined,
        }}
      />
      <FilterListItem
        label={translate("resources.date.lastWeek")}
        value={{
          createdAt_$gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
          createdAt_$lte: startOfWeek(new Date()).toISOString(),
        }}
      />
      <FilterListItem
        label={translate("resources.date.thisMonth")}
        value={{
          createdAt_$gte: startOfMonth(new Date()).toISOString(),
          createdAt_$lte: undefined,
        }}
      />
      <FilterListItem
        label={translate("resources.date.lastMonth")}
        value={{
          createdAt_$gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
          createdAt_$lte: startOfMonth(new Date()).toISOString(),
        }}
      />
      <FilterListItem
        label={translate("resources.date.earlier")}
        value={{
          createdAt_$gte: undefined,
          createdAt_$lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        }}
      />
    </FilterList>
  );
};
const SegmentFilter = () => {
  const translate = useTranslate();
  const typeAboAds = ["agent", "agence", "business", "team", "leader"];
  // const state = ["active", "trial", "waiting"];
  const value = (abo) => {
    if (abo.id === "print") {
      return { "abo.print.state_$in": ["active", "trial", "waiting"] };
    } else if (typeAboAds.includes(abo.id)) {
      return {
        "abo.ads.subtype_$in": abo.id,
        "abo.ads.state_$in": ["active", "trial", "waiting"],
      };
    } else if (abo.id === "display") {
      return { "abo.display.state_$in": ["active", "trial", "waiting"] };
    } else {
      return null;
    }
  };
  return (
    <FilterList
      label={translate("resources.agencies.abo")}
      icon={<LocalOfferIcon />}
    >
      {choices.abo.map((subscription) => (
        <FilterListItem
          label={subscription.name}
          key={subscription.id}
          value={value(subscription)}
        />
      ))}
    </FilterList>
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name-phone-city_$like" alwaysOn />
  </Filter>
);

const FilterSidebar = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { ui } = useContext(UiContext);
  return (
    <>
      {ui && ui.sideBarVisibble && (
        <Card>
          <CardContent>
            <SearchFilter />
            <LastCreatedFilter />
            <SegmentFilter />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default FilterSidebar;
