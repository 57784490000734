import React from "react";
import Loader from "react-loader-spinner";
const DotSvg = (props) => {
  return (
    <>
      {props.count === 0 && (
        <img
          src={props.imageLoad}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            objectFit: "cover",
            filter: "blur(6px)",
            width: "100%",
            height: "100%",
          }}
          alt="loader"
        />
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div style={{ zIndex: "1000" }}>
          <Loader type="ThreeDots" color="#ffffff" height={90} width={90} />
        </div>
      </div>
    </>
  );
};

export default DotSvg;
