import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Error";
import { colorPrimary, colorTertiary } from "../../GlobalStyles";
import { Button } from "@material-ui/core";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
`;
export const InnerContainer = styled.div`
  min-height: 50vh;
  min-width: 450px;
  border-radius: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 50vw;
  padding: 1rem;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
`;

export const Title = styled.h2`
  text-align: center;
  color: #34495e;
`;
export const Message = styled.p`
  margin-top: 1rem;
  color: ${colorPrimary};
  text-align: center;
  font-size: 1.2rem;
`;
export const Icon = styled(ErrorIcon)`
  color: ${colorTertiary};
  font-size: 40px;
  margin-bottom: 1rem;
`;

export const BtnVisite = styled(Button)`
  && {
    background-color: #00b9ff;
    color: white;
    margin-top: 1rem;
    border-radius: 10px;
    padding: 8px 16px;

    &:hover {
      color: ${colorPrimary} !important;
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
`;
