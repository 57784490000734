import styled from "styled-components";

export const Container = styled.div`
  height: 296mm;
  width: 420mm;
  background-color: #ffffff;
  position: relative;
  margin: ${({ $marginAuto }) => ($marginAuto ? "auto" : "0")};
  zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 landscape;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const ContainerTop = styled.div`
  width: 100%;
  height: 595pt;
  display: flex;
`;

export const ContainerBottom = styled.div`
  width: 100%;
  height: 245pt;
  display: flex;
`;

export const ContainerTopLeft = styled.div`
  height: 100%;
  width: 75%;
  position: relative;
`;

export const ImgBackGround = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const ContainerTopRight = styled.div`
  height: 100%;
  width: 298.47pt;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerTopTitle = styled.div`
  margin-top: 66pt;
  width: 321.22pt;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: ${(props) => props.colorPrimary};
  text-align: center;
`;

export const Triange = styled.div`
  width: 0%;
  height: 0%;
  margin-top: 66pt;
  position: absolute;
  right: 299pt;
  top: 10%;
  border-top: 0px solid transparent;
  border-bottom: 35.19pt solid transparent;
  border-right: 31px solid ${(props) => props.colorPrimary};
`;

export const Type = styled.h3`
  color: ${(props) => props.colorTitle};
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const Location = styled.h3`
  color: ${(props) => props.colorSubTitle};
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const ContainerTopRight1 = styled.div`
  width: 100%;
  height: 78%;
`;

export const Description = styled.p`
  text-align: left;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-top: 53%;
  line-height: 115%;
  color: ${(props) => props.colorDesc};
  white-space: pre-wrap;
  max-height: 300pt;
  overflow: hidden;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const ContainerTopRight2 = styled.div`
  width: 298.47pt;
  height: 92.56pt;
  background-color: ${(props) => props.colorPrimary};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerBottomLeft = styled.div`
  width: 42%;
  height: 100%;
  display: flex;
  padding-top: 35px;
  padding-left: 20px;
  position: relative;
  overflow: hidden;
`;

export const RefContainer = styled.div`
  width: 100%;
`;

export const Ref = styled.p`
  margin-bottom: 10pt;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const ContainerBottomCenter = styled.div`
  width: 32%;
  padding: 20pt 0 0 10pt;
`;

export const ContainerBottomRight = styled.div`
  width: 420px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10pt;
  justify-content: center;
  padding: 10pt 0 10pt 0;
`;

export const QrcodeContainer = styled.div`
  width: 120pt;
  height: 120pt;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15pt;
  left: 15pt;
`;
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 298pt;
  height: 64pt;
  min-height: 64pt;
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
export const LogoAgence = styled.img`
  height: 60pt;
`;
export const ExcluContainer = styled.div`
  padding: 5px 0;
  background-color: ${(props) => props.colorPrimary};
  width: 198pt;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 65pt;
  position: absolute;
  left: 0;
`;
export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const CategoryContainer = styled.div`
  padding: 5px 0;
  background-color: ${(props) => props.colorPrimary};
  min-width: 151.99pt;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 125pt;
  position: absolute;
  left: 0;
  padding: 5pt 15pt;
`;
export const CategoryText = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "23pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
