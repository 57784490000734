import React from "react";
import { useRedirect } from "ra-core";
import { EditIcon } from "../Utils/Styles/Agency.styles";

const EditButtonField = (props) => {
  const redirect = useRedirect();
  return <EditIcon onClick={() => redirect("/agencies/" + props.record.id)} />;
};

export default EditButtonField;
