/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@material-ui/core";
import FirstImageChoice from "./FirstImageChoice";
import ImagesSuppChoice from "./ImagesSuppChoice";
import Hdr from "./Hdr";
import {
  AlertContainer,
  AlertText,
  Br,
  ContainerStepThree,
  TitleContainerWithAlert,
  TitleSection,
} from "./Styles/StepThree.styles";
import { useTemplatesStore } from "../../../../store/TemplatesStore";

const StepThree = () => {
  const {
    project,
    setImageFirst,
    imageSupp,
    setImageSupp,
    model,
    orientation,
    setBrightness,
    setSaturation,
    setTitleText,
  } = useTemplatesStore();
  const { t } = useTranslation();
  const [alert, setAlert] = useState(false);
  const [numberImageSupp, setNumberImageSupp] = useState(false);

  useEffect(() => {
    const noSupp = [
      "Paris",
      "Berlin",
      "Sydney",
      "Barcelone",
      "Singapour",
      "Elfi",
      "Bersot Immobilier",
    ];
    if (noSupp.includes(model)) {
      setNumberImageSupp(0);
    } else if (orientation === "portrait" && model === "Londres") {
      setNumberImageSupp(3);
    } else if (orientation === "landscape" && model === "Londres") {
      setNumberImageSupp(0);
    } else if (
      orientation === "landscape" &&
      model === "Roland Gosselin & Associés"
    ) {
      setNumberImageSupp(0);
    } else if (model === "Cuppertino" && orientation === "portrait") {
      setNumberImageSupp(2);
    } else if (model === "Cuppertino" && orientation === "landscape") {
      setNumberImageSupp(0);
    } else if (model === "Cali") {
      setNumberImageSupp(3);
    } else if (model === "Perso") {
      setNumberImageSupp(1);
    } else if (model === "Bersot Immobilier") {
      setNumberImageSupp(0);
    } else if (model === "Loft And House") {
      setNumberImageSupp(1);
    } else if (model === "IPH" || model === "IPH Second") {
      setNumberImageSupp(0);
    } else if (model === "Madrid") {
      setNumberImageSupp(0);
    } else if (model === "Sapene") {
      setNumberImageSupp(4);
    } else {
      setNumberImageSupp(2);
    }
    if (model === "Bersot Immobilier") {
      setTitleText(project.metatitle);
    } else {
      setTitleText(project.type);
    }
  }, [orientation, model]);

  const handleImage = (src) => {
    setImageFirst(src);
  };
  const handleImageSupp = (src) => {
    let array = imageSupp;
    if (array.includes(src)) {
      setAlert(false);
      const newArray = array.filter((item) => item !== src);
      setImageSupp(newArray);
    } else if (imageSupp.length < numberImageSupp) {
      setAlert(false);
      setImageSupp((prev) => [...prev, src]);
    } else {
      setAlert(true);
    }
  };

  const handleBrightnessChange = (event, newValue) => {
    setBrightness(newValue);
  };
  const handleSaturationChange = (event, newValue) => {
    setSaturation(newValue);
  };

  return (
    <Fade in={true} timeout={400}>
      <ContainerStepThree>
        <FirstImageChoice handleImage={handleImage} />
        <Br />
        {numberImageSupp > 0 ? (
          <>
            <TitleContainerWithAlert>
              {alert ? (
                <Fade in={true} timeout={400}>
                  <AlertContainer>
                    <AlertText>{t("modalprint.nomoreimage")}</AlertText>
                  </AlertContainer>
                </Fade>
              ) : (
                <Fade in={true} timeout={400}>
                  <TitleSection lineHeight={"unset"}>
                    {numberImageSupp} {t("modalprint.imagesupp").toUpperCase()}
                  </TitleSection>
                </Fade>
              )}
            </TitleContainerWithAlert>
            <ImagesSuppChoice handleImageSupp={handleImageSupp} />
            <Br />
          </>
        ) : null}
        <TitleSection>{t("modalprint.hdrrender").toUpperCase()}</TitleSection>
        <Hdr
          handleBrightnessChange={handleBrightnessChange}
          handleSaturationChange={handleSaturationChange}
        />
      </ContainerStepThree>
    </Fade>
  );
};

export default StepThree;
