import React from "react";
import { BooleanInput, SelectInput, useTranslate } from "react-admin";
import { Box, Button, CardContent, Fade } from "@material-ui/core";
import { choices } from "../Utils/data-choices";
import { sendActivationApi } from "../Utils/axiosRequest";
import { useAlert } from "react-alert";

const UserForm = (props) => {
  const alert = useAlert();
  const sendActivation = async () => {
    await sendActivationApi(props.record.email);
    alert.show(translate("resources.fields.emailSent"));
  };

  const translate = useTranslate();
  return (
    <Fade in={true} timeout={300}>
      <CardContent className="Form-Project">
        <Box>
          {/* INFORMATION Part */}
          <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px" mt="20px">
            <Box
              display={{ xs: "block", sm: "flex" }}
              style={{ alignItems: "center" }}
            >
              <Box flex={1} mr={"100px"}>
                <SelectInput
                  source="permissions"
                  resource="users"
                  fullWidth
                  choices={choices.permissions}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <BooleanInput
                  source="isActivated"
                  resource="users"
                  fullWidth
                  style={{ flexDirection: "initial" }}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <BooleanInput
                  source="isVerified"
                  resource="users"
                  fullWidth
                  style={{ flexDirection: "initial" }}
                />
              </Box>
            </Box>
            {props.record.id && (
              <Box
                display={{ xs: "block", sm: "flex" }}
                style={{ alignItems: "center" }}
              >
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mt={"1rem"}>
                  <Button onClick={sendActivation}>
                    {translate("resources.fields.sendActivation")}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {/* end */}
      </CardContent>
    </Fade>
  );
};

export default UserForm;
