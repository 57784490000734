import React from "react";
import { useTranslation } from "react-i18next";
import ActionToCall from "./ActionToCall";
import TemplatesList from "./Utils/TemplatesList";
import MyFavorites from "./MyFavorites";
import Error from "../../../Components/Error/Error";
import Loader from "../../../Components/Loader/Loader";
import { AlertContainer, AlertText } from "./StepThree/Styles/StepThree.styles";
import { Fade } from "@material-ui/core";
import { colorPrimary as colorP } from "../../../GlobalStyles";
import { useReactToPrint } from "react-to-print";

import {
  LinkBtn,
  ModeButton,
  PrintActionContainer,
  PrintBodyContainer,
  PrintContainer,
  TitlePrint,
  TitlePrintContainer,
} from "./Styles/ModalPrint.styles";
// IMPORT ALL REFS

import { useTemplatesStore } from "../../../store/TemplatesStore";
import { useEffect } from "react";
import StepFirst from "./StepFirst/StepFirst";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import StepFour from "./StepFour/StepFour";

import {
  barceloneLandscapeRef,
  berlinLandscapeRef,
  cuppertinoLandscapeRef,
  londresLandscapeRef,
  londresPortraitRef,
  parisLandscapeRef,
  singapourLandscapeRef,
  barcelonePortraitRef,
  berlinPortraitRef,
  cuppertinoPortraitRef,
  parisPortraitRef,
  singapourPortraitRef,
  sydneyLandscapeRef,
  sydneyPortraitRef,
  persoLandscapeRef,
  persoPortraitRef,
  bersotLandscapeRef,
  elfiLandscapeRef,
  gosselinLandscapeRef,
  loftAndHouseLandscapeRef,
  iphLandscapeRef,
  iphSecondLandscapeRef,
  madridLandscapeRef,
  sapeneLandscapeRef,
  templateRefSelected,
} from "./Utils/RefTemplates";
import { useParams } from "react-router-dom";

const Print = (props) => {
  const { t } = useTranslation();
  const {
    getData,
    step,
    setStep,
    noResult,
    project,
    loading,
    mode,
    setMode,
    titlePage,
    message,
    orientation,
    setOrientation,
    setModel,
    model,
    setValueFromFavorite,
  } = useTemplatesStore();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  // const wait = (timeout) => {
  //   return new Promise((resolve) => setTimeout(resolve, timeout));
  // };

  const printLandscape = useReactToPrint({
    content: () => {
      return templateRefSelected(orientation, model); // FUNCTION TO RETURN CURRENT REF
    },
  });

  const handleValueFromFavorite = async (item, setter) => {
    setStep(4);
    setOrientation(item.orientation);
    setModel(item.model);
    setValueFromFavorite(item, setter);
  };

  const favoriteAction = (setter) => {
    if (setter === "print") {
      printLandscape();
    } else {
      setMode("create");
    }
  };

  return (
    <>
      {noResult && <Error />}
      {loading && <Loader />}
      {!noResult && !loading && project !== null ? (
        <PrintContainer>
          <Fade
            in={project.templates && project.templates.length ? true : false}
            timeout={400}
          >
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <ModeButton
                selected={mode === "create"}
                onClick={() => {
                  setStep(1);
                  setMode("create");
                }}
              >
                {t("common.create")}
              </ModeButton>
              <ModeButton
                selected={mode === "myFavorites"}
                onClick={() => setMode("myFavorites")}
              >
                {t("modalprint.myfavorites")}
              </ModeButton>
            </div>
          </Fade>
          {mode === "myFavorites" && (
            <MyFavorites
              handleValueFromFavorite={handleValueFromFavorite}
              favoriteAction={favoriteAction}
            />
          )}
          {mode === "create" && (
            <>
              <TitlePrintContainer>
                <TitlePrint>{titlePage}</TitlePrint>
              </TitlePrintContainer>
              <Fade in={message} timeout={400}>
                <AlertContainer success={"true"}>
                  <AlertText>{message}</AlertText>
                </AlertContainer>
              </Fade>
              <PrintBodyContainer>
                {step === 1 && <StepFirst />}
                {step === 2 && <StepTwo />}
                {step === 3 && <StepThree />}
                {step === 4 && <StepFour />}
              </PrintBodyContainer>
            </>
          )}
          <PrintActionContainer>
            {mode === "create" && (
              <>
                <LinkBtn
                  disabled={step === 1 ? true : false}
                  onClick={() => setStep(step - 1)}
                  $color={colorP}
                >
                  {t("modalprint.previous")}
                </LinkBtn>
                <ActionToCall printLandscape={printLandscape} />
              </>
            )}
          </PrintActionContainer>
          {step === 4 && (
            <TemplatesList
              ref={{
                barceloneLandscapeRef,
                berlinLandscapeRef,
                cuppertinoLandscapeRef,
                londresLandscapeRef,
                londresPortraitRef,
                parisLandscapeRef,
                singapourLandscapeRef,
                barcelonePortraitRef,
                berlinPortraitRef,
                cuppertinoPortraitRef,
                parisPortraitRef,
                singapourPortraitRef,
                persoLandscapeRef,
                persoPortraitRef,
                sydneyLandscapeRef,
                sydneyPortraitRef,
                bersotLandscapeRef,
                elfiLandscapeRef,
                gosselinLandscapeRef,
                loftAndHouseLandscapeRef,
                iphLandscapeRef,
                madridLandscapeRef,
                sapeneLandscapeRef,
                iphSecondLandscapeRef,
              }}
            />
          )}
        </PrintContainer>
      ) : null}
    </>
  );
};

export default Print;
