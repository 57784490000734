import React from "react";
import { useTranslation } from "react-i18next";
import ModuleAlurDetails from "../Print/TemplatePrint/ModulesElements/ModuleAlurDetails";
import ModulePrice from "../Print/TemplatePrint/Modules/ModulePrice";

import { choice } from "./Utils/dataChoice";
import {
  AgencyLogo,
  AgencyNameText,
  Container,
  HeroContainer,
  ImageHero,
  LogoContainer,
  TopContainer,
  CategoryContainer,
  CategoryText,
  BottomInnerContainer,
  BottomContainer,
  TypeText,
  CityText,
  Description,
  ContactText,
  LabelContact,
  RefText,
  AlurContainer,
  DpeGesContainer,
  IconContainer,
  IconText,
  DpeTextContainer,
  AlurText,
} from "./PdfDetails.styles";
import RenderContext from "../Print/TemplatePrint/ModulesElements/RenderContext";
import Surface from "../../assets/LogoTemplates/Surface";
import Rooms from "../../assets/LogoTemplates/Rooms";
import Chambres from "../../assets/LogoTemplates/Chambres";
import Sdb from "../../assets/LogoTemplates/Sdb";
import { DpeGesNoValue } from "../Print/TemplatePrint/DpeGesNoValue";
import Ges from "./DPEPDF/Ges";
import Dpe from "./DPEPDF/Dpe";

const PdfDetails = (props) => {
  const { t } = useTranslation();
  return (
    <Container id="pdf-page">
      <TopContainer>
        <AgencyLogo
          src={
            props.agency.images && props.agency.images.length
              ? props.agency.images[0].src
              : "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-1-1-1536x478.png"
          }
          alt="logo"
        />
        <AgencyNameText>{props.agency.name}</AgencyNameText>
      </TopContainer>
      <HeroContainer>
        <ImageHero src={props.infoProject.images[0].src} alt="logement" />
        <CategoryContainer>
          <CategoryText>
            {t(
              choice.category.filter(
                (item) => item.id === props.infoProject.category
              )[0].name
            )}
          </CategoryText>
        </CategoryContainer>
      </HeroContainer>
      <LogoContainer>
        <IconContainer>
          <Surface color={"black"} size={40} />
          <IconText colorSecondary={"black"}>
            {props.infoProject.area.total} m2
          </IconText>
        </IconContainer>
        {props.infoProject.rooms && props.infoProject.rooms > 0 ? (
          <IconContainer>
            <Rooms color={"black"} size={40} />
            <IconText colorSecondary={"black"}>
              {props.infoProject.rooms} {t("modalprint.rooms")}
            </IconText>
          </IconContainer>
        ) : null}
        {props.infoProject.bedrooms && props.infoProject.bedrooms > 0 ? (
          <IconContainer>
            <Chambres color={"black"} size={40} />
            <IconText colorSecondary={"black"}>
              {props.infoProject.bedrooms} {t("modalprint.bedrooms")}
            </IconText>
          </IconContainer>
        ) : null}
        {props.infoProject.sdb && props.infoProject.sdb > 0 ? (
          <IconContainer>
            <Sdb color={"black"} size={40} />
            <IconText colorSecondary={"black"}>
              {props.infoProject.sdb ? props.infoProject.sdb : 0}{" "}
              {t("modalprint.sdb")}
            </IconText>
          </IconContainer>
        ) : null}
      </LogoContainer>
      <BottomContainer>
        <BottomInnerContainer>
          <TypeText>
            {props.infoProject.type &&
            choice.type.filter((item) => item.id === props.infoProject.type)
              .length
              ? t(
                  choice.type.filter(
                    (item) => item.id === props.infoProject.type
                  )[0].name
                )
              : null}
          </TypeText>
          <CityText>{props.infoProject.city}</CityText>
          <Description>
            {props.infoProject.comments && props.infoProject.comments.length
              ? props.infoProject.comments[0].comments.comment.substr(0, 420) +
                "..."
              : null}
          </Description>
          <ContactText>{t("mobileView.pdf.contact")} : </ContactText>
          <LabelContact>
            {t("mobileView.pdf.agencynumber")} : {props.agency.phone}
          </LabelContact>
          <LabelContact>
            {t("mobileView.pdf.agencyemail")} : {props.agency.email}
          </LabelContact>
        </BottomInnerContainer>
        <BottomInnerContainer>
          <RefText>REF: {props.infoProject.reference}</RefText>
          <AlurContainer>
            <ModulePrice
              colorPrice={"black"}
              price={props.infoProject.price}
              project={props.infoProject}
              align={"left"}
              sizePrice={"30pt"}
              weight={700}
              sizeHonoraire={"10.3pt"}
              sizeSpanPrice={"20pt"}
            />
            <RenderContext project={props.infoProject}>
              <ModuleAlurDetails
                project={props.infoProject}
                colorDesc={"black"}
                price={props.infoProject.price}
                sizeText={"10.3pt"}
                noDpeInfo
              />
            </RenderContext>
          </AlurContainer>
          <DpeGesContainer>
            <RenderContext project={props.infoProject}>
              <div
                style={{
                  position: "absolute",

                  top:
                    DpeGesNoValue.includes(props.infoProject.dpeLetter) ||
                    !props.infoProject.dpeLetter
                      ? "335px"
                      : "340px",
                  left:
                    DpeGesNoValue.includes(props.infoProject.dpeLetter) ||
                    !props.infoProject.dpeLetter
                      ? "90px"
                      : "122px",
                }}
              >
                <Dpe
                  letter={
                    props.infoProject.dpeLetter
                      ? DpeGesNoValue.includes(props.infoProject.dpeLetter)
                        ? "vi"
                        : props.infoProject.dpeLetter
                      : "vi"
                  }
                  conso={props.infoProject.dpeValue}
                  emissions={props.infoProject.gesValue}
                  zoom={
                    DpeGesNoValue.includes(props.infoProject.dpeLetter) ||
                    !props.infoProject.dpeLetter
                      ? "0.13"
                      : "0.15"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top: "355px",
                  left:
                    DpeGesNoValue.includes(props.infoProject.gesLetter) ||
                    !props.infoProject.gesLetter
                      ? "255px"
                      : "295px",
                }}
              >
                <Ges
                  letter={
                    props.infoProject.gesLetter
                      ? DpeGesNoValue.includes(props.infoProject.gesLetter)
                        ? "vi"
                        : props.infoProject.gesLetter
                      : "vi"
                  }
                  emissions={props.infoProject.gesValue}
                  zoom={
                    DpeGesNoValue.includes(props.infoProject.gesLetter) ||
                    !props.infoProject.gesLetter
                      ? "0.27"
                      : "0.18"
                  }
                  theme={"light"}
                />
              </div>
              <DpeTextContainer>
                <AlurText colorDesc={"black"}>
                  {t("modalprint.dpeMinMaxInfo")} :{" "}
                  {props.infoProject.dpeCoutMinConso &&
                  props.infoProject.dpeCoutMinConso !== "" ? (
                    <>
                      {`${t("modalprint.between")} ${
                        props.infoProject.dpeCoutMinConso
                      }€ ${t("modalprint.and")} ${
                        props.infoProject.dpeCoutMaxConso
                      }€ ${t("modalprint.perYear")} `}
                    </>
                  ) : (
                    t("details.modalprint.undisclosed")
                  )}{" "}
                  {props.infoProject.dpeCoutMinConso &&
                    props.infoProject.dpeCoutMinConso !== "" &&
                    props.infoProject.dpeAnneRefConso &&
                    props.infoProject.dpeAnneRefConso !== "" &&
                    t("modalprint.refYear") +
                      " " +
                      props.infoProject.dpeAnneRefConso +
                      " " +
                      t("modalprint.refYearEnd")}
                </AlurText>
              </DpeTextContainer>
            </RenderContext>
          </DpeGesContainer>
        </BottomInnerContainer>
      </BottomContainer>
    </Container>
  );
};

export default PdfDetails;
