import { config } from "../../constants/constant";
import axios from "axios";

export const getProject = async (query, caller) => {
  const url = config.API_BASE + query;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller,
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
