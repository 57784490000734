import React from "react";
import CuppertinoTemplateLandscape from "./CuppertinoTemplateLandscape/CuppertinoTemplateLandscape";
import CuppertinoTemplatePortrait from "./CuppertinoTemplatePortrait/CuppertinoTemplatePortrait";

const index = React.forwardRef(
  ({ isMini, isPreview, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <CuppertinoTemplateLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    } else {
      return (
        <CuppertinoTemplatePortrait
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
