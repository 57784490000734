import styled from "styled-components";

export const Container = styled.div`
  && {
    position: relative;
    height: 296.5mm;
    width: 420mm;
    min-width: 420mm;
    background-color: #ffffff;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const ExcluContainer = styled.div`
  && {
    position: absolute;
    top: 235px;
    padding: 0.5rem;
    width: 240px;
    height: 63px;
    background-color: ${(props) => props.colorPrimary};
  }
`;

export const Exclu = styled.p`
  && {
    margin-bottom: 0;
    text-align: center;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "30px")};
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const TopBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /* height: 126px; */
  width: 100%;
  padding: 20px;
  display: flex;
  background: #00000066 0% 0% no-repeat padding-box;
`;
export const BottomBanner = styled.div`
  position: absolute;
  display: flex;
  height: 325px;
  width: 100%;
  background: #00000080 0% 0% no-repeat padding-box;
  bottom: 0;
  left: 0;
`;
export const Title = styled.h3`
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "40px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  max-width: 1200px;
`;
export const Location = styled.h3`
  color: ${(props) => props.colorSubTitle};
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "30px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
export const LeftContainer = styled.div`
  width: 70%;
`;
export const RightContainer = styled.div`
  width: 30%;
  text-align: right;
`;
export const Ref = styled.p`
  && {
    margin-top: 25px;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15px")};
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;
export const BottomLeftContainer = styled.div`
  width: 27%;
  height: 100%;
`;
export const EnergyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 66%;
  overflow: hidden;
`;
export const EnergyText = styled.h3`
  font-size: 11px;
  color: white;
  margin-left: 55px;
  margin-bottom: 2px;
`;
export const EnergyTextContainer = styled.div`
  padding-top: 10px;
`;
export const BottomRightContainer = styled.div`
  margin-left: auto;
  width: 260px;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const LogoContainer = styled.div`
  width: 118.31px;
  height: 80px;
  margin-top: 12px;
`;
export const Logo280Container = styled.div`
  width: 133px;
  height: 34px;
`;
export const QrcodeContainer = styled.div`
  width: 132px;
  height: 132px;
  border: 5px solid #3fa535;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;
export const BottomCenterContainer = styled.div`
  height: 100%;
  width: 60%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const BottomCenterInner = styled.div`
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 45px;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const AlurContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 8rem;
  grid-column-gap: 30px;
`;
export const GlobalQrcodeLogo = styled.div`
  background-color: #3fa535;
  margin-top: -125px;
  padding: 0 12px;
  border-radius: 8px;
`;
export const QrcodeText = styled.p`
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 5px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
  text-align: center;
`;

export const AlurText = styled.p`
  font-size: 13px;
  font-weight: initial;
  color: white;
  line-height: 18pt;
  margin-bottom: 0;
  min-width: 500px;
`;
