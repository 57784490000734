/* eslint-disable no-unused-vars */
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  useGetIdentity,
  BulkDeleteButton,
  Pagination,
} from "react-admin";
import FilterSidebar from "./Utils/filters";
import ListActions from "./Components/ListActions";
import { UserListStyle } from "./Utils/Styles/User.mui.styles";

import { UserChangeContext, UserContext } from "../../../store/UserProvider";
import WrapperList from "./WrapperList";

const PostBulkActionButtons = (props) => (
  <>
    <BulkDeleteButton {...props} />
  </>
);

const UsersPagination = (props) => (
  <Pagination
    rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 1000]}
    {...props}
  />
);

const UserList = (props) => {
  const classes = UserListStyle();
  const { identity, loading: identityLoading } = useGetIdentity();
  const { userSelect, numberOfUser, agency, ...otherProps } = props;

  let role = ["super_admin", "admin", "manager"];

  const queryShow = () => {
    if (userSelect.permissions === "manager" && userSelect.agenceId) {
      return { agenceId_$in: [userSelect.agenceId] };
    } else if (
      userSelect.permissions === "utilisateur" ||
      (userSelect.permissions === "manager" && !userSelect.agenceId)
    ) {
      return { _id_$in: [userSelect._id] };
    } else {
      return null;
    }
  };

  if (identityLoading) return <>Loading...</>;
  if (!props.userSelect) {
    return <div />;
  } else {
    return (
      <List
        {...otherProps}
        exporter={false}
        pagination={<UsersPagination />}
        sort={{ field: "createdAt", order: "DESC" }}
        filter={queryShow()}
        aside={role.includes(identity.permissions) ? <FilterSidebar /> : null}
        bulkActionButtons={
          identity !== undefined &&
          !identity.permissions.includes("utilisateur") ? (
            <PostBulkActionButtons />
          ) : (
            false
          )
        }
        perPage={50}
        actions={
          <ListActions
            userSelect={userSelect}
            numberOfUser={numberOfUser}
            agency={agency}
          />
        }
      >
        <Datagrid optimized={true} rowClick="edit">
          <TextField source="firstname" />
          <TextField source="lastname" />

          <EmailField
            source="email"
            headerClassName={classes.hiddenOnXSmallScreens}
            cellClassName={classes.hiddenOnXSmallScreens}
          />
          <TextField
            source="permissions"
            headerClassName={classes.hiddenOnSmallScreens}
            cellClassName={classes.hiddenOnSmallScreens}
          />
        </Datagrid>
      </List>
    );
  }
};

export default WrapperList(UserList);
