import { makeStyles } from "@material-ui/core/styles";

export const style = makeStyles((theme, type) => ({
  root: {
    "& .MuiTypography-root": {
      fontSize: "15px",
      color: "#34495ed9",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    "& .MuiListItem-root": {
      width: "95%",
    },
    "& .MuiListItemText-root": {
      textAlign: "center",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "60px",
    },
    paddingTop: "0",
    paddingBottom: "0",
    borderRadius: "10px",
    boxShadow: "",
  },
  collapse: {
    width: "100%",
  },
}));

export const styleOne = makeStyles((theme, type) => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
    "& .MuiTypography-root": {
      fontSize: "14px",
      color: "#34495ed9",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: "800",
    },
    "& .MuiListItem-root": {
      width: (type) => (type ? "95%" : "80%"),
    },
    "& .MuiListItemText-root": {
      textAlign: "center",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "60px",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "0",
      paddingRight: "0",
    },
    borderRadius: "10px",
    boxShadow: "",
  },
}));
