import { config } from "../../../../constants/constant";
import axios from "axios";
import { stringify } from "query-string";

export const getAgency = async (id) => {
  const url = config.API_BASE + "/agencies/" + id;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getProjectFromApi = async (query, queryValue) => {
  const url =
    config.API_BASE + "/projects?%24limit=-1&" + query + "=" + queryValue;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "dashboard",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getLeadsFromApi = async (query, queryValue) => {
  const url =
    config.API_BASE + "/contacts?" + query + "=" + queryValue + "&%24limit=-1";
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);
  const params = {
    method: "GET",
    url,
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch.data;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getUsersData = async (query) => {
  const url = config.API_BASE + "/users?agenceId=" + query + "&%24limit=-1";
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "modalUserChange",
      Authorization: bearer,
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getViewData = async (query) => {
  const url = config.API_BASE + "/view?agenceId=" + query + "&%24limit=-1";
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

// ADMIN REQUESTS

export const getAllProject = async () => {
  const query = {};
  query.$limit = -1;
  const url = config.API_BASE + "/projects?" + stringify(query);
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "dashboard",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getAllAgencies = async () => {
  const url = config.API_BASE + "/agencies?%24limit=-1";
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);

  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getAllUsers = async () => {
  const url = config.API_BASE + "/users?%24limit=-1";
  const bearer = "Bearer " + localStorage.getItem(config.ACCESS_TOKEN_NAME);

  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: bearer,
      caller: "modalUserChange",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
