import { createRef } from "react";
// PRINT REF CREATE
export const londresLandscapeRef = createRef(null);
export const londresPortraitRef = createRef(null);
export const parisLandscapeRef = createRef(null);
export const parisPortraitRef = createRef(null);
export const barceloneLandscapeRef = createRef(null);
export const barcelonePortraitRef = createRef(null);
export const berlinLandscapeRef = createRef(null);
export const berlinPortraitRef = createRef(null);
export const cuppertinoLandscapeRef = createRef(null);
export const cuppertinoPortraitRef = createRef(null);
export const singapourLandscapeRef = createRef(null);
export const singapourPortraitRef = createRef(null);
export const persoLandscapeRef = createRef(null);
export const persoPortraitRef = createRef(null);
export const bersotLandscapeRef = createRef(null);
export const elfiLandscapeRef = createRef(null);
export const gosselinLandscapeRef = createRef(null);
export const sydneyLandscapeRef = createRef(null);
export const sydneyPortraitRef = createRef(null);
export const loftAndHouseLandscapeRef = createRef(null);
export const iphLandscapeRef = createRef(null);
export const iphSecondLandscapeRef = createRef(null);
export const madridLandscapeRef = createRef(null);
export const sapeneLandscapeRef = createRef(null);
// PRINT REF.CURRENT CHOICE
export const templateRefSelected = (orientation, model) => {
  if (orientation === "landscape") {
    if (model === "Londres") return londresLandscapeRef.current;
    else if (model === "Paris") return parisLandscapeRef.current;
    else if (model === "Barcelone") return barceloneLandscapeRef.current;
    else if (model === "Berlin") return berlinLandscapeRef.current;
    else if (model === "Cupertino") return cuppertinoLandscapeRef.current;
    else if (model === "Singapour") return singapourLandscapeRef.current;
    else if (model === "Perso") return persoLandscapeRef.current;
    else if (model === "Sydney") return sydneyLandscapeRef.current;
    else if (model === "Bersot Immobilier") return bersotLandscapeRef.current;
    else if (model === "Elfi") return elfiLandscapeRef.current;
    else if (model === "Osenat") return gosselinLandscapeRef.current;
    else if (model === "Loft And House")
      return loftAndHouseLandscapeRef.current;
    else if (model === "IPH") {
      return iphLandscapeRef.current;
    } else if (model === "IPH Second") {
      return iphSecondLandscapeRef.current;
    } else if (model === "Madrid") return madridLandscapeRef.current;
    else if (model === "Sapene") return sapeneLandscapeRef.current;
  } else {
    if (model === "Londres") return londresPortraitRef.current;
    else if (model === "Paris") return parisPortraitRef.current;
    else if (model === "Barcelone") return barcelonePortraitRef.current;
    else if (model === "Berlin") return berlinPortraitRef.current;
    else if (model === "Cupertino") return cuppertinoPortraitRef.current;
    else if (model === "Singapour") return singapourPortraitRef.current;
    else if (model === "Perso") return persoPortraitRef.current;
    else if (model === "Sydney") return sydneyPortraitRef.current;
  }
};
