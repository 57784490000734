import styled from "styled-components";
import { colorPrimary } from "../../GlobalStyles";
export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => (props.image ? props.image : null)});
  background-size: cover;
  background-position: center center;
`;

export const FormContainerFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #34495ed9;
`;

export const FormInner = styled.div`
  min-width: 400px;
  margin: ${(props) => props.margin};
  z-index: 1000;
  border-radius: 40px;
  background-color: #ffffff;
  width: 100%;
  max-width: 35vw;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 30px 50px 55px;
  transition: all 1s ease;

  @media screen and (max-width: 599px) {
    border-radius: 15px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Logo = styled.img`
  width: 60%;
  height: auto;
  margin: auto;
`;
export const TitleForm = styled.h2`
  text-align: center;
  color: ${colorPrimary};
  font-size: clamp(12px, 1rem, 20px);
  max-width: 400px;
  margin: 0 auto 2rem auto;
  font-weight: 500;
`;
export const TitleProject = styled.h2`
  text-align: center;
  color: #34495e;
  font-size: clamp(12px, 1rem, 20px);
  max-width: 400px;
  margin: 0 auto 1.5rem auto;
  text-transform: uppercase;
  font-weight: 500;
`;
export const TitleAgence = styled.h2`
  text-align: center;
  color: #34495e;
  font-size: clamp(12px, 1.2rem, 20px);
  max-width: 400px;
  margin: 1.5rem auto 0 auto;
  text-transform: uppercase;
  font-weight: 500;
`;
export const TitleMail = styled.h2`
  text-align: center;
  color: #34495e;
  font-size: clamp(12px, 1.1rem, 20px);
  max-width: 400px;
  margin: 1.5rem auto 1.5rem auto;
  text-transform: uppercase;
  font-weight: 500;
`;
export const TitleCode = styled.h2`
  text-align: center;
  color: #34495e;
  font-size: clamp(12px, 1rem, 20px);
  max-width: 400px;
  margin: 1.5rem auto 1.5rem auto;
  font-weight: 500;
`;
export const FormGroup = styled.div`
  margin-top: ${({ small }) => (small ? "1rem" : "1.5rem")};
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
`;
export const SendButton = styled.button`
  display: block;
  color: white;
  width: 100%;
  background-color: ${colorPrimary};
  border: 1px solid transparent;
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 600;
`;
