export const Miniatures = {
  londonLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-londres-paysage.png?alt=media&token=ed07b8fa-cc59-4e59-b850-40bede584f87",
  londonPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-londres-portrait.png?alt=media&token=6fcb9cc4-c7c6-406b-a5b0-336482df152c",
  ParisLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-paris-paysage.png?alt=media&token=d8f3d3c3-2fa3-47da-a8d3-a04fbd731993",
  ParisPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-paris-portrait.png?alt=media&token=f5150d23-8bb3-4b68-bba3-68533cab0157",
  BerlinLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-berlin-paysage.png?alt=media&token=4f01bd27-aa9a-46a0-803c-7b7fc8bc3e2a",
  BerlinPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-berlin-portrait.png?alt=media&token=9ca83484-4b76-475f-8e9a-685da9fa7cd4",
  CupertinoLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-cuppertino-paysage.png?alt=media&token=f9b80687-9544-4e7e-91c5-f8550c2d28da",
  CupertinoPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-cuppertino-portrait.png?alt=media&token=f17a44c8-7151-4256-a148-e329dff005f6",
  SydneyLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-sidney-paysage.png?alt=media&token=5bfdf870-187d-4c87-a3f9-6b3f4b1af7bd",
  SydneyPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-sidney-portrait.png?alt=media&token=a07cd92f-cc2a-4b28-b8b0-7c30b81cb857",
  VienneLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Fvienne-landscape.jpg?alt=media&token=ffc9667f-50f3-4ccf-bb6e-10c59cda346f",
  ViennePortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Fvienne-portrait.jpg?alt=media&token=9c7651a2-ad84-41fc-9b15-04176de04b16",
  SingapourLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-singapour-paysage.png?alt=media&token=c200a863-553e-4016-bf06-9759f73308e3",
  SingapourPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-singapour-portrait.png?alt=media&token=56a2aef2-1699-4182-8c8a-2531aa6947e8",
  BarceloneLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-barcelone-paysage.png?alt=media&token=996b979e-affb-4830-881c-60be5d7cfd4b",
  BarcelonePortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2Fvignettes%2Fvign-barcelone-portrait.png?alt=media&token=0d2bd612-b884-4c4a-94ee-cb92a5cef865",
  CaliLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Fcali-landscape.png?alt=media&token=517124e3-2895-45ab-b613-7a42aa346d88",
  CaliPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Fcali-portrait.png?alt=media&token=56408fa6-de5d-4ed3-8228-cc59c519a116",
  UshuaiaLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Fushuaia-landscape.png?alt=media&token=0fab3320-e11f-4f27-a5a4-b56fe36932c8",
  UshuaiaPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Fushuaia-portrait.png?alt=media&token=9f5f4dfd-cc4e-40e2-8e8c-90f851aac0e1",
  PersoLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FPerso-Landscape.png?alt=media&token=07f08c3b-a207-45cf-a92f-19eb165ba8f4",
  PersoPortrait:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FPersoPortrait.png?alt=media&token=f8d1c978-0f03-4d14-b0d0-c0a159b5a834",
  BersoLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2Ftemplate-bersot.png?alt=media&token=2d42b335-7c62-462c-815d-a8b2cf934c95",
  GosselinLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FGosselin.png?alt=media&token=3b51862e-8153-4c3d-adaf-8a9cd2045e47",
  LoftAndHouseLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FLoftAndHouse-landscape.png?alt=media&token=72cd59a0-26d8-4b8d-9536-ef96b655ab5e",
  IphLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FIphLandscape.png?alt=media&token=b4bd6938-6b88-46fe-9ca5-08033bd2b04c",
  IphSecondLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FIphSecondLandscape.png?alt=media&token=a0ea7833-b98b-4ceb-86ee-66b03ef92aa3",
  MadridLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FMadridLandscape.png?alt=media&token=c07f609f-8417-41d0-a16f-77b108bb0c4f",
  SapeneLandscape:
    "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FMiniaturesTemplate%2FSapeneLandscape.png?alt=media&token=d9eff871-c9a4-4470-85b5-aab77ab140c4",
};
