import styled from "styled-components";

export const HotspotContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* min-width: 7em; */
  transform: scale(1);
  opacity: ${(props) => props.opacity};
  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`;

export const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: solid 5px #ffffffbf;
  cursor: pointer;

  & img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 1110px) {
    width: 4em;
    height: 4em;
  }
  @media screen and (max-width: 600px) {
    width: 3.5em;
    height: 3.5em;
  }
`;
export const RoundContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 5em;
  height: 5em;
  cursor: pointer !important;

  & img {
    width: 100%;
  }

  @media screen and (max-width: 1110px) {
    width: 4em;
    height: 4em;
  }
  @media screen and (max-width: 600px) {
    width: 3.5em;
    height: 3.5em;
  }
`;
export const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 6em;
  height: 2.5em;
  cursor: pointer;

  & img {
    width: 100%;
  }

  @media screen and (max-width: 1110px) {
    width: 4.5em;
    height: 1.5em;
  }
  @media screen and (max-width: 600px) {
    width: 4em;
    height: 1.1em;
  }
`;

export const HotspotInfoTextContainer = styled.div`
  padding: 5px;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.hovered ? "100%" : "0")};
  /* transition: width 500ms ease-in-out; */
`;

export const ContainerSvg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.hovered ? "unset" : "100%")};
`;

export const InfoSvg = styled.svg`
  && {
    font-size: ${(props) => (props.hovered ? "1.5rem" : "3rem")};
    color: white;
    cursor: pointer;
    transition: all 500ms ease-in-out;
  }
`;

export const HotspotInfoText = styled.p`
  color: white;
  margin-bottom: 0;
  margin-top: 0;
  opacity: ${(props) => (props.hovered ? "1" : "0")};
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  max-width: ${(props) => (props.hovered ? "100%" : "1px")};
  white-space: nowrap;
  overflow: hidden;
  /* transition: opacity 500ms ease-in-out; */
`;

export const HotspotInfoHeader = styled.div`
  padding: 0.2rem 0.5rem;
  background-color: ${(props) => (props.hovered ? "#000000c2" : "transparent")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-top-left-radius: ${(props) => (props.hovered ? "5px" : "20px")};
  border-top-right-radius: ${(props) => (props.hovered ? "5px" : "20px")};
  border-bottom-left-radius: ${(props) => (props.isText ? "5px" : "0")};
  border-bottom-right-radius: ${(props) => (props.isText ? "5px" : "0")};
  transition: width 500ms ease-in-out;
`;
export const HotspotInfoContent = styled.div`
  padding: ${(props) => (props.isText ? "0.5rem" : "0")};
  background-color: ${(props) => (props.hovered ? "#00000075" : "transparent")};
  height: ${(props) => (props.hovered && props.isText ? "auto" : "0")};
  width: 100%;
  opacity: ${(props) => (props.hovered ? "1" : "0")};
  border-bottom-left-radius: ${(props) => (props.hovered ? "5px" : "20px")};
  border-bottom-right-radius: ${(props) => (props.hovered ? "5px" : "20px")};
  transition: height 300ms ease-in-out;
`;
export const HotspotInfoContentText = styled.p`
  color: white;
  opacity: ${(props) => (props.hovered ? "1" : "0")};
  margin-bottom: 0;
  margin-top: 0;
  text-align: ${(props) => (props.minWidth > 20 ? "left" : "center")};
  min-width: ${(props) => (props.minWidth > 10 ? "200px" : "unset")};
  padding: 0.5rem;
  transition: opacity 600ms ease-in-out;
`;

export const HotspotInfoContainer = styled.div`
  height: 40px;
  width: ${(props) => (props.hovered ? "auto" : "40px")};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.5s ease-in-out;
`;
