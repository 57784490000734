import { Popover } from "@material-ui/core";
import React from "react";
import ColorChoice from "../ColorChoice";
import { ColorCube, TitleSection } from "../Styles/StepFour.styles";
import { ColorElement, EmptyDiv } from "../Styles/ColorChoice.styles";
import { useTranslation } from "react-i18next";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";

const ColorSecondaryComponent = () => {
  const { t } = useTranslation();
  const { setColorSecondary, colorSecondary } = useTemplatesStore();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangeColor = (color) => {
    setColorSecondary(color.hex);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <ColorElement>
      <EmptyDiv />
      <TitleSection>
        {t("modalprint.colorsecondary").toUpperCase()}
      </TitleSection>
      <ColorCube color={colorSecondary} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice
          color={colorSecondary}
          handleChangeColor={handleChangeColor}
        />
      </Popover>
    </ColorElement>
  );
};

export default ColorSecondaryComponent;
