import React, { useEffect } from "react";
import { config } from "../../constants/constant";
import { useTranslation } from "react-i18next";
import {
  Container,
  Icon,
  InnerContainer,
  Message,
  Title,
  BtnVisite,
} from "./Error.styles";

const Error = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (props.project && props.project.lang) {
      i18n.changeLanguage(props.project.lang);
    }
  }, [i18n, props.project]);
  return (
    <Container>
      <InnerContainer>
        <Icon />
        <Title>
          {props.noDiffuse ? t("errorpage.nomandates") : t("errorpage.nopage")}
        </Title>
        {!props.noDiffuse ? (
          <Message>{t("errorpage.deleteorbrokenpage")}</Message>
        ) : null}
        {props.noDiffuse && (
          <BtnVisite href={`${config.APP_BASE}/visite360/${props.slugAgence}`}>
            {t("errorpage.visitagency")}
          </BtnVisite>
        )}
      </InnerContainer>
    </Container>
  );
};

export default Error;
