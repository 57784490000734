/* eslint-disable no-unused-vars */
import React from "react";
import { SketchPicker } from "react-color";
import {
  ColorChoiceContainer,
  PickerContainer,
} from "./Styles/ColorChoice.styles";

const ColorChoice = (props) => {
  return (
    <ColorChoiceContainer>
      <PickerContainer marginRight={"unset"}>
        <SketchPicker
          disableAlpha={true}
          color={props.color}
          onChange={props.handleChangeColor}
        />
      </PickerContainer>
    </ColorChoiceContainer>
  );
};

export default ColorChoice;
