import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import surface from "../../../../../assets/LogoTemplates/Iph/superficie.svg";
import rooms from "../../../../../assets/LogoTemplates/Iph/pieces.svg";
import logo from "../../../../../assets/LogoTemplates/Iph/logo.svg";
import bedrooms from "../../../../../assets/LogoTemplates/Iph/chambres.svg";
import sdb from "../../../../../assets/LogoTemplates/Iph/sdb.svg";
import garden from "../../../../../assets/LogoTemplates/Iph/surface-terrain.svg";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import QRCode from "react-qr-code";

import {
  AlurContainer,
  AlurText,
  BackgroundImage,
  Container,
  DescContainer,
  DescText,
  DpeContainer,
  DpeText,
  EnergyTextContainer,
  Icon,
  IconContainer,
  IconsContainer,
  IconText,
  Logo,
  LogoContainer,
  Price,
  PriceContainer,
  QrcodeContainer,
  SubTitleText,
  TitleContainer,
  TitleDesc,
  TitleDescContainer,
  TitleText,
} from "./styles";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";

const IphLandscapeSecond = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    mirror,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  const [currency] = useState(project.currency ? project.currency : "€");

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
      isPreview={props.isPreview}
    >
      <TitleContainer>
        <TitleText
          colorSubTitle={colorSubTitle || "black"}
          size={subTitleTextFont.size}
          weight={subTitleTextFont.weight}
          family={subTitleTextFont.font}
          colorTitle={colorSubTitle || "black"}
        >
          {subTitleText}
        </TitleText>
        <SubTitleText
          size={titleTextFont.size}
          weight={titleTextFont.weight}
          family={titleTextFont.font}
          colorTitle={colorTitle || "black"}
        >
          {project.type !== titleText
            ? titleText
            : t(choices.type.filter((item) => item.id === titleText)[0].name)}
        </SubTitleText>
      </TitleContainer>
      <BackgroundImage
        src={imageFirst}
        brightness={brightness}
        saturation={saturation}
      />
      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <QrcodeContainer>
          <QRCode value={qrcodeEdit} size={120} />
        </QrcodeContainer>
      )}
      <LogoContainer>
        <Logo src={logo} alt="logo agence" />
      </LogoContainer>
      <RenderContext project={project}>
        <DpeContainer>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "60px"
                  : "48px",
              left:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "40px"
                  : "0px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.16"
                  : "0.18"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"light"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "15px"
                  : "-35px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "225px"
                  : "245px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.4"
                  : "0.22"
              }
              theme={"light"}
            />
          </div>
        </DpeContainer>
        <EnergyTextContainer>
          <DpeText>
            {t("modalprint.dpeMinMaxInfo")} :{" "}
            {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
              <>
                {`${t("modalprint.between")} ${project.dpeCoutMinConso}€ ${t(
                  "modalprint.and"
                )} ${project.dpeCoutMaxConso}€ ${t("modalprint.perYear")} `}
                .
                {project.dpeCoutMinConso &&
                  project.dpeCoutMinConso !== "" &&
                  project.dpeAnneRefConso &&
                  project.dpeAnneRefConso !== "" &&
                  `${t("modalprint.refYear")} ${project.dpeAnneRefConso}
                ${t("modalprint.refYearEnd")}`}
              </>
            ) : (
              t("details.modalprint.undisclosed")
            )}
          </DpeText>
        </EnergyTextContainer>
      </RenderContext>
      <TitleDescContainer>
        <TitleDesc>À propos :</TitleDesc>
      </TitleDescContainer>
      <DescContainer>
        <DescText
          colorDesc={colorDesc || "black"}
          size={descFont.size}
          weight={descFont.weight}
          family={descFont.font}
        >
          {desc}
        </DescText>
      </DescContainer>
      <AlurContainer>
        {project.category === choices.category[1].id ? (
          <AlurText>
            Honoraires à la charge du locataire :{" "}
            {project.fees > 0
              ? project.fees + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.category === choices.category[1].id ? (
          <AlurText>
            {t("modalprint.feesetatdeslieux")} :{" "}
            {project.feesetatdeslieux > 0
              ? project.feesetatdeslieux + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.category === choices.category[0].id && !project.commission ? (
          <AlurText>Honoraires à la charge du vendeur</AlurText>
        ) : null}
        {project.category === choices.category[0].id && project.commission ? (
          <AlurText>Honoraires à la charge de l'acquéreur</AlurText>
        ) : null}

        {(project.commission || project.sharedCommission) &&
        project.category === choices.category[0].id ? (
          <AlurText>
            Honoraires TTC:{" "}
            {project.fees !== ""
              ? project.fees + (project.feespercent ? " %" : " " + currency)
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {(project.sharedCommission || project.commission) &&
        project.category === choices.category[0].id &&
        project.amountWithoutHonorary ? (
          <AlurText>
            Prix hors honoraires : {getPrice(project.amountWithoutHonorary)}{" "}
            {currency}
          </AlurText>
        ) : null}
        {project.category === choices.category[1].id ? (
          <AlurText>
            {t("details.modalprint.monthlycharge")} :{" "}
            {project.monthlycharge > 0
              ? project.monthlycharge + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.category === choices.category[1].id ? (
          <AlurText>
            {t("details.modalprint.feesdepotgarantie")} :{" "}
            {project.feesdepotgarantie > 0
              ? project.feesdepotgarantie + " " + currency
              : t("modalprint.undisclosed")}{" "}
          </AlurText>
        ) : null}
        {project.nblotcopro > 0 ? (
          <AlurText>
            {t("modalprint.copropriete")} {project.nblotcopro}{" "}
            {t("modalprint.lots")}
          </AlurText>
        ) : null}
        {project.category === choices.category[0].id && (
          <AlurText>
            Charges annuelles de copropriété :{" "}
            {project.feescopro
              ? project.feescopro + currency + " / an"
              : t("modalprint.undisclosed")}
          </AlurText>
        )}
        {project.category === choices.category[0].id &&
          project.type !== choices.type[1].id &&
          project.type !== choices.type[2].id &&
          project.type !== choices.type[3].id && (
            <AlurText>
              {project.procsyndicale
                ? "Procédure en cours"
                : "Pas de procédure en cours"}
            </AlurText>
          )}
        <AlurText>
          Les informations sur les risques auxquels ce bien est exposé sont
          disponibles sur le site Géorisques : http://www.georisques.gouv.fr
        </AlurText>
      </AlurContainer>
      <IconsContainer>
        <IconContainer>
          <Icon src={surface} width="30px" height="33px" />
          <IconText>{project.area.total} m2</IconText>
        </IconContainer>
        {project.rooms && project.rooms > 0 ? (
          <IconContainer>
            <Icon src={rooms} width="30px" height="30px" />
            <IconText>{project.rooms}</IconText>
          </IconContainer>
        ) : null}
        {project.bedrooms && project.bedrooms > 0 ? (
          <IconContainer>
            <Icon src={bedrooms} width="33.17px" height="24px" />
            <IconText>{project.bedrooms}</IconText>
          </IconContainer>
        ) : null}
        {project.sdb && project.sdb > 0 ? (
          <IconContainer>
            <Icon src={sdb} width="33.17px" height="30px" />
            <IconText>{project.sdb}</IconText>
          </IconContainer>
        ) : null}
        {project.gardenArea && project.gardenArea !== "" ? (
          <IconContainer>
            <Icon src={garden} width="35.8px" height="30px" />
            <IconText>{project.gardenArea} m2</IconText>
          </IconContainer>
        ) : null}
      </IconsContainer>
      <PriceContainer>
        <Price
          colorPrice={colorPrice || "black"}
          size={priceFont.size !== "" ? `${priceFont.size}pt` : "25px"}
          weight={priceFont.weight || 600}
          family={priceFont.family || "'Montserrat', sans-serif !important"}
        >
          PRIX : {project.hideprice && t("modalprint.consult")}
          {!project.hideprice &&
            `${
              project.category === choices.category[2].id
                ? t("details.modalprint.startAt")
                : ""
            } ${getPrice(price)} ${
              project.currency ? project.currency : "€"
            }`}{" "}
          {project.category === choices.category[1].id && !project.hideprice
            ? " " + t("modalprint.chargespermonth")
            : ""}
          {project.periodicity &&
            project.category === choices.category[2].id &&
            " " +
              t(
                choices.periodicity.filter(
                  (item) => item.id === project.periodicity
                )[0].name
              )}
        </Price>
      </PriceContainer>
    </Container>
  );
});

export default IphLandscapeSecond;
