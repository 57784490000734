export const useGetAbo = () => {
  const getTheTypeAbo = (agency) => {
    const valid = ["waiting", "active"];
    if (agency) {
      if (
        agency.abo &&
        agency.abo.ads &&
        valid.includes(agency.abo.ads.state)
      ) {
        return "360";
      } else if (
        agency.abo &&
        agency.abo.ads &&
        !valid.includes(agency.abo.ads.state) &&
        agency.abo.print &&
        valid.includes(agency.abo.print.state)
      ) {
        return "print";
      } else {
        return "empty";
      }
    }
  };
  return { getTheTypeAbo };
};
