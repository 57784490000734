import React from "react";
import {
  FormContainer,
  FormContainerFilter,
  FormInner,
  Logo,
  LogoContainer,
  TitleMail,
} from "./Form.styles";
import { Fade } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const FormMail = (props) => {
  const { t } = useTranslation();
  return (
    <FormContainer image={props.project.images[0].src}>
      <FormContainerFilter />
      <Fade in={true} timeout={400}>
        <FormInner margin={props.margin}>
          <LogoContainer>
            <Logo
              src={
                "https://visibble.io/wp-content/uploads/2021/07/LOGO-VISIBBLE-1-1-1536x478.png"
              }
              alt="logo"
            />
          </LogoContainer>
          <TitleMail>
            {t("securepage.receiveemail")}{" "}
            {props.context !== "doc"
              ? t("securepage.atthevisit")
              : t("securepage.atthefiles")}
          </TitleMail>
        </FormInner>
      </Fade>
    </FormContainer>
  );
};

export default FormMail;
