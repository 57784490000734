import axios from "axios";
import { requestVmLive } from "./axiosRequests";
import imageReplace from "../../../../assets/img/fondAppartNoImage.png";

function capitalize(s) {
  let text = s.toLowerCase();
  const final = text[0].toUpperCase() + text.slice(1);
  return final;
}

export const manageState = async (token, reference, codeclient, setState) => {
  const responseVmLive = await requestVmLive(token, reference);
  if (responseVmLive && responseVmLive.data) {
    const data = responseVmLive.data;
    const imageExists = async (image_url) => {
      try {
        const imageServer = await axios.get(image_url);
        if (imageServer.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    };
    const images = data.files.map((item) => {
      const image = { src: item };
      item = image;
      return item;
    });
    const imageProject = await imageExists(data.files[0]);
    const imageAgency = await imageExists(data.customer.logo);
    let imageProjectToSend = imageProject ? images : [{ src: imageReplace }];
    let imageAgencyToSend = imageAgency ? data.customer.logo : null;

    setState({
      infoProject: {
        type: capitalize(data.itemType),
        category: data.transactionType === "SALE" ? "Vente" : "Location",
        price: data.price,
        reference: data.reference,
        images: imageProjectToSend,
        comments: [{ comments: { comment: data.description || "" } }],
        area: { total: data.surface },
        rooms: data.rooms,
        dpeLetter: data.dpe_alpha_value !== "" ? data.dpe_alpha_value : null,
        dpeValue: data.dpe_numeric_value !== "" ? data.dpe_numeric_value : null,
        gesLetter: data.ges_alpha_value ? data.ges_alpha_value : null,
        gesValue: data.ges_numeric_value ? data.ges_numeric_value : null,
      },
      agency: {
        images: [{ src: imageAgencyToSend }],
        name: data.customer.name,
        email: data.customer.email,
        phone: data.customer.phone,
      },
      project: false,
      is360: false,
      vmlive: true,
    });
  } else {
    setState({ noResult: true });
  }
};
