/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { config } from "../../constants/constant";

// Icons
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkIcon from "@material-ui/icons/Link";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
} from "react-share";

// CSS
import "./index.css";

const ModalShare = (props) => {
  const { infoProject } = props;
  const { t } = useTranslation();
  const link =
    config.APP_BASE +
    "/visite360/" +
    infoProject.agence.slug +
    "/" +
    infoProject.slug;
  const copyLink = () => {
    const el = document.createElement("textarea");
    el.value = link;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div className="fade modal show" id="modal-share">
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalGalleryLabel">
              {t("sidebar.modalshare.title")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body pt-4 px-4">
            {/* <h4 className="mb-3 text-center">
              {t("sidebar.modalshare.title")}
            </h4> */}
            <FacebookShareButton
              url={link}
              // quote={'Visibble - '}
              className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
            >
              <span className="mr-auto">Facebook</span>
              <span className="">
                <FacebookIcon fontSize="large" />
              </span>
            </FacebookShareButton>
            <div
              className="share-item d-flex align-items-center p-2 bg-light rounded mb-2"
              onClick={() => copyLink()}
            >
              <span className="mr-auto">
                {t("sidebar.modalshare.copylink")}
              </span>
              <span className="">
                <LinkIcon fontSize="large" />
              </span>
            </div>
            <WhatsappShareButton
              url={link}
              // title={'Visibble -'}
              // separator=':: '
              className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
            >
              <span className="mr-auto">WhatsApp</span>
              <span className="">
                <WhatsAppIcon fontSize="large" />
              </span>
            </WhatsappShareButton>

            <FacebookMessengerShareButton
              url={link}
              // quote={'Visibble - '}
              // appId="" // Facebook application id obligatoire
              className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
            >
              <span className="mr-auto">Messenger</span>
              <span className="">
                <FacebookMessengerIcon
                  round="true"
                  size={36}
                  bgStyle={{ fill: "#212529" }}
                  iconFillColor="white"
                />
              </span>
            </FacebookMessengerShareButton>
            <EmailShareButton
              url={link}
              subject={t("sidebar.modalshare.subjectmail")}
              body={t("sidebar.modalshare.bodymail")}
              className="share-item d-flex align-items-center p-2 bg-light rounded w-100 mb-2"
            >
              <span className="mr-auto">E-mail</span>
              <span className="">
                <MailOutlinedIcon fontSize="large" />
              </span>
            </EmailShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalShare.propTypes = {
  infoProject: PropTypes.object,
};

export default ModalShare;
