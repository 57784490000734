import React from "react";
import Loader from "../Loader/Loader";

function Wrapper(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        isLeadsExist: false,
        isAgencyExist: false,
        projects: 0,
        agencies: 0,
        users: 0,
      };
    }

    isLeads = async (data) => {
      const find = data.leads.filter((lead) => {
        if (lead.reference === this.props.project.reference) {
          return true;
        } else {
          return false;
        }
      });
      return find;
    };
    isAgency = async (data) => {
      const find = data.filter((item) => {
        if (item.agenceId === this.props.agency._id) {
          return true;
        } else {
          return false;
        }
      });
      return find;
    };

    refresh = async () => {
      this.setState({ loading: false });
    };

    async componentDidMount() {
      await this.refresh();
    }

    render() {
      if (this.state.loading) {
        return <Loader />;
      } else {
        return (
          <WrappedComponent
            {...this.state}
            isLeads={this.isLeads}
            isAgency={this.isAgency}
            {...this.props}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
}

export default Wrapper;
