import React, { useEffect, useState } from "react";
import Form from "./Form";
import FormCode from "./FormCode";
import FormMail from "./FormMail";

const SecureAccess = (props) => {
  const [view, setView] = useState("form");

  useEffect(() => {
    if (localStorage.getItem(props.project._id) === "code") {
      setView("code");
    }
  }, [props]);

  const changeView = (view) => {
    setView(view);
  };

  switch (view) {
    case "form":
      return (
        <Form
          project={props.project}
          agency={props.agency}
          changeView={changeView}
          margin={props.margin}
          context={props.context}
        />
      );
    case "code":
      return (
        <FormCode
          project={props.project}
          secureUnlock={props.secureUnlock}
          margin={props.margin}
          context={props.context}
          setModal={props.context === "code" ? null : props.setModal}
          setActivated={props.context === "code" ? null : props.setActivated}
        />
      );
    case "formMail":
      return (
        <FormMail
          project={props.project}
          margin={props.margin}
          context={props.context}
        />
      );
    default:
      return null;
  }
};

export default SecureAccess;
