import React from "react";
import { TextField } from "@material-ui/core";
import {
  ModuleAdressContainer,
  FormGroup,
} from "../../../../../../Utils/Styles/BillingInformation.styles";
import { useTranslate } from "ra-core";
import { CssTextField } from "../../../../../../Utils/Styles/input.mui.styles";

const ModuleAdress = (props) => {
  const translate = useTranslate();
  const classesInput = CssTextField();

  return (
    <ModuleAdressContainer className={classesInput.root}>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="name"
          label={translate("resources.agencies.fields.name")}
          onChange={props.handleChange}
          value={props.stateAddress.name}
        />
      </FormGroup>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="address"
          label={translate("resources.agencies.fields.address")}
          onChange={props.handleChange}
          value={props.stateAddress.address}
        />
      </FormGroup>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="city"
          label={translate("resources.agencies.fields.city")}
          onChange={props.handleChange}
          value={props.stateAddress.city}
        />
      </FormGroup>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="postalcode"
          label={translate("resources.agencies.fields.postalcode")}
          onChange={props.handleChange}
          value={props.stateAddress.postalcode}
        />
      </FormGroup>
      <FormGroup className={classesInput.formControl}>
        <TextField
          fullWidth
          type="text"
          id="country"
          label={translate("resources.agencies.fields.country")}
          onChange={props.handleChange}
          value={props.stateAddress.country}
        />
      </FormGroup>
    </ModuleAdressContainer>
  );
};

export default ModuleAdress;
