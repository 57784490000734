import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "react-alert";

// CSS
import Wrapper from "./Wrapper";
import {
  getContactExist,
  sendNewRequestContact,
  sendRequestContact,
} from "./requests";
import {
  Button,
  Container,
  Input,
  InputContainer,
  TitleForm,
} from "./ContactForm.styles";

function ContactForm(props) {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const { project, agency, isLeads, isAgency } = props;
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonemobile: "",
    rdv: false,
  });
  useEffect(() => {}, [project, state]);

  const handleChange = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const submit = async () => {
    if (
      state.email.length &&
      state.lastname.length &&
      state.firstname.length &&
      state.phonemobile.length
    ) {
      const dataFetch = await getContactExist(state.email);

      if (dataFetch.total > 0) {
        const agencyExist = await isAgency(dataFetch.data);
        if (agencyExist.length) {
          const leadsExist = await isLeads(agencyExist[0]);
          if (leadsExist.length) {
            alert.show(`${t("alert.contact-alreadysent")}`);
            props.setSend();
          } else {
            const sendContactForm = await sendRequestContact(
              agencyExist[0],
              project,
              agency,
              state,
              i18n.language
            );
            if (
              sendContactForm.status === 200 ||
              sendContactForm.status === 201
            ) {
              alert.show(`${t("alert.contact-sent")}`);
              localStorage.setItem(
                "contactMobile",
                JSON.stringify({
                  agence: props.match.params.codeclient,
                  reference: props.match.params.reference,
                })
              );
              props.setSend();
              setState({
                firstname: "",
                lastname: "",
                email: "",
                phonemobile: "",
                rdv: false,
              });
            } else {
              alert.error(`${t("alert.error")}`);
            }
          }
        } else {
          const sendContactForm = await sendNewRequestContact(
            project,
            agency,
            state,
            i18n.language
          );
          if (
            sendContactForm.status === 200 ||
            sendContactForm.status === 201
          ) {
            alert.show(`${t("alert.contact-sent")}`);
            localStorage.setItem(
              "contactMobile",
              JSON.stringify({
                agence: props.match.params.codeclient,
                reference: props.match.params.reference,
              })
            );
            props.setSend();
            setState({
              firstname: "",
              lastname: "",
              email: "",
              phonemobile: "",
              rdv: false,
            });
          } else {
            alert.error(`${t("alert.error")}`);
          }
        }
      } else {
        const sendContactForm = await sendNewRequestContact(
          project,
          agency,
          state,
          i18n.language
        );
        if (sendContactForm.status === 200 || sendContactForm.status === 201) {
          alert.show(`${t("alert.contact-sent")}`);
          localStorage.setItem(
            "contactMobile",
            JSON.stringify({
              agence: props.match.params.codeclient,
              reference: props.match.params.reference,
            })
          );
          props.setSend();
          setState({
            firstname: "",
            lastname: "",
            email: "",
            phonemobile: "",
            rdv: false,
          });
        } else {
          alert.error(`${t("alert.error")}`);
        }
      }
    } else {
      alert.error(`${t("alert.fields-required")}`);
    }
  };
  return (
    <Container>
      <TitleForm>{t("contact.form.title").toUpperCase()}</TitleForm>
      <hr />
      <InputContainer>
        <Input
          type="text"
          name="lastname"
          placeholder={t("contact.form.lastname")}
          onChange={handleChange}
          value={state.lastname}
        />
        <Input
          type="text"
          name="firstname"
          placeholder={t("contact.form.firstname")}
          onChange={handleChange}
          value={state.firstname}
        />
        <Input
          type="email"
          name="email"
          placeholder={t("contact.form.email")}
          onChange={handleChange}
          value={state.email}
        />
        <Input
          type="phone"
          name="phonemobile"
          placeholder={t("contact.form.phonemobile")}
          value={state.phonemobile}
          onChange={handleChange}
        />
        <hr />
        <Button onClick={submit}>{t("contact.form.send").toUpperCase()}</Button>
      </InputContainer>
    </Container>
  );
}
export default Wrapper(ContactForm);
