/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import {
  Filter,
  FilterList,
  FilterListItem,
  SearchInput,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import { getAgencyData } from "./axiosRequest";
import {
  SelectContainer,
  FilterListContainer,
  FilterListContainerAgency,
  SelectContainerAgency,
} from "./Styles/User.styles";

import { Card } from "./Styles/User.mui.styles";

const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="firstname-lastname-email_$like" alwaysOn />
  </Filter>
);

const HisVerifiedFilter = () => {
  const translate = useTranslate();
  const [click, setClick] = useState(false);

  return (
    <div onClick={() => setClick(!click)} style={{ cursor: "pointer" }}>
      <FilterList label={translate("resources.users.filter.emailverified")}>
        <SelectContainer click={click}>
          <FilterListItem
            label={translate("resources.users.filter.yes")}
            value={{ isVerified_$in: true }}
          />
          <FilterListItem
            label={translate("resources.users.filter.no")}
            value={{ isVerified_$in: false }}
          />
        </SelectContainer>
      </FilterList>
    </div>
  );
};
const HisActivatedFilter = () => {
  const translate = useTranslate();
  const [click, setClick] = useState(false);

  return (
    // <FilterListContainer onClick={() => setClick(!click)}>
    <div onClick={() => setClick(!click)} style={{ cursor: "pointer" }}>
      <FilterList label={translate("resources.users.filter.accountactivated")}>
        <SelectContainer click={click}>
          {" "}
          {/* <div style={{ display: click ? "block" : "none" }}> */}
          <FilterListItem
            label={translate("resources.users.filter.yes")}
            value={{ isActivated_$in: true }}
          />
          <FilterListItem
            label={translate("resources.users.filter.no")}
            value={{ isActivated_$in: false }}
          />
          {/* </div> */}
        </SelectContainer>
      </FilterList>
    </div>
  );
};

const AgenceList = () => {
  const translate = useTranslate();
  const [dataFetch, setDataFetch] = useState([]);
  const [click, setClick] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      const data = await getAgencyData();
      if (data.data.length > 0) {
        setDataFetch(data.data);
      }
    };
    if (isMounted) {
      getData();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    // <FilterListContainerAgency onClick={() => setClick(!click)}>
    <div onClick={() => setClick(!click)} style={{ cursor: "pointer" }}>
      <FilterList label={translate("resources.agencies.tabname")}>
        <SelectContainerAgency click={click}>
          {dataFetch
            .sort(function (a, b) {
              return a.name.localeCompare(b.name);
            })
            .map((cat) => (
              <FilterListItem
                label={cat.name}
                key={cat._id}
                value={{ agenceId_$in: cat._id }}
              />
            ))}
        </SelectContainerAgency>
      </FilterList>
    </div>
    // </FilterListContainerAgency>
  );
};

const FilterSidebar = () => {
  const { identity, loading: identityLoading } = useGetIdentity();
  if (identityLoading) return <>Loading...</>;
  let role = ["super_admin", "admin"];

  return (
    <Card>
      <CardContent>
        <SearchFilter />
        <HisVerifiedFilter />
        <HisActivatedFilter />
        {role.includes(identity.permissions) && <AgenceList />}
      </CardContent>
    </Card>
  );
};

export default FilterSidebar;
