import { useContext } from "react";
import { CombineContext } from "../store/UserProvider";

export const useGetAbo = () => {
  const abo = useContext(CombineContext);

  const getTheTypeAbo = (agency, prev) => {
    const valid = ["waiting", "active"];
    if (agency) {
      if (
        agency.abo &&
        agency.abo.ads &&
        valid.includes(agency.abo.ads.state)
      ) {
        if (prev.ads && valid.includes(prev.ads.state)) {
          return "close";
        } else {
          abo.typeValue.setType("360");
        }
      } else if (
        agency.abo &&
        agency.abo.ads &&
        !valid.includes(agency.abo.ads.state) &&
        agency.abo.print &&
        valid.includes(agency.abo.print.state)
      ) {
        if (prev.print && valid.includes(prev.print.state)) {
          return "close";
        } else {
          abo.typeValue.setType("print");
        }
      } else {
        abo.typeValue.setType("empty");
      }
    }
  };
  return { getTheTypeAbo };
};
