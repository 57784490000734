import styled from "styled-components";

export const ContainerStepTwo = styled.div`
  width: 100%;
`;
export const RowStepTwo = styled.div`
  width: 100%;
  display: flex;
  overscroll-behavior-x: none;
  overflow-x: scroll;
  min-height: 40vh;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};

  &::-webkit-scrollbar {
    width: 10px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #2fbcee;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b3afb3;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }
`;

export const TileDetailsStepTwo = styled.div`
  margin-top: 5px;
  font-size: 10px;
  background-color: #183848;
  transition: 450ms opacity;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const TileStepTwo = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${(props) => props.marginRight};
  font-size: 20px;
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
  border-radius: 10px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};

  &:hover ${TileDetailsStepTwo} {
    opacity: 1;
  }

  &:hover ~ & {
    transform: translate3d(75px, 0, 0);
  }
`;

export const RowInnerStepTwo = styled.div`
  transition: 450ms transform;
  font-size: 0;
  white-space: nowrap;
  margin: 55px 0;
  padding-bottom: 10px;
  padding-left: 15px;

  &:hover {
    transform: translate3d(-10px, 0, 0);
  }

  &:hover ${TileStepTwo} {
    opacity: 0.3;
  }

  &:hover ${TileStepTwo}:hover {
    transform: scale(1.2);
    opacity: 1;
  }
`;

export const NotAvailableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffc2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotAvailableText = styled.span`
  color: #183848;
`;

export const TileMediaStepTwo = styled.div``;

export const MediaStepTwo = styled.img`
  -o-object-fit: cover;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: ${(props) => props.width};
  border: ${(props) => props.border};
`;

export const TileTitleStepTwo = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 900;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
`;
