/* eslint-disable no-unused-vars */
import React, { createRef } from "react";
import {
  GlobalContainer,
  InformationsContainer,
  InnerContainer,
  ObjectContainer,
  Text,
  Signature,
  SignatureContainer,
  LogoContainer,
  Logo,
} from "../../utils/Styles/PdfPreview.styles";
import CustomerDetails from "./PdfElements/CustomerDetails";
import { TextCertif, Button } from "../../utils/Styles/PdfPreview.styles";
import logo from "../../../../../assets/360-logo.png";
import { useTranslation } from "react-i18next";
import { usePrint } from "../../../../../hooks/usePrint";

const PdfPreview = (props) => {
  const ref = createRef();
  const { t } = useTranslation();
  const printPdf = usePrint();
  const isConditions = () => {
    if (
      props.informations.conditions &&
      props.informations.conditions[1].length
    ) {
      return true;
    } else {
      return false;
    }
  };
  const date = (props, isOptions) => {
    const event = new Date(props);

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return event.toLocaleDateString("fr-FR", isOptions ? options : {});
  };
  const dateToday = (date) => {
    const event = new Date(date);

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const todayDate = event.toLocaleDateString("fr-FR", options);

    return todayDate;
  };

  return (
    <>
      <GlobalContainer ref={ref} id="ici">
        <InformationsContainer>
          <InnerContainer padding={"6mm 10mm"} width={"55%"} margin={0}>
            <Text weight={true}>
              {props.customer.firstname} {props.customer.lastname}
            </Text>
            <Text weight={true}>{props.customer.address}</Text>
            <Text weight={true}>
              {props.customer.postalcode} {props.customer.city}
            </Text>
            <Text weight={true}>{props.customer.phonemobile}</Text>
            <Text weight={true}>{props.customer.email}</Text>
          </InnerContainer>
          <InnerContainer padding={"6mm 0 0 25mm"} width={"45%"}>
            <Text weight={true}>{props.agency.name}</Text>
            <Text weight={true}>{props.agency.address}</Text>
            <Text weight={true}>
              {props.agency.postalcode} {props.agency.city}
            </Text>
          </InnerContainer>
        </InformationsContainer>
        <ObjectContainer>
          <InnerContainer
            flex={true}
            padding={"0 0 0 0"}
            width={"100%"}
            margin={0}
          >
            <InnerContainer padding={"0 0 0 55%"} width={"unset"} margin={0}>
              <Text weight={false} align={false}>
                le {dateToday(props.row.offer.date)} à {props.customer.city}
              </Text>
            </InnerContainer>
          </InnerContainer>
          <Text weight={true} style={{ marginTop: "2em" }}>
            Objet : offre d’achat au prix de votre bien immobilier de{" "}
            {props.infoProject.city}
          </Text>
        </ObjectContainer>
        <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>Madame, Monsieur,</Text>
        </InnerContainer>
        <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            Suite à la visite de votre bien immobilier situé au{" "}
            {props.informations.addressOffer} en date du{" "}
            {date(props.informations.visiteDate, true)}, nous souhaiterions vous
            faire une proposition d’achat.
          </Text>
        </InnerContainer>
        <InnerContainer padding={"1.5mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>Le(s) soussigné(s)</Text>
          <ul>
            <CustomerDetails state={props.customer} date={date} />
            {props.secondCustomer && props.secondCustomer.firstname !== "" && (
              <CustomerDetails state={props.secondCustomer} date={date} />
            )}
          </ul>
        </InnerContainer>
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            s’engage(nt) à acquérir le bien immobilier suivant :
          </Text>
          <ul>
            <li>{props.infoProject.type}</li>
            <li>{props.informations.addressOffer}</li>
            <li>{props.infoProject.area.total} m²</li>
            <li>{props.infoProject.rooms} pièce(s)</li>
          </ul>
        </InnerContainer>
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            Notre [mon] offre d’achat s’élève au prix de{" "}
            {props.informations.offerLetter}. Conformément à l’article 1583 du
            Code civil, le vendeur et l’acheteur sont réciproquement engagés
            lorsqu’il y a un accord entre les deux parties sur la chose et le
            prix. Notre [mon] offre étant équivalente au prix que vous demandez,
            celle-ci est ferme et irrévocable.
          </Text>
        </InnerContainer>
        {isConditions() && (
          <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
            <Text weight={false}>
              Toutefois, cette offre est soumise aux conditions suspensives
              suivantes :
            </Text>
            <ul>
              <li>{t(props.informations.conditions[1])}</li>
              {props.informations.conditions[2] &&
              props.informations.conditions[2].length ? (
                <li>{t(props.informations.conditions[2])}</li>
              ) : null}
              {props.informations.conditions[3] &&
              props.informations.conditions[3].length ? (
                <li>{t(props.informations.conditions[3])}</li>
              ) : null}
              {props.informations.conditions[4] &&
              props.informations.conditions[4].length ? (
                <li>{t(props.informations.conditions[4])}</li>
              ) : null}
            </ul>
          </InnerContainer>
        )}
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            Cette offre est valable à compter de la signature du présent
            document, et, pour un délai de {props.informations.offerTime}.
          </Text>
        </InnerContainer>
        {props.informations.financialType !== null && (
          <InnerContainer padding={"3mm 10mm 0"} width={"100%"} margin={0}>
            <Text weight={false}>
              Nous vous informons que l’achat de votre bien immobilier sera
              financé selon les modalités suivantes :
            </Text>
            <ul>
              <li>{t(props.informations.financialType)}</li>
            </ul>
          </InnerContainer>
        )}
        <InnerContainer padding={"2mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            En l’absence d’une réponse de votre part dans les délais impartis,
            notre proposition d’achat sera caduque.
          </Text>
        </InnerContainer>
        <InnerContainer padding={"1mm 10mm 0"} width={"100%"} margin={0}>
          <Text weight={false}>
            Dans l’attente de votre retour, nous vous prions d’agréer, Madame,
            Monsieur, l’expression de notre profond respect.
          </Text>
        </InnerContainer>
        <SignatureContainer width={"100%"}>
          <InnerContainer padding={"5mm 10mm 0"} width={"auto"} margin={0}>
            <Text weight={false}>
              {props.customer.lastname} {props.customer.firstname}
            </Text>
            {props.codeVerify === true && (
              <Signature weight={false}>
                {props.customer.lastname} {props.customer.firstname}
              </Signature>
            )}
          </InnerContainer>
          {props.secondCustomer && props.secondCustomer.lastname !== "" && (
            <InnerContainer padding={"5mm 10mm 0"} width={"auto"} margin={0}>
              <Text weight={false}>
                {props.secondCustomer.lastname} {props.secondCustomer.firstname}
              </Text>
              {props.codeVerify === true && (
                <Signature weight={false}>
                  {props.secondCustomer.lastname}{" "}
                  {props.secondCustomer.firstname}
                </Signature>
              )}
            </InnerContainer>
          )}
        </SignatureContainer>
        <LogoContainer>
          <Logo src={logo} alt="logo" />
          <TextCertif weight={false}>
            Certifié par Visibble 360 le {date(props.row.offer.date)} à{" "}
            {props.row.offer.dateCertif}
          </TextCertif>
        </LogoContainer>
      </GlobalContainer>
      <Button
        onClick={() => printPdf("Proposition d'achat", "ici")}
        typeOfButton={true}
      >
        TÉLÉCHARGER
      </Button>
    </>
  );
};

export default PdfPreview;
