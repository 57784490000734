const RenderContext = (props) => {
  if (props.project.category === "Locationsaisonnière") {
    return null;
  } else if (props.project.type === "Terrain") {
    return null;
  } else {
    return props.children;
  }
};

export default RenderContext;
