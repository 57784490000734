import styled from "styled-components";

export const RangeSelectorContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RangeItem = styled.div`
  padding: 8px 10px;
  border-radius: 4px;
  background-color: ${(props) => (props.active ? "#5463fa" : "#f0f0f0")};
  color: ${(props) => (props.active ? "white" : "black")};
  margin-bottom: 10px;
  cursor: pointer;
`;
