import React from "react";

const Chambres = ({ color, width, height }) => {
  return (
    <svg
      id="Groupe_5"
      data-name="Groupe 5"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 37.804 27.71"
    >
      <g
        id="icons_bedroom"
        data-name="icons bedroom"
        transform="translate(0 0)"
      >
        <path
          id="Tracé_15"
          data-name="Tracé 15"
          d="M1476.654,259.365h-1.512v-9.141h-27.763v9.141h-1.512V248.712h30.787Z"
          transform="translate(-1442.275 -248.712)"
          fill={color}
        />
        <path
          id="Tracé_16"
          data-name="Tracé 16"
          d="M1462.023,260.108h-1.512v-3.251h-6.048v3.251h-1.512v-4.763h9.073Z"
          transform="translate(-1443.877 -250.212)"
          fill={color}
        />
        <path
          id="Tracé_17"
          data-name="Tracé 17"
          d="M1475.7,260.108h-1.511v-3.251h-6.049v3.251h-1.512v-4.763h9.073Z"
          transform="translate(-1446.969 -250.212)"
          fill={color}
        />
        <path
          id="Rectangle_4"
          data-name="Rectangle 4"
          d="M1441.226,268.29h37.8V276.9h-37.8Zm36.292,1.512h-34.78v5.588h34.78Z"
          transform="translate(-1441.226 -253.138)"
          fill={color}
        />
        <path
          id="Rectangle_5"
          data-name="Rectangle 5"
          d="M1441.226,262.135h37.8V275.4h-37.8Zm36.292,1.512h-34.78v10.245h34.78Z"
          transform="translate(-1441.226 -251.747)"
          fill={color}
        />
        <path
          id="Ligne_4"
          data-name="Ligne 4"
          d="M1442.738,282.693h-1.512v-6.245h1.512Z"
          transform="translate(-1441.226 -254.983)"
          fill={color}
        />
        <path
          id="Ligne_5"
          data-name="Ligne 5"
          d="M1489.632,282.693h-1.512v-6.245h1.512Z"
          transform="translate(-1451.828 -254.983)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Chambres;
