import React, { useRef, useState } from "react";
import PanoController from "./components/Pano/PanoController";
import {
  PanoControllerContainer,
  ToolsContainer,
} from "./components/Home/Tools.styles";

import { useTranslation } from "react-i18next";
import useLoading from "./hooks/useLoading";

const New360 = (props) => {
  const { t, i18n } = useTranslation();
  const outRef = useRef();
  const [scenes, setScenes] = useState(null);
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);
  const {
    loadingTransition,
    loadingTexture,
    setLoadingTransition,
  } = useLoading(props, setScenes, setCount);

  const setCurrentLink = (index) => {
    const newScene = props.scenes.map((item, idx) => {
      if (item.id === index) {
        item.current = true;
        setIndex(idx);
        return item;
      } else {
        item.current = false;
        return item;
      }
    });
    setScenes(newScene);
    setLoadingTransition(false);
  };

  if (scenes === null || !scenes.length) {
    return <div />;
  } else {
    return (
      <>
        <ToolsContainer
          isMobile={props.isMobile}
          loading={loadingTexture}
          ref={outRef}
        >
          <PanoControllerContainer>
            <PanoController
              scenes={scenes}
              setCurrentLink={setCurrentLink}
              loadingTransition={loadingTransition}
              setLoadingTransition={setLoadingTransition}
              loadingTexture={loadingTexture}
              translate={t}
              language={i18n.language}
              index={index}
              count={count}
              setCount={setCount}
              autorotate={props.autorotate}
              noMenu={props.noMenu}
            />
          </PanoControllerContainer>
        </ToolsContainer>
      </>
    );
  }
};

export default New360;
