import React from "react";
import Informations from "../Components/Informations";

const StepTwo = (props) => {
  return (
    <>
      <Informations
        handleChange={props.handleChange}
        context={"informations"}
        state={props.state.informations}
        setState={props.setState}
      />
    </>
  );
};

export default StepTwo;
