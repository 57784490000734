import React from "react";

const Cars = () => {
  return (
    <svg width="53.592" height="45" viewBox="0 0 53.592 45">
      <path
        id="Car"
        d="M37.5,23.429H16.077A1.071,1.071,0,0,0,15.005,24.5v6.428A1.071,1.071,0,0,0,16.077,32H37.5a1.071,1.071,0,0,0,1.071-1.071V24.5A1.071,1.071,0,0,0,37.5,23.429Zm-1.071,6.428H17.148V25.571H36.432Zm17.142-7.049a6.428,6.428,0,0,0-4.543-5.421L44.746,4.959A4.285,4.285,0,0,0,40.5,2H14.148a4.285,4.285,0,0,0-4.114,2.914L5.749,17.108a6.557,6.557,0,0,0-5.742,5.7,1.093,1.093,0,0,0,0,.321V34.142a4.285,4.285,0,0,0,2.143,3.707v5.914A3.235,3.235,0,0,0,5.385,47h6.428a3.214,3.214,0,0,0,3.193-3.235V38.428h23.57v5.357A3.214,3.214,0,0,0,41.789,47h6.428a3.214,3.214,0,0,0,3.214-3.214V37.849a4.285,4.285,0,0,0,2.143-3.707V23.129A1.05,1.05,0,0,0,53.574,22.807ZM12.134,5.666a2.143,2.143,0,0,1,2.014-1.521H40.5a2.143,2.143,0,0,1,2.143,1.478L46.567,17H8Zm.729,38.1a1.071,1.071,0,0,1-1.05,1.093H5.385a1.114,1.114,0,0,1-1.093-1.093V38.428h8.571Zm36.426,0a1.071,1.071,0,0,1-1.071,1.071H41.789a1.071,1.071,0,0,1-1.071-1.071V38.428h8.571Zm2.143-9.621a2.143,2.143,0,0,1-2.143,2.143h-45a2.143,2.143,0,0,1-2.143-2.143V23.664a4.65,4.65,0,0,1,5.035-4.521H46.4a4.65,4.65,0,0,1,5.035,4.521ZM8.577,23.429a4.285,4.285,0,1,0,4.285,4.285A4.285,4.285,0,0,0,8.577,23.429Zm0,6.428a2.143,2.143,0,1,1,2.143-2.143A2.143,2.143,0,0,1,8.577,29.857ZM45,23.429a4.285,4.285,0,1,0,4.285,4.285A4.285,4.285,0,0,0,45,23.429Zm0,6.428a2.143,2.143,0,1,1,2.143-2.143A2.143,2.143,0,0,1,45,29.857Z"
        transform="translate(0.006 -1.998)"
        fill="#fff"
      />
    </svg>
  );
};

export default Cars;
