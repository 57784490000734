import styled from "styled-components";

export const InputContainer = styled.div`
  background-color: white;
  box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 20px 10px;
  min-width: 240px;
  height: 57px;
  position: relative;
  margin-right: 10px;
`;
export const Label = styled.p`
  font-size: 0.6rem;
  color: grey;
  margin-bottom: 3px;
`;
export const Span = styled.span`
  font-size: 0.8rem;
`;
export const DateValueContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;
export const DateRangeContainer = styled.div`
  width: ${(props) => (props.open ? "333px" : "0")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  transition: all 0.3s ease-in-out;
`;
