import React from "react";

const QrcodeContainer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="161.759"
      height="202.119"
      viewBox="0 0 161.759 202.119"
      style={{
        position: "absolute",
        right: "100pt",
        zIndex: "10",
        top: "15pt",
      }}
    >
      <defs>
        <filter
          id="bookmark"
          x="0"
          y="0"
          width="161.759"
          height="202.119"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#bookmark)">
        <path
          id="bookmark-2"
          data-name="bookmark"
          d="M130.734,0a11.59,11.59,0,0,1,4.941,1.1,12.882,12.882,0,0,1,5.894,4.99,13.661,13.661,0,0,1,2.19,7.544v156.86a13.661,13.661,0,0,1-2.19,7.544,12.882,12.882,0,0,1-5.894,4.99,11.761,11.761,0,0,1-4.941.976,12.793,12.793,0,0,1-9.321-3.894l-49.531-51.6-49.531,51.6a12.854,12.854,0,0,1-9.321,4.014,11.59,11.59,0,0,1-4.941-1.1,12.882,12.882,0,0,1-5.894-4.99A13.691,13.691,0,0,1,0,170.489V13.629A13.661,13.661,0,0,1,2.19,6.085a12.877,12.877,0,0,1,5.9-4.99A11.59,11.59,0,0,1,13.03,0H130.738Z"
          transform="translate(9 6)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default QrcodeContainer;
