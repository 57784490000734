import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CombineContext } from "../../../../store/UserProvider";
import { getAudienceFromApi } from "../Utils/axiosRequests";

const useGetData = () => {
  const [data, setData] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useContext(CombineContext);

  useEffect(() => {
    const getData = async () => {
      let userSelect;
      if (user.valueChange.userChange) {
        userSelect = user.valueChange.userChange;
      } else {
        userSelect = user.value.user;
      }
      const cameras = user.agencyValue.agency.cameras;
      if (userSelect && userSelect.agenceId && cameras) {
        const response = await getAudienceFromApi(
          "agenceId=" + userSelect.agenceId
        );
        setData(response);
        setCameras(cameras);
      }
      setLoading(false);
    };
    getData();
  }, [user]);

  return { data, cameras, loading };
};

export default useGetData;
