import styled from "styled-components";

export const Container = styled.div`
  && {
    height: 296.5mm;
    width: 420mm;
    background-color: #ffffff;
    position: relative;
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const BackgroundContainer = styled.div`
  display: flex;
  width: 1190pt;
  height: 436pt;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;

export const ExcluContainer = styled.div`
  width: 181.17pt;
  height: 47.82pt;
  background-color: ${(props) => props.colorPrimary};
  position: absolute;
  right: 0;
  top: 20pt;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "24pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const MiddleContainer = styled.div`
  width: 1190pt;
  height: 87.17pt;
  background-color: #f0f0f0;
  display: flex;
`;

export const LogoContainer = styled.div`
  width: 167pt;
  margin-left: 20pt;
`;
export const LogoAgence = styled.img`
  height: 87.17pt;
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 735pt;
  padding-left: 70pt;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorDesc};
  margin-top: 10px;
  margin-bottom: 0;
`;

export const PriceContainer = styled.div`
  width: 260.06pt;
  height: 100%;
  background-color: ${(props) => props.colorPrimary};
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
`;

export const BottomContainer = styled.div`
  width: 1190pt;
  height: 313.81pt;
  display: flex;
  overflow: hidden;
`;

export const BottomContainerLeft = styled.div`
  width: 60%;
  height: 100%;
  padding-left: 30pt;
`;

export const InfoType = styled.p`
  color: ${(props) => props.colorTitle};
  margin-top: 7px;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
export const InfoCity = styled.p`
  color: ${(props) => props.colorSubTitle};
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;

export const DescContainer = styled.div`
  padding: 0 0 20px;
  max-height: 128px;
  overflow: hidden;
`;

export const DescText = styled.p`
  color: ${(props) => props.colorDesc};
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 115%;
  text-align: left;
  white-space: pre-wrap;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const LastBottomContainer = styled.div`
  display: flex;
`;

export const QrcodeContainer = styled.div`
  width: 135pt;
  height: 135pt;
  background-color: white;
  margin: auto 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10pt;
  margin-right: 15px;
`;

export const AlurContainer = styled.div`
  padding: 20px 0 0;
  min-height: 120px;
  max-width: 600px;
`;

export const InfoRef = styled.p`
  margin-left: 20px;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
  color: ${(props) => (props.color !== "" ? props.color : "black")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const BottomContainerRight = styled.div`
  position: relative;
  width: 40%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;
