/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ModalManager } from "../../../../../../Utils/Styles/ModalManager.mui.styles";
import { Dialog, DialogContent, Divider } from "@material-ui/core";
import {
  BillingManagerContainer,
  TitleContainer,
  Title,
  Container,
  ItemContainer,
  Label,
  ButtonContainer,
  ActionBtn,
} from "../../../../../../Utils/Styles/BillingInformation.styles";
import ActionContainer from "./ActionContainer";
import ModuleAdress from "./ModuleAdress";
import ApproveButton from "./ApprouveBtn";
import { useTranslate } from "react-admin";

const BillingAdressManager = (props) => {
  const translate = useTranslate();
  const [confirmAction, setConfirmAction] = useState(false);
  const classes = ModalManager();
  const [stateAddress, setStateAddress] = useState({
    name: "",
    address: "",
    city: "",
    postalcode: "",
    country: "",
  });

  useEffect(() => {
    if (
      props.record &&
      props.record.billingInformation &&
      props.record.billingInformation.billingAddress
    ) {
      setStateAddress({
        name: props.record.billingInformation.billingAddress.name,
        address: props.record.billingInformation.billingAddress.address,
        city: props.record.billingInformation.billingAddress.city,
        postalcode: props.record.billingInformation.billingAddress.postalcode,
        country: props.record.billingInformation.billingAddress.country,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAddress = () => {
    setConfirmAction(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setStateAddress((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleClose = () => {
    props.setModal(false);
  };

  return (
    <Dialog open={true} className={classes.root}>
      {!confirmAction ? (
        <DialogContent>
          <BillingManagerContainer>
            <TitleContainer>
              <Title>
                {translate(
                  "resources.fields.manageAddressBilling"
                ).toUpperCase()}
              </Title>
            </TitleContainer>
          </BillingManagerContainer>
          <Divider variant="middle" light style={{ marginBottom: "10px" }} />
          <ModuleAdress
            handleChange={handleChange}
            stateAddress={stateAddress}
          />

          <ActionContainer
            handleClose={handleClose}
            saveAddress={saveAddress}
            aboValue={props.aboValue}
            stateAddress={stateAddress}

            // name={props.name}
          />
        </DialogContent>
      ) : (
        <Container>
          <ItemContainer width="true">
            <Label size="true" align="true">
              {translate("resources.alert.confirmsave")}
            </Label>
            <ButtonContainer center="true">
              <ActionBtn right="true" onClick={() => setConfirmAction(false)}>
                {translate("resources.fields.cancel")}
              </ActionBtn>

              <ApproveButton
                record={props.record}
                setModal={props.setModal}
                aboValue={props.aboValue}
                state={stateAddress}
                translate={translate}
                showMessage={props.showMessage}
                confirmAction={confirmAction}
              />
            </ButtonContainer>
          </ItemContainer>
        </Container>
      )}
    </Dialog>
  );
};

export default BillingAdressManager;
