import { config } from "../../../../constants/constant";
import axios from "axios";

export const fetchAgencyData = async (query) => {
  const url = config.API_BASE + "/agencies/" + query;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const fetchProjectData = async (query) => {
  const url = config.API_BASE + "/projects/" + query;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const registerTemplate = async (project, data) => {
  const url = config.API_BASE + "/projects/" + project._id;
  const prevTemplates = project.templates ? project.templates : [];
  const params = {
    method: "PATCH",
    url,
    data: {
      templates: [
        {
          ...data,
        },
        ...prevTemplates,
      ],
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const udpateTemplateRequest = async (project, data) => {
  const url = config.API_BASE + "/projects/" + project._id;
  const prevTemplates = project.templates.filter(
    (item) => item.index !== data.index
  );
  const params = {
    method: "PATCH",
    url,
    data: {
      templates: [
        {
          ...data,
        },
        ...prevTemplates,
      ],
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const deleteTemplateRequest = async (project, data) => {
  const url = config.API_BASE + "/projects/" + project._id;
  const newTemplates = project.templates.filter(
    (item) => item.index !== data.index
  );
  const params = {
    method: "PATCH",
    url,
    data: {
      templates: [...newTemplates],
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
