/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Conditions from "./Conditions";
import {
  FormGroup,
  IconContainer,
  Input,
  MoreIcon,
  SectionContainer,
  SpanText,
  TitleSection,
} from "../Utils/Offer.styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { dateOptions } from "./selectFeatures";
import FinancialType from "./FinancialType";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { CssTextField } from "../Utils/input.mui.styles";
import { colorPrimary } from "../../../GlobalStyles";

const Informations = (props) => {
  const classes = CssTextField();
  const { t } = useTranslation();
  const [conditionsElement, setConditionsElement] = useState(["div"]);

  useEffect(() => {
    if (props.state.conditions[2].length && conditionsElement.length === 1) {
      setConditionsElement((prevState) => [...prevState, "div"]);
    }
    if (props.state.conditions[3].length && conditionsElement.length === 2) {
      setConditionsElement((prevState) => [...prevState, "div"]);
    }
    if (props.state.conditions[4].length && conditionsElement.length === 3) {
      setConditionsElement((prevState) => [...prevState, "div"]);
    }
  }, [props.state.conditions, conditionsElement]);

  const onChange = (e) => {
    const event = {
      target: {
        value: e.target.value,
        id: "informations",
        name: "offerTime",
      },
    };
    props.handleChange(event);
  };

  const addConditions = () => {
    setConditionsElement((prevState) => [...prevState, "div"]);
  };

  const removeConditions = () => {
    if (conditionsElement.length > 1) {
      let array = [...conditionsElement];
      array.splice(conditionsElement.length - 1, 1);
      setConditionsElement([...array]);
      props.setState((prevState) => ({
        ...prevState,
        informations: {
          ...prevState.informations,
          conditions: {
            ...prevState.informations.conditions,
            [conditionsElement.length]: "",
          },
        },
      }));
    }
  };

  return (
    <div className={classes.root}>
      <SectionContainer>
        <TitleSection>{t("offer.informations")}</TitleSection>
        <FormGroup small={true}>
          <TextField
            id={props.context}
            height={"calc(1.5em + 0.75rem + 2px)"}
            type={"text"}
            name="addressOffer"
            fullWidth
            label={t("contact.form.addressOffer")}
            onChange={props.handleChange}
            value={props.state.addressOffer}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id={props.context}
            height={"calc(1.5em + 0.75rem + 2px)"}
            type="date"
            name="visiteDate"
            label={t("contact.form.visiteDate")}
            onChange={props.handleChange}
            value={props.state.visiteDate}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id={props.context}
            type="text"
            fullWidth
            name="offerLetter"
            height={"calc(1.5em + 0.75rem + 2px)"}
            label={t("contact.form.offerLetter")}
            onChange={props.handleChange}
            value={props.state.offerLetter.toUpperCase()}
          />
        </FormGroup>
        <FormGroup>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              {t("contact.form.offerTime")}
            </InputLabel>
            <Select
              name="offerTime"
              onChange={onChange}
              value={t(props.state.offerTime)}
              fullWidth
              labelId="demo-simple-select-label"
            >
              {dateOptions.map((option) => (
                <MenuItem key={option.value} value={t(option.label)}>
                  {t(option.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </SectionContainer>
      <SectionContainer>
        <TitleSection>{t("offer.fundingtype")}</TitleSection>
        <FinancialType
          context={props.context}
          handleChange={props.handleChange}
          state={props.state}
          inputClass={classes}
        />
      </SectionContainer>
      <SectionContainer>
        <TitleSection>{t("offer.suspensiveconditions")}</TitleSection>
        {conditionsElement.map((element, idx) => {
          return (
            <Conditions
              context={"conditions"}
              handleChange={props.handleChange}
              state={props.state}
              key={idx}
              number={idx}
              inputClass={classes}
            />
          );
        })}
      </SectionContainer>
      {conditionsElement.length <= 3 && (
        <IconContainer>
          <MoreIcon
            as={AddCircleIcon}
            onClick={() => addConditions()}
            colored={colorPrimary}
          />
          <SpanText>{t("offer.addcondition")}</SpanText>
        </IconContainer>
      )}
      {conditionsElement.length > 1 && (
        <IconContainer>
          <MoreIcon
            as={RemoveCircleIcon}
            onClick={() => removeConditions()}
            colored={"#ff5868"}
          />
          <SpanText>{t("offer.removecondition")}</SpanText>
        </IconContainer>
      )}
    </div>
  );
};

export default Informations;
