import React from "react";
import {
  BoxContent,
  BoxNumber,
  BoxNumberContainer,
  BoxTitle,
  BoxTitleContainer,
} from "../Statistics.styles";
const StatisticsBox = (props) => {
  const { number, title } = props;
  return (
    <BoxContent>
      <BoxTitleContainer>
        <BoxTitle>{title}</BoxTitle>
      </BoxTitleContainer>
      <BoxNumberContainer>
        <BoxNumber>{number}</BoxNumber>
      </BoxNumberContainer>
    </BoxContent>
  );
};

export default StatisticsBox;
