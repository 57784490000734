import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, MoreIcon } from "../Utils/Offer.styles";
import { conditionsText } from "./selectFeatures";
import { CheckedIcon } from "../Utils/PdfPreview.styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { colorSecondary } from "../../../GlobalStyles";

export const style = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontSize: "0.9rem",
      color: "#5d5d5d",
    },
    borderRadius: "4px",
    boxShadow: "1px 1px 4px 0px rgba(191,191,191,0.96)",
    marginTop: "10px",
  },
}));

const Conditions = (props) => {
  const classes = style();

  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [typeInput, setTypeInput] = useState("input");
  const [row, setRow] = useState(1);

  useEffect(() => {
    if (props.state.conditions[props.number + 1] === "") {
      setTypeInput("input");
      setRow(1);
    }
  }, [props]);

  const setInputCondition = (text) => {
    const event = {
      target: {
        value: text,
        id: "conditions",
        name: props.number + 1,
      },
    };
    props.handleChange(event);
    const newText = t(text);
    if (newText.length > 49) {
      setRow(Math.round(newText.length / 49));
      setTypeInput("textarea");
    } else {
      setTypeInput("input");
      setRow(1);
    }
    setChecked(false);
  };

  return (
    <FormGroup
      // className={props.inputClass.inputRight}
      small={props.number + 1 === 1 ? true : false}
    >
      <TextField
        fullWidth
        id={props.context}
        type="text"
        multiline={row > 1 ? true : false}
        as={typeInput}
        height={row > 1 ? "unset" : "calc(1.5em + 0.75rem + 2px)"}
        rows={row}
        name={props.number + 1}
        label={`${t("contact.form.conditions")} n°${props.number + 1}`}
        onChange={props.handleChange}
        value={t(props.state.conditions[props.number + 1])}
      />
      <MoreIcon
        as={AddCircleIcon}
        color="primary"
        onClick={() => setChecked(!checked)}
        style={{
          position: "absolute",
          top: "13px",
          right: "10px",
          fontSize: "25px !important",
        }}
      />
      <Collapse in={checked}>
        <List
          component="nav"
          aria-label="secondary mailbox folders"
          className={classes.root}
        >
          {conditionsText.map((item, idx) => (
            <ListItem key={idx} button onClick={() => setInputCondition(item)}>
              <ListItemIcon>
                <CheckedIcon
                  as={
                    props.state.conditions[props.number + 1] === item
                      ? CheckCircleIcon
                      : CheckCircleOutlineIcon
                  }
                  mycolor={
                    props.state.conditions[props.number + 1] === item
                      ? colorSecondary
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t(item)} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </FormGroup>
  );
};

export default Conditions;
