import { Popover } from "@material-ui/core";
import React from "react";
import ColorChoice from "../ColorChoice";
import { ColorCube, TitleSection } from "../Styles/StepFour.styles";
import { ColorElement, EmptyDiv } from "../Styles/ColorChoice.styles";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";
import { useTranslation } from "react-i18next";

const ColorBannerComponent = () => {
  const { t } = useTranslation();
  const {
    setColorPrimary,
    setColorSecondary,
    model,
    colorPrimary,
    colorSecondary,
  } = useTemplatesStore();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangeColor = (color) => {
    if (model !== "Loft And House") {
      setColorPrimary(color.hex);
    } else {
      setColorSecondary(color.hex);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <ColorElement>
      <EmptyDiv />
      <TitleSection>{t("modalprint.colorbanner").toUpperCase()}</TitleSection>
      <ColorCube
        color={model !== "Loft And House" ? colorPrimary : colorSecondary}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorChoice
          color={model !== "Loft And House" ? colorPrimary : colorSecondary}
          handleChangeColor={handleChangeColor}
        />
      </Popover>
    </ColorElement>
  );
};

export default ColorBannerComponent;
