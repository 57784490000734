import React, { useState } from "react";
import axios from "axios";
import { config } from "../../../../constants/constant";
import { ImportIcon, Loading } from "../Utils/Styles/Agency.styles";
import { useTranslate } from "ra-core";
import { useAlert } from "react-alert";
import { colorPrimary } from "../../../../GlobalStyles";

export const importMandats = async (agency) => {
  const site = agency.id;
  return await axios.get(`${config.API_BASE}/imports/${site}`, {
    headers: {
      Authorization: localStorage.getItem(config.ACCESS_TOKEN_NAME),
    },
  });
};

export const ImportButton = (props) => {
  const translate = useTranslate();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const requestImport = async () => {
    setLoading(true);
    const response = await importMandats(props.record);
    if (response) {
      setLoading(false);
      alert.show(
        props.record.name + " - " + translate("resources.alert.finishImport")
      );
    }
  };

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        width: "40px",
        height: "40px",
        padding: "0.6rem 0",
      }}
    >
      <ImportIcon
        $loading={loading}
        onClick={props.record.apiTokenValue ? () => requestImport() : null}
        $color={
          props.record.apiTokenValue ? colorPrimary : "rgba(0, 0, 0, 0.26)"
        }
        $disabled={props.record.apiTokenValue}
      />
      {loading && <Loading $loading={loading} size={17} />}
    </div>
  );
};
