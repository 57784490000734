import React, { useContext, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useGetIdentity,
  FunctionField,
  useTranslate,
  Pagination,
} from "react-admin";
import FilterSidebar from "./Utils/filters";
import { agencyListStyle } from "./Utils/Styles/Agency.mui.styles";
import { UserChangeContext, UserContext } from "../../../store/UserProvider";
import { ImportButton } from "./Components/importMandats";
import LastImportField from "./Components/LastImportField";
import ListActions from "./Components/ListActions";
import { queryShow } from "./Utils/features";
import EditButtonField from "./Components/EditButtonField";
import DeleteActionButton from "./Components/DeleteActionButton";

const AgencyList = (props) => {
  const translate = useTranslate();
  const role = ["super_admin", "admin"];
  const roleWithManager = ["super_admin", "admin", "manager"];
  const { user, setUser } = useContext(UserContext);
  const { userChange, setUserChange } = useContext(UserChangeContext);
  const [selected, setSelected] = useState({
    user: user,
    setUser: setUser,
  });
  const classes = agencyListStyle();
  const { identity, loading: identityLoading } = useGetIdentity();

  useEffect(() => {
    if (userChange) {
      setSelected({ user: userChange, setUser: setUserChange });
    }
  }, [userChange, setUserChange, props]);

  if (identityLoading) return <>Loading...</>;

  const AgencyPagination = (props) => (
    <Pagination
      rowsPerPageOptions={[10, 25, 50, 75, 100, 250, 500, 1000]}
      {...props}
    />
  );

  return (
    <List
      {...props}
      pagination={<AgencyPagination />}
      aside={role.includes(identity.permissions) ? <FilterSidebar /> : null}
      actions={<ListActions admin={role.includes(identity.permissions)} />}
      sort={{ field: "createdAt", order: "DESC" }}
      exporter={false}
      perPage={50}
      filter={queryShow(selected)}
      bulkActionButtons={
        identity !== undefined && identity.permissions.includes("admin") ? (
          <DeleteActionButton />
        ) : (
          false
        )
      }
    >
      <Datagrid rowClick={null} className={classes.root}>
        <TextField source="name" />
        <TextField source="city" />
        <TextField
          source="countusers"
          headerClassName={classes.hiddenOnSmallScreens}
          cellClassName={classes.hiddenOnSmallScreens}
        />
        <FunctionField
          label={translate("resources.agencies.fields.lastimport")}
          render={(record) => <LastImportField record={record} {...props} />}
        />
        <DateField
          source="createdAt"
          headerClassName={classes.hiddenOnXSmallScreens}
          cellClassName={classes.hiddenOnXSmallScreens}
        />
        {role.includes(selected.user.permissions) && (
          <FunctionField
            label={translate("resources.fields.importmandates")}
            render={(record) => <ImportButton record={record} {...props} />}
          />
        )}
        {roleWithManager.includes(selected.user.permissions) && (
          <FunctionField
            label={translate("resources.fields.edit")}
            render={(record) => <EditButtonField record={record} {...props} />}
          />
        )}
      </Datagrid>
    </List>
  );
};

export default AgencyList;
