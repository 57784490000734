import React from "react";
import { LoaderContainerChild, LoaderDotElement } from "./Loader.styles";

const SmallLoader = (props) => {
  return (
    <LoaderContainerChild minHeight>
      <LoaderDotElement />
      <LoaderDotElement />
      <LoaderDotElement />
    </LoaderContainerChild>
  );
};

export default SmallLoader;
