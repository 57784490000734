/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslate } from "react-admin";
import {
  TitleNumberOfUser,
  Container,
  ValueContainer,
  NumberValueContainer,
  ValueText,
  ValueTextSmall,
  AddRemoveUserSuppContainer,
  AddRemoveIcon,
} from "../../Utils/Styles/ItemManager.styles";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import { getUserIncluded } from "../features";

const Module = (props) => {
  const translate = useTranslate();

  return (
    <Container>
      <NumberValueContainer>
        <TitleNumberOfUser>
          {translate(props.placeholder) + " "}
        </TitleNumberOfUser>
        <ValueContainer>
          {props.name !== "ads" ? (
            <ValueText>
              {getUserIncluded(props.name, props.category) + " "}
              <ValueTextSmall> - ({translate(props.include)})</ValueTextSmall>
            </ValueText>
          ) : (
            <ValueText>
              {getUserIncluded(props.subtype || null, props.category) + " "}
              <ValueTextSmall> - ({translate(props.include)})</ValueTextSmall>
            </ValueText>
          )}
        </ValueContainer>
      </NumberValueContainer>
      <NumberValueContainer>
        <TitleNumberOfUser>
          {translate(props.secondPlaceholder)}{" "}
        </TitleNumberOfUser>
        <ValueContainer>
          <ValueText margin="true">
            {props.state[props.subcategory]}
            <ValueTextSmall>
              {props.state[props.subcategory]
                ? " - (" +
                  (props.state[props.subcategory] * props.price).toFixed(2) +
                  "€ HT)"
                : ""}
            </ValueTextSmall>
          </ValueText>
          <AddRemoveUserSuppContainer>
            <AddRemoveIcon
              as={ArrowDropDownCircleOutlinedIcon}
              rotate="true"
              onClick={() =>
                props.handleAddOrRemove(props.subcategory, props.name, "add")
              }
            />
            <AddRemoveIcon
              as={ArrowDropDownCircleOutlinedIcon}
              onClick={() =>
                props.handleAddOrRemove(props.subcategory, props.name, "remove")
              }
            />
          </AddRemoveUserSuppContainer>
        </ValueContainer>
      </NumberValueContainer>
    </Container>
  );
};

export default Module;
