import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useTranslate } from "react-admin";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { choices } from "../../../Utils/dataChoices";
import { Fade } from "@material-ui/core";

const TypeFilter = (props) => {
  const [selected, setSelected] = useState("");
  const [click, setClick] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    if (props.isReset) {
      setSelected("");
    }
  }, [props.isReset]);

  const handleListItemClick = (category, name) => {
    setSelected(name);
    props.handleFilter(category, name);
  };
  return (
    <div style={{ marginTop: "2rem" }}>
      <Typography
        onClick={() => setClick(!click)}
        style={{
          cursor: "pointer",
          fontSize: "0.75rem",
          letterSpacing: "0.08333em",
        }}
      >
        {translate("resources.projects.fields.type").toUpperCase()}
      </Typography>
      <Fade in={click} timeout={300} unmountOnExit>
        <List>
          {choices.type.map((item) => {
            return (
              <ListItem
                key={item.id}
                button
                style={{ padding: "0" }}
                selected={selected === translate(item.name)}
                onClick={(event) =>
                  handleListItemClick("type", translate(item.id))
                }
              >
                <ListItemText>
                  <span className="MuiTypography-body2">
                    {translate(item.name)}
                  </span>
                </ListItemText>
                {selected === translate(item.id) ? (
                  <ListItemSecondaryAction
                    onClick={() => handleListItemClick("type", "")}
                  >
                    <CancelOutlinedIcon />
                  </ListItemSecondaryAction>
                ) : null}
              </ListItem>
            );
          })}
        </List>
      </Fade>
    </div>
  );
};

export default TypeFilter;
