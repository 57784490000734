import React, { useContext, useEffect, useState } from "react";
import {
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  useGetIdentity,
  BooleanInput,
} from "react-admin";
import { Box, CardContent, Fade, Typography } from "@material-ui/core";
import {
  GeoapifyContext,
  GeoapifyGeocoderAutocomplete,
} from "@geoapify/react-geocoder-autocomplete";
import "./agency.css";
import { choices } from "../../Utils/dataChoices";
import { config } from "../../../../../constants/constant";
import FormSlug from "./FormSlug";
import FormToken from "./FormToken";
// import FormAcceptOffer from "./FormAcceptOffer";
import FormAbo from "../../../../ModuleAbo/FormAbo";
import BillingInformation from "./BillingInformation/BillingInformation";
import { DateInputText, HrDivider } from "../../Utils/Styles/Agency.styles";
import { now, get24hPast } from "../../Utils/features";
import { CombineContext } from "../../../../../store/UserProvider";

const AgencyForm = (props) => {
  const role = ["admin", "super_admin"];
  const translate = useTranslate();
  const { onPlaceSelect, record } = props;
  const [apiName, setApiName] = useState();
  const [slug, setSlug] = useState("");
  const data = useContext(CombineContext);
  // const [ads, setAds] = useState(false);
  const [admin, setAdmin] = useState(false);
  const { identity, loading: identityLoading } = useGetIdentity();

  useEffect(() => {
    const role = ["super_admin", "admin"];
    let user;
    if (data.valueChange.userChange) {
      user = data.valueChange.userChange;
    } else {
      user = data.value.user;
    }
    if (role.includes(user.permissions)) {
      setAdmin(true);
    }
    // if (data.typeValue.type === "360" || role.includes(user.permissions)) {
    //   setAds(true);
    // }
  }, [data]);

  if (identityLoading) return <>Loading...</>;

  const onChange = (event) => {
    const value = event.target.value;

    event.target.value = value.trim();
    setSlug(event.target.value);
  };

  const changeApi = (value) => {
    setApiName(value.target.value);
  };

  return (
    <Fade in={true} timeout={400}>
      <CardContent className="Form-Project">
        <Box>
          {/* INFORMATION Part */}
          <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px" mt="20px">
            <Typography variant="h6" gutterBottom>
              {translate("resources.agencies.title")}
            </Typography>
            <HrDivider light />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="name"
                  resource="agencies"
                  disabled={role.includes(identity.permissions) ? false : true}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="siren"
                  resource="agencies"
                  disabled={record.id ? true : false}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="phone" resource="agencies" fullWidth />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="email" resource="agencies" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="emailnotifications"
                  resource="agencies"
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="siteweb" resource="agencies" fullWidth />
              </Box>
            </Box>

            {props.record.id && (
              <FormSlug
                config={config}
                slug={slug}
                onChange={onChange}
                record={props.record}
                user={props.userSelect}
                agency={props.agency}
              />
            )}
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="propertyValue"
                resource="agencies"
                fullWidth
                defaultValue={props.record.propertyValue}
              />
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <TextInput
                source="calendar"
                resource="agencies"
                fullWidth
                defaultValue={props.record.calendar}
              />
            </Box>
          </Box>

          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <SelectInput
                source="lang"
                resource="agencies"
                choices={choices.lang}
              />
            </Box>
          </Box>
          {/* {props.record.id && ads && (
            <FormAcceptOffer
              record={props.record}
              agency={props.agency}
              user={props.userSelect}
            />
          )} */}
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <BooleanInput
                source="mobileFormAccess"
                resource="agencies"
                fullWidth
                defaultValue={props.record.mobileFormAccess}
              />
            </Box>
          </Box>
          {/* ADDRESS Part */}
          <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="40px">
            <Typography variant="h6" gutterBottom>
              {translate("resources.agencies.address")}
            </Typography>
            <HrDivider light />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <GeoapifyContext apiKey={config.GEOAPIFY}>
                  <GeoapifyGeocoderAutocomplete
                    placeSelect={onPlaceSelect}
                    resource="projects"
                    source="adressfull"
                    placeholder={translate("resources.agencies.enteraddress")}
                    value={
                      record
                        ? record.addressfull
                          ? record.addressfull
                          : ""
                        : ""
                    }
                  />
                </GeoapifyContext>
              </Box>
            </Box>
          </Box>
          {/* SUBSCRIPTION Part */}
          <FormAbo {...props} />
          {/* BILLING  Part */}
          <BillingInformation {...props} />

          {/* SOCIAL NETWORK Part */}
          <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px">
            <Typography variant="h6" gutterBottom>
              {translate("resources.agencies.social")}
            </Typography>
            <HrDivider light />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ImageInput
                  source="images"
                  resource="agencies"
                  accept="image/*"
                  multiple={false}
                >
                  <ImageField source="src" title="title" src="src" />
                </ImageInput>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ArrayInput
                  source="socialnetwork"
                  resource="agencies"
                  className="arrayInput-product"
                >
                  <SimpleFormIterator>
                    <TextInput source="socialnetwork.url" resource="agencies" />
                    <SelectInput
                      source="socialnetwork.name"
                      choices={choices.socialnetworks}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </Box>
            </Box>
          </Box>

          {/* IMPORT Part */}
          {admin && (
            <FormToken
              record={props.record}
              choices={choices}
              changeApi={changeApi}
              apiName={apiName}
              agency={props.agency}
              user={props.userSelect}
            />
          )}
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <DateInputText
                label={translate("resources.agencies.fields.lastimport")}
                type="datetime-local"
                fullWidth
                value={now(props.record.lastImport)}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                error={get24hPast(props.record.lastImport) === true}
                helperText={
                  get24hPast(props.record.lastImport) === true
                    ? translate("resources.agencies.errorImport")
                    : " "
                }
              />
            </Box>
          </Box>
        </Box>
        {/* end */}
      </CardContent>
    </Fade>
  );
};

export default AgencyForm;
