import React from "react";
import { data } from "./data";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Dialog, DialogContent } from "@material-ui/core";
import { ModalPriceStyles } from "../Utils/Styles/ModalPrice.mui.styles";

const Price = (props) => {
  const { setShow } = props;
  const classes = ModalPriceStyles();

  const handleClose = () => {
    setShow(false);
  };
  return (
    <Dialog open={true} onClose={handleClose} className={classes.root}>
      <DialogContent>
        <div className="prices-container">
          <h1 className="prices-title">Tarifs</h1>
          <div className="divider-container">
            <span
              className="divider-prices"
              style={{ borderTop: `5px solid ${data[props.index].color}` }}
            />
          </div>
          <div className="items-container">
            <div className="item-container">
              <div
                className="item-header"
                style={{ backgroundColor: data[props.index].color }}
              >
                <h3>{data[props.index].name}</h3>
              </div>
              <div className="item-price-container">
                <span className="item-currency">€</span>
                <span className="item-price">{data[props.index].price}</span>
                <div className="item-period-container">
                  <span className="item-period">Mois</span>
                </div>
              </div>
              <ul className="lists-container">
                {data[props.index].list.map((listItem, idx) => (
                  <li key={idx}>
                    <div className="list-item-container">
                      <span className="list-icons">
                        {listItem.icon === true ? (
                          <CheckIcon />
                        ) : listItem.icon === "not" ? (
                          <ClearIcon />
                        ) : null}
                      </span>
                      {listItem.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Price;
