import styled from "styled-components";
import { colorQuaternary } from "../../GlobalStyles";

const Col = {
  paddingRight: "15px",
  paddingLeft: "15px",
};

export const TileBarContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 999;
  min-height: 90px;
  box-shadow: inset 0px 75px 53px -28px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: 1176px) {
    flex-direction: column;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column-reverse;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
  margin-top: 0.5rem;
  flex: 0 0 ${(props) => (props.col === 3 ? "25%" : "50%")};
  max-width: ${(props) => (props.col === 3 ? "25%" : "50%")};
  text-align: center;
  text-transform: uppercase;
  ${Col}

  @media screen and (max-width: 991px) {
    padding-left: unset;
    padding-right: unset;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
  margin-top: 0.5rem;
  flex: 0 0 ${(props) => (props.col === 3 ? "25%" : "50%")};
  max-width: ${(props) => (props.col === 3 ? "25%" : "50%")};
  text-align: center;
  text-transform: uppercase;
  ${Col}

  @media screen and (max-width: 991px) {
    padding-left: unset;
    padding-right: unset;
  }
  @media screen and (max-width: 599px) {
    margin-top: unset;
    width: 100%;
    max-width: 100%;
  }
`;

export const LogoContainer = styled.div`
  padding-left: 1.5rem;
  padding-top: 1rem;
  @media screen and (max-width: 991px) {
    padding-left: unset;
  }
  @media screen and (max-width: 599px) {
    padding-top: 0.5rem;
  }
`;
export const Logo = styled.img`
  width: auto;
  max-width: 150px;
  height: auto;
  border-radius: 0.25rem;
  @media screen and (max-width: 991px) {
    max-width: 130px;
  }
  @media screen and (max-width: 599px) {
    max-width: 100px;
  }
`;
export const TitleAgence = styled.h4`
  margin-top: 1rem;
`;
export const DetailsContainer = styled.div``;

export const TitleDetails = styled.h5`
  font-weight: 300;

  @media screen and (max-width: 991px) {
    font-size: 1rem;
  }
`;
export const SendButton = styled.button`
  display: block;
  color: white;
  width: 200px;
  background-color: ${colorQuaternary};
  border: 1px solid transparent;
  padding: 0.4rem 0.75rem;
  font-size: 0.93rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 600;
  /* margin-top: ${(props) => (props.marginTop ? "1.5rem" : "unset")}; */

  @media screen and (max-width: 599px) {
    margin-top: unset;
    border-radius: 0;
    width: 100%;
    padding: 0.7rem 0.75rem;
    font-size: 1rem;
  }
`;
