import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../store/UserProvider";
import { getUsersData } from "../axiosRequests";

const useData = () => {
  const [dataFetch, setDataFetch] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    const refreshList = async () => {
      const fetch = await getUsersData();
      if (fetch.data) {
        setDataFetch(fetch.data);
        setLoading(false);
      }
    };
    if (user) {
      refreshList();
    }
  }, [user]);

  return { dataFetch, loading };
};

export default useData;
