/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslate } from "react-admin";
import {
  AdsColorBackground,
  DisplayColorBackground,
  PrintColorBackground,
} from "../../../GlobalStyles";
import { choices } from "../Utils/dataChoices";
import { ExpandContainer, ManageBtn } from "../Utils/Styles/Agency.styles";
import { getButtonColor } from "./features";
const ItemButton = (props) => {
  const translate = useTranslate();
  if (
    props.record &&
    props.record.abo &&
    props.record.abo[props.name] &&
    props.record.abo[props.name].type === "print-include"
  ) {
    return null;
  } else
    return (
      <ExpandContainer>
        <ManageBtn
          onClick={() => props.setModal(true)}
          background={props.color}
          active={
            props.record && props.record.abo && props.record.abo[props.name]
              ? getButtonColor(props.record.abo[props.name].state)
              : "false"
          }
        >
          {(props.record &&
            props.record.abo &&
            props.record.abo[props.name] &&
            props.record.abo[props.name].state === choices.state[0].id) ||
          (props.record &&
            props.record.abo &&
            props.record.abo[props.name] &&
            props.record.abo[props.name].state === choices.state[1].id) ||
          (props.record &&
            props.record.abo &&
            props.record.abo[props.name] &&
            props.record.abo[props.name].state === choices.state[3].id)
            ? translate("resources.fields.manageSubscription").toUpperCase()
            : translate("resources.fields.subscribe").toUpperCase()}
        </ManageBtn>
      </ExpandContainer>
    );
};

export default ItemButton;
