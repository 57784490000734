import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  position: relative;
  height: 292mm;
  width: 420mm;
  background-color: white;

  margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
  zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 landscape;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;
export const Header = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 420mm;
  height: 126px;
  min-height: 126px;
  max-height: 126px;
  background-color: ${(props) => props.colorPrimary};
  z-index: 1800;
`;
export const Footer = styled.img`
  position: absolute;
  top: 258.51651mm;
  left: 0;
  width: 420mm;
  height: 126px;
  min-height: 126px;
  max-height: 126px;
  background-color: ${(props) => props.colorPrimary};
  z-index: 1800;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 33.5mm;
  height: 210mm;
  width: 100%;
  z-index: 1800;
`;

export const CityContainer = styled.div`
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CityText = styled.h2`
  font-size: 50px;
  color: black;
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const ImagesContainer = styled.div`
  position: relative;
  justify-content: center;
  padding: 1.5rem 2rem;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 25px;
  height: 60%;
  grid-template-rows: 100%;
`;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 5px ${(props) => props.colorPrimary};
  border-radius: 4px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const DescContainer = styled.div`
  height: 36.98%;
  width: 100%;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 30% 70%;
`;

export const ExcluContainer = styled.div`
  width: 181.17pt;
  height: 47.82pt;
  background-color: ${(props) => props.colorPrimary};
  position: absolute;
  left: 50px;
  top: 18pt;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-top-left-radius: 4px;
`;

export const ExcluText = styled.p`
  font-size: 24pt;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  text-align: center;
  margin-bottom: 0;
`;

export const BlockDescContainer = styled.div`
  display: flex;
  position: relative;
`;
export const Description = styled.p`
  && {
    font-size: 17px;
    text-align: left;
    color: ${(props) => props.colorDesc};
    white-space: pre-wrap;
  }
`;
