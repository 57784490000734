export const errorsHandler = (error, setErrors) => {
  if (error.message.includes("reference")) {
    setErrors((prev) => ({
      ...prev,
      reference: true,
    }));
  } else {
    setErrors((prev) => ({
      ...prev,
      reference: false,
    }));
  }
  if (error.message.includes("state")) {
    setErrors((prev) => ({
      ...prev,
      state: true,
    }));
  } else {
    setErrors((prev) => ({
      ...prev,
      state: false,
    }));
  }
  if (error.message.includes("category")) {
    setErrors((prev) => ({
      ...prev,
      category: true,
    }));
  } else {
    setErrors((prev) => ({
      ...prev,
      category: false,
    }));
  }
  if (
    error.message.includes("type") &&
    !error.message.includes("language override")
  ) {
    setErrors((prev) => ({
      ...prev,
      type: true,
    }));
  } else {
    setErrors((prev) => ({
      ...prev,
      type: false,
    }));
  }
  if (error.message.includes("price")) {
    setErrors((prev) => ({
      ...prev,
      price: true,
    }));
  } else {
    setErrors((prev) => ({
      ...prev,
      price: false,
    }));
  }
  if (error.message.includes("area")) {
    setErrors((prev) => ({
      ...prev,
      area: true,
    }));
  } else {
    setErrors((prev) => ({
      ...prev,
      area: false,
    }));
  }
  if (error.message.includes("language")) {
    setErrors((prev) => ({
      ...prev,
      commentLang: true,
    }));
  } else
    setErrors((prev) => ({
      ...prev,
      commentLang: false,
    }));
};
