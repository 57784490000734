import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { choices } from "../../PrintPage/Utils/dataChoices";
import { AlurText } from "./ModulesElements.styles";

const ModuleAlurDetails = (props) => {
  const { t } = useTranslation();
  const [color, setColor] = useState();
  const [currency] = useState(
    props.project.currency ? props.project.currency : "€"
  );

  useEffect(() => {
    const excludedValue = ["", undefined, null];
    if (excludedValue.includes(props.colorDesc)) {
      setColor(props.defaultColor);
    } else {
      setColor(props.colorDesc);
    }
  }, [props.colorDesc, props.defaultColor]);

  return (
    <>
      {props.project.nblotcopro > 0 ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.copropriete")} {props.project.nblotcopro}{" "}
          {t("modalprint.lots")}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("details.modalprint.monthlycharge")} :{" "}
          {props.project.monthlycharge > 0
            ? props.project.monthlycharge + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.fees")} :{" "}
          {props.project.fees > 0
            ? props.project.fees + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {(props.project.commission || props.project.sharedCommission) &&
      props.project.category === choices.category[0].id ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("details.modalprint.commission")} :{" "}
          {props.project.fees !== ""
            ? props.project.fees +
              (props.project.feespercent ? " %" : " " + currency)
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {(props.project.sharedCommission || props.project.commission) &&
      props.project.category === choices.category[0].id &&
      props.project.amountWithoutHonorary ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.excludefees")} : {props.project.amountWithoutHonorary}{" "}
          {currency}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("modalprint.feesetatdeslieux")} :{" "}
          {props.project.feesetatdeslieux > 0
            ? props.project.feesetatdeslieux + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[1].id ? (
        <AlurText
          colorDesc={color}
          smallMarginBottom={props.smallMarginBottom}
          sizeText={props.sizeText}
        >
          {t("details.modalprint.feesdepotgarantie")} :{" "}
          {props.project.feesdepotgarantie > 0
            ? props.project.feesdepotgarantie + " " + currency
            : t("modalprint.undisclosed")}{" "}
        </AlurText>
      ) : null}
      {props.project.category === choices.category[0].id &&
        props.project.type !== choices.type[1].id &&
        props.project.type !== choices.type[2].id &&
        props.project.type !== choices.type[3].id && (
          <AlurText
            colorDesc={color}
            smallMarginBottom={props.smallMarginBottom}
            sizeText={props.sizeText}
          >
            {props.project.procsyndicale
              ? "Procédure syndicale en cours"
              : "Pas de procédure en cours"}
          </AlurText>
        )}
    </>
  );
};

export default ModuleAlurDetails;
