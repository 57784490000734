export const data = [
  {
    name: "Visibble PRINT",
    validationkey: "print",
    translatetext: "sign.agence.print",
    price: 19,
    list: [
      {
        name: "1 agent",
        icon: false,
      },
      {
        name: "100 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: "not",
      },
      {
        name: "Logiciel création visite 360°",
        icon: "not",
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: "not",
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: "not",
      },
      {
        name: "Partage de document PDF",
        icon: "not",
      },
      {
        name: "Module SEO",
        icon: "not",
      },
      {
        name: "Statistique de visibilité",
        icon: "not",
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: "not",
      },
      {
        name: "Formulaire de contact",
        icon: "not",
      },
      {
        name: "Gestion des leads centralisée",
        icon: "not",
      },
      {
        name: "Partage réseaux sociaux",
        icon: "not",
      },
      {
        name: "Intégration e-mailing",
        icon: "not",
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
  {
    name: "Visibble DISPLAY",
    validationkey: "display",
    translatetext: "sign.agence.display",
    price: 49,
    list: [
      {
        name: "1 agent",
        icon: false,
      },
      {
        name: "100 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: "not",
      },
      {
        name: "Logiciel création visite 360°",
        icon: "not",
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: "not",
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: "not",
      },
      {
        name: "Partage de document PDF",
        icon: "not",
      },
      {
        name: "Module SEO",
        icon: "not",
      },
      {
        name: "Statistique de visibilité",
        icon: "not",
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: "not",
      },
      {
        name: "Formulaire de contact",
        icon: "not",
      },
      {
        name: "Gestion des leads centralisée",
        icon: "not",
      },
      {
        name: "Partage réseaux sociaux",
        icon: "not",
      },
      {
        name: "Intégration e-mailing",
        icon: "not",
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
  {
    name: "Licence AGENT",
    validationkey: "agent",
    translatetext: "sign.agence.agent",
    price: 49,
    color: "#1e4c92",
    list: [
      {
        name: "1 agent",
        icon: false,
      },
      {
        name: "25 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: true,
      },
      {
        name: "Logiciel création visite 360°",
        icon: true,
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: true,
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: true,
      },
      {
        name: "Partage de document PDF",
        icon: true,
      },
      {
        name: "Module SEO",
        icon: true,
      },
      {
        name: "Statistique de visibilité",
        icon: true,
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: true,
      },
      {
        name: "Formulaire de contact",
        icon: true,
      },
      {
        name: "Gestion des leads centralisée",
        icon: true,
      },
      {
        name: "Partage réseaux sociaux",
        icon: true,
      },
      {
        name: "Intégration e-mailing",
        icon: true,
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
  {
    name: "Licence AGENCE",
    validationkey: "agence",
    translatetext: "sign.agence.agence",
    price: 59,
    color: "#2fbcee",
    list: [
      {
        name: "3 agents",
        icon: false,
      },
      {
        name: "75 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: true,
      },
      {
        name: "Logiciel création visite 360°",
        icon: true,
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: true,
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: true,
      },
      {
        name: "Partage de document PDF",
        icon: true,
      },
      {
        name: "Module SEO",
        icon: true,
      },
      {
        name: "Statistique de visibilité",
        icon: true,
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: true,
      },
      {
        name: "Formulaire de contact",
        icon: true,
      },
      {
        name: "Gestion des leads centralisée",
        icon: true,
      },
      {
        name: "Partage réseaux sociaux",
        icon: true,
      },
      {
        name: "Intégration e-mailing",
        icon: true,
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
  {
    name: "Licence BUSINESS",
    validationkey: "business",
    translatetext: "sign.agence.business",
    price: 79,
    color: "#bcd151",
    list: [
      {
        name: "10 agents",
        icon: false,
      },
      {
        name: "150 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: true,
      },
      {
        name: "Logiciel création visite 360°",
        icon: true,
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: true,
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: true,
      },
      {
        name: "Partage de document PDF",
        icon: true,
      },
      {
        name: "Module SEO",
        icon: true,
      },
      {
        name: "Statistique de visibilité",
        icon: true,
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: true,
      },
      {
        name: "Formulaire de contact",
        icon: true,
      },
      {
        name: "Gestion des leads centralisée",
        icon: true,
      },
      {
        name: "Partage réseaux sociaux",
        icon: true,
      },
      {
        name: "Intégration e-mailing",
        icon: true,
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
  {
    name: "Licence TEAM",
    validationkey: "team",
    translatetext: "sign.agence.team",
    price: 129,
    color: "#ef8454",
    list: [
      {
        name: "15 agents",
        icon: false,
      },
      {
        name: "250 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: true,
      },
      {
        name: "Logiciel création visite 360°",
        icon: true,
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: true,
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: true,
      },
      {
        name: "Partage de document PDF",
        icon: true,
      },
      {
        name: "Module SEO",
        icon: true,
      },
      {
        name: "Statistique de visibilité",
        icon: true,
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: true,
      },
      {
        name: "Formulaire de contact",
        icon: true,
      },
      {
        name: "Gestion des leads centralisée",
        icon: true,
      },
      {
        name: "Partage réseaux sociaux",
        icon: true,
      },
      {
        name: "Intégration e-mailing",
        icon: true,
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
  {
    name: "Licence LEADER",
    validationkey: "leader",
    translatetext: "sign.agence.leader",
    price: 299,
    color: "#22292f",
    list: [
      {
        name: "25 agents",
        icon: false,
      },
      {
        name: "500 mandats",
        icon: true,
      },
      {
        name: "Visibble PRINT",
        icon: true,
      },
      {
        name: "Passerelle logiciel de transaction",
        icon: true,
      },
      {
        name: "QR Code Visibble",
        icon: true,
      },
      {
        name: "Visibble",
        icon: true,
      },
      {
        name: "Logiciel création visite 360°",
        icon: true,
      },
      {
        name: "Visite sécurisée à double facteurs",
        icon: true,
      },
      {
        name: "Superplayer (vidéos, photos HD, YouTube)",
        icon: true,
      },
      {
        name: "Partage de document PDF",
        icon: true,
      },
      {
        name: "Module SEO",
        icon: true,
      },
      {
        name: "Statistique de visibilité",
        icon: true,
      },
      {
        name: "Connexion Messagerie WhatsApp",
        icon: true,
      },
      {
        name: "Formulaire de contact",
        icon: true,
      },
      {
        name: "Gestion des leads centralisée",
        icon: true,
      },
      {
        name: "Partage réseaux sociaux",
        icon: true,
      },
      {
        name: "Intégration e-mailing",
        icon: true,
      },
      {
        name: "Support en ligne",
        icon: true,
      },
      {
        name: "Formation en ligne",
        icon: true,
      },
    ],
  },
];
