import React from "react";

const CustomerDetails = (props) => {
  return (
    <li>
      {props.state.lastname} {props.state.firstname},{" "}
      {props.state.address +
        " " +
        props.state.postalcode +
        " " +
        props.state.city}
      , {props.date(props.state.birthDate, false)}, {props.state.birthPlace}
    </li>
  );
};

export default CustomerDetails;
