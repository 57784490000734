import React from "react";

const Group280 = () => {
  return (
    <svg
      id="Groupe_280"
      data-name="Groupe 280"
      xmlns="http://www.w3.org/2000/svg"
      width="133.024"
      height="33.922"
      viewBox="0 0 133.024 33.922"
    >
      <path
        id="Tracé_584"
        data-name="Tracé 584"
        d="M16.961,0h99.1a16.961,16.961,0,1,1,0,33.922h-99.1A16.961,16.961,0,0,1,16.961,0Z"
        transform="translate(0 0)"
        fill="#3fa535"
      />
      <g
        id="Groupe_273"
        data-name="Groupe 273"
        transform="translate(54.293 10.661)"
      >
        <g id="Groupe_272" data-name="Groupe 272">
          <path
            id="Tracé_569"
            data-name="Tracé 569"
            d="M862.976,735.211a1.735,1.735,0,0,1,.022.447c-.332,1.254-.175,1.562,1.066,2.033a14.3,14.3,0,0,0,4.146.637c.528.038.43-.3.44-.6.024-.717.342-.867.867-.39,2.461,2.236,2.6,1.55.058,3.814-.189.168-.385.351-.66.247-.3-.112-.294-.393-.258-.638.073-.495-.186-.545-.592-.562a12.989,12.989,0,0,1-4.142-.658,3.979,3.979,0,0,1-1.574-.98A2.073,2.073,0,0,1,862.976,735.211Z"
            transform="translate(-861.746 -728.993)"
            fill="#fff"
          />
          <path
            id="Tracé_570"
            data-name="Tracé 570"
            d="M909.938,735.15a2.1,2.1,0,0,1,.327,3.667,7.064,7.064,0,0,1-3.587,1.209c-.371.048-.747.059-1.12.09-1.275.108-1.52-.212-1.27-1.514.089-.462.4-.313.631-.33a14.777,14.777,0,0,0,3.6-.507,4.6,4.6,0,0,0,.945-.394c.6-.363,1.078-.794.54-1.579C909.916,735.663,909.964,735.438,909.938,735.15Z"
            transform="translate(-893.632 -728.947)"
            fill="#fff"
          />
          <path
            id="Tracé_571"
            data-name="Tracé 571"
            d="M889.749,712.685c1.169-.759,2.205-.66,2.763.219a1.828,1.828,0,0,1-.639,2.532,2.031,2.031,0,0,1-2.882-.863,3.537,3.537,0,0,1,.039-3.261,2.036,2.036,0,0,1,3.267-.452c.184.208.47.51.289.729-.215.259-.639.19-.939.027C890.359,710.918,889.884,711.151,889.749,712.685Z"
            transform="translate(-881.924 -710.232)"
            fill="#fff"
          />
          <path
            id="Tracé_572"
            data-name="Tracé 572"
            d="M912.456,712.988c0,1.719-.832,2.766-2.161,2.72-1.259-.044-1.979-1.073-1.961-2.8.017-1.632.8-2.641,2.043-2.645S912.455,711.337,912.456,712.988Z"
            transform="translate(-896.736 -710.253)"
            fill="#fff"
          />
          <path
            id="Tracé_573"
            data-name="Tracé 573"
            d="M872.616,712.891c.749.391,1.181.875.943,1.678a1.928,1.928,0,0,1-2.039,1.172,1.8,1.8,0,0,1-1.81-1.072c-.069-.16-.15-.414.086-.478a1.323,1.323,0,0,1,.727.028c.151.05.228.287.372.4a1.13,1.13,0,0,0,1.235.087.622.622,0,0,0,.363-.7c-.095-.513-.51-.568-.922-.623-.286-.039-.653.065-.638-.448.013-.45.312-.38.584-.386.407-.01.75-.114.828-.581a.557.557,0,0,0-.263-.647.873.873,0,0,0-1.122.083c-.3.378-.582.616-1.072.5a.215.215,0,0,1-.173-.277,1.457,1.457,0,0,1,.891-1.127,2.625,2.625,0,0,1,1-.2,1.688,1.688,0,0,1,1.7.933C873.629,711.961,873.266,712.473,872.616,712.891Z"
            transform="translate(-867.673 -710.283)"
            fill="#fff"
          />
          <path
            id="Tracé_574"
            data-name="Tracé 574"
            d="M895.471,722.263a.854.854,0,0,1-.982.87.826.826,0,0,1-.862-.906.923.923,0,0,1,1.844.036Z"
            transform="translate(-885.69 -718.583)"
            fill="#3fa535"
          />
          <path
            id="Tracé_575"
            data-name="Tracé 575"
            d="M912.788,715.723c.085-.591-.076-1.6.944-1.6s.919,1.008.95,1.727c.049,1.178-.334,1.864-.955,1.864S912.779,717.067,912.788,715.723Z"
            transform="translate(-900.082 -713.153)"
            fill="#3fa535"
          />
        </g>
      </g>
      <g
        id="Groupe_274"
        data-name="Groupe 274"
        transform="translate(12.871 9.524)"
      >
        <path
          id="Tracé_576"
          data-name="Tracé 576"
          d="M699.525,733.2c0,.794-.055,1.593.015,2.381.077.873-.179,1.178-1.111,1.178s-1.007-.394-.991-1.137c.04-1.8.055-3.6-.005-5.4-.028-.835.339-.952,1.035-.933.64.017,1.136,0,1.073.893C699.471,731.181,699.525,732.192,699.525,733.2Z"
          transform="translate(-696.912 -723.406)"
          fill="#fefefe"
        />
        <path
          id="Tracé_577"
          data-name="Tracé 577"
          d="M696.909,705.666c.931.151,1.6.619,1.584,1.633a1.428,1.428,0,0,1-1.478,1.527,1.445,1.445,0,0,1-1.67-1.49C695.3,706.323,695.96,705.837,696.909,705.666Z"
          transform="translate(-695.343 -705.666)"
          fill="#f7f7f7"
        />
      </g>
      <g
        id="Groupe_275"
        data-name="Groupe 275"
        transform="translate(81.199 9.383)"
      >
        <path
          id="Tracé_578"
          data-name="Tracé 578"
          d="M976.615,743.424q-2.292,0-4.584,0a2.032,2.032,0,0,1-2.2-2.194q0-1.237,0-2.474c0-.477.269-.781.674-.778s.665.3.668.782c.005.813,0,1.625,0,2.437,0,.634.247.882.882.882q4.6,0,9.2,0c.59,0,.847-.254.851-.84.005-.825,0-1.649,0-2.474,0-.486.256-.78.662-.788s.678.3.68.808c0,.849.006,1.7,0,2.547a2.013,2.013,0,0,1-2.08,2.089C979.792,743.43,978.2,743.424,976.615,743.424Z"
          transform="translate(-969.834 -729.793)"
          fill="#fff"
        />
        <path
          id="Tracé_579"
          data-name="Tracé 579"
          d="M984.183,712.334v-1.115q0-2.675,0-5.349a.686.686,0,0,1,.406-.707.654.654,0,0,1,.714.1.8.8,0,0,1,.243.693q0,2.966,0,5.931v.349l.081.044q.9-.915,1.794-1.83c.128-.13.251-.264.388-.384a.685.685,0,0,1,.951.015.666.666,0,0,1-.006.948c-.341.362-.7.707-1.051,1.059q-1.144,1.146-2.288,2.292a.68.68,0,0,1-1.055.042q-1.678-1.667-3.343-3.347a.673.673,0,0,1-.131-.889.66.66,0,0,1,.764-.276.811.811,0,0,1,.383.263Z"
          transform="translate(-978.049 -705.099)"
          fill="#fff"
        />
      </g>
      <g
        id="Groupe_276"
        data-name="Groupe 276"
        transform="translate(29.552 9.608)"
      >
        <path
          id="Tracé_580"
          data-name="Tracé 580"
          d="M769.2,706a6.908,6.908,0,0,1,4.866,11.73,6.512,6.512,0,0,1-7.307,1.614,2.645,2.645,0,0,0-1.777-.111c-.686.206-1.385.369-2.081.542-.449.111-.614-.069-.489-.518.027-.1.053-.193.086-.287a6.84,6.84,0,0,0,.115-4.432A6.788,6.788,0,0,1,769.2,706Z"
          transform="translate(-762.352 -706)"
          fill="#fff"
        />
        <path
          id="Tracé_581"
          data-name="Tracé 581"
          d="M780.867,726.981a5.789,5.789,0,0,1-2.528-.978,7.9,7.9,0,0,1-2.519-2.8,2.075,2.075,0,0,1,.241-2.562.75.75,0,0,1,1.344.25c.268.678.833,1.372-.016,2.068-.2.166.034.362.13.519a4.869,4.869,0,0,0,1.528,1.5c.588.388,1.111.535,1.517-.234.16-.3.4-.323.685-.168.217.117.463.186.665.323.264.179.73.153.715.639a1.27,1.27,0,0,1-.638,1.114A1.929,1.929,0,0,1,780.867,726.981Z"
          transform="translate(-772.223 -716.758)"
          fill="#3fa535"
        />
      </g>
      <g
        id="Groupe_278"
        data-name="Groupe 278"
        transform="translate(106.039 11.709)"
      >
        <g id="Groupe_277" data-name="Groupe 277">
          <path
            id="Tracé_582"
            data-name="Tracé 582"
            d="M1076.55,734.077c-1.726,0-3.452,0-5.178,0-1.252,0-1.739-.476-1.743-1.7-.007-1.927.008-3.855-.007-5.782-.006-.7.241-.823.819-.46,1.6,1.007,3.257,1.943,4.81,3.024a1.957,1.957,0,0,0,2.611-.027c1.518-1.08,3.138-2.017,4.721-3,.686-.428.852-.344.855.436.009,1.985.014,3.97,0,5.954-.008,1.052-.537,1.552-1.624,1.559C1080.059,734.085,1078.3,734.077,1076.55,734.077Z"
            transform="translate(-1069.622 -723.025)"
            fill="#fff"
          />
          <path
            id="Tracé_583"
            data-name="Tracé 583"
            d="M1077.152,714.442c1.694,0,3.388,0,5.082,0a3.153,3.153,0,0,1,.848.087.893.893,0,0,1,.717.672c.06.379-.274.532-.539.7-1.816,1.148-3.64,2.285-5.448,3.447a1.115,1.115,0,0,1-1.346.03c-1.838-1.168-3.69-2.313-5.536-3.467-.265-.166-.6-.311-.518-.7.07-.328.351-.5.643-.647a1.954,1.954,0,0,1,.928-.122Q1074.567,714.438,1077.152,714.442Z"
            transform="translate(-1070.205 -714.441)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Group280;
