/* eslint-disable no-unused-vars */
import React from "react";
import { Layout, Sidebar } from "react-admin";
import MyAppbar from "./MyAppbar";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "#fafafa",
  },
});

const MySidebar = (props) => {
  const matches = useMediaQuery("(max-width:960px)");
  const classes = useSidebarStyles();
  return (
    <Sidebar
      classes={classes}
      {...props}
      variant={matches ? "temporary" : "permanent"}
    />
  );
};

const MyLayout = (props) => {
  return <Layout {...props} appBar={MyAppbar} sidebar={MySidebar} />;
};

export default MyLayout;
