import { Box, Divider, Typography } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from "react-admin";

// HR COMPONENT
const Hr = () => {
  return <Divider light style={{ marginBottom: "20px" }} />;
};

const Areas = (props) => {
  const translate = useTranslate();
  const { choices } = props;
  return (
    <div style={{ marginTop: "15px" }}>
      <Typography variant="h6" gutterBottom>
        {translate("resources.projects.typographies.areasglobal")}
      </Typography>
      <Hr />
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <NumberInput
          source="gardenArea"
          resource="projects"
          min={0}
          step={0.1}
          fullWidth
        />
      </Box>

      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <ArrayInput
            source="areas"
            resource="projects"
            className="arrayInput-product"
          >
            <SimpleFormIterator>
              <SelectInput
                source="areas.type"
                resettable
                resource="projects"
                choices={choices.areasType}
              />
              <NumberInput
                source="areas.number"
                resource="projects"
                min={0}
                step={1}
              />
              <NumberInput
                source="areas.area"
                resource="projects"
                min={0}
                step={0.1}
              />
              <SelectInput
                source="areas.orientations"
                resettable
                resource="projects"
                choices={choices.orientations}
              />
              <SelectInput
                source="areas.flooring"
                resettable
                resource="projects"
                choices={choices.areasFlooring}
              />
              <NumberInput
                source="areas.floor"
                resource="projects"
                min={0}
                step={1}
              />
              <TextInput source="areas.comment" resource="projects" />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      </Box>
    </div>
  );
};

export default Areas;
