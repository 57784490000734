import styled from "styled-components";
import { colorPrimary } from "../../../GlobalStyles";

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;
export const FormInner = styled.div`
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 10px;
  transition: all 0.5s ease;
`;

export const SectionContainer = styled.div`
  margin-top: 1.5rem;
`;

export const TitleForm = styled.h2`
  text-align: center;
  color: #34495e;
  font-size: clamp(12px, 1.5rem, 20px);
  max-width: 400px;
  margin: 0 auto 2rem auto;
  font-weight: 500;
  text-transform: uppercase;
`;
export const TitleSection = styled.p`
  text-align: ${(props) => (props.align ? "center" : "left")};
  color: ${(props) => (props.color ? colorPrimary : "#34495e")};
  font-size: ${(props) => (props.size ? "1.8rem" : "clamp(12px, 1rem, 20px)")};
  max-width: ${(props) => (props.maxWidth ? "unset" : "400px")};
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
  margin: ${(props) => (props.margin ? "auto" : "initial")};
  z-index: 1999;
`;
export const FormGroup = styled.div`
  position: relative;
  margin-top: ${({ small }) => (small ? "0" : "1.5rem")};
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: ${(props) => props.height};
  padding: 0.375rem 0.75rem;
  padding-right: ${(props) => props.paddingRight};
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  transition: all 0.5s ease;
`;
export const SendButton = styled.button`
  display: block;
  color: white;
  width: 100%;
  background-color: ${colorPrimary};
  border: 1px solid transparent;
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 600;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
`;

export const MoreIcon = styled.svg`
  color: ${(props) => props.colored};
  cursor: pointer;
`;
export const SpanText = styled.span`
  margin-left: 10px;
  color: #34495e;
`;

export const ActionContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
`;
export const Button = styled.button`
  display: block;
  color: ${(props) => (props.typeOfButton ? "white" : "#34495e")};
  min-width: 160px;
  max-width: 220px;
  background-color: ${(props) =>
    props.typeOfButton ? colorPrimary : "transparent"};
  border: 1px solid transparent;
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-weight: 600;

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
`;
