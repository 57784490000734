/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import {
  Container,
  BillingContainer,
  TitleBilling,
  BillingValueContainer,
  BillingElement,
  TitleElement,
  Hr,
  PriceElement,
} from "../../Utils/Styles/ItemManager.styles";
import { getAboPrice } from "../features";

const ModuleBilling = (props) => {
  const translate = useTranslate();
  const [totalAbo, setTotalAbo] = useState(0);
  const [total, setTotal] = useState(0);
  const [userSupp, setUserSupp] = useState(0);
  const [mandatsSupp, setMandatsSupp] = useState(0);
  const [screenSupp, setScreenSupp] = useState(0);

  useEffect(() => {
    if (props.name !== "ads") {
      setTotalAbo(getAboPrice(props.name));
    } else {
      setTotalAbo(getAboPrice(props.subtype));
      setTotal(getAboPrice(props.subtype));
    }
    if (props.state[props.name] && props.state[props.name].total) {
      setTotal(props.state[props.name].total);
    } else {
      setTotal(0);
    }
    setUserSupp(props.state.usersupp * 5);
    setMandatsSupp(props.state.mandatsupp * 0.45);
    setScreenSupp(props.state.screensupp * 10);
  }, [props.name, props.state, props.subtype]);

  return (
    <Container>
      <BillingContainer>
        <TitleBilling>
          {translate("resources.agencies.fields.summary")}
        </TitleBilling>
        <BillingValueContainer>
          <BillingElement>
            <TitleElement>
              Visibble {props.name === "ads" ? "360" : props.name.toUpperCase()}{" "}
              {props.name === "ads" && props.state.ads.subtype
                ? " - " + props.state.ads.subtype.toUpperCase()
                : null}
            </TitleElement>
            <Hr />
            <PriceElement>{totalAbo} €</PriceElement>
          </BillingElement>
          {props.state.usersupp !== 0 && props.name !== "display" && (
            <BillingElement>
              <TitleElement>
                {translate("resources.agencies.fields.numberofuser.shortbis")} -{" "}
                {props.state.usersupp}
              </TitleElement>
              <Hr />
              <PriceElement>{props.state.usersupp * 5} €</PriceElement>
            </BillingElement>
          )}
          {props.state.screensupp !== 0 && props.name === "display" && (
            <BillingElement>
              <TitleElement>
                {translate("resources.agencies.fields.nomberofscreen.short")} -{" "}
                {props.state.screensupp}
              </TitleElement>
              <Hr />
              <PriceElement>{props.state.screensupp * 10} €</PriceElement>
            </BillingElement>
          )}
          {props.state.mandatsupp !== 0 && props.name !== "display" && (
            <BillingElement>
              <TitleElement>
                {translate(
                  "resources.agencies.fields.numberofmandats.shortbis"
                )}{" "}
                - {props.state.mandatsupp}
              </TitleElement>
              <Hr />
              <PriceElement>{props.state.mandatsupp * 0.45} €</PriceElement>
            </BillingElement>
          )}

          <BillingElement minheight={"50px"} bottom="true">
            <TitleElement absolute="true" weight="true">
              {translate("resources.agencies.subtotal")}
            </TitleElement>
            <PriceElement>
              {props.name === "ads" && total + userSupp + mandatsSupp}
              {props.name === "print" && total + userSupp + mandatsSupp}
              {props.name === "data" && total + userSupp}
              {props.name === "display" && total + screenSupp} €
            </PriceElement>
            <TitleElement absolute="true" top={"32px"}>
              {translate("resources.agencies.tva")} {"20%"}
            </TitleElement>
            <PriceElement top={"32px"}>
              {props.name === "ads" &&
                (
                  (total + userSupp + mandatsSupp) * 1.2 -
                  (total + userSupp + mandatsSupp)
                ).toFixed(2)}
              {props.name === "data" &&
                ((total + userSupp) * 1.2 - (total + userSupp)).toFixed(2)}
              {props.name === "print" &&
                (
                  (total + userSupp + mandatsSupp) * 1.2 -
                  (total + userSupp + mandatsSupp)
                ).toFixed(2)}
              {props.name === "display" &&
                (
                  (total + userSupp + screenSupp) * 1.2 -
                  (total + userSupp + screenSupp)
                ).toFixed(2)}{" "}
              €
            </PriceElement>
          </BillingElement>
          <BillingElement
            minheight={"26px"}
            background="true"
            width={"97%"}
            marginleft={"auto"}
          >
            <TitleElement absolute="true" weight="true" top={"3px"}>
              {translate("resources.agencies.total")}
            </TitleElement>

            <PriceElement weight="true" top={"3px"}>
              {props.name === "ads" &&
                ((total + userSupp + mandatsSupp) * 1.2).toFixed(2)}
              {props.name === "data" && ((total + userSupp) * 1.2).toFixed(2)}
              {props.name === "print" &&
                ((total + userSupp + mandatsSupp) * 1.2).toFixed(2)}
              {props.name === "display" &&
                ((total + screenSupp) * 1.2).toFixed(2)}{" "}
              €
            </PriceElement>
          </BillingElement>
        </BillingValueContainer>
      </BillingContainer>
    </Container>
  );
};

export default ModuleBilling;
