import React from "react";

const Orientation = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40.967 41.509">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_559"
            data-name="Rectangle 559"
            width={"40.966"}
            height="41.509"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Groupe_1707" data-name="Groupe 1707" transform="translate(4 0)">
        <g
          id="Groupe_1706"
          data-name="Groupe 1706"
          transform="translate(-4 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Tracé_2936"
            data-name="Tracé 2936"
            d="M195.016,442.437h.41a.521.521,0,0,0-.41,0"
            transform="translate(-177.222 -402.029)"
            fill={color}
          />
          <path
            id="Tracé_2937"
            data-name="Tracé 2937"
            d="M392.175,252.837v-.41a.524.524,0,0,0,0,.41"
            transform="translate(-356.353 -229.395)"
            fill={color}
          />
          <path
            id="Tracé_2938"
            data-name="Tracé 2938"
            d="M202.5,442.438h.341a.369.369,0,0,0-.341,0"
            transform="translate(-184.023 -402.03)"
            fill={color}
          />
          <path
            id="Tracé_2939"
            data-name="Tracé 2939"
            d="M210.175,442.462c-.064.007-.135-.02-.191.035h.2a.294.294,0,0,1,0-.035h-.016"
            transform="translate(-190.824 -402.089)"
            fill={color}
          />
          <path
            id="Tracé_2940"
            data-name="Tracé 2940"
            d="M189.771,442.461h-.016a.346.346,0,0,1,0,.035h.2c-.056-.055-.127-.028-.191-.035"
            transform="translate(-172.441 -402.089)"
            fill={color}
          />
          <path
            id="Tracé_2941"
            data-name="Tracé 2941"
            d="M443.938.036h.008c0-.012,0-.023,0-.035h-.2c.058.058.132.027.2.035"
            transform="translate(-403.251 -0.001)"
            fill={color}
          />
          <path
            id="Tracé_2942"
            data-name="Tracé 2942"
            d="M392.243,242.8v-.137l-.035,0c0,.049-.008.1.035.139"
            transform="translate(-356.421 -220.519)"
            fill={color}
          />
          <path
            id="Tracé_2943"
            data-name="Tracé 2943"
            d="M392.206,241.16c0,.039,0,.077,0,.115.012,0,.024,0,.035.021v-.137l-.035,0Z"
            transform="translate(-356.42 -219.154)"
            fill={color}
          />
          <path
            id="Tracé_2944"
            data-name="Tracé 2944"
            d="M391.971,242.458v-.007a.044.044,0,0,0-.024.018h.024s0-.008,0-.012"
            transform="translate(-356.184 -220.329)"
            fill={color}
          />
          <path
            id="Tracé_2945"
            data-name="Tracé 2945"
            d="M392.206,239.678c0,.034,0,.068,0,.1.012,0,.024,0,.035.02v-.137l-.035,0c0,.006,0,.011,0,.017"
            transform="translate(-356.42 -217.793)"
            fill={color}
          />
          <path
            id="Tracé_2946"
            data-name="Tracé 2946"
            d="M391.992,240.958a.045.045,0,0,0-.022.017h.022v-.018"
            transform="translate(-356.205 -218.972)"
            fill={color}
          />
          <path
            id="Tracé_2947"
            data-name="Tracé 2947"
            d="M392.209,238.3c.012,0,.024,0,.035.021v-.137a.139.139,0,0,0-.035.116"
            transform="translate(-356.422 -216.449)"
            fill={color}
          />
          <path
            id="Tracé_2948"
            data-name="Tracé 2948"
            d="M391.983,239.456a.045.045,0,0,0-.023.017h.023c0-.006,0-.012,0-.019"
            transform="translate(-356.196 -217.607)"
            fill={color}
          />
          <path
            id="Tracé_2949"
            data-name="Tracé 2949"
            d="M392.208,259.314l.035,0v-.137c-.043.039-.034.09-.035.139"
            transform="translate(-356.421 -235.527)"
            fill={color}
          />
          <path
            id="Tracé_2950"
            data-name="Tracé 2950"
            d="M392.206,260.694v.107s0,.007,0,.011l.035,0v-.137c-.011.019-.023.024-.035.02"
            transform="translate(-356.42 -236.889)"
            fill={color}
          />
          <path
            id="Tracé_2951"
            data-name="Tracé 2951"
            d="M391.992,260.706s0,0,0-.007h-.022a.046.046,0,0,0,.022.017v-.011"
            transform="translate(-356.205 -236.912)"
            fill={color}
          />
          <path
            id="Tracé_2952"
            data-name="Tracé 2952"
            d="M392.209,262.195a.138.138,0,0,0,.035.116v-.137c-.011.018-.023.024-.035.021"
            transform="translate(-356.422 -238.252)"
            fill={color}
          />
          <path
            id="Tracé_2953"
            data-name="Tracé 2953"
            d="M391.98,262.2h-.023a.045.045,0,0,0,.023.017c0-.006,0-.012,0-.019"
            transform="translate(-356.193 -238.271)"
            fill={color}
          />
          <path
            id="Tracé_2954"
            data-name="Tracé 2954"
            d="M447.617,0h-.137c0,.012,0,.023,0,.035.047,0,.1.007.135-.035"
            transform="translate(-406.65)"
            fill={color}
          />
          <path
            id="Tracé_2955"
            data-name="Tracé 2955"
            d="M446.008.036h.089a.017.017,0,0,1,0-.015c.007-.014.015-.021.023-.021h-.137c.019.011.027.023.024.035"
            transform="translate(-405.291 -0.001)"
            fill={color}
          />
          <path
            id="Tracé_2956"
            data-name="Tracé 2956"
            d="M446.024.392a.035.035,0,0,0,0,0H446c0,.009,0,.018,0,.026s.014-.007.02-.022"
            transform="translate(-405.308 -0.352)"
            fill={color}
          />
          <path
            id="Tracé_2957"
            data-name="Tracé 2957"
            d="M447.231.382h-.005a.049.049,0,0,0,.027.023c0-.008,0-.016,0-.023h-.02"
            transform="translate(-406.419 -0.346)"
            fill={color}
          />
          <path
            id="Tracé_2958"
            data-name="Tracé 2958"
            d="M188.394,442.471a.135.135,0,0,0-.114.035h.137c-.02-.011-.026-.023-.023-.035"
            transform="translate(-171.101 -402.098)"
            fill={color}
          />
          <path
            id="Tracé_2959"
            data-name="Tracé 2959"
            d="M189.526,442.227s0,0,0,0h.021c0-.008,0-.016,0-.024s-.013.007-.019.021"
            transform="translate(-172.233 -401.857)"
            fill={color}
          />
          <path
            id="Tracé_2960"
            data-name="Tracé 2960"
            d="M428.889,0h-.137c0,.012,0,.023,0,.035.049,0,.1.007.138-.035"
            transform="translate(-389.63)"
            fill={color}
          />
          <path
            id="Tracé_2961"
            data-name="Tracé 2961"
            d="M427.254.036h.113a.02.02,0,0,1,0-.017c.007-.012.015-.019.022-.019h-.137c0,.012,0,.023,0,.035Z"
            transform="translate(-388.267 -0.001)"
            fill={color}
          />
          <path
            id="Tracé_2962"
            data-name="Tracé 2962"
            d="M428.523.384h-.009a.049.049,0,0,0,.02.024h0c0-.008,0-.016,0-.024h-.013"
            transform="translate(-389.415 -0.349)"
            fill={color}
          />
          <path
            id="Tracé_2963"
            data-name="Tracé 2963"
            d="M425.773.035h.1a.019.019,0,0,1,0-.017c.007-.012.014-.018.021-.018h-.137c0,.012,0,.023,0,.035h.017"
            transform="translate(-386.908)"
            fill={color}
          />
          <path
            id="Tracé_2964"
            data-name="Tracé 2964"
            d="M427.027.387a.049.049,0,0,0,.019.022c0-.007,0-.015,0-.022h-.02"
            transform="translate(-388.063 -0.352)"
            fill={color}
          />
          <path
            id="Tracé_2965"
            data-name="Tracé 2965"
            d="M424.4.035a.019.019,0,0,1,0-.017c.007-.012.014-.018.022-.018h-.137a.136.136,0,0,0,.114.035"
            transform="translate(-385.568 0)"
            fill={color}
          />
          <path
            id="Tracé_2966"
            data-name="Tracé 2966"
            d="M425.531.382A.047.047,0,0,0,425.55.4c0-.008,0-.015,0-.023h-.021"
            transform="translate(-386.704 -0.347)"
            fill={color}
          />
          <path
            id="Tracé_2967"
            data-name="Tracé 2967"
            d="M212.252,442.471a.02.02,0,0,1,0,.016c-.007.012-.015.019-.022.019h.137a.135.135,0,0,0-.114-.035"
            transform="translate(-192.864 -402.098)"
            fill={color}
          />
          <path
            id="Tracé_2968"
            data-name="Tracé 2968"
            d="M212.273,442.231a.045.045,0,0,0-.019-.024c0,.008,0,.016,0,.024h.021"
            transform="translate(-192.885 -401.858)"
            fill={color}
          />
          <path
            id="Tracé_2969"
            data-name="Tracé 2969"
            d="M125.052,158.821q-5,2.862-9.992,5.723a1.146,1.146,0,0,0-.442.452q-2.489,4.371-4.986,8.737c-.259.454-.515.91-.775,1.364a.289.289,0,0,0,.015.376.277.277,0,0,0,.373.021l.148-.084q5.01-2.872,10.02-5.742a.847.847,0,0,0,.33-.33q2.882-5.05,5.768-10.1a.5.5,0,0,0,.107-.268c-.006-.288-.231-.344-.566-.152m-7.874,9.994a1.706,1.706,0,0,1-1.682-1.706,1.686,1.686,0,0,1,1.71-1.7,1.7,1.7,0,0,1,1.691,1.7,1.728,1.728,0,0,1-1.72,1.712"
            transform="translate(-98.868 -144.227)"
            fill={color}
          />
          <path
            id="Tracé_2970"
            data-name="Tracé 2970"
            d="M198,112.6a.256.256,0,0,0,.287.267.252.252,0,0,0,.253-.267c.006-.34.006-.681,0-1.021a.247.247,0,0,0-.251-.266.255.255,0,0,0-.287.265c-.01.158,0,.317,0,.476,0,.181-.006.363,0,.544"
            transform="translate(-179.93 -101.16)"
            fill={color}
          />
          <path
            id="Tracé_2971"
            data-name="Tracé 2971"
            d="M62.938,247.922c-.169-.01-.34,0-.509,0s-.363-.01-.543,0a.249.249,0,0,0-.249.268.243.243,0,0,0,.249.267q.526.017,1.053,0a.247.247,0,0,0,.25-.268.252.252,0,0,0-.25-.269"
            transform="translate(-56.012 -225.296)"
            fill={color}
          />
          <path
            id="Tracé_2972"
            data-name="Tracé 2972"
            d="M198.28,373.5a.257.257,0,0,0-.278.277c-.005.329,0,.658,0,.987a.255.255,0,0,0,.265.289c.2,0,.278-.123.28-.309,0-.159,0-.318,0-.476s0-.318,0-.476a.261.261,0,0,0-.265-.291"
            transform="translate(-179.932 -339.424)"
            fill={color}
          />
          <path
            id="Tracé_2973"
            data-name="Tracé 2973"
            d="M323.606,248.461c.351.01.7.011,1.052,0a.244.244,0,0,0,.248-.268.247.247,0,0,0-.249-.268c-.169-.011-.339,0-.509,0s-.363-.009-.543,0a.253.253,0,0,0-.251.269.248.248,0,0,0,.251.267"
            transform="translate(-293.851 -225.297)"
            fill={color}
          />
          <path
            id="Tracé_2974"
            data-name="Tracé 2974"
            d="M18.477,49.927A18.477,18.477,0,1,0,36.954,68.4,18.477,18.477,0,0,0,18.477,49.927m0,35.053A16.576,16.576,0,1,1,35.053,68.4,16.576,16.576,0,0,1,18.477,84.98"
            transform="translate(0 -45.371)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default Orientation;
