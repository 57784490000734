import styled from "styled-components";

export const SelectContainer = styled.div`
  && {
    display: ${({ click }) => (click ? "block" : "none")};
    max-height: 300px;
    .MuiListItem-root {
      margin-top: 0.7rem;
      padding-left: 16px;
    }
  }
`;
export const SelectContainerAgency = styled.div`
  && {
    display: ${({ click }) => (click ? "block" : "none")};
    max-height: 300px;
    overflow-y: scroll;
    .MuiListItem-root {
      margin-top: 0.7rem;
      padding-left: 16px;
    }
  }
`;

export const FilterListContainer = styled.div`
  && {
    display: ${({ click }) => (click ? "block" : "none")};
    max-height: 300px;
    cursor: pointer;
    .MuiListItem-root {
      margin-top: 0.7rem;
      padding-left: 16px;
    }
  }
`;
export const FilterListContainerAgency = styled.div`
  && {
    display: ${({ click }) => (click ? "block" : "none")};
    max-height: 300px;
    overflow: scroll;
    cursor: pointer;
    .MuiListItem-root {
      margin-top: 0.7rem;
      padding-left: 16px;
    }
  }
`;
