import React from "react";
import QRCode from "react-qr-code";
import {
  AlurContainer,
  CatTypeContainer,
  CatTypeText,
  Container,
  DpeGesContainer,
  Exclu,
  ExcluContainer,
  IconContainer,
  IconsContainer,
  IconText,
  InnerContainer,
  LogoAgence,
  LogoContainer,
  PriceContainer,
  QrcodeContainer,
  RefContainer,
  RefText,
  Subtitle,
  SubtitleContainer,
} from "./styles";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import ModulePrice from "../../Modules/ModulePrice";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../Modules/ModulesElements.styles";

const SydneyTemplateLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const excludedValue = ["", undefined, null, false];

  const {
    imageFirst,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    colorSubTitle,
    subTitleText,
    agency,
    colorDesc,
    colorSecondary,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    qrcode,
    qrcodeMode,
    titleTextFont,
    subTitleTextFont,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <InnerContainer imageFirst={imageFirst}>
        {isBanner === "true" && (
          <ExcluContainer colorPrimary={color.primary || "black"}>
            <Exclu
              size={bannerFont.size}
              weight={bannerFont.weight}
              family={bannerFont.font}
              color={bannerFont.color}
            >
              {banner}
            </Exclu>
          </ExcluContainer>
        )}
        <SubtitleContainer color={"white"}>
          <Subtitle
            color={colorSubTitle || "black"}
            size={subTitleTextFont.size}
            weight={subTitleTextFont.weight}
            family={subTitleTextFont.font}
          >
            {subTitleText}
          </Subtitle>
        </SubtitleContainer>
        <CatTypeContainer color={color.primary || "black"}>
          <CatTypeText
            color={!excludedValue.includes(colorTitle) ? colorTitle : "white"}
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            {t(
              choices.category.filter((item) => item.id === project.category)[0]
                .name
            ).toUpperCase()}
            {" - "}
            {project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </CatTypeText>
        </CatTypeContainer>
        <PriceContainer color={"white"}>
          <ModulePrice
            colorPrice={colorPrice || "black"}
            price={price}
            project={project}
            align={"left"}
            sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "32pt"}
            weight={priceFont.weight}
            family={priceFont.font}
            sizeHonoraire={"11pt"}
            sizeSpanPrice={"12pt"}
          />
        </PriceContainer>
        <RenderContext project={project}>
          <DpeGesContainer color={"#ffffffbf"}>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                top:
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "40px"
                    : "20px",
                left:
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "70px"
                    : "25px",
              }}
            >
              <Dpe
                letter={
                  project.dpeLetter
                    ? DpeGesNoValue.includes(project.dpeLetter)
                      ? "vi"
                      : project.dpeLetter
                    : "vi"
                }
                conso={project.dpeValue}
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "0.22"
                    : "0.25"
                }
                topTitle={"logement extrêmement performant"}
                bottomTitle={"logement extrêmement peu performant"}
                redInfoTop={"passoire"}
                redInfoBottom={"énergetique"}
                label={"consommation"}
                secondLabel={"émissions"}
                subLabel={"(énergie primaire)"}
                subValue={"kWh/m2/an"}
                secondSubValue={"kg CO₂/m²/an"}
                theme={"light"}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                bottom:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "25px"
                    : "-30px",
                left:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "365px"
                    : "360px",
              }}
            >
              <Ges
                letter={
                  project.gesLetter
                    ? DpeGesNoValue.includes(project.gesLetter)
                      ? "vi"
                      : project.gesLetter
                    : "vi"
                }
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "0.50"
                    : "0.30"
                }
                theme={"light"}
              />
            </div>
          </DpeGesContainer>
        </RenderContext>

        <RefContainer
          color={
            !excludedValue.includes(colorSecondary) ? colorSecondary : "white"
          }
        >
          <RefText
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            Ref: {refText}
          </RefText>
        </RefContainer>
        <IconsContainer color={color.primary || "black"}>
          <IconContainer>
            <Surface color={colorSecondary || "white"} size={38} />
            <IconText
              colorSecondary={colorSecondary || "white"}
              marginLeft="10pt"
            >
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer>
              <Rooms color={colorSecondary || "white"} size={38} />
              <IconText
                colorSecondary={colorSecondary || "white"}
                marginLeft="10pt"
              >
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer>
              <Chambres color={colorSecondary || "white"} size={38} />
              <IconText
                colorSecondary={colorSecondary || "white"}
                marginLeft="10pt"
              >
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer marginLeft="10pt">
              <Sdb color={colorSecondary || "white"} size={38} />
              <IconText
                colorSecondary={colorSecondary || "white"}
                marginLeft="15pt"
              >
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </IconsContainer>
      </InnerContainer>
      <RenderContext project={project}>
        <AlurContainer color={"white"}>
          <ModuleAlurDetails
            project={project}
            colorDesc={colorDesc || "black"}
            price={price}
            sizeText={"12pt"}
          />
          <AlurText sizeText={"12pt"} colorDesc={colorDesc || "black"}>
            Les informations sur les risques auxquels ce bien est exposé sont
            disponibles sur le site Géorisques : http://www.georisques.gouv.fr
          </AlurText>
        </AlurContainer>
      </RenderContext>
      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <QrcodeContainer>
          <QRCode value={qrcodeEdit} size={130} />
        </QrcodeContainer>
      )}
      <LogoContainer>
        {agency.images && agency.images.length ? (
          <LogoAgence src={agency.images[0].src} alt="logo agence" />
        ) : null}
      </LogoContainer>
    </Container>
  );
});

export default SydneyTemplateLandscape;
