import { createTheme, makeStyles } from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const ModalManager = makeStyles((min) => ({
  root: {
    "& .MuiDialog-paper": {
      maxWidth: "100%",
      minWidth: "540px",
      margin: "0",
      padding: "1.5rem",
      borderRadius: "0",
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
        padding: "0",
      },
    },
    "& .MuiDialogTitle-root": {
      textAlign: "center",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
    },
    "& .MuiDialog-paperScrollPaper": {
      minHeight: "100%",
    },
    "& .MuiDialog-scrollPaper": {
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
  },
}));
