import { Box } from "@material-ui/core";
import React from "react";
import {
  ArrayInput,
  FileField,
  FileInput,
  ImageField,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

const Medias = (props) => {
  return (
    <>
      {props.ads && (
        <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
          <TextInput source="url" resource="projects" fullWidth />
        </Box>
      )}
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FileInput
            source="images"
            resource="projects"
            accept="image/*"
            multiple
          >
            <ImageField source="src" title="title" />
          </FileInput>
        </Box>
      </Box>

      {props.ads && (
        <>
          <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <FileInput source="medias" resource="projects" multiple>
                <FileField source="src" title="name" />
              </FileInput>
            </Box>
          </Box>
          <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <ArrayInput source="mediasurl" resource="projects">
                <SimpleFormIterator>
                  <TextInput
                    source="mediasurl.source"
                    resource="projects"
                    style={{ marginTop: "25px" }}
                  />
                  <SelectInput
                    source="mediasurl.type"
                    resource="projects"
                    choices={props.choices.mediasType}
                    defaultValue={1}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Medias;
