import React, { useContext } from "react";
import { CardContent } from "@material-ui/core";
import {
  Filter,
  FilterList,
  FilterListItem,
  SearchInput,
  useTranslate,
} from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {
  endOfYesterday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";

import { Card } from "./Styles/Contact.mui.styles";
import { UiContext } from "../../../../store/UserProvider";

const LastCreatedFilter = () => {
  const translate = useTranslate();
  return (
    <FilterList
      label={translate("resources.fields.createdAt")}
      icon={<AccessTimeIcon />}
    >
      <FilterListItem
        label={translate("resources.date.today")}
        value={{
          createdAt_$gte: endOfYesterday().toISOString(),
          createdAt_$lte: undefined,
        }}
      />
      <FilterListItem
        label={translate("resources.date.thisWeek")}
        value={{
          createdAt_$gte: startOfWeek(new Date()).toISOString(),
          createdAt_$lte: undefined,
        }}
      />
      <FilterListItem
        label={translate("resources.date.lastWeek")}
        value={{
          createdAt_$gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
          createdAt_$lte: startOfWeek(new Date()).toISOString(),
        }}
      />
      <FilterListItem
        label={translate("resources.date.thisMonth")}
        value={{
          createdAt_$gte: startOfMonth(new Date()).toISOString(),
          createdAt_$lte: undefined,
        }}
      />
      <FilterListItem
        label={translate("resources.date.lastMonth")}
        value={{
          createdAt_$gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
          createdAt_$lte: startOfMonth(new Date()).toISOString(),
        }}
      />
      <FilterListItem
        label={translate("resources.date.earlier")}
        value={{
          createdAt_$gte: undefined,
          createdAt_$lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        }}
      />
    </FilterList>
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="firstname-lastname-email_$like" alwaysOn />
  </Filter>
);

const FilterSidebar = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { ui } = useContext(UiContext);
  return (
    <>
      {ui && ui.sideBarVisibble && (
        <Card>
          <CardContent>
            <SearchFilter />
            <LastCreatedFilter />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default FilterSidebar;
