import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";
import Address from "./BillingItems/Address";
import BillingMethod from "./BillingItems/BillingMethod";

const BillingInformation = (props) => {
  const translate = useTranslate();

  return (
    <Box flex={2} mr={{ md: 0, lg: "1em" }} mb="20px">
      <Typography variant="h6" gutterBottom>
        {translate("resources.agencies.billingInformation")}
      </Typography>
      <Divider light style={{ marginBottom: "30px" }} />

      <Box display={{ xs: "block", sm: "flex" }}>
        <Address
          {...props}
          edit={true}
          minHeight={"165px"}
          smallPadding={false}
          noBorder
          boxShadow
        />
        <BillingMethod
          {...props}
          edit={true}
          minHeight={"165px"}
          smallPadding={false}
          noBorder
          boxShadow
        />
      </Box>
    </Box>
  );
};

export default BillingInformation;
