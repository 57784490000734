import React from "react";
import SapeneLandscape from "./Landscape/SapeneLandscape";

const index = React.forwardRef(
  ({ isPreview, isMini, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <SapeneLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
