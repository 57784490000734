import styled from "styled-components";

export const ContentContainer = styled.div``;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.flex ? "row" : "column")};
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  max-width: 500px;
`;
