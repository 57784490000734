import React from "react";
import { useTranslate } from "react-admin";
import {
  TitleNumberOfUser,
  Container,
  ValueContainer,
  NumberValueContainer,
  ValueText,
} from "../../../../../../Utils/Styles/ItemManager.styles";

const ModuleSelect = (props) => {
  const translate = useTranslate();

  const handleMode = (mode) => {
    props.setMode(mode);
  };

  return (
    <Container>
      <NumberValueContainer>
        <TitleNumberOfUser>
          {translate("resources.fields.billingMethod")}
        </TitleNumberOfUser>
        <ValueContainer
          background={
            props.state.mode && props.state.mode === "debit" ? "true" : "false"
          }
          cursor="true"
          onClick={() => handleMode("debit")}
        >
          <ValueText
            mycolor={
              props.state.mode && props.state.mode === "debit"
                ? "true"
                : "false"
            }
          >
            {translate("resources.agencies.fields.debit").toUpperCase()}
          </ValueText>
        </ValueContainer>
      </NumberValueContainer>
      <NumberValueContainer>
        <TitleNumberOfUser></TitleNumberOfUser>
        <ValueContainer
          margin="true"
          background={
            props.state.mode && props.state.mode === "stripe" ? "true" : "false"
          }
          cursor="true"
          onClick={() => handleMode("stripe")}
        >
          <ValueText
            margin="true"
            mycolor={
              props.state.mode && props.state.mode === "stripe"
                ? "true"
                : "false"
            }
          >
            STRIPE
          </ValueText>
        </ValueContainer>
      </NumberValueContainer>
    </Container>
  );
};

export default ModuleSelect;
