import React, { useEffect, useState } from "react";
import { Fade, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslate } from "react-admin";
import {
  Container,
  LandscapeImage,
  PortraitImage,
  LandscapeImageA3,
  PortraitImageA3,
  TextFormat,
  ContainerText,
  ContainerFormatA4,
  ContainerFormatA3,
  LandscapeContainerA4,
  PortraitContainerA4,
  LandscapeContainerA3,
  PortraitContainerA3,
  GlobalContainer,
  TextShop,
  Link,
} from "./StepFirst.styles";

import { Miniatures } from "../Miniatures";
import { useTranslation } from "react-i18next";
import { useTemplatesStore } from "../../../../store/TemplatesStore";

const StepFirst = () => {
  const {
    setOrientation,
    orientation,
    setModel,
    setFormat,
    format,
    setImageFirst,
    setImageSupp,
    setMirror,
  } = useTemplatesStore();
  const translate = useTranslate();
  const { i18n } = useTranslation();
  const matchesXs = useMediaQuery("(max-width:720px)");
  const [breakWidth, setBreakWidth] = useState(920);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    if (open) {
      setBreakWidth(1180);
    }
  }, [open]);

  const choiceOrientation = (choice, format) => {
    setOrientation(choice);
    setFormat(format);
    if (orientation !== choice) {
      setModel(false);
      setImageFirst(false);
      setImageSupp([]);
      setMirror(false);
    }
  };
  return (
    <Fade in={true} timeout={1200}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <GlobalContainer breakWidth={breakWidth}>
          <ContainerFormatA4 breakWidth={breakWidth}>
            <ContainerText>
              <TextFormat>A4</TextFormat>
            </ContainerText>
            <Container matchesXs={matchesXs}>
              <LandscapeContainerA4
                onClick={() => choiceOrientation("landscape", "A4")}
                isSelect={orientation}
                format={format}
                matchesXs={matchesXs}
              >
                <LandscapeImage src={Miniatures.londonLandscape} />
              </LandscapeContainerA4>
              <PortraitContainerA4
                onClick={() => choiceOrientation("portrait", "A4")}
                isSelect={orientation}
                format={format}
                matchesXs={matchesXs}
              >
                <PortraitImage src={Miniatures.londonPortrait} />
              </PortraitContainerA4>
            </Container>
          </ContainerFormatA4>
          <ContainerFormatA3 breakWidth={breakWidth}>
            <ContainerText>
              <TextFormat>A3</TextFormat>
            </ContainerText>
            <Container matchesXs={matchesXs}>
              <LandscapeContainerA3
                onClick={() => choiceOrientation("landscape", "A3")}
                isSelect={orientation}
                format={format}
                matchesXs={matchesXs}
              >
                <LandscapeImageA3 src={Miniatures.londonLandscape} />
              </LandscapeContainerA3>
              <PortraitContainerA3
                onClick={() => choiceOrientation("portrait", "A3")}
                isSelect={orientation}
                format={format}
                matchesXs={matchesXs}
              >
                <PortraitImageA3 src={Miniatures.londonPortrait} />
              </PortraitContainerA3>
            </Container>
          </ContainerFormatA3>
        </GlobalContainer>
        {i18n.language !== "de-DE" && (
          <>
            <TextShop marginBottom={"1rem"} green>
              {translate("resources.modalprint.VMShop.text1")}
            </TextShop>
            <TextShop marginBottom={"0"}>
              {translate("resources.modalprint.VMShop.text2")}
            </TextShop>
            <TextShop>
              {translate("resources.modalprint.VMShop.text3")}{" "}
              <Link
                href="https://www.vitrinemedia.com/fr/fr/prints"
                target="_blank"
                rel="noreferrer"
              >
                VMShop
              </Link>
            </TextShop>
          </>
        )}
      </div>
    </Fade>
  );
};

export default StepFirst;
