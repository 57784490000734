import { Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import PdfPreview from "../PdfPreview";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "0.5rem",
      maxHeight: "80vh",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "630px",
    },
  },
}));

const ModalPdf = (props) => {
  const classes = useStyles();
  const cancel = () => {
    props.setModal(false);
  };

  return (
    <div>
      <Dialog
        open={props.modal}
        fullWidth
        onClose={cancel}
        className={classes.root}
      >
        <DialogContent>
          <PdfPreview
            informations={props.informations}
            customer={props.customer}
            secondCustomer={props.secondCustomer}
            infoProject={props.infoProject}
            agency={props.agency}
            codeVerify={props.codeVerify}
            row={props.row}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalPdf;
