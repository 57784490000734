import { Box } from "@material-ui/core";
import React from "react";
import { BooleanInput, FileField, FileInput } from "react-admin";

const Files = (props) => {
  return (
    <>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <FileInput
            source="files"
            resource="projects"
            multiple
            accept="application/pdf"
          >
            <FileField source="src" title="name" />
          </FileInput>
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <BooleanInput source="secureFiles" resource="projects" />
        </Box>
      </Box>
    </>
  );
};

export default Files;
