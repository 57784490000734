import * as React from "react";
import { Route } from "react-router-dom";
import profile from "../Profile";
import Print from "../../../pages/Print/PrintPage/Print";

const MyRoutes = [
  <Route key="my-profile" path="/profile" component={profile.edit} />,
  <Route exact key="print" path="/print/:id" component={Print} />,
];

export default MyRoutes;
