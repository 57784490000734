import React, { useState, useEffect } from "react";
import {
  ContainerProjectList,
  ContainerList,
} from "./Utils/Styles/ProjectList.styles";

import Modal from "./Components/ProjectListComponents/Modal";
import "./Utils/Styles/projectList.css";
import ModalToken from "./Components/ProjectListComponents/ModalToken";
import ListWrapper from "./Components/ProjectListComponents/ListWrapper";
import SideBarFilter from "./Components/ProjectListComponents/FilterSideBar/SideBarFilter";
import Toolbar from "./Components/ProjectListComponents/Toolbar/Toolbar";
import ListView from "./Components/ProjectListComponents/List/ListView";
import { useMediaQuery } from "@material-ui/core";

const ProjectList = ListWrapper((props) => {
  const { data, dataFilter, user, agency } = props;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [choiceImport, setChoiceImport] = useState([]);
  const [noRegisterToken, setNoRegisterToken] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [dataToShow, setDataToShow] = useState(data);
  const matches = useMediaQuery("(max-width:960px)");

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    if (dataFilter && isMounted) {
      setDataToShow(dataFilter);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      if (isMounted) {
        setDataToShow(data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [data, dataFilter]);

  return (
    <ContainerProjectList>
      <Toolbar
        importMandats={props.importMandats}
        setLoading={setLoading}
        loading={loading}
        setModal={setModal}
        setNoRegisterToken={setNoRegisterToken}
        refreshList={props.refreshList}
        setChoiceImport={setChoiceImport}
        setInvalidToken={setInvalidToken}
        data={data}
        dataToShow={dataToShow}
        user={user}
        changeView={props.changeView}
      />
      <ContainerList matches={matches}>
        <SideBarFilter
          onChange={props.onChange}
          handleFilterCategory={props.handleFilterCategory}
          resetFilter={props.resetFilter}
          matches={matches}
          loading={loading}
          data={dataToShow}
          isReset={props.isReset}
          user={user}
          agency={agency}
        />
        {modal ? (
          <Modal
            choiceImport={choiceImport}
            setChoiceImport={setChoiceImport}
            importMandats={props.importMandats}
            setModal={setModal}
            refreshList={props.refreshList}
            modal={modal}
            setLoading={setLoading}
          />
        ) : noRegisterToken ? (
          <ModalToken
            setNoRegisterToken={setNoRegisterToken}
            setInvalidToken={setInvalidToken}
            setLoading={setLoading}
            refreshList={props.refreshList}
          />
        ) : invalidToken ? (
          <ModalToken
            setNoRegisterToken={setNoRegisterToken}
            setInvalidToken={setInvalidToken}
            setLoading={setLoading}
            refreshList={props.refreshList}
            message={"invalidToken"}
          />
        ) : (
          <ListView
            loading={loading}
            dataToShow={dataToShow}
            agency={agency}
            user={user}
          />
        )}
      </ContainerList>
    </ContainerProjectList>
  );
});

export default ProjectList;
