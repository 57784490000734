import React from "react";
import { getUsersData } from "../../../Utils/axiosRequest";

const UserFromAgencyWrapper = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    async componentDidMount() {
      const fetch = await getUsersData();
      const userFromAgence = fetch.data.filter((item) => {
        if (
          item.agenceId &&
          item.agenceId._id.toString() === this.props.id.toString()
        ) {
          return item;
        } else {
          return null;
        }
      });
      this.setState({ users: userFromAgence });
    }

    render() {
      if (!this.state.data && !this.state.users) {
        return null;
      } else {
        return (
          <WrappedComponent
            {...this.props}
            data={this.state.data}
            id={this.props.id}
            users={this.state.users}
            changeUserInCharge={this.changeUserInCharge}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
};

export default UserFromAgencyWrapper;
