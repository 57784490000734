import React from "react";
import { Toolbar, SaveButton, useTranslate, useRedirect } from "react-admin";
import axios from "axios";
import { config } from "../../../../../constants/constant";
import { useAlert } from "react-alert";

const transform = async (data) => {
  if (data.apiTokenName && data.apiTokenUsername) {
    let bodyFormData = new FormData();
    bodyFormData.append("username", data.apiTokenUsername);

    if (data.permissions && data.permissions.includes("admin") >= 1) {
      bodyFormData.append(
        "token",
        window.localStorage.getItem(config.ACCESS_TOKEN_NAME)
      );
    } else {
      bodyFormData.append("password", data.apiTokenPassword);
    }

    await axios({
      method: "POST",
      url: config.EXTERNAL_API[data.apiTokenName] + "/auth",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.data.user) {
          data.apiTokenValue = response.data.user.token;
          delete data.apiTokenUsername;
          delete data.apiTokenPassword;
        }
      })
      .catch(function (error) {});
  }
  return {
    ...data,
  };
};

const AgencyToolbar = ({ permissions, ...props }) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const alert = useAlert();
  const onSuccess = () => {
    alert.show(translate("resources.alert.changessaved"));
    redirect(null);
  };

  return (
    <Toolbar>
      <SaveButton
        transform={(data) =>
          transform({
            ...data,
            ...props.initialValues,
            permissions: permissions,
          })
        }
        submitOnEnter={false}
        disabled={props.enableSaveAddress === false ? false : props.pristine}
        handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        onSuccess={props.record && props.record.id ? onSuccess : null}
        redirect={props.record && !props.record.id ? "edit" : null}
      />
    </Toolbar>
  );
};
export default AgencyToolbar;
