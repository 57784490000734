import React from "react";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import ModulePrice from "../../ModulesElements/ModulePrice";
import {
  Container,
  Header,
  Footer,
  ContentContainer,
  CityContainer,
  CityText,
  ImagesContainer,
  Img,
  DescContainer,
  BlockDescContainer,
  Description,
  SecondTitle,
  HonoraryContainer,
  AlurText,
  CoproContainer,
  AlurContainer,
  PriceContainer,
  EnergyContainer,
  EnergyTextContainer,
  SoldBanner,
  SoldBannerText,
  CityTextSpan,
} from "./styles";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import RenderContext from "../../ModulesElements/RenderContext";
import { useState } from "react";
import { DpeGesNoValue } from "../../DpeGesNoValue";

const LoftAndHouseLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const excludedValue = ["", undefined, null];

  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    colorSecondary,
    imageSupp,
    colorTitle2,
    titleText2,
    areaFloor,
  } = props;

  const [currency] = useState(project.currency ? project.currency : "€");

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <Container
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      mirror={mirror}
      className={props.isPreview ? "" : "d-print-block d-none"}
      ref={ref}
    >
      <div
        style={{
          position: "absolute",
          height: "292mm",
          width: "100%",
          zIndex: "1000",
          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FTemplates%2FLoftAndHouse%2Fback-lh.jpg?alt=media&token=b3097352-6a4d-4bd3-b543-7c23236c6bbb)`,
        }}
      />
      <Header
        src={
          "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FTemplates%2FLoftAndHouse%2Flogo-loft-house.png?alt=media&token=c709b0bc-0c2d-4239-b156-4f5f8e31b80b"
        }
        colorPrimary={color.primary || "black"}
      />
      <Footer
        src={
          "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FTemplates%2FLoftAndHouse%2FLH-Flat%20monochrome.png?alt=media&token=ed37467b-d994-412d-8873-96b9f34c5bd4"
        }
        colorPrimary={color.primary || "black"}
      />
      <ContentContainer>
        <CityContainer>
          <CityText
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            <CityTextSpan
              style={{
                color: !excludedValue.includes(colorTitle)
                  ? colorTitle
                  : "black",
              }}
            >
              {project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </CityTextSpan>{" "}
            <CityTextSpan
              style={{
                color: !excludedValue.includes(colorSubTitle)
                  ? colorSubTitle
                  : "black",
              }}
            >
              {subTitleText}
            </CityTextSpan>
          </CityText>
        </CityContainer>
        <ImagesContainer>
          <Img
            src={imageFirst}
            brightness={brightness}
            saturation={saturation}
            colorPrimary={color.primary || "black"}
          />
          <Img
            src={imageSupp[0]}
            brightness={brightness < 110 ? brightness : brightness - 10}
            saturation={saturation}
            colorPrimary={color.primary || "black"}
          />
        </ImagesContainer>
        <DescContainer>
          <BlockDescContainer flex>
            <div
              style={{
                width: "100%",
                paddingRight: "2rem",
              }}
            >
              <SecondTitle colorTitle2={colorTitle2}>{titleText2}</SecondTitle>
              <Description
                colorDesc={colorDesc || "black"}
                size={descFont.size}
                weight={descFont.weight}
                family={descFont.font}
              >
                {desc.substr(0, 410)}
              </Description>

              <RenderContext project={project}>
                <AlurContainer>
                  <HonoraryContainer>
                    <AlurText>
                      ●{" "}
                      {project.type === choices.type[0].id
                        ? "Superficie loi carrez"
                        : "Superficie habitable"}
                      : {project.area.total} m2
                    </AlurText>
                    {areaFloor && areaFloor > 0 ? (
                      <AlurText>● Superficie au sol: {areaFloor} m2</AlurText>
                    ) : null}
                    {project.gardenArea && project.gardenArea > 0 ? (
                      <AlurText>
                        ● Superficie du terrain: {project.gardenArea} m2
                      </AlurText>
                    ) : null}
                    {project.category === choices.category[1].id ? (
                      <AlurText>
                        ● Honoraires :{" "}
                        {project.fees > 0
                          ? project.fees + " " + currency
                          : t("modalprint.undisclosed")}{" "}
                      </AlurText>
                    ) : null}
                    {(project.commission || project.sharedCommission) &&
                    project.category === choices.category[0].id ? (
                      <AlurText>
                        ● Honoraires :{" "}
                        {project.fees !== ""
                          ? project.fees +
                            (project.feespercent ? " %" : " " + currency)
                          : t("modalprint.undisclosed")}{" "}
                      </AlurText>
                    ) : null}
                    {(project.sharedCommission || project.commission) &&
                    project.category === choices.category[0].id &&
                    project.amountWithoutHonorary ? (
                      <AlurText>
                        ● Prix hors honoraires :{" "}
                        {getPrice(project.amountWithoutHonorary)} {currency}
                      </AlurText>
                    ) : null}
                  </HonoraryContainer>
                  <CoproContainer>
                    {project.nblotcopro > 0 ? (
                      <>
                        <AlurText>
                          ● {t("modalprint.copropriete")} {project.nblotcopro}{" "}
                          {t("modalprint.lots")}
                        </AlurText>
                        <AlurText>
                          ● {t("modalprint.feescopro")} :{" "}
                          {project.feescopro
                            ? project.feescopro + currency
                            : t("modalprint.undisclosed")}
                        </AlurText>
                        <AlurText>
                          ● Procédure en cours :{" "}
                          {project.procsyndicale ? "Oui" : "Non"}
                        </AlurText>
                      </>
                    ) : null}
                  </CoproContainer>
                  <PriceContainer>
                    <ModulePrice
                      colorPrice={colorPrice || "black"}
                      price={price}
                      project={project}
                      align={"left"}
                      sizePrice={
                        priceFont.size !== "" ? `${priceFont.size}pt` : "70px"
                      }
                      weight={priceFont.weight || 700}
                      family={"'Charter', sans-serif !important"}
                      sizeHonoraire={"12pt"}
                      sizeSpanPrice={"20pt"}
                    />
                  </PriceContainer>
                </AlurContainer>
              </RenderContext>
            </div>
          </BlockDescContainer>
          <BlockDescContainer>
            <RenderContext project={project}>
              <EnergyContainer>
                <div
                  style={{
                    height: "fit-content",
                    position: "absolute",
                    top:
                      DpeGesNoValue.includes(project.dpeLetter) ||
                      !project.dpeLetter
                        ? "60px"
                        : "48px",
                    left:
                      DpeGesNoValue.includes(project.dpeLetter) ||
                      !project.dpeLetter
                        ? "40px"
                        : "0px",
                  }}
                >
                  <Dpe
                    letter={
                      project.dpeLetter
                        ? DpeGesNoValue.includes(project.dpeLetter)
                          ? "vi"
                          : project.dpeLetter
                        : "vi"
                    }
                    conso={project.dpeValue}
                    emissions={project.gesValue}
                    zoom={
                      DpeGesNoValue.includes(project.dpeLetter) ||
                      !project.dpeLetter
                        ? "0.16"
                        : "0.18"
                    }
                    topTitle={"logement extrêmement performant"}
                    bottomTitle={"logement extrêmement peu performant"}
                    redInfoTop={"passoire"}
                    redInfoBottom={"énergetique"}
                    label={"consommation"}
                    secondLabel={"émissions"}
                    subLabel={"(énergie primaire)"}
                    subValue={"kWh/m2/an"}
                    secondSubValue={"kg CO₂/m²/an"}
                    theme={"light"}
                  />
                </div>
                <div
                  style={{
                    height: "fit-content",
                    position: "absolute",
                    bottom:
                      DpeGesNoValue.includes(project.gesLetter) ||
                      !project.gesLetter
                        ? "-10px"
                        : "-58px",
                    left:
                      DpeGesNoValue.includes(project.gesLetter) ||
                      !project.gesLetter
                        ? "195px"
                        : "225px",
                  }}
                >
                  <Ges
                    letter={
                      project.gesLetter
                        ? DpeGesNoValue.includes(project.gesLetter)
                          ? "vi"
                          : project.gesLetter
                        : "vi"
                    }
                    emissions={project.gesValue}
                    zoom={
                      DpeGesNoValue.includes(project.gesLetter) ||
                      !project.gesLetter
                        ? "0.4"
                        : "0.22"
                    }
                    theme={"light"}
                  />
                </div>
              </EnergyContainer>
              <EnergyTextContainer>
                <AlurText small>
                  {t("modalprint.dpeMinMaxInfo")} :{" "}
                  {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
                    <>
                      {`${t("modalprint.between")} ${
                        project.dpeCoutMinConso
                      }€ ${t("modalprint.and")} ${project.dpeCoutMaxConso}€ ${t(
                        "modalprint.perYear"
                      )} `}
                    </>
                  ) : (
                    t("details.modalprint.undisclosed")
                  )}
                </AlurText>
                {project.dpeCoutMinConso &&
                  project.dpeCoutMinConso !== "" &&
                  project.dpeAnneRefConso &&
                  project.dpeAnneRefConso !== "" && (
                    <AlurText small>
                      {t("modalprint.refYear")} {project.dpeAnneRefConso}{" "}
                      {t("modalprint.refYearEnd")}
                    </AlurText>
                  )}
                <AlurText>
                  Les informations sur les risques auxquels ce bien est exposé
                  sont disponibles sur le site Géorisques :
                  http://www.georisques.gouv.fr
                </AlurText>
              </EnergyTextContainer>
            </RenderContext>
          </BlockDescContainer>
        </DescContainer>
      </ContentContainer>
      {isBanner === "true" ? (
        <SoldBanner colorPrimary={colorSecondary || "black"}>
          <SoldBannerText
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </SoldBannerText>
        </SoldBanner>
      ) : null}
    </Container>
  );
});

export default LoftAndHouseLandscape;
