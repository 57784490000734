import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  MetaDescriptionContainer,
  GoogleTitle,
  GoogleTitleContainer,
  MetaTitle,
  MetaLink,
  MetaDescription,
} from "../../../Utils/Styles/ProjectList.styles";

// HR COMPONENT
const Hr = () => {
  return <Divider light style={{ marginBottom: "20px" }} />;
};

const MetaPreview = (props) => {
  const { initialValues, config, formProps } = props;
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [slug, setSlug] = useState("");

  useEffect(() => {
    if (initialValues.metatitle) {
      setMetaTitle(initialValues.metatitle);
    } else {
      setMetaTitle("");
    }
    if (initialValues.metadescription) {
      setMetaDescription(initialValues.metadescription);
    } else {
      setMetaDescription("");
    }
    if (initialValues.slug) {
      setSlug(initialValues.slug);
    } else {
      setSlug("");
    }
  }, [initialValues]);

  return (
    <MetaDescriptionContainer>
      <GoogleTitleContainer>
        <GoogleTitle>Aperçu Google</GoogleTitle>
        <Hr />
      </GoogleTitleContainer>
      <MetaTitle>{metaTitle || formProps.record.metatitle}</MetaTitle>
      <MetaLink>
        {`${config.APP_BASE}/visite360/${props.agency.slug}/${
          slug || formProps.record.slug || ""
        }`}
      </MetaLink>
      <MetaDescription>
        {metaDescription || formProps.record.metadescription}
      </MetaDescription>
    </MetaDescriptionContainer>
  );
};

export default MetaPreview;
