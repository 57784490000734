import React, { useState } from "react";
import PropTypes from "prop-types";
// CSS
import "./index.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { useMediaQuery } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ModalItem from "./ModalItem";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: "3rem",
    "& svg": {
      position: "absolute",
      fontSize: "2rem",
      opacity: "0",
      color: "white",
      zIndex: "1",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& .MuiGridListTile-root": {
      cursor: "pointer",
      "&:hover": {
        "& img": {
          [theme.breakpoints.up("sm")]: {
            transition: "all 0.3s ease",
            opacity: "0.8",
            filter: "brightness(0.8) blur(1px)",
          },
        },
        "& svg": {
          transition: "all 0.3s ease",
          opacity: "1",
        },
      },
    },
  },
  gridList: {
    width: "90%",
    maxHeight: 650,
    "&::-webkit-scrollbar": { width: "0 !important" },
    msOverflowStyle: "none",
    paddingBottom: "3rem",
  },
}));

const ModalGallery = (props) => {
  // const { infoProject } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(false);

  // const infoProject = {
  //   images: [
  //     {
  //       src: "https://via.placeholder.com/950x400.png",
  //       title: "truc 1",
  //     },
  //     {
  //       src: "https://via.placeholder.com/950x402.png",
  //       title: "truc 2",
  //     },
  //   ],
  // };

  const setModalView = (idx) => {
    setIndex(idx);
    setModal(true);
  };

  return (
    <div
      className="fade modal show"
      id="modal-gallery"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalGalleryLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalGalleryLabel">
              {t("sidebar.popoverImages")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body">
            <div className={classes.root}>
              <GridList
                cellHeight={xs ? 250 : sm ? 350 : md ? 126 : 126}
                className={classes.gridList}
                cols={xs ? 1 : sm ? 1 : md ? 2 : 2}
              >
                {props.infoProject.images &&
                  props.infoProject.images.map((tile, idx) => (
                    <GridListTile key={idx} cols={1}>
                      {!xs && (
                        <VisibilityIcon
                          style={{
                            position: "absolute",
                            color: "white",
                            zIndex: "1",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                          onClick={() => setModalView(idx)}
                        />
                      )}
                      <img
                        src={tile.src}
                        alt={tile.title}
                        style={{ height: "100%", objectFit: "cover" }}
                      />
                    </GridListTile>
                  ))}
              </GridList>
              {modal && (
                <ModalItem
                  index={index}
                  setIndex={setIndex}
                  data={props.infoProject.images}
                  setModal={setModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalGallery.propTypes = {
  infoProject: PropTypes.object,
};

export default ModalGallery;
