import React, { useEffect, useRef, useState } from "react";
import SecurePage from "../SecureAccess/SecureAccess";
import { useTranslation } from "react-i18next";
// Icons
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
// CSS
import "./index.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  CodeButton,
  ContainerFilter,
  SpanBtn,
  SpanIcon,
  UnlockBtn,
} from "./Files.styles";
import { colorPrimary } from "../../GlobalStyles";

function ListItemLink(props) {
  if (props.component === "a") {
    return <ListItem button component={props.component} {...props} />;
  } else {
    return <Typography {...props} />;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    backgroundColor: "transparent",
    "& .MuiList-root": {
      margin: "2rem",
    },
  },
  list: {
    position: "relative",
  },
  opacity: { opacity: "0.3", filter: "blur(4px)" },
  pointer: { cursor: "none" },
  icon: {
    color: colorPrimary,
  },
}));

const ModalFiles = (props) => {
  const { infoProject, getAllClick } = props;
  const [unLockAction, setUnLockAction] = useState(false);
  const [activated, setActivated] = useState(false);
  const containerPlanRef = useRef();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem(infoProject._id) === "isActivated") {
      setActivated(true);
    }

    function handler(event) {
      if (containerPlanRef.current?.contains(event.target)) {
        getAllClick("countClickPlan");
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [getAllClick, infoProject]);

  return (
    <div
      className="fade modal show"
      id="modal-files"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalDetailsLabel"
      aria-modal="true"
    >
      <div
        className="modal-dialog modal-full-height modal-right"
        role="document"
      >
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title" id="modalDetailsLabel">
              {t("details.files")}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseRoundedIcon fontSize="large" />
            </button>
          </div>
          <div className="modal-body">
            <div className="row no-gutters">
              <div className="col-12">
                {infoProject.files && infoProject.files.length > 0 ? (
                  !unLockAction ? (
                    <div className={`${classes.root} border border-white`}>
                      {infoProject.secureFiles && !activated && (
                        <>
                          <CodeButton onClick={() => setUnLockAction(true)}>
                            <SpanBtn>
                              <SpanIcon>
                                <UnlockBtn />
                              </SpanIcon>
                              {t("details.unblock")}
                            </SpanBtn>
                          </CodeButton>
                          <ContainerFilter />
                        </>
                      )}

                      <List
                        component="nav"
                        className={
                          (classes.list,
                          infoProject.secureFiles && !activated
                            ? classes.opacity
                            : "")
                        }
                        aria-label="main mailbox folders"
                      >
                        {infoProject.files.map((file, idx) => {
                          return (
                            <ListItemLink
                              component={
                                infoProject.secureFiles && !activated
                                  ? "div"
                                  : "a"
                              }
                              key={idx}
                              href={
                                infoProject.secureFiles && !activated
                                  ? null
                                  : file.src
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <ListItemIcon>
                                <PictureAsPdfIcon className={classes.icon} />
                              </ListItemIcon>
                              <ListItemText primary={file.name} />
                            </ListItemLink>
                          );
                        })}
                      </List>
                    </div>
                  ) : (
                    <SecurePage
                      project={props.infoProject}
                      agency={props.agency}
                      secureUnlock={props.secureUnlock}
                      margin={"2rem"}
                      setModal={setUnLockAction}
                      context="doc"
                      setActivated={setActivated}
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        project={props.infoProject}
        agency={props.agency}
        secureUnlock={props.secureUnlock}
        setActivated={setActivated}
      /> */}
    </div>
  );
};

export default ModalFiles;
