/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useRef, useState } from "react";
import DotSvg from "./DotSvg";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Preload } from "@react-three/drei";
import { PanoContainer } from "./Pano.styles";
import Preloader from "./Preloader";
import Switcher from "./Switcher";
import Menu from "./Menu";
import ControlsButton from "./ControlsButton";
import * as THREE from "three";
import { MathUtils } from "three";

function Dolly(props) {
  useFrame((state) => {
    const planeAspectRatio = 16 / 9;
    const fov = props.fov;
    const cameraHeight = Math.tan(MathUtils.degToRad(fov / props.size));
    const ratio = state.camera.aspect / planeAspectRatio;
    const newCameraHeight = cameraHeight / ratio;
    state.camera.fov =
      MathUtils.radToDeg(Math.atan(newCameraHeight)) * props.size;
    // state.camera.fov = props.fov;
    state.camera.updateProjectionMatrix();
  });
  return null;
}

const PanoController = (props) => {
  const panoRef = useRef();
  const canvasRef = useRef();
  const [fov, setFov] = useState(80);
  const [initCam, setInitCam] = useState([0, 0, -0.9]);
  const [open, setOpen] = useState(false);
  const vh = window.innerHeight * 0.01;

  useEffect(() => {
    if (props.scenes !== null && props.scenes.length) {
      const getPos = props.scenes.filter((item) => item.current === true)[0]
        .cameraPos;
      setInitCam(getPos);
    }
  }, [initCam, props.change, props.scenes]);

  function onMouseWheel(e) {
    if (e.target.nodeName === "CANVAS") {
      const fovVal = Math.sign(e.deltaY) * 0.05 + fov + e.deltaY * 0.3;
      setFov(THREE.MathUtils.clamp(fovVal, 10, 80));
    }
  }

  const fullSizeAction = () => {
    let targetelement = panoRef.current;
    if (targetelement.requestFullscreen) {
      targetelement.requestFullscreen();
    }
    if (targetelement.webkitRequestFullscreen) {
      targetelement.webkitRequestFullscreen();
    }
    if (targetelement.mozRequestFullScreen) {
      targetelement.mozRequestFullScreen();
    }
    if (targetelement.msRequestFullscreen) {
      targetelement.msRequestFullscreen();
    }
  };
  const fullSizeActionExit = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      setOpen(false);
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
      setOpen(false);
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
      setOpen(false);
    }
  };
  return (
    <>
      <PanoContainer onWheel={onMouseWheel} ref={panoRef} vh={vh}>
        <DotSvg
          loadingTransition={props.loadingTransition}
          count={props.count}
          imageLoad={props.scenes[props.index].finalLoadImage}
        />

        {!props.loadingTransition && (
          <>
            <Canvas
              dpr={[1, 2]}
              camera={{ fov: fov, position: initCam }}
              ref={canvasRef}
              mode="concurrent"
              flat={
                props.scenes.filter((item) => item.current === true)[0].type ===
                "360"
                  ? false
                  : true
              }
              linear={true}
              gl={{
                alpha: true,
                stencil: true,
                depth: true,
                antialias: false,
              }}
              onCreated={(state) => (state.gl.toneMappingExposure = 1.5)}
            >
              <Dolly
                fov={fov}
                setCameraPosition={props.setCameraPosition}
                size={
                  props.scenes.filter((item) => item.current === true)[0]
                    .type === "360"
                    ? 1.3
                    : 2
                }
              />
              <OrbitControls
                enableDamping
                dampingFactor={0.2}
                rotateSpeed={-0.5}
                autoRotate={props.autorotate}
                enableZoom={false}
                enabled={
                  props.scenes.filter((item) => item.current === true)[0]
                    .type === "360"
                }
              />
              <Suspense fallback={null}>
                {/* <Preloader store={props.scenes} /> */}
                <Preload all />
                <Switcher
                  setClickPosTemp={props.setClickPosTemp}
                  store={props.scenes}
                  setCurrentLink={props.setCurrentLink}
                  setLoadingTransition={props.setLoadingTransition}
                  setCount={props.setCount}
                  ref={{ canvasRef, panoRef }}
                  autorotate={props.autorotate}
                />
              </Suspense>
            </Canvas>
          </>
        )}
        {!props.noMenu && props.scenes.length > 1 ? (
          <Menu
            scenes={props.scenes}
            setCurrentLink={props.setCurrentLink}
            setLoadingTransition={props.setLoadingTransition}
            open={open}
            setOpen={setOpen}
            setCount={props.setCount}
            autorotate={props.autorotate}
          />
        ) : null}
        {!props.autorotate && props.scenes.length ? (
          <ControlsButton
            setFov={setFov}
            fov={fov}
            open={open}
            setOpen={setOpen}
            fullSizeAction={fullSizeAction}
            fullSizeActionExit={fullSizeActionExit}
            translate={props.translate}
          />
        ) : null}
      </PanoContainer>
    </>
  );
};

export default PanoController;
