import styled from "styled-components";

export const Container = styled.div`
  && {
    width: 297mm;
    height: 420mm;
    min-width: 296mm;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 portrait;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const TitleContainer = styled.div`
  && {
    width: 100%;
    height: 196pt;
  }
`;

export const TitleTop = styled.div`
  && {
    width: 100%;
    height: 50%;
    display: flex;
  }
`;

export const TitleTopLeft = styled.div`
  && {
    width: 50%;
    height: 100%;
    border-right: 1px solid black;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AgencyLogo = styled.img`
  height: 65pt;
  z-index: 2;
`;

export const Title = styled.h2`
  && {
    text-align: center;
    color: ${(props) => props.colorSubTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const TitleTopRight = styled.div`
  && {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TopTitle = styled.div`
  && {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const TopBottom = styled.div`
  && {
    width: 100%;
    height: 50%;
  }
`;

export const Ref = styled.p`
  && {
    margin-bottom: 0;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const TitleBottom = styled.div`
  && {
    width: 100%;
    height: 50%;
    display: flex;
    background-color: ${(props) => props.colorPrimary};
  }
`;

export const TitleBottomLeftContainer = styled.div`
  && {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const TitleBottomRightContainer = styled.div`
  && {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleBottomLeft = styled.h2`
  && {
    color: ${(props) => props.colorTitle};
    text-align: center;
    margin: 0;
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const ImgContainer = styled.div`
  && {
    height: 400.38pt;
    width: 100%;
    position: relative;
  }
`;
export const Img = styled.img`
  && {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
  }
`;

export const ContainerLogo = styled.div`
  && {
    display: flex;
    justify-content: space-around;
    height: 122pt;
  }
`;

export const ExcluContainer = styled.div`
  && {
    height: 47.48pt;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 194.9pt;
    position: absolute;
    top: 20pt;
    left: 0pt;
    background-color: ${(props) => props.colorPrimary};
    z-index: 10;
  }
`;

export const Exclu = styled.p`
  && {
    margin-bottom: 0;
    text-align: center;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const ContainerDescription = styled.div`
  && {
    padding: 12pt 45pt;
    height: 124.53pt;
    width: 100%;
    background-color: ${(props) => props.colorPrimary};
  }
`;

export const Description = styled.p`
  && {
    color: ${(props) => props.colorDesc};
    text-align: left;
    line-height: 115%;
    margin-bottom: 0;
    white-space: pre-wrap;
    max-height: 104pt;
    overflow: hidden;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;

export const Category = styled.p`
  font-size: 30pt;
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 12pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  height: 346pt;
`;
export const BottomContainerLeft = styled.div`
  width: 40%;
  padding-left: 45pt;
  padding-top: 20pt;
`;
export const BottomContainerRight = styled.div`
  width: 60%;
  display: flex;
  position: relative;
  margin-top: -65px;
  overflow: hidden;
`;
export const ContainerPrice = styled.div`
  padding: 0 0 15pt;
  width: 100%;
`;
export const ContainerAlur = styled.div`
  width: 100%;
  min-height: 92pt;
`;
export const QrcodeContainer = styled.div`
  width: 135pt;
  height: 135pt;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15pt;
`;
export const AlurText = styled.p`
  font-size: 12px;
  font-weight: initial;
  color: black;
  margin-bottom: 0;
  margin-top: 0;
`;
export const DpeTextContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 115px;
  max-width: 475px;
`;
