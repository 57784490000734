import styled from "styled-components";

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: ${({ minHeight }) => (minHeight ? "unset" : "80vh")};
`;

export const LoaderContainerChild = styled.div`
  text-align: center;
  color: #34495e;
`;

export const LoaderText = styled.h2``;

export const LoaderDotElement = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 3px;
  background: white;
  animation: wave 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-15px);
    }
  }
`;
