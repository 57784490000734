import styled from "styled-components";

export const QrcodeContainer = styled.div`
  position: absolute;
  top: 5pt;
  right: 100pt;
  z-index: 10;
  width: 0px;
  height: 200px;
  border-radius: 10px;
  background-color: blue;
  border-right: 100px solid transparent;
  border-bottom: 50px solid white;
  border-left: 100px solid transparent;
`;

export const Container = styled.div`
  && {
    width: 297mm;
    height: 420mm;
    display: flex;
    flex-direction: column;
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    background-color: white;
    zoom: ${({ zoom }) => (zoom ? "0.4" : "initial")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    position: relative;

    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 portrait;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;

export const TopBanner = styled.div`
  && {
    width: 100%;
    height: 96.34pt;
    padding: 0.5rem;
  }
`;

export const ContainerLogo = styled.div`
  && {
    display: flex;
    margin-left: 5%;
  }
`;

export const Logo = styled.img`
  && {
    height: 90pt;
  }
`;
export const QrCodeContainerInner = styled.div`
  position: absolute;
  top: 30pt;
  right: 122pt;
  z-index: 11;
`;

export const ContainerImg = styled.div`
  && {
    width: 100%;
    height: 520.81pt;
    position: relative;
    display: flex;
  }
`;

export const ImgProperty = styled.img`
  && {
    width: 496pt;
    height: 100%;
    object-fit: cover;
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
  }
`;

export const ContainerGallery = styled.div`
  && {
    width: 338pt;
    margin-left: 8pt;
  }
`;

export const Gallery = styled.div`
  && {
    width: 100%;
    height: 282pt;
    display: flex;
    margin-bottom: 8pt;
  }
`;
export const Gallery2 = styled.div`
  && {
    width: 100%;
    height: 230.33pt;
    display: flex;
  }
`;

export const Img = styled.img`
  && {
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: brightness(${(props) => props.brightness}%)
      saturate(${(props) => props.saturation}%);
  }
`;

export const ContainerDescription = styled.div`
  && {
    width: 100%;
    height: 573.4pt;
    display: flex;
    flex-direction: column;
    padding: 0 30pt;
  }
`;

export const ContainerDescTop = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerTitle = styled.div`
  && {
    width: 60%;
    margin-bottom: 20pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const ContainerPrice = styled.div`
  && {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Title = styled.h4`
  && {
    margin-top: 15pt;
    margin-bottom: 0;
    text-align: left;
    width: 100%;
    color: ${(props) => props.colorTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "27pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const SubTitle = styled.h4`
  && {
    margin-top: 7pt;
    text-align: left;
    width: 100%;
    color: ${(props) => props.colorSubTitle};
    font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "21pt")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
  }
`;

export const Line1 = styled.div`
  width: 100%;
  border-bottom: solid 1pt #707070;
`;

export const ContainerResume = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 0 1rem;
`;

export const Resume = styled.p`
  width: 100%;
  text-align: left;
  color: ${(props) => props.colorDesc};
  white-space: pre-wrap;
  line-height: 115%;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;

export const Line2 = styled.div`
  && {
    width: 100%;
    border-bottom: solid 1pt #707070;
  }
`;

export const ContainerBottom = styled.div`
  && {
    width: 100%;
    height: 48%;
    display: flex;
  }
`;

export const ContainerBottomLeft = styled.div`
  && {
    width: 35%;
    height: 100%;
    padding-top: 5pt;
    color: ${(props) => props.colorDesc};
  }
`;
export const ContainerBottomRight = styled.div`
  && {
    width: 65%;
    height: 100%;
    display: flex;
    color: ${(props) => props.colorDesc};
    position: relative;
    margin-top: -25px;
  }
`;

export const Details = styled.p`
  && {
    margin-left: 2em;
    font-size: 100%;
    margin-bottom: 0;
    border-right: solid 1px black;
  }
`;
export const Ref = styled.p`
  && {
    margin-bottom: 0;
    margin-top: 7pt;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "15pt")};
    color: ${(props) => (props.color !== "" ? props.color : "white")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== ""
        ? props.family
        : "'Open Sans', sans-serif"} !important;
  }
`;
export const IconsContainer = styled.div`
  background-color: ${(props) => props.colorPrimary};
  width: 325pt;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5pt;
  padding: 15pt;
  position: absolute;
  bottom: 0;
  left: 0;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const IconText = styled.p`
  font-size: 12pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const ExcluContainer = styled.div`
  padding: 5px 0;
  background-color: #ffffff94;
  width: 177pt;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15pt;
  position: absolute;
  left: 0;
  z-index: 10;
`;
export const ExcluText = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "26pt")};
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "bold")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const AlurText = styled.p`
  font-size: 12px;
  font-weight: initial;
  color: black;
  margin-bottom: 0;
  margin-top: 0;
`;
export const DpeTextContainer = styled.div`
  position: absolute;
  bottom: -15px;
  left: 115px;
  max-width: 475px;
`;
