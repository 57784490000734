import React from "react";
import { getAgencyData } from "../Utils/axiosRequest";

const formWrapper = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    handleChangeAgency = (event, value) => {
      if (value !== null) {
        this.setState({
          send: { agenceId: value._id },
          enableSave: false,
        });
      } else {
        this.setState({
          send: { agenceId: null },
          enableSave: false,
        });
      }
    };

    refresh = async () => {
      const agency = await getAgencyData();
      this.setState({ agency: agency.data });
    };

    async componentDidMount() {
      await this.refresh();
    }

    render() {
      if (!this.state.agency) {
        return null;
      } else {
        return (
          <WrappedComponent
            initialValues={this.state.send}
            agency={this.state.agency}
            handleChangeAgency={this.handleChangeAgency}
            enableSave={this.state.enableSave && this.state.enableSave}
            {...this.props}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
};

export default formWrapper;
