import { config } from "../../../../constants/constant";
import axios from "axios";
export const getAgencyData = async () => {
  const url = config.API_BASE + "/agencies?%24limit=-1";
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

export const getAgency = async (id) => {
  const url = config.API_BASE + "/agencies/" + id;
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "agency",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const getUserFromAgency = async (id) => {
  const url = config.API_BASE + "/users?agenceId=" + id + "&%24limit=-1";
  const params = {
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      caller: "modalUserChange",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
export const sendActivationApi = async (email) => {
  const url = config.API_BASE + "/authManagement/sendActivation";
  const params = {
    method: "POST",
    url,
    data: { email, context: "resend" },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  try {
    const fetch = await axios(params);
    return fetch;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};
