import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import { CreateButton, TopToolbar } from "react-admin";
import { UiContext } from "../../../../store/UserProvider";
import { ShowIcon, SidebarVisibble } from "../Utils/Styles/Agency.styles";
const ListActions = (props) => {
  const { ui, setUi } = useContext(UiContext);

  return (
    <Box width="100%">
      <TopToolbar>
        {props.admin && (
          <>
            {" "}
            <SidebarVisibble
              onClick={() =>
                setUi((prevState) => ({
                  ...prevState,
                  sideBarVisibble: !ui.sideBarVisibble,
                }))
              }
            >
              <ShowIcon $right={"true"} $size={"true"} /> Sidebar
            </SidebarVisibble>
            <CreateButton basePath="agencies" />{" "}
          </>
        )}
      </TopToolbar>
    </Box>
  );
};

export default ListActions;
