import React from "react";
import { useTranslation } from "react-i18next";
import { data } from "./data";
import { Fade, useMediaQuery } from "@material-ui/core";
import {
  ContainerStepTwo,
  RowStepTwo,
  RowInnerStepTwo,
  TileStepTwo,
  NotAvailableContainer,
  NotAvailableText,
  TileMediaStepTwo,
  MediaStepTwo,
  TileDetailsStepTwo,
  TileTitleStepTwo,
} from "./StepTwo.styles";
import { colorSecondary } from "../../../../GlobalStyles";
import { useTemplatesStore } from "../../../../store/TemplatesStore";

const StepTwo = () => {
  const {
    orientation,
    model,
    setModel,
    setImageFirst,
    setImageSupp,
    setMirror,
    agency,
  } = useTemplatesStore();
  const { t } = useTranslation();
  const matches = useMediaQuery("(max-width:1285px)");
  const matchesXs = useMediaQuery("(max-width:600px)");

  const handleModel = (choice) => {
    setModel(choice);
    if (model !== choice) {
      setImageFirst(false);
      setImageSupp([]);
      setMirror(false);
    }
  };

  return (
    <Fade in={true} timeout={1000}>
      <ContainerStepTwo>
        <RowStepTwo
          alignItems={orientation === "portrait" ? "initial" : "center"}
          justifyContent={
            orientation === "portrait" && data.length <= 5 && !matches
              ? "center"
              : orientation === "landscape" && data.length <= 3
              ? "center"
              : "unset"
          }
        >
          <RowInnerStepTwo>
            {data
              .filter((item) => {
                if (item.name === "Bersot Immobilier") {
                  if (
                    agency.name.toLowerCase().includes("bersot") &&
                    orientation === "landscape"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "Elfi") {
                  if (
                    agency.name.toLowerCase().includes("bersot") &&
                    orientation === "landscape"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "Loft And House") {
                  if (
                    agency.name.toLowerCase().includes("loft and house") &&
                    orientation === "landscape"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "IPH") {
                  if (
                    agency.name.toLowerCase().includes("iph") &&
                    orientation === "landscape"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "IPH Second") {
                  if (
                    agency.name.toLowerCase().includes("iph") &&
                    orientation === "landscape"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "Madrid") {
                  if (orientation === "landscape") {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "Osenat") {
                  if (
                    agency.name === "ROLAND GOSSELIN & ASSOCIES PARIS 7" &&
                    orientation === "landscape"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (item.name === "Sapene") {
                  if (
                    orientation === "landscape" &&
                    agency.name === "SAPÈNE IMMOBILIER RENNES"
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              })
              .map((item, idx) => {
                if (
                  (orientation === "landscape" && item.name !== "Perso") ||
                  (orientation === "landscape" &&
                    item.name === "Perso" &&
                    agency.header &&
                    agency.header.length &&
                    agency.footer &&
                    agency.footer.length) ||
                  (orientation === "portrait" &&
                    item.name === "Perso" &&
                    agency.headerPortrait &&
                    agency.headerPortrait.length &&
                    agency.footerPortrait &&
                    agency.footerPortrait.length) ||
                  orientation === "portrait"
                )
                  return (
                    <TileStepTwo
                      width={orientation !== "portrait" ? "400px" : "230px"}
                      marginRight={matchesXs ? "30px" : "10px"}
                      backgroundColor={
                        model === item.name ? colorSecondary : "unset"
                      }
                      key={idx}
                      onClick={
                        item.available ? () => handleModel(item.name) : null
                      }
                    >
                      {!item.available && (
                        <NotAvailableContainer>
                          <NotAvailableText>
                            {t("common.comingsoon").toUpperCase()}
                          </NotAvailableText>
                        </NotAvailableContainer>
                      )}
                      <TileMediaStepTwo>
                        <MediaStepTwo
                          src={item.format[orientation].src}
                          alt={item.name}
                          width={orientation !== "portrait" ? "400px" : "230px"}
                          border={
                            model === item.name
                              ? `solid 5px ${colorSecondary}`
                              : "unset"
                          }
                        />
                      </TileMediaStepTwo>
                      <TileDetailsStepTwo>
                        <TileTitleStepTwo>{item.name}</TileTitleStepTwo>
                      </TileDetailsStepTwo>
                    </TileStepTwo>
                  );
                else return null;
              })}
          </RowInnerStepTwo>
        </RowStepTwo>
      </ContainerStepTwo>
    </Fade>
  );
};

export default StepTwo;
