/* eslint-disable no-unused-vars */
import React from "react";
import { CombineContext } from "../../../../../store/UserProvider";
import Loader from "../../../../Loader/Loader";
import {
  buildFilter,
  filterData,
  filterTips,
} from "./FilterSideBar/filterTips";
import {
  getDataFromCategory,
  getDataLike,
  getDataProject,
  getUserData,
  getDataAgency,
} from "../../Utils/axiosRequest";
import axios from "axios";
import { config } from "../../../../../constants/constant";

const abortController = new AbortController();
const signal = abortController.signal;

function ListWrapper(WrappedComponent) {
  return class extends React.Component {
    _isMounted = false;
    constructor(props) {
      super(props);
      this.state = {
        data: false,
        dataFilter: false,
        loading: true,
        user: false,
        agency: false,
        query: false,
        queryValue: false,
        isReset: false,
        filter: {
          type: [],
          state: [],
          category: [],
          scenes: [],
          medias: [],
          mediasurl: [],
          userId: [],
        },
      };
    }

    static contextType = CombineContext;

    importMandats = async (selected) => {
      const theUser = await getUserData(this.state.user._id);
      const site = selected ? selected : theUser.data.agenceId;
      if (theUser.data.permissions.includes("admin") && !site) {
        const sites = await axios.get(`${config.API_BASE}/imports`, {
          headers: {
            Authorization: localStorage.getItem(config.ACCESS_TOKEN_NAME),
          },
        });
        return sites;
      }

      if (
        (theUser &&
          theUser.data.agenceId &&
          theUser.data.agenceId.apiTokenValue) ||
        (theUser && site)
      ) {
        return await axios.get(`${config.API_BASE}/imports/${site}`, {
          headers: {
            Authorization: localStorage.getItem(config.ACCESS_TOKEN_NAME),
          },
        });
      } else {
        const response = "notoken";
        return response;
      }
    };

    onChange = async (event) => {
      const fetchLike = await getDataLike(
        this.state.query,
        this.state.queryValue,
        event.target.value
      );
      this.setState({ dataFilter: fetchLike.data.data });
      if (event.target.value === "") {
        this.setState({ dataFilter: false });
      }
    };

    handleChangeFilter = (value) => {
      this.setState((prev) => ({
        filter: { ...prev.filter, ...value },
      }));
    };

    handleFilterCategory = async (category, value) => {
      this.setState({ isReset: false });
      filterTips(category, value, this.state.filter, this.handleChangeFilter);
    };

    resetFilter = () => {
      this.setState({ filter: {} });
      this.setState({ isReset: true });
    };

    refreshList = async () => {
      if (this._isMounted) {
        const context = await this.context;
        let userSelect;
        if (context.valueChange.userChange) {
          userSelect = context.valueChange.userChange;
        } else {
          userSelect = context.value.user;
        }
        let query;
        let queryValue;

        if (userSelect.agenceId) {
          query = "agenceId";
          queryValue = userSelect.agenceId;
        } else {
          query = "userId";
          queryValue = userSelect._id;
        }
        // const fetchAgency = await getDataAgency(userSelect.agenceId);
        // this.setState({ agency: fetchAgency.data });
        this.setState({ agency: context.agencyValue.agency });

        const fetch = await getDataProject(query, queryValue, {
          signal,
        });
        if (fetch.data.length === 0) {
          this.setState({ data: [] });
        } else {
          this.setState({ data: fetch.data.data });
        }

        this.setState({ user: userSelect });
        this.setState({ query });
        this.setState({ queryValue });
        this.setState({ loading: false });
      }
    };

    async componentDidMount() {
      this._isMounted = true;
      if (this._isMounted) {
        const test = await this.refreshList({ signal });
      }
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    render() {
      if (this.state.loading) {
        return <Loader />;
      } else {
        let queryfil = buildFilter(this.state.filter);
        let result = filterData(this.state.data, queryfil);
        return (
          <WrappedComponent
            data={result}
            onChange={this.onChange}
            dataFilter={this.state.dataFilter}
            handleFilterCategory={this.handleFilterCategory}
            resetFilter={this.resetFilter}
            filter={this.state.filter}
            importMandats={this.importMandats}
            refreshList={this.refreshList}
            isReset={this.state.isReset}
            user={this.state.user}
            message={this.state.message}
            agency={this.state.agency}
          >
            {this.props.children}
          </WrappedComponent>
        );
      }
    }
  };
}

ListWrapper.displayName = "ProjectList";

export default ListWrapper;
