export const choice = {
  category: [
    { id: "Vente", name: "details.category.sale" },
    { id: "Location", name: "details.category.rental" },
    {
      id: "Locationsaisonnière",
      name: "details.category.seasonalrental",
    },
    { id: "Programme", name: "details.category.program" },
    { id: "Viager", name: "details.category.annuities" },
    {
      id: "Nue-propriété",
      name: "details.category.bareownership",
    },
    { id: "Enchère", name: "details.category.bid" },
  ],
  subcategory: [
    { id: "unitsale", name: "resources.projects.fields.subcategory.unitsale" },
    { id: "bulksale", name: "resources.projects.fields.subcategory.bulksale" },
    { id: "empty", name: "resources.projects.fields.subcategory.empty" },
    {
      id: "furnished",
      name: "resources.projects.fields.subcategory.furnished",
    },
    {
      id: "flatsharing",
      name: "resources.projects.fields.subcategory.flatsharing",
    },
    { id: "student", name: "resources.projects.fields.subcategory.student" },
  ],
  type: [
    { id: "Appartement", name: "titlebar.type.apartment" },
    { id: "Maison", name: "titlebar.type.house" },
    { id: "Maison/villa", name: "titlebar.type.housevilla" },
    {
      id: "Maison prestige",
      name: "titlebar.type.house.prestige",
    },
    { id: "Terrain", name: "titlebar.type.field" },
    { id: "Commerce", name: "titlebar.type.store" },
    { id: "Local", name: "titlebar.type.local" },
    { id: "Garage", name: "titlebar.type.garage" },
    { id: "Parking", name: "titlebar.type.parking" },
    { id: "Parking/box", name: "titlebar.type.parkingbox" },
    { id: "Box", name: "titlebar.type.box" },
    { id: "Immeuble", name: "titlebar.type.building" },
    { id: "Bureau", name: "titlebar.type.office" },
    { id: "Bateau", name: "titlebar.type.boat" },
    {
      id: "Locaux d'activité",
      name: "resources.projects.fields.type.activitypremises",
    },
    { id: "Entrepot", name: "resources.projects.fields.type.warehouse" },
    { id: "Cave", name: "resources.projects.fields.type.cellar" },
  ],
};
