import styled from "styled-components";
import MoodIcon from "@material-ui/icons/Mood";
import { colorTertiary } from "../../../GlobalStyles";

const after = {
  animationDuration: "1.25s, 1.25s, 7.25s",
  webkitAnimationDuration: "1.25s, 1.25s, 7.25s",
};

export const SmileyIcon = styled(MoodIcon)`
  && {
    color: ${colorTertiary};
    font-size: 30px;
  }
`;

export const FireworksContainer = styled.div``;

export const BeforeAfter = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
    0 0 #fff, 0 0 #fff, 0 0 #fff;
  animation: bang 1s 3 ease-in backwards, gravity 1s 3 ease-in backwards,
    5s position linear backwards;
  -webkit-animation: bang 1s 3 ease-in backwards, gravity 1s 3 ease-in backwards,
    5s position linear backwards;

  ${(props) => (props.after ? after : null)}

  @keyframes bang {
    to {
      box-shadow: 7px -347.6666666667px #ff002b, -69px -294.6666666667px #22ff00,
        232px -367.6666666667px #26ff00, 108px -294.6666666667px #001aff,
        -196px -5.6666666667px #00ddff, 109px -77.6666666667px #00ff7b,
        145px -228.6666666667px #ff0900, -193px -206.6666666667px #0099ff,
        91px -159.6666666667px #ff5100, 87px -396.6666666667px #6aff00,
        -245px -371.6666666667px #00ff80, 91px -136.6666666667px #1500ff,
        -208px 4.3333333333px #ff5e00, -93px -204.6666666667px #7bff00,
        -113px -310.6666666667px #d000ff, 22px 45.3333333333px #00ff2b,
        45px -145.6666666667px #00bbff, -222px -8.6666666667px #8c00ff,
        9px -272.6666666667px #0033ff, 123px -20.6666666667px #2f00ff,
        -171px 16.3333333333px #ffd000, -241px 62.3333333333px aqua,
        58px -7.6666666667px #1eff00, 145px -402.6666666667px #0026ff,
        -114px -184.6666666667px #ff5500, -42px -275.6666666667px #0900ff,
        138px -50.6666666667px #00ff11, -98px -52.6666666667px #ff00d0,
        -59px -324.6666666667px #00a2ff, -217px -36.6666666667px #00ffbf,
        118px -23.6666666667px darkorange, -60px -337.6666666667px #ff00ea,
        229px 26.3333333333px #003cff, 58px -255.6666666667px #91ff00,
        -72px -167.6666666667px #ff001e, 131px -107.6666666667px #ff1e00,
        -112px -11.6666666667px #6600ff, 17px -196.6666666667px #00ff6a,
        62px 77.3333333333px #00ffe1, 134px 3.3333333333px #ff2200,
        248px -136.6666666667px #00eeff, -215px -186.6666666667px #0055ff,
        236px -346.6666666667px #ff8000, -214px -169.6666666667px #9dff00,
        -83px -59.6666666667px #ffcc00, -155px 75.3333333333px #bb00ff,
        -62px -95.6666666667px #00ffc4, 71px -391.6666666667px #00ffbf,
        -234px 75.3333333333px #ff003c, 57px -227.6666666667px #00ff4d,
        150px -72.6666666667px #ff00b3;
    }
  }
  @keyframes gravity {
    to {
      transform: translateY(200px);
      -webkit-transform: translateY(200px);
      opacity: 0;
    }
  }

  @keyframes position {
    0%,
    19.9% {
      margin-top: 0%;
      margin-left: 0%;
    }

    20%,
    39.9% {
      margin-top: 15%;
      margin-left: 30%;
    }

    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 5%;
    }

    60%,
    79.9% {
      margin-top: 5%;
      margin-left: 25%;
    }

    80%,
    99.9% {
      margin-top: 10%;
      margin-left: 5%;
    }
  }
`;
