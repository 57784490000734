import React, { useState } from "react";
import { Box } from "@material-ui/core";
import CollapseOne from "./Collapse/CollapseOne";
import ItemButton from "./ItemButton";
import ItemManager from "./ItemManager/ItemManager";
import { useTranslate } from "react-admin";
import { choices } from "../Utils/dataChoices";
import { getStateColor } from "./features";
import Brightness1RoundedIcon from "@material-ui/icons/Brightness1Rounded";
import {
  AboContainer,
  AboDescContainer,
  AboTitleContainer,
  StateAboContainer,
  StateIcon,
  StateText,
} from "../Utils/Styles/Agency.styles";
import { useAlert } from "react-alert";

const Item = (props) => {
  const alert = useAlert();
  const translate = useTranslate();
  const [modal, setModal] = useState(false);

  const getState = (value) => {
    // const state = choices.state.filter((item) => item.id === value);
    let state;
    if (value !== "inactive") {
      state = choices.state.filter((item) => item.id === "active");
    } else {
      state = choices.state.filter((item) => item.id === "inactive");
    }
    return state;
  };

  const showMessage = (message) => {
    alert.show(translate(message));
  };

  return (
    <Box flex={1} mr={{ xs: 0, sm: "20px" }} mb={"15px"} display={"flex"}>
      <AboContainer color={props.color}>
        <AboTitleContainer background={"transparent"}>
          <img src={props.logo} alt="logo" width="130" />
        </AboTitleContainer>
        <AboTitleContainer background={"transparent"}>
          <h2 style={{ fontSize: "1.3rem" }}>{props.text}</h2>
        </AboTitleContainer>
        <AboDescContainer background={"transparent"}>
          <p style={{ color: "#b2b2b2", fontSize: "14px" }}>{props.desc}</p>
        </AboDescContainer>
        <StateAboContainer>
          <StateIcon
            as={Brightness1RoundedIcon}
            mycolor={
              props.record && props.record.abo && props.record.abo[props.name]
                ? getStateColor(props.record.abo[props.name].state)
                : "#CC0000"
            }
          />
          <StateText>
            {translate(
              props.record && props.record.abo && props.record.abo[props.name]
                ? getState(props.record.abo[props.name].state)[0].name
                : choices.state[2].name
            ).toUpperCase()}
          </StateText>
        </StateAboContainer>

        {(props.record &&
          props.record.abo &&
          props.record.abo[props.name] &&
          props.record.abo[props.name].state === choices.state[0].id) ||
        (props.record &&
          props.record.abo &&
          props.record.abo[props.name] &&
          props.record.abo[props.name].state === choices.state[1].id) ||
        (props.record &&
          props.record.abo &&
          props.record.abo[props.name] &&
          props.record.abo[props.name].state === choices.state[3].id) ? (
          <CollapseOne
            record={props.record.abo[props.name]}
            type={
              props.record.abo[props.name].type === "print-include"
                ? true
                : false
            }
            callInfoPage={props.callInfoPage}
          />
        ) : null}

        <ItemButton
          name={props.name}
          record={props.record}
          setModal={setModal}
          color={props.color}
        />
      </AboContainer>
      {modal && (
        <ItemManager
          setModal={setModal}
          modal={modal}
          {...props}
          showMessage={showMessage}
        />
      )}
    </Box>
  );
};

export default Item;
