import styled from "styled-components";

export const RowChoiceImage = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  overscroll-behavior-x: none;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: #2fbcee;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b3afb3;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }
`;

export const TileChoiceImage = styled.div`
  position: relative;
  display: inline-block;
  margin-right: ${(props) => props.marginRight};
  font-size: 20px;
  cursor: pointer;
  transition: 450ms all;
  transform-origin: center left;
  border-radius: 10px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.backgroundColor};

  &:hover ~ & {
    transform: translate3d(75px, 0, 0);
  }
`;

export const RowInnerChoiceImage = styled.div`
  transition: 450ms transform;
  font-size: 0;
  white-space: nowrap;
  margin: 55px 0;
  padding-bottom: 10px;
  padding-left: 15px;

  &:hover {
    transform: translate3d(-10px, 0, 0);
  }

  &:hover ${TileChoiceImage} {
    opacity: 0.3;
  }

  &:hover ${TileChoiceImage}:hover {
    transform: scale(1.2);
    opacity: 1;
  }
`;

export const TileMediaChoiceImage = styled.div``;

export const MediaChoiceImage = styled.img`
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  width: ${(props) => props.width};
  height: auto;
  max-height: 300px;
  border: ${(props) => props.border};
`;
