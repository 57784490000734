import React from "react";
import DescComponent from "./Items/DescComponent";
import PriceComponent from "./Items/PriceComponent";
import QrCodeComponent from "./Items/QrCodeComponent";
import SubTitleComponent from "./Items/SubTitleComponent";
import TitleComponent from "./Items/TitleComponent";
import BannerComponent from "./Items/BannerComponent";
import { ContentContainer } from "./Styles/Content.styles";
import BannerShowComponent from "./Items/BannerShowComponent";
import TitleTwoComponent from "./Items/TitleTwoComponent";
// import CategoryComponent from "./Items/CategoryComponent";
import RefComponent from "./Items/RefComponent";
import AreaFloorComponent from "./Items/AreaFloorComponent";
import BannerSecondComponent from "./Items/BannerSecondComponent";
import BannerSecondShowComponent from "./Items/BannerSecondShowComponent";
import IconsSelector from "./Items/IconsSelector";
import { useTemplatesStore } from "../../../../store/TemplatesStore";

const Content = () => {
  const { model, orientation, icons } = useTemplatesStore();
  const secondTitleIn = ["Loft And House", "Madrid"];
  const notRef = ["Loft And House", "Sapene", "IPH", "IPH Second"];

  return (
    <ContentContainer>
      {(model === "Singapour" && orientation === "landscape") ||
      model === "Sydney" ||
      model === "Bersot Immobilier" ||
      model === "IPH" ||
      model === "Madrid" ? null : (
        <DescComponent />
      )}
      {model !== "Sapene" && <TitleComponent />}
      {model !== "Sapene" && <SubTitleComponent />}
      {secondTitleIn.includes(model) && <TitleTwoComponent />}

      {model === "Sapene" &&
        icons.map((item, idx) => (
          <IconsSelector key={idx} index={idx} item={item} />
        ))}
      {!notRef.includes(model) && (
        <>
          <RefComponent />
        </>
      )}
      <PriceComponent />
      {model === "Loft And House" && <AreaFloorComponent />}
      {model !== "IPH Second" && (
        <>
          <BannerComponent />
          <BannerShowComponent />
        </>
      )}
      {model === "Sapene" && <BannerSecondComponent />}
      {model === "Sapene" && <BannerSecondShowComponent />}
      {model !== "Loft And House" && <QrCodeComponent />}
    </ContentContainer>
  );
};

export default Content;
