/* eslint-disable no-unused-vars */
import {
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InputRange from "react-input-range";
import { Fade } from "@material-ui/core";
import "react-input-range/lib/css/index.css";
import "./index.css";
import { useTranslate } from "react-admin";
import { colorPrimary } from "../../../../../../GlobalStyles";

const GreenCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: colorPrimary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RoomsFilter = (props) => {
  const translate = useTranslate();
  const [click, setClick] = useState(false);
  const [state, setState] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  });

  useEffect(() => {
    if (props.isReset) {
      setState({
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
      });
    }
  }, [props.isReset]);

  const submitValue = (value) => {};

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name === "1"
        ? "one"
        : event.target.name === "2"
        ? "two"
        : event.target.name === "3"
        ? "three"
        : event.target.name === "4"
        ? "four"
        : "five"]: event.target.checked,
    });
    props.handleFilter("rooms", event.target);
  };

  return (
    <div style={{ marginTop: "2rem" }}>
      <Typography
        onClick={() => setClick(!click)}
        style={{
          cursor: "pointer",
          fontSize: "0.75rem",
          letterSpacing: "0.08333em",
        }}
      >
        {translate("resources.projects.fields.rooms").toUpperCase()}
      </Typography>
      <Fade in={click} timeout={300} unmountOnExit>
        <div style={{ marginTop: "0.5rem" }}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.one}
                onChange={handleChange}
                name="1"
              />
            }
            label="1"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.two}
                onChange={handleChange}
                name="2"
              />
            }
            label="2"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.three}
                onChange={handleChange}
                name="3"
              />
            }
            label="3"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.four}
                onChange={handleChange}
                name="4"
              />
            }
            label="4"
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={state.five}
                onChange={handleChange}
                name="5"
              />
            }
            label="5+"
          />
        </div>
      </Fade>
    </div>
  );
};

export default RoomsFilter;
