import { Miniatures } from "../Miniatures";
export const data = [
  {
    name: "Perso",
    format: {
      landscape: {
        src: Miniatures.PersoLandscape,
      },
      portrait: {
        src: Miniatures.PersoPortrait,
      },
    },
    available: true,
  },
  {
    name: "Bersot Immobilier",
    format: {
      landscape: {
        src: Miniatures.BersoLandscape,
      },
    },
    available: true,
  },
  {
    name: "Elfi",
    format: {
      landscape: {
        src: Miniatures.BersoLandscape,
      },
    },
    available: true,
  },
  {
    name: "Osenat",
    format: {
      landscape: {
        src: Miniatures.GosselinLandscape,
      },
    },
    available: true,
  },
  {
    name: "Loft And House",
    format: {
      landscape: {
        src: Miniatures.LoftAndHouseLandscape,
      },
    },
    available: true,
  },
  {
    name: "IPH",
    format: {
      landscape: {
        src: Miniatures.IphLandscape,
      },
    },
    available: true,
  },
  {
    name: "IPH Second",
    format: {
      landscape: {
        src: Miniatures.IphSecondLandscape,
      },
    },
    available: true,
  },
  {
    name: "Londres",
    format: {
      landscape: {
        src: Miniatures.londonLandscape,
      },
      portrait: {
        src: Miniatures.londonPortrait,
      },
    },
    available: true,
  },
  {
    name: "Paris",
    format: {
      landscape: {
        src: Miniatures.ParisLandscape,
      },
      portrait: {
        src: Miniatures.ParisPortrait,
      },
    },
    available: true,
  },
  {
    name: "Barcelone",
    format: {
      landscape: {
        src: Miniatures.BarceloneLandscape,
      },
      portrait: {
        src: Miniatures.BarcelonePortrait,
      },
    },
    available: true,
  },
  {
    name: "Berlin",
    format: {
      landscape: {
        src: Miniatures.BerlinLandscape,
      },
      portrait: {
        src: Miniatures.BerlinPortrait,
      },
    },
    available: true,
  },
  {
    name: "Cupertino",
    format: {
      landscape: {
        src: Miniatures.CupertinoLandscape,
      },
      portrait: {
        src: Miniatures.CupertinoPortrait,
      },
    },
    available: true,
  },
  {
    name: "Singapour",
    format: {
      landscape: {
        src: Miniatures.SingapourLandscape,
      },
      portrait: {
        src: Miniatures.SingapourPortrait,
      },
    },
    available: true,
  },
  {
    name: "Sydney",
    format: {
      landscape: {
        src: Miniatures.SydneyLandscape,
      },
      portrait: {
        src: Miniatures.SydneyPortrait,
      },
    },
    available: true,
  },
  {
    name: "Madrid",
    format: {
      landscape: {
        src: Miniatures.MadridLandscape,
      },
    },
    available: true,
  },
  {
    name: "Sapene",
    format: {
      landscape: {
        src: Miniatures.SapeneLandscape,
      },
    },
    available: true,
  },
];
