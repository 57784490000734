/* eslint-disable no-unused-vars */
import React from "react";
import {
  GeoapifyContext,
  GeoapifyGeocoderAutocomplete,
} from "@geoapify/react-geocoder-autocomplete";
import "@geoapify/geocoder-autocomplete/styles/minimal.css";
import { Box, Divider, Typography } from "@material-ui/core";
import { BooleanInput, TextInput, useTranslate } from "react-admin";
import { config } from "../../../../../../constants/constant";

// HR COMPONENT
const Hr = () => {
  return <Divider light style={{ marginBottom: "20px" }} />;
};

const Localisation = (props) => {
  const translate = useTranslate();
  const { formProps, onPlaceSelect, initialValues } = props;
  return (
    <div style={{ marginTop: "15px" }}>
      <Typography variant="h6" gutterBottom mt="15px">
        {translate("resources.projects.typographies.localisation")}
      </Typography>
      <Hr />
      <Box
        display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}
        mt="5px"
        mb="20px"
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <GeoapifyContext apiKey={config.GEOAPIFY}>
            <GeoapifyGeocoderAutocomplete
              placeSelect={onPlaceSelect}
              placeholder={translate("resources.projects.enteraddress")}
              value={
                formProps.record.addressfull
                  ? formProps.record.addressfull
                  : initialValues.addressfull
              }
            />
          </GeoapifyContext>
        </Box>
      </Box>
      <Box
        display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}
        mt="5px"
        mb="20px"
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="lat"
            resource="projects"
            fullWidth
            initialValue={
              initialValues.lat ? initialValues.lat : formProps.record.lat
            }
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="lon"
            resource="projects"
            fullWidth
            initialValue={
              initialValues.lon ? initialValues.lon : formProps.record.lon
            }
          />
        </Box>
      </Box>
      <Box
        display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}
        mt="5px"
        mb="20px"
      >
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="city"
            resource="projects"
            fullWidth
            initialValue={
              initialValues.city ? initialValues.city : formProps.record.city
            }
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="postalcode"
            resource="projects"
            fullWidth
            initialValue={
              initialValues.postalcode
                ? initialValues.postalcode
                : formProps.record.postalcode
            }
          />
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}>
        {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="district" resource="projects" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="region" resource="projects" fullWidth />
        </Box> */}
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="location" resource="projects" fullWidth />
        </Box>
      </Box>
      {/* <Box
        display={{ xs: "block", sm: "block", md: "flex", lg: "flex" }}
        mt="15px"
      >
        <BooleanInput source="publish_address" resource="projects" />
      </Box> */}
    </div>
  );
};

export default Localisation;
