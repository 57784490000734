import { Input } from "@material-ui/core";
import styled from "styled-components";

export const DashConsoleContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 700px;
  background-color: #fff;
  box-shadow: 0px 30px 60px 0px rgb(145 152 156 / 9%);
`;
export const DashTitleContainer = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  text-align: left;
`;
export const DashTitle = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  font-weight: bolder;
  text-transform: uppercase;
`;

export const SearchInput = styled(Input)`
  && {
    padding: 0.5rem 1.2rem;
    border-radius: 50px;
    width: 280px;
    background-color: #f2f2f2;
    margin-left: auto;
    .MuiInputBase-input {
      background-color: #f2f2f2 !important;
      padding: 0.3rem;
    }
  }
`;

export const DashListContainer = styled.div`
  width: 100%;
  padding: 1rem 2rem 2rem;
  height: 75%;
  overflow: scroll;
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplate};
  grid-gap: 30px;
  justify-content: center;
`;
export const DashItemContainer = styled.div`
  width: 100%;
  margin: auto;
  border-radius: 10px;
  padding: 1rem;
  min-height: 250px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 10%), 0 10px 10px rgb(0 0 0 / 0%);
  text-align: center;
`;

export const DashItemTitleContainer = styled.div`
  padding: 0.5rem 15px;
  text-align: center;
`;

export const DashItemTitle = styled.span`
  text-transform: uppercase;
  font-weight: 900;
`;

export const DashItemBodyContainer = styled.div`
  padding: 1rem 5px;
`;
export const BodyAboContainer = styled.div`
  display: flex;
`;
