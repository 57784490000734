import React from "react";
import {
  Container,
  ContainerLeft,
  BackGroundImg,
  ContainerLeftDescription,
  ExcluContainer,
  Exclu,
  TitleContainer,
  Titletop,
  TitleBottom,
  DescriptionContainer,
  Description,
  ContainerRight,
  DivLine,
  ContainerRightDescription,
  Ref,
  AgencyLogo,
  LogoContainer,
  ContainerPrice,
  ContainerAlur,
  Category,
  ContainerCategory,
  IconsContainer,
  IconContainer,
  IconText,
  EnergyContainer,
  QrcodeContainer,
} from "./BerlinTemplate.styles";
import ModulePrice from "../../Modules/ModulePrice";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../Modules/ModulesElements.styles";

const BerlinTemplateLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      background={imageFirst}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <ContainerLeft>
        <BackGroundImg
          src={imageFirst}
          brightness={brightness}
          saturation={saturation}
        />
        {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
          <QrcodeContainer>
            <QRCode value={qrcodeEdit} size={140} />
          </QrcodeContainer>
        )}
        <ContainerLeftDescription>
          {isBanner === "true" && (
            <ExcluContainer colorPrimary={color.primary || "#C30010"}>
              <Exclu
                size={bannerFont.size}
                weight={bannerFont.weight}
                family={bannerFont.font}
                color={bannerFont.color}
              >
                {banner}
              </Exclu>
            </ExcluContainer>
          )}

          <TitleContainer>
            <Titletop
              colorTitle={colorTitle || "black"}
              size={titleTextFont.size}
              weight={titleTextFont.weight}
              family={titleTextFont.font}
            >
              {project.type !== titleText
                ? titleText
                : t(
                    choices.type.filter((item) => item.id === titleText)[0].name
                  )}
            </Titletop>
            <TitleBottom
              colorSubTitle={colorSubTitle || "black"}
              size={subTitleTextFont.size}
              weight={subTitleTextFont.weight}
              family={subTitleTextFont.font}
            >
              {subTitleText}
            </TitleBottom>
          </TitleContainer>
          <DescriptionContainer>
            <Description
              colorDesc={colorDesc || "white"}
              size={descFont.size}
              weight={descFont.weight}
              family={descFont.font}
            >
              {desc}
            </Description>
          </DescriptionContainer>
        </ContainerLeftDescription>
      </ContainerLeft>
      <ContainerRight>
        {agency.images.length ? (
          <LogoContainer>
            <AgencyLogo src={agency.images[0].src} alt="logo" />
          </LogoContainer>
        ) : null}

        <DivLine />
        <ContainerRightDescription>
          <ContainerCategory>
            <Category>
              {t(
                choices.category.filter(
                  (item) => item.id === project.category
                )[0].name
              )}
            </Category>
          </ContainerCategory>
          <ContainerPrice>
            <ModulePrice
              colorPrice={colorPrice || "black"}
              price={price}
              project={project}
              align={"left"}
              sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "40pt"}
              weight={priceFont.weight}
              family={priceFont.font}
              sizeHonoraire={"12pt"}
              sizeSpanPrice={"20pt"}
            />
          </ContainerPrice>
          <Ref
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            Réf : {refText}
          </Ref>
          <ContainerAlur>
            <RenderContext project={project}>
              <ModuleAlurDetails
                project={project}
                colorDesc={"black"}
                price={price}
                sizeText={"12pt"}
              />
              <AlurText sizeText={"12pt"} colorDesc={colorDesc || "black"}>
                Les informations sur les risques auxquels ce bien est exposé
                sont disponibles sur le site Géorisques :
                http://www.georisques.gouv.fr
              </AlurText>
            </RenderContext>
          </ContainerAlur>
        </ContainerRightDescription>
        <IconsContainer>
          <IconContainer>
            <Surface color={colorSecondary || "black"} size={45} />
            <IconText colorSecondary={colorSecondary || "black"}>
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Rooms color={colorSecondary || "black"} size={45} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Chambres color={colorSecondary || "black"} size={45} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Sdb color={colorSecondary || "black"} size={45} />
              <IconText colorSecondary={colorSecondary || "black"}>
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </IconsContainer>
        <EnergyContainer>
          <RenderContext project={project}>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                top: "100px",
                left: "35px",
              }}
            >
              <Dpe
                letter={
                  project.dpeLetter
                    ? DpeGesNoValue.includes(project.dpeLetter)
                      ? "vi"
                      : project.dpeLetter
                    : "vi"
                }
                conso={project.dpeValue}
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.dpeLetter) ||
                  !project.dpeLetter
                    ? "0.22"
                    : "0.25"
                }
                topTitle={"logement extrêmement performant"}
                bottomTitle={"logement extrêmement peu performant"}
                redInfoTop={"passoire"}
                redInfoBottom={"énergetique"}
                label={"consommation"}
                secondLabel={"émissions"}
                subLabel={"(énergie primaire)"}
                subValue={"kWh/m2/an"}
                secondSubValue={"kg CO₂/m²/an"}
                theme={"light"}
              />
            </div>
            <div
              style={{
                height: "fit-content",
                position: "absolute",
                bottom:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "40px"
                    : "-43px",
                left:
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "305px"
                    : "355px",
              }}
            >
              <Ges
                letter={
                  project.gesLetter
                    ? DpeGesNoValue.includes(project.gesLetter)
                      ? "vi"
                      : project.gesLetter
                    : "vi"
                }
                emissions={project.gesValue}
                zoom={
                  DpeGesNoValue.includes(project.gesLetter) ||
                  !project.gesLetter
                    ? "0.50"
                    : "0.30"
                }
                theme={"light"}
              />
            </div>
          </RenderContext>
        </EnergyContainer>
      </ContainerRight>
    </Container>
  );
});

export default BerlinTemplateLandscape;
