import React from "react";
import LoftAndHouseLandscape from "./LoftAndHouseLandscape/LoftAndHouseLandscape";

const index = React.forwardRef(
  ({ isMini, isPreview, orientation, ...otherProps }, ref) => {
    if (orientation === "landscape") {
      return (
        <LoftAndHouseLandscape
          isMini={isMini}
          isPreview={isPreview}
          ref={ref}
          {...otherProps}
        />
      );
    }
  }
);

export default index;
