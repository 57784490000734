import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
// Icons
// Translation
import { useTranslation } from "react-i18next";
// CSS
import "./index.css";
import { InfosContainer, PlayCircleFWI, PdfIcon } from "./Siderbar.styles.js";
import { Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LangSwitcher from "./LangSwitcher";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "black",
  },
  tooltip: {
    backgroundColor: "black",
    fontSize: "15px",
    fontWeight: 400,
  },
}));

const SidebarMobile = (props) => {
  const { infoProject, getAllClick } = props;
  const [lang, setLang] = useState("");

  const { i18n, t } = useTranslation();
  const classes = useStylesBootstrap();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const phoneRef = useRef();
  const whatsappRef = useRef();
  const containerRef = useRef();

  const storageTitle = null;

  useEffect(() => {
    if (lang === "") {
      const enums = ["fr-FR", "en-US", "it-IT"];
      const value =
        navigator.language === "en-GB" ? "en-US" : navigator.language;
      if (enums.includes(value)) {
        i18n.changeLanguage(navigator.language);
        setLang(navigator.language);
      } else {
        i18n.changeLanguage("fr-FR");
        setLang("fr-FR");
      }
    }
    tooltip();
    function handler(event) {
      if (phoneRef.current?.contains(event.target)) {
        getAllClick("countClickPhone");
      } else if (containerRef.current?.contains(event.target)) {
        getAllClick("countClick");
      }
      if (whatsappRef.current?.contains(event.target)) {
        getAllClick("countClickWhatsapp");
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, [
    getAllClick,
    matches,
    storageTitle,
    i18n.language,
    i18n,
    lang,
    infoProject.lang,
    infoProject._id,
  ]);

  const tooltip = () => {
    $('[data-toggle="tooltip"]').tooltip({
      boundary: "window",
      placement: "left",
      trigger: "hover",
    });
    $('[data-second="tooltip"]').tooltip({
      boundary: "window",
      placement: "left",
      trigger: "hover",
    });
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: props.isOld ? "absolute" : "unset",
        bottom: props.isOld ? "0" : "unset",
        height: "9%",
        width: "100%",
        backgroundColor: "#fff",
        boxShadow:
          "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          padding: "0 20px",
        }}
      >
        <InfosContainer
          className="circle-icon"
          onClick={() => props.setSelectView("home")}
          mobile
        >
          <div className="fas fa-lg fa-home" />
        </InfosContainer>
        {infoProject.images.length > 0 ? (
          <Tooltip
            title={t("sidebar.popoverImages")}
            placement="left"
            arrow
            classes={classes}
          >
            <InfosContainer
              className="circle-icon"
              data-toggle="modal"
              data-target="#modal-gallery"
              mobile
            >
              <div className="far fa-lg fa-image" />
            </InfosContainer>
          </Tooltip>
        ) : null}
        {infoProject.medias.length > 0 || infoProject.mediasurl.length > 0 ? (
          <Tooltip
            title={t("sidebar.popoverMedia")}
            placement="left"
            arrow
            classes={classes}
          >
            <InfosContainer
              className="circle-icon"
              data-toggle="modal"
              data-target="#modal-medias"
              mobile
            >
              <PlayCircleFWI className="fas" />
            </InfosContainer>
          </Tooltip>
        ) : null}
        <Tooltip
          title={t("sidebar.popoverDetails")}
          placement="left"
          arrow
          classes={classes}
        >
          <InfosContainer
            className="circle-icon"
            data-toggle="modal"
            data-target="#modal-details"
            mobile
          >
            <div className="fas fa-lg fa-info" />
          </InfosContainer>
        </Tooltip>
        {infoProject.files && infoProject.files.length ? (
          <Tooltip
            title={t("sidebar.popoverFiles")}
            placement="left"
            arrow
            classes={classes}
          >
            <InfosContainer
              className="circle-icon"
              data-toggle="modal"
              data-target="#modal-files"
              mobile
            >
              <PdfIcon className="fas" $mobile />
            </InfosContainer>
          </Tooltip>
        ) : null}
        {/* <Tooltip
          title={t("sidebar.popoverRdv")}
          placement="left"
          arrow
          classes={classes}
        >
          <InfosContainer
            className="circle-icon"
            data-toggle="modal"
            data-target="#modal-contact"
            mobile
          >
            <div className="fas fa-lg fa-calendar-alt" />
          </InfosContainer>
        </Tooltip> */}
        <Tooltip
          title={t("sidebar.popoverShare")}
          placement="left"
          arrow
          classes={classes}
        >
          <InfosContainer
            className="circle-icon"
            data-toggle="modal"
            data-target="#modal-share"
            mobile
          >
            <div className="fas fa-lg fa-share-alt" />
          </InfosContainer>
        </Tooltip>
        <LangSwitcher />
      </div>
    </div>
  );
};

export default SidebarMobile;
