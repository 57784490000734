/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";
import logoVisibble from "../../assets/logo-resize.png";
// CSS
import "./index.css";
import {
  Container,
  ButtonContainer,
  Logo,
  LogoContainer,
  SendButton,
  TileBarContainer,
  TitleAgence,
} from "./TileBar.styles";

const TitleBar = (props) => {
  const { project, agency, user } = props;
  const { t } = useTranslation();

  return (
    <TileBarContainer>
      <Container col={3} justify={"flex-start"} direction={"initial"}>
        <LogoContainer>
          <Logo
            src={agency.images.length ? agency.images[0].src : logoVisibble}
            alt="logo blanc"
          />
        </LogoContainer>
      </Container>
      <Container col={6} justify={"center"} direction={"column"}>
        <TitleAgence>
          {agency !== null
            ? agency && agency.name
            : user.firstname + " " + user.lastname}
        </TitleAgence>
      </Container>
      <ButtonContainer col={3} justify={"flex-start"} direction={"initial"}>
        {!props.only360 &&
          project.acceptOffer &&
          project.category !== "Location" && (
            <SendButton data-toggle="modal" data-target="#modal-offer">
              {t("titlebar.offerbutton")}
            </SendButton>
          )}
      </ButtonContainer>
    </TileBarContainer>
  );
};

// TitleBar.propTypes = {
//   infoProject: PropTypes.object,
//   agency: PropTypes.object,
//   user: PropTypes.object,
//   showDetails: PropTypes.func,
// };

export default TitleBar;
