import React from "react";
import { getDataProject, getLeadsData } from "../../../../Utils/axiosRequest";

const StatisticsWrapper = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { data: false, leads: 0, loading: true };
    }

    async getLeads(data) {
      data.map((item) => {
        return item.leads.map((lead) => {
          if (lead.reference === this.props.record.reference) {
            this.setState((prev) => ({
              leads: prev.leads + 1,
            }));
          }
          return true;
        });
      });
    }

    async componentDidMount() {
      const getStatistics = await getDataProject("_id", this.props.record.id);
      this.setState({ data: getStatistics.data.data[0] });
      const fetchLeads = await getLeadsData(this.props.record.agenceId);
      const getCountLeads = await this.getLeads(fetchLeads.data);
      if (getCountLeads) {
        this.setState({ loading: false });
      }
    }

    render() {
      if (!this.state.data && this.state.loading) {
        return <div />;
      }
      return (
        <WrappedComponent {...this.props} {...this.state}>
          {this.props.children}
        </WrappedComponent>
      );
    }
  };
};

export default StatisticsWrapper;
