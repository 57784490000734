import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const Doughnuts = (props) => {
  return (
    <div style={{ width: "100px" }}>
      <Doughnut data={props.data} />
    </div>
  );
};

export default Doughnuts;
