import {
  addMonths,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";

const useRanges = () => {
  const day = new Date();
  day.setHours(0, 0, 0, 0);
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 0);
  const today = {
    start: day,
    end: endOfDay,
  };
  const yesterday = {
    start: subDays(day, 1),
    end: subDays(endOfDay, 1),
  };
  const thisWeek = {
    start: startOfWeek(day, { weekStartsOn: 1 }),
    end: endOfWeek(endOfDay, { weekStartsOn: 1 }),
  };
  const lastWeek = {
    start: subWeeks(startOfWeek(day, { weekStartsOn: 1 }), 1),
    end: subWeeks(endOfWeek(endOfDay, { weekStartsOn: 1 }), 1),
  };
  const thisMonth = {
    start: startOfMonth(day),
    end: subDays(addMonths(startOfMonth(endOfDay), 1), 1),
  };
  const lastMonth = {
    start: subMonths(startOfMonth(day), 1),
    end: subDays(startOfMonth(endOfDay), 1),
  };

  return { thisWeek, lastWeek, thisMonth, lastMonth, yesterday, today };
};

export default useRanges;
