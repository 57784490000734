/* eslint-disable no-unused-vars */

export const filterTips = (category, value, state, handleChangeFilter) => {
  if (category === "userId") {
    if (value !== "") {
      handleChangeFilter({ userId: [value] });
    } else {
      handleChangeFilter({ userId: [] });
    }
  }

  if (category === "price") {
    if (value !== "") {
      handleChangeFilter({ price: { min: value.min, max: value.max } });
    } else {
      handleChangeFilter({ price: {} });
    }
  }
};

export const filterData = (data, query) => {
  const hasUserId = ["userId"];
  const filteredData = data.filter((item) => {
    for (let key in query) {
      if (item[key] === undefined) {
        return false;
      } else if (hasUserId.includes(key)) {
        if (query[key] !== null && !query[key].includes(item[key])) {
          return false;
        }
      } else if (!query[key].includes(item[key])) {
        return false;
      }
    }
    return true;
  });
  return filteredData;
};

export const filterDataView = (data, setDate) => {
  let countView = 0;
  let countClick = 0;
  let countClickVideo = 0;
  let countClickPhone = 0;
  let countClickWhatsapp = 0;
  const filteredData = data.filter((item) => {
    let foundView = false;
    item.countView.map((count) => {
      const date = new Date(count.date);
      date.setHours(0, 0, 0, 0);
      if (setDate.min !== null) {
        if (date >= setDate.min) {
          if (setDate.max !== null) {
            if (date <= setDate.max) {
              countView = countView + 1;
            }
          } else {
            countView = countView + 1;
          }
        }
      }
      return true;
    });
    item.countClick.map((count) => {
      const date = new Date(count.date);
      date.setHours(0, 0, 0, 0);
      if (setDate.min !== null) {
        if (date >= setDate.min) {
          if (setDate.max !== null) {
            if (date <= setDate.max) {
              countClick = countClick + 1;
            }
          } else {
            countClick = countClick + 1;
          }
        }
      }
      return true;
    });
    item.countClickVideo.map((count) => {
      const date = new Date(count.date);
      date.setHours(0, 0, 0, 0);
      if (setDate.min !== null) {
        if (date >= setDate.min) {
          if (setDate.max !== null) {
            if (date <= setDate.max) {
              countClickVideo = countClickVideo + 1;
            }
          } else {
            countClickVideo = countClickVideo + 1;
          }
        }
      }
      return true;
    });
    item.countClickPhone.map((count) => {
      const date = new Date(count.date);
      date.setHours(0, 0, 0, 0);
      if (setDate.min !== null) {
        if (date >= setDate.min) {
          if (setDate.max !== null) {
            if (date <= setDate.max) {
              countClickPhone = countClickPhone + 1;
            }
          } else {
            countClickPhone = countClickPhone + 1;
          }
        }
      }
      return true;
    });
    item.countClickWhatsapp.map((count) => {
      const date = new Date(count.date);
      date.setHours(0, 0, 0, 0);
      if (setDate.min !== null) {
        if (date >= setDate.min) {
          if (setDate.max !== null) {
            if (date <= setDate.max) {
              countClickWhatsapp = countClickWhatsapp + 1;
            }
          } else {
            countClickWhatsapp = countClickWhatsapp + 1;
          }
        }
      }
      return true;
    });
    return true;
  });
  return {
    countView,
    countClick,
    countClickVideo,
    countClickWhatsapp,
    countClickPhone,
  };
};

const getDataDate = (date) => {
  const formerDate = new Date(date);
  formerDate.setHours(0, 0, 0, 0);
  return formerDate;
};
const getDataEnd = () => {
  const start = new Date();
  var lastDay = new Date(start.getFullYear(), start.getMonth() + 1, 0);

  lastDay.setMonth(lastDay.getMonth() - 1);
  lastDay.setHours(0, 0, 0, 0);
  return lastDay;
};
const getDataEndActual = () => {
  const start = new Date();
  var lastDay = new Date(start.getFullYear(), start.getMonth() + 1, 0);
  lastDay.setHours(0, 0, 0, 0);
  return lastDay;
};
const getStartDate = () => {
  const start = new Date();
  var firstDay = new Date(start.getFullYear(), start.getMonth(), 1);
  // const m = firstDay.getMonth();
  firstDay.setMonth(firstDay.getMonth() - 1);
  firstDay.setHours(0, 0, 0, 0);
  return firstDay;
};
const getStartDateActual = () => {
  const start = new Date();
  var firstDay = new Date(start.getFullYear(), start.getMonth(), 1);
  firstDay.setHours(0, 0, 0, 0);
  return firstDay;
};

export const filterPourcentPrevious = (data) => {
  let countView = 0;
  let countClick = 0;
  let countClickVideo = 0;
  let countClickPhone = 0;
  let countClickWhatsapp = 0;
  const filteredData = data.filter((item) => {
    let foundView = false;
    item.countView.map((count) => {
      if (getStartDate() !== null) {
        if (getDataDate(count.date) >= getStartDate()) {
          if (getDataEnd() !== null) {
            if (getDataDate(count.date) <= getDataEnd()) {
              countView = countView + 1;
            }
          } else {
            countView = countView + 1;
          }
        }
      }
      return true;
    });
    item.countClick.map((count) => {
      if (getStartDate() !== null) {
        if (getDataDate(count.date) >= getStartDate()) {
          if (getDataEnd() !== null) {
            if (getDataDate(count.date) <= getDataEnd()) {
              countClick = countClick + 1;
            }
          } else {
            countClick = countClick + 1;
          }
        }
      }
      return true;
    });
    item.countClickVideo.map((count) => {
      if (getStartDate() !== null) {
        if (getDataDate(count.date) >= getStartDate()) {
          if (getDataEnd() !== null) {
            if (getDataDate(count.date) <= getDataEnd()) {
              countClickVideo = countClickVideo + 1;
            }
          } else {
            countClickVideo = countClickVideo + 1;
          }
        }
      }
      return true;
    });
    item.countClickPhone.map((count) => {
      if (getStartDate() !== null) {
        if (getDataDate(count.date) >= getStartDate()) {
          if (getDataEnd() !== null) {
            if (getDataDate(count.date) <= getDataEnd()) {
              countClickPhone = countClickPhone + 1;
            }
          } else {
            countClickPhone = countClickPhone + 1;
          }
        }
      }
      return true;
    });
    item.countClickWhatsapp.map((count) => {
      if (getStartDate() !== null) {
        if (getDataDate(count.date) >= getStartDate()) {
          if (getDataEnd() !== null) {
            if (getDataDate(count.date) <= getDataEnd()) {
              countClickWhatsapp = countClickWhatsapp + 1;
            }
          } else {
            countClickWhatsapp = countClickWhatsapp + 1;
          }
        }
      }
      return true;
    });
    return true;
  });
  return {
    countView,
    countClick,
    countClickVideo,
    countClickWhatsapp,
    countClickPhone,
  };
};
export const filterPourcentActual = (data) => {
  let countView = 0;
  let countClick = 0;
  let countClickVideo = 0;
  let countClickPhone = 0;
  let countClickWhatsapp = 0;
  const filteredData = data.filter((item) => {
    let foundView = false;
    item.countView.map((count) => {
      if (getStartDateActual() !== null) {
        if (getDataDate(count.date) >= getStartDateActual()) {
          if (getDataEndActual() !== null) {
            if (getDataDate(count.date) <= getDataEndActual()) {
              countView = countView + 1;
            }
          } else {
            countView = countView + 1;
          }
        }
      }
      return true;
    });
    item.countClick.map((count) => {
      if (getStartDateActual() !== null) {
        if (getDataDate(count.date) >= getStartDateActual()) {
          if (getDataEndActual() !== null) {
            if (getDataDate(count.date) <= getDataEndActual()) {
              countClick = countClick + 1;
            }
          } else {
            countClick = countClick + 1;
          }
        }
      }
      return true;
    });
    item.countClickVideo.map((count) => {
      if (getStartDateActual() !== null) {
        if (getDataDate(count.date) >= getStartDateActual()) {
          if (getDataEndActual() !== null) {
            if (getDataDate(count.date) <= getDataEndActual()) {
              countClickVideo = countClickVideo + 1;
            }
          } else {
            countClickVideo = countClickVideo + 1;
          }
        }
      }
      return true;
    });
    item.countClickPhone.map((count) => {
      if (getStartDateActual() !== null) {
        if (getDataDate(count.date) >= getStartDateActual()) {
          if (getDataEndActual() !== null) {
            if (getDataDate(count.date) <= getDataEndActual()) {
              countClickPhone = countClickPhone + 1;
            }
          } else {
            countClickPhone = countClickPhone + 1;
          }
        }
      }
      return true;
    });
    item.countClickWhatsapp.map((count) => {
      if (getStartDateActual() !== null) {
        if (getDataDate(count.date) >= getStartDateActual()) {
          if (getDataEndActual() !== null) {
            if (getDataDate(count.date) <= getDataEndActual()) {
              countClickWhatsapp = countClickWhatsapp + 1;
            }
          } else {
            countClickWhatsapp = countClickWhatsapp + 1;
          }
        }
      }
      return true;
    });
    return true;
  });
  return {
    countView,
    countClick,
    countClickVideo,
    countClickWhatsapp,
    countClickPhone,
  };
};
export const filterDataLeads = (data, setDate) => {
  let countLeads = 0;
  const filteredData = data.filter((item) => {
    item.leads.map((count) => {
      const date = new Date(count.date);
      date.setHours(0, 0, 0, 0);
      if (setDate.min !== null) {
        if (date >= setDate.min) {
          if (setDate.max !== null) {
            if (date <= setDate.max) {
              countLeads = countLeads + 1;
            }
          } else {
            countLeads = countLeads + 1;
          }
        }
      }

      return true;
    });
    return true;
  });
  return countLeads;
};
export const filterPourcentPreviousLead = (data) => {
  let countLeads = 0;
  const filteredData = data.filter((item) => {
    item.leads.map((count) => {
      if (getStartDate() !== null) {
        if (getDataDate(count.date) >= getStartDate()) {
          if (getDataEnd() !== null) {
            if (getDataDate(count.date) <= getDataEnd()) {
              countLeads = countLeads + 1;
            }
          } else {
            countLeads = countLeads + 1;
          }
        }
      }

      return true;
    });
    return true;
  });
  return countLeads;
};
export const filterPourcentActualLead = (data) => {
  let countLeads = 0;
  const filteredData = data.filter((item) => {
    item.leads.map((count) => {
      if (getStartDateActual() !== null) {
        if (getDataDate(count.date) >= getStartDateActual()) {
          if (getDataEndActual() !== null) {
            if (getDataDate(count.date) <= getDataEndActual()) {
              countLeads = countLeads + 1;
            }
          } else {
            countLeads = countLeads + 1;
          }
        }
      }

      return true;
    });
    return true;
  });
  return countLeads;
};

export const buildFilter = (filter) => {
  let query = {};
  for (let keys in filter) {
    if (
      filter[keys].constructor === Object ||
      (filter[keys].constructor === Array && filter[keys].length > 0)
    ) {
      query[keys] = filter[keys];
    }
  }
  return query;
};
