import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import surface from "../../../../../assets/LogoTemplates/Iph/surface.png";
import rooms from "../../../../../assets/LogoTemplates/Iph/pieces.png";
import bedrooms from "../../../../../assets/LogoTemplates/Iph/chambre.png";
import sdb from "../../../../../assets/LogoTemplates/Iph/salle-de-bain.png";
import garden from "../../../../../assets/LogoTemplates/Iph/surface-jardin.png";
import terrace from "../../../../../assets/LogoTemplates/Iph/terrasse.png";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import QRCode from "react-qr-code";

import {
  AlurContainer,
  AlurText,
  BackgroundImage,
  BottomContainer,
  BottomLastContainer,
  Container,
  CoproContainer,
  DpeContainer,
  DpeText,
  EnergyTextContainer,
  Footer,
  Honoraire,
  HonoraryContainer,
  Icon,
  IconContainer,
  IconsContainer,
  IconText,
  Logo,
  LogoContainer,
  Price,
  PriceContainer,
  QrcodeContainer,
  SoldBanner,
  SoldBannerText,
  TitleContainer,
  TitleText,
} from "./styles";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";

const IphLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const {
    imageFirst,
    brightness,
    saturation,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    subTitleText,
    colorPrice,
    price,
    priceFont,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;
  const [currency] = useState(project.currency ? project.currency : "€");

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  const getPrice = (value) => {
    const integer = Math.floor(value);
    const price = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return price;
  };

  return (
    <Container
      mirror={mirror}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      marginAuto={props.isPreview}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <BackgroundImage
        src={imageFirst}
        brightness={brightness}
        saturation={saturation}
      />
      {banner && banner.length ? (
        <SoldBanner colorPrimary={color.primary}>
          <SoldBannerText
            size={bannerFont.size}
            weight={bannerFont.weight}
            family={bannerFont.font}
            color={bannerFont.color}
          >
            {banner}
          </SoldBannerText>
        </SoldBanner>
      ) : null}
      {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
        <QrcodeContainer>
          <QRCode value={qrcodeEdit} size={120} />
        </QrcodeContainer>
      )}
      <Footer src="https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FTemplates%2FIPH%2Flh-footer.png?alt=media&token=f1ffedfa-e106-45a5-8727-cc3259e4df1f" />
      <LogoContainer>
        <Logo
          src={
            "https://firebasestorage.googleapis.com/v0/b/visibble.appspot.com/o/assets%2FTemplates%2FIPH%2Flogo-iph.png?alt=media&token=85d571b2-ac9a-43af-b59e-ab6665f23901"
          }
          alt="logo agence"
        />
      </LogoContainer>
      <RenderContext project={project}>
        <DpeContainer>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              top:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "5px"
                  : "0px",
              left:
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "25px"
                  : "0px",
            }}
          >
            <Dpe
              letter={
                project.dpeLetter
                  ? DpeGesNoValue.includes(project.dpeLetter)
                    ? "vi"
                    : project.dpeLetter
                  : "vi"
              }
              conso={project.dpeValue}
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.dpeLetter) || !project.dpeLetter
                  ? "0.14"
                  : "0.17"
              }
              topTitle={"logement extrêmement performant"}
              bottomTitle={"logement extrêmement peu performant"}
              redInfoTop={"passoire"}
              redInfoBottom={"énergetique"}
              label={"consommation"}
              secondLabel={"émissions"}
              subLabel={"(énergie primaire)"}
              subValue={"kWh/m2/an"}
              secondSubValue={"kg CO₂/m²/an"}
              theme={"dark"}
            />
          </div>
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              bottom:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "5px"
                  : "-45px",
              left:
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "195px"
                  : "207px",
            }}
          >
            <Ges
              letter={
                project.gesLetter
                  ? DpeGesNoValue.includes(project.gesLetter)
                    ? "vi"
                    : project.gesLetter
                  : "vi"
              }
              emissions={project.gesValue}
              zoom={
                DpeGesNoValue.includes(project.gesLetter) || !project.gesLetter
                  ? "0.3"
                  : "0.18"
              }
              theme={"dark"}
            />
          </div>
        </DpeContainer>
        <EnergyTextContainer>
          <DpeText>
            {t("modalprint.dpeMinMaxInfo")} :{" "}
            {project.dpeCoutMinConso && project.dpeCoutMinConso !== "" ? (
              <>
                {`${t("modalprint.between")} ${project.dpeCoutMinConso}€ ${t(
                  "modalprint.and"
                )} ${project.dpeCoutMaxConso}€ ${t("modalprint.perYear")} `}
              </>
            ) : (
              t("details.modalprint.undisclosed")
            )}
          </DpeText>
          {project.dpeCoutMinConso &&
            project.dpeCoutMinConso !== "" &&
            project.dpeAnneRefConso &&
            project.dpeAnneRefConso !== "" && (
              <DpeText>
                {t("modalprint.refYear")} {project.dpeAnneRefConso}{" "}
                {t("modalprint.refYearEnd")}
              </DpeText>
            )}
          <DpeText>
            Les informations sur les risques auxquels ce bien est exposé sont
            disponibles sur le site Géorisques : http://www.georisques.gouv.fr
          </DpeText>
        </EnergyTextContainer>
      </RenderContext>
      <BottomContainer>
        <TitleContainer>
          <TitleText
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
            colorTitle={colorTitle || "white"}
          >
            {project.type !== titleText
              ? titleText
              : t(
                  choices.type.filter((item) => item.id === titleText)[0].name
                )}{" "}
            {subTitleText}
          </TitleText>
        </TitleContainer>
        <IconsContainer>
          <IconContainer>
            <Icon src={surface} width="25px" height="25px" />
            <IconText>{project.area.total} m2</IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer>
              <Icon src={rooms} width="25px" height="25px" />
              <IconText>
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer>
              <Icon src={bedrooms} width="33.37px" height="25px" />
              <IconText>
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer>
              <Icon src={sdb} width="27.58px" height="25px" />
              <IconText>
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.gardenArea && project.gardenArea !== "" ? (
            <IconContainer>
              <Icon src={garden} width="29.8px" height="25px" />
              <IconText>{project.gardenArea} m2</IconText>
            </IconContainer>
          ) : null}
          {project.terrace && project.terrace > 0 ? (
            <IconContainer>
              <Icon src={terrace} width="29.69px" height="25px" />
              <IconText>{t("modalprint.terrace")}</IconText>
            </IconContainer>
          ) : null}
        </IconsContainer>
        <BottomLastContainer>
          <PriceContainer>
            <Price
              colorPrice={colorPrice || "white"}
              size={priceFont.size !== "" ? `${priceFont.size}pt` : "24px"}
              weight={priceFont.weight || 600}
              family={priceFont.family || "'Lato', sans-serif !important"}
            >
              {project.hideprice && t("modalprint.consult")}
              {!project.hideprice &&
                `${
                  project.category === choices.category[2].id
                    ? t("details.modalprint.startAt")
                    : ""
                } ${getPrice(price)} ${
                  project.currency ? project.currency : "€"
                }`}{" "}
              {project.category === choices.category[1].id && !project.hideprice
                ? " " + t("modalprint.chargespermonth")
                : ""}
              {project.periodicity &&
                project.category === choices.category[2].id &&
                " " +
                  t(
                    choices.periodicity.filter(
                      (item) => item.id === project.periodicity
                    )[0].name
                  )}
            </Price>
            {project.category === choices.category[0].id &&
            !project.hideprice ? (
              <Honoraire
                size={
                  priceFont.size !== "" ? `${priceFont.size - 10}px` : "14px"
                }
                family={priceFont.family || "'Lato', sans-serif !important"}
                colorPrice={colorPrice || "white"}
              >
                {project.sharedCommission
                  ? t("details.fees.feesShared")
                  : project.commission
                  ? "Honoraires TTC à la charge de l'acheteur"
                  : "Honoraires TTC à la charge du vendeur"}
              </Honoraire>
            ) : null}
            <br />
            <Honoraire
              size={priceFont.size !== "" ? `${priceFont.size - 10}px` : "14px"}
              colorPrice={colorPrice || "white"}
              family={priceFont.family || "'Lato', sans-serif !important"}
            >
              N° Mandat: {project.reference}
            </Honoraire>
          </PriceContainer>
          <RenderContext project={project}>
            <AlurContainer>
              <HonoraryContainer>
                {props.project.category === choices.category[1].id ? (
                  <AlurText>
                    {t("details.modalprint.monthlycharge")} :{" "}
                    {props.project.monthlycharge > 0
                      ? props.project.monthlycharge + " " + currency
                      : t("modalprint.undisclosed")}{" "}
                  </AlurText>
                ) : null}
                {props.project.category === choices.category[1].id ? (
                  <AlurText>
                    Honoraires à la charge du locataire :{" "}
                    {props.project.fees > 0
                      ? props.project.fees + " " + currency
                      : t("modalprint.undisclosed")}{" "}
                  </AlurText>
                ) : null}
                {props.project.category === choices.category[1].id ? (
                  <AlurText>
                    {t("modalprint.feesetatdeslieux")} :{" "}
                    {props.project.feesetatdeslieux > 0
                      ? props.project.feesetatdeslieux + " " + currency
                      : t("modalprint.undisclosed")}{" "}
                  </AlurText>
                ) : null}
                {props.project.category === choices.category[1].id ? (
                  <AlurText>
                    {t("details.modalprint.feesdepotgarantie")} :{" "}
                    {props.project.feesdepotgarantie > 0
                      ? props.project.feesdepotgarantie + " " + currency
                      : t("modalprint.undisclosed")}{" "}
                  </AlurText>
                ) : null}
                {(props.project.commission || props.project.sharedCommission) &&
                props.project.category === choices.category[0].id ? (
                  <AlurText>
                    Honoraires TTC:{" "}
                    {props.project.fees !== ""
                      ? props.project.fees +
                        (props.project.feespercent ? " %" : " " + currency)
                      : t("modalprint.undisclosed")}{" "}
                  </AlurText>
                ) : null}
                {(props.project.sharedCommission || props.project.commission) &&
                props.project.category === choices.category[0].id &&
                props.project.amountWithoutHonorary ? (
                  <AlurText>
                    Prix hors honoraires :{" "}
                    {getPrice(props.project.amountWithoutHonorary)} {currency}
                  </AlurText>
                ) : null}
              </HonoraryContainer>
              <CoproContainer>
                {props.project.nblotcopro > 0 ? (
                  <>
                    <AlurText>
                      {t("modalprint.copropriete")} {props.project.nblotcopro}{" "}
                      {t("modalprint.lots")}
                    </AlurText>
                    <AlurText>
                      {t("modalprint.feescopro")} :{" "}
                      {props.project.feescopro
                        ? props.project.feescopro + currency
                        : t("modalprint.undisclosed")}
                    </AlurText>
                    <AlurText>
                      {props.project.procsyndicale &&
                      props.project.type !== choices.type[1].id &&
                      props.project.type !== choices.type[2].id &&
                      props.project.type !== choices.type[3].id
                        ? "Procédure syndicale en cours"
                        : "Pas de procédure en cours"}
                    </AlurText>
                  </>
                ) : null}
              </CoproContainer>
            </AlurContainer>
          </RenderContext>
        </BottomLastContainer>
      </BottomContainer>
    </Container>
  );
});

export default IphLandscape;
