import styled from "styled-components";

export const Container = styled.div`
  && {
    position: relative;
    height: 296.5mm;
    width: 420mm;
    min-width: 420mm;
    background: #000000 0% 0% no-repeat padding-box;
    display: flex;
    zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
    margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
    transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};
    overflow: hidden;
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      @page {
        size: A3 landscape;
      }
    }

    @media print {
      html,
      body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
    }

    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }

    @page {
      size: auto;
      margin: 20mm;
    }
  }
`;
export const BackgroundImage = styled.img`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 80%;
  height: 65%;
  object-fit: cover;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const LogoContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 350px;
  height: 110px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const Logo = styled.img`
  width: 100%;
`;
export const PriceContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 970px;
  width: 340px;
  height: 115px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DescriptionContainer = styled.div`
  position: absolute;
  top: 800px;
  left: 40px;
  width: 79%;
`;

export const Description = styled.p`
  text-align: left;
  line-height: 115%;
  color: ${(props) => props.colorDesc};
  white-space: pre-wrap;
  max-height: 300pt;
  overflow: hidden;
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "16px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans', sans-serif"} !important;
`;
export const IconsContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 1390px;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
`;
export const IconText = styled.p`
  font-size: 13pt;
  color: ${(props) => props.colorSecondary};
  margin-top: 10px;
  margin-bottom: 0;
`;
export const EnergyContainer = styled.div`
  position: absolute;
  top: 705px;
  right: 0;
  width: 275px;
  height: 410px;
  overflow: hidden;
`;
export const AlurContainer = styled.div`
  width: 79%;
  height: 120px;
  position: absolute;
  left: 40px;
  top: 970px;
  border: solid 3px white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
export const HonoraryContainer = styled.div`
  width: 100%;
  height: 100%;
  border-right: solid 3px white;
  padding-top: 15px;
  padding-left: 15px;
`;
export const CoproContainer = styled.div`
  width: 100%;
  height: 100%;
  border-right: solid 3px white;
  padding-left: 15px;
  padding-top: 15px;
`;

export const EnergyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
`;

export const AlurText = styled.p`
  margin-bottom: 5px;
  color: white;
  font-size: 14px;
`;
export const SoldBanner = styled.div`
  height: 80px;
  width: 881px;
  position: absolute;
  top: 227px;
  left: -175px;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "#c60000"};
  transform: rotate(-42deg);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SoldBannerText = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "55px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "800")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Open Sans' sans-serif"} !important;
`;
