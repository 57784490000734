import React from "react";
import QRCode from "react-qr-code";
import {
  Container,
  ContainerLeft,
  ContainerVisibble,
  Visibble,
  ContainerTitle,
  ContainerDescription,
  ContainerPrice,
  ContainerDetails,
  ContainerRight,
  ContainerImg,
  ContainerBottom,
  Title,
  Description,
  Ref,
  ImgProperty,
  SubTitle,
  IconText,
  IconContainer,
  IconsContainer,
  QrcodeContainer,
  ExcluContainer,
  ExcluText,
} from "./CupertinoPaysage.styles";
import ModulePrice from "../../Modules/ModulePrice";
import Dpe from "../../../../../Components/NewDpeGes/Dpe";
import Ges from "../../../../../Components/NewDpeGes/Ges";
import { choices } from "../../../PrintPage/Utils/dataChoices";
import { useTranslation } from "react-i18next";
import ModuleAlurDetails from "../../Modules/ModuleAlurDetails";
import Surface from "../../../../../assets/LogoTemplates/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sdb";
import RenderContext from "../../ModulesElements/RenderContext";
import { DpeGesNoValue } from "../../DpeGesNoValue";
import { AlurText } from "../../Modules/ModulesElements.styles";

const CuppertinoTemplateLandscape = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    imageFirst,
    brightness,
    saturation,
    isBanner,
    bannerFont,
    mirror,
    banner,
    color,
    project,
    colorTitle,
    titleText,
    titleTextFont,
    colorSubTitle,
    subTitleText,
    subTitleTextFont,
    agency,
    colorDesc,
    descFont,
    desc,
    colorPrice,
    price,
    priceFont,
    refFont,
    refText,
    colorSecondary,
    qrcode,
    qrcodeMode,
    qrcodeMobile,
  } = props;

  const qrcodeEdit =
    qrcodeMode === "qrcode"
      ? qrcode
      : qrcodeMode === "qrcodeMobile"
      ? qrcodeMobile
      : null;

  return (
    <Container
      mirror={mirror}
      marginAuto={props.isPreview}
      zoom={props.isMini ? props.isMini : props.isPreview ? "0.4" : null}
      className={props.isPreview ? "" : "d-print-flex d-none"}
      ref={ref}
    >
      <ContainerLeft>
        <ContainerVisibble>
          {agency.images && agency.images.length ? (
            <Visibble src={agency.images[0].src} />
          ) : null}
        </ContainerVisibble>
        <ContainerTitle>
          <Title
            colorTitle={colorTitle !== "" ? colorTitle : "black"}
            size={titleTextFont.size}
            weight={titleTextFont.weight}
            family={titleTextFont.font}
          >
            {project.type !== titleText
              ? titleText
              : t(choices.type.filter((item) => item.id === titleText)[0].name)}
          </Title>
          <SubTitle
            size={subTitleTextFont.size}
            weight={subTitleTextFont.weight}
            family={subTitleTextFont.font}
            colorSubTitle={colorSubTitle !== "" ? colorSubTitle : "black"}
          >
            {subTitleText}
          </SubTitle>
          <Ref
            size={refFont.size}
            weight={refFont.weight}
            family={refFont.font}
            color={refFont.color}
          >
            Ref: {refText}
          </Ref>
        </ContainerTitle>
        <ContainerDescription>
          <Description
            colorDesc={colorDesc !== "" ? colorDesc : "black"}
            size={descFont.size}
            weight={descFont.weight}
            family={descFont.font}
          >
            {desc}
          </Description>
        </ContainerDescription>
        <ContainerPrice>
          <ModulePrice
            colorPrice={colorPrice || "black"}
            price={price}
            project={project}
            align={"left"}
            sizePrice={priceFont.size !== "" ? `${priceFont.size}pt` : "35pt"}
            weight={priceFont.weight}
            family={priceFont.font}
            sizeHonoraire={"12pt"}
            sizeSpanPrice={"15pt"}
          />
        </ContainerPrice>

        <ContainerDetails>
          <RenderContext project={project}>
            <ModuleAlurDetails
              project={project}
              colorDesc={colorDesc || "black"}
              price={price}
              sizeText={"13pt"}
            />
            <AlurText sizeText={"13pt"} colorDesc={colorDesc || "black"}>
              Les informations sur les risques auxquels ce bien est exposé sont
              disponibles sur le site Géorisques : http://www.georisques.gouv.fr
            </AlurText>
          </RenderContext>
        </ContainerDetails>
        <IconsContainer colorPrimary={color.primary || "black"}>
          <IconContainer>
            <Surface color={colorSecondary || "white"} size={55} />
            <IconText
              colorSecondary={colorSecondary || "white"}
              marginLeft="15pt"
            >
              {project.area.total} m2
            </IconText>
          </IconContainer>
          {project.rooms && project.rooms > 0 ? (
            <IconContainer>
              <Rooms color={colorSecondary || "white"} size={55} />
              <IconText
                colorSecondary={colorSecondary || "white"}
                marginLeft="15pt"
              >
                {project.rooms} {t("modalprint.rooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.bedrooms && project.bedrooms > 0 ? (
            <IconContainer>
              <Chambres color={colorSecondary || "white"} size={55} />
              <IconText
                colorSecondary={colorSecondary || "white"}
                marginLeft="15pt"
              >
                {project.bedrooms} {t("modalprint.bedrooms")}
              </IconText>
            </IconContainer>
          ) : null}
          {project.sdb && project.sdb > 0 ? (
            <IconContainer marginLeft="35pt">
              <Sdb color={colorSecondary || "white"} size={55} />
              <IconText
                colorSecondary={colorSecondary || "white"}
                marginLeft="15pt"
              >
                {project.sdb ? project.sdb : 0} {t("modalprint.sdb")}
              </IconText>
            </IconContainer>
          ) : null}
        </IconsContainer>
      </ContainerLeft>
      <ContainerRight>
        <ContainerImg>
          <ImgProperty
            src={imageFirst}
            brightness={brightness}
            saturation={saturation}
          />
          {isBanner === "true" && (
            <ExcluContainer>
              <ExcluText
                size={bannerFont.size}
                weight={bannerFont.weight}
                family={bannerFont.font}
                color={bannerFont.color}
              >
                {banner}
              </ExcluText>
            </ExcluContainer>
          )}

          {project.category !== "Locationsaisonnière" && qrcodeEdit !== null && (
            <QrcodeContainer>
              <QRCode value={qrcodeEdit} size={130} />
            </QrcodeContainer>
          )}
          <RenderContext project={project}>
            <ContainerBottom>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  top:
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "65px"
                      : "20px",
                  left:
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "50px"
                      : "25px",
                }}
              >
                <Dpe
                  letter={
                    project.dpeLetter
                      ? DpeGesNoValue.includes(project.dpeLetter)
                        ? "vi"
                        : project.dpeLetter
                      : "vi"
                  }
                  conso={project.dpeValue}
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.dpeLetter) ||
                    !project.dpeLetter
                      ? "0.22"
                      : "0.28"
                  }
                  topTitle={"logement extrêmement performant"}
                  bottomTitle={"logement extrêmement peu performant"}
                  redInfoTop={"passoire"}
                  redInfoBottom={"énergetique"}
                  label={"consommation"}
                  secondLabel={"émissions"}
                  subLabel={"(énergie primaire)"}
                  subValue={"kWh/m2/an"}
                  secondSubValue={"kg CO₂/m²/an"}
                  theme={"light"}
                />
              </div>
              <div
                style={{
                  height: "fit-content",
                  position: "absolute",
                  bottom:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "25px"
                      : "-40px",
                  left:
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "350px"
                      : "400px",
                }}
              >
                <Ges
                  letter={
                    project.gesLetter
                      ? DpeGesNoValue.includes(project.gesLetter)
                        ? "vi"
                        : project.gesLetter
                      : "vi"
                  }
                  emissions={project.gesValue}
                  zoom={
                    DpeGesNoValue.includes(project.gesLetter) ||
                    !project.gesLetter
                      ? "0.50"
                      : "0.34"
                  }
                  theme={"light"}
                />
              </div>
            </ContainerBottom>
          </RenderContext>
        </ContainerImg>
      </ContainerRight>
    </Container>
  );
});

export default CuppertinoTemplateLandscape;
