import * as React from "react";
import {
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { TextFieldContainer } from "../Styles/Content.styles";
import Surface from "../../../../../assets/LogoTemplates/Sapene/Surface";
import Rooms from "../../../../../assets/LogoTemplates/Sapene/Rooms";
import Chambres from "../../../../../assets/LogoTemplates/Sapene/Chambres";
import Sdb from "../../../../../assets/LogoTemplates/Sapene/Sdb";
import AreaGarden from "../../../../../assets/LogoTemplates/Sapene/AreaGarden";
import Terrace from "../../../../../assets/LogoTemplates/Sapene/Terrace";
import Orientation from "../../../../../assets/LogoTemplates/Sapene/Orientation";
import Ascenseur from "../../../../../assets/LogoTemplates/Sapene/Ascenseur";
import Garage from "../../../../../assets/LogoTemplates/Sapene/Garage";
import { useTemplatesStore } from "../../../../../store/TemplatesStore";

const BootstrapInput = withStyles((theme) => ({
  root: {
    padding: "15px 30px 15px 15px",
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
}))(Select);

const Item = withStyles((theme) => ({
  root: {
    margin: "0 5px",
    display: "grid",
    gridTemplateColumns: "20% 80%",
  },
}))(MenuItem);

const IconsSelector = ({ index, item }) => {
  const { setIcons, icons } = useTemplatesStore();
  const handleChange = (e) => {
    const array = [...icons];
    array[index] = { ...array[index], name: e.target.value, active: true };
    setIcons([...array]);
  };

  const handleChangeValue = (e) => {
    const array = [...icons];
    array[index] = { ...array[index], valeur: e.target.value, active: true };
    setIcons([...array]);
  };

  return (
    <TextFieldContainer flex margin={"1rem auto"}>
      <BootstrapInput
        variant="outlined"
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={item.name}
        onChange={handleChange}
      >
        <Item value={"area"}>
          <Surface width="25" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Surface
          </Typography>
        </Item>
        <Item value={"rooms"}>
          <Rooms width="25" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Pièce(s)
          </Typography>
        </Item>
        <Item value={"bedrooms"}>
          <Chambres width="27" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            chambre(s)
          </Typography>
        </Item>
        <Item value={"sdb"}>
          <Sdb width="25" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Salle(s) de bain
          </Typography>
        </Item>
        <Item value={"gardenArea"}>
          <AreaGarden width="27" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Terrain
          </Typography>
        </Item>
        <Item value={"balcony"}>
          <Terrace width="27" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Balcon
          </Typography>
        </Item>
        <Item value={"terrace"}>
          <Terrace width="27" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Terrasse
          </Typography>
        </Item>
        <Item value={"ascenceur"}>
          <Ascenseur width="25" height="24" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Ascenceur
          </Typography>
        </Item>
        <Item value={"garage"}>
          <Garage width="25" height="25" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Pkg/garage
          </Typography>
        </Item>
        <Item value={"orientation"}>
          <Orientation width="26" height="26" color="black" />
          <Typography style={{ marginLeft: "10px" }} component="subtitle1">
            Orientation
          </Typography>
        </Item>
      </BootstrapInput>
      <TextField
        id="outlined-multiline-static"
        label={"valeur"}
        value={item.valeur}
        variant="outlined"
        onChange={handleChangeValue}
        fullWidth
      />
    </TextFieldContainer>
  );
};

export default IconsSelector;
