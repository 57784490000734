import { BulkDeleteButton } from "ra-ui-materialui";
import React from "react";

const DeleteActionButton = (props) => (
  <>
    <BulkDeleteButton {...props} />
  </>
);

export default DeleteActionButton;
