import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  position: relative;
  height: 292mm;
  width: 438mm;
  background-color: white;

  margin: ${({ marginAuto }) => (marginAuto ? "auto" : "0")};
  zoom: ${({ zoom }) => (zoom ? zoom : "initial")};
  transform: ${({ mirror }) => (mirror ? "scaleX(-1)" : "initial")};

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    @page {
      size: A3 landscape;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;
export const Header = styled.img`
  position: absolute;
  top: 39px;
  left: 50%;
  width: 30%;
  z-index: 1800;
  transform: translate(-50%);
`;
export const Footer = styled.img`
  position: absolute;
  z-index: 1800;
  bottom: 10px;
  right: 5px;
  width: 9%;
`;

export const ContentContainer = styled.div`
  position: absolute;
  top: 33.5mm;
  height: 210mm;
  width: 100%;
  z-index: 1800;
`;

export const CityContainer = styled.div`
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CityText = styled.h2`
  color: black;
  margin-bottom: 0;
  font-size: ${(props) => (props.size !== "" ? `${props.size}pt` : "50px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "900")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Lato', sans-serif"} !important;
`;
export const CityTextSpan = styled.span``;

export const SecondTitle = styled.h3`
  font-size: 33px;
  color: ${(props) => (props.colorTitle2 !== "" ? props.colorTitle2 : "black")};
  margin-bottom: 15px;
  font-weight: bold;
  font-family: "Charter", sans-serif !important;
`;

export const ImagesContainer = styled.div`
  justify-content: center;
  padding: 1.5rem 2rem;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 25px;
  height: 65%;
  grid-template-rows: 100%;
`;
export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 5px ${(props) => props.colorPrimary};
  border-radius: 4px;
  filter: brightness(${(props) => props.brightness}%)
    saturate(${(props) => props.saturation}%);
`;
export const DescContainer = styled.div`
  height: 36.98%;
  width: 100%;
  padding: 0px 30px 0 50px;
  display: grid;
  grid-template-columns: 65% 35%;
`;

export const ExcluContainer = styled.div`
  width: 181.17pt;
  height: 47.82pt;
  background-color: ${(props) => props.colorPrimary};
  position: absolute;
  left: 50px;
  top: 12pt;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ExcluText = styled.p`
  font-size: 24pt;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  text-align: center;
  margin-bottom: 0;
`;

export const BlockDescContainer = styled.div`
  display: ${(props) => (props.flex ? "flex" : "block")};
`;
export const Description = styled.p`
  && {
    line-height: 115%;
    text-align: left;
    color: ${(props) => props.colorDesc};
    white-space: pre-wrap;
    max-height: 147px;
    overflow: hidden;
    font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "25px")};
    font-weight: ${(props) => (props.weight !== "" ? props.weight : "initial")};
    font-family: ${(props) =>
      props.family !== "" ? props.family : "'Lato', sans-serif"} !important;
  }
`;
export const AlurContainer = styled.div`
  display: flex;
`;
export const HonoraryContainer = styled.div`
  width: 28%;
  padding-top: 15px;
`;
export const CoproContainer = styled.div`
  width: 34%;
  padding-top: 15px;
`;
export const PriceContainer = styled.div`
  width: 35%;
  padding-top: 15px;
`;

export const EnergyTextContainer = styled.div`
  padding-left: 15px;
  padding-top: 25px;
  max-width: 370px;
`;

export const AlurText = styled.p`
  margin-bottom: 5px;
  color: black;
  font-size: ${(props) => (props.small ? "12px" : "14px")};
  font-family: "Lato", sans-serif !important;
`;
export const EnergyContainer = styled.div`
  height: 220px;
  display: flex;
  padding-top: 30px;
  position: relative;
`;
export const SoldBanner = styled.div`
  height: 80px;
  min-width: 207px;
  padding: 0 20px;
  position: absolute;
  top: 683px;
  right: 0;
  background-color: ${(props) =>
    props.colorPrimary ? props.colorPrimary : "black"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1999;
`;
export const SoldBannerText = styled.p`
  margin-bottom: 0;
  color: ${(props) => (props.color !== "" ? props.color : "white")};
  font-size: ${(props) => (props.size !== "" ? `${props.size}px` : "50px")};
  font-weight: ${(props) => (props.weight !== "" ? props.weight : "600")};
  font-family: ${(props) =>
    props.family !== "" ? props.family : "'Lato', sans-serif"} !important;
`;
