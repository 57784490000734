import { Box } from "@material-ui/core";
import React from "react";
import { TextInput } from "react-admin";
import { UrlInputText } from "../../Utils/Styles/Agency.styles";

const FormSlug = (props) => {
  // const role = ["admin", "super_admin"];

  // if (
  //   role.includes(props.user.permissions) ||
  //   (props.agency && props.agency.abo && props.agency.abo.ads)
  // ) {
  return (
    <>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source="slug"
            resource="agencies"
            fullWidth
            defaultValue={props.record.slug}
            value={props.slug}
            onChange={props.onChange}
          />
        </Box>
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }} mb={4}>
        <UrlInputText
          fullWidth
          value={`${props.config.APP_BASE}/visite360/${props.record.slug}`}
          label="Url agence"
          disabled
        />
      </Box>
    </>
  );
  // }
  // else {
  //   return null;
  // }
};

export default FormSlug;
